import { Highcharts } from "./utils";

/**
 * Shared chart options for all chart types
 * These options are used to ensure consistent styling and behavior across all charts
 * https://api.highcharts.com/highcharts/plotOptions.series
 * Configuration options for a series are given in three levels:
  
    1. Options for all series in a chart are defined in the plotOptions.series object.
    2. Options for all line series are defined in plotOptions.line, plotOptions.bar, etc.
    3. Options for one single series are given in the series instance array.
    Highcharts.chart('container', {
        plotOptions: {
            series: {
                // general options for all series
            },
            line: {
                // shared options for all line series
            }
        },
        series: [{
            // specific options for this series instance
            type: 'line'
        }]
    });
 */

export const sharedChartOptions: Highcharts.Options = {
  chart: {
    animation: false,
    zooming: {
      type: "xy" as const,
    },
    panning: {
      enabled: true,
      type: "xy" as const,
    },
    panKey: "shift" as const,
    style: {
      fontFamily: "inherit",
    },
  },
  // Add time settings to ensure consistent timezone handling
  time: {
    timezone: undefined, // Use local timezone by default
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
    floating: false,
    enabled: false,
  },
  // @ts-ignore
  series: "{{series}}", // Data will be injected dynamically
  tooltip: {
    pointFormat: "{series.name}: <b>{point.y:,.1f}</b>",
    enabled: true,
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        format: "{point.y:,.0f}",
      },
    },
  },
  xAxis: {
    min: 0,
    // @ts-ignore
    categories: "{{categories}}", // Will be injected dynamically
    title: {
      text: "", // TODO: Return the column name
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "",
    },
    crosshair: true,
  },
};

// Default Pie Chart Options
export const defaultPieChartOptions = (
  savedOptions: Highcharts.Options = {}
): Highcharts.Options => ({
  ...sharedChartOptions,
  ...savedOptions,
  chart: {
    ...sharedChartOptions.chart,
    ...savedOptions.chart,
    type: "pie",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
    ...savedOptions.accessibility,
  },
  plotOptions: {
    ...sharedChartOptions.plotOptions,
    ...savedOptions.plotOptions,
    pie: {
      dataLabels: {
        enabled: true,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
      tooltip: {
        ...sharedChartOptions.tooltip,
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        ...savedOptions.tooltip,
      },
      ...savedOptions.plotOptions?.pie,
      showInLegend: true,
    },
  },
  legend: {
    ...sharedChartOptions.legend,
    enabled: false,
    labelFormatter(this): string {
      const point = this as Highcharts.Point;
      return `${point.name}: ${point.y?.toFixed(1)}`;
    },
    ...savedOptions.legend,
  },
});

// Default Bar Chart Options
export const defaultBarChartOptions = (
  savedOptions: Highcharts.Options = {}
): Highcharts.Options => ({
  ...sharedChartOptions,
  ...savedOptions,
  xAxis: {
    // @ts-ignore
    categories: "{{categories}}", // Will be injected dynamically
    ...sharedChartOptions.xAxis,
    ...savedOptions.xAxis,
  },
  chart: {
    ...sharedChartOptions.chart,
    ...savedOptions.chart,
    type: "bar",
  },
  plotOptions: {
    ...sharedChartOptions.plotOptions,
    ...savedOptions.plotOptions,
    series: {
      ...sharedChartOptions.plotOptions.series,
      dataLabels: {
        enabled: true,
        ...savedOptions.plotOptions?.series?.dataLabels,
        format: "{point.y:,.0f}",
      },
      ...savedOptions.plotOptions?.series,
      tooltip: {
        ...sharedChartOptions.tooltip,
        ...savedOptions.tooltip,
        pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
      },
    },
  },
});

// Default Line Chart Options
export const defaultLineChartOptions = (
  savedOptions: Highcharts.Options = {}
): Highcharts.Options => ({
  ...sharedChartOptions,
  ...savedOptions,
  chart: {
    ...sharedChartOptions.chart,
    zooming: {
      type: "x",
    },
    ...savedOptions.chart,
    type: "line", // override the current chart type
  },
  legend: {
    ...sharedChartOptions.legend,
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
    ...savedOptions.legend,
  },
  plotOptions: {
    ...sharedChartOptions.plotOptions,
    ...savedOptions.plotOptions,
    series: {
      dataLabels: {
        enabled: false,
        ...savedOptions.plotOptions?.series?.dataLabels,
        format: "{point.y:,.0f}",
      },
      ...savedOptions.plotOptions?.series,
    },
  },
});

// Default Scatter Chart Options
export const defaultScatterChartOptions = (
  savedOptions: Highcharts.Options = {}
): Highcharts.Options => ({
  ...sharedChartOptions,
  ...savedOptions,
  chart: {
    ...sharedChartOptions.chart,
    ...savedOptions.chart,
    type: "scatter",
  },
  xAxis: {
    ...sharedChartOptions.xAxis,
    title: {
      text: "{{xColumn}}", // Will be set based on data
    },
    crosshair: true,
    ...savedOptions.xAxis,
  },
  yAxis: {
    ...sharedChartOptions.yAxis,
    title: {
      text: "{{yColumn}}", // Will be set based on data
    },
    ...savedOptions.yAxis,
  },
  plotOptions: {
    ...sharedChartOptions.plotOptions,
    ...savedOptions.plotOptions,
    series: {
      ...sharedChartOptions.plotOptions.series,
      dataLabels: {
        enabled: false,
        ...savedOptions.plotOptions?.series?.dataLabels,
        format: "{point.y:,.0f}",
      },
      tooltip: {
        ...sharedChartOptions.tooltip,
        ...savedOptions.tooltip,
        pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
      },
    },
  },
  legend: {
    ...sharedChartOptions.legend,
    verticalAlign: "top",
    floating: true,
    y: 50,
    itemMarginTop: 5,
    itemMarginBottom: 5,
    maxHeight: 100,
    ...savedOptions.legend,
  },
  tooltip: {
    ...sharedChartOptions.tooltip,
    headerFormat: "<b>{series.name}</b><br>",
    pointFormat: `${"{{xColumn}}"}: {point.x}<br>${"{{yColumn}}"}: {point.y}`, // Will be set based on data
    ...savedOptions.tooltip,
  },
});
