/* eslint-disable lingui/no-unlocalized-strings */
import { User } from "@firebase/auth";

const UserDesignations = {
  Administrator: "Administrator",
  Researcher: "Researcher",
  Instructor: "Instructor",
  ITProfessional: "IT Professional",
  Other: "Other",
} as const;

enum UserRoles {
  ADMIN = "admin",
  VIEWER = "viewer",
}

enum PinboardAccessLevel {
  VIEWER = "viewer",
  EDITOR = "editor",
  OWNER = "owner",
}

type AuthUser = User;

export { PinboardAccessLevel, UserDesignations, UserRoles };
export type { AuthUser };
