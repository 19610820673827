import {
  ChatLoading,
  ChatResultCard,
  PlainChatCard,
} from "@components/ChatContainer/ChatResultCard";
import { useChatData } from "@context/chat";
import { Result } from "@doowii-types/chat";
import { VisualizationTypesEnum } from "@doowii-types/viz";
import { useLingui } from "@lingui/react/macro";
import { Flex } from "@styled-system/jsx";
import { isMessageDescriptor } from "@utils/i18n";

import { ChatQuestionCard } from "./ChatQuestionCard";

interface ChatContainerProps {
  index: number;
  id: string;
  result: Result;
}

const ChatContainer = ({ id, index, result }: ChatContainerProps) => {
  const { answer } = useChatData();
  const { t } = useLingui();

  return (
    <Flex direction="column" gap="4" id={id} w="full">
      <ChatQuestionCard result={result} />
      {result.loading ? (
        <ChatLoading />
      ) : result.error && result.error.message ? (
        <PlainChatCard
          chatId={result.id}
          message={
            isMessageDescriptor(result.error.message)
              ? t(result.error.message)
              : result.error.message
          }
        />
      ) : result.chartConfig?.suggestion === VisualizationTypesEnum.NO_SQL ||
        result.chartConfig?.suggestion === VisualizationTypesEnum.EMPTY_ROWS ? (
        <PlainChatCard chatId={result.id} message={answer[index]} />
      ) : (
        <ChatResultCard index={index} result={result} />
      )}
    </Flex>
  );
};

export { ChatContainer };
