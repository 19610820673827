import { ParentDocTypeEnum } from "@api/retriever.i";
import { ApolloDialog } from "@components/Apollo/ApolloDialog";
import { ChartBuilderDialog } from "@components/ChartBuilder";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { DataVizTypes, VisualizationType } from "@doowii-types/viz";
import { faCog, faTableRows } from "@fortawesome/pro-regular-svg-icons";
import { useLingui } from "@lingui/react/macro";
import * as Tabs from "@radix-ui/react-tabs";
import { getOrCreateTableStore } from "@stores/tableStore";
import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";
import { ChartConfig } from "@utils/chartTransformations";
import { Button, Tooltip } from "doowii-ui";
import { useId, useState } from "react";
import { useStore } from "zustand";

import { GenericChartIcon } from "./GenericChartIcon";

interface DataNavigatorProps {
  currentResult: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
}

const DataNavigator = ({ currentResult, parentDocId, parentDocType }: DataNavigatorProps) => {
  const [apolloDialogIsOpen, setApolloDialogIsOpen] = useState(false);
  const [chartBuilderIsOpen, setChartBuilderIsOpen] = useState(false);
  const id = useId();
  const { t } = useLingui();

  // Get the table store
  const tableStore = getOrCreateTableStore({
    parentDocId,
    parentDocType,
    docId: currentResult.id,
    sql: currentResult.sql,
  });

  // Use separate useStore hooks for each value we need to access
  const chartConfig = useStore(tableStore, (state) => state.chartConfig);
  const updateChartConfig = useStore(tableStore, (state) => state.updateChartConfig);
  const saveStoreChartConfig = useStore(tableStore, (state) => state.saveStoreChartConfig);
  const selectedVisualization = chartConfig?.selected_visualization ?? chartConfig?.suggestion;

  const handleVisualizationChange = (value: string) => {
    if (chartConfig && updateChartConfig) {
      updateChartConfig({
        ...chartConfig,
        selected_visualization: value as VisualizationType,
      } as ChartConfig);
      saveStoreChartConfig();
    }
  };

  return (
    <>
      <Tabs.Root onValueChange={handleVisualizationChange} value={selectedVisualization}>
        <Tabs.List className={flex({ gap: "sm" })}>
          {/* Table Button */}
          <Tabs.Trigger
            aria-label={t`Table view`}
            asChild
            id={`${id}-TABLE`}
            value={DataVizTypes.TABLE}
          >
            <Tooltip content={t`Table view`}>
              <Button
                aria-describedby={`${id}-TABLE`}
                className={css({
                  '&[data-state="active"]': {
                    backgroundColor: "base.blueBrandSecondary",
                  },
                })}
                data-testid="table-view-button"
                iconOnly={faTableRows}
                onClick={() => handleVisualizationChange(DataVizTypes.TABLE)}
                size="small"
                variant="dropdown"
              />
            </Tooltip>
          </Tabs.Trigger>

          <Tabs.Trigger
            aria-label={t`Chart View`}
            asChild
            id={`${id}-CHART`}
            // if suggestion is table, remove active state by setting value to null
            value={chartConfig?.suggestion === DataVizTypes.TABLE ? null : chartConfig?.suggestion}
          >
            {/* Chart View Button */}
            <Tooltip content={t`Chart View`}>
              <Button
                aria-describedby="chart-view"
                aria-label={t`Chart View`}
                data-testid="chart-view-button"
                className={css({
                  '&[data-state="active"]': {
                    backgroundColor: "base.blueBrandSecondary",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                })}
                // if suggestion is not set or is TABLE, open the chart builder
                onClick={() =>
                  chartConfig?.suggestion === DataVizTypes.TABLE
                    ? setChartBuilderIsOpen(true)
                    : handleVisualizationChange(chartConfig?.suggestion)
                }
                size="small"
                variant="dropdown"
              >
                <GenericChartIcon />
              </Button>
            </Tooltip>
          </Tabs.Trigger>
          {/* Chart Builder Button */}
          <Tooltip content={t`Chart Builder`}>
            <Button
              data-testid="chart-builder-button"
              iconOnly={faCog}
              onClick={() => setChartBuilderIsOpen(true)}
              size="small"
              variant="dropdown"
            />
          </Tooltip>
        </Tabs.List>
      </Tabs.Root>
      <ChartBuilderDialog
        isOpen={chartBuilderIsOpen}
        parentDocId={parentDocId}
        parentDocType={parentDocType}
        result={currentResult}
        setIsOpen={setChartBuilderIsOpen}
      />
      <ApolloDialog
        isOpen={apolloDialogIsOpen}
        parentDocId={parentDocId}
        parentDocType={parentDocType}
        result={currentResult}
        setIsOpen={setApolloDialogIsOpen}
      />
    </>
  );
};
export { DataNavigator };
