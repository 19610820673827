import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";

export const useResize = () => {
  const [dimensions, setDimensions] = useState<{ height: number | null; width: number | null }>({
    height: null,
    width: null,
  });
  const [entries, setEntries] = useState<ResizeObserverEntry[]>([]);

  const componentRef = useRef(null);
  const debouncedEntries = useDebounce(entries, 300);

  useEffect(() => {
    if (!debouncedEntries.length) {
      return;
    }

    for (const entry of debouncedEntries) {
      setDimensions({
        height: entry.contentRect.height,
        width: entry.contentRect.width,
      });
    }
  }, [debouncedEntries]);

  useEffect(() => {
    if (componentRef.current) {
      const resizeObserver = new ResizeObserver(setEntries);
      resizeObserver.observe(componentRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  return { dimensions, componentRef };
};
