/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { ActivityLogRequest } from "../../models/activityLogRequest";
import type { ActivityLogResponse } from "../../models/activityLogResponse";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { PinboardResponse } from "../../models/pinboardResponse";
import type { PinboardUpdateRequest } from "../../models/pinboardUpdateRequest";
import type { PinResponse } from "../../models/pinResponse";
import type { PinUpdateRequest } from "../../models/pinUpdateRequest";
import type { PinUpdateResponse } from "../../models/pinUpdateResponse";
import type { SharePinboardCanvasRequest } from "../../models/sharePinboardCanvasRequest";
import type { UpdateCanViewRequest } from "../../models/updateCanViewRequest";

/**
 * Get all pins in a pinboard in their specified order.

Args:
    pinboard_id: ID of the pinboard to fetch pins from
    current_user: Current user and organization info from auth

Returns:
    PinResponse containing ordered list of pins

Raises:
    HTTPException: If pinboard not found or other errors occur
 * @summary Get Pinboard Pins
 */
export const getPinboardPins = (pinboardId: string, signal?: AbortSignal) =>
  webserverAxios<PinResponse>({
    url: `/api/pinboards/${pinboardId}/pins`,
    method: "GET",
    signal,
  });

export const getGetPinboardPinsQueryKey = (pinboardId: string) =>
  [`/api/pinboards/${pinboardId}/pins`] as const;

export const getGetPinboardPinsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPinboardPins>>,
  TError = HTTPValidationError,
>(
  pinboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPinboardPins>>, TError, TData>>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPinboardPinsQueryKey(pinboardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPinboardPins>>> = ({ signal }) =>
    getPinboardPins(pinboardId, signal);

  return { queryKey, queryFn, enabled: Boolean(pinboardId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPinboardPins>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPinboardPinsQueryResult = NonNullable<Awaited<ReturnType<typeof getPinboardPins>>>;
export type GetPinboardPinsQueryError = HTTPValidationError;

export function useGetPinboardPins<
  TData = Awaited<ReturnType<typeof getPinboardPins>>,
  TError = HTTPValidationError,
>(
  pinboardId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPinboardPins>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getPinboardPins>>, TError, TData>,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPinboardPins<
  TData = Awaited<ReturnType<typeof getPinboardPins>>,
  TError = HTTPValidationError,
>(
  pinboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPinboardPins>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPinboardPins>>, TError, TData>,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPinboardPins<
  TData = Awaited<ReturnType<typeof getPinboardPins>>,
  TError = HTTPValidationError,
>(
  pinboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPinboardPins>>, TError, TData>>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Pinboard Pins
 */

export function useGetPinboardPins<
  TData = Awaited<ReturnType<typeof getPinboardPins>>,
  TError = HTTPValidationError,
>(
  pinboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPinboardPins>>, TError, TData>>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPinboardPinsQueryOptions(pinboardId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update pinboard details including name, permissions, pins, layouts and color.

Args:
    pinboard_id: ID of the pinboard to update
    request: Updated pinboard details matching PinboardDoc model
    current_user: Current user and organization info from auth
    db: Firestore client instance

Returns:
    PinboardResponse containing updated pinboard details

Raises:
    HTTPException: If pinboard not found or other errors occur
 * @summary Update Pinboard
 */
export const updatePinboard = (pinboardId: string, pinboardUpdateRequest: PinboardUpdateRequest) =>
  webserverAxios<PinboardResponse>({
    url: `/api/pinboards/${pinboardId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: pinboardUpdateRequest,
  });

export const getUpdatePinboardMutationOptions = <
  TData = Awaited<ReturnType<typeof updatePinboard>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: PinboardUpdateRequest },
    TContext
  >;
}) => {
  const mutationKey = ["updatePinboard"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePinboard>>,
    { pinboardId: string; data: PinboardUpdateRequest }
  > = (props) => {
    const { pinboardId, data } = props ?? {};

    return updatePinboard(pinboardId, data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: PinboardUpdateRequest },
    TContext
  >;
};

export type UpdatePinboardMutationResult = NonNullable<Awaited<ReturnType<typeof updatePinboard>>>;
export type UpdatePinboardMutationBody = PinboardUpdateRequest;
export type UpdatePinboardMutationError = HTTPValidationError;

/**
 * @summary Update Pinboard
 */
export const useUpdatePinboard = <
  TData = Awaited<ReturnType<typeof updatePinboard>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: PinboardUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  TData,
  TError,
  { pinboardId: string; data: PinboardUpdateRequest },
  TContext
> => {
  const mutationOptions = getUpdatePinboardMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update the list of users who can view a pinboard.

Only the pinboard owner can update view permissions.

Args:
    pinboard_id: ID of the pinboard to update permissions for
    request: New list of users who can view the pinboard
    current_user: Current user and organization info from auth

Returns:
    PinboardResponse containing updated pinboard details

Raises:
    HTTPException: If pinboard not found, user not authorized, or other errors occur
 * @summary Update Pinboard Can View
 */
export const updatePinboardCanView = (
  pinboardId: string,
  updateCanViewRequest: UpdateCanViewRequest
) =>
  webserverAxios<PinboardResponse>({
    url: `/api/pinboards/${pinboardId}/can_view`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateCanViewRequest,
  });

export const getUpdatePinboardCanViewMutationOptions = <
  TData = Awaited<ReturnType<typeof updatePinboardCanView>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: UpdateCanViewRequest },
    TContext
  >;
}) => {
  const mutationKey = ["updatePinboardCanView"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePinboardCanView>>,
    { pinboardId: string; data: UpdateCanViewRequest }
  > = (props) => {
    const { pinboardId, data } = props ?? {};

    return updatePinboardCanView(pinboardId, data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: UpdateCanViewRequest },
    TContext
  >;
};

export type UpdatePinboardCanViewMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePinboardCanView>>
>;
export type UpdatePinboardCanViewMutationBody = UpdateCanViewRequest;
export type UpdatePinboardCanViewMutationError = HTTPValidationError;

/**
 * @summary Update Pinboard Can View
 */
export const useUpdatePinboardCanView = <
  TData = Awaited<ReturnType<typeof updatePinboardCanView>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: UpdateCanViewRequest },
    TContext
  >;
}): UseMutationResult<
  TData,
  TError,
  { pinboardId: string; data: UpdateCanViewRequest },
  TContext
> => {
  const mutationOptions = getUpdatePinboardCanViewMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update the sharing settings of a pinboard canvas.

Verifies that the current user is the owner (matches the created_by field)
and then updates the sharing settings:
  - If permission is Viewer, adds the org id to the can_view array and clears can_edit.
  - If permission is Collaborator, adds the org id to the can_edit array and clears can_view.
  - If permission is No Access, clears both arrays.
  - If permission is Owner, returns an error.
 * @summary Share Pinboard Canvas
 */
export const sharePinboardCanvas = (
  sharePinboardCanvasRequest: SharePinboardCanvasRequest,
  signal?: AbortSignal
) =>
  webserverAxios<PinboardResponse>({
    url: `/api/pinboards/share_pinboard_canvas`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: sharePinboardCanvasRequest,
    signal,
  });

export const getSharePinboardCanvasMutationOptions = <
  TData = Awaited<ReturnType<typeof sharePinboardCanvas>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SharePinboardCanvasRequest }, TContext>;
}) => {
  const mutationKey = ["sharePinboardCanvas"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sharePinboardCanvas>>,
    { data: SharePinboardCanvasRequest }
  > = (props) => {
    const { data } = props ?? {};

    return sharePinboardCanvas(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: SharePinboardCanvasRequest },
    TContext
  >;
};

export type SharePinboardCanvasMutationResult = NonNullable<
  Awaited<ReturnType<typeof sharePinboardCanvas>>
>;
export type SharePinboardCanvasMutationBody = SharePinboardCanvasRequest;
export type SharePinboardCanvasMutationError = HTTPValidationError;

/**
 * @summary Share Pinboard Canvas
 */
export const useSharePinboardCanvas = <
  TData = Awaited<ReturnType<typeof sharePinboardCanvas>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SharePinboardCanvasRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: SharePinboardCanvasRequest }, TContext> => {
  const mutationOptions = getSharePinboardCanvasMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Log an activity for a pinboard. The endpoint records various actions such as:

  1. Pinboard has been created
  2. Sharing status has changed (e.g., to no access, viewer, or collaborator)
  3. A new pin has been added
  4. A pin has been removed
  5. Pinboard has been renamed

The logged entry contains:
  - The type of activity.
  - The user who executed the action (obtained from the auth dependency).
  - The timestamp of the action.
  - Any additional data required by the action (e.g., old and new names, pin id, etc.).

The entry is stored in a subcollection named "activity_log" within the pinboard document.
 * @summary Log Activity
 */
export const logActivity = (activityLogRequest: ActivityLogRequest, signal?: AbortSignal) =>
  webserverAxios<ActivityLogResponse>({
    url: `/api/pinboards/log_activity`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: activityLogRequest,
    signal,
  });

export const getLogActivityMutationOptions = <
  TData = Awaited<ReturnType<typeof logActivity>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: ActivityLogRequest }, TContext>;
}) => {
  const mutationKey = ["logActivity"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logActivity>>,
    { data: ActivityLogRequest }
  > = (props) => {
    const { data } = props ?? {};

    return logActivity(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: ActivityLogRequest },
    TContext
  >;
};

export type LogActivityMutationResult = NonNullable<Awaited<ReturnType<typeof logActivity>>>;
export type LogActivityMutationBody = ActivityLogRequest;
export type LogActivityMutationError = HTTPValidationError;

/**
 * @summary Log Activity
 */
export const useLogActivity = <
  TData = Awaited<ReturnType<typeof logActivity>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: ActivityLogRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: ActivityLogRequest }, TContext> => {
  const mutationOptions = getLogActivityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update pin details such as title and description.

Args:
    pinboard_id: ID of the pinboard containing the pin
    pin_id: ID of the pin to update
    request: Updated pin details matching PinUpdateRequest model
    current_user: Current user and organization info from auth

Returns:
    PinUpdateResponse containing updated pin details

Raises:
    HTTPException: If pin not found or other errors occur
 * @summary Update Pin
 */
export const updatePin = (pinboardId: string, pinId: string, pinUpdateRequest: PinUpdateRequest) =>
  webserverAxios<PinUpdateResponse>({
    url: `/api/pinboards/${pinboardId}/pin/${pinId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: pinUpdateRequest,
  });

export const getUpdatePinMutationOptions = <
  TData = Awaited<ReturnType<typeof updatePin>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; pinId: string; data: PinUpdateRequest },
    TContext
  >;
}) => {
  const mutationKey = ["updatePin"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePin>>,
    { pinboardId: string; pinId: string; data: PinUpdateRequest }
  > = (props) => {
    const { pinboardId, pinId, data } = props ?? {};

    return updatePin(pinboardId, pinId, data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; pinId: string; data: PinUpdateRequest },
    TContext
  >;
};

export type UpdatePinMutationResult = NonNullable<Awaited<ReturnType<typeof updatePin>>>;
export type UpdatePinMutationBody = PinUpdateRequest;
export type UpdatePinMutationError = HTTPValidationError;

/**
 * @summary Update Pin
 */
export const useUpdatePin = <
  TData = Awaited<ReturnType<typeof updatePin>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; pinId: string; data: PinUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  TData,
  TError,
  { pinboardId: string; pinId: string; data: PinUpdateRequest },
  TContext
> => {
  const mutationOptions = getUpdatePinMutationOptions(options);

  return useMutation(mutationOptions);
};
