import { PATHS } from "@constants/Paths";
import { UserRoles } from "@doowii-types/auth";
import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

interface RoleGuardProps {
  allowedRoles: UserRoles[]; // Array of roles allowed to access the route
  children?: ReactNode; // Optional children to render if access is allowed
  redirectTo?: string; // Path to redirect unauthorized users (defaults to 404)
  requiredFeatureFlags?: string[]; // Array of feature flags allowed to access the route
}

/**
 * RoleGuard Component
 *
 * This component restricts access to specific routes based on user roles.
 * It acts as a protective layer, ensuring that only users with the appropriate roles can
 * access certain components or pages.
 * If a user does not have the required role, they are redirected to a specified path.
 *
 * Usage:
 * - Wrap protected routes with RoleGuard to enforce role-based access control.
 * - Pass the allowed roles as a prop (e.g., `[UserRoles.ADMIN, UserRoles.EDITOR]`).
 * - If the user lacks the required role, they are redirected to the `redirectTo` path.
 * - Defaults to redirecting to the 404 page if no `redirectTo` is provided.
 *
 * Example:
 * ```tsx
 * <RoleGuard allowedRoles={[UserRoles.ADMIN]}>
 *   <AdminDashboard />
 * </RoleGuard>
 * ```
 *
 * Props:
 * - allowedRoles: Array of user roles permitted to access the component.
 * - children: React elements to render if access is granted.
 * - redirectTo: Path to redirect unauthorized users (default is `PAGE_NOT_FOUND`).
 */
const RoleGuard: React.FC<RoleGuardProps> = ({
  allowedRoles,
  children,
  redirectTo = PATHS.PAGE_NOT_FOUND,
  requiredFeatureFlags = [],
}) => {
  const { userDocument, featureFlags } = useAuth();
  const userRole = userDocument?.role;

  // Check if the user has the required role or feature flag
  if (
    !allowedRoles.includes(userRole) ||
    !requiredFeatureFlags.every((flag) => featureFlags.includes(flag))
  ) {
    return <Navigate replace to={redirectTo.startsWith("/") ? redirectTo : `/${redirectTo}`} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : <Outlet />;
};
export { RoleGuard };
