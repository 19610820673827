import { PageHeader } from "@components/PageHeader";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { css, cx } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { Button, Heading, InputField, PageLayout, useToast } from "doowii-ui";
import { useEffect } from "react";
import { Form, useActionData, useNavigation, useSubmit } from "react-router-dom";

import { DeleteAccountDialog } from "./DeleteAccountDialog";
import { SettingsActionResponse } from "./settingsAction";

const boxStyles = css({
  padding: "xl",
  border: "1px solid",
  borderColor: "base.lightGray",
  borderRadius: "lg",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
});

const ActionButtonStyles = css({
  marginTop: "ld",
  width: "fit-content",
});

const Settings = () => {
  const { t } = useLingui();
  const { userDocument } = useAuth();
  const navigation = useNavigation();
  const { toast } = useToast();
  const actionData = useActionData() as SettingsActionResponse | null;
  const submit = useSubmit();

  const isSubmitting = navigation.state === "submitting";

  useEffect(() => {
    if (actionData?.message) {
      toast({
        status: actionData.success ? "success" : "error",
        title: actionData.message,
      });
    }
  }, [actionData, toast, t]);

  const handleDeleteAccount = () => {
    submit({ intent: "deleteAccount" }, { method: "post" });
  };

  return (
    <>
      <PageHeader description={t`Manage account Settings`} title={t`Doowii - Settings`} />
      <PageLayout title={t`Settings`}>
        {" "}
        <Flex
          css={{
            w: "full",
            flexDirection: "row",
            gap: "ld",
          }}
        >
          <Box className={cx(boxStyles, css({ flex: 1 }))}>
            <Heading css={{ marginBottom: "ld" }} level={2}>
              <Trans>Contact Details</Trans>
            </Heading>
            <Form
              className={css({ display: "flex", flexDirection: "column", gap: "ld" })}
              method="post"
            >
              <InputField
                defaultValue={userDocument?.firstName || ""}
                label={t`First Name`}
                name="firstName"
              />
              <InputField
                defaultValue={userDocument?.lastName || ""}
                label={t`Last Name`}
                name="lastName"
              />
              <InputField
                defaultValue={userDocument?.email || ""}
                disabled
                label={t`Email`}
                name="email"
              />
              <InputField
                defaultValue={userDocument.organization}
                disabled
                label={t`Organization`}
                name="organization"
              />

              <Button
                className={ActionButtonStyles}
                disabled={isSubmitting}
                name="intent"
                type="submit"
                value="updateProfile"
                variant="primary"
              >
                <Trans>Save</Trans>
              </Button>
            </Form>
          </Box>
          <Flex css={{ flexDirection: "column", gap: "ld" }}>
            <Box className={boxStyles}>
              <Heading level={2}>
                <Trans>Password</Trans>
              </Heading>
              <p>
                <Trans>You will receive an email to change your password.</Trans>
              </p>
              <Form method="post">
                <Button
                  className={ActionButtonStyles}
                  disabled={isSubmitting}
                  name="intent"
                  type="submit"
                  value="sendResetLink"
                  variant="primary"
                >
                  <Trans>Send reset link</Trans>
                </Button>
              </Form>
            </Box>
            <Box className={boxStyles}>
              <Heading level={2}>
                <Trans>Delete account</Trans>
              </Heading>
              <p>
                <Trans>Revoke access to doowii and clear all data stored for your account.</Trans>
              </p>
              <Form className={css({ marginTop: "ld" })} method="post">
                <DeleteAccountDialog onConfirm={handleDeleteAccount} />
              </Form>
            </Box>
          </Flex>
        </Flex>
      </PageLayout>
    </>
  );
};

export { Settings };
