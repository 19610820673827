import { sharedChartOptions } from "@components/DynamicChartRenderer/options";
import { mergeSavedDefaultChartOptions } from "@components/DynamicChartRenderer/utils";
import { ChartConfig } from "@doowii-types/chart";
import { useLingui } from "@lingui/react/macro";
import { VStack } from "@styled-system/jsx";
import { useDebounce } from "@uidotdev/usehooks";
import { Accordion } from "doowii-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  AxesOptions,
  GeneralOptions,
  LegendOptions,
  SeriesOptions,
  TooltipOptions,
} from "../OptionGroups";

interface LineChartOptionsProps {
  chartOptions: Highcharts.Options;
  handleUpdateConfigState: (chartOptions: Highcharts.Options) => void;
}

// Define the structure of options for line charts
interface LineChartOptionsValues {
  // General options
  title: string;
  subtitle: string;

  // Legend options
  showLegend: boolean;
  legendAlign: string;
  legendVerticalAlign: string;
  legendLayout: string;
  legendFloating: boolean;

  // Axes options
  xAxisTitle: string;
  yAxisTitle: string;
  showXAxisGrid: boolean;
  showYAxisGrid: boolean;

  // Tooltip options
  showTooltip: boolean;
  tooltipFormat: string;

  // Series options
  showDataLabels: boolean;
  dataLabelSuffix: string;
}

const LineChartOptions: React.FC<LineChartOptionsProps> = ({
  chartOptions,
  handleUpdateConfigState,
}) => {
  const { t } = useLingui();

  // Extract initial values from chart config
  const initialValues = useMemo<LineChartOptionsValues>(() => {
    const savedConfig = chartOptions;

    // Parse existing format to extract suffix if possible
    let dataLabelSuffix = "";

    try {
      // Try to get the suffix from the existing format
      // @ts-ignore
      const format = (savedConfig.plotOptions?.series?.dataLabels?.format as string) || "";
      // Simple check - anything after the closing brace is the suffix
      const match = format.match(/\{point\.y:,\.0f\}(.*)/u);
      if (match && match[1]) {
        dataLabelSuffix = match[1];
      }
    } catch (error) {
      // Ignore errors, use empty suffix
    }

    return {
      // General options
      title: savedConfig.title?.text || "",
      subtitle: savedConfig.subtitle?.text || "",

      // Legend options
      showLegend: savedConfig.legend?.enabled !== false,
      legendAlign: savedConfig.legend?.align || "center",
      legendVerticalAlign: savedConfig.legend?.verticalAlign || "bottom",
      legendLayout: savedConfig.legend?.layout || "horizontal",
      legendFloating: savedConfig.legend?.floating || false,

      // Axes options
      // @ts-ignore
      xAxisTitle: savedConfig.xAxis?.title?.text || "",
      // @ts-ignore
      yAxisTitle: savedConfig.yAxis?.title?.text || "",
      // @ts-ignore
      showXAxisGrid: savedConfig.xAxis?.gridLineWidth !== 0,
      // @ts-ignore
      showYAxisGrid: savedConfig.yAxis?.gridLineWidth !== 0,

      // Tooltip options
      showTooltip: savedConfig.tooltip?.enabled !== false,
      tooltipFormat: savedConfig.tooltip?.pointFormat || "",

      // Series options
      // @ts-ignore
      showDataLabels: savedConfig.plotOptions?.series?.dataLabels?.enabled === true,
      dataLabelSuffix,
    };
  }, [chartOptions]);

  // Local state to track form changes
  const [formValues, setFormValues] = useState<LineChartOptionsValues>(initialValues);

  // Create debounced version of formValues with useDebounce
  const debouncedValues = useDebounce(formValues, 500);

  // Update local state when chartConfig changes
  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  // Apply changes when debouncedValues change
  useEffect(() => {
    // Skip the initial application when values match the initial state
    if (JSON.stringify(debouncedValues) === JSON.stringify(initialValues)) {
      return;
    }

    // Build format string - fixed format plus suffix
    let format = "{point.y:,.0f}";
    if (debouncedValues.dataLabelSuffix) {
      format += debouncedValues.dataLabelSuffix;
    }

    // Set default options if chartConfig is missing options
    const oldOptions = chartOptions;
    const updatedOptions: Highcharts.Options = {
      ...oldOptions,
      // General options
      title: {
        ...(oldOptions.title || {}),
        text: debouncedValues.title,
      },
      subtitle: {
        ...(oldOptions.subtitle || {}),
        text: debouncedValues.subtitle,
      },
      // Legend options
      legend: {
        ...(oldOptions.legend || {}),
        enabled: debouncedValues.showLegend,
        align: debouncedValues.legendAlign as Highcharts.AlignValue,
        verticalAlign: debouncedValues.legendVerticalAlign as Highcharts.VerticalAlignValue,
        layout: debouncedValues.legendLayout as any, // Force cast to avoid TS issues
        floating: debouncedValues.legendFloating,
      },
      // Axes options
      xAxis: {
        ...(oldOptions.xAxis || {}),
        title: {
          // @ts-ignore
          ...((oldOptions.xAxis?.title as any) || {}),
          text: debouncedValues.xAxisTitle,
        },
        gridLineWidth: debouncedValues.showXAxisGrid ? 1 : 0,
      },
      yAxis: {
        ...(oldOptions.yAxis || {}),
        title: {
          // @ts-ignore
          ...((oldOptions.yAxis?.title as any) || {}),
          text: debouncedValues.yAxisTitle,
        },
        gridLineWidth: debouncedValues.showYAxisGrid ? 1 : 0,
      },
      // Tooltip options
      tooltip: {
        ...(oldOptions.tooltip || {}),
        enabled: debouncedValues.showTooltip,
        pointFormat: debouncedValues.tooltipFormat || undefined,
      },
      // Series options
      plotOptions: {
        ...(oldOptions.plotOptions || {}),
        series: {
          ...(oldOptions.plotOptions?.series || {}),
          animation: false,
          dataLabels: {
            // @ts-ignore
            ...((oldOptions.plotOptions?.series?.dataLabels as any) || {}),
            enabled: debouncedValues.showDataLabels,
            format,
          },
        },
      },
    };

    // Update the chart config with the new options
    handleUpdateConfigState(updatedOptions);
  }, [debouncedValues, chartOptions, initialValues, handleUpdateConfigState]);

  // Handlers for form changes
  const handleOptionsChange = useCallback((options: Partial<LineChartOptionsValues>) => {
    setFormValues((prev) => ({
      ...prev,
      ...options,
    }));
  }, []);

  // Handle legend options change
  const handleLegendOptionsChange = useCallback(
    (options: {
      showLegend?: boolean;
      align?: string;
      verticalAlign?: string;
      layout?: string;
      floating?: boolean;
    }) => {
      setFormValues((prev) => ({
        ...prev,
        showLegend: options.showLegend ?? prev.showLegend,
        legendAlign: options.align ?? prev.legendAlign,
        legendVerticalAlign: options.verticalAlign ?? prev.legendVerticalAlign,
        legendLayout: options.layout ?? prev.legendLayout,
        legendFloating: options.floating ?? prev.legendFloating,
      }));
    },
    []
  );

  // Handle series options change
  const handleSeriesOptionsChange = useCallback(
    (options: { showDataLabels?: boolean; suffix?: string }) => {
      setFormValues((prev) => ({
        ...prev,
        showDataLabels: options.showDataLabels ?? prev.showDataLabels,
        dataLabelSuffix: options.suffix ?? prev.dataLabelSuffix,
      }));
    },
    []
  );

  // Define accordion rows for line chart options
  const accordionRows = useMemo(
    () => [
      {
        title: t`General Options`,
        content: (
          <GeneralOptions
            initialSubtitle={formValues.subtitle}
            initialTitle={formValues.title}
            onOptionsChange={handleOptionsChange}
          />
        ),
      },
      {
        title: t`Legend Options`,
        content: (
          <LegendOptions
            initialAlign={formValues.legendAlign}
            initialFloating={formValues.legendFloating}
            initialLayout={formValues.legendLayout}
            initialShowLegend={formValues.showLegend}
            initialVerticalAlign={formValues.legendVerticalAlign}
            onOptionsChange={handleLegendOptionsChange}
          />
        ),
      },
      {
        title: t`Axes Options`,
        content: (
          <AxesOptions
            initialShowXAxisGrid={formValues.showXAxisGrid}
            initialShowYAxisGrid={formValues.showYAxisGrid}
            initialXAxisTitle={formValues.xAxisTitle}
            initialYAxisTitle={formValues.yAxisTitle}
            onOptionsChange={handleOptionsChange}
          />
        ),
      },
      {
        title: t`Tooltip Options`,
        content: (
          <TooltipOptions
            initialShowTooltip={formValues.showTooltip}
            initialTooltipFormat={formValues.tooltipFormat}
            onOptionsChange={handleOptionsChange}
          />
        ),
      },
      {
        title: t`Series Options`,
        content: (
          <SeriesOptions
            initialShowDataLabels={formValues.showDataLabels}
            initialSuffix={formValues.dataLabelSuffix}
            onOptionsChange={handleSeriesOptionsChange}
          />
        ),
      },
    ],
    [t, formValues, handleOptionsChange, handleLegendOptionsChange, handleSeriesOptionsChange]
  );

  return (
    <VStack gap="md" width="full">
      <Accordion lockedHeader rows={accordionRows} title={t`Chart Configuration`} />
    </VStack>
  );
};

export { LineChartOptions };
