import { Box } from "@styled-system/jsx";
import {
  Breadcrumb as BreadcrumbContainer,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "doowii-ui";
import { Fragment } from "react";

interface RoleChainBreadcrumbProps {
  crumbs: Array<{
    role: string;
    name: string;
    label: string;
  }>;
  setCrumbs: (
    crumbs: Array<{
      role: string;
      name: string;
      label: string;
    }>
  ) => void;
  setRoleState: React.Dispatch<
    React.SetStateAction<{
      currentRole?: string;
      parentLevelName?: string;
      parentUserName?: string;
    }>
  >;
}

const RoleChainBreadcrumb = ({ crumbs, setCrumbs, setRoleState }: RoleChainBreadcrumbProps) => {
  const handleRoleClick = (index: number) => {
    if (index < crumbs.length - 1) {
      setRoleState((prev) => ({
        ...prev,
        currentRole: crumbs[index + 1].role,
        parentLevelName: crumbs[index].role,
        parentUserName: crumbs[index].name,
      }));

      const updatedCrumbs = crumbs.slice(0, index + 1);
      setCrumbs(updatedCrumbs);
    }
  };

  return (
    <BreadcrumbContainer>
      <BreadcrumbList>
        {crumbs.map((crumb, index) => (
          <Fragment key={crumb.role}>
            <BreadcrumbItem>
              <Box
                onClick={() => handleRoleClick(index)}
                css={{
                  cursor: "pointer",
                  _hover: { textDecoration: "underline" },
                }}
              >
                {`${crumb.label}: ${crumb.name}`}
              </Box>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </Fragment>
        ))}
      </BreadcrumbList>
    </BreadcrumbContainer>
  );
};

export { RoleChainBreadcrumb };
