import { useChatData } from "@context/chat";
import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import React, { createContext, useContext } from "react";

import { Doowii } from "../../search/Doowii";

interface DoowiiContextValue {
  doowii: Doowii;
}

const DoowiiContext = createContext<DoowiiContextValue | null>(null);

export const DoowiiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useLingui();
  const { userDocument } = useAuth();

  const {
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    currentThread,
    allResults,
    threads,
  } = useChatData();

  const doowii = new Doowii(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    userDocument,
    currentThread,
    allResults,
    threads
  );

  return <DoowiiContext.Provider value={{ doowii }}>{children}</DoowiiContext.Provider>;
};

export const useDoowii = (): Doowii => {
  const context = useContext(DoowiiContext);
  if (!context) {
    throw new Error("useDoowii must be used within a DoowiiProvider");
  }
  return context.doowii;
};
