import { ContactMeBtn } from "@components/ContactMe/ContactMeBtn";
import { HIGHER_ED } from "@constants/constants";
import { DATA_CONTEXT as DATA_CONTEXT_FLAG, RBAC_ORGANIZATION } from "@constants/features";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { UserRoles } from "@doowii-types/auth";
import {
  faBookmark,
  faCodeBranch,
  faDatabase,
  faHouse,
  faMessages,
  faScreenUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import { NavBar, NavItem } from "doowii-ui";

import { env } from "../../env";

const NavigationBar = () => {
  const { authUser, userDocument, featureFlags } = useAuth();
  const { loading, streamLoading } = useChatData();
  const { t } = useLingui();
  const userId = authUser?.uid;
  const disableNavigation = loading || streamLoading;

  const HOME = {
    path: `${PATHS.USERS}/${userId}/${PATHS.HOME}`,
    label: t`Home`,
    icon: <FontAwesomeIcon icon={faHouse} />,
    disabled: disableNavigation,
  };

  const PINBOARDS = {
    path: `${PATHS.USERS}/${userId}/${PATHS.PINBOARDS}`,
    label: t`Pinboards`,
    icon: <FontAwesomeIcon icon={faBookmark} />,
    disabled: disableNavigation,
    dataTestId: "pinboards-nav-item",
  };

  const THREADS = {
    path: `${PATHS.USERS}/${userId}/${PATHS.THREADS}`,
    label: t`Threads`,
    icon: <FontAwesomeIcon icon={faMessages} />,
    disabled: disableNavigation,
    dataTestId: "threads-nav-item",
  };

  const DATA = {
    path: PATHS.DATA_SCHEMA,
    label: t`Data`,
    icon: <FontAwesomeIcon icon={faCodeBranch} />,
    disabled: disableNavigation,
  };

  const KNOWLEDGE_LIBRARY = {
    path: PATHS.KNOWLEDGE_LIBRARY,
    label: t`Knowledge`,
    icon: <FontAwesomeIcon icon={faDatabase} />,
    disabled: disableNavigation,
  };

  const ORGANIZATION = {
    path: PATHS.ORGANIZATION,
    label: t`Organization`,
    icon: <FontAwesomeIcon icon={faScreenUsers} />,
    disabled: disableNavigation,
  };

  const mainNavItems = [HOME, PINBOARDS, THREADS, DATA];
  const adminNavItems: NavItem[] = [];
  if (featureFlags?.includes(RBAC_ORGANIZATION)) {
    adminNavItems.push(ORGANIZATION);
  }
  if (featureFlags?.includes(DATA_CONTEXT_FLAG)) {
    adminNavItems.push(KNOWLEDGE_LIBRARY);
  }

  const isAdmin = userDocument.role === UserRoles.ADMIN;

  return (
    <NavBar
      footerButton={env.REACT_APP_ENV === HIGHER_ED ? <ContactMeBtn /> : null}
      navItems={mainNavItems}
      secondaryNavItems={isAdmin ? adminNavItems : undefined}
      secondaryNavTitle={isAdmin ? t`Admin Console` : undefined}
    />
  );
};

export { NavigationBar };
