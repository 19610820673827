import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { useLingui } from "@lingui/react/macro";
import { Button, Tooltip } from "doowii-ui";

const NotificationsButton = () => {
  const { t } = useLingui();
  return (
    <Tooltip content={t`No unread notifications`}>
      <Button aria-label={t`Open notification panel`} iconOnly={faBell} variant="dropdown" />
    </Tooltip>
  );
};

export { NotificationsButton };
