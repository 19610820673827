import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faLock,
  faPlus,
  faRotate,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react/macro";
import {
  getGetCustomKnowledgeQueryKey,
  useCreateCustomKnowledge,
  useDeleteCustomKnowledge,
  useGetCustomKnowledge,
  useUpdateCustomKnowledge,
} from "@services/api/generated/webserver/endpoints/knowledge/knowledge";
import type { CustomKnowledgeItem } from "@services/api/generated/webserver/models/customKnowledgeItem";
import { KnowledgeStatus } from "@services/api/generated/webserver/models/knowledgeStatus";
import type { UpdateCustomKnowledgeRequest } from "@services/api/generated/webserver/models/updateCustomKnowledgeRequest";
import { css } from "@styled-system/css";
import { Box, Stack } from "@styled-system/jsx";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import {
  Button,
  ConfirmationDialog,
  DropdownMenuItem,
  Label,
  SearchInput,
  SimpleTable,
  Text,
  useToast,
} from "doowii-ui";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";

import { EditKnowledgeItemDialog } from "./EditKnowledgeDialog";
import { EnableSwitch } from "./EnableSwitch";
import { ContextActionsDropdown } from "./KnowledgeActionsDropdown";
import { StatusBadge } from "./StatusBadge";

const columnHelper = createColumnHelper<CustomKnowledgeItem>();

const CustomKnowledgeTab = () => {
  const { t } = useLingui();
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetCustomKnowledge({
    query: {
      refetchInterval: 12000,
    },
  });
  const { toast } = useToast();
  const [editingItem, setEditingItem] = useState<CustomKnowledgeItem | null>(null);
  const [deletingItem, setDeletingItem] = useState<CustomKnowledgeItem | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const { mutate: update, isPending: isUpdating } = useUpdateCustomKnowledge({
    mutation: {
      onMutate: async (newData) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries({ queryKey: getGetCustomKnowledgeQueryKey() });

        // Snapshot the previous value
        const previousItems =
          queryClient.getQueryData<CustomKnowledgeItem[]>(getGetCustomKnowledgeQueryKey()) ?? [];
        // Optimistically update to the new value
        queryClient.setQueryData<CustomKnowledgeItem[]>(
          getGetCustomKnowledgeQueryKey(),
          previousItems.map((item) =>
            // reset updated_at on client to update the item without re-ordering the items
            item.id === newData.data.id
              ? {
                  ...item,
                  ...newData.data,
                  updated_at: new Date().toISOString(),
                  status: KnowledgeStatus.PENDING,
                }
              : item
          )
        );

        return { previousItems };
      },
      onError: (err, variables, context) => {
        if (context?.previousItems) {
          queryClient.setQueryData(getGetCustomKnowledgeQueryKey(), context.previousItems);
        }
      },
      onSettled: () => {
        // do not refetch immediately to avoid re-ordering the items
      },
    } as UseMutationOptions<
      unknown,
      unknown,
      { data: UpdateCustomKnowledgeRequest },
      { previousItems: CustomKnowledgeItem[] }
    >,
  });

  const { mutate: create, isPending: isCreating } = useCreateCustomKnowledge();
  const { mutate: remove, isPending: isDeleting } = useDeleteCustomKnowledge();

  const handleEdit = useCallback((item: CustomKnowledgeItem) => {
    setEditingItem(item);
  }, []);

  const handleCreate = useCallback(() => {
    setEditingItem({
      id: "new",
      title: "",
      content: "",
      is_enabled: true,
    } as CustomKnowledgeItem);
  }, []);

  const handleDelete = useCallback((item: CustomKnowledgeItem) => {
    setDeletingItem(item);
  }, []);

  const handleSaveEdit = useCallback(
    (data: Partial<CustomKnowledgeItem>) => {
      const isNew = data.id === "new";
      const updatedItem = {
        ...editingItem,
        ...data,
      } as CustomKnowledgeItem;
      const mutation = isNew ? create : update;

      mutation(
        { data: updatedItem },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: isNew ? t`Context created successfully` : t`Context updated successfully`,
            });
            if (isNew) {
              // only invalidate query & refetch if new item is created
              void queryClient.invalidateQueries({ queryKey: getGetCustomKnowledgeQueryKey() });
            }
            setEditingItem(null);
          },
          onError: (error) => {
            console.error(error);
            const errorDetail = error.response?.data?.detail;
            toast({
              status: "error",
              title: isNew ? t`Failed to create context` : t`Failed to update context`,
              description: errorDetail,
            });
          },
        }
      );
    },
    [t, toast, update, create, editingItem, queryClient]
  );

  const handleConfirmDelete = useCallback(() => {
    if (!deletingItem) {
      return;
    }

    remove(
      { knowledgeId: deletingItem.id },
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData<CustomKnowledgeItem[]>(getGetCustomKnowledgeQueryKey(), (old) =>
            old.map((item) =>
              item.id === variables.knowledgeId
                ? {
                    ...item,
                    status: KnowledgeStatus.DELETING,
                    updated_at: new Date().toISOString(),
                  }
                : item
            )
          );
          toast({
            status: "success",
            title: t`Context deleted successfully`,
            description: t`The context item has been removed`,
          });
          setDeletingItem(null);
        },
        onError: (error) => {
          console.error(error);
          toast({
            status: "error",
            title: t`Failed to delete context`,
          });
          setDeletingItem(null);
        },
      }
    );
  }, [deletingItem, t, toast, remove, queryClient]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        header: t`Expand`,
        cell: ({ row }) => {
          if (!row.getCanExpand()) {
            return null;
          }
          return (
            <Stack alignItems="center">
              <Button
                aria-label={row.getIsExpanded() ? t`Collapse row` : t`Expand row`}
                className={css({
                  color: "gray.900",
                })}
                iconOnly={row.getIsExpanded() ? faChevronDown : faChevronRight}
                onClick={row.getToggleExpandedHandler()}
                size="xsmall"
                variant="text"
              />
            </Stack>
          );
        },
      },
      columnHelper.accessor("title", {
        header: t`Title`,
        cell: (info) => (
          <Box
            css={{
              maxWidth: "200px",
              lineHeight: "1.25",
              textOverflow: "ellipsis",
              lineClamp: 3,
            }}
          >
            {info.getValue()}
          </Box>
        ),
      }),
      columnHelper.accessor("content", {
        header: t`Description`,
        cell: (info) => (
          <Box
            css={{
              lineHeight: "1.25",
              textOverflow: "ellipsis",
              lineClamp: 3,
            }}
          >
            {info.getValue()}
          </Box>
        ),
      }),
      columnHelper.accessor("status", {
        header: t`Status`,
        cell: (info) => <StatusBadge status={info.getValue()} />,
      }),
      columnHelper.accessor("updated_at", {
        header: t`Last Updated`,
        cell: (info) => {
          const date = info.getValue() ? DateTime.fromISO(info.getValue()) : null;
          return date ? (
            <Text
              className={css({
                whiteSpace: "nowrap",
                color: "gray.600",
              })}
            >
              {date.toRelative()}
            </Text>
          ) : null;
        },
      }),
      columnHelper.accessor("is_enabled", {
        header: t`Enable`,
        cell: (info) => (
          <EnableSwitch
            isEnabled={info.getValue()}
            onToggle={(checked) => {
              handleSaveEdit({
                id: info.row.original.id,
                is_enabled: checked,
              } satisfies Pick<CustomKnowledgeItem, "id" | "is_enabled">);
            }}
            status={info.row.original.status}
          />
        ),
      }),
      {
        id: "actions",
        header: t`Actions`,
        cell: ({ row }) => {
          const isEditable = row.original.status === KnowledgeStatus.COMPLETED;
          const isDeletable =
            row.original.status === KnowledgeStatus.COMPLETED ||
            row.original.status === KnowledgeStatus.FAILED;
          const canRetry =
            row.original.status === KnowledgeStatus.FAILED ||
            row.original.status === KnowledgeStatus.PENDING;

          return (
            <Stack alignItems="center">
              <ContextActionsDropdown>
                {isEditable ? (
                  <DropdownMenuItem onClick={() => handleEdit(row.original)}>
                    <FontAwesomeIcon icon={faEdit} />
                    {t`Edit`}
                  </DropdownMenuItem>
                ) : canRetry ? (
                  <DropdownMenuItem onClick={() => handleSaveEdit(row.original)}>
                    <FontAwesomeIcon icon={faRotate} />
                    {t`Retry`}
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem disabled>
                    <FontAwesomeIcon icon={faLock} />
                    {t`Processing`}
                  </DropdownMenuItem>
                )}
                {isDeletable ? (
                  <DropdownMenuItem onClick={() => handleDelete(row.original)}>
                    <FontAwesomeIcon icon={faTrash} />
                    {t`Delete`}
                  </DropdownMenuItem>
                ) : null}
              </ContextActionsDropdown>
            </Stack>
          );
        },
      },
    ],
    [t, handleEdit, handleDelete, handleSaveEdit]
  );

  const renderSubComponent = ({ row }: { row: Row<CustomKnowledgeItem> }) => (
    <Stack
      css={{
        p: 4,
        bg: "gray.50",
        pl: 10,
        maxHeight: "300px",
        overflowY: "auto",
        boxShadow: "inset 0 0 10px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Label level={4} variant="primary">
        {row.original.title}
      </Label>
      <Text>{row.original.content}</Text>
    </Stack>
  );

  return (
    <Box>
      <Stack css={{ mb: 4 }} gap={4} justifyContent="space-between">
        <Text level={3} variant="primary">
          {t`Here you can add custom terms, definitions, and other context items to aid Doowii when generating responses.`}
        </Text>
        <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
          <SearchInput
            aria-label={t`Search knowledge items`}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t`Search by title`}
            value={searchQuery}
            variant="standard"
          />
          <Button iconLeft={faPlus} onClick={handleCreate} size="small" variant="primary">
            {t`Add Knowledge`}
          </Button>
        </Stack>
      </Stack>

      <SimpleTable
        className={css({
          maxHeight: "3xl",
        })}
        columnFilters={[{ id: "title", value: searchQuery }]}
        columns={columns as ColumnDef<CustomKnowledgeItem>[]}
        data={data ?? []}
        emptyComponent={
          <div className={css({ textAlign: "center", py: "4" })}>{t`No data available`}</div>
        }
        getRowCanExpand={(row) => String(row.getValue("content")).length > 0}
        loading={isLoading}
        paginationMode="none"
        renderSubComponent={renderSubComponent}
      />

      <EditKnowledgeItemDialog
        dialogDescription={
          editingItem?.id ? t`Edit this context item.` : t`Create a new context item.`
        }
        dialogTitle={editingItem?.id ? t`Edit Context` : t`New Context`}
        isOpen={editingItem !== null}
        isSaving={isCreating || isUpdating}
        item={editingItem}
        onClose={() => setEditingItem(null)}
        onSave={handleSaveEdit}
      />

      <ConfirmationDialog
        confirmationButtonText={t`Delete`}
        description={t`Are you sure you want to delete this context item? This action cannot be undone.`}
        destructive
        icon={faTrash}
        isLoadingAction={isDeleting}
        isOpen={deletingItem !== null}
        onConfirm={handleConfirmDelete}
        setIsOpen={() => setDeletingItem(null)}
        title={t`Delete Context`}
      />
    </Box>
  );
};

export { CustomKnowledgeTab };
