import { PageHeader } from "@components/PageHeader";
import { HIGHER_ED } from "@constants/constants";
import { PATHS } from "@constants/Paths";
import { UserDesignations } from "@doowii-types/auth";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { BackgroundBox, Button, Checkbox, InputField, LoadingLogo, Logo } from "doowii-ui";
import { useEffect, useState } from "react";
import { Form, useActionData, useNavigate, useNavigation } from "react-router-dom";

import { env } from "../../env";
import { DesignationSelector } from "./DesignationSelector";
import { RegisterActionResponse } from "./registerAction";

const Register = () => {
  const { t } = useLingui();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const actionData = useActionData() as RegisterActionResponse | null;
  const { isLoading, userDocument, authUser, deleteAuthUser } = useAuth();
  const [selectedDesignation, setSelectedDesignation] = useState<
    (typeof UserDesignations)[keyof typeof UserDesignations]
  >(UserDesignations.Administrator);

  useEffect(() => {
    if (userDocument ?? !authUser) {
      if (env.REACT_APP_ENV === HIGHER_ED) {
        navigate(PATHS.ONBOARDING);
      } else {
        navigate(PATHS.ROOT, { replace: true });
      }
    }
  }, [userDocument, authUser, navigate]);

  const isSubmitting = navigation.state === "submitting";

  const handleCancelRegister = async () => {
    await deleteAuthUser();
    navigate(PATHS.SIGN_IN);
  };

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <>
      <PageHeader description={t`Register your user information`} title={t`Doowii - Register`} />

      <BackgroundBox
        containerProps={{ css: {} }}
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          padding: "xl6",
          opacity: isSubmitting ? 0.5 : 1,
          pointerEvents: isSubmitting ? "none" : "auto",
        }}
      >
        <Box
          className={flex({
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            flex: 1,
          })}
        >
          <Logo />
          <h2
            className={css({
              textStyle: "title_h2",
              fontWeight: 600,
              marginTop: "md",
              marginBottom: "xl",
            })}
          >
            <Trans>Your information</Trans>
          </h2>
          <Form className={css({ width: "100%", maxWidth: "400px" })} method="post">
            <Box
              className={flex({
                flexDirection: "column",
                gap: "ld",
              })}
            >
              <InputField
                disabled={true}
                label={t`Email`}
                name="email"
                placeholder={authUser?.email}
                type="text"
              />
              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.firstName}
                label={t`First Name`}
                name="firstName"
                placeholder={t`First Name`}
                required
                type="text"
              />

              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.lastName}
                label={t`Last Name`}
                name="lastName"
                placeholder={t`Last Name`}
                required
                type="text"
              />

              {env.REACT_APP_ENV === HIGHER_ED ? (
                <>
                  <DesignationSelector
                    selectedDesignation={selectedDesignation}
                    setSelectedDesignation={setSelectedDesignation}
                  />

                  {selectedDesignation === UserDesignations.Other ? (
                    <InputField
                      defaultValue={UserDesignations.Other}
                      disabled={isSubmitting}
                      label={t`Other Designation`}
                      name="otherDesignation"
                      placeholder={t`Other`}
                      type="text"
                    />
                  ) : null}
                </>
              ) : null}
            </Box>

            <Flex align="flex-start" direction="column" gap="ld" marginTop="ld">
              <Checkbox
                className={css({ background: "transparent" })}
                label="Receive information from doowii"
                name="marketingOptIn"
                variant="secondary"
              />
              <Button disabled={isSubmitting} full loading={isSubmitting} type="submit">
                <Trans>Get Started</Trans>
              </Button>
              <Button
                disabled={isSubmitting}
                full
                loading={isSubmitting}
                onClick={handleCancelRegister}
                variant="secondary"
              >
                <Trans>Cancel</Trans>
              </Button>
            </Flex>
          </Form>
        </Box>
      </BackgroundBox>
    </>
  );
};

export { Register };
