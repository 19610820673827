import { DATA_CONTEXT, RBAC_ORGANIZATION } from "@constants/features";
import { PATH_PARAMS, PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import { UserRoles } from "@doowii-types/auth";
import { AuthLayout } from "@layouts/AuthLayout";
import { useLingui } from "@lingui/react/macro";
import { DataSchema } from "@pages/DataSchema";
import { KnowledgeLibrary } from "@pages/KnowledgeLibrary";
import { Onboarding } from "@pages/Onboarding";
import { useMemo } from "react";
import { createBrowserRouter, Outlet, Params } from "react-router-dom";

import { Pinboards } from "../pages/Pinboards";
import { Pinboard } from "../pages/Pinboards/Pinboard";
import { Thread } from "../pages/Threads/Thread/Thread";
import { Threads } from "../pages/Threads/Threads";
import { NotFoundPage } from "./404";
import { ErrorPage } from "./Error";
import { Home } from "./Home";
import { Redirector } from "./Redirector";
import { Register, registerAction } from "./Register";
import { ResetPassword, resetPasswordAction } from "./ResetPassword";
import { RoleGuard } from "./RoleGuard";
import { rootLoader } from "./rootLoader";
import { Settings, settingsAction } from "./Settings";
import { SignIn, signInAction } from "./SignIn";
import { signOutAction } from "./SignOut";
import { SignUp, signUpAction } from "./SignUp";
import { UserManagement } from "./UserManagement";
import { Organization } from "@pages/Organization";
import { VerifyEmail } from "./VerifyEmail/VerifyEmail";
import { verifyEmailAction } from "./VerifyEmail/verifyEmailAction";

/*
Routes:

Must be authenticated:
    /settings
    /data-schema
    /user-management
    /sources

    all users must be able to view/edit :pinboardId (via loader)
        /user/:userId/pinboards
        /user/:userId/pinboards/:pinboardId

    only admin users must be able to view/edit :threadId (via loader)
        /user/:userId/threads
        /user/:userId/threads/:threadId 

Public:
    /sign-in
    /404
*/

// Loaders need to fetch current user at ever level currently:
// https://github.com/remix-run/react-router/discussions/9564

const useRouter = () => {
  const { t } = useLingui();
  const { boards } = usePinboard();
  const { threads } = useChatData();

  return useMemo(
    () =>
      createBrowserRouter([
        {
          path: PATHS.ROOT,
          loader: rootLoader,
          element: <AuthLayout />,
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Redirector />,
            },
            {
              path: PATHS.SETTINGS,
              element: <Settings />,
              action: settingsAction,
            },

            {
              path: PATHS.SIGN_OUT,
              action: signOutAction,
            },
            {
              path: PATHS.USER_MANAGEMENT,
              element: (
                <RoleGuard allowedRoles={[UserRoles.ADMIN]} redirectTo={PATHS.PAGE_NOT_FOUND}>
                  <UserManagement />
                </RoleGuard>
              ),
            },
            {
              path: PATHS.DATA_SCHEMA,
              element: (
                <RoleGuard allowedRoles={[UserRoles.ADMIN]} redirectTo={PATHS.PAGE_NOT_FOUND}>
                  <DataSchema />
                </RoleGuard>
              ),
            },

            {
              path: PATHS.SOURCES,
              element: (
                <RoleGuard allowedRoles={[UserRoles.ADMIN]} redirectTo={PATHS.PAGE_NOT_FOUND}>
                  <div>{t`Sources`}</div>
                </RoleGuard>
              ), // TODO: Create Sources page
            },
            {
              path: PATHS.KNOWLEDGE_LIBRARY,
              element: (
                <RoleGuard
                  allowedRoles={[UserRoles.ADMIN]}
                  redirectTo={PATHS.PAGE_NOT_FOUND}
                  requiredFeatureFlags={[DATA_CONTEXT]}
                >
                  <KnowledgeLibrary />
                </RoleGuard>
              ),
            },
            {
              path: PATHS.ORGANIZATION,
              element: (
                <RoleGuard
                  allowedRoles={[UserRoles.ADMIN]}
                  redirectTo={PATHS.PAGE_NOT_FOUND}
                  requiredFeatureFlags={[RBAC_ORGANIZATION]}
                >
                  <Organization />
                </RoleGuard>
              ),
            },
            {
              path: `${PATHS.USERS}/:${PATH_PARAMS.USER_ID}`,
              element: <Outlet />,
              children: [
                {
                  path: PATHS.HOME,
                  element: (
                    <RoleGuard allowedRoles={[UserRoles.ADMIN]} redirectTo={PATHS.PAGE_NOT_FOUND}>
                      <Home />
                    </RoleGuard>
                  ),
                },
                {
                  path: PATHS.PINBOARDS,
                  element: <Outlet />,
                  children: [
                    {
                      path: ``,
                      element: <Pinboards />,
                    },
                    {
                      path: `:${PATH_PARAMS.PINBOARD_ID}`,
                      element: <Pinboard />,
                      handle: {
                        crumbs: [
                          {
                            text: t`Pinboards`,
                            link: (params: Readonly<Params>) =>
                              `/${PATHS.USERS}/${params.userId}/${PATHS.PINBOARDS}`,
                          },
                          {
                            dynamicText: (params: Readonly<Params>) =>
                              params.pinboardId
                                ? boards.get(params.pinboardId)?.name
                                : t`Undefined`,
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  path: PATHS.THREADS,
                  element: <Outlet />,
                  children: [
                    {
                      path: ``,
                      element: (
                        <RoleGuard allowedRoles={[UserRoles.ADMIN]}>
                          <Threads />{" "}
                        </RoleGuard>
                      ),
                    },
                    {
                      path: `:${PATH_PARAMS.THREAD_ID}`,
                      element: (
                        <RoleGuard allowedRoles={[UserRoles.ADMIN]}>
                          <Thread />
                        </RoleGuard>
                      ),
                      handle: {
                        crumbs: [
                          {
                            text: t`Threads`,
                            link: (params: Readonly<Params>) =>
                              `/${PATHS.USERS}/${params.userId}/${PATHS.THREADS}`,
                          },
                          {
                            dynamicText: (params: Readonly<Params>) =>
                              threads.reduce(
                                (acc, thread) =>
                                  thread.id === params.threadId ? thread.title : acc,
                                t`New Chat`
                              ),
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: PATHS.RESET_PASSWORD,
          element: <ResetPassword />,
          errorElement: <ErrorPage />,
          action: resetPasswordAction,
        },
        {
          path: PATHS.SIGN_IN,
          element: <SignIn />,
          errorElement: <ErrorPage />,
          action: signInAction,
        },
        {
          path: PATHS.REGISTER,
          element: <Register />,
          errorElement: <ErrorPage />,
          action: registerAction,
        },
        {
          path: PATHS.SIGN_UP,
          element: <SignUp />,
          errorElement: <ErrorPage />,
          action: signUpAction,
        },
        {
          path: PATHS.VERIFY_EMAIL,
          element: <VerifyEmail />,
          errorElement: <ErrorPage />,
          action: verifyEmailAction,
        },
        {
          path: PATHS.ONBOARDING,
          element: <Onboarding />,
          // action: settingsAction,
        },
        {
          path: PATHS.PAGE_NOT_FOUND,
          element: <NotFoundPage />,
        },
      ]),
    [t, boards, threads]
  );
};

export default useRouter;
