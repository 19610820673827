import { PinboardAccessLevel } from "@doowii-types/auth";
import { css } from "@styled-system/css";

export const gridLayoutStyle = css({
  position: "relative",
  transition: "all 0.5s ease-in",

  "& > .react-grid-item.react-grid-placeholder": {
    background: "gray",
    opacity: 0.1,
    transitionDuration: "100ms",
    zIndex: 2,
    userSelect: "none",
  },
});

export const getGridItemStyle = (accessLevel: PinboardAccessLevel) =>
  css({
    transition: "all 200ms ease",
    transitionProperty: "left, top",
    "&.cssTransforms": {
      transitionProperty: "transform",
    },
    "&:not(.react-grid-placeholder)": {
      userSelect: "none",
    },
    "&.resizing": {
      opacity: 0.9,
      zIndex: 1,
      willChange: "width, height",
    },
    "&.react-draggable-dragging": {
      transition: "none",
      zIndex: 3,
      willChange: "transform",
    },
    "& > .react-resizable-handle": {
      display: accessLevel === PinboardAccessLevel.OWNER ? "block" : "none",
      position: "absolute",
      width: "20px",
      height: "20px",
      bottom: "0",
      right: "0",
      background:
        'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjODA4MDgwIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xMyAwYzAgNy4xOC01LjgyIDEzLTEzIDEzIi8+PC9zdmc+")',
      backgroundPosition: "bottom right",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      boxSizing: "border-box",
      cursor: "se-resize",
    },
    "& > div": {
      height: "100%",
      width: "100%",
      display: "flex",
    },
  });

export const widgetContainerStyle = css({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "stretch",
  justifyContent: "center",
});
