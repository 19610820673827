import { Aggregations } from "@doowii-types/chart";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { useLingui } from "@lingui/react/macro";
import { Aggregations as GeneratedAggregations } from "@services/api/generated/webserver/models/aggregations";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "doowii-ui";
import React from "react";

interface AggregationDropdownProps {
  aggregationType: Aggregations | GeneratedAggregations;
  onAggregationChange: (type: Aggregations) => void;
}

const AggregationDropdown: React.FC<AggregationDropdownProps> = ({
  aggregationType,
  onAggregationChange,
}) => {
  const { t } = useLingui();
  const aggregationOptions: { label: string; value: Aggregations }[] = [
    { label: t`Sum`, value: Aggregations.SUM },
    { label: t`Count`, value: Aggregations.COUNT },
    { label: t`Mean`, value: Aggregations.MEAN },
    { label: t`Min`, value: Aggregations.MIN },
    { label: t`Max`, value: Aggregations.MAX },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button iconRight={faChevronDown} size="xsmall" variant="dropdown">
          {aggregationType
            ? aggregationType.charAt(0).toUpperCase() + aggregationType.slice(1)
            : t`Aggregate`}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {aggregationOptions.map((option) => (
          <DropdownMenuItem key={option.value} onClick={() => onAggregationChange(option.value)}>
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { AggregationDropdown };
