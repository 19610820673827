import { DateTime } from "luxon";

export const firebaseTimestampToLuxon = (firebaseTimestamp: {
  seconds: number;
  nanoseconds: number;
}) => {
  const asNumbers = firebaseTimestamp.seconds * 1000 + firebaseTimestamp.nanoseconds / 1000000;
  return DateTime.fromMillis(asNumbers);
};
export const firebaseTimestampToDate = (
  firebaseTimestamp:
    | {
        seconds: number;
        nanoseconds: number;
      }
    | null
    | undefined
) => {
  if (!firebaseTimestamp) {
    return null;
  }
  const asNumbers = firebaseTimestamp.seconds * 1000 + firebaseTimestamp.nanoseconds / 1000000;
  return new Date(asNumbers);
};
