import { useResize } from "@hooks/useResize";
import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { ChartConfig, transformDataForChart } from "@utils/chartTransformations";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useMemo, useState } from "react";

import { ChartErrorState } from "./ChartErrorState";
import {
  Highcharts,
  mergeSavedDefaultChartOptions,
  parseFunctions,
  replacePlaceholders,
} from "./utils";

// Define a more specific type for chart data
interface ChartData {
  series: Highcharts.SeriesOptionsType[];
  [key: string]: unknown;
}

type ChartOptions = Record<string, unknown>;

interface DynamicChartRendererProps {
  dataObject: RetrieveAllResponse; // Chart data
  chartConfig: ChartConfig; // Chart configuration
}

const DynamicChartRenderer: React.FC<DynamicChartRendererProps> = ({ dataObject, chartConfig }) => {
  const [chartOptions, setChartOptions] = useState<ChartOptions | null>(null);

  const [error, setError] = useState<Error | null>(null);
  const { dimensions, componentRef } = useResize();

  // Memoize the transformed chart data
  const chartData = useMemo(() => {
    try {
      return transformDataForChart(dataObject, chartConfig);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Error processing chart data"));
      console.error("Error transforming data for chart", err);
      return null;
    }
  }, [dataObject, chartConfig]);

  // Process data for chart visualization
  useEffect(() => {
    try {
      const { height, width } = dimensions;
      if (!chartData || !height || !width) {
        return;
      }

      const chartType = chartConfig?.suggestion;
      const savedOptions = chartConfig?.options?.config || {};
      // tech debt: remove these once we have a way to handle them but leave pie options
      delete savedOptions.plotOptions?.bar;
      delete savedOptions.plotOptions?.scatter;
      delete savedOptions.plotOptions?.line;

      const combinedOptions = mergeSavedDefaultChartOptions({
        chartType,
        savedOptions,
      });

      combinedOptions.chart.height = height;
      combinedOptions.chart.width = width;

      const optionsWithData = replacePlaceholders(combinedOptions, chartData);
      const parsedOptions = parseFunctions(optionsWithData) as ChartOptions;
      setChartOptions(parsedOptions);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Error processing chart data"));
      console.error("Error processing chart data", err);
    }
  }, [chartConfig, chartData, dimensions]);

  // Error state
  if (error) {
    return <ChartErrorState />;
  }

  // Render chart
  return (
    <Box
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflow: "hidden", // required to avoid layout shift
        p: 3,
      })}
      data-testid="dynamic-chart-renderer"
      ref={componentRef}
    >
      {chartOptions ? (
        <HighchartsReact
          data-testid="highcharts-react-wrapper"
          highcharts={Highcharts}
          immutable
          options={chartOptions}
        />
      ) : null}
    </Box>
  );
};

export { DynamicChartRenderer };
export type { ChartData };
