/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Supported aggregation functions.

Functions that can be applied to aggregate numerical data:
- SUM: Total of values
- MEAN: Average of values
- COUNT: Number of values
- MAX: Maximum value
- MIN: Minimum value
 */
export enum Aggregations {
  sum = "sum",
  mean = "mean",
  count = "count",
  max = "max",
  min = "min",
}
