import { UserRoles } from "@doowii-types/auth";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import {
  InputField,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "doowii-ui";

interface Props {
  email: string;
  setEmail: (email: string) => void;
  role: UserRoles;
  setRole: (role: UserRoles) => void;
  loading: boolean;
  errorMessage: string;
  // More props to be added with business logic
}
const NewUserInviteForm: React.FC<Props> = ({
  email,
  setEmail,
  role,
  setRole,
  loading,
  errorMessage,
}) => {
  const { t } = useLingui();

  const handleRoleChange = (value: UserRoles) => {
    setRole(value);
  };

  return (
    <Flex className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
      <InputField
        disabled={loading}
        errorText={errorMessage}
        label={t`Email`}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t`Enter email`}
        type="text"
        value={email}
      />

      <Box>
        <Label level={1}>{t`Role`}</Label>
        <Select
          disabled={loading}
          name={t`Role Selector`}
          onValueChange={handleRoleChange}
          value={role}
        >
          <SelectTrigger>
            <SelectValue placeholder={t`Select`} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value={UserRoles.ADMIN}>
                <Trans>Admin</Trans>
              </SelectItem>
              <SelectItem value={UserRoles.VIEWER}>
                <Trans>Viewer</Trans>
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </Box>
    </Flex>
  );
};

export { NewUserInviteForm };
