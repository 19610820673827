import { ParentDocTypeEnum } from "@api/retriever.i";
import { ChartBuilderDialog } from "@components/ChartBuilder";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import { PinboardAccessLevel } from "@doowii-types/auth";
import { Pin } from "@doowii-types/pinboard";
import {
  faDownload,
  faEllipsisVertical,
  faExpand,
  faMessageLines,
  faPen,
  faShare,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { downloadData } from "@utils/downloadData";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
} from "doowii-ui";
import { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DeletePinDialog } from "./DeletePinDialog";
import { EditPinDialog } from "./EditPinDialog";
import { FullScreenDialog } from "./FullScreenDialog";

interface Props {
  pin: Pin;
  setIsShareResultDialogOpen: (isOpen: boolean) => void;
  setChartBuilderDialogOpen: (isOpen: boolean) => void;
  accessLevel: PinboardAccessLevel;
}
const PinDropdown: React.FC<Props> = ({
  pin,
  setIsShareResultDialogOpen,
  setChartBuilderDialogOpen,
  accessLevel,
}) => {
  const id = useId();
  const { t } = useLingui();
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { navigateToChatFromPin, isChatAvailable } = useChatData();
  const [isEditPinDialogOpen, setIsEditPinDialogOpen] = useState(false);
  const [isDeletePinDialogOpen, setIsDeletePinDialogOpen] = useState(false);
  const { currBoardId } = usePinboard();
  const [showViewChat, setShowViewChat] = useState(false);
  const handleDownloadData = async () => {
    await downloadData({
      docId: pin.id,
      parentDocId: pin.boardId || currBoardId,
      parentDocType: ParentDocTypeEnum.PINBOARD,
      filename: `${pin.title}.csv`,
    });
  };

  const handleNavigateToThread = async () => {
    const threadId = await navigateToChatFromPin(pin.id);
    if (threadId) {
      navigate(`/${PATHS.USERS}/${authUser.uid}/${PATHS.THREADS}/${threadId}/#${pin.id}`);
    } else {
      console.error(`No thread found for this pin`, { pin });
    }
  };

  useEffect(() => {
    (async () => {
      const chatAvailable = await isChatAvailable(pin.id);
      setShowViewChat(chatAvailable);
    })();
  }, [pin, isChatAvailable]);

  const triggerId = `${id}-trigger`;

  return (
    <>
      <DropdownMenu modal={false}>
        <Tooltip content={t`Pin options`}>
          <DropdownMenuTrigger asChild>
            <Button
              aria-label={t`Pin options`}
              iconOnly={faEllipsisVertical}
              id={triggerId}
              size="small"
              variant="dropdown"
            />
          </DropdownMenuTrigger>
        </Tooltip>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => setChartBuilderDialogOpen(true)}>
              <FontAwesomeIcon icon={faGear} />
              <Trans>Chart Builder</Trans>
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => handleDownloadData()}>
              <FontAwesomeIcon icon={faDownload} />
              <Trans>Download data</Trans>
            </DropdownMenuItem>
            {accessLevel === PinboardAccessLevel.OWNER ? (
              <>
                <DropdownMenuItem
                  onClick={() => {
                    setIsEditPinDialogOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                  <Trans>Edit pin</Trans>
                </DropdownMenuItem>

                {/* TODO: Enable when move is enabled. 
            <DropdownMenuItem>
              <FontAwesomeIcon icon={faTurnDownRight} />
              <Trans>Move to other pinboard</Trans>
            </DropdownMenuItem>  */}

                <DropdownMenuItem disabled={!showViewChat} onClick={() => handleNavigateToThread()}>
                  <FontAwesomeIcon icon={faMessageLines} />
                  <Trans>View chat</Trans>
                </DropdownMenuItem>

                <DropdownMenuItem onClick={() => setIsShareResultDialogOpen(true)}>
                  <FontAwesomeIcon icon={faShare} />
                  <Trans>Share result</Trans>
                </DropdownMenuItem>

                <DropdownMenuItem onClick={() => setIsDeletePinDialogOpen(true)}>
                  <FontAwesomeIcon icon={faTrash} />
                  <Trans>Delete</Trans>
                </DropdownMenuItem>
              </>
            ) : null}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      {accessLevel === PinboardAccessLevel.OWNER ? (
        <>
          <EditPinDialog
            initialPinDescription={pin.description}
            initialPinTitle={pin.title}
            isOpen={isEditPinDialogOpen}
            pinId={pin.id}
            pinboardId={pin.boardId || currBoardId}
            setIsOpen={setIsEditPinDialogOpen}
            triggerId={triggerId}
          />
          <DeletePinDialog
            boardId={pin.boardId || currBoardId}
            isOpen={isDeletePinDialogOpen}
            pinId={pin.id}
            setIsOpen={setIsDeletePinDialogOpen}
            triggerId={triggerId}
          />
        </>
      ) : null}
    </>
  );
};
export { PinDropdown };
