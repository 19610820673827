import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/react/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { Card, Heading, Separator, Text } from "doowii-ui";
import { useState } from "react";
const TipsCard = () => {
  const [open, setOpen] = useState(false);
  return (
    <Card>
      <Collapsible.Root className={css({ w: "100%" })} onOpenChange={setOpen} open={open}>
        <Collapsible.Trigger className={css({ w: "100%", cursor: "pointer" })}>
          <Flex align="center" justify="space-between">
            <Heading level={3}>
              <Trans>Tips on effective prompt composition</Trans>
            </Heading>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="lg" />
          </Flex>
        </Collapsible.Trigger>

        <Collapsible.Content>
          <Separator className={css({ my: "4" })} colorful={false} />
          <Flex align="center" direction="column" gap="xl">
            <Box>
              <Heading level={3}>
                <Trans>Be Specific</Trans>
              </Heading>
              <Text>
                <Trans>
                  When referring to an ID or number, qualify that item with what the data point is
                  related to (student ID, teacher ID, grade, score, etc).
                </Trans>
              </Text>
            </Box>

            <Box>
              <Heading level={3}>
                <Trans>Time Frame</Trans>
              </Heading>
              <Text>
                <Trans>
                  If no date range is specified, by default we will use the most recent data
                  available. Please note the year for the most recent data available may differ from
                  field to field, so not all multi-year queries will yield a result.
                </Trans>
              </Text>
            </Box>

            <Box>
              <Heading level={3}>
                <Trans>Interpret</Trans>
              </Heading>
              <Text>
                <Trans>
                  Don’t ask about vague measures such as “student performance” or “student
                  participation”. Create interpretable values by specifying metrics, such as
                  “student performance by achievement level” or “student participation by number of
                  days attended”.
                </Trans>
              </Text>
            </Box>
          </Flex>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  );
};

export { TipsCard };
