/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { GeneratePinDescriptionResponse } from "../../models/generatePinDescriptionResponse";
import type { HTTPValidationError } from "../../models/hTTPValidationError";

/**
 * Generate an engaging description for a pin based on its title and optional content.

This endpoint uses generative AI to create a compelling description
for a pin that can help increase engagement and discoverability.
 * @summary Create Pin Description
 */
export const createPinDescription = (pinboardId: string, pinId: string, signal?: AbortSignal) =>
  webserverAxios<GeneratePinDescriptionResponse>({
    url: `/api/generative_ai/pin_description/${pinboardId}/${pinId}`,
    method: "POST",
    signal,
  });

export const getCreatePinDescriptionMutationOptions = <
  TData = Awaited<ReturnType<typeof createPinDescription>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { pinboardId: string; pinId: string }, TContext>;
}) => {
  const mutationKey = ["createPinDescription"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPinDescription>>,
    { pinboardId: string; pinId: string }
  > = (props) => {
    const { pinboardId, pinId } = props ?? {};

    return createPinDescription(pinboardId, pinId);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; pinId: string },
    TContext
  >;
};

export type CreatePinDescriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPinDescription>>
>;

export type CreatePinDescriptionMutationError = HTTPValidationError;

/**
 * @summary Create Pin Description
 */
export const useCreatePinDescription = <
  TData = Awaited<ReturnType<typeof createPinDescription>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { pinboardId: string; pinId: string }, TContext>;
}): UseMutationResult<TData, TError, { pinboardId: string; pinId: string }, TContext> => {
  const mutationOptions = getCreatePinDescriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
