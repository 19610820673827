/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { InviteUserRequest } from "../../models/inviteUserRequest";
import type { InviteUserResponse } from "../../models/inviteUserResponse";
import type { SignInRequest } from "../../models/signInRequest";
import type { SignInResponse } from "../../models/signInResponse";
import type { VerifyEmailCodeRequest } from "../../models/verifyEmailCodeRequest";
import type { VerifyEmailCodeResponse } from "../../models/verifyEmailCodeResponse";

/**
 * @summary Signin
 */
export const signin = (signInRequest: SignInRequest, signal?: AbortSignal) =>
  webserverAxios<SignInResponse>({
    url: `/api/users/signin`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: signInRequest,
    signal,
  });

export const getSigninMutationOptions = <
  TData = Awaited<ReturnType<typeof signin>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SignInRequest }, TContext>;
}) => {
  const mutationKey = ["signin"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signin>>,
    { data: SignInRequest }
  > = (props) => {
    const { data } = props ?? {};

    return signin(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: SignInRequest },
    TContext
  >;
};

export type SigninMutationResult = NonNullable<Awaited<ReturnType<typeof signin>>>;
export type SigninMutationBody = SignInRequest;
export type SigninMutationError = HTTPValidationError;

/**
 * @summary Signin
 */
export const useSignin = <
  TData = Awaited<ReturnType<typeof signin>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SignInRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: SignInRequest }, TContext> => {
  const mutationOptions = getSigninMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update both the user's role and allowed applications in a single request.

- Updates the user's custom role based on their Firestore role.
- Updates the allowedApps custom claim based on organization type.

Args:
    current_user (AuthUserTuple): The current authenticated user.

Returns:
    UpdateUserResponse: The updated user details.

Raises:
    HTTPException: 404 if user data is not found, 500 if an error occurs.
 * @summary Update User Token
 */
export const updateUserToken = (signal?: AbortSignal) =>
  webserverAxios<unknown>({ url: `/api/users/update_user_token`, method: "POST", signal });

export const getUpdateUserTokenMutationOptions = <
  TData = Awaited<ReturnType<typeof updateUserToken>>,
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>;
}) => {
  const mutationKey = ["updateUserToken"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserToken>>, void> = () =>
    updateUserToken();

  return { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError, void, TContext>;
};

export type UpdateUserTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserToken>>
>;

export type UpdateUserTokenMutationError = unknown;

/**
 * @summary Update User Token
 */
export const useUpdateUserToken = <
  TData = Awaited<ReturnType<typeof updateUserToken>>,
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>;
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getUpdateUserTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Invite a user to an organization.
 * @summary Invite User
 */
export const inviteUser = (inviteUserRequest: InviteUserRequest, signal?: AbortSignal) =>
  webserverAxios<InviteUserResponse>({
    url: `/api/users/invite_user`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: inviteUserRequest,
    signal,
  });

export const getInviteUserMutationOptions = <
  TData = Awaited<ReturnType<typeof inviteUser>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: InviteUserRequest }, TContext>;
}) => {
  const mutationKey = ["inviteUser"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviteUser>>,
    { data: InviteUserRequest }
  > = (props) => {
    const { data } = props ?? {};

    return inviteUser(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: InviteUserRequest },
    TContext
  >;
};

export type InviteUserMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUser>>>;
export type InviteUserMutationBody = InviteUserRequest;
export type InviteUserMutationError = HTTPValidationError;

/**
 * @summary Invite User
 */
export const useInviteUser = <
  TData = Awaited<ReturnType<typeof inviteUser>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: InviteUserRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: InviteUserRequest }, TContext> => {
  const mutationOptions = getInviteUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Checks the code to verify user email upon sign-up
 * @summary Verify Code
 */
export const verifyCode = (verifyEmailCodeRequest: VerifyEmailCodeRequest, signal?: AbortSignal) =>
  webserverAxios<VerifyEmailCodeResponse>({
    url: `/api/users/verify-code`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: verifyEmailCodeRequest,
    signal,
  });

export const getVerifyCodeMutationOptions = <
  TData = Awaited<ReturnType<typeof verifyCode>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: VerifyEmailCodeRequest }, TContext>;
}) => {
  const mutationKey = ["verifyCode"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verifyCode>>,
    { data: VerifyEmailCodeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return verifyCode(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: VerifyEmailCodeRequest },
    TContext
  >;
};

export type VerifyCodeMutationResult = NonNullable<Awaited<ReturnType<typeof verifyCode>>>;
export type VerifyCodeMutationBody = VerifyEmailCodeRequest;
export type VerifyCodeMutationError = HTTPValidationError;

/**
 * @summary Verify Code
 */
export const useVerifyCode = <
  TData = Awaited<ReturnType<typeof verifyCode>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: VerifyEmailCodeRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: VerifyEmailCodeRequest }, TContext> => {
  const mutationOptions = getVerifyCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
