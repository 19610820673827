import { usePinboard } from "@context/pinboard";
import { PinboardAccessLevel } from "@doowii-types/auth";
import { Flex } from "@doowii/design-system/jsx";
import { useAuth } from "@hooks/useAuth";
import { Plural, Trans, useLingui } from "@lingui/react/macro";
import { PinboardsView } from "@pages/Pinboards";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import { Badge, Button, CircularCounter, Heading, Text, Tooltip } from "doowii-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PinboardDoc } from "@services/api/generated/webserver/models/pinboardDoc";
import PinboardCardDropdown from "./components/PinboardCardDropdown";
import PinboardCardImage from "./components/PinboardCardImage";
import { PinboardCardSuggestions } from "./components/PinboardCardSuggestions";

const ElipsisTextStyles = {
  display: "-webkit-box",
  overflow: "hidden",
  "-webkit-line-clamp": 2,
  "-webkit-box-orient": "vertical",
};

// Fixed height for the description area
const DESCRIPTION_HEIGHT = "3rem";

interface Props {
  view: PinboardsView;
  board: PinboardDoc;
}
const PinboardCard: React.FC<Props> = ({ view, board }) => {
  const { t } = useLingui();
  const { setCurrBoardId } = usePinboard();
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const isGrid = view === "grid";
  const accessLevel: PinboardAccessLevel =
    board.created_by === authUser?.uid ? PinboardAccessLevel.OWNER : PinboardAccessLevel.VIEWER;
  const totalShares: number = board.can_edit.length + board.can_view.length;
  const boardName = board.name;
  const hasDescription = Boolean(board.description);

  return (
    <Box
      aria-label={t`Open board ${boardName}`}
      className={css({
        flexDirection: "column",
        position: "relative",
        p: "ld",
        pt: "md",
        bg: "white",
        maxW: isGrid ? 534 : "auto",
        maxH: ["auto", isGrid ? 470 : "auto"],
        borderRadius: "xl",
        boxShadow: "regular",
        gap: "ld",
        cursor: "pointer",
      })}
      onClick={() => {
        setCurrBoardId(board.id);
        // Avoid blinking when navigating
        setTimeout(() => {
          navigate(`${board.id}`);
        }, 100);
      }}
    >
      <Flex
        css={{
          flexDirection: isGrid ? "column" : "row",
          gap: "ld",
          alignItems: isGrid ? "" : "center",
          justifyContent: "flex-start",
        }}
      >
        <Flex css={{ flexDirection: "column", gap: "xs", order: isGrid ? 1 : 2, flex: 1 }}>
          <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
            <Tooltip content={board.name}>
              <Box>
                <Heading css={ElipsisTextStyles} level={2}>
                  {board.name}
                </Heading>
              </Box>
            </Tooltip>
            {accessLevel === PinboardAccessLevel.OWNER ? (
              <Box>
                <PinboardCardDropdown board={board} />
              </Box>
            ) : null}
          </Flex>
          {hasDescription && (
            <Box css={{ height: DESCRIPTION_HEIGHT }}>
              {hasDescription && <Text level={2}>{board.description}</Text>}
            </Box>
          )}

          {/* Counter and badges */}
          <Flex gap={"sm"}>
            <Flex alignItems="center" gap="sm">
              <CircularCounter counter={board.pins.length || 0} />
              <Text level={3}>
                <Trans>Responses pinned to this board.</Trans>
              </Text>
            </Flex>
            <Badge withIcons={false}>
              {accessLevel === PinboardAccessLevel.OWNER ? t`Owner` : t`Viewer`}
            </Badge>
            {totalShares > 0 ? (
              <Badge withIcons={false}>
                <Plural
                  one="Shared with # person"
                  other="Shared with # people"
                  value={totalShares}
                />
              </Badge>
            ) : null}
          </Flex>
          {!hasDescription && <Box css={{ height: DESCRIPTION_HEIGHT }} />}
        </Flex>
        <Box css={{ order: isGrid ? 2 : 1 }}>
          {/* TODO: Replace with real image once it is available */}
          <PinboardCardImage imgUrl="https://github.com/shadcn.png" isGrid={isGrid} />
        </Box>
      </Flex>
    </Box>
  );
};

export { PinboardCard };
