import { usePinboard } from "@context/pinboard";
import { useOrgUsers } from "@context/users";
import { UserRoles } from "@doowii-types/auth";
import { useAuth } from "@hooks/useAuth";
import { useModalClose } from "@hooks/useModalClose";
import { Trans, useLingui } from "@lingui/react/macro";
import { createInvitation } from "@services/firebase";
import { css } from "@styled-system/css";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TabContent,
  Tabs,
  useToast,
} from "doowii-ui";
import { useEffect, useState } from "react";

import { ExistingUsersChecklist } from "./ExistingUsersChecklist";
import { NewUserInviteForm } from "./NewUserInviteForm";
import { checkSharedUsersIsEqual, validateEmail } from "./utils";

interface Props {
  isOpen: boolean;
  boardId: string;
  boardName: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}

const enum Tab {
  EXISTING_USERS = "existing_users",
  NEW_USER = "new_user",
}

const SharePinboardDialog: React.FC<Props> = ({
  isOpen,
  boardId,
  boardName,
  triggerId,
  setIsOpen,
}) => {
  useModalClose(isOpen, triggerId);
  const { t } = useLingui();
  const { toast } = useToast();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<UserRoles>(UserRoles.ADMIN);
  const [sharedUsers, setSharedUsers] = useState<string[]>([]);
  const [tab, setTab] = useState<Tab>(Tab.EXISTING_USERS);
  const { boards, sharePinboardViewOnly } = usePinboard();
  const { userDocument } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { organizationUsers, disabledUsers, fetchUsersInOrganization, clearUsersData } =
    useOrgUsers();

  const tabItems = [
    { value: Tab.EXISTING_USERS, label: t`Existing Users` },
    { value: Tab.NEW_USER, label: t`New user` },
  ];

  useEffect(() => {
    void fetchUsersInOrganization();
    setSharedUsers(boards.get(boardId).can_view);

    return () => {
      clearUsersData();
      setEmail("");
      setErrorMessage("");
      setRole(UserRoles.ADMIN);
    };
    // we do not want to run again on boards change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, boardId, fetchUsersInOrganization, clearUsersData]);

  const shareWithExistingUsers = async () => {
    await sharePinboardViewOnly(boardId, sharedUsers);
    toast({
      title: t`Access Updated`,
      description: t`Pinboard access updated successfully`,
      status: "success",
    });
  };

  const shareWithNewUser = async () => {
    if (!validateEmail(email)) {
      setErrorMessage(t`Please enter a valid email address.`);
    } else if (organizationUsers.find((user) => user.email === email.toLowerCase())) {
      setErrorMessage(t`User already exists in organization.`);
    } else if (disabledUsers.find((user) => user.email === email.toLowerCase())) {
      setErrorMessage(t`User has been disabled.`);
    } else {
      try {
        const res = await createInvitation(userDocument.organization, email.toLowerCase(), role, [
          boardId,
        ]);
        if (res.code === 409) {
          setErrorMessage(t`User has already been invited or exists.`);
        } else {
          toast({
            title: t`Invitation sent`,
            description: t`Invitation has been sent successfully`,
            status: "success",
          });

          fetchUsersInOrganization();
          setIsOpen(false);
        }
      } catch (e) {
        toast({
          title: t`Error sending invitation`,
          description: t`An error occurred while sending the invitation.`,
          status: "error",
        });
      }
    }
  };

  const handleShare = async () => {
    setLoading(true);

    try {
      if (tab === Tab.NEW_USER) {
        await shareWithNewUser();
      } else {
        await shareWithExistingUsers();
      }
    } catch (e) {
      toast({
        title: t`An error occurred`,
        description: t`Error sharing pinboard`,
        status: "error",
      });
    }
    setLoading(false);
  };

  const isShareDisabled = () => {
    if (tab === Tab.NEW_USER) {
      return !validateEmail(email);
    }
    return checkSharedUsersIsEqual(sharedUsers, boards.get(boardId).can_view);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Share pinboard</Trans>
          </DialogTitle>
          <DialogDescription>
            <Trans>
              Share <b>{boardName}</b> with your team
            </Trans>
          </DialogDescription>
        </DialogHeader>
        <DialogBody
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "md",
          })}
        >
          <Tabs
            className={css({ marginTop: "-1.5rem" })}
            defaultValue={Tab.EXISTING_USERS}
            items={tabItems}
            onValueChange={(tab) => {
              setTab(tab as Tab);
            }}
          >
            {" "}
            <TabContent value={Tab.NEW_USER}>
              <NewUserInviteForm
                email={email}
                errorMessage={errorMessage}
                loading={loading}
                role={role}
                setEmail={setEmail}
                setRole={setRole}
              />{" "}
            </TabContent>
            <TabContent value={Tab.EXISTING_USERS}>
              <ExistingUsersChecklist
                organizationUsers={organizationUsers}
                setSharedUsers={setSharedUsers}
                sharedUsers={sharedUsers}
              />{" "}
            </TabContent>
          </Tabs>
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button
            disabled={isShareDisabled()}
            loading={loading}
            onClick={stopPropagationWrapper(handleShare)}
            size="large"
            variant="primary"
          >
            <Trans>Share</Trans>
          </Button>
          <Button
            disabled={loading}
            onClick={stopPropagationWrapper(setIsOpen.bind(null, false))}
            size="large"
            variant="secondary"
          >
            <Trans>Cancel</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { SharePinboardDialog };
