import { useLingui } from "@lingui/react/macro";
import * as Sentry from "@sentry/react";
import { Flex } from "@styled-system/jsx";
import { Button, Heading, Text } from "doowii-ui";
import React, { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const error = useRouteError();
  const { t } = useLingui();

  useEffect(() => {
    if (error) {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "frontend-error");
        scope.setLevel("error");
        Sentry.captureException(error);
      });
    }
  }, [error]);

  return (
    <Flex
      alignItems="center"
      css={{
        backgroundImage: `url(/error.webp)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "100vh",
      }}
      justifyContent="center"
    >
      <Flex alignItems="center" css={{ marginLeft: "54px" }} direction="column" gap="md">
        <Heading level={1}>{t`Oops!`}</Heading>
        <Text level={3}>{t`Looks like we are experiencing some difficulties.`}</Text>
        <Text>{t`Don't worry the doowers are on it!`}</Text>
        <Button onClick={() => navigate("/")}>{t`Return to Home`}</Button>
      </Flex>
    </Flex>
  );
};

export { ErrorPage };
