import { TipsModal } from "@components/TipsModal";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { useDoowii } from "@context/doowii/doowii";
import { css } from "@doowii/design-system/css";
import {
  faMagnifyingGlass,
  faMessageQuestion,
  faPaperPlaneTop,
  faStars,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { Box, Flex, HStack } from "@styled-system/jsx";
import { token } from "@styled-system/tokens";
import { Button } from "doowii-ui";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

const ChatSearchBar: React.FC<Props> = (props: Props) => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const { t } = useLingui();

  const placeholder = t`How can doowii help you today?`;

  const inputRef = useRef<HTMLInputElement>(null);
  const doowii = useDoowii();
  const { authUser } = useAuth();
  const { answer, allResults, refetchSuggestions, fetchingSuggestions, dynamicCache, newChat } =
    useChatData();

  const handleSubmitClick = async () => {
    if (inputRef.current) {
      inputRef.current.placeholder = placeholder;
    }
    setInputValue("");

    await doowii.chat({
      query: inputValue,
      index: allResults.length,
    });
  };
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handleSubmitClick();
    }
  };

  const handleGenerateQuestion = async () => {
    await refetchSuggestions();

    if (!fetchingSuggestions && dynamicCache.length > 0) {
      setInputValue(dynamicCache[0]);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleNewChat = () => {
    const newThreadId = newChat();
    navigate(`/${PATHS.USERS}/${authUser.uid}/${PATHS.THREADS}/${newThreadId}`);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <Box
      className={css({
        maxH: "28",
        px: "10",
        py: "4",
      })}
    >
      <Flex align="center" gap="4" justify="center">
        <Flex
          align="center"
          className={css({
            height: "14",
            rounded: "full",
            border: "2px solid token(colors.base.gray)",
          })}
          flexGrow={1}
          gap="4"
          justify="center"
          p="4"
        >
          <FontAwesomeIcon color={token("colors.base.gray")} icon={faMagnifyingGlass} />

          <input
            className={css({
              flexGrow: 1,
              textStyle: "paragraph_16",
              fontWeight: "medium",
              color: "base.gray",
              overflow: "hidden",
              _focus: {
                outline: "none",
                color: "base.darkBlue",
              },
            })}
            name="chat input"
            onBlur={(e) => (e.target.placeholder = placeholder)}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={(e) => (e.target.placeholder = "")}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            ref={inputRef}
            type="text"
            value={inputValue}
          />

          {inputValue ? (
            <Button
              iconOnly={faPaperPlaneTop}
              onClick={handleSubmitClick}
              onKeyDown={(e) => e.key === "Enter" && handleSubmitClick()}
              size="small"
              variant="tertiary"
            />
          ) : null}
        </Flex>
        <HStack gap="4" height="10">
          {allResults.length === 0 ? (
            <TipsModal full={false} />
          ) : (
            <Button
              iconLeft={faMessageQuestion}
              onClick={handleNewChat}
              size="small"
              variant="tertiary"
            >
              <Trans>New Chat</Trans>
            </Button>
          )}

          <Button
            iconLeft={faStars}
            onClick={() => {
              handleGenerateQuestion();
            }}
            size="small"
            variant="tertiary"
          >
            <Trans>Generate Question</Trans>
          </Button>
        </HStack>
      </Flex>
    </Box>
  );
};

export { ChatSearchBar };
