import { ParentDocTypeEnum } from "@api/retriever.i";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Trans, useLingui } from "@lingui/react/macro";
import { css, cx } from "@styled-system/css";
import { flex } from "@styled-system/patterns";
import { Button, Text } from "doowii-ui";

const PaginationControl = ({ pagination, dataQuery, setPagination, table, type }) => {
  const { t } = useLingui();
  return (
    <div
      className={cx(
        flex({
          direction: "row",
          justify: "space-between",
          align: "center",
        }),
        css({
          px: 4,
          py: "md",
          position: "sticky",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 50,
          backgroundColor: "base.white",
          borderTop: "1px solid",
          borderColor: "base.lightGray",
          boxShadow: "regular",
          width: "100%",
          minWidth: "max-content",
        })
      )}
    >
      <Text>
        <Trans>
          {pagination.pageSize * pagination.pageIndex + 1} -{" "}
          {Math.min(
            pagination.pageSize * (pagination.pageIndex + 1),
            dataQuery.data?.rowCount ?? 0
          )}{" "}
          of {dataQuery.data?.rowCount?.toLocaleString() ?? 0} results{" "}
        </Trans>
      </Text>

      {dataQuery.isFetching ? (
        <Text>
          <Trans>Loading...</Trans>
        </Text>
      ) : null}

      <div
        className={cx(
          flex({
            direction: "row",
            justify: "space-between",
            align: "center",
          })
        )}
      >
        {type === ParentDocTypeEnum.THREAD ? (
          <Text>
            <Trans> Results per page </Trans>
            <select
              onChange={(e) => setPagination({ ...pagination, pageSize: Number(e.target.value) })}
              value={pagination.pageSize}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Text>
        ) : null}

        <Button
          aria-label={t`Previous page`}
          disabled={!table.getCanPreviousPage()}
          iconOnly={faChevronLeft}
          onClick={() => table.previousPage()}
          size="small"
          variant="tertiary"
        />

        <Button
          aria-label={t`Next page`}
          disabled={!table.getCanNextPage() || dataQuery.isFetching}
          iconOnly={faChevronRight}
          onClick={() => table.nextPage()}
          size="small"
          variant="tertiary"
        />
      </div>
    </div>
  );
};

export { PaginationControl };
