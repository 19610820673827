import { PATHS } from "@constants/Paths";
import { usePinboard } from "@context/pinboard";
import { useAuth } from "@hooks/useAuth";
import { Trans } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { Badge, Text } from "doowii-ui";
import { useNavigate } from "react-router-dom";

interface PinboardBadgesProps {
  chatId: string;
}
const PinboardBadges = ({ chatId }: PinboardBadgesProps) => {
  const { boards, unpinFromBoard, setCurrBoardId } = usePinboard();
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const handleRemove = async (boardId: string) => {
    await unpinFromBoard(chatId, boardId);
  };
  const navigateToPinboard = (boardId: string) => {
    setCurrBoardId(boardId);
    navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.PINBOARDS}/${boardId}`);
  };
  return (
    <Flex
      align="center"
      className={css({
        borderTop: "1px solid token(colors.base.lightGray)",
      })}
      gap="2"
      h="16"
      px="4"
      w="full"
    >
      <Text
        className={css({
          fontWeight: "medium",
        })}
        level={2}
      >
        <Trans>Result Pinned to:</Trans>
      </Text>
      {Array.from(boards.keys())
        .filter((key) => boards.get(key).pins.includes(chatId))
        .map((boardId, index) => (
          <Badge
            data-testid={`pinboard-badge-${boards.get(boardId).name}`}
            key={index}
            onNavigate={() => {
              navigateToPinboard(boardId);
            }}
            onRemove={() => handleRemove(boardId)}
            onRemoveTooltip="Remove from pinboard"
          >
            {boards.get(boardId).name}
          </Badge>
        ))}
    </Flex>
  );
};

export { PinboardBadges };
