import { DEFAULTS } from "@constants/Defaults";
import { type AuthUser, UserRoles } from "@doowii-types/auth";
import { updateUserToken } from "@services/api/generated/webserver/endpoints/users/users";
import { withSentry } from "@utils/wrapper";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import { UserDocument } from "../../types/user";
import { db } from "./connection";

/**
 * Creates a new Firestore document for a user registering for the first time.
 *
 * This function handles:
 * - Creating a new user document within the appropriate organization.
 * - Assigning the user to an organization based on an invitation (if present).
 * - Removing the invitation after successful registration.
 * - Updating the user's role by triggering an external endpoint.
 *
 * @param firstName - User's first name.
 * @param lastName - User's last name.
 * @param role - User's role within the organization.
 * @param designation - User's job designation.
 * @param otherDesignation - Additional designation (if applicable).
 * @param marketingOptIn - User's preference for marketing communications.
 * @param user - Authenticated user object from Firebase Auth.
 * @returns A Promise resolving with the newly created user document data.
 *
 * @throws Error if the user's email is null or if Firestore operations fail.
 */
const createNewUserDocument = withSentry(
  async (
    firstName: string,
    lastName: string,
    designation: UserDocument["designation"],
    otherDesignation: UserDocument["otherDesignation"],
    marketingOptIn: UserDocument["marketingOptIn"],
    user: AuthUser
  ) => {
    // Validate that the user's email exists
    if (!user.email) {
      throw new Error("User or user email is null");
    }

    try {
      // Query Firestore for invitations matching the user's email
      const invitationsCollectionRef = collection(db, "invitations");
      const emailQuery = query(invitationsCollectionRef, where("email", "==", user.email));
      const emailSnapshot = await getDocs(emailQuery);

      // Default organization ID, updated if an invitation exists
      let organizationId = DEFAULTS.ORGANIZATION_ID;
      let role: UserRoles = UserRoles.ADMIN;
      let pinboards: string[] = [];
      if (emailSnapshot.docs.length > 0) {
        const data = emailSnapshot.docs[0].data();
        organizationId = data.organization;
        role = data.role;
        pinboards = data?.pinboards ?? [];
      }

      // Prepare user document data
      const newUserDocData = {
        firstName,
        lastName,
        email: user.email,
        organization: organizationId,
        role,
        designation,
        otherDesignation,
        pinboards: [],
        id: user.uid,
        marketingOptIn,
        registration: {
          status: "complete",
          date: new Date(Date.now()).toLocaleDateString(),
        },
        LSO: new Date(Date.now()).toLocaleDateString(), // Last sign-on date
      };

      // Write new user document to Firestore under the organization
      await setDoc(doc(db, "organizations", organizationId, "users", user.uid), newUserDocData);

      // Create a reference for the user in the user_orgs collection
      await setDoc(doc(db, "user_orgs", user.uid), {
        id: user.uid,
        organization: organizationId,
      });

      await addUserToPinboards(organizationId, user.uid, pinboards);

      // Remove the invitation if it exists to prevent duplicates
      if (!emailSnapshot.empty) {
        await deleteDoc(doc(db, "invitations", emailSnapshot.docs[0].id));
      }

      await updateUserToken();

      // Return the newly created user document data
      return newUserDocData;
    } catch (e) {
      // Log the error for debugging and rethrow
      console.error(e);

      throw e;
    }
  }
);

/**
 * Adds the given user ID to the can_view array of each pinboard document.
 * @param organizationId - ID of the organization where the pinboards are located.
 * @param userId - The ID of the user to add to the pinboards' can_view arrays.
 * @param pinboardIds - Array of pinboard document IDs to update.
 */
export const addUserToPinboards = async (
  organizationId: string,
  userId: string,
  pinboardIds: string[]
): Promise<void> => {
  if (!organizationId || !userId || pinboardIds.length === 0) {
    return;
  }

  const batch = writeBatch(db);

  pinboardIds.forEach((pinboardId) => {
    const pinboardRef = doc(db, "organizations", organizationId, "pinboards", pinboardId);
    batch.update(pinboardRef, {
      can_view: arrayUnion(userId),
    });
  });

  try {
    await batch.commit();
    console.log("User added to pinboards successfully.");
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : "Unknown error";
    console.error("Error updating pinboards:", errorMessage);

    throw new Error(`Failed to add user to pinboards: ${errorMessage}`);
  }
};

const disableAccount = withSentry(
  async (
    userToDelete: { id?: string; email: string; organization?: string },
    admin: { email: string }
  ) => {
    if (userToDelete.id) {
      const userRef = doc(db, "organizations", userToDelete.organization, "users", userToDelete.id);
      await setDoc(userRef, { registration: { status: "disabled" } }, { merge: true });

      const deletedCollectionRef = collection(db, "deleted");
      await addDoc(deletedCollectionRef, {
        email: userToDelete.email,
        disabledBy: admin.email,
        id: userToDelete.id,
        day: new Date().toISOString(),
        organization: userToDelete.organization,
      });
    } else {
      const invitationsCollectionRef = collection(db, "invitations");
      const emailQuery = query(invitationsCollectionRef, where("email", "==", userToDelete.email));
      const emailSnapshot = await getDocs(emailQuery);

      if (!emailSnapshot.empty) {
        const invitationDocRef = doc(invitationsCollectionRef, emailSnapshot.docs[0].id);
        await deleteDoc(invitationDocRef);
      }
    }
  }
);

const fetchSchemaURL = async (orgId: string) => {
  const docRef = doc(db, "organizations", orgId);

  let schemaURL = null;

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().diagram_url) {
      schemaURL = docSnap.data().diagram_url;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
  return schemaURL;
};

const isInvitedUser = async (email: string): Promise<boolean> => {
  const invitationsDoc = doc(db, "invitations", email.toLowerCase());
  const invitationSnap = await getDoc(invitationsDoc);
  return invitationSnap.exists();
};

export { createNewUserDocument, disableAccount, fetchSchemaURL, isInvitedUser };
