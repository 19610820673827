import { Trans, useLingui } from "@lingui/react/macro";
import { Flex, VStack } from "@styled-system/jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Text,
} from "doowii-ui";
import React, { memo, useEffect, useState } from "react";

export interface LegendOptionsProps {
  initialShowLegend?: boolean;
  initialAlign?: string;
  initialVerticalAlign?: string;
  initialLayout?: string;
  initialFloating?: boolean;
  onOptionsChange: (options: {
    showLegend?: boolean;
    align?: string;
    verticalAlign?: string;
    layout?: string;
    floating?: boolean;
  }) => void;
}

export const LegendOptions: React.FC<LegendOptionsProps> = memo(
  ({
    initialShowLegend,
    initialAlign = "center",
    initialVerticalAlign = "bottom",
    initialLayout = "horizontal",
    initialFloating,
    onOptionsChange,
  }) => {
    const { t } = useLingui();

    // Set displayName inside the component
    LegendOptions.displayName = t`Legend Options`;

    // Local state
    const [showLegend, setShowLegend] = useState(initialShowLegend);
    const [align, setAlign] = useState(initialAlign);
    const [verticalAlign, setVerticalAlign] = useState(initialVerticalAlign);
    const [layout, setLayout] = useState(initialLayout);
    const [floating, setFloating] = useState(initialFloating);

    // Update local state when props change
    useEffect(() => {
      setShowLegend(initialShowLegend);
      setAlign(initialAlign || "center");
      setVerticalAlign(initialVerticalAlign || "bottom");
      setLayout(initialLayout || "horizontal");
      setFloating(initialFloating);
    }, [initialShowLegend, initialAlign, initialVerticalAlign, initialLayout, initialFloating]);

    // Handle showLegend change
    const handleShowLegendChange = (checked: boolean) => {
      setShowLegend(checked);
      onOptionsChange({ showLegend: checked });
    };

    // Handle align change
    const handleAlignChange = (value: string) => {
      setAlign(value);
      onOptionsChange({ align: value });
    };

    // Handle verticalAlign change
    const handleVerticalAlignChange = (value: string) => {
      setVerticalAlign(value);
      onOptionsChange({ verticalAlign: value });
    };

    // Handle layout change
    const handleLayoutChange = (value: string) => {
      setLayout(value);
      onOptionsChange({ layout: value });
    };

    // Handle floating change
    const handleFloatingChange = (checked: boolean) => {
      setFloating(checked);
      onOptionsChange({ floating: checked });
    };

    return (
      <VStack alignItems="flex-start" gap="md" width="full">
        <Flex alignItems="center" gap="sm">
          <Switch checked={showLegend} onCheckedChange={handleShowLegendChange} />
          <Text level={3}>
            <Trans>Show Legend</Trans>
          </Text>
        </Flex>

        {showLegend ? (
          <>
            <Flex alignItems="center" gap="sm">
              <Switch checked={floating} onCheckedChange={handleFloatingChange} />
              <Text level={3}>
                <Trans>Floating</Trans>
              </Text>
            </Flex>
            <VStack gap="md" width="50%">
              <Text level={3}>
                <Trans>Alignment</Trans>
              </Text>
              <Select defaultValue={align} onValueChange={handleAlignChange} value={align}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="left">{t`Left`}</SelectItem>
                  <SelectItem value="center">{t`Center`}</SelectItem>
                  <SelectItem value="right">{t`Right`}</SelectItem>
                </SelectContent>
              </Select>
              <Text level={3}>
                <Trans>Vertical Position</Trans>
              </Text>
              <Select
                defaultValue={verticalAlign}
                onValueChange={handleVerticalAlignChange}
                value={verticalAlign}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="top">{t`Top`}</SelectItem>
                  <SelectItem value="middle">{t`Middle`}</SelectItem>
                  <SelectItem value="bottom">{t`Bottom`}</SelectItem>
                </SelectContent>
              </Select>
              <Text level={3}>
                <Trans>Layout</Trans>
              </Text>
              <Select defaultValue={layout} onValueChange={handleLayoutChange} value={layout}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="horizontal">{t`Horizontal`}</SelectItem>
                  <SelectItem value="vertical">{t`Vertical`}</SelectItem>
                  <SelectItem value="proximate">{t`Proximate`}</SelectItem>
                </SelectContent>
              </Select>
            </VStack>
          </>
        ) : null}
      </VStack>
    );
  }
);
