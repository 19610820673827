import { useLingui } from "@lingui/react/macro";
import { ColumnDataType } from "@services/api/generated/webserver/models/columnDataType";
import { HStack } from "@styled-system/jsx";
import { ColumnFilter, Table } from "@tanstack/react-table";
import { Badge, Button, Callout } from "doowii-ui";
import { AnimatePresence, motion } from "framer-motion";

interface FilterBadgesProps {
  tableInstance: Table<unknown[]>;
  allowFiltering?: boolean;
}

/**
 * FilterBadges component displays active filters as badges with removal options
 * It shows the column name, filter value, and provides a way to clear individual filters
 */
export const FilterBadges = ({ tableInstance, allowFiltering = true }: FilterBadgesProps) => {
  const { t } = useLingui();
  const activeFilters = tableInstance.getState().columnFilters;

  // Function to clear all filters
  const clearAllFilters = () => {
    tableInstance.resetColumnFilters(true);
  };

  // Function to clear a specific column filter
  const clearColumnFilter = (columnId: string) => {
    tableInstance.getColumn(columnId)?.setFilterValue(undefined);
  };

  // Helper function to format filter value for display
  const formatFilterValue = (filter: ColumnFilter, columnType: ColumnDataType) => {
    const value = filter.value;

    if (columnType === ColumnDataType.categorical || columnType === ColumnDataType.temporal) {
      return Array.isArray(value) ? `${value.length} items` : String(value);
    }

    if (columnType === ColumnDataType.numerical) {
      return `${value?.[0] ?? "-inf"} -> ${value?.[1] ?? "inf"}`;
    }

    return String(value);
  };

  return (
    <AnimatePresence initial={false}>
      {activeFilters.length > 0 ? (
        <motion.div
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          initial={{ height: 0, opacity: 0 }}
          style={{ alignContent: "start", width: "100%" }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
        >
          <AnimatePresence propagate>
            <HStack alignContent="start" flexWrap="wrap" gap="2" p="3" pt="0" width="full">
              <motion.div
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                initial={{ opacity: 0, x: -20 }}
                key="clear-button"
                transition={{ duration: 0.5 }}
              >
                {allowFiltering ? (
                  <Button
                    onClick={clearAllFilters}
                    size="xsmall"
                    style={{
                      lineHeight: "1.1rem",
                    }}
                    variant="primary"
                  >
                    {t`Clear Filters`}
                  </Button>
                ) : (
                  <Callout size="1" style={{ lineHeight: "1.1rem" }} variant="info">
                    {t`Applied Filters`}
                  </Callout>
                )}
              </motion.div>

              {activeFilters.map((filter) => {
                const column = tableInstance.getColumn(filter.id);
                const columnName = column?.columnDef?.header?.toString() || filter.id;
                const columnType = column?.columnDef?.meta?.columnType;
                const filterValue = formatFilterValue(filter, columnType as ColumnDataType);

                return (
                  <motion.div
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    initial={{ opacity: 0, x: -20 }}
                    key={filter.id}
                    transition={{ duration: 0.5 }}
                  >
                    <Badge
                      data-testid={`filter-badge-${filter.id}`}
                      key={`${filter.id}-badge`}
                      onRemove={() => clearColumnFilter(filter.id)}
                      onRemoveTooltip={t`Remove filter`}
                      style={{
                        gap: "0",
                      }}
                      withIcons={allowFiltering}
                    >
                      <b>{columnName}</b>: {filterValue}
                    </Badge>
                  </motion.div>
                );
              })}
            </HStack>
          </AnimatePresence>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
