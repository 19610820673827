/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export enum VerifyEmailCodeResponseResult {
  EXPIRED = "EXPIRED",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}
