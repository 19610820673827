import { useOrgUsers } from "@context/users";
import { User } from "@context/users/users.i";
import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import { Flex } from "@styled-system/jsx";
import { Checkbox, SearchInput, Skeleton } from "doowii-ui";
import { useState } from "react";

interface Props {
  sharedUsers: string[];
  setSharedUsers: (sharedUsers: string[]) => void;
  organizationUsers: User[];
}
const ExistingUsersChecklist: React.FC<Props> = ({
  sharedUsers,
  setSharedUsers,
  organizationUsers,
}) => {
  const { t } = useLingui();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { fetchingOrganizationUsers } = useOrgUsers();
  const { authUser } = useAuth();

  const userFilter = (user: User) =>
    (user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchQuery.toLowerCase())) &&
    user.registration.status !== "pending" &&
    user.id !== authUser?.uid;

  const handleCheckboxChange = (userId: string) => {
    if (sharedUsers.includes(userId)) {
      setSharedUsers(sharedUsers.filter((id) => id !== userId));
    } else {
      setSharedUsers([...sharedUsers, userId]);
    }
  };

  return (
    <Flex
      css={{
        gap: "sm",
      }}
      direction="column"
    >
      <SearchInput
        aria-label={t`Search users`}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={t`Search for a user`}
        value={searchQuery}
      />
      {fetchingOrganizationUsers ? (
        <>
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton height="60px" key={index} rounded="md" width="100%" />
          ))}
        </>
      ) : (
        organizationUsers
          .filter(userFilter)
          .map((user) => (
            <Checkbox
              checked={sharedUsers.includes(user.id)}
              key={user.id}
              label={`${user.firstName} ${user.lastName}`}
              onCheckedChange={() => handleCheckboxChange(user.id)}
              secondaryLabel={user.role}
              variant="expanded"
            />
          ))
      )}
    </Flex>
  );
};

export { ExistingUsersChecklist };
