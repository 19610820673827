/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { GetRolePeersParams } from "../../models/getRolePeersParams";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { PeerResponse } from "../../models/peerResponse";
import type { RoleChain } from "../../models/roleChain";

/**
 * Get the role chain for the current user's organization.

Returns the role chain for the current user's organization.

Args:
    current_user (AuthUserTuple): The current user.

Returns:
    List[RoleChain]: The role chain for the current user's organization containing
    program manager, senior manager and director names.
 * @summary Get Role Chain
 */
export const getRoleChain = (signal?: AbortSignal) =>
  webserverAxios<RoleChain>({ url: `/api/organization/role/chain`, method: "GET", signal });

export const getGetRoleChainQueryKey = () => [`/api/organization/role/chain`] as const;

export const getGetRoleChainQueryOptions = <
  TData = Awaited<ReturnType<typeof getRoleChain>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoleChain>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRoleChainQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoleChain>>> = ({ signal }) =>
    getRoleChain(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRoleChain>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetRoleChainQueryResult = NonNullable<Awaited<ReturnType<typeof getRoleChain>>>;
export type GetRoleChainQueryError = unknown;

export function useGetRoleChain<
  TData = Awaited<ReturnType<typeof getRoleChain>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoleChain>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getRoleChain>>, TError, TData>,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRoleChain<
  TData = Awaited<ReturnType<typeof getRoleChain>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoleChain>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getRoleChain>>, TError, TData>,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRoleChain<
  TData = Awaited<ReturnType<typeof getRoleChain>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoleChain>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Role Chain
 */

export function useGetRoleChain<
  TData = Awaited<ReturnType<typeof getRoleChain>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoleChain>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetRoleChainQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get peers at the same role level for the current user's organization.

Returns paginated list of peers at the same role level.

Args:
    page (int): Page number for pagination (default: 0)
    page_size (int): Number of items per page (default: 10)
    parent_level_name (str | None): Parent level name, if not provided will be fetched from hierarchy
    parent_user_name (str | None): Parent user name, if not provided will be fetched from role chain
    current_peer_level (str | None): Current peer level, if not provided will use user's level
    current_user (AuthUserTuple): The current user.

Returns:
    PeerResponse: Contains list of peer information and total count
 * @summary Get Role Peers
 */
export const getRolePeers = (params?: GetRolePeersParams, signal?: AbortSignal) =>
  webserverAxios<PeerResponse>({
    url: `/api/organization/role/peers`,
    method: "GET",
    params,
    signal,
  });

export const getGetRolePeersQueryKey = (params?: GetRolePeersParams) =>
  [`/api/organization/role/peers`, ...(params ? [params] : [])] as const;

export const getGetRolePeersQueryOptions = <
  TData = Awaited<ReturnType<typeof getRolePeers>>,
  TError = HTTPValidationError,
>(
  params?: GetRolePeersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRolePeers>>, TError, TData>>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRolePeersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRolePeers>>> = ({ signal }) =>
    getRolePeers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRolePeers>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetRolePeersQueryResult = NonNullable<Awaited<ReturnType<typeof getRolePeers>>>;
export type GetRolePeersQueryError = HTTPValidationError;

export function useGetRolePeers<
  TData = Awaited<ReturnType<typeof getRolePeers>>,
  TError = HTTPValidationError,
>(
  params: undefined | GetRolePeersParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRolePeers>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getRolePeers>>, TError, TData>,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRolePeers<
  TData = Awaited<ReturnType<typeof getRolePeers>>,
  TError = HTTPValidationError,
>(
  params?: GetRolePeersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRolePeers>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getRolePeers>>, TError, TData>,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRolePeers<
  TData = Awaited<ReturnType<typeof getRolePeers>>,
  TError = HTTPValidationError,
>(
  params?: GetRolePeersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRolePeers>>, TError, TData>>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Role Peers
 */

export function useGetRolePeers<
  TData = Awaited<ReturnType<typeof getRolePeers>>,
  TError = HTTPValidationError,
>(
  params?: GetRolePeersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRolePeers>>, TError, TData>>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetRolePeersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
