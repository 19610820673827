import { ColumnType } from "@doowii-types/chart";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import { ChartConfig } from "@utils/chartTransformations";

/**
 * Determines the available chart types based on the selected columns and their data types.
 *
 * @param {ChartConfig["column_types"]} columnTypes - An object mapping column names to their data types.
 * @param {string[]} selectedColumns - An array of selected column names.
 * @returns {DataVizType[]} - An array of available DataVizTypes for the selected columns.
 */
export const getAvailableCharts = (
  columnTypes: ChartConfig["column_types"],
  selectedColumns: string[]
): DataVizType[] => {
  // Map the selected column names to their corresponding data types
  const selectedColumnTypes = selectedColumns.map((col) => columnTypes[col]);

  // Separate the columns based on their data types
  const numericalColumns = selectedColumnTypes.filter((type) => type === ColumnType.NUMERICAL);
  const categoricalColumns = selectedColumnTypes.filter((type) => type === ColumnType.CATEGORICAL);
  const temporalColumns = selectedColumnTypes.filter((type) => type === ColumnType.TEMPORAL);

  // Count the number of columns for each type
  const numericalCount = numericalColumns.length;
  const categoricalCount = categoricalColumns.length;
  const temporalCount = temporalColumns.length;

  // Total number of categorical or temporal columns
  const categoricalOrTemporalCount = categoricalCount + temporalCount;

  // Initialize the list of available charts with TABLE (always available)
  const availableCharts: DataVizType[] = [DataVizTypes.TABLE];

  addBarCharts(numericalCount, categoricalCount, temporalCount, availableCharts);
  addLineCharts(numericalCount, categoricalCount, temporalCount, availableCharts);
  addScatterCharts(
    numericalCount,
    categoricalOrTemporalCount,
    selectedColumns.length,
    availableCharts
  );
  addPieCharts(numericalCount, categoricalCount, selectedColumns.length, availableCharts);

  return availableCharts;
};

/**
 * Checks and adds Bar Chart types based on the column configuration.
 *
 * @param {number} numericalCount - Number of numerical columns.
 * @param {number} categoricalCount - Number of categorical columns.
 * @param {number} temporalCount - Number of temporal columns.
 * @param {DataVizType[]} availableCharts - Array of available charts to update.
 */
const addBarCharts = (
  numericalCount: number,
  categoricalCount: number,
  temporalCount: number,
  availableCharts: DataVizType[]
): void => {
  // Condition 1: One Categorical, One Temporal, One Numerical Column
  if (numericalCount === 1 && categoricalCount === 1 && temporalCount === 1) {
    availableCharts.push(DataVizTypes.BAR_CHART);
  }
  // Condition 2: Two Categorical Columns, One Numerical Column
  if (numericalCount === 1 && categoricalCount + temporalCount === 2) {
    availableCharts.push(DataVizTypes.BAR_CHART);
  }
  // Condition 3: Multiple numerical columns (1-6) with one categorical/temporal column for grouping
  if (numericalCount > 0 && numericalCount < 7 && categoricalCount + temporalCount === 1) {
    availableCharts.push(DataVizTypes.BAR_CHART);
  }
};

/**
 * Checks and adds Line Chart types based on the column configuration.
 *
 * @param {number} numericalCount - Number of numerical columns.
 * @param {number} categoricalCount - Number of categorical columns.
 * @param {number} temporalCount - Number of temporal columns.
 * @param {DataVizType[]} availableCharts - Array of available charts to update.
 */
const addLineCharts = (
  numericalCount: number,
  categoricalCount: number,
  temporalCount: number,
  availableCharts: DataVizType[]
): void => {
  // Condition 1: One Categorical, One Temporal, One Numerical Column
  if (numericalCount === 1 && categoricalCount === 1 && temporalCount === 1) {
    availableCharts.push(DataVizTypes.LINE_CHART);
  }
  // Condition 2: Two Categorical Columns, One Numerical Column
  if (numericalCount === 1 && categoricalCount + temporalCount === 2) {
    availableCharts.push(DataVizTypes.LINE_CHART);
  }

  // Condition 3: Between 1-6 numerical columns and exactly one categorical or temporal column
  if (numericalCount > 0 && numericalCount < 7 && categoricalCount + temporalCount === 1) {
    availableCharts.push(DataVizTypes.LINE_CHART);
  }
};

/**
 * Checks and adds Scatter Chart types based on the column configuration.
 *
 * @param {number} numericalCount - Number of numerical columns.
 * @param {number} categoricalOrTemporalCount - Number of categorical or temporal columns.
 * @param {number} totalSelectedColumns - Total number of selected columns.
 * @param {DataVizType[]} availableCharts - Array of available charts to update.
 */
const addScatterCharts = (
  numericalCount: number,
  categoricalOrTemporalCount: number,
  totalSelectedColumns: number,
  availableCharts: DataVizType[]
): void => {
  // Condition: Exactly 2 numerical columns and at most 1 categorical/temporal column
  if (
    numericalCount === 2 &&
    categoricalOrTemporalCount <= 1
    // totalSelectedColumns === numericalCount + categoricalOrTemporalCount
  ) {
    availableCharts.push(DataVizTypes.SCATTER_CHART);
  }
};

/**
 * Checks and adds Pie Chart types based on the column configuration.
 *
 * @param {number} numericalCount - Number of numerical columns.
 * @param {number} categoricalCount - Number of categorical columns.
 * @param {number} totalSelectedColumns - Total number of selected columns.
 * @param {DataVizType[]} availableCharts - Array of available charts to update.
 */
const addPieCharts = (
  numericalCount: number,
  categoricalCount: number,
  totalSelectedColumns: number,
  availableCharts: DataVizType[]
): void => {
  // Condition: Exactly 1 numerical column and 1 categorical column
  if (numericalCount === 1 && categoricalCount === 1 && totalSelectedColumns === 2) {
    availableCharts.push(DataVizTypes.PIE_CHART);
  }
};
