import { PINBOARD_SHARING } from "@constants/features";
import { useOrgUsers } from "@context/users";
import { UserRoles } from "@doowii-types/auth";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { createInvitation } from "@services/firebase";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  useToast,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const InviteUserDialog: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { t } = useLingui();
  const { toast } = useToast();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<UserRoles>(UserRoles.ADMIN);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { userDocument, featureFlags } = useAuth();
  const { organizationUsers, disabledUsers, fetchUsersInOrganization } = useOrgUsers();

  const handleRoleChange = (value: UserRoles) => {
    setRole(value);
  };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
    return re.test(email);
  };

  const handleSend = async () => {
    setLoading(true);
    if (!validateEmail(email)) {
      setErrorMessage(t`Please enter a valid email address.`);
    } else if (organizationUsers.find((user) => user.email === email.toLowerCase())) {
      setErrorMessage(t`User already exists in organization.`);
    } else if (disabledUsers.find((user) => user.email === email.toLowerCase())) {
      setErrorMessage(t`User has been disabled.`);
    } else {
      try {
        const res = await createInvitation(userDocument.organization, email.toLowerCase(), role);
        if (res.code === 409) {
          setErrorMessage(t`User has already been invited or exists.`);
        } else {
          toast({
            title: t`Invitation sent`,
            description: t`Invitation has been sent successfully`,
            status: "success",
          });

          fetchUsersInOrganization();
          setIsOpen(false);
        }
      } catch (e) {
        toast({
          title: t`Error sending invitation`,
          description: t`An error occurred while sending the invitation.`,
          status: "error",
        });
        setIsOpen(false);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Invite more users</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <Text level={3}>
            <Trans>
              Invite others to leverage the power of AI-enabled analytics. Users will receive an
              email to create an account. At this time, users can access all data you have
              integrated with Doowii.
            </Trans>
          </Text>
          <InputField
            disabled={loading}
            errorText={errorMessage}
            label={t`Email`}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t`Enter email`}
            type="email"
            value={email}
          />
          <Box>
            <Label level={1}>{t`Role`}</Label>
            <Select
              disabled={loading}
              name={t`Role Selector`}
              onValueChange={handleRoleChange}
              value={role}
            >
              <SelectTrigger>
                <SelectValue placeholder={t`Select`} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value={UserRoles.ADMIN}>Admin</SelectItem>
                  {featureFlags?.includes(PINBOARD_SHARING) ? (
                    <SelectItem value={UserRoles.VIEWER}>Viewer</SelectItem>
                  ) : null}
                </SelectGroup>
              </SelectContent>
            </Select>
          </Box>
        </DialogBody>
        <DialogFooter
          className={css({ borderTop: "none", justifyContent: "center!", p: "xl2", gap: "sm" })}
        >
          <Button loading={loading} onClick={handleSend} size="large" variant="primary">
            <Trans>Send</Trans>
          </Button>
          <Button
            disabled={loading}
            onClick={() => setIsOpen(false)}
            size="large"
            variant="secondary"
          >
            <Trans>Cancel</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserDialog;
