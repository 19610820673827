/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { ChatUpdateRequest } from "../../models/chatUpdateRequest";
import type { ChatUpdateResponse } from "../../models/chatUpdateResponse";
import type { HTTPValidationError } from "../../models/hTTPValidationError";

/**
 * Update chart configuration for a chat.

Args:
    thread_id: ID of the thread containing the chat
    chat_id: ID of the chat to update
    request: Updated chat details matching ChatUpdateRequest model
    current_user: Current user and organization info from auth

Returns:
    ChatUpdateResponse containing updated chat details

Raises:
    HTTPException: If chat not found or other errors occur
 * @summary Update Chat
 */
export const updateChat = (
  threadId: string,
  chatId: string,
  chatUpdateRequest: ChatUpdateRequest
) =>
  webserverAxios<ChatUpdateResponse>({
    url: `/api/threads/${threadId}/chat/${chatId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: chatUpdateRequest,
  });

export const getUpdateChatMutationOptions = <
  TData = Awaited<ReturnType<typeof updateChat>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { threadId: string; chatId: string; data: ChatUpdateRequest },
    TContext
  >;
}) => {
  const mutationKey = ["updateChat"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateChat>>,
    { threadId: string; chatId: string; data: ChatUpdateRequest }
  > = (props) => {
    const { threadId, chatId, data } = props ?? {};

    return updateChat(threadId, chatId, data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { threadId: string; chatId: string; data: ChatUpdateRequest },
    TContext
  >;
};

export type UpdateChatMutationResult = NonNullable<Awaited<ReturnType<typeof updateChat>>>;
export type UpdateChatMutationBody = ChatUpdateRequest;
export type UpdateChatMutationError = HTTPValidationError;

/**
 * @summary Update Chat
 */
export const useUpdateChat = <
  TData = Awaited<ReturnType<typeof updateChat>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { threadId: string; chatId: string; data: ChatUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  TData,
  TError,
  { threadId: string; chatId: string; data: ChatUpdateRequest },
  TContext
> => {
  const mutationOptions = getUpdateChatMutationOptions(options);

  return useMutation(mutationOptions);
};
