import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";

import { env } from "../../env";

const getCurrentUserAuthorization = (): Promise<string> => {
  const currentUser = getAuth().currentUser;
  if (currentUser) {
    return currentUser.getIdToken(true);
  }

  throw new Error("No user is logged in");
};

const getAuthHeader = async (): Promise<AxiosRequestConfig> => {
  try {
    const token = await getCurrentUserAuthorization();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    throw new Error("Error getting the auth token");
  }
};

// API URLs from environment
const API_URLS = {
  webserver: env.REACT_APP_WEB_SERVER_URL,
  retriever: env.REACT_APP_RETRIEVER_API_URL,
  sequalizer: env.REACT_APP_SEQUALIZER_API_URL,
};

// Create a factory function for generating axios instances
const createAxiosInstance = (apiType: keyof typeof API_URLS): AxiosInstance => {
  const instance = axios.create({
    baseURL: API_URLS[apiType],
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Add request interceptor for auth token
  instance.interceptors.request.use(
    async (config) => {
      try {
        const authHeader = await getAuthHeader();
        Object.assign(config.headers, authHeader.headers);
        return config;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

// Create instances for each API
const webserverAxiosInstance = createAxiosInstance("webserver");
const retrieverAxiosInstance = createAxiosInstance("retriever");
const sequalizerAxiosInstance = createAxiosInstance("sequalizer");

// Default instance for backward compatibility
const axiosInstance = webserverAxiosInstance;

// Generic request function
export const customAxiosInstance = <T>(config: AxiosRequestConfig): Promise<T> =>
  axiosInstance(config).then((response) => response.data);

// Specific request functions for each API
export const webserverAxios = <T>(config: AxiosRequestConfig): Promise<T> =>
  webserverAxiosInstance(config).then((response) => response.data);

export const retrieverAxios = <T>(config: AxiosRequestConfig): Promise<T> =>
  retrieverAxiosInstance(config).then((response) => response.data);

export const sequalizerAxios = <T>(config: AxiosRequestConfig): Promise<T> =>
  sequalizerAxiosInstance(config).then((response) => response.data);
