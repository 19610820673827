import { PageHeader } from "@components/PageHeader";
import { ThreadListItem } from "@components/ThreadListItem";
import { PATHS } from "@constants/Paths";
import { useLocaleContext } from "@context/locale";
import { faRectangleHistory } from "@fortawesome/pro-regular-svg-icons";
import { Trans, useLingui } from "@lingui/react/macro";
import { Flex } from "@styled-system/jsx";
import { Heading, PageLayout, SearchInput } from "doowii-ui";
import { DateTime } from "luxon";
import { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useChatData } from "../../context/chat";

const Threads = () => {
  const { t } = useLingui();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { threads } = useChatData();
  const { locale } = useLocaleContext();
  const { i18n } = useLingui();

  const filteredThreads = useMemo(
    () =>
      threads
        .filter((thread) => thread.title.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    [threads, search]
  );

  const ThreadList = useMemo(() => {
    const today = DateTime.now().startOf("day").toJSDate().getTime();
    const lastSevenDate = DateTime.now().minus({ days: 7 }).startOf("day").toJSDate().getTime();

    let regularDate = lastSevenDate;

    let isTodaySet = false;
    let isSevenDaysSet = false;
    const threadStack: React.ReactNode[] = [];

    filteredThreads.forEach((thread) => {
      const threadDate = new Date(thread.created_at).getTime();

      if (!isTodaySet && threadDate >= today) {
        threadStack.push(
          <Heading level={3}>
            {" "}
            {DateTime.now()
              .toRelativeCalendar({ locale })
              .replace(/^(.)/u, (match) => match.toUpperCase())}
          </Heading>
        );
        isTodaySet = true;
      } else if (!isSevenDaysSet && threadDate >= lastSevenDate && threadDate < today) {
        threadStack.push(
          <Heading level={3}>
            <Trans>Past 7 days</Trans>
          </Heading>
        );
        isSevenDaysSet = true;
      } else if (threadDate < regularDate) {
        threadStack.push(
          <Heading level={3}>
            {i18n.date(DateTime.fromMillis(threadDate).toJSDate(), {
              dateStyle: "medium",
            })}
          </Heading>
        );
        regularDate = DateTime.fromMillis(threadDate).startOf("day").toJSDate().getTime();
      }

      threadStack.push(
        <ThreadListItem chatCount={thread.chatCount} id={thread.id} title={thread.title} />
      );
    });

    return threadStack.map((component, index) => <Fragment key={index}>{component}</Fragment>);
  }, [filteredThreads, i18n, locale]);

  return (
    <>
      <PageHeader description={t`Page to list threads`} title={t`Doowii - Threads`} />
      <PageLayout
        action={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t`Search threads`}
            variant="standard"
          />
        }
        emptyProps={{
          title: t`It’s looking a bit empty here`,
          description: t`All the chats between you and doowii will appear here`,
          buttonText: t`Start a chat`,
          icon: faRectangleHistory,
          onClick: () => navigate(PATHS.ROOT),
        }}
        isEmpty={!threads.length}
        title={t`Threads`}
      >
        <Flex css={{ flexDirection: "column", gap: "md", w: "full" }}>{ThreadList}</Flex>
      </PageLayout>
    </>
  );
};

export { Threads };
