/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable react/jsx-max-depth */
import "@fontsource-variable/outfit";
import "./index.css";

import AuthInitializer from "@context/auth/AuthInitializer";
import { ChatDataProvider } from "@context/chat";
import { DoowiiProvider } from "@context/doowii/doowii";
import { LocaleProvider } from "@context/locale";
import { PinboardProvider } from "@context/pinboard";
import { OrganizationUsersProvider } from "@context/users";
import RouterWrapper from "@routes/RouterWrapper";
import * as Sentry from "@sentry/react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Toaster } from "doowii-ui";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { env } from "./env";

interface ErrorResponse {
  detail?: string;
  message?: string;
}

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error: unknown, _variables, _context, mutation) => {
      Sentry.withScope((scope) => {
        const axiosError = error as AxiosError<ErrorResponse>;
        const errorDetail = axiosError.response?.data?.detail || axiosError.message;

        // Create a descriptive error title
        const errorTitle = mutation.options.mutationKey
          ? `Mutation Failed: ${mutation.options.mutationKey.join("/")} - ${errorDetail}`
          : "Unknown Mutation Error";

        // Add mutation context with more details
        scope.setContext("mutation", {
          mutationId: mutation.mutationId,
          variables: JSON.stringify(mutation.state.variables),
          mutationKey: mutation.options.mutationKey,
          endpoint: axiosError.config?.url,
          method: axiosError.config?.method?.toUpperCase(),
        });

        // Set tags for better filtering
        scope.setTag("error_type", "mutation");
        scope.setTag("http_method", axiosError.config?.method?.toUpperCase() || "UNKNOWN");
        scope.setTag("status_code", axiosError.response?.status?.toString() || "UNKNOWN");

        // Set fingerprint for better grouping
        if (mutation.options.mutationKey) {
          scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
        }

        // Add axios response data if available
        if (axiosError.response?.data) {
          scope.setContext("response", {
            status: axiosError.response.status,
            statusText: axiosError.response.statusText,
            data: axiosError.response.data,
            detail: axiosError.response.data.detail,
          });
        }

        // Add request details
        scope.setContext("request", {
          url: axiosError.config?.url,
          method: axiosError.config?.method,
        });

        // Capture exception with better context
        Sentry.captureException(new Error(errorTitle), {
          originalException: error,
          data: {
            errorMessage: errorDetail,
            statusCode: axiosError.response?.status,
            endpoint: axiosError.config?.url,
          },
        });
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (error: unknown, query) => {
      Sentry.withScope((scope) => {
        const axiosError = error as AxiosError<ErrorResponse>;
        const errorDetail = axiosError.response?.data?.detail || axiosError.message;

        // Create a descriptive error title
        const errorTitle = `Query Failed: ${query.queryKey.join("/")} - ${errorDetail}`;

        // Add query context with more details
        scope.setContext("query", {
          queryHash: query.queryHash,
          queryKey: query.queryKey,
          endpoint: axiosError.config?.url,
          method: axiosError.config?.method?.toUpperCase(),
        });

        // Set tags for better filtering
        scope.setTag("error_type", "query");
        scope.setTag("http_method", axiosError.config?.method?.toUpperCase() || "UNKNOWN");
        scope.setTag("status_code", axiosError.response?.status?.toString() || "UNKNOWN");

        // Set fingerprint for better grouping
        scope.setFingerprint([
          query.queryHash
            // Numbers wrapped in forward-slashes, or at the end of the string
            .replaceAll(/\/[0-9]+(\/|$)/gu, "/<id>$1")
            .replaceAll(
              // UUIDs wrapped in forward-slashes, or at the end of the string
              /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(\/|$)/gu,
              "/<uuid>$1"
            ),
        ]);

        // Add axios response data if available
        if (axiosError.response?.data) {
          scope.setContext("response", {
            status: axiosError.response.status,
            statusText: axiosError.response.statusText,
            data: axiosError.response.data,
            detail: axiosError.response.data.detail,
          });
        }

        // Add request details
        scope.setContext("request", {
          url: axiosError.config?.url,
          method: axiosError.config?.method,
        });

        // Capture exception with better context
        Sentry.captureException(new Error(errorTitle), {
          originalException: error,
          data: {
            errorMessage: errorDetail,
            statusCode: axiosError.response?.status,
            endpoint: axiosError.config?.url,
          },
        });
      });
    },
  }),
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

Sentry.init({
  dsn: "https://946b8670c6e1eb35b3f88d47a78ee39a@o4505761205649408.ingest.us.sentry.io/4507936248496128",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.extraErrorDataIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["https://ask.doowii.io", "https://staging.doowii.io"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: env.REACT_APP_ENV === "prod" || env.REACT_APP_ENV === "staging",
  environment: env.REACT_APP_ENV,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <LocaleProvider>
      <AuthInitializer>
        <ChatDataProvider>
          <PinboardProvider>
            <QueryClientProvider client={queryClient}>
              <DoowiiProvider>
                <OrganizationUsersProvider>
                  <HelmetProvider>
                    <RouterWrapper />
                  </HelmetProvider>
                </OrganizationUsersProvider>
              </DoowiiProvider>
              <Toaster />
            </QueryClientProvider>
          </PinboardProvider>
        </ChatDataProvider>
      </AuthInitializer>
    </LocaleProvider>
  </React.StrictMode>
);
