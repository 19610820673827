import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { useLingui } from "@lingui/react/macro";
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "doowii-ui";
import { ReactNode } from "react";

interface ContextActionsDropdownProps {
  children: ReactNode;
}

const ContextActionsDropdown = ({ children }: ContextActionsDropdownProps) => {
  const { t } = useLingui();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          aria-label={t`More options`}
          iconOnly={faEllipsisVertical}
          size="xsmall"
          style={{
            color: "black",
            padding: 0,
            fontSize: "16px",
          }}
          variant="text"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">{children}</DropdownMenuContent>
    </DropdownMenu>
  );
};

export { ContextActionsDropdown };
