import { UserDocument } from "@doowii-types/user";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import { withSentry } from "../../utils/wrapper";
import { db } from "./connection";

/**
 * Fetches the full name of a pinboard owner based on user and organization IDs,
 * while reporting missing user errors to Sentry for logging and diagnostics.
 *
 * @param userId - The ID of the user to fetch.
 * @param orgId - The ID of the organization the user belongs to.
 * @returns The user's full name.
 */
const getPinboardOwnerName = withSentry(async (userId: string, orgId: string): Promise<string> => {
  try {
    const docRef = doc(db, `organizations/${orgId}/users`, userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userDoc = docSnap.data() as UserDocument;
      return `${userDoc.firstName} ${userDoc.lastName}`;
    } else {
      // Report the error to Sentry
      throw new Error(`User not found for pinboard owner: userId=${userId}, orgId=${orgId}`);
    }
  } catch (error) {
    // Capture the error for visibility
    console.error("Failed to fetch pinboard owner name:", error);

    // Rethrow the error to Sentry while returning a graceful fallback
    throw error; // Sentry will catch this due to withSentry wrapper
  }
});

const updatePinSchemaUpdatedAt = async ({
  orgId,
  boardId,
  pinId,
  schemaUpdatedAt,
}: {
  orgId: string;
  boardId: string;
  pinId: string;
  schemaUpdatedAt: Date;
}) => {
  const docRef = doc(db, `organizations/${orgId}/pinboards/${boardId}/pins`, pinId);
  await updateDoc(docRef, {
    schema_updated_at: schemaUpdatedAt,
  });
};

export { getPinboardOwnerName, updatePinSchemaUpdatedAt };
