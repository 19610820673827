import { ParentDocTypeEnum } from "@api/retriever.i";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { useLingui } from "@lingui/react/macro";
import { getOrCreateTableStore } from "@stores/tableStore";
import { css } from "@styled-system/css";
import { Box, Flex, VStack } from "@styled-system/jsx";
import { MainSearchBar, Spinner } from "doowii-ui";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "zustand";

import { ApolloChatArea } from "../Apollo/ApolloChatArea";
import { ApolloConversation } from "../Apollo/ApolloConversation";
import ApolloGuide from "../Apollo/ApolloGuide";
import { handleChartModification } from "../DynamicChartRenderer/utils";

interface AIContentProps {
  conversation: { author: "User" | "Doowii"; message: string }[];
  isAccordionOpen: boolean;
  onMessageSubmit: (message: string) => void;
  setIsAccordionOpen: (isOpen: boolean) => void;
  result: Result | Pin;
  isLoading: boolean;
}

// AI Content Component (extracted to reduce nesting depth)
const AIContent: React.FC<AIContentProps> = ({ conversation, onMessageSubmit, isLoading }) => {
  const { t } = useLingui();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Function to scroll to bottom of chat container
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const { scrollHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight;
    }
  };

  // Scroll to bottom of chat when conversation changes
  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  return (
    <VStack
      className={css({
        height: "100%",
        width: "100%",
        gap: "md",
      })}
    >
      {/* Chat Area */}
      <ApolloChatArea ref={chatContainerRef}>
        <ApolloConversation conversation={conversation} />
      </ApolloChatArea>

      {/* Input Area */}
      <Box flexShrink={0} position="relative" width="100%">
        {isLoading ? (
          <Box position="absolute" right="16px" top="50%" transform="translateY(-50%)" zIndex="1">
            <Spinner size="sm" />
          </Box>
        ) : null}
        <MainSearchBar
          label={t`Chat Input`}
          onSubmit={isLoading ? () => {} : onMessageSubmit}
          placeholder={t`Ask how to improve your chart`}
          size="small"
        />
      </Box>
    </VStack>
  );
};

interface ApolloTabProps {
  result: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
}

export const ApolloTab: React.FC<ApolloTabProps> = ({ result, parentDocId, parentDocType }) => {
  const { t } = useLingui();
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Get the table store to access chart config and conversation history
  const tableStore = getOrCreateTableStore({
    parentDocId,
    parentDocType,
    docId: result.id,
    sql: result.sql,
  });

  // Access chart config and conversation from the table store
  const chartConfig = useStore(tableStore, (state) => state.chartConfig);
  const conversation = useStore(tableStore, (state) => state.conversation);
  const addMessage = useStore(tableStore, (state) => state.addMessage);
  const updateChartConfig = useStore(tableStore, (state) => state.updateChartConfig);

  const handleMessageSubmit = async (message: string) => {
    if (!chartConfig || isLoading) {
      return;
    }

    // Add user message to conversation
    addMessage({ author: "User", message });

    // Set loading state
    setIsLoading(true);

    try {
      // Call the API to modify the chart
      // @ts-ignore - The ChartConfig type mismatch is expected but will still work correctly
      const response = await handleChartModification(message, chartConfig);

      if (response) {
        // Add AI response to conversation
        addMessage({
          author: "Doowii",
          message: response.explanation,
        });

        // Add suggestions if any
        if (response.suggestions) {
          const suggestions = Array.isArray(response.suggestions)
            ? response.suggestions
            : [response.suggestions];

          suggestions.forEach((suggestion) => {
            if (suggestion) {
              addMessage({
                author: "Doowii",
                message: suggestion,
              });
            }
          });
        }
        // Apollo currently removes selected_visualization & table_state fields so
        // we need to add them back
        // Update chart config with the new configuration
        updateChartConfig({
          ...response.chart_config,
          selected_visualization: chartConfig.selected_visualization,
          table_state: chartConfig.table_state,
        });
      } else {
        // Handle error - API call returned undefined
        addMessage({
          author: "Doowii",
          message: t`Sorry, I'm having some trouble processing your request. Please try again later.`,
        });
      }
    } catch (error) {
      console.error("Error modifying chart:", error);
      // Add error message to conversation
      addMessage({
        author: "Doowii",
        message: t`Sorry, I encountered an error while trying to update your chart. Please try again later.`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      className={css({
        height: "100%",
        width: "100%",
        p: "md",
        gap: "md",
      })}
    >
      {/* Left side: Guide */}
      <Box width="70%">
        <AIContent
          conversation={conversation}
          isAccordionOpen={isAccordionOpen}
          isLoading={isLoading}
          onMessageSubmit={handleMessageSubmit}
          result={result}
          setIsAccordionOpen={setIsAccordionOpen}
        />
      </Box>
      <Box className={css({ overflow: "auto" })} pr="md" width="50%">
        <ApolloGuide
          isAccordionOpen={isAccordionOpen}
          result={result}
          setIsAccordionOpen={setIsAccordionOpen}
        />
      </Box>
    </Flex>
  );
};
