import { faArrowDown, faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, Flex, VisuallyHidden } from "@styled-system/jsx";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Heading,
  Label,
  Separator,
  Text,
} from "doowii-ui";
import { useId, useState } from "react";

const modalGray = "base.lightGray";

interface TipsModalProps {
  full?: boolean;
}
const TipsModal = ({ full = true }: TipsModalProps) => {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogTrigger asChild>
        <button
          aria-labelledby={`tips-modal-${id}`}
          className={css({ cursor: "pointer" })}
          onClick={() => setIsOpen(true)}
          type="button"
        >
          <Label
            className={css({
              display: "flex",
              gap: "sm",
              textAlign: "center",
              cursor: "pointer",
            })}
            id={`tips-modal-${id}`}
            level={2}
            variant="constrast"
          >
            <FontAwesomeIcon icon={faLightbulbOn} />
            {full ? (
              <Trans>View tips on effective prompt composition</Trans>
            ) : (
              <Trans>View tips </Trans>
            )}
          </Label>
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <Heading component={DialogTitle} level={2}>
            <Trans>Tips on composing prompts</Trans>
          </Heading>
          <VisuallyHidden>
            <DialogDescription>Tips on efecctive propmt composition</DialogDescription>
          </VisuallyHidden>
        </DialogHeader>
        <DialogBody>
          <Flex css={{ flexDirection: "column", gap: "xl" }}>
            <Box>
              <Heading level={3}>
                <Trans>Be Specific</Trans>
              </Heading>
              <Text>
                <Trans>
                  When referring to an ID or number, qualify that item with what the data point is
                  related to (student ID, teacher ID, grade, score, etc).
                </Trans>
              </Text>
            </Box>

            <Box>
              <Heading level={3}>
                <Trans>Time Frame</Trans>
              </Heading>
              <Text>
                <Trans>
                  Specify a time frame for your query if applicable. For instance, you can ask for
                  specific terms in your prompt like 22-23.
                </Trans>
              </Text>
            </Box>

            <Box>
              <Heading level={3}>
                <Trans>Interpret</Trans>
              </Heading>
              <Text>
                <Trans>
                  Don’t ask about vague measures such as “student performance” or “student
                  participation”. Create interpretable values by specifying metrics, such as
                  “student performance by achievement level” or “student participation by number of
                  days attended”.
                </Trans>
              </Text>
            </Box>
          </Flex>
          <Separator className={css({ my: "ld" })} colorful={false} />
          <Heading level={3}>
            <Trans>Example</Trans>
          </Heading>
          <Flex css={{ flexDirection: "column", gap: "md", mt: "ld" }}>
            <Flex css={{ gap: "md", alignItems: "center" }}>
              <Label className={css({ w: "48px" })} level={1}>
                <Trans>Before</Trans>
              </Label>
              <Label
                className={css({
                  borderWidth: 2,
                  borderColor: modalGray,
                  p: "md",
                  borderRadius: "md",
                  flex: 1,
                })}
                level={2}
              >
                <Trans>What is the proficiency rate for each school?</Trans>
              </Label>
            </Flex>
            <FontAwesomeIcon
              className={css({
                alignSelf: "flex-start",
                color: modalGray,
              })}
              icon={faArrowDown}
            />
            <Flex css={{ gap: "md", alignItems: "center" }}>
              <Label className={css({ w: "48px" })} level={1}>
                <Trans>After</Trans>
              </Label>
              <Label
                className={css({
                  borderWidth: 2,
                  borderColor: modalGray,
                  p: "md",
                  borderRadius: "md",
                  flex: 1,
                })}
                level={2}
              >
                <Trans>
                  What is the average math proficiency rate for each school in the school year
                  2022-2023?
                </Trans>
              </Label>
            </Flex>
          </Flex>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export { TipsModal };
