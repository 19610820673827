import { usePinboard } from "@context/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Text,
  Textarea,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  triggerId: string;
  chatIdToPin?: string | null;
}

const CreatePinboardDialog: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  triggerId,
  chatIdToPin = null,
}) => {
  useModalClose(isOpen, triggerId);
  const { t } = useLingui();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { boards, addNewBoard, pinToBoard } = usePinboard();

  const characterLimit = 100;
  const totalCharacters = description.length;

  const handleCreatePinboard = async () => {
    setLoading(true);

    if (name.trim().length < 1) {
      setNameErrorMessage(t`Pinboard name cannot be empty`);
      setLoading(false);
      return;
    } else if (
      [...boards.values()].some((board) => board.name.toLowerCase() === name.toLowerCase())
    ) {
      setNameErrorMessage(t`Pinboard with this name already exists`);
      setLoading(false);
      return;
    }

    const boardId = await addNewBoard({
      name,
      description: description || undefined,
    });

    if (chatIdToPin) {
      await pinToBoard(chatIdToPin, boardId);
    }
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Create a new pinboard</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <InputField
            disabled={loading}
            errorText={nameErrorMessage}
            label={t`Pinboard name`}
            onChange={(e) => {
              setName(e.target.value);
              if (e.target.value.trim().length > 0) {
                setNameErrorMessage("");
              }
            }}
            placeholder={t`Enter pinboard name`}
            type="text"
            value={name}
            required
          />
          <Flex direction="column">
            <Flex direction="column" gap="xs">
              <Textarea
                disabled={loading}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= characterLimit) {
                    setDescription(newValue);
                  }
                }}
                placeholder={t`Enter pinboard description...`}
                value={description}
                rows={3}
                label={t`Pinboard description (optional)`}
              />
            </Flex>
            <Text
              className={css({
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "sm",
                color: "gray.500",
                mt: "xs",
              })}
            >
              {totalCharacters} / {characterLimit}
            </Text>
          </Flex>
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button
            disabled={name.length === 0}
            loading={loading}
            onClick={handleCreatePinboard}
            size="large"
            variant="primary"
          >
            <Trans>Create pinboard</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { CreatePinboardDialog };
