import { mergeSavedDefaultChartOptions } from "@components/DynamicChartRenderer/utils";
import { ChartConfig } from "@doowii-types/chart";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";
import React from "react";

import { LineChartOptions } from "./LineChartOptions";
import { PieChartOptions } from "./PieChartOptions";

interface ChartTypeOptionsProps {
  chartConfig: ChartConfig;
  chartType: string;
  updateConfigState: (config: ChartConfig) => void;
}

/**
 * Factory component that loads the appropriate chart options
 * based on the chart type
 */
const ChartTypeOptions: React.FC<ChartTypeOptionsProps> = ({
  chartConfig,
  chartType,
  updateConfigState,
}) => {
  // Render the appropriate options component based on chart type
  const savedChartOptions =
    // chartConfig?.options?.config ??
    mergeSavedDefaultChartOptions({
      chartType,
      savedOptions: chartConfig?.options?.config ?? {},
    });

  const handleUpdateConfigState = (chartOptions: Highcharts.Options) => {
    updateConfigState({
      ...chartConfig,
      options: { ...chartConfig.options, config: chartOptions },
    });
  };

  const renderChartOptions = () => {
    // Simplified approach to avoid linter issues with switch fallthrough
    if (chartType === DataVisualizationType.PIE) {
      return (
        <PieChartOptions
          chartOptions={savedChartOptions}
          handleUpdateConfigState={handleUpdateConfigState}
        />
      );
    }

    // All other chart types use LineChartOptions for now
    return (
      <LineChartOptions
        chartOptions={savedChartOptions}
        handleUpdateConfigState={handleUpdateConfigState}
      />
    );
  };

  return renderChartOptions();
};

export default ChartTypeOptions;
