import { useLingui } from "@lingui/react/macro";
import { KnowledgeStatus } from "@services/api/generated/webserver/models/knowledgeStatus";
import { token } from "@styled-system/tokens";
import { Badge } from "doowii-ui";

interface StatusBadgeProps {
  status: KnowledgeStatus;
}

const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { t } = useLingui();

  let style;
  let content;

  switch (status) {
    case KnowledgeStatus.COMPLETED:
      style = {
        backgroundColor: token("colors.complementary.greenSecondary"),
        color: token("colors.complementary.greenPrimary"),
      };
      content = t`Completed`;
      break;

    case KnowledgeStatus.FAILED:
      style = {
        backgroundColor: token("colors.complementary.destructiveSecondary"),
        color: token("colors.complementary.destructivePrimary"),
      };
      content = t`Failed`;
      break;

    case KnowledgeStatus.DELETING:
      style = {
        backgroundColor: token("colors.complementary.orangeSecondary"),
        color: token("colors.complementary.orangePrimary"),
      };
      content = t`Deleting`;
      break;

    case KnowledgeStatus.PENDING:
      style = {
        backgroundColor: token("colors.base.lightherGray"),
        color: token("colors.base.gray"),
      };
      content = t`Pending`;
      break;

    default:
      style = {
        backgroundColor: token("colors.base.blueBrandSecondary"),
        color: token("colors.base.blueBrandPrimary"),
      };
      content = t`Processing`;
  }

  return (
    <Badge style={style} withIcons={false}>
      {content}
    </Badge>
  );
};

export { StatusBadge };
