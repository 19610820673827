import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faLock,
  faRotate,
  faRotateLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react/macro";
import {
  getGetCategoricalKnowledgeQueryKey,
  useGetCategoricalKnowledge,
  useUpdateCategoricalKnowledge,
} from "@services/api/generated/webserver/endpoints/knowledge/knowledge";
import { KnowledgeStatus } from "@services/api/generated/webserver/models/knowledgeStatus";
import type { OrganizationCategoricalKnowledgeItem } from "@services/api/generated/webserver/models/organizationCategoricalKnowledgeItem";
import type { UpdateOrgKnowledgeRequest } from "@services/api/generated/webserver/models/updateOrgKnowledgeRequest";
import { css } from "@styled-system/css";
import { Box, Stack } from "@styled-system/jsx";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import {
  Button,
  ConfirmationDialog,
  DropdownMenuItem,
  Label,
  SearchInput,
  SimpleTable,
  Text,
  useToast,
} from "doowii-ui";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";

import { EditKnowledgeItemDialog } from "./EditKnowledgeDialog";
import { EnableSwitch } from "./EnableSwitch";
import { ContextActionsDropdown } from "./KnowledgeActionsDropdown";
import { StatusBadge } from "./StatusBadge";

const columnHelper = createColumnHelper<OrganizationCategoricalKnowledgeItem>();

const GeneralKnowledgeTab = () => {
  const { t } = useLingui();
  const { data, isLoading } = useGetCategoricalKnowledge({
    query: {
      refetchInterval: 12000,
    },
  });
  const { toast } = useToast();
  const [editingItem, setEditingItem] = useState<OrganizationCategoricalKnowledgeItem | null>(null);
  const [resetItem, setResetItem] = useState<OrganizationCategoricalKnowledgeItem | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isPending: isUpdating } = useUpdateCategoricalKnowledge({
    mutation: {
      onMutate: async (newData) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries({ queryKey: getGetCategoricalKnowledgeQueryKey() });

        // Snapshot the previous value
        const previousItems =
          queryClient.getQueryData<OrganizationCategoricalKnowledgeItem[]>(
            getGetCategoricalKnowledgeQueryKey()
          ) ?? [];

        // Optimistically update to the new value
        queryClient.setQueryData<OrganizationCategoricalKnowledgeItem[]>(
          getGetCategoricalKnowledgeQueryKey(),
          previousItems.map((item) =>
            item.id === newData.data.id
              ? {
                  ...item,
                  ...newData.data,
                  updated_at: new Date().toISOString(),
                  status: KnowledgeStatus.PENDING,
                }
              : item
          )
        );

        return { previousItems };
      },
      onError: (err, variables, context) => {
        if (context?.previousItems) {
          queryClient.setQueryData(getGetCategoricalKnowledgeQueryKey(), context.previousItems);
        }
      },
      onSettled: () => {
        void queryClient.invalidateQueries({ queryKey: getGetCategoricalKnowledgeQueryKey() });
      },
    } as UseMutationOptions<
      unknown,
      unknown,
      { data: UpdateOrgKnowledgeRequest },
      { previousItems: OrganizationCategoricalKnowledgeItem[] }
    >,
  });

  const handleEdit = useCallback((item: OrganizationCategoricalKnowledgeItem) => {
    setEditingItem(item);
  }, []);

  const handleResetToDefault = useCallback((item: OrganizationCategoricalKnowledgeItem) => {
    setResetItem(item);
  }, []);

  const handleSaveEdit = useCallback(
    (data: Partial<OrganizationCategoricalKnowledgeItem>) => {
      mutate(
        {
          data: {
            id: data.id,
            category_id: data.category_id,
            content: data.content,
            is_enabled: data.is_enabled,
          },
        },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: t`Context updated successfully`,
            });
            setEditingItem(null);
          },
          onError: (error) => {
            console.error(error);
            toast({
              status: "error",
              title: t`Failed to update context`,
            });
          },
        }
      );
    },
    [t, toast, mutate]
  );

  const handleConfirmReset = useCallback(() => {
    if (!resetItem) {
      return;
    }

    mutate(
      {
        data: {
          id: resetItem.id,
          category_id: resetItem.category_id,
          content: resetItem.default_content,
        },
      },
      {
        onSuccess: () => {
          toast({
            status: "success",
            title: t`Context reset successfully`,
            description: t`Content has been reset to default value`,
          });
          setResetItem(null);
        },
        onError: (error) => {
          console.error(error);
          toast({
            status: "error",
            title: t`Failed to reset context`,
          });
          setResetItem(null);
        },
      }
    );
  }, [resetItem, t, toast, mutate]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        header: t`Expand`,
        cell: ({ row }) => {
          if (!row.getCanExpand()) {
            return null;
          }
          return (
            <Stack alignItems="center">
              <Button
                aria-label={row.getIsExpanded() ? t`Collapse row` : t`Expand row`}
                className={css({
                  color: "gray.900",
                })}
                iconOnly={row.getIsExpanded() ? faChevronDown : faChevronRight}
                onClick={row.getToggleExpandedHandler()}
                size="xsmall"
                variant="text"
              />
            </Stack>
          );
        },
      },
      columnHelper.accessor("title", {
        header: t`Title`,
        cell: (info) => (
          <Box
            css={{
              maxWidth: "200px",
              lineHeight: "1.25",
              textOverflow: "ellipsis",
              lineClamp: 3,
            }}
          >
            {info.getValue()}
          </Box>
        ),
      }),
      columnHelper.accessor("content", {
        header: t`Description`,
        cell: (info) => (
          <Box
            css={{
              lineHeight: "1.25",
              textOverflow: "ellipsis",
              lineClamp: 3,
            }}
          >
            {info.getValue()}
          </Box>
        ),
      }),
      columnHelper.accessor("status", {
        header: t`Status`,
        cell: (info) => <StatusBadge status={info.getValue()} />,
      }),
      columnHelper.accessor("updated_at", {
        header: t`Last Updated`,
        cell: (info) => {
          const date = info.getValue() ? DateTime.fromISO(info.getValue()) : null;
          return date ? (
            <Text
              className={css({
                whiteSpace: "nowrap",
                color: "gray.600",
              })}
            >
              {date.toRelative()}
            </Text>
          ) : null;
        },
      }),
      columnHelper.accessor("is_enabled", {
        header: t`Enable`,
        cell: (info) => (
          <EnableSwitch
            isEnabled={info.getValue()}
            onToggle={(checked) => {
              handleSaveEdit({
                id: info.row.original.id,
                category_id: info.row.original.category_id,
                is_enabled: checked,
              });
            }}
            status={info.row.original.status}
          />
        ),
      }),
      {
        id: "actions",
        header: t`Actions`,
        cell: ({ row }) => {
          const isEditable = row.original.status === KnowledgeStatus.COMPLETED;
          const canRetry =
            row.original.status === KnowledgeStatus.FAILED ||
            row.original.status === KnowledgeStatus.PENDING;

          return (
            <Stack alignItems="center">
              <ContextActionsDropdown>
                {isEditable ? (
                  <>
                    <DropdownMenuItem onClick={() => handleEdit(row.original)}>
                      <FontAwesomeIcon icon={faEdit} />
                      {t`Edit`}
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleResetToDefault(row.original)}>
                      <FontAwesomeIcon icon={faRotateLeft} />
                      {t`Reset to Default`}
                    </DropdownMenuItem>
                  </>
                ) : canRetry ? (
                  <DropdownMenuItem onClick={() => handleSaveEdit(row.original)}>
                    <FontAwesomeIcon icon={faRotate} />
                    {t`Retry`}
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem disabled>
                    <FontAwesomeIcon icon={faLock} />
                    {t`Processing`}
                  </DropdownMenuItem>
                )}
              </ContextActionsDropdown>
            </Stack>
          );
        },
      },
    ],
    [t, handleEdit, handleResetToDefault, handleSaveEdit]
  );

  const renderSubComponent = ({ row }: { row: Row<OrganizationCategoricalKnowledgeItem> }) => (
    <Stack
      css={{
        p: 4,
        bg: "gray.50",
        pl: 10,
        maxHeight: "300px",
        overflowY: "auto",
        // inner shadow
        boxShadow: "inset 0 0 10px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Label level={4} variant="primary">
        {row.original.title}
      </Label>
      <Text>{row.original.content}</Text>
    </Stack>
  );

  return (
    <Box>
      <Stack css={{ mb: 4 }} gap={4} justifyContent="space-between">
        <Text level={3} variant="primary">
          {t`Here you can view and edit Doowii-curated "categorical" terms, definitions, and other context items determined to be potentially relevant to your organization. These are used to help the AI generate more accurate and relevant responses.`}
        </Text>
        <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
          <SearchInput
            aria-label={t`Search knowledge items`}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t`Search by title`}
            value={searchQuery}
            variant="standard"
          />
        </Stack>
      </Stack>

      <SimpleTable
        className={css({
          maxHeight: "3xl",
        })}
        columnFilters={[{ id: "title", value: searchQuery }]}
        columns={columns as ColumnDef<OrganizationCategoricalKnowledgeItem>[]}
        data={data ?? []}
        emptyComponent={
          <div className={css({ textAlign: "center", py: "4" })}>{t`No data available`}</div>
        }
        getRowCanExpand={(row) => String(row.getValue("content")).length > 0}
        loading={isLoading}
        paginationMode="none"
        renderSubComponent={renderSubComponent}
      />

      <EditKnowledgeItemDialog
        dialogDescription={t`Edit this context item.`}
        dialogTitle={t`Edit Context`}
        isOpen={editingItem !== null}
        isSaving={isUpdating}
        item={editingItem}
        onClose={() => setEditingItem(null)}
        onSave={handleSaveEdit}
      />

      <ConfirmationDialog
        confirmationButtonText={t`Reset`}
        description={t`Are you sure you want to reset this context to its default value? This action cannot be undone.`}
        destructive
        icon={faRotateLeft}
        isLoadingAction={isUpdating}
        isOpen={resetItem !== null}
        onConfirm={handleConfirmReset}
        setIsOpen={() => setResetItem(null)}
        title={t`Reset Context`}
      />
    </Box>
  );
};

export { GeneralKnowledgeTab };
