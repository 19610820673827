/* eslint-disable @typescript-eslint/naming-convention */
enum PATHS {
  ROOT = "/",
  HOME = "home",
  SETTINGS = "/settings",
  USER_MANAGEMENT = "/user-management",
  ONBOARDING = "/onboarding",
  SOURCES = "/sources",
  DATA_SCHEMA = "/data-schema",
  KNOWLEDGE_LIBRARY = "/knowledge-library",
  ORGANIZATION = "/organization",
  USERS = "users",
  PINBOARDS = "pinboards",
  THREADS = "threads",
  DATA = "data",
  SIGN_IN = "/sign-in",
  SIGN_OUT = "/sign-out",
  PAGE_NOT_FOUND = "*",
  SIGN_UP = "/sign-up",
  REGISTER = "/register",
  RESET_PASSWORD = "/reset-password",
  VERIFY_EMAIL = "/verify-email",
}

enum PATH_PARAMS {
  USER_ID = "userId",
  PINBOARD_ID = "pinboardId",
  THREAD_ID = "threadId",
}

export { PATH_PARAMS, PATHS };
