/* eslint-disable no-console */
/* eslint-disable lingui/no-unlocalized-strings */
import { z } from "zod";

const envSchema = z.object({
  REACT_APP_SEQUALIZER_API_URL: z.string().url(),
  REACT_APP_RETRIEVER_API_URL: z.string().url(),
  REACT_APP_ENV: z.string().min(1, "Environment is required"),
  REACT_APP_PROJECT_ID: z.string().min(1, "Project ID is required"),
  REACT_APP_FIREBASE_CONFIG: z.string().min(1, "Firebase config is required"), // stringified firebase config object
  REACT_APP_WEB_SERVER_URL: z.string().url(),
});

// Function to validate environment during build/start
const validateEnv = () => {
  try {
    envSchema.parse(import.meta.env);
    console.log("✅ Environment variables validation passed");
    return true;
  } catch (error) {
    console.error("❌ Environment variables validation failed:");
    if (error instanceof z.ZodError) {
      error.errors.forEach((err) => {
        console.error(`- ${err.path.join(".")}: ${err.message}`);
      });
    } else {
      console.error(error);
    }
    process.exit(1);
  }
};

// Run validation immediately
validateEnv();

// Parse and export environment variables for runtime use
export const env = envSchema.parse(import.meta.env);
