import { getAvailableCharts } from "@components/DataNavigator/utils";
import { DataVizType, DataVizTypes, VisualizationType } from "@doowii-types/viz";
import {
  faChartLine,
  faChartPie,
  faChartScatter,
  faChartSimple,
} from "@fortawesome/pro-regular-svg-icons";
import { useLingui } from "@lingui/react/macro";
import * as Tabs from "@radix-ui/react-tabs";
import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { ChartConfig } from "@utils/chartTransformations";
import { Button, Tooltip } from "doowii-ui";
import { useId, useMemo } from "react";

interface ChartSelectorProps {
  chartConfig: ChartConfig;
  onChange: (chartConfig: ChartConfig) => void;
}

// Chart icons mapping
const dataVizIcons = {
  [DataVizTypes.BAR_CHART]: faChartSimple,
  [DataVizTypes.PIE_CHART]: faChartPie,
  [DataVizTypes.LINE_CHART]: faChartLine,
  [DataVizTypes.SCATTER_CHART]: faChartScatter,
};

/**
 * ChartSelector component displays chart type icons and allows selection of chart types
 * based on available charts determined by data characteristics.
 */
const ChartSelector = ({ chartConfig, onChange }: ChartSelectorProps) => {
  const id = useId();
  const { t } = useLingui();

  // Labels for tooltips
  const dataVizLabels = {
    [DataVizTypes.BAR_CHART]: t`Bar Chart view`,
    [DataVizTypes.PIE_CHART]: t`Pie Chart view`,
    [DataVizTypes.LINE_CHART]: t`Line Chart view`,
    [DataVizTypes.SCATTER_CHART]: t`Scatter Chart view`,
  };

  // Calculate available chart types based on the columns and their types
  const availableCharts = useMemo(() => {
    if (!chartConfig) {
      return [DataVizTypes.TABLE];
    }

    try {
      // Cast column_types to the expected type for getAvailableCharts
      const columnTypes = chartConfig.column_types;

      const availableTypes = getAvailableCharts(columnTypes, chartConfig.columns);

      // Filter out TABLE from available charts since we don't show it in the selector
      return availableTypes.filter((type) => type !== DataVizTypes.TABLE) as DataVizType[];
    } catch (error) {
      console.error("Error calculating available charts:", error);
      return [
        DataVizTypes.BAR_CHART,
        DataVizTypes.PIE_CHART,
        DataVizTypes.LINE_CHART,
        DataVizTypes.SCATTER_CHART,
      ];
    }
  }, [chartConfig]);

  const handleChartTypeChange = (value: string) => {
    // Create a new chart config object with updated suggestion
    const newConfig = {
      ...chartConfig,
      suggestion: value as VisualizationType,
      selected_visualization: value as VisualizationType,
    } as ChartConfig;
    onChange(newConfig);
  };

  return (
    <Tabs.Root onValueChange={handleChartTypeChange} value={chartConfig.suggestion}>
      <Tabs.List className={flex({ gap: "sm" })}>
        {Object.entries(dataVizIcons).map(([type, icon]) => {
          const formattedButtonName = capitalizeFirstLetter(type.toLowerCase());
          return (
            <Tabs.Trigger
              aria-label={dataVizLabels[type as keyof typeof dataVizLabels]}
              asChild
              disabled={
                !availableCharts.includes(type as DataVizType)
                  ? type !== chartConfig.suggestion
                  : false
              }
              id={`${id}-${type}`}
              key={type}
              value={type}
            >
              <Tooltip content={t`${formattedButtonName} chart`}>
                <Button
                  aria-describedby={`${id}-${type}`}
                  className={css({
                    '&[data-state="active"]': {
                      backgroundColor: "base.blueBrandSecondary",
                    },
                  })}
                  data-testid={`chart-selector-${type}`}
                  iconOnly={icon}
                  size="large"
                  variant="dropdown"
                />
              </Tooltip>
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
    </Tabs.Root>
  );
};

export { ChartSelector };
