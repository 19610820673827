import { PageHeader } from "@components/PageHeader";
import { HIGHER_ED } from "@constants/constants";
import { PATHS } from "@constants/Paths";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { BackgroundBox, Button, InputField, LoadingLogo, Logo } from "doowii-ui";
import { useEffect, useState } from "react";
import { Form, Link, useActionData, useNavigate, useNavigation, useSubmit } from "react-router-dom";

import { env } from "../../env";
import IpedsDemoInfo from "./IpedsDemoInfo";
import { RequestDemoDialog } from "./RequestDemoDialog";
import { SignUpActionResponse } from "./signUpAction";

const SignUp = () => {
  const { t } = useLingui();
  const submit = useSubmit();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const actionData = useActionData() as SignUpActionResponse | null;
  const { authUser, isLoading } = useAuth();
  const [isRequestDemoDialogOpen, setIsRequestDemoDialogOpen] = useState(false);

  const params = new URLSearchParams(location.search);
  const from = params.get("from");

  const handleGoogleSignUp = () => {
    submit({ signUpMethod: "google", redirectTo: from }, { method: "post", action: PATHS.SIGN_UP });
  };

  const isSubmitting = navigation.state === "submitting" || actionData?.loadingUser;

  useEffect(() => {
    if (authUser) {
      const destination = from ?? authUser.email.startsWith("test_")
        ? PATHS.REGISTER
        : PATHS.VERIFY_EMAIL;

      navigate(destination, { replace: true });
    }

    if (actionData?.openDialog) {
      setIsRequestDemoDialogOpen(true);
    }
  }, [authUser, from, navigate, actionData]);

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <>
      <PageHeader description={t`Sign up for a new account`} title={t`Doowii - Sign up`} />
      <BackgroundBox
        containerProps={{ css: {} }}
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          padding: "xl6",
          opacity: isSubmitting ? 0.5 : 1,
          pointerEvents: isSubmitting ? "none" : "auto",
        }}
      >
        <Box
          className={flex({
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            flex: 1,
          })}
        >
          <Logo />
          {env.REACT_APP_ENV === HIGHER_ED ? (
            <IpedsDemoInfo onContactUsClick={() => setIsRequestDemoDialogOpen(true)} />
          ) : null}

          <h2
            className={css({
              textStyle: "title_h2",
              fontWeight: 600,
              marginTop: "md",
              marginBottom: "xl",
            })}
          >
            <Trans>Create an account</Trans>
          </h2>
          <Form className={css({ width: "100%", maxWidth: "400px" })} method="post">
            <Box
              className={flex({
                flexDirection: "column",
                gap: "ld",
              })}
            >
              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.email}
                label={t`Your email`}
                name="email"
                placeholder="name@school.edu"
                type="email"
              />

              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.password}
                label={t`Password`}
                name="password"
                placeholder="••••••••"
                type="password"
              />

              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.confirmPassword}
                label={t`Confirm password`}
                name="confirmPassword"
                placeholder="••••••••"
                type="password"
              />
            </Box>
            <Button
              className={css({ marginTop: "xl3" })}
              disabled={isSubmitting}
              full
              loading={isSubmitting}
              type="submit"
            >
              <Trans>Sign up</Trans>
            </Button>
          </Form>
          {env.REACT_APP_ENV !== HIGHER_ED ? (
            <>
              <Box
                className={css({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  my: "ld",
                  width: "100%",
                  maxWidth: "400px",
                })}
              >
                <Box
                  className={css({
                    flex: 1,
                    height: "1px",
                    backgroundColor: "border.disabled",
                  })}
                />
                <Box
                  className={css({
                    color: "foreground.muted",
                    textStyle: "paragraph_14",
                    px: "md",
                  })}
                >
                  <Trans>OR</Trans>
                </Box>
                <Box
                  className={css({
                    flex: 1,
                    height: "1px",
                    backgroundColor: "border.disabled",
                  })}
                />
              </Box>
              <Button
                className={css({
                  width: "100%",
                  maxWidth: "400px",
                })}
                full={false}
                iconLeft={faGoogle}
                onClick={handleGoogleSignUp}
                variant="secondary"
              >
                <Trans>Sign up with Google</Trans>
              </Button>
            </>
          ) : null}
          <Box
            className={css({
              marginTop: "md",
              textAlign: "center",
              color: "foreground.muted",
              textStyle: "paragraph_14",
            })}
          >
            <Trans>By signing up, you have read and agree to Doowii's </Trans>
            <Link
              className={css({
                color: "base.blueBrandPrimary",
                _hover: { textDecoration: "underline" },
              })}
              rel="noopener noreferrer"
              target="_blank"
              to="https://www.doowii.io/terms-of-use"
            >
              <Trans>Terms of Service</Trans>
            </Link>{" "}
            <Trans>and</Trans>{" "}
            <Link
              className={css({
                color: "base.blueBrandPrimary",
                _hover: { textDecoration: "underline" },
              })}
              rel="noopener noreferrer"
              target="_blank"
              to="https://www.doowii.io/privacy"
            >
              <Trans>Privacy Policy</Trans>
            </Link>
            .
          </Box>
        </Box>
        <Box
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "foreground.muted",
            textStyle: "paragraph_14",
          })}
        >
          <Trans>Already have an account?</Trans>{" "}
          <Button
            className={css({ marginTop: "md" })}
            disabled={isSubmitting}
            full
            onClick={() => navigate(PATHS.SIGN_IN)}
          >
            <Trans>Sign in</Trans>
          </Button>
        </Box>
      </BackgroundBox>
      {env.REACT_APP_ENV === HIGHER_ED ? (
        <RequestDemoDialog
          isOpen={isRequestDemoDialogOpen}
          setIsOpen={setIsRequestDemoDialogOpen}
          triggerId="request-demo-btn"
        />
      ) : null}
    </>
  );
};

export { SignUp };
