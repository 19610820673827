import { PageHeader } from "@components/PageHeader";
import { faDatabase } from "@fortawesome/pro-regular-svg-icons";
import { useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Card, PageLayout, TabContent, Tabs } from "doowii-ui";

import { CustomKnowledgeTab } from "./components/CustomKnowledgeTab";
import { DocumentKnowledgeTab } from "./components/DocumentKnowledgeTab";
import { GeneralKnowledgeTab } from "./components/GeneralKnowledgeTab";

const KnowledgeLibrary = () => {
  const { t } = useLingui();
  const tabItems = [
    {
      value: "general",
      label: t`General Knowledge`,
    },
    {
      value: "custom",
      label: t`Custom Knowledge`,
    },
    {
      value: "document",
      label: t`Document Knowledge`,
    },
  ];

  return (
    <>
      <PageHeader
        description={t`Manage your organization's knowledge library to help Doowii better understand your data`}
        title={t`Doowii - Knowledge Library`}
      />

      <PageLayout
        emptyProps={{
          title: t`It's looking a bit empty here`,
          description: t`Start by adding knowledge to help Doowii understand your data better`,
          buttonText: t`Add Knowledge`,
          icon: faDatabase,
          onClick: () => null,
        }}
        isEmpty={false}
        title={t`Knowledge Library`}
      >
        <Box css={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Card css={{ display: "flex", flexDirection: "column", flexShrink: 1 }} variant="flat">
            <Tabs defaultValue="general" items={tabItems}>
              <TabContent className={css({ pt: "md" })} value="general">
                <GeneralKnowledgeTab />
              </TabContent>
              <TabContent className={css({ pt: "md" })} value="custom">
                <CustomKnowledgeTab />
              </TabContent>
              <TabContent className={css({ pt: "md" })} value="document">
                <DocumentKnowledgeTab />
              </TabContent>
            </Tabs>
          </Card>
        </Box>
      </PageLayout>
    </>
  );
};

export { KnowledgeLibrary };
