import { ParentDocTypeEnum } from "@api/retriever.i";
import { ChartConfig } from "@doowii-types/chart";
import { VisualizationTypesEnum } from "@doowii-types/viz";
import { I18n } from "@lingui/core";
import { getNewChartConfig } from "@services/webserver/openai";
import { useChartConfigStore } from "@stores/chartConfigStore";
import { generateKey } from "@utils/keyGenerator";
import { CanceledError } from "axios";
import { v4 as uuidv4 } from "uuid";

import { callSequalizer, fetchFollowUpPrompts } from "../api/sequalizer";
import { addToChatHistory, getSourceInfo } from "../services/firebase";
import { Result, Satisfaction } from "../types/chat";
import { UserDocument } from "../types/user";
import {
  DefaultError,
  ErrorType,
  QuestionTypeEnum,
  SequalizerErrorKey,
  sequalizerErrorMessagesMap,
} from "./Doowii.i";
import { fetchStreamEvent } from "./StreamEvent";

export class Doowii {
  static abortControllers: Map<string, AbortController> = new Map();

  i18n: I18n;

  setLoading: (loading: boolean) => void;

  setAllResults: Function;

  setAnswer: Function;

  setStreamLoading: (loading: boolean) => void;

  user: UserDocument;

  threadId: string;

  chat_id: string | null;

  chat_start_time: number | null;

  allResults: Result[];

  threads: any;

  constructor(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    user,
    threadId,
    allResults,
    threads
  ) {
    this.i18n = i18n;
    this.setLoading = setLoading;
    this.setAllResults = setAllResults;
    this.setAnswer = setAnswer;
    this.setStreamLoading = setStreamLoading;
    this.user = user;
    this.threadId = threadId;
    this.chat_id = null;
    this.chat_start_time = null;
    this.allResults = allResults;
    this.threads = threads;
  }

  async chat({ query, index, questionType = QuestionTypeEnum.USER }) {
    try {
      this.initializeChat(query, index, questionType);
      await this.getSqlAndAnswer(query, index, questionType);
    } catch (error) {
      if (error instanceof CanceledError) {
        await this.handleCancel(error, query, index, questionType);
        return;
      }
      await this.handleError(error, query);
    }
  }

  cancelQuery(chatId) {
    if (Doowii.abortControllers.has(chatId)) {
      const controller = Doowii.abortControllers.get(chatId);
      controller?.abort();
      Doowii.abortControllers.delete(this.chat_id);
    }
  }

  initializeChat(query, index, questionType) {
    this.chat_start_time = performance.now();
    this.chat_id =
      questionType === QuestionTypeEnum.REGENERATE ? this.allResults[index].id : uuidv4();
    this.setLoading(true);
    Doowii.abortControllers.set(this.chat_id, new AbortController());
    const newResult = {
      id: this.chat_id,
      query,
      title: query,
      satisfied: "UNKNOWN",
      loading: true,
    };

    this.setAllResults((prevResults) => {
      const updatedResults = [...prevResults];
      updatedResults[index] = newResult;
      return updatedResults;
    });
    this.setAnswer((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = "";
      return updatedAnswers;
    });

    setTimeout(() => {
      const element = document.getElementById(this.chat_id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);
  }

  async getSqlAndAnswer(query, index, questionType = "user") {
    const { sourceType, schemaUpdatedAt } = await getSourceInfo(this.user.organization);

    const controller = Doowii.abortControllers.get(this.chat_id);

    const response = await callSequalizer(
      this.user.organization,
      query,
      this.user.email,
      this.threadId,
      this.chat_id,
      sourceType,
      "query",
      questionType,
      controller
    );
    // TODO: add types
    let chartConfig = { suggestion: "TABLE", columns: [], column_types: {} };

    try {
      chartConfig = await getNewChartConfig(response.sql, query);
    } catch (error) {
      console.error("Error in getNewChartConfig", error);
    }

    await this.cleanUp({
      index,
      visualisation: response.sql ? VisualizationTypesEnum.TABLE : VisualizationTypesEnum.NO_SQL,
      query,
      sql: response.sql,
      chartConfig,
      questionType: questionType as QuestionTypeEnum,
      schemaUpdatedAt,
      total_rows: response.total_rows ? response.total_rows : -1,
    });
  }

  async handleCancel(error, query, index, questionType) {
    const chatEndTime = performance.now();
    const duration = chatEndTime - this.chat_start_time;
    const canceledError = {
      message: "This query was canceled. If this was a mistake, please retry the query.",
      name: error.name,
      code: error.code,
    };

    const newResult = {
      id: this.chat_id,
      query,
      title: query,
      satisfied: "UNKNOWN" as Satisfaction,
      sql: "",
      timestamp: new Date(),
      latency: parseFloat((duration / 1000).toFixed(2)),
      follow_up_prompts: [],
      error: canceledError,
      loading: false,
      chartConfig: {
        suggestion: VisualizationTypesEnum.CANCELED,
        columns: [],
        column_types: {},
        options: {},
        column_aggregations: {},
        column_grouping: {},
      } as ChartConfig,
    };

    this.setLoading(false);
    this.setAllResults((prevResults) => {
      const updatedResults = [...prevResults];
      updatedResults[index] = newResult;
      return updatedResults;
    });
    this.setAnswer((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = "";
      return updatedAnswers;
    });

    await addToChatHistory({
      threads: this.threads,
      currentThread: this.threadId,
      organization: this.user.organization,
      userId: this.user.id,
      query,
      result: newResult,
      answer: "",
      questionType,
    });
  }

  async handleError(error, query) {
    console.error("Error in chat", error);
    let err = DefaultError;
    if (error.name in sequalizerErrorMessagesMap) {
      err = {
        message: sequalizerErrorMessagesMap[error.name as SequalizerErrorKey],
        name: error.name,
      };
    }

    await this.cleanUp({
      visualisation: VisualizationTypesEnum.ERROR,
      query,
      index: this.allResults.length,
      sql: "",
      error: err,
    });
  }

  async cleanUp({
    index,
    visualisation,
    query,
    sql,
    error = {} as ErrorType,
    chartConfig = null,
    questionType = QuestionTypeEnum.USER,
    schemaUpdatedAt = null,
    total_rows = -1,
  }) {
    setTimeout(() => {
      const element = document.getElementById(this.chat_id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
    const chatEndTime = performance.now();
    const duration = chatEndTime - this.chat_start_time;

    const config =
      visualisation === VisualizationTypesEnum.ERROR ||
      visualisation === VisualizationTypesEnum.NO_SQL
        ? { suggestion: visualisation, columns: [], column_types: {} }
        : (chartConfig ?? {
            suggestion: VisualizationTypesEnum.TABLE,
            columns: [],
            column_types: {},
          });

    const newResult = {
      id: this.chat_id,
      query,
      title: query,
      satisfied: "UNKNOWN" as Satisfaction,
      error,
      sql,
      timestamp:
        questionType === QuestionTypeEnum.REGENERATE
          ? this.allResults[index]?.timestamp
          : new Date(),
      latency: parseFloat((duration / 1000).toFixed(2)),
      chartConfig: config,
      loading: false,
      schemaUpdatedAt,
      total_rows,
    };

    this.setLoading(false);

    let newAnswer = error.message
      ? typeof error.message === "string"
        ? error.message
        : this.i18n._(error.message)
      : "";
    // Update the chart config in the Zustand store
    const chartConfigInstanceKey = generateKey(
      ParentDocTypeEnum.THREAD,
      this.threadId,
      this.chat_id
    );
    useChartConfigStore.getState().updateInstance(chartConfigInstanceKey, {
      chartConfig: config,
    });

    this.setAllResults((prevResults) =>
      prevResults.map((result, i) => (i === index ? newResult : result))
    );
    this.setAnswer((prevAnswers) =>
      prevAnswers.map((answer, i) => (i === index ? newAnswer : answer))
    );

    let followUpPrompts = [];
    if (Object.keys(error).length === 0) {
      this.setStreamLoading(true);

      // Initiate both promises without awaiting them
      const fetchStreamEventPromise = fetchStreamEvent({
        setAnswer: this.setAnswer,
        index,
        thread_id: this.threadId,
        question_id: this.chat_id,

        setStreamLoading: this.setStreamLoading,
      });

      const fetchFollowUpPromptsPromise = fetchFollowUpPrompts({
        org_id: this.user.organization,
        thread_id: this.threadId,
        question_id: this.chat_id,
      });

      const results = await Promise.allSettled([
        fetchStreamEventPromise,
        fetchFollowUpPromptsPromise,
      ]);

      const [fetchStreamEventResult, fetchFollowUpPromptsResult] = results;

      if (fetchStreamEventResult.status === "fulfilled") {
        newAnswer = fetchStreamEventResult.value;
      } else {
        const errorMessage =
          sequalizerErrorMessagesMap[fetchStreamEventResult.reason as SequalizerErrorKey];
        newAnswer = this.i18n._(errorMessage);
        this.setStreamLoading(false);
      }

      if (fetchFollowUpPromptsResult.status === "fulfilled") {
        followUpPrompts = fetchFollowUpPromptsResult.value;
      }
    }

    const updatedResult = {
      ...newResult,
      follow_up_prompts: followUpPrompts || [],
    };

    this.setAllResults((prev) => {
      const updatedResults = [...prev];
      updatedResults[index] = updatedResult;
      return updatedResults;
    });

    await addToChatHistory({
      threads: this.threads,
      currentThread: this.threadId,
      organization: this.user.organization,
      userId: this.user.id,
      query,
      result: updatedResult,
      answer: newAnswer,
      questionType,
    });
  }
}
