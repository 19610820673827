import { sharedChartOptions } from "@components/DynamicChartRenderer/options";
import { ChartConfig } from "@doowii-types/chart";
import { useLingui } from "@lingui/react/macro";
import { VStack } from "@styled-system/jsx";
import { useDebounce } from "@uidotdev/usehooks";
import { Accordion } from "doowii-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  GeneralOptions,
  LegendOptions,
  PieOptions,
  SeriesOptions,
  TooltipOptions,
} from "../OptionGroups";

interface PieChartOptionsProps {
  chartOptions: Highcharts.Options;
  handleUpdateConfigState: (chartOptions: Highcharts.Options) => void;
}

// Define the structure of options for pie charts
interface PieChartOptionsValues {
  // General options
  title: string;
  subtitle: string;

  // Legend options
  showLegend: boolean;
  legendAlign: string;
  legendVerticalAlign: string;
  legendLayout: string;
  legendFloating: boolean;

  // Pie-specific options
  innerSize: string;
  startAngle: number;
  endAngle?: number;

  // Tooltip options
  showTooltip: boolean;
  tooltipFormat: string;

  // Series options
  showDataLabels: boolean;
}

const PieChartOptions: React.FC<PieChartOptionsProps> = ({
  chartOptions,
  handleUpdateConfigState,
}) => {
  const { t } = useLingui();

  // Extract initial values from chart config
  const initialValues = useMemo<PieChartOptionsValues>(() => {
    const savedConfig = chartOptions;

    return {
      // General options
      title: savedConfig.title?.text || "",
      subtitle: savedConfig.subtitle?.text || "",

      // Legend options
      showLegend: savedConfig.legend?.enabled !== false,
      legendAlign: savedConfig.legend?.align || "center",
      legendVerticalAlign: savedConfig.legend?.verticalAlign || "bottom",
      legendLayout: savedConfig.legend?.layout || "horizontal",
      legendFloating: savedConfig.legend?.floating || false,

      // Pie-specific options
      innerSize: (savedConfig.plotOptions?.pie?.innerSize as string) || "0%",
      startAngle: savedConfig.plotOptions?.pie?.startAngle || 0,
      endAngle: savedConfig.plotOptions?.pie?.endAngle,

      // Tooltip options
      showTooltip: savedConfig.tooltip?.enabled !== false,
      tooltipFormat: savedConfig.tooltip?.pointFormat || "",

      // Series options
      // @ts-ignore
      showDataLabels: savedConfig.plotOptions?.pie?.dataLabels?.enabled === true,
    };
  }, [chartOptions]);

  // Local state to track form changes
  const [formValues, setFormValues] = useState<PieChartOptionsValues>(initialValues);

  // Create debounced version of formValues with useDebounce
  const debouncedValues = useDebounce(formValues, 500);

  // Update local state when chartConfig changes
  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  // Apply changes when debouncedValues change
  useEffect(() => {
    // Skip the initial application when values match the initial state
    if (JSON.stringify(debouncedValues) === JSON.stringify(initialValues)) {
      return;
    }

    // Fixed format for pie chart with name and percentage
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const format = "<b>{point.name}</b>: {point.percentage:.1f} %";

    // Set default options if chartConfig is missing options
    const oldOptions = chartOptions;
    const updatedOptions: Highcharts.Options = {
      ...oldOptions,
      // General options
      title: {
        ...(oldOptions.title || {}),
        text: debouncedValues.title,
      },
      subtitle: {
        ...(oldOptions.subtitle || {}),
        text: debouncedValues.subtitle,
      },
      // Legend options
      legend: {
        ...(oldOptions.legend || {}),
        enabled: debouncedValues.showLegend,
        // @ts-ignore
        align: debouncedValues.legendAlign as any,
        // @ts-ignore
        verticalAlign: debouncedValues.legendVerticalAlign as any,
        // @ts-ignore
        layout: debouncedValues.legendLayout as any,
        floating: debouncedValues.legendFloating,
      },
      // Tooltip options
      tooltip: {
        ...(oldOptions.tooltip || {}),
        enabled: debouncedValues.showTooltip,
        pointFormat: debouncedValues.tooltipFormat || undefined,
      },
      // Pie chart specific options
      plotOptions: {
        ...(oldOptions.plotOptions || {}),
        pie: {
          ...(oldOptions.plotOptions?.pie || {}),
          innerSize: debouncedValues.innerSize,
          startAngle: debouncedValues.startAngle,
          endAngle: debouncedValues.endAngle,
          dataLabels: {
            ...(oldOptions.plotOptions?.pie?.dataLabels || {}),
            enabled: debouncedValues.showDataLabels,
            format,
          },
        },
        // Make sure we keep general series options too
        series: {
          ...(oldOptions.plotOptions?.series || {}),
          animation: false,
        },
      },
    };

    handleUpdateConfigState(updatedOptions);
  }, [debouncedValues, chartOptions, initialValues, handleUpdateConfigState]);

  // Handle series options change
  const handleSeriesOptionsChange = useCallback((options: { showDataLabels?: boolean }) => {
    setFormValues((prev) => ({
      ...prev,
      showDataLabels: options.showDataLabels ?? prev.showDataLabels,
    }));
  }, []);

  // Handlers for form changes
  const handleOptionsChange = useCallback((options: Partial<PieChartOptionsValues>) => {
    setFormValues((prev) => ({
      ...prev,
      ...options,
    }));
  }, []);

  // Handle legend options change
  const handleLegendOptionsChange = useCallback(
    (options: {
      showLegend?: boolean;
      align?: string;
      verticalAlign?: string;
      layout?: string;
      floating?: boolean;
    }) => {
      setFormValues((prev) => ({
        ...prev,
        showLegend: options.showLegend ?? prev.showLegend,
        legendAlign: options.align ?? prev.legendAlign,
        legendVerticalAlign: options.verticalAlign ?? prev.legendVerticalAlign,
        legendLayout: options.layout ?? prev.legendLayout,
        legendFloating: options.floating ?? prev.legendFloating,
      }));
    },
    []
  );

  // Define accordion rows for pie chart options
  const accordionRows = useMemo(
    () => [
      {
        title: t`General Options`,
        content: (
          <GeneralOptions
            initialSubtitle={formValues.subtitle}
            initialTitle={formValues.title}
            onOptionsChange={handleOptionsChange}
          />
        ),
      },
      {
        title: t`Legend Options`,
        content: (
          <LegendOptions
            initialAlign={formValues.legendAlign}
            initialFloating={formValues.legendFloating}
            initialLayout={formValues.legendLayout}
            initialShowLegend={formValues.showLegend}
            initialVerticalAlign={formValues.legendVerticalAlign}
            onOptionsChange={handleLegendOptionsChange}
          />
        ),
      },
      {
        title: t`Pie Chart Options`,
        content: (
          <PieOptions
            initialEndAngle={formValues.endAngle}
            initialInnerSize={formValues.innerSize}
            initialStartAngle={formValues.startAngle}
            onOptionsChange={handleOptionsChange}
          />
        ),
      },
      {
        title: t`Tooltip Options`,
        content: (
          <TooltipOptions
            initialShowTooltip={formValues.showTooltip}
            initialTooltipFormat={formValues.tooltipFormat}
            onOptionsChange={handleOptionsChange}
          />
        ),
      },
      {
        title: t`Series Options`,
        content: (
          <SeriesOptions
            initialShowDataLabels={formValues.showDataLabels}
            onOptionsChange={handleSeriesOptionsChange}
          />
        ),
      },
    ],
    [t, formValues, handleOptionsChange, handleLegendOptionsChange, handleSeriesOptionsChange]
  );

  return (
    <VStack gap="md" width="full">
      <Accordion lockedHeader rows={accordionRows} title={t`Chart Configuration`} />
    </VStack>
  );
};

export { PieChartOptions };
