import { useLingui } from "@lingui/react/macro";
import { VStack } from "@styled-system/jsx";
import { InputField } from "doowii-ui";
import React, { useEffect, useState } from "react";

export interface GeneralOptionsProps {
  initialTitle?: string;
  initialSubtitle?: string;
  onOptionsChange: (options: { title?: string; subtitle?: string }) => void;
}

export const GeneralOptions: React.FC<GeneralOptionsProps> = ({
  initialTitle = "",
  initialSubtitle = "",
  onOptionsChange,
}) => {
  const { t } = useLingui();

  // Local state
  const [title, setTitle] = useState(initialTitle);
  const [subtitle, setSubtitle] = useState(initialSubtitle);

  // Initialize state from props
  useEffect(() => {
    setTitle(initialTitle || "");
    setSubtitle(initialSubtitle || "");
  }, [initialTitle, initialSubtitle]);

  // Notify parent of changes
  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange({
        title,
        subtitle,
      });
    }
  }, [title, subtitle, onOptionsChange]);

  return (
    <VStack alignItems="flex-start" gap="md" width="full">
      <InputField
        label={t`Chart Title`}
        onChange={(e) => {
          if (e?.target?.value !== undefined) {
            setTitle(e.target.value);
          }
        }}
        placeholder={t`Enter chart title`}
        value={title || ""}
      />
      <InputField
        label={t`Chart Subtitle`}
        onChange={(e) => {
          if (e?.target?.value !== undefined) {
            setSubtitle(e.target.value);
          }
        }}
        placeholder={t`Enter chart subtitle`}
        value={subtitle || ""}
      />
    </VStack>
  );
};
