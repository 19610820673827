import { User } from "firebase/auth";
interface RoleHierarchyConfig {
  parent?: string;
  label?: string;
}

interface RoleHierarchy {
  [key: string]: RoleHierarchyConfig;
}

export const buildRoleOrder = (roleHierarchy: RoleHierarchy): string[] => {
  const roleOrder: string[] = [];

  // Start with roles that have no parent
  const rootRoles = Object.entries(roleHierarchy)
    .filter(([_, config]) => !config.parent)
    .map(([role]) => role);

  for (const rootRole of rootRoles) {
    roleOrder.push(rootRole);
    let currentRole = rootRole;

    // Follow the parent chain
    while (true) {
      const childRole = Object.entries(roleHierarchy).find(
        ([_, config]) => config.parent === currentRole
      )?.[0];

      if (!childRole) break;
      roleOrder.push(childRole);
      currentRole = childRole;
    }
  }

  return roleOrder;
};

export const getUserAccessRole = async (authUser: User): Promise<string | undefined> => {
  try {
    const tokenResult = await authUser.getIdTokenResult();
    return tokenResult.claims.access_role_name as string;
  } catch (error) {
    console.error("Error getting user access role:", error);
    return undefined;
  }
};
export const getChildRoleName = (
  roleHierarchy: RoleHierarchy,
  parentRole: string
): string | undefined => {
  // Find the child role where the parent matches the given parentRole
  const childRole = Object.entries(roleHierarchy).find(
    ([_, config]) => config.parent === parentRole
  );

  // Return the role name (key) if found, undefined otherwise
  return childRole?.[0];
};

export const getRoleLabel = (roleHierarchy: RoleHierarchy, role: string): string => {
  const roleConfig = roleHierarchy[role];
  return roleConfig?.label || "Unknown Role";
};
