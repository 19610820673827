import { withSentry } from "@utils/wrapper";

import { put } from "./base";

const sharePinboard = withSentry(async (pinboardId: string, userIds: string[]): Promise<void> => {
  const path = `/pinboards/${pinboardId}/can_view`;
  await put(path, { user_ids: userIds, send_email: true });
});

export { sharePinboard };
