import { ChartConfig as TypesChartConfig } from "@doowii-types/chart";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { ChartConfig } from "@utils/chartTransformations";
import React, { useEffect, useRef } from "react";

import { ChartOptionsForm } from "./ChartOptionsForm";

interface CustomizePanelProps {
  chartConfig: ChartConfig;
  updateConfig: (config: ChartConfig) => void;
}

const CustomizePanel: React.FC<CustomizePanelProps> = ({ chartConfig, updateConfig }) => {
  // Use refs to track latest values for cleanup function
  const latestChartConfigRef = useRef(chartConfig);

  // Keep refs updated with latest props
  useEffect(() => {
    latestChartConfigRef.current = chartConfig;
  }, [chartConfig]);

  // The animations are annoying when the user is customizing the chart
  // so we disable them when the user is customizing the chart
  // and restore them when the user is done customizing the chart
  useEffect(
    () =>
      // Restore animation on unmount
      () => {
        // Using latest refs to get current values
        const latestConfig = latestChartConfigRef.current?.options?.config;
        if (latestConfig) {
          updateConfig({
            ...latestChartConfigRef.current,
            options: {
              ...(latestChartConfigRef.current.options || {}),
              config: {
                ...latestConfig,
                plotOptions: {
                  ...latestConfig.plotOptions,
                  series: {
                    ...latestConfig.plotOptions?.series,
                    animation: true,
                  },
                },
              },
            },
          });
        }
      },
    [updateConfig]
  );

  return (
    <Box
      className={css({
        py: "md",
        width: "100%",
        px: "md",
        overflow: "auto",
      })}
    >
      <ChartOptionsForm
        chartConfig={chartConfig as unknown as TypesChartConfig}
        updateConfigState={updateConfig}
      />
    </Box>
  );
};

export { CustomizePanel };
