// Import Highcharts directly
// eslint-disable-next-line simple-import-sort/imports
import * as Highcharts from "highcharts";
// Import modules directly (no initialization needed in v12)
import "highcharts/modules/accessibility";
import "highcharts/modules/boost";

import { ChartConfig } from "@doowii-types/chart";
import { modifyChart } from "@services/webserver/chartModification";
import { DataVizTypes } from "@doowii-types/viz";
import {
  defaultPieChartOptions,
  defaultBarChartOptions,
  defaultLineChartOptions,
  defaultScatterChartOptions,
  sharedChartOptions,
} from "./options";

export const replacePlaceholders = (
  config: unknown,
  replacements: Record<string, unknown>
): unknown => {
  if (typeof config === "string") {
    // Check if the entire string is a placeholder without quotes
    const placeholderMatch = config.match(/^\{\{(\w+)\}\}$/u);
    if (placeholderMatch) {
      const key = placeholderMatch[1];
      // console.log("Key:", key);
      // console.log("Replacements:", replacements[key], Object.keys(replacements));
      if (replacements[key] !== undefined) {
        return replacements[key]; // Return the value directly, preserving type
      } else {
        return config; // Leave as is if no replacement found
      }
    } else {
      // Replace placeholders within the string
      return config.replace(/\{\{(\w+)\}\}/gu, (_, key) =>
        replacements[key] !== undefined ? String(replacements[key]) : ""
      );
    }
  } else if (Array.isArray(config)) {
    return config.map((item) => replacePlaceholders(item, replacements));
  } else if (typeof config === "object" && config !== null) {
    const newObj: Record<string, unknown> = {};
    for (const key in config) {
      newObj[key] = replacePlaceholders(config[key], replacements);
    }
    return newObj;
  } else {
    return config;
  }
};

export function parseFunctions(obj: unknown): unknown {
  if (typeof obj === "string") {
    // Check if the string is a function
    if (
      /^function\s*\(/u.test(obj.trim()) ||
      /^\(\s*function/u.test(obj.trim()) ||
      /^.*=>.*$/u.test(obj.trim())
    ) {
      // Evaluate the function string to a real function

      const originalFunction = eval(`(${obj})`);

      // Wrap the original function to catch errors during execution
      return function (...args: unknown[]) {
        try {
          return originalFunction.apply(this, args);
        } catch (error) {
          console.error("Error in evaluated function:", error);
          // Return a default value to prevent crashing
          return "";
        }
      };
    } else {
      return obj;
    }
  } else if (Array.isArray(obj)) {
    return obj.map((item) => parseFunctions(item));
  } else if (typeof obj === "object" && obj !== null) {
    const newObj: Record<string, unknown> = {};
    for (const key in obj) {
      newObj[key] = parseFunctions(obj[key]);
    }
    return newObj;
  } else {
    return obj;
  }
}

export const handleChartModification = async (
  userRequest: string,
  currentChartConfig: ChartConfig
) => {
  try {
    return await modifyChart(userRequest, currentChartConfig);
  } catch (error) {
    console.error("Error modifying chart:", error);
  }
};

export const mergeSavedDefaultChartOptions = ({
  chartType,
  savedOptions,
}: {
  chartType: string;
  savedOptions: Highcharts.Options;
}) => {
  const combinedOptions: Highcharts.Options = {
    ...savedOptions,
    chart: { ...savedOptions.chart, type: chartType },
  };

  switch (chartType) {
    case DataVizTypes.PIE_CHART:
      return defaultPieChartOptions(combinedOptions);

    case DataVizTypes.BAR_CHART:
      return defaultBarChartOptions(combinedOptions);

    case DataVizTypes.LINE_CHART:
      return defaultLineChartOptions(combinedOptions);

    case DataVizTypes.SCATTER_CHART:
      return defaultScatterChartOptions(combinedOptions);

    default:
      console.error(`Unsupported chart type: ${chartType}`);
      return { ...sharedChartOptions, ...combinedOptions };
  }
};
// Export Highcharts directly since it now includes accessibility
export { Highcharts };
