import { useChatData } from "@context/chat";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex, VisuallyHidden } from "@styled-system/jsx";
import {
  Button,
  CircularIcon,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  FeedbackButton,
  Heading,
  Text,
  Textarea,
} from "doowii-ui";
import React, { useEffect, useState } from "react";

interface Props {
  index: number;
  isActive: boolean;
  type: "positive" | "negative";
}
const FeedbackDialog: React.FC<Props> = ({ index, isActive, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updateIndividualSatisfaction } = useChatData();
  const [feedback, setFeedback] = useState("");
  const { t } = useLingui();

  const isPositive = type === "positive";

  const handleFeedback = async () => {
    setLoading(true);
    await updateIndividualSatisfaction(index, type === "positive", feedback, !isPositive);
    setLoading(false);
    setIsOpen(false);
  };

  useEffect(() => {
    setFeedback("");
  }, [isOpen]);

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogTrigger asChild>
        <FeedbackButton
          isActive={isActive}
          label={isPositive ? t`Give positive feedback` : t`Give negative feedback`}
          onClick={() => setIsOpen(true)}
          type={type}
        />
      </DialogTrigger>
      <DialogContent aria-describedby={undefined}>
        <VisuallyHidden>
          <DialogTitle>
            <Trans>Feedback dialog</Trans>
          </DialogTitle>
        </VisuallyHidden>
        <DialogBody className={css({ p: "ld", pt: "xl2" })}>
          <Flex
            css={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "xl",
            }}
          >
            <CircularIcon icon={type === "positive" ? faThumbsUp : faThumbsDown} />
            <Flex
              css={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "sm",
              }}
            >
              <Heading level={2}>
                {type === "positive" ? (
                  <Trans>We got it right</Trans>
                ) : (
                  <Trans>We got it wrong</Trans>
                )}
              </Heading>
              <Flex
                css={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text level={3}>
                  <Trans>Could you please provide us with your feedback?</Trans>
                </Text>
                <Text level={3}>
                  <Trans>This will help us improve our service.</Trans>
                </Text>
              </Flex>
            </Flex>
            <Textarea
              css={{ w: "full", h: "124px" }}
              onChange={(e) => {
                setFeedback(e.target.value);
              }}
              placeholder={t`Optional feedback`}
              value={feedback}
            />
          </Flex>
        </DialogBody>
        <DialogFooter
          className={css({
            borderTop: "none",
            display: "flex",
            justifyContent: "center!",
            gap: "md",
          })}
        >
          <Button loading={loading} onClick={handleFeedback} size="large">
            <Trans>Send feedback</Trans>
          </Button>
          <Button
            loading={loading}
            onClick={() => setIsOpen(false)}
            size="large"
            variant="secondary"
          >
            <Trans>Cancel</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { FeedbackDialog };
