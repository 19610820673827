import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataTable } from "@components/DataTable";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { DataVizTypes } from "@doowii-types/viz";
import { useChartConfig } from "@hooks/useChartConfig";
import { useResize } from "@hooks/useResize";
import { getOrCreateTableStore } from "@stores/tableStore";
import { css } from "@styled-system/css";
import { VStack } from "@styled-system/jsx";
import { transformDataForChart } from "@utils/chartTransformations";
import { LogoLoader } from "doowii-ui";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "zustand";

import { ChartErrorState } from "./ChartErrorState";
import {
  defaultBarChartOptions,
  defaultLineChartOptions,
  defaultPieChartOptions,
  defaultScatterChartOptions,
} from "./options";
import { Highcharts, parseFunctions, replacePlaceholders } from "./utils";

// Define a more specific type for chart data
interface ChartData {
  series: Highcharts.SeriesOptionsType[];
  [key: string]: unknown;
}

interface DynamicChartRendererProps {
  currentResult: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  isModificationMode?: boolean;
}

const ApolloDynamicChartRenderer: React.FC<DynamicChartRendererProps> = ({
  currentResult,
  parentDocId,
  parentDocType,
  isModificationMode = false,
}) => {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { dimensions, componentRef } = useResize();

  const {
    loadingModification,
    chartConfig,
    updateConfigState,
    tempChartConfig,
    updateOptionsToModify,
  } = useChartConfig({
    initialConfig: currentResult.chartConfig,
    chatId: currentResult.id,
    parentId: parentDocId,
    parentType: parentDocType,
  });
  const config = isModificationMode ? tempChartConfig : chartConfig;

  const tableStore = useStore(
    getOrCreateTableStore({
      parentDocId,
      parentDocType,
      docId: currentResult.id,
      sql: currentResult.sql,
    })
  );
  const { getFilteredRows, tableInstance } = tableStore;

  const dataObject = getFilteredRows();

  // Memoize the transformed chart data
  const chartData = useMemo(() => {
    try {
      return transformDataForChart(dataObject, config);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Error processing chart data"));
      console.error("Error transforming data for chart", err);
      return null;
    }
  }, [dataObject, config]);

  useEffect(() => {
    const { height, width } = dimensions;
    if (!chartData || loadingModification || !height || !width) {
      return;
    }

    const chartType = config?.suggestion;
    const savedOptions = config?.options?.config || {};
    let combinedOptions: Highcharts.Options = {
      ...savedOptions,
      chart: { ...savedOptions.chart, height, width, type: chartType },
    };

    switch (chartType) {
      case DataVizTypes.PIE_CHART:
        combinedOptions = defaultPieChartOptions(combinedOptions);
        break;

      case DataVizTypes.BAR_CHART:
        combinedOptions = defaultBarChartOptions(combinedOptions);
        break;

      case DataVizTypes.LINE_CHART:
        combinedOptions = defaultLineChartOptions(combinedOptions);
        break;

      case DataVizTypes.SCATTER_CHART:
        combinedOptions = defaultScatterChartOptions(combinedOptions);
        break;

      case DataVizTypes.TABLE:
        break;

      default:
        console.error(`Unsupported chart type: ${chartType}`);
        setError(new Error(`Unsupported chart type: ${chartType}`));
    }

    const optionsWithData = replacePlaceholders(combinedOptions, chartData);
    const parsedOptions = parseFunctions(optionsWithData);

    setChartOptions(parsedOptions);
    if (isModificationMode) {
      updateOptionsToModify(parsedOptions);
    }
  }, [config, chartData, dimensions, loadingModification]);

  if (error) {
    return <ChartErrorState />;
  }

  return (
    <VStack
      alignItems="center"
      className={css({
        height: "100%",
      })}
      justifyContent="center"
      ref={componentRef}
    >
      {(!chartOptions && config?.suggestion != DataVizTypes.TABLE) ||
      (loadingModification && isModificationMode) ? (
        <LogoLoader size="lg" variant="infinite" />
      ) : config?.suggestion === DataVizTypes.TABLE ? (
        <DataTable
          allowColumnSelection={true}
          chartConfig={config}
          tableInstance={tableInstance}
          updateConfigState={updateConfigState}
        />
      ) : (
        <HighchartsReact
          data-testid="highcharts-react-wrapper"
          highcharts={Highcharts}
          immutable
          options={chartOptions}
        />
      )}
    </VStack>
  );
};

export { ApolloDynamicChartRenderer };
export type { ChartData };
