import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react/macro";
import type { CustomKnowledgeItem } from "@services/api/generated/webserver/models/customKnowledgeItem";
import type { OrganizationCategoricalKnowledgeItem } from "@services/api/generated/webserver/models/organizationCategoricalKnowledgeItem";
import { Stack } from "@styled-system/jsx";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Textarea,
} from "doowii-ui";
import { useEffect, useMemo, useState } from "react";

type KnowledgeItem = OrganizationCategoricalKnowledgeItem | CustomKnowledgeItem;

interface EditKnowledgeItemProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: KnowledgeItem) => void;
  isSaving: boolean;
  item: KnowledgeItem | null;
  dialogTitle?: string;
  dialogDescription?: string;
}

const EditKnowledgeItemDialog = ({
  isOpen,
  onClose,
  onSave,
  item,
  isSaving = false,
  dialogTitle,
  dialogDescription,
}: EditKnowledgeItemProps) => {
  const { t } = useLingui();
  const [formData, setFormData] = useState<{
    title: KnowledgeItem["title"];
    content: KnowledgeItem["content"];
  }>({
    title: undefined,
    content: undefined,
  });
  const [showDiscardAlert, setShowDiscardAlert] = useState(false);

  useEffect(() => {
    if (item?.id) {
      setFormData({
        title: item.title,
        content: item.content,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  const hasChanges = useMemo(() => {
    if (!item) {
      return false;
    }
    return formData.title !== item.title || formData.content !== item.content;
  }, [formData, item]);

  const handleSubmit = () => {
    if (!item) {
      return;
    }

    onSave({
      ...item,
      title: formData.title,
      content: formData.content,
    });
  };

  const handleResetToDefault = () => {
    if (!item || !("default_content" in item)) {
      return;
    }
    setFormData({
      title: item.title,
      content: item.default_content,
    });
  };

  const handleClose = () => {
    if (hasChanges) {
      setShowDiscardAlert(true);
    } else {
      onClose();
    }
  };

  const handleDiscardChanges = () => {
    setShowDiscardAlert(false);
    onClose();
    setFormData({
      title: "",
      content: "",
    });
  };

  const showResetButton = item && "default_content" in item;

  return (
    <>
      <Dialog onOpenChange={handleClose} open={isOpen}>
        <DialogContent variant="fullScreen">
          <DialogHeader>
            <DialogTitle>{dialogTitle ?? t`Edit Description`}</DialogTitle>
            {dialogDescription ? <DialogDescription>{dialogDescription}</DialogDescription> : null}
          </DialogHeader>

          <Stack css={{ gap: "4", p: "6", overflowY: "auto" }}>
            <Stack>
              <InputField
                label={t`Title`}
                onChange={(e) => setFormData((prev) => ({ ...prev, title: e.target.value }))}
                placeholder={t`Enter a title for the knowledge item`}
                value={formData.title}
              />
            </Stack>

            <Stack>
              <Textarea
                cols={100}
                label={t`Description`}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    content: e.target.value ?? "",
                  }))
                }
                placeholder={t`Enter a description for the knowledge item`}
                rows={20}
                value={formData.content}
              />
            </Stack>
          </Stack>

          <DialogFooter>
            {showResetButton ? (
              <Button onClick={handleResetToDefault} style={{ marginRight: "auto" }} variant="text">
                <FontAwesomeIcon icon={faRotateLeft} />
                {t`Reset to Default`}
              </Button>
            ) : null}
            <Button onClick={handleClose} variant="secondary">
              {t`Cancel`}
            </Button>
            <Button disabled={!hasChanges || isSaving} onClick={handleSubmit} variant="primary">
              {isSaving ? t`Saving...` : t`Save`}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog onOpenChange={setShowDiscardAlert} open={showDiscardAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t`Discard Changes`}</AlertDialogTitle>
            <AlertDialogDescription>
              {t`Are you sure you want to discard your changes? This action cannot be undone.`}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setShowDiscardAlert(false)}>
              {t`Cancel`}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleDiscardChanges} variant="primary">
              {t`Discard Changes`}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export { EditKnowledgeItemDialog };
