import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import {
  getGetRolePeersQueryKey,
  useGetRolePeers,
} from "@services/api/generated/webserver/endpoints/organization/organization";
import { PeerResponse } from "@services/api/generated/webserver/models/peerResponse";
import { css } from "@styled-system/css";
import { ColumnDef } from "@tanstack/react-table";
import { Button, SimpleTable } from "doowii-ui";
import { useCallback, useState } from "react";
import { buildRoleOrder, getChildRoleName, getRoleLabel } from "../utils";

interface RoleTableProps {
  roleState: {
    currentRole?: string;
    parentLevelName?: string;
    parentUserName?: string;
  };
  setRoleState: React.Dispatch<
    React.SetStateAction<{
      currentRole?: string;
      parentLevelName?: string;
      parentUserName?: string;
    }>
  >;
  setCrumbs: React.Dispatch<
    React.SetStateAction<
      {
        role: string;
        name: string;
        label: string;
      }[]
    >
  >;
}
const NoPeersFound = () => {
  const { t } = useLingui();
  return (
    <div
      className={css({ display: "flex", justifyContent: "center", alignItems: "center", p: "xl" })}
    >
      {t`No peers found`}
    </div>
  );
};

const ErrorMessage = () => {
  const { t } = useLingui();
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: "xl",
        color: "error.base",
      })}
    >
      {t`Error loading peers data. Please try again later.`}
    </div>
  );
};

const RoleTable = ({ roleState, setRoleState, setCrumbs }: RoleTableProps) => {
  const { t } = useLingui();
  const { organizationDoc } = useAuth();
  const roleHierarchy = organizationDoc?.role_hierarchy || {};
  const roleOrder = buildRoleOrder(roleHierarchy);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const isLowestRole = roleState.currentRole === roleOrder[roleOrder.length - 1];
  const {
    data: peerResponse,
    isLoading,
    isError,
  } = useGetRolePeers(
    {
      page: pagination.pageIndex,
      page_size: pagination.pageSize,
      parent_level_name: roleState.parentLevelName,
      parent_user_name: roleState.parentUserName,
      current_peer_level: roleState.currentRole,
    },
    {
      query: {
        queryKey: getGetRolePeersQueryKey({
          parent_level_name: roleState.parentLevelName,
          parent_user_name: roleState.parentUserName,
          current_peer_level: roleState.currentRole,
          page: pagination.pageIndex,
          page_size: pagination.pageSize,
        }),
        retry: false,
      },
    }
  );

  const handleExpandPeer = useCallback(
    (row: PeerResponse["peers"][0]) => {
      setPagination({
        pageIndex: 0,
        pageSize: 10,
      });

      setRoleState((prev) => ({
        ...prev,
        parentLevelName: roleState.currentRole,
        parentUserName: row.MENTOR_NAME,
      }));
      setCrumbs((prev) => [
        ...prev,
        {
          role: roleState.currentRole,
          name: row.MENTOR_NAME,
          label: getRoleLabel(roleHierarchy, roleState.currentRole),
        },
      ]);
      const nextLevel = getChildRoleName(roleHierarchy, roleState.currentRole);
      setRoleState((prev) => ({
        ...prev,
        currentRole: nextLevel,
      }));
    },
    [roleState, roleHierarchy, setCrumbs, setRoleState]
  );

  const columns: ColumnDef<PeerResponse["peers"][0]>[] = [
    {
      header: t`Current Role`,
      cell: () => <div>{getRoleLabel(roleHierarchy, roleState.currentRole)}</div>,
    },
    {
      header: t`Mentor Name`,
      accessorKey: "MENTOR_NAME",
      filterFn: "includesString",
    },
    {
      header: t`Email Address`,
      accessorKey: "email_address",
      filterFn: "includesString",
    },
    {
      header: t`Expand`,
      enableHiding: false,
      cell: ({ row }) => (
        <Button
          aria-label={t`Expand peer details`}
          iconOnly={faChevronRight}
          // NOTE: This is a workaround to fix the weird bug where the function is being called twice
          onMouseDown={(e) => {
            handleExpandPeer(row.original);
          }}
          variant="dropdown"
          disabled={isLowestRole}
        />
      ),
    },
  ];

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <div className={css({ display: "flex", gap: "md", flexDirection: "column" })}>
      <SimpleTable
        columns={columns}
        data={peerResponse?.peers ?? []}
        emptyComponent={<NoPeersFound />}
        loading={
          isLoading ||
          !roleState.currentRole ||
          !roleState.parentLevelName ||
          !roleState.parentUserName
        }
        paginationMode="server-side"
        pageCount={Math.ceil((peerResponse?.total ?? 0) / pagination.pageSize)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </div>
  );
};

export { RoleTable };
