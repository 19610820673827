import { useDebounce } from "@uidotdev/usehooks";
import { RefObject, useEffect, useState } from "react";

interface InViewOptions {
  rootMargin?: string;
  threshold?: number | number[];
  triggerOnce?: boolean;
}

/**
 * Custom hook that tracks whether an element is in the viewport
 * @param ref React ref object for the element to observe
 * @param options Configuration options for the IntersectionObserver
 * @returns Boolean indicating if the element is in view
 */
export const useInView = (
  ref: RefObject<Element>,
  { rootMargin = "0px", threshold = 0, triggerOnce = false }: InViewOptions = {}
): boolean => {
  const [inView, setInView] = useState<boolean>(false);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const debouncedInView = useDebounce(inView, 300);

  useEffect(() => {
    const element = ref.current;
    // Return early if no element
    if (!element) {
      return;
    }

    const newObserver = new IntersectionObserver(
      (entries) => {
        // We're only observing one element, so we can use the first entry
        const entry = entries[0];

        // Update state when intersection status changes
        setInView(entry.isIntersecting);
      },
      { rootMargin, threshold }
    );

    // Start observing the element
    newObserver.observe(element);

    // Set the observer to the new observer
    setObserver(newObserver);

    // Clean up observer on unmount
    return () => {
      newObserver?.disconnect();
    };
  }, [ref, rootMargin, threshold, triggerOnce]);

  useEffect(() => {
    // If triggerOnce is true and the element is in view,
    // disconnect the observer so it only fires once
    if (triggerOnce && debouncedInView) {
      observer?.disconnect();
    }
  }, [debouncedInView, observer, triggerOnce]);

  return debouncedInView;
};
