/**
 * Common utility functions for chart data transformations
 */

import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { Aggregations } from "@services/api/generated/webserver/models/aggregations";
import { ChartConfig } from "@utils/chartTransformations";

// Define types for row data
export type RowArray = unknown[];

// Default maximum number of unique breakdown values to display before grouping as "Other"
export const MAX_BREAKDOWN_VALUES = Infinity;

// Maximum number of categories to display before grouping as "Other"
export const MAX_CATEGORIES = Infinity;

/**
 * Gets a value from a row using column name
 * @param row Array of values
 * @param columnName Column name
 * @param columnNames Array of column names
 * @returns The value at the specified column
 */
export const getColumnValue = (
  row: RowArray,
  columnName: string,
  columnNames: string[]
): unknown => {
  const columnIndex = columnNames.indexOf(columnName.toLowerCase());
  if (columnIndex === -1) {
    return undefined;
  }
  return row[columnIndex];
};

/**
 * Aggregates an array of values using the specified method
 * @param values Array of values to aggregate
 * @param method Aggregation method (sum, avg, max, min, count)
 * @returns Aggregated value
 */
export const aggregateValues = (
  values: Array<number | null | undefined>,
  method: Aggregations = Aggregations.sum
): number => {
  // Filter out null and undefined values
  const filteredValues = values.filter(
    (v): v is number => v !== null && v !== undefined && !isNaN(v)
  );

  if (filteredValues.length === 0) {
    return 0;
  }

  switch (method) {
    case Aggregations.sum:
      return filteredValues.reduce((sum, value) => sum + value, 0);

    case Aggregations.mean:
      return filteredValues.reduce((sum, value) => sum + value, 0) / filteredValues.length;

    case Aggregations.max:
      return Math.max(...filteredValues);

    case Aggregations.min:
      return Math.min(...filteredValues);

    case Aggregations.count:
      return filteredValues.length;

    default:
      return filteredValues.reduce((sum, value) => sum + value, 0);
  }
};

/**
 * Groups an array of rows by a specified column
 * @param rows Array of row arrays
 * @param columnKey Column key to group by
 * @param columnNames Array of column names
 * @returns Object with groups as keys and arrays of rows as values
 */
export const groupDataByColumn = (
  rows: RowArray[],
  columnKey: string,
  columnNames: string[]
): Record<string, RowArray[]> => {
  if (rows.length === 0) {
    return {};
  }

  return rows.reduce(
    (groups, row) => {
      // Get the value to group by
      const value = getColumnValue(row, columnKey, columnNames);
      const groupKey = String(value ?? "undefined");

      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }

      groups[groupKey].push(row);
      return groups;
    },
    {} as Record<string, RowArray[]>
  );
};

/**
 * Determines if a sort configuration is present and relevant for the chart
 * @param chartConfig Chart configuration with optional table_state
 * @param columnToCheck Column name to check against sort configuration
 * @returns Boolean indicating if the sort is relevant
 */
export const hasRelevantSort = (chartConfig: ChartConfig, columnToCheck: string): boolean => {
  // Check if sorting is defined in table_state
  if (!chartConfig.table_state?.sorting?.length) {
    return false;
  }

  // Check if any sort column matches the column to check
  return chartConfig.table_state.sorting.some((sort) => sort.id === columnToCheck);
};

/**
 * Gets sort configuration for the specified column if it exists
 * @param chartConfig Chart configuration with optional table_state
 * @param columnToCheck Column name to get sort configuration for
 * @returns Sort configuration or undefined if not found
 */
export const getColumnSortConfig = (
  chartConfig: ChartConfig,
  columnToCheck: string
): { id: string; desc: boolean } | undefined => {
  if (!chartConfig.table_state?.sorting?.length) {
    return undefined;
  }

  return chartConfig.table_state.sorting.find((sort) => sort.id === columnToCheck);
};

/**
 * Gets the first relevant sort column from the table state
 * @param chartConfig Chart configuration with optional table_state
 * @param columns Array of column names to check
 * @returns The first sort column that matches or undefined
 */
export const getFirstRelevantSortColumn = (
  chartConfig: ChartConfig,
  columns: string[]
): { id: string; desc: boolean } | undefined => {
  if (!chartConfig.table_state?.sorting?.length) {
    return undefined;
  }

  return chartConfig.table_state.sorting.find((sort) => columns.includes(sort.id));
};

/**
 * Gets the top N most frequent values in a dataset
 * @param rows Raw data rows
 * @param columnName Name of the column to analyze
 * @param columnNames Array of all column names
 * @param maxValues Maximum number of unique values to return (defaults to MAX_BREAKDOWN_VALUES)
 * @returns Object containing the top values and a map of all value counts
 */
export const getTopBreakdownValues = (
  rows: RowArray[],
  columnName: string,
  columnNames: string[],
  maxValues: number = MAX_BREAKDOWN_VALUES
): { values: string[]; valueCounts: Map<string, number> } => {
  // Count occurrences of each unique value
  const valueCounts = new Map<string, number>();

  rows.forEach((row) => {
    const value = String(getColumnValue(row, columnName, columnNames) ?? "undefined");
    valueCounts.set(value, (valueCounts.get(value) || 0) + 1);
  });

  // Convert to array and sort by frequency
  const sortedValues = Array.from(valueCounts.entries())
    .sort((a, b) => b[1] - a[1])
    .map(([value]) => value);

  // Take only the top N values
  const topValues = sortedValues.slice(0, maxValues);

  return { values: topValues, valueCounts };
};

/**
 * Gets ordered breakdown values based on table state sorting or frequency if no sorting
 * @param data Raw data rows
 * @param breakdownColumn Column to extract breakdown values from
 * @param columnNames Normalized column names
 * @param chartConfig Chart configuration with optional table_state
 * @param maxValues Maximum number of breakdown values to return
 * @returns Ordered array of breakdown values
 */
export const getOrderedBreakdownValues = (
  rows: RowArray[],
  breakdownColumn: string,
  columnNames: string[],
  chartConfig: ChartConfig,
  maxValues: number = MAX_BREAKDOWN_VALUES
): string[] => {
  // Check if we need to preserve the sort order from table state
  const sortConfig = getColumnSortConfig(chartConfig, breakdownColumn);

  if (sortConfig) {
    // When sorting by breakdown column, preserve the input order (data is already sorted)
    // Extract unique breakdown values while preserving order of first occurrence
    const seenValues = new Set<string>();
    const orderedValues: string[] = [];

    // If sorting is ascending, collect in order they appear in the pre-sorted data
    // If sorting is descending, data is already pre-sorted in descending order
    rows.forEach((row) => {
      const value = String(getColumnValue(row, breakdownColumn, columnNames) ?? "undefined");
      if (!seenValues.has(value)) {
        seenValues.add(value);
        orderedValues.push(value);
      }
    });

    // No need to reverse here - the tableStore data is already properly sorted
    // Take only the first maxValues
    return orderedValues.slice(0, maxValues);
  } else {
    // If no relevant sorting, fall back to frequency-based ordering
    const { values } = getTopBreakdownValues(rows, breakdownColumn, columnNames, maxValues);
    return values;
  }
};

/**
 * Gets ordered category values based on table state sorting or frequency if no sorting
 * @param data Raw data from API or test data
 * @param categoryColumn Column to extract categories from
 * @param columnNames Normalized column names
 * @param chartConfig Chart configuration with optional table_state
 * @param maxValues Maximum number of categories to return
 * @returns Ordered array of category values
 */
export const getOrderedCategoryValues = (
  data: RetrieveAllResponse,
  categoryColumn: string,
  columnNames: string[],
  chartConfig: ChartConfig,
  maxValues: number = MAX_CATEGORIES
): { values: string[]; otherCategories: string[] } => {
  const { rows } = data;

  // Check if we need to preserve the sort order from table state
  const categorySortConfig = getColumnSortConfig(chartConfig, categoryColumn);

  // Get all available sort configurations
  const numericSortColumns =
    chartConfig.table_state?.sorting?.filter((sort) =>
      // Check if this is a numerical column in the chart
      Object.entries(chartConfig.column_types || {}).some(
        ([col, type]) => col === sort.id && type === "numerical"
      )
    ) || [];

  // Use the first numerical sort column if available
  const numericSortConfig = numericSortColumns.length > 0 ? numericSortColumns[0] : undefined;

  if (categorySortConfig) {
    // Case 1: When sorting by category column, preserve the input order (data is already sorted)
    // Extract unique category values while preserving order of first occurrence
    const seenCategories = new Set<string>();
    const orderedCategories: string[] = [];

    // Collect categories in order they appear in the pre-sorted data
    rows.forEach((row) => {
      const value = String(getColumnValue(row, categoryColumn, columnNames) ?? "undefined");
      if (!seenCategories.has(value)) {
        seenCategories.add(value);
        orderedCategories.push(value);
      }
    });

    // Take only the first maxValues categories
    const limitedCategories = orderedCategories.slice(0, maxValues);
    // Identify categories that will be grouped as "Other"
    const otherCategories = orderedCategories.slice(maxValues);

    return { values: limitedCategories, otherCategories };
  } else if (numericSortConfig) {
    // Case 2: When sorting by numerical column, we need to:
    // 1. Group by category
    // 2. Compute aggregated values for each category
    // 3. Sort categories by these aggregated values

    // Get the numerical column being sorted
    const numericColumn = numericSortConfig.id;
    const aggregation = chartConfig.column_aggregations?.[numericColumn] || Aggregations.sum;

    // Group data by category
    const groupedData = groupDataByColumn(rows, categoryColumn, columnNames);

    // Calculate aggregated value for each category
    const categoryValues: Array<{ category: string; value: number }> = [];

    Object.entries(groupedData).forEach(([category, categoryRows]) => {
      // Extract values for this category and numerical column
      const values: number[] = [];
      categoryRows.forEach((row) => {
        const value = getColumnValue(row, numericColumn, columnNames);
        if (typeof value === "number") {
          values.push(value);
        }
      });

      // Apply aggregation
      const aggregatedValue = aggregateValues(values, aggregation as Aggregations);
      categoryValues.push({ category, value: aggregatedValue });
    });

    // Sort categories by their aggregated values
    categoryValues.sort(
      (a, b) =>
        // Apply the sort direction from the sort config
        numericSortConfig.desc
          ? b.value - a.value // Descending
          : a.value - b.value // Ascending
    );

    // Extract categories in sorted order
    const sortedCategories = categoryValues.map((item) => item.category);

    // Take only the first maxValues categories
    const limitedCategories = sortedCategories.slice(0, maxValues);
    // Identify categories that will be grouped as "Other"
    const otherCategories = sortedCategories.slice(maxValues);

    return { values: limitedCategories, otherCategories };
  } else {
    // Case 3: If no relevant sorting, fall back to frequency-based ordering
    const { values, valueCounts } = getTopBreakdownValues(
      rows,
      categoryColumn,
      columnNames,
      maxValues
    );

    // Get all category values that aren't in the top N
    const allCategories = new Set<string>();
    rows.forEach((row) => {
      const value = String(getColumnValue(row, categoryColumn, columnNames) ?? "undefined");
      allCategories.add(value);
    });

    const otherCategories = Array.from(allCategories).filter((cat) => !values.includes(cat));

    return { values, otherCategories };
  }
};

/**
 * Gets unique values from an array of rows for a specific column
 * @param rows Array of row arrays
 * @param columnKey Column key to get unique values for
 * @param columnNames Array of column names
 * @returns Array of unique values
 */
export const getUniqueValues = (
  rows: RowArray[],
  columnKey: string,
  columnNames: string[]
): string[] => {
  if (rows.length === 0) {
    return [];
  }

  const values = rows.map((row) => getColumnValue(row, columnKey, columnNames));
  return [...new Set(values.map((v) => String(v ?? "undefined")))];
};

/**
 * Determines if a column contains numeric data
 * @param rows Array of row arrays
 * @param columnKey Column key to check
 * @param columnNames Array of column names
 * @returns True if the column contains numeric data
 */
export const isNumericColumn = (
  rows: RowArray[],
  columnKey: string,
  columnNames: string[]
): boolean => {
  if (rows.length === 0) {
    return false;
  }

  // Check a sample of values to determine if the column is numeric
  const sampleSize = Math.min(rows.length, 10);
  const sample = rows.slice(0, sampleSize);

  return sample.some((row) => {
    const value = getColumnValue(row, columnKey, columnNames);
    return typeof value === "number" && !isNaN(value);
  });
};
