import { ColumnType } from "@doowii-types/chart";
import { Result } from "@doowii-types/chat";
import { t } from "@lingui/core/macro";
import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { ChartConfigTableStateAnyOf } from "@services/api/generated/webserver/models/chartConfigTableStateAnyOf";
import { ColumnDataType } from "@services/api/generated/webserver/models/columnDataType";
import {
  ColumnDef,
  createTable,
  FilterFnOption,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  RowData,
  Table,
  TableState,
} from "@tanstack/react-table";
import { convertCase } from "@utils/convertCase";
import { memoize } from "proxy-memoize";
import { create } from "zustand";

import { ChartConfig } from "../utils/chartTransformations";
import { debouncedSaveChartConfig } from "./utils/apiCalls";

declare module "@tanstack/react-table" {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    // need both until we fully move to using only generated types
    columnType?: ColumnDataType | ColumnType;
  }
}

// Apollo conversation message structure
interface ConversationMessage {
  author: "User" | "Doowii";
  message: string;
}

// Define the store structure
interface TableStore {
  // Raw data
  rawData: RetrieveAllResponse | null;
  setUpTableStore: (data: RetrieveAllResponse, chartConfig: ChartConfig) => void;

  // Table instance (created once and reused)
  tableInstance: Table<unknown[]> | null;
  tableState: TableState | null;
  tableId: string;

  // chat/pin info for API calls
  parentDocId: string;
  parentDocType: string;
  docId: string;
  sql: string;

  // chart config
  chartConfig: ChartConfig | null;

  // Apollo conversation history
  conversation: ConversationMessage[];
  addMessage: (message: ConversationMessage) => void;
  setConversation: (messages: ConversationMessage[]) => void;

  // Actions
  getFilteredRows: () => RetrieveAllResponse | null;
  updateChartConfig: (newConfig: ChartConfig) => void;
  saveStoreChartConfig: () => void;

  // misc
  shouldAutoSave: boolean;
  // Pins auto update from firestore subscription but chats do not so manually update the result
  // TODO: remove this once we have a better way to update the chat results
  updateCurrentChatResults: React.Dispatch<React.SetStateAction<Result[]>> | undefined;
}

// Create the store
const createTableStore = ({
  parentDocId,
  parentDocType,
  docId,
  sql,
  tableId,
}: {
  parentDocId: string;
  parentDocType: string;
  docId: string;
  sql: string;
  tableId: string;
}) =>
  create<TableStore>((set, get) => {
    // Create a memoized function to calculate filtered rows
    // https://github.com/dai-shi/proxy-memoize
    const memoizedGetFilteredRows = memoize(
      (state: {
        tableInstance: Table<unknown[]> | null;
        rawData: RetrieveAllResponse | null;
        tableState: TableState | null;
      }) => {
        const { tableInstance, rawData, tableState } = state;
        if (!tableInstance || !rawData || !tableState) {
          return null;
        }

        // Get filtered model - this directly depends on the current state
        // This ensures proxy-memoize tracks the dependency on the filtered model
        const filteredModel = tableInstance.getSortedRowModel();

        // Get filtered rows from the filtered model
        const filteredRows = filteredModel.rows.map((row) => row.original) as (string | number)[][];

        const filteredResponse: RetrieveAllResponse = {
          rows: filteredRows,
          total_rows: filteredRows.length,
          data_types: rawData.data_types,
          hyperlink_columns: rawData.hyperlink_columns || {},
          column_names: rawData.column_names,
        };
        return filteredResponse;
      }
    );

    return {
      // Initial state
      rawData: null,
      tableInstance: null,
      tableState: null,
      parentDocId,
      parentDocType,
      docId,
      sql,
      tableId,
      chartConfig: null,
      // Conversation state
      conversation: [
        {
          author: "Doowii",
          message: t`Hello! I can help you improve your chart. Ask me anything about customizing your visualization.`,
        },
      ],
      // Setters
      setUpTableStore: (data, chartConfig) => {
        const initialChartConfig = chartConfig;

        // Process the raw data to convert types before setting up the table
        const processedData = {
          ...data,
          rows: data.rows.map((row) =>
            data.column_names.map((name, index) => {
              const dataType = data.data_types[name];
              const rawValue = row[index];
              const columnType = initialChartConfig?.column_types[name];

              // Handle null values
              if (rawValue === null || rawValue === undefined) {
                return "null";
              }

              // Handle categorical values
              // Must check this first since IDs may have number data type but should be treated
              // categorical (strings) otherwise filters will not work
              if (columnType === ColumnDataType.categorical) {
                return String(rawValue).trim();
              }

              // Convert values based on data type
              if (dataType === "integer" || dataType === "number") {
                return typeof rawValue === "string" ? parseInt(rawValue, 10) : Number(rawValue);
              }

              if (dataType === "float") {
                if (typeof rawValue === "string") {
                  return parseFloat(parseFloat(rawValue).toFixed(2));
                } else if (typeof rawValue === "number") {
                  return parseFloat(rawValue.toFixed(2));
                }
                return rawValue;
              }

              // Default to string for all other types
              return String(rawValue).trim();
            })
          ),
        };

        // // Store the processed data
        // set({ rawData: processedData });

        // Create columns from data
        const columns: ColumnDef<unknown[]>[] = processedData.column_names.map((name, index) => {
          const columnType = initialChartConfig?.column_types?.[name] || ColumnDataType.categorical;

          // Determine the appropriate filter function based on column type
          // https://tanstack.com/table/latest/docs/guide/column-filtering#filterfns
          const getFilterFn = () => {
            // For numerical columns, use the default between filter
            if (columnType === ColumnDataType.numerical) {
              return "inNumberRange" as const;
            }

            // For categorical columns, use  filter
            return "specificValueMatchFilter" as unknown as FilterFnOption<unknown[]>;
          };

          return {
            id: name,
            accessorFn: (row: unknown[]) => row[index],
            header: convertCase(name),
            cell: (info) => info.getValue(),
            enableColumnFilter: true,
            size: columnType === ColumnDataType.numerical ? 150 : 250,
            enableResizing: false,
            filterFn: getFilterFn(),
            meta: {
              columnType,
            },
          };
        });

        const tableInstance = createTable({
          data: processedData.rows,
          columns,
          getCoreRowModel: getCoreRowModel(),
          getFilteredRowModel: getFilteredRowModel(),
          getSortedRowModel: getSortedRowModel(),
          // Add faceted model features
          getFacetedRowModel: getFacetedRowModel(),
          getFacetedUniqueValues: getFacetedUniqueValues(),
          getFacetedMinMaxValues: getFacetedMinMaxValues(),
          initialState: {
            globalFilter: null, // not being set to null by default
            ...initialChartConfig?.table_state,
          },
          filterFns: {
            specificValueMatchFilter: (row, columnId, filterValue) => {
              const rowValue = row.getValue(columnId);
              return Array.isArray(filterValue)
                ? filterValue.some((value) => rowValue === value)
                : rowValue === filterValue;
            },
          },
          state: {},
          onStateChange: () => {}, // Will be replaced
          renderFallbackValue: null,
        });

        const initialState = tableInstance.initialState;
        //   Headless table isntances require manual state management
        //   This automatically updates the table state when the store's state changes
        // https://tanstack.com/table/latest/docs/framework/vanilla/examples/pagination?path=examples/vanilla/pagination/src/useTable.ts
        tableInstance.setOptions((prev) => ({
          ...prev,
          state: get().tableState || initialState,
          onStateChange: (updater) => {
            const currentState = get().tableState || initialState;
            const { chartConfig: currentChartConfig } = get();

            // Update zustand store's state
            // tanstack onStateChange callbacks work exactly like the setState functions in React.
            // The updater values can either be a new state value or a callback function
            // that takes the previous state value and returns the new state value.
            // https://tanstack.com/table/latest/docs/framework/react/guide/table-state#2-updaters-can-either-be-raw-values-or-callback-functions
            let newState;
            if (typeof updater === "function") {
              newState = updater(currentState);
              set({ tableState: newState });
            } else {
              newState = updater;
              set({ tableState: newState });
            }
            // Using `onStateChange` means the tanstack stable internal state is not updated
            // Update the table instance directly
            tableInstance.setOptions((prev) => ({
              ...prev,
              state: get().tableState || initialState,
            }));

            get().updateChartConfig({
              ...currentChartConfig,
              table_state: newState as unknown as ChartConfigTableStateAnyOf,
            });
          },
        }));

        // if chartConfig is missing a table_state, add it
        if (!initialChartConfig?.table_state) {
          initialChartConfig.table_state = tableInstance.getState();
        }

        // Set the table instance in the store
        set({
          tableInstance,
          tableState: tableInstance.getState(),
          chartConfig: initialChartConfig,
          rawData: processedData,
        });
      },

      // Conversation methods
      addMessage: (message: ConversationMessage) => {
        set((state) => ({
          conversation: [...state.conversation, message],
        }));
      },

      setConversation: (messages: ConversationMessage[]) => {
        set({ conversation: messages });
      },

      updateChartConfig: (newConfig: ChartConfig) => {
        set({ chartConfig: newConfig });
        if (get().shouldAutoSave) {
          get().saveStoreChartConfig();
        }
      },

      saveStoreChartConfig: () => {
        const { parentDocType, parentDocId, docId, chartConfig } = get();
        debouncedSaveChartConfig(parentDocType, parentDocId, docId, {
          ...chartConfig,
        } as ChartConfig);
        get().updateCurrentChatResults?.((allResults: Result[]) =>
          allResults.map((r) => (r.id === docId ? { ...r, chartConfig } : r) as unknown as Result)
        );
      },

      // Use the memoized function to get filtered rows
      getFilteredRows: () =>
        memoizedGetFilteredRows({
          tableInstance: get().tableInstance,
          rawData: get().rawData,
          tableState: get().tableState,
        }),

      shouldAutoSave: true,

      updateCurrentChatResults: undefined,
    };
  });

// Store factory with Map to cache instances
const tableStores = new Map<string, ReturnType<typeof createTableStore>>();

const createTableStoreFactory =
  (stores: typeof tableStores) =>
  ({
    parentDocId,
    parentDocType,
    docId,
    sql,
  }: {
    parentDocId: string;
    parentDocType: string;
    docId: string;
    sql: string;
    initialData?: RetrieveAllResponse;
    initialConfig?: ChartConfig;
  }) => {
    const tableId = `${parentDocId}::${parentDocType}::${docId}::${sql}`;
    if (!stores.has(tableId)) {
      const newStore = createTableStore({
        parentDocId,
        parentDocType,
        docId,
        sql,
        tableId,
      });
      stores.set(tableId, newStore);
    }
    return stores.get(tableId);
  };

// Export the factory function
export const getOrCreateTableStore = createTableStoreFactory(tableStores);
