import { fetchQueryMetaData } from "@api/retriever";
import { Trans, useLingui } from "@lingui/react/macro";
import { Center, VStack } from "@styled-system/jsx";
import { Spinner, Text } from "doowii-ui";
import { FC, useEffect, useState } from "react";

import SQLDiagram from "./SQLDiagram";

interface SQLEditorProps {
  sql: string;
}

export const SQLDiagramHandler: FC<SQLEditorProps> = ({ sql }) => {
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState({});
  const [joins, setJoins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useLingui();
  const handleUpdateSQL = async (sql: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { tables, joins, columns } = await fetchQueryMetaData(sql);
      setTables(tables);
      setJoins(joins);
      setColumns(columns);
    } catch (error) {
      console.error("Error fetching SQL metadata: ", error);
      setError(t`Failed to load SQL metadata. Please try again later.`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sql) {
      handleUpdateSQL(sql);
    }
  }, [sql]);

  return (
    <Center h="full">
      {isLoading ? (
        <VStack padding="medium" textAlign="center">
          <Spinner />
          <Text>
            <Trans>Loading SQL diagram...</Trans>
          </Text>
        </VStack>
      ) : error ? (
        <Text>{error}</Text>
      ) : tables.length > 0 ? (
        <SQLDiagram columns={columns} joins={joins} tables={tables} />
      ) : (
        <Text>
          <Trans>No tables found in the SQL query.</Trans>
        </Text>
      )}
    </Center>
  );
};
