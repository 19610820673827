import { useOrgUsers } from "@context/users";
import { User } from "@context/users/users.i";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import { auth, disableAccount } from "@services/firebase";
import { ConfirmationDialog, useToast } from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  selectedUser: User;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>;
}
const DeleteUserDialog: React.FC<Props> = ({
  isOpen,
  selectedUser,
  setIsOpen,
  setSelectedUser,
}) => {
  const { t } = useLingui();
  const { signOut, userDocument } = useAuth();
  const { fetchUsersInOrganization } = useOrgUsers();
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setSelectedUser(null);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await disableAccount(selectedUser, userDocument);
      await fetchUsersInOrganization();
      toast({
        title: t`User deleted`,
        description: t`User has been deleted successfully`,
        status: "success",
      });
      if (selectedUser.email === auth.currentUser?.email) {
        await signOut?.();
      }
      handleClose();
    } catch (error) {
      console.error(error);
      toast({
        title: t`Error deleting user`,
        description: t`An error occurred while deleting the user`,
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmationDialog
      cancelButtonText={t`Cancel`}
      confirmationButtonText={t`Delete`}
      description={t`Are you sure you want to delete this user?`}
      destructive
      icon={faTrash}
      isLoadingAction={loading}
      isOpen={isOpen}
      onCancel={handleClose}
      onConfirm={handleDelete}
      setIsOpen={setIsOpen}
      title={t`Delete user`}
    />
  );
};

export default DeleteUserDialog;
