import { ColumnType } from "@doowii-types/chart";
import { DataVizTypes } from "@doowii-types/viz";
import { useLingui } from "@lingui/react/macro";
import { Aggregations } from "@services/api/generated/webserver/models/aggregations";
import { CategoryType } from "@services/api/generated/webserver/models/categoryType";
import { css } from "@styled-system/css";
import { Box, Flex, HStack, VStack } from "@styled-system/jsx";
import { ChartConfig } from "@utils/chartTransformations";
import {
  Accordion,
  Badge,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from "doowii-ui";
import { useCallback, useMemo } from "react";

interface ChartColumnSelectorProps {
  chartConfig: ChartConfig;
  onChange: (chartConfig: ChartConfig) => void;
}

/**
 * ChartColumnSelector component for selecting columns to visualize in charts
 * Supports selection of categorical columns (up to 2) and any number of series columns
 * with chart-type specific validations
 */
const ChartColumnSelector = ({ chartConfig, onChange }: ChartColumnSelectorProps) => {
  const { t } = useLingui();

  // Get the current category selection
  const selectedCategory = useMemo(() => {
    if (!chartConfig?.column_types || Object.keys(chartConfig.column_types).length === 0) {
      return "";
    }

    return (
      Object.entries(chartConfig.column_types)
        .filter(([_, type]) => type === ColumnType.CATEGORICAL || type === ColumnType.TEMPORAL)
        .map(([column]) => column)
        .find(
          (column) =>
            chartConfig.columns.includes(column) &&
            (!chartConfig.column_grouping ||
              chartConfig.column_grouping[column] !== CategoryType.breakdown)
        ) || ""
    );
  }, [chartConfig?.column_types, chartConfig?.columns, chartConfig?.column_grouping]);

  // Get the current breakdown selection
  const selectedBreakdown = useMemo(() => {
    if (!chartConfig?.column_grouping || Object.keys(chartConfig.column_grouping).length === 0) {
      return "";
    }

    return (
      Object.entries(chartConfig.column_grouping).find(
        ([_, type]) => type === CategoryType.breakdown
      )?.[0] || ""
    );
  }, [chartConfig?.column_grouping]);

  // Process column options for selection
  const categoricalOptions = useMemo(() => {
    if (!chartConfig?.column_types) {
      return [];
    }

    return Object.entries(chartConfig.column_types)
      .filter(([_, type]) => type === ColumnType.CATEGORICAL || type === ColumnType.TEMPORAL)
      .map(([name]) => ({
        label: name,
        value: name,
      }));
  }, [chartConfig?.column_types]);

  const seriesOptions = useMemo(() => {
    if (!chartConfig?.column_types) {
      return [];
    }

    return Object.entries(chartConfig.column_types)
      .filter(([_, type]) => type === ColumnType.NUMERICAL)
      .map(([name]) => ({
        label: name,
        value: name,
      }));
  }, [chartConfig?.column_types]);

  const selectedSeries = useMemo(() => {
    if (!chartConfig?.columns) {
      return [];
    }

    return chartConfig.columns.filter(
      (col) => chartConfig.column_types[col] === ColumnType.NUMERICAL
    );
  }, [chartConfig?.columns, chartConfig?.column_types]);

  const maxSeries = useMemo(() => {
    if (!chartConfig?.suggestion) {
      return Infinity;
    }

    // Pie charts can only have one series
    if (chartConfig.suggestion === DataVizTypes.PIE_CHART) {
      return 1;
    }

    // Reasonable limit for other chart types
    return 6;
  }, [chartConfig?.suggestion]);

  // Handle selecting a primary category column
  const handleCategoryChange = useCallback(
    (value: string) => {
      if (!value || !chartConfig) {
        return;
      }

      // Start with current grouping
      const currentGrouping = { ...chartConfig.column_grouping };

      // Find any existing category column
      const existingCategory = Object.entries(currentGrouping).find(
        ([_, type]) => type === CategoryType.category
      )?.[0];

      // If there's an existing category and it's different, replace it
      if (existingCategory && existingCategory !== value) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete currentGrouping[existingCategory];
      }

      // Set the new category
      currentGrouping[value] = CategoryType.category;

      // Update columns to include the category & remove the existingCategory
      const newColumns = [...chartConfig.columns].filter((col) => col !== existingCategory);
      if (!newColumns.includes(value)) {
        newColumns.push(value);
      }

      const newConfig = {
        ...chartConfig,
        columns: newColumns,
        column_grouping: currentGrouping as Record<string, CategoryType>,
      } as ChartConfig;

      onChange(newConfig);
    },
    [chartConfig, onChange]
  );

  // Handle selecting a breakdown column
  const handleBreakdownChange = useCallback(
    (value: string) => {
      if (!value || !chartConfig) {
        return;
      }

      // Start with current grouping
      const currentGrouping = { ...chartConfig.column_grouping };

      // Find any existing breakdown column
      const existingBreakdown = Object.entries(currentGrouping).find(
        ([_, type]) => type === CategoryType.breakdown
      )?.[0];

      // If there's an existing breakdown and it's different, replace it
      if (existingBreakdown && existingBreakdown !== value) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete currentGrouping[existingBreakdown];
      }

      // Set the new breakdown
      currentGrouping[value] = CategoryType.breakdown;

      // Update columns to include the breakdown
      const newColumns = [...chartConfig.columns];
      if (!newColumns.includes(value)) {
        newColumns.push(value);
      }

      const newConfig = {
        ...chartConfig,
        columns: newColumns,
        column_grouping: currentGrouping as Record<string, CategoryType>,
      } as ChartConfig;

      onChange(newConfig);
    },
    [chartConfig, onChange]
  );

  // Handle removing a breakdown column
  const handleBreakdownRemove = useCallback(() => {
    if (!chartConfig) {
      return;
    }

    // Start with current grouping
    const currentGrouping = { ...chartConfig.column_grouping };

    // Find existing breakdown column
    const existingBreakdown = Object.entries(currentGrouping).find(
      ([_, type]) => type === CategoryType.breakdown
    )?.[0];

    if (existingBreakdown) {
      // Remove the breakdown column from grouping
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete currentGrouping[existingBreakdown];

      // Remove the breakdown column from columns
      const newColumns = chartConfig.columns.filter((col) => col !== existingBreakdown);

      // Update config
      const newConfig = {
        ...chartConfig,
        columns: newColumns,
        column_grouping: currentGrouping as Record<string, CategoryType>,
      } as ChartConfig;

      onChange(newConfig);
    }
  }, [chartConfig, onChange]);

  // Handle removing a category column
  const handleCategoryRemove = useCallback(() => {
    if (!chartConfig) {
      return;
    }

    // Start with current grouping
    const currentGrouping = { ...chartConfig.column_grouping };

    if (selectedCategory) {
      // Remove the category column from grouping
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete currentGrouping[selectedCategory];

      // Remove the category column from columns
      const newColumns = chartConfig.columns.filter((col) => col !== selectedCategory);

      // If there was a previous BREAKDOWN column, set it to CATEGORY
      const typeColumnId = Object.entries(currentGrouping).find(
        ([_, type]) => type === CategoryType.breakdown
      )?.[0];

      if (typeColumnId) {
        currentGrouping[typeColumnId] = CategoryType.category;
      }

      // Update config
      const newConfig = {
        ...chartConfig,
        columns: newColumns,
        column_grouping: currentGrouping as Record<string, CategoryType>,
      } as ChartConfig;

      onChange(newConfig);
    }
  }, [chartConfig, onChange, selectedCategory]);

  // Handle selecting a series column
  const handleSeriesAdd = useCallback(
    (value: string) => {
      if (!value || !chartConfig) {
        return;
      }

      // If we're at max series, don't add more
      if (selectedSeries.length >= maxSeries) {
        return;
      }

      // Update columns and add aggregation for the new series
      const newColumns = [...chartConfig.columns];
      if (!newColumns.includes(value)) {
        newColumns.push(value);
      }

      const newAggregations = { ...chartConfig.column_aggregations };
      if (!newAggregations[value]) {
        newAggregations[value] = Aggregations.sum;
      }

      const newConfig = {
        ...chartConfig,
        columns: newColumns,
        column_aggregations: newAggregations as Record<string, Aggregations>,
      } as ChartConfig;

      onChange(newConfig);
    },
    [chartConfig, maxSeries, selectedSeries.length, onChange]
  );

  // Handle removing a series column
  const handleRemoveSeries = useCallback(
    (seriesName: string) => {
      if (!chartConfig) {
        return;
      }

      // Remove the series from columns
      const newColumns = chartConfig.columns.filter((col) => col !== seriesName);

      // Remove the series from aggregations
      const newAggregations = { ...chartConfig.column_aggregations };
      // Use property assignment instead of delete
      newAggregations[seriesName] = undefined;

      const newConfig = {
        ...chartConfig,
        columns: newColumns,
        column_aggregations: newAggregations as Record<string, Aggregations>,
      } as ChartConfig;

      onChange(newConfig);
    },
    [chartConfig, onChange]
  );

  // Check if we can show breakdown selection
  // (only for charts that support it and when a category is selected)
  const showBreakdownSelect = useMemo(() => {
    if (!chartConfig?.suggestion) {
      return false;
    }

    // Pie charts can't have breakdown
    if (chartConfig.suggestion === DataVizTypes.PIE_CHART) {
      return false;
    }

    // Need a category first
    return selectedCategory !== "";
  }, [chartConfig?.suggestion, selectedCategory]);

  // Create chart constraint help text
  const getChartConstraintText = () => {
    if (!chartConfig?.suggestion) {
      return "";
    }

    switch (chartConfig.suggestion) {
      case DataVizTypes.PIE_CHART:
        return t`Pie charts require 1 category and 1 series`;

      case DataVizTypes.LINE_CHART:
        return t`Line charts work best with 1-2 categories and 1-6 series`;

      case DataVizTypes.BAR_CHART:
        return t`Bar charts work best with 1-2 categories and 1-6 series`;

      case DataVizTypes.SCATTER_CHART:
        return t`Scatter charts require 2 numerical series and an optional category`;

      default:
        return "";
    }
  };

  const breakDownOptions = categoricalOptions.filter((option) => option.value !== selectedCategory);

  // Content for the accordion
  const columnSelectorContent = (
    <HStack alignItems="flex-start" className={css({ width: "100%" })} gap="4">
      <Box className={css({ borderRight: "1px solid lightgray", pr: "4" })} width="50%">
        <VStack
          alignItems="flex-start"
          className={css({ minHeight: "10rem" })}
          gap="2"
          width="100%"
        >
          <Text level={2} style={{ fontWeight: "600" }}>
            {t`Main Category`}
          </Text>
          {selectedCategory ? (
            <Badge
              onRemove={handleCategoryRemove}
              onRemoveTooltip={t`Remove category`}
              style={{ background: "white", border: "1px solid lightgray" }}
              withIcons
            >
              {selectedCategory}
            </Badge>
          ) : (
            <Select onValueChange={handleCategoryChange} value={selectedCategory}>
              <SelectTrigger
                disabled={categoricalOptions.length === 0}
                size="sm"
                style={{ marginTop: "0" }}
              >
                <SelectValue placeholder={t`Select category`} />
              </SelectTrigger>
              <SelectContent>
                {categoricalOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}

          {showBreakdownSelect ? (
            <VStack
              alignItems="flex-start"
              className={css({ minHeight: "4.5rem" })}
              gap="2"
              width="100%"
            >
              <Text level={2} style={{ fontWeight: "600" }}>
                {t`Breakdown Category`}
              </Text>
              {selectedBreakdown ? (
                <Badge
                  onRemove={handleBreakdownRemove}
                  onRemoveTooltip={t`Remove breakdown`}
                  style={{ background: "white", border: "1px solid lightgray" }}
                  withIcons
                >
                  {selectedBreakdown}
                </Badge>
              ) : (
                <Select onValueChange={handleBreakdownChange} value="">
                  <SelectTrigger
                    disabled={!selectedCategory || breakDownOptions.length === 0}
                    size="sm"
                    style={{ marginTop: "0" }}
                  >
                    <SelectValue placeholder={t`Select breakdown`} />
                  </SelectTrigger>
                  <SelectContent>
                    {breakDownOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            </VStack>
          ) : null}
        </VStack>
      </Box>

      <Box width="50%">
        <Text level={2} style={{ fontWeight: "600" }}>
          {t`Series`}
        </Text>
        <Flex className={css({ mt: "2" })} direction="column" gap="2">
          {selectedSeries.length < maxSeries ? (
            <Select
              disabled={
                // !selectedCategory ||
                selectedSeries.length === seriesOptions.length ||
                selectedSeries.length === maxSeries
              }
              onValueChange={handleSeriesAdd}
              value=""
            >
              <SelectTrigger size="sm" style={{ marginTop: "0" }}>
                <SelectValue placeholder={t`Add a series`} />
              </SelectTrigger>
              <SelectContent>
                {seriesOptions
                  .filter((option) => !selectedSeries.includes(option.value))
                  .map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          ) : null}
          <Flex gap="2" wrap="wrap">
            {selectedSeries.map((series) => (
              <Badge
                key={series}
                onRemove={() => handleRemoveSeries(series)}
                onRemoveTooltip={t`Remove series`}
                style={{ background: "white", border: "1px solid lightgray" }}
                withIcons
              >
                {series} ({chartConfig?.column_aggregations?.[series] || Aggregations.sum})
              </Badge>
            ))}
          </Flex>
        </Flex>
      </Box>
    </HStack>
  );

  return (
    <div className={css({ width: "100%" })}>
      <Accordion
        defaultExpandedRow="column-selection"
        defaultOpen={true}
        lockedHeader={false}
        rows={[
          {
            title: t`Data Selection`,
            content: (
              <VStack gap="4">
                {columnSelectorContent}
                <Text color="gray.600" level={3}>
                  {getChartConstraintText()}
                </Text>
              </VStack>
            ),
          },
        ]}
        showHeader={false}
        title={t`Chart Data`}
      />
    </div>
  );
};

export { ChartColumnSelector };
