import { UserDesignations } from "@doowii-types/auth";
import { useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Text } from "doowii-ui";

type UserDesignation = (typeof UserDesignations)[keyof typeof UserDesignations];

interface DesignationSelectorProps {
  selectedDesignation: UserDesignation;
  setSelectedDesignation: React.Dispatch<React.SetStateAction<UserDesignation>>;
}

const DesignationSelector: React.FC<DesignationSelectorProps> = ({
  selectedDesignation,
  setSelectedDesignation,
}) => {
  const { t } = useLingui();
  const handleDesignationChange = (value: string) => {
    setSelectedDesignation(value as UserDesignation);
  };
  return (
    <Box>
      <Text level={3}>{t`Designation`}</Text>
      <Select
        name="designation"
        onValueChange={handleDesignationChange}
        value={selectedDesignation}
      >
        <SelectTrigger>
          <SelectValue id="designation" name="designation" placeholder={t`Select designation`} />
        </SelectTrigger>
        <SelectContent className={css({ width: "400px" })}>
          <SelectItem value={UserDesignations.Administrator}>{t`Administrator`}</SelectItem>
          <SelectItem value={UserDesignations.Instructor}>{t`Instructor`}</SelectItem>
          <SelectItem value={UserDesignations.Researcher}>{t`Researcher`}</SelectItem>
          <SelectItem value={UserDesignations.ITProfessional}>{t`IT Professional`}</SelectItem>
          <SelectItem value={UserDesignations.Other}>{t`Other`}</SelectItem>
        </SelectContent>
      </Select>
    </Box>
  );
};

export { DesignationSelector };
