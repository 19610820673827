import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import { Avatar, AvatarFallback, AvatarImage } from "doowii-ui";

const getInitials = (name: string) => {
  const parts = name.split(/[ @]/u);
  return parts.length > 1
    ? `${parts[0][0]}${parts[1][0]}`.toUpperCase()
    : parts[0].slice(0, 2).toUpperCase();
};

const UserAvatar = () => {
  const { userDocument, authUser } = useAuth();
  const { t } = useLingui();
  const userDisplayName =
    userDocument?.firstName && userDocument?.lastName
      ? `${userDocument?.firstName} ${userDocument?.lastName}`
      : (authUser?.displayName ?? authUser?.email ?? t`Unknown User`);
  return (
    <Avatar>
      <AvatarImage src={authUser?.photoURL} />
      <AvatarFallback>{getInitials(userDisplayName)}</AvatarFallback>
    </Avatar>
  );
};

export { UserAvatar };
