import { Pin } from "@doowii-types/pinboard";
import { WidgetDoc } from "@services/api/generated/webserver/models/widgetDoc";
import { Layouts } from "react-grid-layout";

type GridItem = {
  id: string;
  type: "QUERY" | "WIDGET";
};

export function generateLayouts(pinboardResults: Pin[], widgets: WidgetDoc[] = []): Layouts {
  const breakpoints = {
    lg: { cols: 12, itemsPerRow: 2, width: 6, height: 12, minH: 6, minW: 3 },
    md: { cols: 10, itemsPerRow: 2, width: 5, height: 12, minH: 6, minW: 3 },
    sm: { cols: 6, itemsPerRow: 1, width: 6, height: 10, minH: 5, minW: 3 },
    xs: { cols: 4, itemsPerRow: 1, width: 4, height: 10, minH: 5, minW: 2 },
    xxs: { cols: 2, itemsPerRow: 1, width: 2, height: 10, minH: 3, minW: 2 },
  };

  // Create a combined array of pins and widgets, with type indicator
  const combinedItems: GridItem[] = [
    ...pinboardResults.map((pin) => ({ id: pin.id, type: "QUERY" as const })),
    ...widgets.map((widget) => ({ id: widget.id, type: "WIDGET" as const })),
  ];

  const layouts: Layouts = {};

  Object.entries(breakpoints).forEach(([breakpoint, config]) => {
    layouts[breakpoint] = combinedItems.map((item, i) => {
      // For widgets, we want them to be shorter but take full width
      if (item.type === "WIDGET") {
        // Make widgets take up full row width but maintain compact height
        return {
          x: 0, // Start at the beginning of the row
          y: Math.floor(i / config.itemsPerRow) * config.height,
          w: config.cols, // Take up full width
          h: Math.floor(config.height * 0.25), // Just 25% of normal height
          i: item.id,
          minH: 3, // Minimum height should be small but usable
          minW: config.minW,
          static: false,
        };
      } else {
        // For regular pins, use the standard layout
        const itemsPerRow = config.itemsPerRow;
        return {
          x: (i % itemsPerRow) * config.width,
          y: Math.floor(i / itemsPerRow) * config.height,
          w: config.width,
          h: config.height,
          i: item.id,
          minH: config.minH,
          minW: config.minW,
          static: false,
        };
      }
    });
  });

  return layouts;
}
