import { ParentDocTypeEnum } from "@api/retriever.i";
import { updateChat } from "@services/api/generated/webserver/endpoints/chats/chats";
import { updatePin } from "@services/api/generated/webserver/endpoints/pinboards/pinboards";
import { ChartConfig as ChartConfigGen } from "@services/api/generated/webserver/models/chartConfig";
import { ChartConfig } from "@utils/chartTransformations";
import debounce from "lodash/debounce";

/**
 * Debounced function to update chat configuration via API
 */
export const debouncedSaveChatConfig = debounce(
  async (parentDocId: string, docId: string, chartConfig: ChartConfigGen) => {
    try {
      await updateChat(parentDocId, docId, {
        chart_config: chartConfig,
      });
    } catch (error) {
      console.error("Failed to update chat configuration:", error);
    }
  },
  1000 // 1 second debounce
);

/**
 * Debounced function to update pin configuration via API
 */
export const debouncedSavePinConfig = debounce(
  async (parentDocId: string, docId: string, chartConfig: ChartConfigGen) => {
    try {
      await updatePin(parentDocId, docId, {
        chartConfig,
      });
    } catch (error) {
      console.error("Failed to update pin configuration:", error);
    }
  },
  1000 // 1 second debounce
);

/**
 * Update chart configuration based on parent document type
 * This function routes to the appropriate API call based on whether we're updating
 * a chat in a thread or a pin in a pinboard
 */
export const debouncedSaveChartConfig = (
  parentDocType: string,
  parentDocId: string,
  docId: string,
  chartConfig: ChartConfig
) => {
  if (parentDocType === ParentDocTypeEnum.THREAD) {
    debouncedSaveChatConfig(parentDocId, docId, chartConfig);
  } else if (parentDocType === ParentDocTypeEnum.PINBOARD) {
    debouncedSavePinConfig(parentDocId, docId, chartConfig);
  } else {
    console.error(`Unknown parent document type: ${parentDocType}`);
  }
};
