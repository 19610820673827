import { ParentDocTypeEnum } from "@api/retriever.i";
import { ClientSideData } from "@components/ClientSideData";
import { ServerSideDataRender } from "@components/ServerSideDataRenderer/ServerSideDataRender";
import { MAX_ROWS_FOR_CLIENT_SIDE_DATA_HANDLING } from "@constants/Limits";
import { useChatData } from "@context/chat";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/index";
import { useUpdateSqlTotalRows } from "@services/api/generated/retriever/endpoints/retrieve/retrieve";
import { css } from "@styled-system/css";
import { Box, VStack } from "@styled-system/jsx";
import { LoadingLogo } from "doowii-ui";
import { useEffect, useState } from "react";

interface VisualizationRouterProps {
  currentResult: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  enableDataEditing?: boolean;
}

/**
 * VisualizationRouter is a component that centralizes the logic for rendering
 * visualizations across the application.
 */
const VisualizationRouter: React.FC<VisualizationRouterProps> = ({
  currentResult,
  parentDocId,
  parentDocType,
  enableDataEditing = true,
}) => {
  const { updateResultsFields } = useChatData();
  const [totalRows, setTotalRows] = useState<number>(-1);

  const tooManyRows = totalRows > MAX_ROWS_FOR_CLIENT_SIDE_DATA_HANDLING;

  const { mutate, isPending } = useUpdateSqlTotalRows({
    mutation: {
      onSuccess: (data) => {
        setTotalRows(data.total_rows);
        updateResultsFields(
          {
            total_rows: data.total_rows,
          },
          currentResult.id
        );
      },
    },
  });

  useEffect(() => {
    // Get the total rows from the current result or default to -1 if not available
    const resultTotalRows = currentResult.total_rows ?? -1;
    setTotalRows(resultTotalRows);

    // If total rows is not set (-1), fetch the total rows count from the server
    if (resultTotalRows === -1) {
      mutate({
        data: {
          doc_id: currentResult.id,
          parent_doc_id: parentDocId,
          parent_doc_type: parentDocType,
        },
      });
    }
    // We only fetch row count from the server when needed (total_rows = -1)
    // This happens with historical chats, pins, or after SQL edits
  }, [
    currentResult.id,
    currentResult.sql,
    currentResult.total_rows,
    mutate,
    parentDocId,
    parentDocType,
  ]);

  if (isPending) {
    return (
      <div
        style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoadingLogo />
      </div>
    );
  }

  return (
    <VStack
      className={css({
        height: "100%",
        bg: "white",
        position: "relative",
      })}
      gap="0"
      width="100%"
    >
      {/* Data Visualization Content */}
      <Box
        className={css({
          overflow: "auto",
          position: "relative",
        })}
        height="100%"
        width="100%"
      >
        {tooManyRows ? (
          <ServerSideDataRender
            currentResult={currentResult}
            parentDocId={parentDocId}
            parentDocType={parentDocType}
          />
        ) : (
          <ClientSideData
            currentResult={currentResult}
            enableDataEditing={enableDataEditing}
            parentDocId={parentDocId}
            parentDocType={parentDocType}
          />
        )}
      </Box>
    </VStack>
  );
};

export { VisualizationRouter };
