import { components, operations } from "@doowii-types/WebserverInterfaces.gen";
import { AxiosResponse } from "axios";

import { post, postUnauthenticated } from "./base";

type ShareResultRequest = components["schemas"]["ShareResultRequest"];

type ShareResultResponse =
  operations["share_result_api_share_result__post"]["responses"]["200"]["content"]["application/json"];

export const shareResult = async (
  request: ShareResultRequest
): Promise<AxiosResponse<ShareResultResponse> | undefined> => {
  try {
    return await post("/share-result/", { share_request: request });
  } catch (error) {
    console.error("Error sharing result", error);
  }
};

type SendContactRequestRequest = components["schemas"]["SendContactRequest"];

type SendContactRequestResponse =
  operations["send_contact_request_api_send_contact_request__post"]["responses"]["200"]["content"]["application/json"];

export const sendContactRequest = async (
  request: SendContactRequestRequest
): Promise<AxiosResponse<SendContactRequestResponse> | undefined> => {
  try {
    return await post("/send-contact-request/", request);
  } catch (error) {
    console.error("Error sending contact request", error);
  }
};

type SendDemoRegisterRequest = components["schemas"]["SendDemoRegisterRequest"];

type SendDemoRegisterResponse =
  operations["send_demo_register_request_api_send_demo_register_request__post"]["responses"]["200"]["content"]["application/json"];

export const sendDemoRegisterRequest = async (
  request: SendDemoRegisterRequest
): Promise<AxiosResponse<SendDemoRegisterResponse> | undefined> => {
  try {
    return await postUnauthenticated("/send-demo-register-request/", request);
  } catch (error) {
    console.error("Error sending access request", error);
  }
};