import { ParentDocTypeEnum } from "@api/retriever.i";
import { ShareResultDialog } from "@components/ShareResultDialog";
import { VisualizationRouter } from "@components/VisualizationRouter/VisualizationRouter";
import { useChatData } from "@context/chat";
import type { Result } from "@doowii-types/chat";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";

interface ChatDataBoardProps {
  result: Result;
  setIsShareResultDialogOpen: (isOpen: boolean) => void;
  isShareResultDialogOpen: boolean;
}

const ChatDataBoard = ({
  result,
  setIsShareResultDialogOpen,
  isShareResultDialogOpen,
}: ChatDataBoardProps) => {
  const { currentThread } = useChatData();

  return (
    <Flex
      className={css({
        position: "relative",
      })}
      data-testid="chat-data-board"
      direction="column"
      height="100%"
      maxHeight="850px"
      minHeight="500px"
    >
      {/* Visualization Router handles all chart/table rendering */}
      <VisualizationRouter
        currentResult={result}
        enableDataEditing={true}
        parentDocId={currentThread}
        parentDocType={ParentDocTypeEnum.THREAD}
      />

      {/* Only the ShareResultDialog remains in ChatDataBoard */}
      <ShareResultDialog
        isOpen={isShareResultDialogOpen}
        parentDocId={currentThread}
        parentDocType={ParentDocTypeEnum.THREAD}
        result={result}
        setIsShareResultDialogOpen={setIsShareResultDialogOpen}
      />
    </Flex>
  );
};

export { ChatDataBoard };
