import { AxiosResponse } from "axios";

import { FeedbackRequest } from "@services/api/generated/webserver/models/feedbackRequest";
import { FeedbackResponse } from "@services/api/generated/webserver/models/feedbackResponse";
import { post } from "./base";

export const reportNegativeFeedback = (
  question: string,
  questionId: string,
  feedback?: string
): Promise<AxiosResponse<FeedbackResponse>> => {
  try {
    const request: FeedbackRequest = {
      question,
      question_id: questionId,
      feedback: feedback || "",
    };
    return post("/negative_feedback", request);
  } catch (error) {
    console.error("Error sending feedback", error);

    throw error;
  }
};
