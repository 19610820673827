import { ChatContainer } from "@components/ChatContainer";
import { ChatSearchBar } from "@components/ChatSearchBar";
import { PageHeader } from "@components/PageHeader";
import { SuggestionsCardsContainer } from "@components/SuggestionsCardsContainer";
import { useChatData } from "@context/chat";
import { useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

const Thread = () => {
  const { t } = useLingui();

  const { allResults, currentThread, threads } = useChatData();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const elementId = hash.replace("#", "");
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  }, [hash]);

  const currentThreadName = useMemo(
    () => threads.find((thread) => thread.id === currentThread)?.title || t`New chat`,
    [currentThread, threads]
  );

  const hasChats = allResults.length !== 0;

  return (
    <>
      <PageHeader
        description={t`Page to list threads`}
        title={t`Doowii - Thread: ${currentThreadName}`}
      />

      <Flex
        className={css({
          w: "full",
          minHeight: "100vh",
        })}
        direction="column"
        justify="space-between"
      >
        <Flex
          css={{
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: hasChats ? "auto" : "center",
            gap: "4",
            pb: "10",
            px: "10",
          }}
        >
          {hasChats ? (
            allResults.map((result, index) => (
              <ChatContainer
                id={result.id}
                index={index}
                key={`index-${result.id}`}
                result={result}
              />
            ))
          ) : (
            <SuggestionsCardsContainer homePage={false} />
          )}
        </Flex>

        <Box
          className={css({
            position: "sticky",
            bottom: 0,
            zIndex: 10,
          })}
        >
          <Box
            className={css({
              backgroundColor: "base.white",
            })}
          >
            <ChatSearchBar />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export { Thread };
