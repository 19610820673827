import { DOOWII_CORE } from "@constants/constants";
import * as Sentry from "@sentry/react";
import { withSentry } from "@utils/wrapper";
import {
  createUserWithEmailAndPassword,
  User as FirebaseUser,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { useBoundStore } from "@stores/store";
import { env } from "../../env";
import { isInvitedUser } from "./user";

const auth = getAuth();

/**
 * Signs out the current user.
 */
const signOutUser = withSentry(async () => {
  await signOut(auth);
});

/**
 * Validates if a user is allowed to access the current app.
 * If not, the user is signed out and an error is logged.
 * @param user - The authenticated Firebase user.
 * @throws Error if the user lacks required claims.
 */
const validateUserClaims = async (user: FirebaseUser) => {
  const tokenResult = await user.getIdTokenResult(true);
  const allowedApps = tokenResult.claims.allowed_apps as string[] | undefined;
  if (!allowedApps) {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureException(new Error(`User ${user.uid} has no allowedApps claims.`));
    });

    await signOutUser();
    throw new Error("Access denied: No app permissions found.");
  }

  const currentApp = env.REACT_APP_ENV === "higher_ed" ? "HIGHER_ED" : DOOWII_CORE;

  if (!allowedApps.includes(currentApp)) {
    console.warn(`User ${user.uid} is not authorized for this app.`);
    await signOutUser();

    throw new Error("Access denied: User is not authorized for this app.");
  }
};

/**
 * Signs in a user with email and password.
 * @param email - The user's email.
 * @param password - The user's password.
 * @returns A Promise that resolves with the user credentials.
 */
const signInWithFirebase = withSentry(async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);

  try {
    await validateUserClaims(userCredential.user);
  } catch (error) {
    throw new Error("Access denied: User is not authorized for this app.");
  }
});

/**
 * Signs in a user with Google.
 * @returns A Promise that resolves with the user credentials.
 */
const signInWithGoogle = withSentry(async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;

  if (!user || !user.email) {
    throw new Error("Google sign in failed");
  }

  try {
    await validateUserClaims(user);
  } catch (error) {
    throw new Error("Access denied: User is not authorized for this app.");
  }
});

/**
 * Signs up a user with Google.
 * @returns A Promise that resolves with the user credentials.
 */
const signUpWithGoogle = withSentry(async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;

  if (!user || !user.email) {
    throw new Error("Google sign in failed");
  }
  const isInvited = await isInvitedUser(user.email);
  if (!isInvited) {
    console.error("User not invited: ", user.email);
    await user.delete();
    return false;
  }
  return true;
});

/**
 * Signs up a new user with email and password.
 * @param email - The user's email.
 * @param password - The user's password.
 * @returns A Promise that resolves with the user credentials.
 */
const signUpWithFirebase = withSentry(async (email: string, password: string) => {
  // check if auth user already exists
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  return userCredential;
});

/**
 * Sends a password reset email to the specified email address.
 * @param email - The email address to send the password reset email to.
 * @returns A Promise that resolves when the email has been sent.
 */
const sendPwdResetEmailWithFirebase = withSentry((email: string) =>
  sendPasswordResetEmail(auth, email)
);

/**
 * Initializes the authentication listener to monitor user state.
 * Ensures only authorized users remain signed in by validating claims and fetching user data.
 * Sets auth state in the global store based on authentication status.
 */
const initializeAuthListener = () => {
  onAuthStateChanged(auth, async (firebaseUser) => {
    const store = useBoundStore.getState();
    store.setAuthState({ isLoading: true });

    if (firebaseUser) {
      try {
        await store.fetchAndInitializeAuthState(firebaseUser.uid);

        // Only validate claims if we have a user document
        if (store.userDocument) {
          await validateUserClaims(firebaseUser);
        }

        store.setAuthState({
          authUser: firebaseUser,
          isLoading: false,
        });

        return;
      } catch (error) {
        console.error("Authentication validation failed:", error);
      }
    }

    // Handle both error case and no firebaseUser case
    store.setAuthState({
      authUser: null,
      userDocument: null,
      organization: null,
      organizationDoc: null,
      isLoading: false,
      featureFlags: [],
    });
  });
};
export {
  auth,
  initializeAuthListener,
  sendPwdResetEmailWithFirebase,
  signInWithFirebase,
  signInWithGoogle,
  signOutUser,
  signUpWithFirebase,
  signUpWithGoogle,
  validateUserClaims,
};
