import { FeedbackDialog } from "@components/FeedbackDialog/FeedbackDialog";
import { Satisfaction } from "@doowii-types/chat";
import { Trans } from "@lingui/react/macro";
import { Flex } from "@styled-system/jsx";
import { Label } from "doowii-ui";
import React from "react";

const Feedback: React.FC<{ index: number; feedbackResult: Satisfaction }> = ({
  index,
  feedbackResult,
}) => {
  const isSatisfied = feedbackResult !== "UNKNOWN" && feedbackResult.like;
  const isUnsatisfied = feedbackResult !== "UNKNOWN" && !feedbackResult.like;

  return (
    <Flex css={{ alignItems: "center", gap: "sm" }}>
      <Label level={1}>
        <Trans>Did we get it right?</Trans>
      </Label>
      <Flex css={{ gap: "sm" }}>
        <FeedbackDialog index={index} isActive={isSatisfied} type="positive" />
        <FeedbackDialog index={index} isActive={isUnsatisfied} type="negative" />
      </Flex>
    </Flex>
  );
};

export { Feedback };
