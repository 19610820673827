import { create } from "zustand";

import type { AuthSliceInterface } from "./authSlice";
import { createAuthSlice } from "./authSlice";
import { getOrCreateTableStore } from "./tableStore";

const useBoundStore = create<AuthSliceInterface>()((...a) => ({
  ...createAuthSlice(...a),
}));

export { getOrCreateTableStore, useBoundStore };
