import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react/macro";
import {
  getGetDocumentKnowledgeQueryKey,
  useUploadDocument,
} from "@services/api/generated/webserver/endpoints/knowledge/knowledge";
import { AcceptedFileExtension } from "@services/api/generated/webserver/models/acceptedFileExtension";
import { css } from "@styled-system/css";
import { Box, Stack } from "@styled-system/jsx";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Text,
  useToast,
} from "doowii-ui";
import { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

import { FileList, type UploadingFile, UploadingFileStatus } from "./FileList";

// Maximum file size: 50MB
const MAX_FILE_SIZE = 50 * 1024 * 1024;

interface UploadDocumentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

// Map MIME types to our accepted extensions
const ACCEPTED_FILE_TYPES: Record<string, string[]> = {
  "application/pdf": [`.${AcceptedFileExtension.PDF}`],
  "application/msword": [`.${AcceptedFileExtension.DOC}`],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    `.${AcceptedFileExtension.DOCX}`,
  ],
  "text/plain": [`.${AcceptedFileExtension.TXT}`],
  "text/markdown": [`.${AcceptedFileExtension.MD}`],
  "text/x-markdown": [`.${AcceptedFileExtension.MD}`],
  "application/x-yaml": [`.${AcceptedFileExtension.YAML}`],
  "application/yaml": [`.${AcceptedFileExtension.YAML}`],
  "application/rtf": [`.${AcceptedFileExtension.RTF}`],
  "text/rtf": [`.${AcceptedFileExtension.RTF}`],
} as const;

// Replace with simple extension mapping
const extensionsStringList = Object.values(AcceptedFileExtension)
  .map((ext) => `.${ext.toLowerCase()}`)
  .join(", ");

const UploadDocumentDialog = ({ isOpen, onClose }: UploadDocumentDialogProps) => {
  const { t } = useLingui();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [filesToUpload, setFilesToUpload] = useState<UploadingFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const { mutateAsync: uploadDocument } = useUploadDocument({
    mutation: {
      onSettled: () => {
        // Invalidate cache
        void queryClient.invalidateQueries({
          queryKey: getGetDocumentKnowledgeQueryKey(),
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { detail?: string })?.detail ?? t`Upload failed`;
        toast({
          status: "error",
          title: t`Upload failed`,
          description: errorMessage,
        });
      },
    },
  });

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const validateFile = (file: File): string | null => {
        // Check file size
        if (file.size > MAX_FILE_SIZE) {
          return t`File size exceeds 50MB limit`;
        }

        // Check file extension
        const extension = file.name.split(".").pop()?.toUpperCase();
        if (
          !extension ||
          !Object.values(AcceptedFileExtension).includes(extension as AcceptedFileExtension)
        ) {
          return t`Invalid file type. Supported types: ${extensionsStringList}`;
        }

        return null;
      };
      // Handle rejected files
      if (rejectedFiles.length > 0) {
        toast({
          status: "error",
          title: t`Some files were rejected`,
          description: t`Please check file types and sizes`,
        });
      }

      // Validate and add accepted files
      const validatedFiles = acceptedFiles.map((file) => {
        const error = validateFile(file);
        return {
          file,
          isDescriptionOpen: false,
          status: error ? UploadingFileStatus.FAILED : UploadingFileStatus.QUEUED,
          error,
          description: "",
          file_type: file.name.split(".").pop()?.toUpperCase() as AcceptedFileExtension,
        } satisfies UploadingFile;
      });

      setFilesToUpload((prev) => {
        const existingFileNames = new Set(prev.map((f) => f.file.name));
        const uniqueValidatedFiles = validatedFiles.filter(
          (f) => !existingFileNames.has(f.file.name)
        );
        if (uniqueValidatedFiles.length !== validatedFiles.length) {
          toast({
            status: "info",
            title: t`Duplicate file names were removed`,
          });
        }
        return [...prev, ...uniqueValidatedFiles];
      });
    },
    [t, toast]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      ...ACCEPTED_FILE_TYPES,
    },
  });

  const handleRemoveFile = (index: number) => {
    setFilesToUpload((prev) => prev.filter((_, i) => i !== index));
  };

  const toggleDescription = (index: number) => {
    setFilesToUpload((prev) =>
      prev.map((file, i) =>
        i === index ? { ...file, isDescriptionOpen: !file.isDescriptionOpen } : file
      )
    );
  };

  const handleDescriptionChange = (index: number, description: string) => {
    setFilesToUpload((prev) =>
      prev.map((file, i) => (i === index ? { ...file, description } : file))
    );
  };

  const handleUpload = async () => {
    const validFiles = filesToUpload.filter(
      (file) => !file.error && file.status !== UploadingFileStatus.COMPLETED
    );
    if (validFiles.length === 0) {
      toast({
        status: "error",
        title: t`No valid files to upload`,
        description: t`Please add valid files before uploading`,
      });
      return;
    }

    setIsUploading(true);

    try {
      // Update status to uploading
      setFilesToUpload((prev) =>
        prev.map((file) =>
          file.error || file.status === UploadingFileStatus.COMPLETED
            ? file
            : { ...file, status: UploadingFileStatus.UPLOADING }
        )
      );

      // Upload files in parallel with a concurrency limit of 3
      const concurrencyLimit = 3;
      const uploadQueue = [...validFiles];
      const inProgressFileNames = new Set<string>();

      while (uploadQueue.length > 0 || inProgressFileNames.size > 0) {
        // Fill up the in-progress set until we hit the limit
        while (uploadQueue.length > 0 && inProgressFileNames.size < concurrencyLimit) {
          const fileData = uploadQueue.shift();
          const fileName = fileData.file.name; // Use filename as unique identifier
          inProgressFileNames.add(fileName);

          uploadDocument({
            data: {
              file: fileData.file,
              title: fileName,
              description: fileData.description,
              file_type: fileData.file_type,
            },
          })
            .then(() => {
              setFilesToUpload((prev) =>
                prev.map((file) =>
                  file.file.name === fileName
                    ? { ...file, status: UploadingFileStatus.COMPLETED }
                    : file
                )
              );
            })
            .catch((error) => {
              console.error(`Failed to upload ${fileName}:`, error);
              const errorDetail = error.response?.data?.detail ?? error.message;
              const errorMessage = errorDetail ? errorDetail : t`Upload failed`;
              setFilesToUpload((prev) =>
                prev.map((file) =>
                  file.file.name === fileName
                    ? { ...file, status: UploadingFileStatus.FAILED, error: errorMessage }
                    : file
                )
              );
            })
            .finally(() => {
              inProgressFileNames.delete(fileName);
            });
        }

        // Wait a bit before checking again
        await new Promise((resolve) => setTimeout(resolve, 300));
      }
    } catch (error) {
      console.error("Failed to upload files:", error);
      toast({
        status: "error",
        title: t`Upload failed`,
        description: t`Some files could not be uploaded`,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleOnClose = () => {
    if (isUploading) {
      toast({
        status: "warning",
        title: t`Upload in progress`,
        description: t`Please wait for the upload to complete`,
      });
      return;
    }
    setFilesToUpload([]);
    onClose();
  };

  return (
    <Dialog onOpenChange={handleOnClose} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t`Upload new documents`}</DialogTitle>
          <DialogDescription>
            {t`You can upload one or more documents and then describe what it's about`}
          </DialogDescription>
        </DialogHeader>

        <DialogBody>
          <Stack gap={4}>
            {/* Dropzone */}
            <Box
              {...getRootProps()}
              className={css({
                border: "2px dashed",
                borderColor: isDragActive ? "primary.500" : "gray.300",
                rounded: "lg",
                p: 6,
                cursor: "pointer",
                transition: "all 0.2s",
                _hover: {
                  borderColor: "primary.500",
                },
              })}
            >
              <input {...getInputProps()} />
              <Stack alignItems="center" gap={2}>
                <FontAwesomeIcon
                  className={css({ color: isDragActive ? "primary.500" : "gray.500" })}
                  icon={faUpload}
                  size="2x"
                />
                <Text variant="primary">
                  {isDragActive ? t`Drop files here` : t`Drag files here or browse`}
                </Text>
                <Text variant="constrast">
                  {t`Supported file types: ${extensionsStringList} (max 50MB)`}
                </Text>
              </Stack>
            </Box>

            <FileList
              files={filesToUpload}
              onDescriptionChange={handleDescriptionChange}
              onRemoveFile={handleRemoveFile}
              onToggleDescription={toggleDescription}
            />
          </Stack>
        </DialogBody>

        <DialogFooter>
          <Button disabled={isUploading} onClick={handleOnClose} variant="secondary">
            {t`Close`}
          </Button>
          <Button
            disabled={
              filesToUpload.filter((file) => file.status === UploadingFileStatus.QUEUED).length ===
                0 || isUploading
            }
            onClick={handleUpload}
            variant="primary"
          >
            {isUploading ? t`Uploading...` : t`Upload`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { UploadDocumentDialog };
