import { usePinboard } from "@context/pinboard";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useModalClose } from "@hooks/useModalClose";
import { useLingui } from "@lingui/react/macro";
import { useDeleteWidget } from "@services/api/generated/webserver/endpoints/widgets/widgets";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import { ConfirmationDialog, useToast } from "doowii-ui";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  widgetId: string;
  pinboardId: string;
  triggerId?: string;
}

const DeleteWidgetDialog: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  widgetId,
  pinboardId,
  triggerId,
}) => {
  if (triggerId) {
    useModalClose(isOpen, triggerId);
  }

  const { t } = useLingui();
  const { toast } = useToast();
  const { fetchWidgetsForBoard } = usePinboard();

  const { mutate: deleteWidget, isPending: isDeleting } = useDeleteWidget({
    mutation: {
      onSuccess: () => {
        toast({
          status: "success",
          title: t`Widget deleted successfully`,
        });
        setIsOpen(false);
        fetchWidgetsForBoard(pinboardId);
      },
      onError: (error) => {
        console.error("Error deleting widget:", error);
        toast({
          status: "error",
          title: t`Failed to delete widget`,
          description:
            typeof error.detail === "string" ? error.detail : t`An unexpected error occurred`,
        });
      },
    },
  });

  const handleDelete = async () => {
    try {
      deleteWidget({
        pinboardId,
        widgetId,
      });
    } catch (error) {
      console.error("Error deleting widget:", error);
    }
  };

  return (
    <ConfirmationDialog
      cancelButtonText={t`Cancel`}
      confirmationButtonText={t`Delete`}
      description={t`Are you sure you want to delete this widget?`}
      destructive
      icon={faTrash}
      isLoadingAction={isDeleting}
      isOpen={isOpen}
      onCancel={stopPropagationWrapper(() => setIsOpen(false))}
      onConfirm={stopPropagationWrapper(handleDelete)}
      setIsOpen={setIsOpen}
      title={t`Delete widget`}
    />
  );
};

export { DeleteWidgetDialog };
