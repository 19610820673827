import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@components/Table";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { css } from "@styled-system/css";
import { HStack } from "@styled-system/jsx";
import { useQuery } from "@tanstack/react-query";
import { flexRender, getCoreRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { fetchPageData } from "../../api/retriever";
import { ParentDocTypeEnum } from "../../api/retriever.i";
import { DataTableSkeleton } from "./DataTableSkeleton";
import { EmptyTableOverlay } from "./EmptyTableOverlay";
import { PaginationControl } from "./PaginationControl";
import { SortingControl } from "./SortingControl";

interface PaginatedDataTableProps {
  result: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
}

const PaginatedDataTable = ({ result, parentDocId, parentDocType }: PaginatedDataTableProps) => {
  const [pagination, setPagination] = useState({
    pageSize: 50,
    pageIndex: 0,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const dataQuery = useQuery({
    queryKey: ["data", pagination, result.id, sorting, result.sql],
    queryFn: () =>
      fetchPageData({
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageIndex,
        sortModel: sorting,
        docId: result.id,
        parentDocId,
        parentDocType,
        retrievalType: "query",
        userDefinedQuery: Boolean(result.originalSql && result.sql !== result.originalSql),
      }),
  });
  const defaultData = useMemo(() => [], []);

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    columns: dataQuery.data?.columns ?? [],
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: dataQuery.data?.rowCount ?? 0,
    onPaginationChange: setPagination,
    state: {
      pagination,
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <div
      className={css({
        width: "full",
        maxHeight: "700px",
        position: "relative",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      })}
      id="paginated-data-table-container"
    >
      <div
        className={css({
          width: "100%",
          overflow: "auto",
          flexGrow: 1,
        })}
      >
        <Table data-testid="paginated-data-table">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    <HStack className="column-controls" justify="space-between" textWrap="nowrap">
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {table.getRowModel().rows.length > 1 ? (
                            <HStack>
                              <SortingControl
                                id={header.column.id}
                                isSorted={header.column.getIsSorted()}
                                setSorting={setSorting}
                              />
                            </HStack>
                          ) : null}
                        </>
                      )}
                    </HStack>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows.length
              ? table.getRowModel().rows.map((row) => (
                  <TableRow data-state={row.getIsSelected() ? "selected" : null} key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        className={css({
                          backgroundColor: result.chartConfig.columns.includes(cell.column.id)
                            ? "base.blueBrandSecondary"
                            : "inherit",
                        })}
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </div>

      {table.getRowModel().rows.length ? (
        <PaginationControl
          dataQuery={dataQuery}
          pagination={pagination}
          setPagination={setPagination}
          table={table}
          type={parentDocType}
        />
      ) : dataQuery.isLoading ? (
        <DataTableSkeleton />
      ) : (
        <EmptyTableOverlay />
      )}
    </div>
  );
};
export { PaginatedDataTable };
