import { usePinboard } from "@context/pinboard";
import { Pin } from "@doowii-types/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { Trans, useLingui } from "@lingui/react/macro";
import { useCreatePinDescription } from "@services/api/generated/webserver/endpoints/generative-ai/generative-ai";
import { useUpdatePin } from "@services/api/generated/webserver/endpoints/pinboards/pinboards";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Text,
  Textarea,
} from "doowii-ui";
import { useEffect, useState } from "react";
const characterLimit = 100;

interface Props {
  isOpen: boolean;
  initialPinDescription?: string;
  initialPinTitle: string;
  pinboardId: string;
  pinId: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const EditPinDialog: React.FC<Props> = ({
  isOpen,
  pinboardId,
  pinId,
  initialPinTitle,
  initialPinDescription,
  triggerId,
  setIsOpen,
}) => {
  useModalClose(isOpen, triggerId);
  const { t } = useLingui();
  const { setPinboardResults } = usePinboard();
  const [pinTitle, setPinTitle] = useState(initialPinTitle);
  const [pinDescription, setPinDescription] = useState(initialPinDescription);
  const [errorMessage, setErrorMessage] = useState("");

  const totalCharacters = pinDescription?.length || 0;

  // This is used to generate a suggested description for the pin with quiet error handling
  const { mutate: generatePinDescription, isPending: isGeneratingPinDescription } =
    useCreatePinDescription({
      mutation: {
        onSuccess: (data) => {
          if (data?.description) {
            setPinDescription(data.description);
            document.querySelector("textarea")?.focus();
          }
        },
      },
    });

  const { mutate: updatePin, isPending: isUpdatingPin } = useUpdatePin({
    mutation: {
      onSuccess: () => {
        setPinboardResults((prev: Pin[]) =>
          prev.map((pin) =>
            pin.id === pinId ? { ...pin, title: pinTitle, description: pinDescription } : pin
          )
        );
      },

      onSettled: () => {
        setIsOpen(false);
      },
    },
  });

  useEffect(() => {
    if (isOpen && pinId && pinboardId && !pinDescription) {
      generatePinDescription({ pinboardId, pinId });
    }
  }, [isOpen, pinId, pinboardId, generatePinDescription, pinDescription]);

  const handleUpdatePin = () => {
    if (pinTitle.trim().length < 1) {
      setErrorMessage(t`Pinboard name cannot be empty`);
      return;
    }

    updatePin({
      pinboardId,
      pinId,
      data: { title: pinTitle, description: pinDescription },
    });
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Edit pin</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <InputField
            disabled={isUpdatingPin}
            errorText={errorMessage}
            label={t`Pin title`}
            onChange={(e) => setPinTitle(e.target.value)}
            placeholder={t`Enter pin title`}
            type="text"
            value={pinTitle}
          />
          <Flex direction="column">
            <Textarea
              disabled={isUpdatingPin || isGeneratingPinDescription}
              label={t`Pin description`}
              maxLength={characterLimit}
              onChange={(e) => {
                setPinDescription(e.target.value);
              }}
              placeholder={
                isGeneratingPinDescription
                  ? t`Generating suggested description...`
                  : t`Enter pin description...`
              }
              rows={3}
              value={pinDescription}
            />
            <Text className={css({ color: "base.gray", mt: "xs" })} level={1}>
              {totalCharacters} / {characterLimit}
            </Text>
          </Flex>
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", gap: "sm" })}>
          <Button
            disabled={isUpdatingPin}
            loading={isUpdatingPin}
            onClick={handleUpdatePin}
            size="large"
            variant="primary"
          >
            <Trans>Save</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { EditPinDialog };
