import { css } from "@doowii/design-system/css";
import { ColumnType } from "@doowii-types/chart";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { Trans, useLingui } from "@lingui/react/macro";
import { Accordion, Text } from "doowii-ui";
import React from "react";

interface ApolloGuideProps {
  result: Result | Pin;
  setIsAccordionOpen: (open: boolean) => void;
  isAccordionOpen: boolean;
}

const ulStyle = css({
  paddingLeft: "16px",
});

const liStyle = css({
  marginBottom: "8px",
});

const boldTextStyle = css({
  fontWeight: "bold",
});

const italicTextStyle = css({
  fontStyle: "italic",
});

const availableColumnsUlStyle = css({
  padding: 0,
  listStyleType: "none",
});

const availableColumnsLiStyle = css({
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
});

const boldTextWithMarginRightStyle = css({
  fontWeight: "bold",
  marginRight: "8px",
});

const grayItalicTextStyle = css({
  color: "{colors.base.gray}",
  fontStyle: "italic",
});

const ModifyChartColors = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Modify specific elements:</Trans>
        </span>{" "}
        <Trans>Change the bar color to blue</Trans>
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Update overall theme:</Trans>
        </span>{" "}
        <Trans>Use a pastel color scheme</Trans>
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Highlight data:</Trans>
        </span>{" "}
        <Trans>Make the datalabels red</Trans>
      </Text>
    </li>
  </ul>
);

const ModifyAxisLabels = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Rename axes:</Trans>
        </span>{" "}
        <Trans>Change x-axis label to 'Months'</Trans>
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Format values:</Trans>
        </span>{" "}
        <Trans>Round data labels to 2 decimal points</Trans>
      </Text>
    </li>
  </ul>
);

const ToggleDataLabelsAndTooltips = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Show/hide data labels:</Trans>
        </span>{" "}
        <Trans>Display values on each bar</Trans>
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Customize tooltips:</Trans>
        </span>{" "}
        <Trans>Add product names to tooltips</Trans>
      </Text>
    </li>
  </ul>
);

const ModifyChartLegend = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Reposition:</Trans>
        </span>{" "}
        <Trans>Move legend to the bottom</Trans>
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <span className={boldTextStyle}>
          <Trans>Hide:</Trans>
        </span>{" "}
        <Trans>Remove legend from the chart</Trans>
      </Text>
    </li>
  </ul>
);

const determineColumns = (
  columnTypes: Record<string, ColumnType>
): { categoricalColumn: string; numericalColumn: string } => {
  const categoricalColumn =
    Object.keys(columnTypes).find((key) => columnTypes[key] === ColumnType.CATEGORICAL) ||
    "Categorical Placeholder";
  const numericalColumn =
    Object.keys(columnTypes).find((key) => columnTypes[key] === ColumnType.NUMERICAL) ||
    "Numerical Placeholder";

  return { categoricalColumn, numericalColumn };
};

const ChangeChartType = ({ columnTypes }: { columnTypes: Record<string, ColumnType> }) => {
  const { categoricalColumn, numericalColumn } = determineColumns(columnTypes);

  return (
    <>
      <ul className={ulStyle}>
        <li className={liStyle}>
          <Text>
            <Text className={boldTextStyle}>
              <Trans>Switch chart types:</Trans>
            </Text>{" "}
            <Trans>
              Change from bar to line chart using {categoricalColumn} and {numericalColumn}
            </Trans>
          </Text>
        </li>
      </ul>
      <br />
      <Text className={italicTextStyle}>
        <Trans>
          Remember: Ensure your data is compatible with the new format and specify the columns you
          want to use for the chart.
        </Trans>
      </Text>
    </>
  );
};

const AvailableColumns = ({ columnTypes }: { columnTypes: Record<string, ColumnType> }) => (
  <div>
    <ul className={availableColumnsUlStyle}>
      {Object.keys(columnTypes)
        .sort()
        .map((column) => (
          <React.Fragment key={column}>
            <li className={availableColumnsLiStyle} style={{ marginBottom: "0" }}>
              <Text className={boldTextWithMarginRightStyle}>{column}</Text>
            </li>
            <li style={{ marginBottom: "8px" }}>
              <Text className={grayItalicTextStyle}>({columnTypes[column]})</Text>
            </li>
          </React.Fragment>
        ))}
    </ul>
  </div>
);

const ApolloGuide: React.FC<ApolloGuideProps> = ({
  result,
  isAccordionOpen,
  setIsAccordionOpen,
}) => {
  const { t } = useLingui();
  const columnTypes = result?.chartConfig?.column_types || {};

  return (
    <Accordion
      defaultExpandedRow={t`Available Columns`}
      lockedHeader
      onValueChange={() => setIsAccordionOpen(!isAccordionOpen)}
      rows={[
        {
          title: t`Available Columns`,
          content: <AvailableColumns columnTypes={columnTypes} />,
        },
        { title: t`Modify Chart Colors`, content: <ModifyChartColors /> },
        { title: t`Modify Axis Labels`, content: <ModifyAxisLabels /> },
        {
          title: t`Data Labels & Tooltips`,
          content: <ToggleDataLabelsAndTooltips />,
        },
        { title: t`Modify Chart Legend`, content: <ModifyChartLegend /> },
        {
          title: t`Change Chart Type`,
          content: <ChangeChartType columnTypes={columnTypes} />,
        },
      ]}
      title={t`Chart Modification Guide`}
    />
  );
};

export default ApolloGuide;
