import { useChatData } from "@context/chat";
import { useDoowii } from "@context/doowii/doowii";
import { Result } from "@doowii-types/chat";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/react/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { FollowUpCard, Separator, Text } from "doowii-ui";
import { useState } from "react";

import { QuestionTypeEnum } from "../../../../search/Doowii.i";
import { FollowupSkeleton } from "./FollowupSkeleton";

interface ChatFollowupProps {
  result: Result;
}
const ChatFollowup = ({ result }: ChatFollowupProps) => {
  const hasFollowUp = result.follow_up_prompts && result.follow_up_prompts.length > 0;
  const [open, setOpen] = useState(true);

  const { allResults } = useChatData();
  const doowii = useDoowii();
  const handleClickFollowup = async (followUp: string) => {
    await doowii.chat({
      query: followUp,
      index: allResults.length,
      questionType: QuestionTypeEnum.REFINEMENT,
    });
  };

  return (
    <Collapsible.Root
      className={css({
        px: "4",
        pb: "2",
        w: "full",
        marginX: "auto",
      })}
      onOpenChange={setOpen}
      open={open}
    >
      <>
        <Separator colorful={false} />
        <Collapsible.Trigger>
          <Flex
            align="center"
            cursor="pointer"
            direction="row"
            gap="4"
            justify="center"
            p="3"
            textWrap="nowrap"
          >
            <Text className={css({ fontWeight: "medium" })} level={3} variant="constrast">
              {open ? <Trans>Hide follow-ups </Trans> : <Trans>Show follow-ups</Trans>}
            </Text>
            <FontAwesomeIcon
              className={css({
                w: "6",
                h: "6",
              })}
              icon={open ? faChevronUp : faChevronDown}
            />
          </Flex>
        </Collapsible.Trigger>
      </>

      <Collapsible.Content>
        {hasFollowUp ? (
          <Flex align="stretch" data-testid="follow-up" direction="row" gap="4" pb="4">
            {result.follow_up_prompts
              ? result.follow_up_prompts.map((followUp, index) => (
                  <FollowUpCard
                    key={index}
                    onClick={() => {
                      handleClickFollowup(followUp);
                    }}
                  >
                    {followUp}
                  </FollowUpCard>
                ))
              : null}
          </Flex>
        ) : (
          <FollowupSkeleton />
        )}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export { ChatFollowup };
