/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { BodyUploadDocumentApiKnowledgeDocumentUploadPost } from "../../models/bodyUploadDocumentApiKnowledgeDocumentUploadPost";
import type { CreateCustomKnowledgeRequest } from "../../models/createCustomKnowledgeRequest";
import type { CustomKnowledgeItem } from "../../models/customKnowledgeItem";
import type { DocumentKnowledgeItem } from "../../models/documentKnowledgeItem";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { OrganizationCategoricalKnowledgeItem } from "../../models/organizationCategoricalKnowledgeItem";
import type { UpdateCustomKnowledgeRequest } from "../../models/updateCustomKnowledgeRequest";
import type { UpdateDocumentKnowledgeRequest } from "../../models/updateDocumentKnowledgeRequest";
import type { UpdateOrgKnowledgeRequest } from "../../models/updateOrgKnowledgeRequest";

/**
 * Get all categorical knowledge items for the organization.

Collection structure:
organizations_context/<org_id>/categorical_knowledge/<category_id>/knowledge_items/<knowledge_item_id>
 * @summary Get Categorical Knowledge
 */
export const getCategoricalKnowledge = (signal?: AbortSignal) =>
  webserverAxios<OrganizationCategoricalKnowledgeItem[]>({
    url: `/api/knowledge/categorical`,
    method: "GET",
    signal,
  });

export const getGetCategoricalKnowledgeQueryKey = () => [`/api/knowledge/categorical`] as const;

export const getGetCategoricalKnowledgeQueryOptions = <
  TData = Awaited<ReturnType<typeof getCategoricalKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCategoricalKnowledge>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCategoricalKnowledgeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategoricalKnowledge>>> = ({
    signal,
  }) => getCategoricalKnowledge(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCategoricalKnowledge>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetCategoricalKnowledgeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCategoricalKnowledge>>
>;
export type GetCategoricalKnowledgeQueryError = unknown;

export function useGetCategoricalKnowledge<
  TData = Awaited<ReturnType<typeof getCategoricalKnowledge>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCategoricalKnowledge>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getCategoricalKnowledge>>, TError, TData>,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCategoricalKnowledge<
  TData = Awaited<ReturnType<typeof getCategoricalKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCategoricalKnowledge>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getCategoricalKnowledge>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCategoricalKnowledge<
  TData = Awaited<ReturnType<typeof getCategoricalKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCategoricalKnowledge>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Categorical Knowledge
 */

export function useGetCategoricalKnowledge<
  TData = Awaited<ReturnType<typeof getCategoricalKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCategoricalKnowledge>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetCategoricalKnowledgeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update a categorical knowledge item.

Collection structure:
organizations_context/<org_id>/categorical_knowledge/<category_id>/knowledge_items/<knowledge_item_id>
 * @summary Update Categorical Knowledge
 */
export const updateCategoricalKnowledge = (updateOrgKnowledgeRequest: UpdateOrgKnowledgeRequest) =>
  webserverAxios<OrganizationCategoricalKnowledgeItem>({
    url: `/api/knowledge/categorical`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateOrgKnowledgeRequest,
  });

export const getUpdateCategoricalKnowledgeMutationOptions = <
  TData = Awaited<ReturnType<typeof updateCategoricalKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UpdateOrgKnowledgeRequest }, TContext>;
}) => {
  const mutationKey = ["updateCategoricalKnowledge"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCategoricalKnowledge>>,
    { data: UpdateOrgKnowledgeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateCategoricalKnowledge(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: UpdateOrgKnowledgeRequest },
    TContext
  >;
};

export type UpdateCategoricalKnowledgeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCategoricalKnowledge>>
>;
export type UpdateCategoricalKnowledgeMutationBody = UpdateOrgKnowledgeRequest;
export type UpdateCategoricalKnowledgeMutationError = HTTPValidationError;

/**
 * @summary Update Categorical Knowledge
 */
export const useUpdateCategoricalKnowledge = <
  TData = Awaited<ReturnType<typeof updateCategoricalKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UpdateOrgKnowledgeRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: UpdateOrgKnowledgeRequest }, TContext> => {
  const mutationOptions = getUpdateCategoricalKnowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get all custom knowledge items for the organization.
 * @summary Get Custom Knowledge
 */
export const getCustomKnowledge = (signal?: AbortSignal) =>
  webserverAxios<CustomKnowledgeItem[]>({
    url: `/api/knowledge/custom`,
    method: "GET",
    signal,
  });

export const getGetCustomKnowledgeQueryKey = () => [`/api/knowledge/custom`] as const;

export const getGetCustomKnowledgeQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomKnowledge>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomKnowledgeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomKnowledge>>> = ({ signal }) =>
    getCustomKnowledge(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomKnowledge>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetCustomKnowledgeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomKnowledge>>
>;
export type GetCustomKnowledgeQueryError = unknown;

export function useGetCustomKnowledge<
  TData = Awaited<ReturnType<typeof getCustomKnowledge>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomKnowledge>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getCustomKnowledge>>, TError, TData>,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomKnowledge<
  TData = Awaited<ReturnType<typeof getCustomKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomKnowledge>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getCustomKnowledge>>, TError, TData>,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomKnowledge<
  TData = Awaited<ReturnType<typeof getCustomKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomKnowledge>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Custom Knowledge
 */

export function useGetCustomKnowledge<
  TData = Awaited<ReturnType<typeof getCustomKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomKnowledge>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetCustomKnowledgeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update a custom knowledge item.
 * @summary Update Custom Knowledge
 */
export const updateCustomKnowledge = (updateCustomKnowledgeRequest: UpdateCustomKnowledgeRequest) =>
  webserverAxios<CustomKnowledgeItem>({
    url: `/api/knowledge/custom`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateCustomKnowledgeRequest,
  });

export const getUpdateCustomKnowledgeMutationOptions = <
  TData = Awaited<ReturnType<typeof updateCustomKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UpdateCustomKnowledgeRequest }, TContext>;
}) => {
  const mutationKey = ["updateCustomKnowledge"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCustomKnowledge>>,
    { data: UpdateCustomKnowledgeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateCustomKnowledge(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: UpdateCustomKnowledgeRequest },
    TContext
  >;
};

export type UpdateCustomKnowledgeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomKnowledge>>
>;
export type UpdateCustomKnowledgeMutationBody = UpdateCustomKnowledgeRequest;
export type UpdateCustomKnowledgeMutationError = HTTPValidationError;

/**
 * @summary Update Custom Knowledge
 */
export const useUpdateCustomKnowledge = <
  TData = Awaited<ReturnType<typeof updateCustomKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UpdateCustomKnowledgeRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: UpdateCustomKnowledgeRequest }, TContext> => {
  const mutationOptions = getUpdateCustomKnowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Create a new custom knowledge item.
 * @summary Create Custom Knowledge
 */
export const createCustomKnowledge = (
  createCustomKnowledgeRequest: CreateCustomKnowledgeRequest,
  signal?: AbortSignal
) =>
  webserverAxios<CustomKnowledgeItem>({
    url: `/api/knowledge/custom`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createCustomKnowledgeRequest,
    signal,
  });

export const getCreateCustomKnowledgeMutationOptions = <
  TData = Awaited<ReturnType<typeof createCustomKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: CreateCustomKnowledgeRequest }, TContext>;
}) => {
  const mutationKey = ["createCustomKnowledge"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCustomKnowledge>>,
    { data: CreateCustomKnowledgeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createCustomKnowledge(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: CreateCustomKnowledgeRequest },
    TContext
  >;
};

export type CreateCustomKnowledgeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCustomKnowledge>>
>;
export type CreateCustomKnowledgeMutationBody = CreateCustomKnowledgeRequest;
export type CreateCustomKnowledgeMutationError = HTTPValidationError;

/**
 * @summary Create Custom Knowledge
 */
export const useCreateCustomKnowledge = <
  TData = Awaited<ReturnType<typeof createCustomKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: CreateCustomKnowledgeRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: CreateCustomKnowledgeRequest }, TContext> => {
  const mutationOptions = getCreateCustomKnowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mark a custom knowledge item for deletion.
 * @summary Delete Custom Knowledge
 */
export const deleteCustomKnowledge = (knowledgeId: string) =>
  webserverAxios<unknown>({ url: `/api/knowledge/custom/${knowledgeId}`, method: "DELETE" });

export const getDeleteCustomKnowledgeMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteCustomKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { knowledgeId: string }, TContext>;
}) => {
  const mutationKey = ["deleteCustomKnowledge"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCustomKnowledge>>,
    { knowledgeId: string }
  > = (props) => {
    const { knowledgeId } = props ?? {};

    return deleteCustomKnowledge(knowledgeId);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { knowledgeId: string },
    TContext
  >;
};

export type DeleteCustomKnowledgeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCustomKnowledge>>
>;

export type DeleteCustomKnowledgeMutationError = HTTPValidationError;

/**
 * @summary Delete Custom Knowledge
 */
export const useDeleteCustomKnowledge = <
  TData = Awaited<ReturnType<typeof deleteCustomKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { knowledgeId: string }, TContext>;
}): UseMutationResult<TData, TError, { knowledgeId: string }, TContext> => {
  const mutationOptions = getDeleteCustomKnowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get all document knowledge items for the organization.
 * @summary Get Document Knowledge
 */
export const getDocumentKnowledge = (signal?: AbortSignal) =>
  webserverAxios<DocumentKnowledgeItem[]>({
    url: `/api/knowledge/document`,
    method: "GET",
    signal,
  });

export const getGetDocumentKnowledgeQueryKey = () => [`/api/knowledge/document`] as const;

export const getGetDocumentKnowledgeQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentKnowledge>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentKnowledgeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentKnowledge>>> = ({ signal }) =>
    getDocumentKnowledge(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentKnowledge>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDocumentKnowledgeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentKnowledge>>
>;
export type GetDocumentKnowledgeQueryError = unknown;

export function useGetDocumentKnowledge<
  TData = Awaited<ReturnType<typeof getDocumentKnowledge>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentKnowledge>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getDocumentKnowledge>>, TError, TData>,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDocumentKnowledge<
  TData = Awaited<ReturnType<typeof getDocumentKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getDocumentKnowledge>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getDocumentKnowledge>>, TError, TData>,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDocumentKnowledge<
  TData = Awaited<ReturnType<typeof getDocumentKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentKnowledge>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Document Knowledge
 */

export function useGetDocumentKnowledge<
  TData = Awaited<ReturnType<typeof getDocumentKnowledge>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentKnowledge>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDocumentKnowledgeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update a document knowledge item.
 * @summary Update Document Knowledge
 */
export const updateDocumentKnowledge = (
  updateDocumentKnowledgeRequest: UpdateDocumentKnowledgeRequest
) =>
  webserverAxios<unknown>({
    url: `/api/knowledge/document`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateDocumentKnowledgeRequest,
  });

export const getUpdateDocumentKnowledgeMutationOptions = <
  TData = Awaited<ReturnType<typeof updateDocumentKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UpdateDocumentKnowledgeRequest }, TContext>;
}) => {
  const mutationKey = ["updateDocumentKnowledge"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDocumentKnowledge>>,
    { data: UpdateDocumentKnowledgeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateDocumentKnowledge(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: UpdateDocumentKnowledgeRequest },
    TContext
  >;
};

export type UpdateDocumentKnowledgeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDocumentKnowledge>>
>;
export type UpdateDocumentKnowledgeMutationBody = UpdateDocumentKnowledgeRequest;
export type UpdateDocumentKnowledgeMutationError = HTTPValidationError;

/**
 * @summary Update Document Knowledge
 */
export const useUpdateDocumentKnowledge = <
  TData = Awaited<ReturnType<typeof updateDocumentKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UpdateDocumentKnowledgeRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: UpdateDocumentKnowledgeRequest }, TContext> => {
  const mutationOptions = getUpdateDocumentKnowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a document knowledge item.

This endpoint first deletes the document from GCS storage,
then marks the document status as DELETING and triggers
an external service to handle the actual deletion from Firestore.
 * @summary Delete Document Knowledge
 */
export const deleteDocumentKnowledge = (knowledgeId: string) =>
  webserverAxios<unknown>({
    url: `/api/knowledge/document/${knowledgeId}`,
    method: "DELETE",
  });

export const getDeleteDocumentKnowledgeMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteDocumentKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { knowledgeId: string }, TContext>;
}) => {
  const mutationKey = ["deleteDocumentKnowledge"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteDocumentKnowledge>>,
    { knowledgeId: string }
  > = (props) => {
    const { knowledgeId } = props ?? {};

    return deleteDocumentKnowledge(knowledgeId);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { knowledgeId: string },
    TContext
  >;
};

export type DeleteDocumentKnowledgeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDocumentKnowledge>>
>;

export type DeleteDocumentKnowledgeMutationError = HTTPValidationError;

/**
 * @summary Delete Document Knowledge
 */
export const useDeleteDocumentKnowledge = <
  TData = Awaited<ReturnType<typeof deleteDocumentKnowledge>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { knowledgeId: string }, TContext>;
}): UseMutationResult<TData, TError, { knowledgeId: string }, TContext> => {
  const mutationOptions = getDeleteDocumentKnowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Upload a document and create a document knowledge item.
 * @summary Upload Document
 */
export const uploadDocument = (
  bodyUploadDocumentApiKnowledgeDocumentUploadPost: BodyUploadDocumentApiKnowledgeDocumentUploadPost,
  signal?: AbortSignal
) => {
  const formData = new FormData();
  formData.append("file", bodyUploadDocumentApiKnowledgeDocumentUploadPost.file);
  formData.append("title", bodyUploadDocumentApiKnowledgeDocumentUploadPost.title);
  if (bodyUploadDocumentApiKnowledgeDocumentUploadPost.description !== undefined) {
    formData.append("description", bodyUploadDocumentApiKnowledgeDocumentUploadPost.description);
  }
  formData.append("file_type", bodyUploadDocumentApiKnowledgeDocumentUploadPost.file_type);

  return webserverAxios<DocumentKnowledgeItem>({
    url: `/api/knowledge/document/upload`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
    signal,
  });
};

export const getUploadDocumentMutationOptions = <
  TData = Awaited<ReturnType<typeof uploadDocument>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyUploadDocumentApiKnowledgeDocumentUploadPost },
    TContext
  >;
}) => {
  const mutationKey = ["uploadDocument"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadDocument>>,
    { data: BodyUploadDocumentApiKnowledgeDocumentUploadPost }
  > = (props) => {
    const { data } = props ?? {};

    return uploadDocument(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyUploadDocumentApiKnowledgeDocumentUploadPost },
    TContext
  >;
};

export type UploadDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof uploadDocument>>>;
export type UploadDocumentMutationBody = BodyUploadDocumentApiKnowledgeDocumentUploadPost;
export type UploadDocumentMutationError = HTTPValidationError;

/**
 * @summary Upload Document
 */
export const useUploadDocument = <
  TData = Awaited<ReturnType<typeof uploadDocument>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyUploadDocumentApiKnowledgeDocumentUploadPost },
    TContext
  >;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyUploadDocumentApiKnowledgeDocumentUploadPost },
  TContext
> => {
  const mutationOptions = getUploadDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
