/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { AddWidgetRequest } from "../../models/addWidgetRequest";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { UpdateWidgetRequest } from "../../models/updateWidgetRequest";
import type { WidgetResponse } from "../../models/widgetResponse";

/**
 * Add a widget to a pinboard.

Creates a new widget with the provided title and description.
The widget will be added to the top of the pinboard layout and will occupy the full row width.
 * @summary Create Widget
 */
export const createWidget = (
  pinboardId: string,
  addWidgetRequest: AddWidgetRequest,
  signal?: AbortSignal
) =>
  webserverAxios<WidgetResponse>({
    url: `/api/pinboards/${pinboardId}/widgets`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: addWidgetRequest,
    signal,
  });

export const getCreateWidgetMutationOptions = <
  TData = Awaited<ReturnType<typeof createWidget>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: AddWidgetRequest },
    TContext
  >;
}) => {
  const mutationKey = ["createWidget"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createWidget>>,
    { pinboardId: string; data: AddWidgetRequest }
  > = (props) => {
    const { pinboardId, data } = props ?? {};

    return createWidget(pinboardId, data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: AddWidgetRequest },
    TContext
  >;
};

export type CreateWidgetMutationResult = NonNullable<Awaited<ReturnType<typeof createWidget>>>;
export type CreateWidgetMutationBody = AddWidgetRequest;
export type CreateWidgetMutationError = HTTPValidationError;

/**
 * @summary Create Widget
 */
export const useCreateWidget = <
  TData = Awaited<ReturnType<typeof createWidget>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; data: AddWidgetRequest },
    TContext
  >;
}): UseMutationResult<TData, TError, { pinboardId: string; data: AddWidgetRequest }, TContext> => {
  const mutationOptions = getCreateWidgetMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update a widget's title and/or description.

Args:
    pinboard_id: ID of the pinboard containing the widget
    widget_id: ID of the widget to update
    request: Updated widget details
    current_user: Current user information

Returns:
    WidgetResponse containing the updated widget
 * @summary Update Widget
 */
export const updateWidget = (
  pinboardId: string,
  widgetId: string,
  updateWidgetRequest: UpdateWidgetRequest
) =>
  webserverAxios<WidgetResponse>({
    url: `/api/pinboards/${pinboardId}/widgets/${widgetId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateWidgetRequest,
  });

export const getUpdateWidgetMutationOptions = <
  TData = Awaited<ReturnType<typeof updateWidget>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; widgetId: string; data: UpdateWidgetRequest },
    TContext
  >;
}) => {
  const mutationKey = ["updateWidget"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateWidget>>,
    { pinboardId: string; widgetId: string; data: UpdateWidgetRequest }
  > = (props) => {
    const { pinboardId, widgetId, data } = props ?? {};

    return updateWidget(pinboardId, widgetId, data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; widgetId: string; data: UpdateWidgetRequest },
    TContext
  >;
};

export type UpdateWidgetMutationResult = NonNullable<Awaited<ReturnType<typeof updateWidget>>>;
export type UpdateWidgetMutationBody = UpdateWidgetRequest;
export type UpdateWidgetMutationError = HTTPValidationError;

/**
 * @summary Update Widget
 */
export const useUpdateWidget = <
  TData = Awaited<ReturnType<typeof updateWidget>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; widgetId: string; data: UpdateWidgetRequest },
    TContext
  >;
}): UseMutationResult<
  TData,
  TError,
  { pinboardId: string; widgetId: string; data: UpdateWidgetRequest },
  TContext
> => {
  const mutationOptions = getUpdateWidgetMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a widget from a pinboard.

Args:
    pinboard_id: ID of the pinboard containing the widget
    widget_id: ID of the widget to delete
    current_user: Current user information

Returns:
    WidgetResponse containing status information
 * @summary Delete Widget
 */
export const deleteWidget = (pinboardId: string, widgetId: string) =>
  webserverAxios<WidgetResponse>({
    url: `/api/pinboards/${pinboardId}/widgets/${widgetId}`,
    method: "DELETE",
  });

export const getDeleteWidgetMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteWidget>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { pinboardId: string; widgetId: string }, TContext>;
}) => {
  const mutationKey = ["deleteWidget"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteWidget>>,
    { pinboardId: string; widgetId: string }
  > = (props) => {
    const { pinboardId, widgetId } = props ?? {};

    return deleteWidget(pinboardId, widgetId);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { pinboardId: string; widgetId: string },
    TContext
  >;
};

export type DeleteWidgetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWidget>>>;

export type DeleteWidgetMutationError = HTTPValidationError;

/**
 * @summary Delete Widget
 */
export const useDeleteWidget = <
  TData = Awaited<ReturnType<typeof deleteWidget>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { pinboardId: string; widgetId: string }, TContext>;
}): UseMutationResult<TData, TError, { pinboardId: string; widgetId: string }, TContext> => {
  const mutationOptions = getDeleteWidgetMutationOptions(options);

  return useMutation(mutationOptions);
};
