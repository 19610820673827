/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pin, PinboardContextI } from "@doowii-types/pinboard";
import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import { PinboardDoc } from "@services/api/generated/webserver/models/pinboardDoc";
import { WidgetDoc } from "@services/api/generated/webserver/models/widgetDoc";
import { sharePinboard } from "@services/webserver/pinboard";
import { firebaseTimestampToDate } from "@utils/time";
import { withSentry } from "@utils/wrapper";
import { useToast } from "doowii-ui";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Layout } from "react-grid-layout";
import { v4 as uuidV4 } from "uuid";

import { db } from "../../services/firebase";
import { useChatData } from "../chat";

const initialState: PinboardContextI = {
  boards: new Map(),
  currBoardId: null,
  loading: false,
  pinboardResults: [],
  widgets: [],
  setBoards: () => {},
  setCurrBoardId: () => {},
  setPinboardResults: () => {},
  addNewBoard: async () => "",
  deleteBoard: async () => {},
  updateBoardDetails: async () => {},
  fetchPinboardResultsForBoard: async () => {},
  fetchNextPagePinboardResultsForBoard: async () => {},
  fetchWidgetsForBoard: async () => {},
  pinToBoard: async () => {},
  unpinFromBoard: async () => {},
  updatePinTitle: async () => {},
  sharePinboardViewOnly: async () => {},
};

const PinboardContext = createContext<PinboardContextI>(initialState);
export const usePinboard = () => useContext(PinboardContext);

export const PinboardProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useLingui();
  const [boards, setBoards] = useState<Map<string, PinboardDoc>>(new Map());
  const [currBoardId, setCurrBoardId] = useState<string | null>(null);
  const [pinboardResults, setPinboardResults] = useState<Pin[]>([]);
  const [widgets, setWidgets] = useState<WidgetDoc[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(null);
  const [loading, setLoading] = useState(false);

  const { userDocument } = useAuth();
  const { allResults, answer }: any = useChatData();

  const pinsUnsubscribeRef = useRef<ReturnType<typeof onSnapshot>>();
  const pageSize = 50;
  const { toast } = useToast();

  const fetchAndSetPins = withSentry((pins: Pin[]): Promise<void> => {
    setPinboardResults(pins);
    setLoading(false);
    return Promise.resolve();
  });

  // Function to fetch widgets for a specific pinboard
  const fetchWidgetsForBoard = withSentry(async (boardId: string): Promise<void> => {
    if (!boardId) {
      return Promise.resolve();
    }

    if (!userDocument) {
      throw new Error("User not found!");
    }

    // Clear widgets when fetching new ones
    setWidgets([]);

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
    const widgetsCollection = query(
      collection(pinboardDocRef, "widgets"),
      orderBy("created_at", "asc")
    );

    try {
      // Use getDocs for a one-time fetch instead of onSnapshot
      const snapshot = await getDocs(widgetsCollection);
      const fetchedWidgets: WidgetDoc[] = [];

      snapshot.forEach((docSnap) => {
        const widgetData = docSnap.data() as WidgetDoc;
        fetchedWidgets.push(widgetData);
      });

      // Update widgets with the fetched widgets
      setWidgets(fetchedWidgets);
    } catch (error) {
      console.error("Error fetching widgets:", error);

      throw error;
      // Handle error accordingly
    }

    return Promise.resolve();
  });

  const fetchPinboardResultsForBoard = withSentry((): Promise<void> => {
    // Ensure we have a board ID to fetch results for
    if (!currBoardId) {
      return Promise.resolve();
    }

    if (!userDocument) {
      throw new Error("User not found!");
    }
    setLoading(true);
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", currBoardId);
    const pinsCollection = query(
      collection(pinboardDocRef, "pins"),
      orderBy("created_at", "asc"),
      limit(pageSize)
    );

    // Cleanup any previous listener
    if (pinsUnsubscribeRef.current) {
      pinsUnsubscribeRef.current();
    }

    // Set up a real-time listener for the pins subCollection
    pinsUnsubscribeRef.current = onSnapshot(pinsCollection, async (snapshot) => {
      const fetchedResults: Pin[] = [];

      snapshot.forEach((docSnap) => {
        const schemaUpdatedAt = firebaseTimestampToDate(docSnap.data().schema_updated_at) || null;
        const pinData = docSnap.data() as Pin;
        pinData.schemaUpdatedAt = schemaUpdatedAt;
        pinData.chartConfig.selected_visualization =
          pinData.chartConfig.selected_visualization ?? pinData.chartConfig.suggestion;
        fetchedResults.push(pinData);
      });
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

      await fetchAndSetPins(fetchedResults);
    });
    return Promise.resolve();
  });

  useEffect(() => {
    setLoading(true);
    setBoards(new Map());
    const userId = userDocument?.id;
    if (!userId) {
      setLoading(false);
      return;
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const createdByQuery = query(pinboardsCollection, where("created_by", "==", userId));
    const canEditQuery = query(pinboardsCollection, where("can_view", "array-contains", userId));

    const mergeAndUpdateBoards = (docSnap: QueryDocumentSnapshot<any>) => {
      const updatedBoards = new Map([...boards, [docSnap.id, docSnap.data()]]);
      setBoards((prevBoards) => new Map([...prevBoards, [docSnap.id, docSnap.data()]]));

      if (updatedBoards.size > 0) {
        setCurrBoardId((prevCurrBoardId) => prevCurrBoardId ?? updatedBoards.keys().next().value);
      } else {
        setCurrBoardId(null);
      }
    };

    const unsubscribeCreatedBy = onSnapshot(createdByQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });

    const unsubscribeCanEdit = onSnapshot(canEditQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });

    setLoading(false);

    // Cleanup the listeners on component unmount
    return () => {
      unsubscribeCreatedBy();
      unsubscribeCanEdit();
    };
    // do not run again on boards change
    // do not add fetchPinboardResultsForBoard to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDocument]);

  useEffect(() => {
    // Whenever the current board ID changes, fetch the results for that board
    setPinboardResults([]);
    setWidgets([]);
    void fetchPinboardResultsForBoard();

    // Also fetch widgets for the current board
    if (currBoardId) {
      void fetchWidgetsForBoard(currBoardId);
    }

    // Cleanup the listeners on component unmount or when the board ID changes
    return () => {
      if (pinsUnsubscribeRef.current) {
        pinsUnsubscribeRef.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currBoardId]);

  const addNewBoard = withSentry(async (details: { name: string; description?: string }) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }
      const userId = userDocument.id;
      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardsCollection = collection(organizationRef, "pinboards");
      const userDocRef = doc(organizationRef, "users", userId);

      const uuid = uuidV4();

      const pinboardData = {
        created_by: userId,
        can_edit: [],
        can_view: [],
        pins: [],
        widgets: [],
        name: details.name,
        description: details.description || "",
        id: uuid,
        created_at: serverTimestamp(),
        last_updated: serverTimestamp(),
        count: 0,
      };
      await setDoc(doc(pinboardsCollection, uuid), pinboardData);
      await updateDoc(userDocRef, {
        pinboards: arrayUnion(uuid),
      });

      toast({
        title: t`Pinboard created`,
        description: t`Pinboard has been created successfully`,
        status: "success",
      });
      return uuid;
    } catch (error) {
      toast({
        title: t`Failed to create Pinboard`,
        description: t`Failed to create Pinboard. Please try again later.`,
        status: "error",
      });
    }
  });

  const deleteBoard = withSentry(async (boardId: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }
      const organizationRef = doc(db, "organizations", userDocument.organization);

      const pinboardsCollection = collection(organizationRef, "pinboards");
      const pinboardDocRef = doc(pinboardsCollection, boardId);

      // Fetch the pinboard document
      const pinboardDocSnap = await getDoc(pinboardDocRef);

      if (!pinboardDocSnap.exists()) {
        throw new Error("Pinboard not found!");
      }

      // Update the user documents to remove the pinboard ID
      const pinboardData = pinboardDocSnap.data();
      const allUsersToUpdate = [...pinboardData.can_edit, ...pinboardData.can_view];
      allUsersToUpdate.push(pinboardData.created_by);
      const userUpdates = allUsersToUpdate.map((userId) => {
        const usersCollection = collection(organizationRef, "users");
        const userDocRef = doc(usersCollection, userId);
        return updateDoc(userDocRef, {
          pinboards: arrayRemove(boardId),
        });
      });
      await Promise.all(userUpdates);

      // Delete the pinboard document and all its pins and widgets
      const pinsCollectionRef = collection(pinboardDocRef, "pins");
      const widgetsCollectionRef = collection(pinboardDocRef, "widgets");
      const pinsSnapshot = await getDocs(pinsCollectionRef);
      const widgetsSnapshot = await getDocs(widgetsCollectionRef);
      const batch = writeBatch(db);

      // Delete all pins
      pinsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete all widgets
      widgetsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      batch.delete(pinboardDocRef);
      await batch.commit();

      // Update the local state
      const newBoards = new Map(boards);
      newBoards.delete(boardId);
      setBoards(newBoards);

      // Clear widgets if we're deleting the current board
      if (currBoardId === boardId) {
        setWidgets([]);
      }

      if (newBoards.size > 0) {
        setCurrBoardId(newBoards.keys().next().value);
      } else {
        setCurrBoardId(null);
      }

      toast({
        title: t`Pinboard deleted`,
        description: t`Pinboard has been deleted successfully`,
        status: "success",
      });
    } catch (error) {
      toast({
        title: t`Failed to delete Pinboard`,
        description: t`Failed to delete Pinboard. Please try again later.`,
        status: "error",
      });
    }
  });

  const updateBoardDetails = withSentry(
    async (boardId: string, details: { name?: string; description?: string }) => {
      try {
        if (!userDocument) {
          throw new Error("User not found!");
        }
        const organizationRef = doc(db, "organizations", userDocument.organization);
        const pinboardDocRef = doc(organizationRef, "pinboards", boardId);

        const updateData: { name?: string; description?: string } = {};
        if (details.name !== undefined) {
          updateData.name = details.name;
        }
        if (details.description !== undefined) {
          updateData.description = details.description;
        }

        await updateDoc(pinboardDocRef, updateData);

        toast({
          title: t`Pinboard updated`,
          description: t`Pinboard details have been updated successfully`,
          status: "success",
        });
      } catch (error) {
        toast({
          title: t`An error occurred`,
          description: t`Failed to update pinboard details`,
          status: "error",
        });
      }
    }
  );

  const pinToBoard = withSentry(async (chatId: string, boardId: string) => {
    try {
      if (!userDocument || !allResults) {
        throw new Error("User not found!");
      }
      const result = allResults.find((res) => res.id === chatId);
      const chatIndex = allResults.findIndex((item) => item.id === chatId);
      const chatAnswer = answer.find((item, index) => index === chatIndex);
      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
      const pinRef = doc(pinboardDocRef, "pins", result.id);
      const pinDoc = await getDoc(pinRef);

      if (pinDoc.exists()) {
        console.info("Chat already pinned to the pinboard");
        toast({
          title: t`Chat already pinned`,
          description: t`The chat is already pinned to the pinboard`,
          status: "info",
        });
        return;
      }

      await setDoc(pinRef, {
        id: result.id,
        query: result.query,
        sql: result.sql ?? "",
        originalSql: result.originalSql ?? result.sql ?? "",
        title: result.title,
        timestamp: new Date(),
        can_view: [],
        can_edit: [],
        created_at: new Date(),
        last_updated: new Date(),
        type: result.type || "QUERY",
        status: result.status || "success",
        chartConfig: result.chartConfig || {},
        answer: chatAnswer || "",
        schema_updated_at: result.schemaUpdatedAt,
        total_rows: result.total_rows || -1,
        boardId,
      });
      const pinboardDoc = await getDoc(pinboardDocRef);
      const pinboardData = pinboardDoc.data();
      if (pinboardData.pin_layouts) {
        const currentLayouts = pinboardData.pin_layouts;

        const updatedLayouts = Object.keys(currentLayouts).reduce((acc, breakpoint) => {
          const layouts = currentLayouts[breakpoint];

          // Set the breakpoint configuration directly since we will move the whole function to the backend
          const breakpointConfig = {
            lg: { width: 6, height: 12, minH: 6, minW: 3 },
            md: { width: 5, height: 12, minH: 6, minW: 3 },
            sm: { width: 6, height: 10, minH: 5, minW: 3 },
            xs: { width: 4, height: 10, minH: 5, minW: 2 },
            xxs: { width: 2, height: 10, minH: 3, minW: 2 },
          }[breakpoint];

          const newLayout = {
            i: result.id,
            x: 0,
            y: layouts.length > 0 ? Math.max(...layouts.map((item) => item.y + item.h)) : 0,
            w: breakpointConfig.width,
            h: breakpointConfig.height,
            minH: breakpointConfig.minH,
            minW: breakpointConfig.minW,
            static: false,
          };

          return {
            ...acc,
            [breakpoint]: [...layouts, newLayout],
          };
        }, {});

        await updateDoc(pinboardDocRef, {
          pin_layouts: updatedLayouts,
          pins: arrayUnion(result.id),
          count: increment(1),
        });
      } else {
        await updateDoc(pinboardDocRef, { pins: arrayUnion(result.id), count: increment(1) });
      }

      toast({
        title: t`Chat pinned`,
        description: t`The chat has been pinned to the pinboard`,
        status: "success",
      });
    } catch (error) {
      toast({
        title: t`Failed to pin chat`,
        description: t`Failed to pin chat to the pinboard`,
        status: "error",
      });
    }
  });

  const unpinFromBoard = withSentry(async (chatId: string, boardId: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }
      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
      const pinRef = doc(pinboardDocRef, "pins", chatId);

      // Remove pin from layouts and update pinboard doc
      const pinboardDoc = await getDoc(pinboardDocRef);

      const pinLayouts = pinboardDoc.data()?.pin_layouts;

      if (pinLayouts) {
        // Remove the pin from all breakpoint layouts
        const updatedLayouts = Object.entries(pinLayouts).reduce(
          (acc, [breakpoint, layouts]) => ({
            ...acc,
            [breakpoint]: (layouts as Layout[]).filter((layout) => layout.i !== chatId),
          }),
          {}
        );

        await updateDoc(pinboardDocRef, {
          pins: arrayRemove(chatId),
          count: increment(-1),
          pin_layouts: updatedLayouts,
        });
      } else {
        await updateDoc(pinboardDocRef, { pins: arrayRemove(chatId), count: increment(-1) });
      }

      await deleteDoc(pinRef);
      toast({
        title: t`Chat unpinned`,
        description: t`The chat has been unpinned from the pinboard`,
        status: "success",
      });
    } catch (error) {
      console.error("Error unpinning chat:", error);
      toast({
        title: t`Failed to unpin chat`,
        description: t`Failed to unpin chat from the pinboard`,
        status: "error",
      });
    }
  });

  const fetchNextPagePinboardResultsForBoard = withSentry(async () => {
    if (!currBoardId || !lastVisible) {
      return;
    }

    if (!userDocument) {
      throw new Error("User not found!");
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", currBoardId);
    const pinsCollectionQuery = query(
      collection(pinboardDocRef, "pins"),
      orderBy("created_at", "asc"),
      startAfter(lastVisible),
      limit(pageSize)
    );
    const snapshot = await getDocs(pinsCollectionQuery);

    const fetchedResults: Pin[] = [];
    snapshot.forEach((docSnap) => {
      const schemaUpdatedAt = firebaseTimestampToDate(docSnap.data().schema_updated_at) || null;
      const pinData = docSnap.data() as Pin;
      pinData.schemaUpdatedAt = schemaUpdatedAt;
      fetchedResults.push(pinData);
    });
    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    await fetchAndSetPins([...pinboardResults, ...fetchedResults]);
  });
  // Add other relevant functions for your use case

  const updatePinTitle = withSentry(async (pinboardId: string, pinId: string, title: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }

      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", pinboardId);
      const pinRef = doc(pinboardDocRef, "pins", pinId);
      await updateDoc(pinRef, { title });

      toast({
        title: t`Pin title updated`,
        description: t`Pin title updated successfully!`,
        status: "success",
      });
    } catch (error) {
      toast({
        title: t`An error occurred`,
        description: t`Error updating pin title! Please try again later.`,
        status: "error",
      });
    }
  });

  const sharePinboardViewOnly = withSentry(async (boardId: string, userIds: string[]) => {
    try {
      if (!boardId) {
        throw new Error("Pinboard ID is required!");
      }
      await sharePinboard(boardId, userIds);
    } catch (error) {
      throw new Error(error);
    }
  });

  return (
    <PinboardContext.Provider
      value={{
        loading,
        boards,
        setBoards,
        currBoardId,
        setCurrBoardId,
        pinboardResults,
        setPinboardResults,
        widgets,
        addNewBoard,
        deleteBoard,
        updateBoardDetails,
        fetchPinboardResultsForBoard,
        fetchNextPagePinboardResultsForBoard,
        fetchWidgetsForBoard,
        pinToBoard,
        unpinFromBoard,
        updatePinTitle,
        sharePinboardViewOnly,
      }}
    >
      {children}
    </PinboardContext.Provider>
  );
};
