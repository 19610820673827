import { ParentDocTypeEnum } from "@api/retriever.i";
import { PaginatedDataTable } from "@components/PaginatedDataTable";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { Trans } from "@lingui/react/macro";
import { VStack } from "@styled-system/jsx";
import { Callout } from "doowii-ui";
import React from "react";

interface ServerSideDataRenderProps {
  currentResult: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
}

const ServerSideDataRender: React.FC<ServerSideDataRenderProps> = ({
  currentResult,
  parentDocId,
  parentDocType,
}) => (
  <VStack gap="4" width="100%">
    <Callout size="1" style={{ marginTop: "1rem" }} variant="info">
      <Trans>
        Your query is returning too much data which restricts charting and filtering. Try asking
        Doowii to filter the data for better results!
      </Trans>
    </Callout>
    <PaginatedDataTable
      parentDocId={parentDocId}
      parentDocType={parentDocType}
      result={currentResult}
    />
  </VStack>
);

export { ServerSideDataRender };
