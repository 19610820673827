import { PageHeader } from "@components/PageHeader";
import { HIGHER_ED } from "@constants/constants";
import { PATHS } from "@constants/Paths";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { BackgroundBox, Button, InputField, LoadingLogo, Logo } from "doowii-ui";
import { useEffect } from "react";
import { Form, Link, useActionData, useNavigate, useNavigation, useSubmit } from "react-router-dom";

import { env } from "../../env";
import { SignInActionResponse } from "./signInAction";

const SignIn = () => {
  const { t } = useLingui();
  const submit = useSubmit();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const actionData = useActionData() as SignInActionResponse | null;
  const { authUser, isLoading } = useAuth();

  const params = new URLSearchParams(location.search);
  const from = params.get("from") ?? PATHS.ROOT;

  const handleGoogleSignIn = () => {
    submit({ signInMethod: "google", redirectTo: from }, { method: "post", action: PATHS.SIGN_IN });
  };

  const isSubmitting = navigation.state === "submitting" || actionData?.loadingUser;

  useEffect(() => {
    if (authUser && !actionData?.errors) {
      navigate(from, { replace: true });
    }
  }, [authUser, navigate, from]);

  if (isLoading) {
    return (
      <Flex css={{ h: "100vh", alignItems: "center" }}>
        <LoadingLogo />
      </Flex>
    );
  }

  return (
    <>
      <PageHeader description={t`Sign in to your account`} title={t`Doowii - Sign in`} />

      <BackgroundBox
        containerProps={{ css: {} }}
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          padding: "xl6",
          opacity: isSubmitting ? 0.5 : 1,
          pointerEvents: isSubmitting ? "none" : "auto",
        }}
      >
        <Box
          className={flex({
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            flex: 1,
          })}
        >
          <Logo />
          <h2
            className={css({
              textStyle: "title_h2",
              fontWeight: 600,
              marginTop: "md",
              marginBottom: "xl",
            })}
          >
            <Trans>Sign in to your account</Trans>
          </h2>
          <Form className={css({ width: "100%", maxWidth: "400px" })} method="post">
            <input name="redirectTo" type="hidden" value={from} />
            <Box
              className={flex({
                flexDirection: "column",
                gap: "ld",
              })}
            >
              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.email}
                label={t`Your email`}
                name="email"
                placeholder="name@school.edu"
                type="email"
              />

              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.password}
                label={t`Password`}
                name="password"
                placeholder="••••••••"
                type="password"
              />
            </Box>
            <Link
              className={css({
                display: "block",
                textAlign: "left",
                marginTop: "sm",
                color: "base.blueBrandPrimary",
                textStyle: "paragraph_14",
                _hover: { textDecoration: "underline" },
              })}
              to={PATHS.RESET_PASSWORD}
            >
              <Trans>Forgot password?</Trans>
            </Link>
            <Button
              className={css({ marginTop: "xl3" })}
              data-testid="sign-in-submit"
              disabled={isSubmitting}
              full
              loading={isSubmitting}
              type="submit"
            >
              <Trans>Sign in</Trans>
            </Button>
          </Form>
          {env.REACT_APP_ENV !== HIGHER_ED ? (
            <>
              <Box
                className={css({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  my: "ld",
                  width: "100%",
                  maxWidth: "400px",
                })}
              >
                <Box
                  className={css({
                    flex: 1,
                    height: "1px",
                    backgroundColor: "border.disabled",
                  })}
                />
                <Box
                  className={css({
                    color: "foreground.muted",
                    textStyle: "paragraph_14",
                    px: "md",
                  })}
                >
                  <Trans>OR</Trans>
                </Box>
                <Box
                  className={css({
                    flex: 1,
                    height: "1px",
                    backgroundColor: "border.disabled",
                  })}
                />
              </Box>

              <Button
                className={css({
                  width: "100%",
                  maxWidth: "400px",
                })}
                disabled={isSubmitting}
                full={false}
                iconLeft={faGoogle}
                loading={isSubmitting}
                onClick={handleGoogleSignIn}
                variant="secondary"
              >
                <Trans>Sign in with Google</Trans>
              </Button>
            </>
          ) : null}
        </Box>
        <Box
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "foreground.muted",
            textStyle: "paragraph_14",
          })}
        >
          <Trans>Don't have an account yet?</Trans>{" "}
          <Button
            className={css({ marginTop: "md" })}
            disabled={isSubmitting}
            full
            onClick={() => navigate(PATHS.SIGN_UP)}
          >
            {" "}
            <Trans>Sign up</Trans>
          </Button>
        </Box>
      </BackgroundBox>
    </>
  );
};

export { SignIn };
