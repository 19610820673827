import { Flex } from "@styled-system/jsx";
import { Skeleton } from "doowii-ui";

const FollowupSkeleton = () => (
  <Flex align="stretch" direction="row" gap="4" pb="4">
    <Skeleton height="6rem" width="100%" />
    <Skeleton height="6rem" width="100%" />
    <Skeleton height="6rem" width="100%" />
  </Flex>
);

export { FollowupSkeleton };
