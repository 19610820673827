import { usePinboard } from "@context/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { Trans, useLingui } from "@lingui/react/macro";
import { useUpdateWidget } from "@services/api/generated/webserver/endpoints/widgets/widgets";
import { WidgetDoc } from "@services/api/generated/webserver/models/widgetDoc";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Text,
  Textarea,
  useToast,
} from "doowii-ui";
import { useEffect, useState } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  widget: WidgetDoc;
  pinboardId: string;
}

const EditWidgetDialog: React.FC<Props> = ({ isOpen, setIsOpen, widget, pinboardId }) => {
  const { t } = useLingui();
  const { toast } = useToast();
  const [title, setTitle] = useState(widget.title || "");
  const [description, setDescription] = useState(widget.description || "");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const { fetchWidgetsForBoard } = usePinboard();

  // Update state if widget changes (e.g., when editing different widgets)
  useEffect(() => {
    if (isOpen) {
      setTitle(widget.title || "");
      setDescription(widget.description || "");
      setTitleErrorMessage("");
    }
  }, [widget, isOpen]);

  const { mutate: updateWidget, isPending: isUpdating } = useUpdateWidget({
    mutation: {
      onSuccess: () => {
        toast({
          status: "success",
          title: t`Widget updated successfully`,
        });
        setIsOpen(false);
        fetchWidgetsForBoard(pinboardId);
      },
      onError: (error) => {
        console.error("Error updating widget:", error);
        toast({
          status: "error",
          title: t`Failed to update widget`,
          description:
            typeof error.detail === "string" ? error.detail : t`An unexpected error occurred`,
        });
      },
    },
  });

  const handleUpdateWidget = async () => {
    if (title.trim().length < 1) {
      setTitleErrorMessage(t`Widget title cannot be empty`);
      return;
    }

    try {
      updateWidget({
        pinboardId,
        widgetId: widget.id,
        data: {
          title,
          description: description || undefined,
        },
      });
    } catch (error) {
      console.error("Error updating widget:", error);
    }
  };

  const characterLimit = 500;
  const totalCharacters = description.length;
  const hasChanges = title !== widget.title || description !== widget.description;

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent
        aria-describedby={t`Edit text widget dialog.`}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader>
          <DialogTitle>
            <Trans>Edit text widget</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <InputField
            disabled={isUpdating}
            errorText={titleErrorMessage}
            label={t`Widget title`}
            onChange={(e) => {
              setTitle(e.target.value);
              if (e.target.value.trim().length > 0) {
                setTitleErrorMessage("");
              }
            }}
            placeholder={t`Enter widget title`}
            required
            type="text"
            value={title}
          />
          <Flex direction="column">
            <Flex direction="column" gap="xs">
              <Textarea
                disabled={isUpdating}
                label={t`Widget description (optional)`}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= characterLimit) {
                    setDescription(newValue);
                  }
                }}
                placeholder={t`Enter widget description...`}
                rows={3}
                value={description}
              />
            </Flex>
            <Text
              className={css({
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "sm",
                color: "gray.500",
                mt: "xs",
              })}
            >
              {totalCharacters} / {characterLimit}
            </Text>
          </Flex>
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button
            disabled={isUpdating}
            onClick={() => setIsOpen(false)}
            size="large"
            variant="secondary"
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            disabled={title.length === 0 || isUpdating || !hasChanges}
            loading={isUpdating}
            onClick={stopPropagationWrapper(handleUpdateWidget)}
            size="large"
            variant="primary"
          >
            <Trans>Save changes</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { EditWidgetDialog };
