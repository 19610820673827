import { ParentDocTypeEnum } from "@api/retriever.i";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { faGripLinesVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useLingui } from "@lingui/react/macro";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";
import { getOrCreateTableStore } from "@stores/tableStore";
import { css } from "@styled-system/css";
import { HStack } from "@styled-system/jsx";
import { token } from "@styled-system/tokens";
import { TableState } from "@tanstack/react-table";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TabContent,
  Tabs,
} from "doowii-ui";
import React, { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useStore } from "zustand";

import { ApolloTab } from "./ApolloTab";
import { CustomizePanel } from "./CustomizePanel";
import { DataPanel } from "./DataPanel";
import { PreviewPanel } from "./PreviewPanel";

interface ChartBuilderDialogProps {
  result: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const ChartBuilderDialog: React.FC<ChartBuilderDialogProps> = ({
  result,
  parentDocId,
  parentDocType,
  setIsOpen,
  isOpen,
}) => {
  const { t } = useLingui();
  const [activeTab, setActiveTab] = useState("data");

  // Get the table store
  const tableStore = getOrCreateTableStore({
    parentDocId,
    parentDocType,
    docId: result.id,
    sql: result.sql,
  });

  // Use separate useStore hooks for each value we need to access, with proper typing
  const chartConfig = useStore(tableStore, (state) => state.chartConfig);
  const getFilteredRows = useStore(tableStore, (state) => state.getFilteredRows);
  const updateChartConfig = useStore(tableStore, (state) => state.updateChartConfig);
  const saveStoreChartConfig = useStore(tableStore, (state) => state.saveStoreChartConfig);
  const { tableInstance } = useStore(tableStore);

  useEffect(() => {
    // turn off auto save for the duration of the dialog
    if (isOpen) {
      tableStore.setState({ shouldAutoSave: false });
    } else {
      tableStore.setState({ shouldAutoSave: true });
    }
  }, [tableStore, isOpen]);

  const handleSave = () => {
    if (!chartConfig?.columns || chartConfig.columns.length <= 1) {
      // if no feasible charts flip suggested & selected_visualization to TABLE
      updateChartConfig({
        ...chartConfig,
        suggestion: DataVisualizationType.TABLE,
        selected_visualization: DataVisualizationType.TABLE,
      });
    }
    saveStoreChartConfig();
    setIsOpen(false);
    setActiveTab("data");
  };

  const handleCancel = () => {
    // reset the store chart config to the saved version in firestore
    updateChartConfig(result.chartConfig);
    // reset the table state to the saved version in firestore
    tableInstance?.setState(result.chartConfig.table_state as unknown as TableState);

    setIsOpen(false);
    setActiveTab("data");
  };

  // Handle dialog open state changes
  const handleOpenChange = (open: boolean) => {
    // This only triggers when the dialog is closed via X button or clicking outside the dialog
    // does not trigger on open
    if (!open) {
      handleCancel();
      setIsOpen(false);
    }
  };

  const isCustomizeDisabled =
    !chartConfig?.suggestion ||
    chartConfig?.suggestion === DataVisualizationType.TABLE ||
    chartConfig?.columns.length <= 1;

  const tabItems = [
    { value: "data", label: t`Data` },
    // disable the customize & ai tabs if the selected_visualization is not set or TABLE
    // or if there are not enough columns to generate a chart
    {
      value: "customize",
      label: t`Customize`,
      disabled: isCustomizeDisabled,
    },
    { value: "ai", label: t`AI Assistant`, disabled: isCustomizeDisabled },
  ];

  return (
    <Dialog onOpenChange={handleOpenChange} open={isOpen}>
      <DialogContent
        aria-describedby={undefined}
        className={css({ minWidth: "90vw", minHeight: "90vh" })}
        data-testid="chart-builder-dialog-content"
        variant="fullScreen"
      >
        <DialogHeader>
          <DialogTitle style={{ margin: "0", justifyContent: "start" }}>
            <Trans>Chart Builder</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ p: 0, display: "flex" })}>
          <PanelGroup
            className={css({
              minHeight: "0",
              maxHeight: "100%",
              p: "sm",
              py: 0,
              height: "auto",
            })}
            direction="horizontal"
            style={{ height: "auto" }}
          >
            {/* Left Panel: Configuration */}
            <Panel
              className={css({
                backgroundColor: "white",
                borderRadius: "md",
              })}
              data-test-id="tabs-wrapper"
              defaultSize={60}
              minSize={40}
            >
              <Tabs
                className={css({ height: "100%", display: "flex", flexDirection: "column" })}
                data-test-id="tabs-component"
                items={tabItems}
                onValueChange={setActiveTab}
                value={activeTab}
              >
                <TabContent
                  className={css({
                    minHeight: 0,
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  })}
                  data-test-id="data-tabs-content"
                  value="data"
                >
                  <DataPanel
                    chartConfig={chartConfig}
                    currentResult={result}
                    parentDocId={parentDocId}
                    parentDocType={parentDocType}
                    updateConfigState={updateChartConfig}
                  />
                </TabContent>
                <TabContent
                  className={css({
                    minHeight: 0,
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  })}
                  value="customize"
                >
                  <CustomizePanel chartConfig={chartConfig} updateConfig={updateChartConfig} />
                </TabContent>
                <TabContent
                  className={css({
                    minHeight: 0,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  })}
                  value="ai"
                >
                  <ApolloTab
                    parentDocId={parentDocId}
                    parentDocType={parentDocType}
                    result={result}
                  />
                </TabContent>
              </Tabs>
            </Panel>

            <PanelResizeHandle
              className={css({
                display: "flex",
                alignItems: "center",
                width: "7px",
                backgroundColor: "base.lightherGray",
                opacity: 0.75,
              })}
            >
              <FontAwesomeIcon color={token("colors.border.base")} icon={faGripLinesVertical} />
            </PanelResizeHandle>

            {/* Right Panel: Chart Preview */}
            <Panel
              className={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                gap: "md",
                backgroundColor: "background.base",
                borderRadius: "md",
                padding: "md",
              })}
              defaultSize={40}
              minSize={20}
            >
              {/* Use the new PreviewPanel component */}
              <PreviewPanel
                chartConfig={chartConfig}
                dataObject={getFilteredRows()}
                onChartTypeChange={updateChartConfig}
              />
            </Panel>
          </PanelGroup>
        </DialogBody>
        <DialogFooter>
          <HStack gap="md" justifyContent="end" width="full">
            <Button onClick={handleCancel} variant="secondary">
              <Trans>Cancel</Trans>
            </Button>
            <Button onClick={handleSave} variant="primary">
              <Trans>Save chart</Trans>
            </Button>
          </HStack>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ChartBuilderDialog };
