import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Skeleton } from "doowii-ui";

const ChatExplanationSkeleton = () => (
  <>
    <Skeleton height="2.5rem" mb="2" width="50%" />
    <Skeleton height="6rem" mb="2" width="100%" />
    <Box className={css({ mt: "4" })}>
      <Skeleton height="2.5rem" mb="2" width="50%" />
      <Skeleton height="1.5rem" mb="2" width="60%" />
      <Skeleton height="1.5rem" mb="2" width="80%" />
      <Skeleton height="1.5rem" mb="2" width="90%" />
      <Skeleton height="1.5rem" mb="2" width="70%" />
      <Skeleton height="1.5rem" mb="2" width="80%" />
      <Skeleton height="1.5rem" mb="2" width="90%" />
      <Skeleton height="1rem" mb="2" width="40%" />
      <Skeleton height="1.5rem" mb="2" width="70%" />
      <Skeleton height="1.5rem" mb="2" width="80%" />
    </Box>
  </>
);

export { ChatExplanationSkeleton };
