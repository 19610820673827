import { Trans } from "@lingui/react/macro";
import { Flex, VStack } from "@styled-system/jsx";
import { Switch, Text } from "doowii-ui";
import React, { useEffect, useState } from "react";

export interface TooltipOptionsProps {
  initialShowTooltip?: boolean;
  initialTooltipFormat?: string;
  onOptionsChange: (options: { showTooltip?: boolean; tooltipFormat?: string }) => void;
}

export const TooltipOptions: React.FC<TooltipOptionsProps> = ({
  initialShowTooltip = true,
  initialTooltipFormat = "",
  onOptionsChange,
}) => {
  // Local state
  const [showTooltip, setShowTooltip] = useState(initialShowTooltip);
  const [tooltipFormat, setTooltipFormat] = useState(initialTooltipFormat);

  // Initialize state from props
  useEffect(() => {
    setShowTooltip(initialShowTooltip !== false);
    setTooltipFormat(initialTooltipFormat || "");
  }, [initialShowTooltip, initialTooltipFormat]);

  // Notify parent of changes
  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange({
        showTooltip,
        tooltipFormat,
      });
    }
  }, [showTooltip, tooltipFormat, onOptionsChange]);

  return (
    <VStack alignItems="flex-start" gap="md" width="full">
      <Flex alignItems="center" gap="sm">
        <Switch
          checked={Boolean(showTooltip)}
          onCheckedChange={(checked) => {
            setShowTooltip(checked);
          }}
        />
        <Text level={3}>
          <Trans>Show Tooltip</Trans>
        </Text>
      </Flex>
    </VStack>
  );
};
