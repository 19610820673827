import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataNavigator } from "@components/DataNavigator";
import { DataTable } from "@components/DataTable";
import { ServerSideDataRender } from "@components/ServerSideDataRenderer/ServerSideDataRender";
import { useChatData } from "@context/chat";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { useChartConfig } from "@hooks/useChartConfig";
import { useInView } from "@hooks/useInView";
import { Trans } from "@lingui/react/macro";
import { useRetrieveAllData } from "@services/api/generated/retriever/endpoints/retrieve-all/retrieve-all";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";
import { getOrCreateTableStore } from "@stores/tableStore";
import { css } from "@styled-system/css";
import { Box, Flex, VStack } from "@styled-system/jsx";
import { LogoLoader, Text } from "doowii-ui";
import { useEffect, useRef } from "react";
import { useStore } from "zustand";

import { DynamicChartRenderer } from "../DynamicChartRenderer";

interface ClientSideDataProps {
  currentResult: Result | Pin;
  parentDocType: ParentDocTypeEnum;
  parentDocId: string;
  enableDataEditing?: boolean;
}

const ClientSideData = ({
  currentResult,
  parentDocType,
  parentDocId,
  enableDataEditing = true,
}: ClientSideDataProps) => {
  // Ref for the container element we want to observe
  const containerRef = useRef<HTMLDivElement>(null);

  // Check if the component is in view
  const isInView = useInView(containerRef, {
    // Start loading slightly before the element comes into view
    rootMargin: "300px",
    // Only trigger once - we don't want to reset when scrolling away
    triggerOnce: true,
  });

  // Get or create a table store for this component
  // Include sql in the tableId to ensure a new table store is created when SQL changes,
  // preventing stale data and ensuring proper rendering of updated query results
  const tableStore = getOrCreateTableStore({
    parentDocId,
    parentDocType,
    docId: currentResult.id,
    sql: currentResult.sql,
  });

  const { tableInstance } = useStore(tableStore);
  const { setAllResults } = useChatData();

  const setUpTableStore = useStore(tableStore, (state) => state.setUpTableStore);
  const getFilteredRows = useStore(tableStore, (state) => state.getFilteredRows);
  const chartConfig = useStore(tableStore, (state) => state.chartConfig);
  const updateChartConfig = useStore(tableStore, (state) => state.updateChartConfig);

  const selectedVisualization = chartConfig?.selected_visualization ?? chartConfig?.suggestion;

  // Use the retrieve-all hook to fetch data
  const { mutate, isPending, isError } = useRetrieveAllData({
    mutation: {
      onSuccess: (data) => {
        // When data is successfully retrieved, set it up in the table store
        if (!tableInstance && data) {
          setUpTableStore(data, currentResult.chartConfig);
          if (parentDocType === ParentDocTypeEnum.THREAD) {
            tableStore.setState({
              updateCurrentChatResults: setAllResults,
            });
          }
        }
      },
    },
  });

  // Only fetch data when the component is in view and data isn't already loaded
  useEffect(() => {
    // If already fetching or loaded, don't do anything
    if (tableInstance || isPending || isError) {
      return;
    }

    // Only fetch when the component comes into view
    if (isInView) {
      mutate({
        data: {
          doc_id: currentResult.id,
          parent_doc_type: parentDocType,
          parent_doc_id: parentDocId,
        },
      });
    }
  }, [
    isInView,
    isPending,
    isError,
    mutate,
    currentResult.id,
    parentDocType,
    parentDocId,
    tableInstance,
    currentResult.sql,
  ]);

  // The general response size limit is 35MB.
  // If the data is too large to handle, we fallback to the paginated data table.
  // If it errors out due to other issues, ServerSideDataRender will handle it appropriately.
  if (isError) {
    return (
      <ServerSideDataRender
        currentResult={currentResult}
        parentDocId={parentDocId}
        parentDocType={parentDocType}
      />
    );
  }

  // Return the container div with ref regardless of loading state
  // This ensures we can track when it comes into view
  return (
    <Flex
      className={css({
        minHeight: "200px", // Ensure there's enough height to detect
      })}
      direction="column"
      gap="0"
      height="100%"
      ref={containerRef}
      width="100%"
    >
      {!isInView || isPending || !tableInstance || !chartConfig ? (
        // Loading state
        <Box
          className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          })}
          data-testid="client-side-data-loading"
        >
          <LogoLoader size="md" variant="infinite" />
        </Box>
      ) : null}

      {/* Only render content when in view and data is loaded */}
      {isInView && tableInstance && !isPending ? (
        <>
          {/* No data state */}
          {!tableInstance ? (
            <Box
              bg="gray.50"
              borderColor="gray.300"
              borderRadius="md"
              borderWidth="1px"
              p="4"
              width="100%"
            >
              <Text color="gray.600">
                <Trans>No data available</Trans>
              </Text>
            </Box>
          ) : null}

          {/* DataNavigator Row */}
          {enableDataEditing ? (
            <Flex
              align="center"
              className={css({
                p: "3",
                pb: "0",
                width: "100%",
              })}
              direction="row"
              justify="space-between"
            >
              <DataNavigator
                currentResult={currentResult}
                parentDocId={parentDocId}
                parentDocType={parentDocType}
              />
            </Flex>
          ) : null}

          {/* Content once data is loaded */}
          <VStack
            className={css({
              width: "100%",
              overflowX: "auto",
              height: "100%",
            })}
            gap="0"
            height="100%"
          >
            {/* Render table or chart based on configuration */}
            {selectedVisualization === DataVisualizationType.TABLE ? (
              <DataTable
                allowColumnSelection={false}
                allowFiltering={enableDataEditing}
                chartConfig={chartConfig}
                tableInstance={tableInstance}
                updateConfigState={updateChartConfig}
              />
            ) : (
              <DynamicChartRenderer chartConfig={chartConfig} dataObject={getFilteredRows()} />
            )}
          </VStack>
        </>
      ) : null}
    </Flex>
  );
};

export { ClientSideData };
