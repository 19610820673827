/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Supported chart visualization types.

Available chart types for data visualization:
- BAR: For comparing categories
- LINE: For trends over time
- PIE: For part-to-whole relationships
- SCATTER: For correlations
- TABLE: For raw data display
 */
export enum DataVisualizationType {
  BAR = "BAR",
  LINE = "LINE",
  PIE = "PIE",
  SCATTER = "SCATTER",
  TABLE = "TABLE",
}
