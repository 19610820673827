import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "@lingui/react/macro";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  CircularIcon,
} from "doowii-ui";
import { useNavigation } from "react-router-dom";

interface DeleteAccountDialogProps {
  onConfirm: () => void;
}

const DeleteAccountDialog = ({ onConfirm }: DeleteAccountDialogProps) => {
  const navigation = useNavigation();

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button destructive>
          <Trans>Delete Account</Trans>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <CircularIcon css={{ marginBottom: "md" }} icon={faTrash} />
          <AlertDialogTitle>
            <Trans>Delete Account?</Trans>
          </AlertDialogTitle>
          <AlertDialogDescription>
            <Trans>Are you sure you want to delete your account?</Trans>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <Trans>Cancel</Trans>
          </AlertDialogCancel>
          <AlertDialogAction
            destructive
            disabled={navigation.state === "submitting"}
            onClick={onConfirm}
            type="submit"
          >
            <Trans>Delete</Trans>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DeleteAccountDialog };
