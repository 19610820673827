/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Bar chart transformation utilities
 */
import { CategoryType } from "@doowii-types/chart";
import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { Aggregations } from "@services/api/generated/webserver/models/aggregations";
import { ColumnDataType } from "@services/api/generated/webserver/models/columnDataType";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";
import { ChartConfig } from "@utils/chartTransformations";
import * as Highcharts from "highcharts";

import {
  aggregateValues,
  getColumnValue,
  getFirstRelevantSortColumn,
  getOrderedBreakdownValues,
  getOrderedCategoryValues,
  groupDataByColumn,
  MAX_CATEGORIES,
} from "./commonTransformations";

interface BarChartData {
  categories: string[];
  series: Highcharts.SeriesBarOptions[];
  [key: string]: unknown;
}

/**
 * Determines if the chart should be a grouped bar chart based on column_grouping
 * @param chartConfig Chart configuration
 * @returns True if the chart should be a grouped bar chart
 */
const isGroupedBarChart = (chartConfig: ChartConfig): boolean => {
  if (!chartConfig.column_grouping) {
    return false;
  }

  // Check if we have both category and breakdown roles in column_grouping
  const hasCategory = Object.values(chartConfig.column_grouping).includes(CategoryType.CATEGORY);
  const hasBreakdown = Object.values(chartConfig.column_grouping).includes(CategoryType.BREAKDOWN);

  return hasCategory && hasBreakdown;
};

/**
 * Transforms data for a regular bar chart (no breakdown/series from column_grouping)
 * @param data Raw data from API or test data
 * @param categoricalColumns Array of categorical column names
 * @param numericalColumns Array of numerical column names
 * @param chartConfig Chart configuration
 * @returns Transformed data for a regular bar chart
 */
const transformRegularBarChart = (
  data: RetrieveAllResponse,
  categoricalColumns: string[],
  numericalColumns: string[],
  chartConfig: ChartConfig
): BarChartData => {
  const { rows, column_names } = data;
  const columnNames = column_names.map((name) => name.toLowerCase());

  // Use the first categorical column as the category axis
  const categoryColumn =
    categoricalColumns.length > 0 ? categoricalColumns[0] : chartConfig.columns[0];

  // Check if we should honor multiple sort columns
  const relevantSortColumn = getFirstRelevantSortColumn(chartConfig, [
    ...categoricalColumns,
    ...numericalColumns,
  ]);

  // Get ordered categories based on sort configuration or frequency
  const { values: topCategories, otherCategories } = getOrderedCategoryValues(
    data,
    categoryColumn,
    columnNames,
    chartConfig,
    MAX_CATEGORIES
  );

  // Group data by category column
  const groupedData = groupDataByColumn(rows, categoryColumn, columnNames);

  // Separate the top categories and other categories
  const categories = [...topCategories];
  const hasOtherCategories = otherCategories.length > 0;

  if (hasOtherCategories) {
    categories.push("Other");
  }

  // Create a series for each numerical column
  const series: Highcharts.SeriesBarOptions[] = numericalColumns.map((numCol) => {
    const seriesValues = categories.map((cat) => {
      if (cat === "Other") {
        // Aggregate all values from categories in the "Other" group
        const values: number[] = [];
        otherCategories.forEach((otherCat) => {
          const categoryRows = groupedData[otherCat];
          if (!categoryRows) {
            return;
          }

          // Extract values for this category and numerical column
          categoryRows.forEach((row) => {
            const value = getColumnValue(row, numCol, columnNames);
            if (typeof value === "number") {
              values.push(value);
            }
          });
        });

        // Apply aggregation if specified
        const aggregation = chartConfig.column_aggregations?.[numCol];
        return aggregateValues(values, aggregation as Aggregations);
      } else {
        const categoryRows = groupedData[cat];
        if (!categoryRows) {
          return 0;
        }

        // Extract values for this category and numerical column
        const values = categoryRows.map((row) => {
          const value = getColumnValue(row, numCol, columnNames);
          return typeof value === "number" ? value : 0;
        });

        // Apply aggregation if specified
        const aggregation = chartConfig.column_aggregations?.[numCol];
        return aggregateValues(values, aggregation as Aggregations);
      }
    });

    return {
      name: numCol,
      data: seriesValues,
      type: DataVisualizationType.BAR.toLowerCase() as Highcharts.SeriesBarOptions["type"],
    };
  });

  // If no numerical columns, create a single series with the first column
  if (series.length === 0 && chartConfig.columns.length > 1) {
    const valueColumn = chartConfig.columns[1];
    const seriesValues = categories.map((cat) => {
      if (cat === "Other") {
        // Aggregate all values from categories in the "Other" group
        const values: number[] = [];
        otherCategories.forEach((otherCat) => {
          const categoryRows = groupedData[otherCat];
          if (!categoryRows) {
            return;
          }

          // Extract values for this category and numerical column
          categoryRows.forEach((row) => {
            const value = getColumnValue(row, valueColumn, columnNames);
            if (typeof value === "number") {
              values.push(value);
            }
          });
        });

        // Apply aggregation if specified
        const aggregation = chartConfig.column_aggregations?.[valueColumn];
        return aggregateValues(values, aggregation as Aggregations);
      } else {
        const categoryRows = groupedData[cat];
        if (!categoryRows) {
          return 0;
        }

        const values = categoryRows.map((row) => {
          const value = getColumnValue(row, valueColumn, columnNames);
          return typeof value === "number" ? value : 0;
        });
        const aggregation = chartConfig.column_aggregations?.[valueColumn];
        return aggregateValues(values, aggregation as Aggregations);
      }
    });

    series.push({
      name: valueColumn,
      data: seriesValues,
      type: DataVisualizationType.BAR.toLowerCase() as Highcharts.SeriesBarOptions["type"],
    });
  }

  return {
    categories,
    series,
  };
};

/**
 * Transforms data for a grouped bar chart (with breakdown/series from column_grouping)
 * @param data Raw data from API or test data
 * @param numericalColumns Array of numerical column names
 * @param chartConfig Chart configuration
 * @returns Transformed data for a grouped bar chart
 */
const transformGroupedBarChart = (
  data: RetrieveAllResponse,
  numericalColumns: string[],
  chartConfig: ChartConfig
): BarChartData => {
  const { rows, column_names } = data;
  const columnNames = column_names.map((name) => name.toLowerCase());

  if (!chartConfig.column_grouping) {
    throw new Error("column_grouping must be defined for grouped bar charts");
  }

  // Get the category and breakdown columns from column_grouping
  const categoryColumn = Object.entries(chartConfig.column_grouping).find(
    ([_, role]) => role === CategoryType.CATEGORY
  )?.[0];

  const breakdownColumn = Object.entries(chartConfig.column_grouping).find(
    ([_, role]) => role === CategoryType.BREAKDOWN
  )?.[0];

  if (!categoryColumn || !breakdownColumn) {
    throw new Error("Both category and breakdown columns must be specified in column_grouping");
  }

  // Get ordered categories based on sort configuration or frequency
  const { values: topCategoryValues, otherCategories } = getOrderedCategoryValues(
    data,
    categoryColumn,
    columnNames,
    chartConfig,
    MAX_CATEGORIES
  );

  // Group data by category column
  const groupedData = groupDataByColumn(rows, categoryColumn, columnNames);

  // Separate the top categories and other categories
  const categories = [...topCategoryValues];
  const hasOtherCategories = otherCategories.length > 0;

  if (hasOtherCategories) {
    categories.push("Other");
  }

  // Use ordered breakdown values based on sort configuration or frequency
  const topBreakdownValues = getOrderedBreakdownValues(
    rows,
    breakdownColumn,
    columnNames,
    chartConfig
  );

  // Create series for each numerical column and breakdown value combination
  const series: Highcharts.SeriesBarOptions[] = [];

  // For each numerical column
  numericalColumns.forEach((numCol) => {
    // First handle the top breakdown values
    topBreakdownValues.forEach((breakdownValue) => {
      // Create a series for this combination
      const seriesValues = categories.map((cat) => {
        if (cat === "Other") {
          // Aggregate all values from categories in the "Other" group
          const values: number[] = [];
          otherCategories.forEach((otherCat) => {
            // Find rows that match both other category and breakdown value
            const matchingRows =
              groupedData[otherCat]?.filter(
                (row) =>
                  String(getColumnValue(row, breakdownColumn, columnNames)) === breakdownValue
              ) || [];

            // Extract values for the matching rows
            matchingRows.forEach((row) => {
              const value = getColumnValue(row, numCol, columnNames);
              if (typeof value === "number") {
                values.push(value);
              }
            });
          });

          // Apply aggregation if specified
          const aggregation = chartConfig.column_aggregations?.[numCol];
          return values.length > 0 ? aggregateValues(values, aggregation as Aggregations) : null;
        } else {
          // Find rows that match both category and breakdown value
          const matchingRows =
            groupedData[cat]?.filter(
              (row) => String(getColumnValue(row, breakdownColumn, columnNames)) === breakdownValue
            ) || [];

          if (matchingRows.length === 0) {
            return null;
          }

          // Extract values for this category, breakdown value, and numerical column
          const values = matchingRows.map((row) => {
            const value = getColumnValue(row, numCol, columnNames);
            return typeof value === "number" ? value : 0;
          });

          // Apply aggregation if specified
          const aggregation = chartConfig.column_aggregations?.[numCol];
          return aggregateValues(values, aggregation as Aggregations);
        }
      });

      series.push({
        name: `${numCol} - ${breakdownValue}`,
        data: seriesValues,
        type: DataVisualizationType.BAR.toLowerCase() as Highcharts.SeriesBarOptions["type"],
      });
    });

    // Now handle the "Other" category for values not in the top N
    const seriesValues = categories.map((cat) => {
      if (cat === "Other") {
        // Aggregate all values from categories in the "Other" group with
        // breakdown values not in top N
        const values: number[] = [];
        otherCategories.forEach((otherCat) => {
          // Find rows that match this category but have breakdown values not in the top N
          const matchingRows =
            groupedData[otherCat]?.filter((row) => {
              const value = String(getColumnValue(row, breakdownColumn, columnNames));
              return !topBreakdownValues.includes(value);
            }) || [];

          // Extract values for the matching rows
          matchingRows.forEach((row) => {
            const value = getColumnValue(row, numCol, columnNames);
            if (typeof value === "number") {
              values.push(value);
            }
          });
        });

        // Apply aggregation if specified
        const aggregation = chartConfig.column_aggregations?.[numCol];
        return values.length > 0 ? aggregateValues(values, aggregation as Aggregations) : null;
      } else {
        // Find rows that match this category but have breakdown values not in the top N
        const matchingRows =
          groupedData[cat]?.filter((row) => {
            const value = String(getColumnValue(row, breakdownColumn, columnNames));
            return !topBreakdownValues.includes(value);
          }) || [];

        if (matchingRows.length === 0) {
          return null;
        }

        // Extract values for this category, breakdown value, and numerical column
        const values = matchingRows.map((row) => {
          const value = getColumnValue(row, numCol, columnNames);
          return typeof value === "number" ? value : 0;
        });

        // Apply aggregation if specified
        const aggregation = chartConfig.column_aggregations?.[numCol];
        return aggregateValues(values, aggregation as Aggregations);
      }
    });

    // Only add the "Other" series if it has any non-null values
    if (seriesValues.some((value) => value !== null)) {
      series.push({
        name: `${numCol} - Other`,
        data: seriesValues,
        type: DataVisualizationType.BAR.toLowerCase() as Highcharts.SeriesBarOptions["type"],
      });
    }
  });

  // If no series were created (no numerical columns or breakdown values),
  // create a default series
  if (series.length === 0 && chartConfig.columns.length > 1) {
    const valueColumn = chartConfig.columns[1];
    const seriesValues = categories.map((cat) => {
      const categoryRows = groupedData[cat];
      if (!categoryRows) {
        return 0;
      }

      const values = categoryRows.map((row) => {
        const value = getColumnValue(row, valueColumn, columnNames);
        return typeof value === "number" ? value : 0;
      });
      const aggregation = chartConfig.column_aggregations?.[valueColumn];
      return aggregateValues(values, aggregation as Aggregations);
    });

    series.push({
      name: valueColumn,
      data: seriesValues,
      type: DataVisualizationType.BAR.toLowerCase() as Highcharts.SeriesBarOptions["type"],
    });
  }

  return {
    categories,
    series,
  };
};

/**
 * Transforms raw data into a format suitable for bar charts
 * @param data Raw data from API or test data
 * @param chartConfig Chart configuration containing columns,
 * column_types, column_aggregations, and column_grouping
 * @returns Transformed data ready for chart rendering
 */
const transformForBarChart = (
  data: RetrieveAllResponse,
  chartConfig: ChartConfig
): BarChartData => {
  // Ensure data has the expected structure
  if (!data || !Array.isArray(data.rows) || !Array.isArray(data.column_names)) {
    console.error("Invalid data format for bar chart transformation", data);
    return { categories: [], series: [] };
  }
  const selectedColumns = chartConfig.columns;

  // Get all categorical and numerical columns
  const categoricalColumns = Object.entries(chartConfig.column_types || {})
    .filter(([_, type]) => type === ColumnDataType.categorical || type === ColumnDataType.temporal)
    .map(([col]) => col)
    .filter((col) => selectedColumns.includes(col));

  const numericalColumns = Object.entries(chartConfig.column_types || {})
    .filter(([_, type]) => type === ColumnDataType.numerical)
    .map(([col]) => col)
    .filter((col) => selectedColumns.includes(col));

  // Log warning if we don't have proper column types
  if (
    (categoricalColumns.length === 0 || numericalColumns.length === 0) &&
    chartConfig.columns.length >= 2
  ) {
    console.warn("Bar chart missing explicit column types. Using column positions as fallback.", {
      chartConfig,
    });
  }

  // Determine if this is a grouped bar chart
  if (isGroupedBarChart(chartConfig)) {
    return transformGroupedBarChart(data, numericalColumns, chartConfig);
  } else {
    return transformRegularBarChart(data, categoricalColumns, numericalColumns, chartConfig);
  }
};

export { transformForBarChart };
export type { BarChartData };
