import { PINBOARD_SHARING } from "@constants/features";
import { useOrgUsers } from "@context/users";
import { User } from "@context/users/users.i";
import { css } from "@doowii/design-system/css";
import { UserRoles } from "@doowii-types/auth";
import { faTrash, faUser } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { Flex } from "@styled-system/jsx";
import { Button, CircularIcon, ColumnDef, Heading, SimpleTable, ToggleGroup } from "doowii-ui";
import { useState } from "react";

import DeleteUserDialog from "./DeleteUserDialog";

enum UserFilter {
  ALL = "all",
  ADMINS = UserRoles.ADMIN,
  VIEWERS = UserRoles.VIEWER,
  INVITED = "invited",
}

interface Props {
  search: string;
}

const NoUsersFound = () => (
  <Flex
    css={{
      flexDirection: "column",
      gap: "md",
      alignItems: "center",
      justifyContent: "center",
      p: "xl2",
      h: 400,
    }}
  >
    <CircularIcon icon={faUser} />
    <Heading level={2}>
      <Trans>No users found</Trans>
    </Heading>
  </Flex>
);

const UsersTable: React.FC<Props> = ({ search }) => {
  const { t } = useLingui();
  const { organizationUsers } = useOrgUsers();
  const { featureFlags } = useAuth();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [filter, setFilter] = useState<UserFilter>(UserFilter.ALL);

  const handleFilterChange = (value: UserFilter) => {
    setFilter(value);
  };

  const filterCondition = (user: User) => {
    switch (filter) {
      case UserFilter.ALL:
        return true;

      case UserFilter.ADMINS:
        return user.role === UserRoles.ADMIN;

      case UserFilter.VIEWERS:
        return user.role === UserRoles.VIEWER;

      case UserFilter.INVITED:
        return user.registration.status === "pending";

      default:
        return true;
    }
  };

  const filteredUsers = organizationUsers.filter((user) => filterCondition(user));

  const defaultTabs = [
    { label: t`All Users`, value: UserFilter.ALL },
    { label: t`Invited`, value: UserFilter.INVITED },
  ];

  const pinboardSharingTabs = [
    { label: t`All Users`, value: UserFilter.ALL },
    { label: t`Admins`, value: UserFilter.ADMINS },
    { label: t`Viewers`, value: UserFilter.VIEWERS },
    { label: t`Invited`, value: UserFilter.INVITED },
  ];

  const columns: ColumnDef<User, unknown>[] = [
    {
      header: t`Name`,
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      id: "fullName",
      cell: (info) => info.getValue(),
      filterFn: "includesString",
    },
    {
      header: t`Email`,
      accessorKey: "email",
      filterFn: "includesString",
    },
    {
      header: t`Joined on`,
      accessorFn: (row) => row.registration.date,
    },
    {
      header: t`Role`,
      accessorKey: "role",
    },
    {
      header: t`Last logged in`,
      accessorKey: "LSO",
    },
    {
      id: "actions",
      header: t`Actions`,
      isAction: true,
      enableHiding: false,
      cell: ({ row }) => (
        <Flex css={{ gap: "xl" }}>
          <Button
            aria-label={t`Open delete user modal`}
            iconOnly={faTrash}
            onClick={() => {
              setSelectedUser(row.original);
              setDeleteModalOpen(true);
            }}
            variant="dropdown"
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex css={{ gap: "md", flexDirection: "column" }}>
        <ToggleGroup
          ariaLabel={t`Filter users`}
          onValueChange={handleFilterChange}
          options={featureFlags?.includes(PINBOARD_SHARING) ? pinboardSharingTabs : defaultTabs}
          value={filter}
        />
        <SimpleTable
          columns={columns}
          data={filteredUsers}
          emptyComponent={<NoUsersFound />}
          globalFilter={search}
        />
      </Flex>
      {deleteModalOpen ? (
        <DeleteUserDialog
          isOpen={deleteModalOpen}
          selectedUser={selectedUser}
          setIsOpen={() => setDeleteModalOpen(false)}
          setSelectedUser={setSelectedUser}
        />
      ) : null}
    </>
  );
};

export default UsersTable;
