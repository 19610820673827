/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";

import { retrieverAxios } from "../../../../axios-client";
import type { DownloadRetrieverInput } from "../../models/downloadRetrieverInput";
import type { GetSqlMetadataRequest } from "../../models/getSqlMetadataRequest";
import type { GetSqlMetadataResponse } from "../../models/getSqlMetadataResponse";
import type { GetSqlTotalRowsRequest } from "../../models/getSqlTotalRowsRequest";
import type { GetTotalRowsResponse } from "../../models/getTotalRowsResponse";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { RetrieverInput } from "../../models/retrieverInput";
import type { ValidateSQLInput } from "../../models/validateSQLInput";
import type { ValidateSQLOutput } from "../../models/validateSQLOutput";

/**
 * @summary Retrieve Data
 */
export const retrieveData = (retrieverInput: RetrieverInput, signal?: AbortSignal) =>
  retrieverAxios<unknown>({
    url: `/retrieve`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: retrieverInput,
    signal,
  });

export const getRetrieveDataMutationOptions = <
  TData = Awaited<ReturnType<typeof retrieveData>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: RetrieverInput }, TContext>;
}) => {
  const mutationKey = ["retrieveData"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof retrieveData>>,
    { data: RetrieverInput }
  > = (props) => {
    const { data } = props ?? {};

    return retrieveData(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: RetrieverInput },
    TContext
  >;
};

export type RetrieveDataMutationResult = NonNullable<Awaited<ReturnType<typeof retrieveData>>>;
export type RetrieveDataMutationBody = RetrieverInput;
export type RetrieveDataMutationError = HTTPValidationError;

/**
 * @summary Retrieve Data
 */
export const useRetrieveData = <
  TData = Awaited<ReturnType<typeof retrieveData>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: RetrieverInput }, TContext>;
}): UseMutationResult<TData, TError, { data: RetrieverInput }, TContext> => {
  const mutationOptions = getRetrieveDataMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Download Data
 */
export const downloadData = (
  downloadRetrieverInput: DownloadRetrieverInput,
  signal?: AbortSignal
) =>
  retrieverAxios<unknown>({
    url: `/retrieve/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: downloadRetrieverInput,
    signal,
  });

export const getDownloadDataMutationOptions = <
  TData = Awaited<ReturnType<typeof downloadData>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: DownloadRetrieverInput }, TContext>;
}) => {
  const mutationKey = ["downloadData"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downloadData>>,
    { data: DownloadRetrieverInput }
  > = (props) => {
    const { data } = props ?? {};

    return downloadData(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: DownloadRetrieverInput },
    TContext
  >;
};

export type DownloadDataMutationResult = NonNullable<Awaited<ReturnType<typeof downloadData>>>;
export type DownloadDataMutationBody = DownloadRetrieverInput;
export type DownloadDataMutationError = HTTPValidationError;

/**
 * @summary Download Data
 */
export const useDownloadData = <
  TData = Awaited<ReturnType<typeof downloadData>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: DownloadRetrieverInput }, TContext>;
}): UseMutationResult<TData, TError, { data: DownloadRetrieverInput }, TContext> => {
  const mutationOptions = getDownloadDataMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Validate Sql
 */
export const validateSql = (validateSQLInput: ValidateSQLInput, signal?: AbortSignal) =>
  retrieverAxios<ValidateSQLOutput>({
    url: `/retrieve/validate_sql`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: validateSQLInput,
    signal,
  });

export const getValidateSqlMutationOptions = <
  TData = Awaited<ReturnType<typeof validateSql>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: ValidateSQLInput }, TContext>;
}) => {
  const mutationKey = ["validateSql"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateSql>>,
    { data: ValidateSQLInput }
  > = (props) => {
    const { data } = props ?? {};

    return validateSql(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: ValidateSQLInput },
    TContext
  >;
};

export type ValidateSqlMutationResult = NonNullable<Awaited<ReturnType<typeof validateSql>>>;
export type ValidateSqlMutationBody = ValidateSQLInput;
export type ValidateSqlMutationError = HTTPValidationError;

/**
 * @summary Validate Sql
 */
export const useValidateSql = <
  TData = Awaited<ReturnType<typeof validateSql>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: ValidateSQLInput }, TContext>;
}): UseMutationResult<TData, TError, { data: ValidateSQLInput }, TContext> => {
  const mutationOptions = getValidateSqlMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Sql Metadata
 */
export const getSqlMetadata = (
  getSqlMetadataRequest: GetSqlMetadataRequest,
  signal?: AbortSignal
) =>
  retrieverAxios<GetSqlMetadataResponse>({
    url: `/retrieve/sql_metadata`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: getSqlMetadataRequest,
    signal,
  });

export const getGetSqlMetadataMutationOptions = <
  TData = Awaited<ReturnType<typeof getSqlMetadata>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: GetSqlMetadataRequest }, TContext>;
}) => {
  const mutationKey = ["getSqlMetadata"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getSqlMetadata>>,
    { data: GetSqlMetadataRequest }
  > = (props) => {
    const { data } = props ?? {};

    return getSqlMetadata(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: GetSqlMetadataRequest },
    TContext
  >;
};

export type GetSqlMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof getSqlMetadata>>>;
export type GetSqlMetadataMutationBody = GetSqlMetadataRequest;
export type GetSqlMetadataMutationError = HTTPValidationError;

/**
 * @summary Get Sql Metadata
 */
export const useGetSqlMetadata = <
  TData = Awaited<ReturnType<typeof getSqlMetadata>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: GetSqlMetadataRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: GetSqlMetadataRequest }, TContext> => {
  const mutationOptions = getGetSqlMetadataMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Sql Diagram
 */
export const getSqlDiagram = (signal?: AbortSignal) =>
  retrieverAxios<GetSqlMetadataResponse>({
    url: `/retrieve/sql_diagram`,
    method: "GET",
    signal,
  });

export const getGetSqlDiagramQueryKey = () => [`/retrieve/sql_diagram`] as const;

export const getGetSqlDiagramQueryOptions = <
  TData = Awaited<ReturnType<typeof getSqlDiagram>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSqlDiagram>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSqlDiagramQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSqlDiagram>>> = ({ signal }) =>
    getSqlDiagram(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSqlDiagram>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSqlDiagramQueryResult = NonNullable<Awaited<ReturnType<typeof getSqlDiagram>>>;
export type GetSqlDiagramQueryError = unknown;

export function useGetSqlDiagram<
  TData = Awaited<ReturnType<typeof getSqlDiagram>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSqlDiagram>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getSqlDiagram>>, TError, TData>,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSqlDiagram<
  TData = Awaited<ReturnType<typeof getSqlDiagram>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSqlDiagram>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSqlDiagram>>, TError, TData>,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSqlDiagram<
  TData = Awaited<ReturnType<typeof getSqlDiagram>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSqlDiagram>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Sql Diagram
 */

export function useGetSqlDiagram<
  TData = Awaited<ReturnType<typeof getSqlDiagram>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSqlDiagram>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSqlDiagramQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Sql Total Rows
 */
export const updateSqlTotalRows = (
  getSqlTotalRowsRequest: GetSqlTotalRowsRequest,
  signal?: AbortSignal
) =>
  retrieverAxios<GetTotalRowsResponse>({
    url: `/retrieve/sql_total_rows`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: getSqlTotalRowsRequest,
    signal,
  });

export const getUpdateSqlTotalRowsMutationOptions = <
  TData = Awaited<ReturnType<typeof updateSqlTotalRows>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: GetSqlTotalRowsRequest }, TContext>;
}) => {
  const mutationKey = ["updateSqlTotalRows"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSqlTotalRows>>,
    { data: GetSqlTotalRowsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateSqlTotalRows(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: GetSqlTotalRowsRequest },
    TContext
  >;
};

export type UpdateSqlTotalRowsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSqlTotalRows>>
>;
export type UpdateSqlTotalRowsMutationBody = GetSqlTotalRowsRequest;
export type UpdateSqlTotalRowsMutationError = HTTPValidationError;

/**
 * @summary Update Sql Total Rows
 */
export const useUpdateSqlTotalRows = <
  TData = Awaited<ReturnType<typeof updateSqlTotalRows>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: GetSqlTotalRowsRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: GetSqlTotalRowsRequest }, TContext> => {
  const mutationOptions = getUpdateSqlTotalRowsMutationOptions(options);

  return useMutation(mutationOptions);
};
