import React from "react";

// Base64 generic chart icon
const GENERIC_CHART_ICON =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEf0lEQVR4nO2bzW8NYRTGH0GJiPRDUoveSgQlImHTJQm18QcQCQvxUf+BLYmF0C5qg5WERJRGbWwEidiwwMLCRkWoaClttUoX7pFpT5Ob6Tszd3rn48w950neVeeeeeb85p33vB8FTCaTyWQySVQJwACAX9wGAXTkbUozjB8AyNd+8t9MGWvAAWOh3c3ajAlzn6ggIJOWoOw1HgJkIgc/6vU0BEi/+uxkrH0A/gXAGAPQlrUhzWoBMBwwbng9w2BkrH4fiDKAg1mbMM3rlKNn9PDfTBlrs6PUfQtgddZGTMBKAC99MP4A2GnJyUeXHZ+q7py8qNdeR4n7EMAy9ZnJQU0APvlgjABozcOMCXOLhJUwvJ7SZYnJR92OccMbS0w5lbhTPhivATRAuUq+Hbl7ANpTvmcDJ78Sxm8A26BcpYAdubGUobhK3NMp3q8uduTupHTPLkeJ6+2TmxC+I+f1ksaEs7QewBfffYZ5dVe9mgDMhgAh3rE7x3OFJPTAUeLuV08C84PnuwgYlc3rSRcBNNeQvDOOuJcMBnAYwHQMGJVtaolgtnMVVRnrlfYSdzlXN2VHoocAjHLFdZ/3H/xzBP/O3YUqwawC8Mb3+2nth91aADwKSO71gDe1hcePiYge0xex7tTr+N3JgGupxlYI7QLwwWH+b0hiXGDGqwCzwffbA44S1+uB0ArkGIAZh/HPADpjxmrmz9RkBJgeTrprwhlV4tYtkBU8+LpMP3e8yXG0DsDZiB7jatWUuHUJpBXAswDDfbxVmoS8ucn5iDGmsnmH3qIUN8HigXTy58hvdIY/X2moqYoxZuHEuiogJ3igJl/7CGB3BvdvjJjfTGgB4pWs1wKS8JjXj7LSYI1ncQsJZBOvxI5yG3IYK/Mk0JsMZqn2kOX8tnoEshHAt4hv9TQvj+Sldt4nn1zCWdzCAbkVAeN9wQ+WFQbIWj7zGra+NJvgMnleEg/EW/a4GjE7jlPFSJdIIGsAHAfwogoIcasY6RIFZAeAK0tYkohTxUhX7kC8Y/ZHeX0pKunfeeFuDw/uI3X4H0WUBZDKc09eEm9yUnv5zQ6DUOY1oCO80VPvorSBBJ17imrfeGK3FbpEaQMJO/fk6g1PeFKXVm+gGlvhgYSde1poo3z6YgvSFxmQ4CR41dShjE9ekHYgYaufN5C9SDuQDt6YcX2m8ihVKe0HLoK/UsXq51cAt3nVNg+RAZElMiCyRAZElsiAyBIZkPoGQgmX0dJfmMRFBkSWyIDIEhkQXUCiZGNIwRIoflCXXsWQ8HiJy4CEy4D4ZD3EPlmyekit15OyeIlL+gOT8HiJS/oDk/B4iUv6A5PweIsuiNuK9sAkPJ4BiZABKdgbbT1EGxBpBklZPPEGSVk88QZJWTzxBklZPPEGSVk88QZJWTzxBklZPPEGSVk88QZJWTzxBklZPPEGSVk88QZJWTzxBklZPPEGSVk88QZJWTzxBklZPPEGSVk88QZJWTzxBklZPPEGSVk88QZJWTzxBklZPPEGSVm8RRfEbRYPiebPgEDWC2hAYEDsEwhfD/kPC3d1fgj2RhMAAAAASUVORK5CYII=";

/**
 * Generic chart icon component that renders a chart image
 * Uses a base64 encoded PNG for the icon
 */
const GenericChartIcon = () => (
  <img
    alt="Chart icon"
    height="20"
    src={GENERIC_CHART_ICON}
    style={{ filter: "var(--doowii-icon-filter, none)" }}
    width="20"
  />
);

export { GenericChartIcon };
