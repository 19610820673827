import { usePinboard } from "@context/pinboard";
import { PinboardsView } from "@pages/Pinboards";
import { Flex, Grid } from "@styled-system/jsx";

import { PinboardCard } from "../PinboardCard";

interface Props {
  view: PinboardsView;
  searchQuery: string;
}
const PinboardCardContainer: React.FC<Props> = ({ view, searchQuery }) => {
  const { boards } = usePinboard();

  const isGrid = view === "grid";

  return (
    <Flex flex={1} justifyContent="center">
      <Grid
        gap="ld"
        gridAutoRows="max-content"
        gridTemplateColumns={isGrid ? [1, 1, 1, 2, 3, 3] : 1}
        w="full"
      >
        {Array.from(boards)
          .filter(([_, board]) => board.name.toLowerCase().includes(searchQuery.toLowerCase()))
          .map(([key, board]) => (
            <PinboardCard board={board} key={key} view={view} />
          ))}
      </Grid>
    </Flex>
  );
};

export default PinboardCardContainer;
