import { AuthUser } from "@doowii-types/auth";
import { deleteUser, getAuth, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { StateCreator } from "zustand";

import { OrganizationDoc } from "@doowii-types/firestore_docs";

import { fetchFeatureFlags } from "@services/webserver/featureFlags";
import {
  createNewUserDocument,
  db,
  updateDocumentInFirestore,
  updateLastSignIn,
} from "../services/firebase";
import { UserDocument } from "../types/user";

interface AuthState {
  authUser: AuthUser | null;
  userDocument: UserDocument | null;
  isLoading: boolean;
  organization: string | null;
  organizationDoc: OrganizationDoc | null;
  featureFlags: string[];
}

interface AuthSliceInterface extends AuthState {
  setAuthUser: (authUser: AuthUser | null) => void;
  createNewUser: (userDetails: Partial<UserDocument>) => Promise<UserDocument>;
  updateUserDocument: (userDetails: Partial<UserDocument>) => Promise<void>;
  deleteAuthUser: () => Promise<void>;
  signOut: () => Promise<void>;
  fetchUserDocument: (userId: string, org?: string) => Promise<UserDocument | null>;
  fetchOrganizationId: (userId: string) => Promise<string | null>;
  fetchOrganizationDoc: (orgId: string) => Promise<OrganizationDoc | null>;
  setAuthState: (state: Partial<AuthState>) => void;
  fetchAndInitializeAuthState: (userId: string) => Promise<void>;
}

const createAuthSlice: StateCreator<AuthSliceInterface> = (set, get) => ({
  authUser: null,
  userDocument: null,
  isLoading: true,
  organization: "",
  organizationDoc: null,
  featureFlags: [],
  setAuthUser: (authUser: AuthUser | null) => set({ authUser }),
  createNewUser: async (userDetails: Partial<UserDocument>) => {
    const newUserDoc = await createNewUserDocument(
      userDetails.firstName,
      userDetails.lastName,
      userDetails.designation,
      userDetails.otherDesignation,
      userDetails.marketingOptIn,
      get().authUser
    );
    await get().fetchAndInitializeAuthState(newUserDoc.id);
    return newUserDoc;
  },
  updateUserDocument: async (userDetails: Partial<UserDocument>) => {
    const { organization, authUser } = get();
    if (!organization || !authUser) {
      return;
    }
    await updateDocumentInFirestore(
      `organizations/${organization}/users`,
      authUser.uid,
      userDetails
    ).catch((error) => {
      console.error("Error updating user document:", error);
    });
    set({ userDocument: { ...get().userDocument, ...userDetails } as UserDocument });
  },
  deleteAuthUser: async () => {
    await deleteUser(getAuth().currentUser);
    await signOut(getAuth());
    set({ authUser: null, userDocument: null, isLoading: false });
  },
  signOut: async () => {
    await signOut(getAuth());
    set({ authUser: null, userDocument: null, isLoading: false });
  },
  fetchOrganizationId: async (userId: string) => {
    const userOrgsDocRef = doc(db, "user_orgs", userId);
    const userOrgsDocSnapshot = await getDoc(userOrgsDocRef);
    if (!userOrgsDocSnapshot.exists()) {
      console.error("User organization document does not exist.");
      return null;
    }
    return userOrgsDocSnapshot.data().organization;
  },
  fetchOrganizationDoc: async (orgId: string): Promise<OrganizationDoc | null> => {
    const orgsDocRef = doc(db, "organizations", orgId);
    const orgsDocSnapshot = await getDoc(orgsDocRef);
    if (!orgsDocSnapshot.exists()) {
      console.warn("No Organization document found.");
      return null;
    }

    return orgsDocSnapshot.data() as OrganizationDoc;
  },
  fetchUserDocument: async (userId, orgId) => {
    try {
      const userDocSnapshot = await getDoc(doc(db, "organizations", orgId, "users", userId));
      if (!userDocSnapshot.exists()) {
        console.error("User document does not exist in the specified organization.");
        return null;
      }
      return userDocSnapshot.data() as UserDocument;
    } catch (error) {
      console.error("Error fetching user document:", error);
      return null;
    }
  },
  fetchAndInitializeAuthState: async (userId: string) => {
    const orgId = await get().fetchOrganizationId(userId);
    if (!orgId) {
      return;
    }

    const userDocument = await get().fetchUserDocument(userId, orgId);
    if (!userDocument) {
      return;
    }

    const orgDoc = await get().fetchOrganizationDoc(orgId);
    if (!orgDoc) {
      throw new Error("Organization document not found");
    }

    set({ userDocument, organization: orgDoc.name, organizationDoc: orgDoc });

    fetchFeatureFlags().then((flags) => {
      set({ featureFlags: flags });
    });

    await updateLastSignIn(orgId, userId);
  },
  setAuthState: (state) => set(state),
});

export { createAuthSlice, type AuthSliceInterface, type AuthState };
