import { ColumnDataType } from "@services/api/generated/webserver/models/columnDataType";
import { Column } from "@tanstack/react-table";
import { useMemo } from "react";

import { MultiSelectFilter } from "./MultiSelectFilter";
import { NumericRangeFilter } from "./NumericRangeFilter";

interface ColumnFilterWrapperProps {
  column: Column<unknown[]>;
}

/**
 * ColumnFilterWrapper component handles different types of filters based on column data type
 * Delegates to specific filter components based on data type
 */
export const ColumnFilterWrapper = ({ column }: ColumnFilterWrapperProps) => {
  const columnType = column.columnDef.meta?.columnType;
  const allUniqueValues = column.getFacetedUniqueValues();

  // Get unique values for type detection
  const sortedUniqueValues = useMemo(
    () => (allUniqueValues ? Array.from(allUniqueValues.keys()).sort().slice(0, 100) : []),
    [allUniqueValues]
  );

  // Determine if we should use a numeric filter based on column type
  const isNumber = useMemo(() => {
    if (columnType === ColumnDataType.numerical) {
      return true;
    }

    // Fallback to checking values if no explicit type is provided
    if (!columnType) {
      return (
        sortedUniqueValues.some(
          (val) => val !== null && val !== undefined && !isNaN(Number(val))
        ) && sortedUniqueValues.length > 0
      );
    }

    return false;
  }, [columnType, sortedUniqueValues]);

  // For numeric columns, use range filter
  if (isNumber) {
    return <NumericRangeFilter column={column} sortedUniqueValues={sortedUniqueValues} />;
  }

  // For all categorical columns, use multi-select
  return <MultiSelectFilter column={column} />;
};
