/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Accepted file extensions for document uploads.
 */
export enum AcceptedFileExtension {
  PDF = "PDF",
  DOC = "DOC",
  DOCX = "DOCX",
  TXT = "TXT",
  MD = "MD",
  YAML = "YAML",
  RTF = "RTF",
}
