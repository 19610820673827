import { usePinboard } from "@context/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { Trans, useLingui } from "@lingui/react/macro";
import { useCreateWidget } from "@services/api/generated/webserver/endpoints/widgets/widgets";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Text,
  Textarea,
  useToast,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  triggerId: string;
  pinboardId: string;
}

const AddWidgetDialog: React.FC<Props> = ({ isOpen, setIsOpen, triggerId, pinboardId }) => {
  useModalClose(isOpen, triggerId);
  const { t } = useLingui();
  const { toast } = useToast();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const { fetchWidgetsForBoard, currBoardId } = usePinboard();

  const { mutate: createWidget, isPending: isCreating } = useCreateWidget({
    mutation: {
      onSuccess: () => {
        toast({
          status: "success",
          title: t`Widget created successfully`,
        });
        setIsOpen(false);
        setTitle("");
        setDescription("");
        setTitleErrorMessage("");
        fetchWidgetsForBoard(currBoardId);
      },
      onError: (error) => {
        console.error("Error creating widget:", error);
        toast({
          status: "error",
          title: t`Failed to create widget`,
          description:
            typeof error.detail === "string" ? error.detail : t`An unexpected error occurred`,
        });
      },
    },
  });

  const handleAddWidget = async () => {
    if (title.trim().length < 1) {
      setTitleErrorMessage(t`Widget title cannot be empty`);
      return;
    }

    try {
      createWidget({
        pinboardId,
        data: {
          title,
          description: description || undefined,
        },
      });
    } catch (error) {
      console.error("Error adding widget:", error);
    }
  };

  const characterLimit = 500;
  const totalCharacters = description.length;

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent
        aria-describedby={t`Add text widget to pinboard dialog.`}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader>
          <DialogTitle>
            <Trans>Add text widget</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <InputField
            disabled={isCreating}
            errorText={titleErrorMessage}
            label={t`Widget title`}
            onChange={(e) => {
              setTitle(e.target.value);
              if (e.target.value.trim().length > 0) {
                setTitleErrorMessage("");
              }
            }}
            placeholder={t`Enter widget title`}
            required
            type="text"
            value={title}
          />
          <Flex direction="column">
            <Flex direction="column" gap="xs">
              <Textarea
                disabled={isCreating}
                label={t`Widget description (optional)`}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= characterLimit) {
                    setDescription(newValue);
                  }
                }}
                placeholder={t`Enter widget description...`}
                rows={3}
                value={description}
              />
            </Flex>
            <Text
              className={css({
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "sm",
                color: "gray.500",
                mt: "xs",
              })}
            >
              {totalCharacters} / {characterLimit}
            </Text>
          </Flex>
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button
            disabled={title.length === 0 || isCreating}
            loading={isCreating}
            onClick={stopPropagationWrapper(handleAddWidget)}
            size="large"
            variant="primary"
          >
            <Trans>Add</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { AddWidgetDialog };
