// src/hooks/useInactivityLogout.ts
import { signOutUser } from "@services/firebase/auth";
import { useToast } from "doowii-ui";
import { useEffect } from "react";

import { useAuth } from "./useAuth";

// 12 hours in milliseconds (12 * 60 * 60 * 1000)
const INACTIVITY_LIMIT = 12 * 60 * 60 * 1000;

// Throttle utility to limit how frequently a function is executed
const throttle = (func: (...args: any[]) => void, delay: number) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = Date.now();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    func(...args);
  };
};

export const useInactivityLogout = () => {
  const { toast } = useToast();
  const { authUser } = useAuth();
  useEffect(() => {
    if (!authUser) {
      return;
    }
    let timer: ReturnType<typeof setTimeout>;

    /*
     * Reset timer on user activity. For "visibilitychange",
     * only reset when the document becomes visible.
     * */
    const resetTimer = (event?: Event) => {
      if (event && event.type === "visibilitychange" && document.visibilityState !== "visible") {
        return;
      }

      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        console.info("User inactive for 12 hours. Signing out...");
        toast({
          title: "You have been signed out due to inactivity.",
          status: "info",
        });
        await signOutUser();
      }, INACTIVITY_LIMIT);
    };

    // Wrap the resetTimer function in a throttle (once per 10 seconds) to avoid performance issues.
    const throttledResetTimer = throttle(resetTimer, 10000);

    // List of events considered as user activity
    const activityEvents: (keyof WindowEventMap)[] = ["click", "focus", "keydown", "touchstart"];

    // Attach event listeners for all activity events
    activityEvents.forEach((event) => window.addEventListener(event, resetTimer));

    // Initialize the timer immediately on mount
    throttledResetTimer();

    // Cleanup event listeners and timer on unmount
    return () => {
      activityEvents.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [authUser, toast]);
};
