import { ChartSelector } from "@components/ChartSelector";
import { ChartColumnSelector } from "@components/ChartSelector/ChartColumnSelector";
import { DynamicChartRenderer } from "@components/DynamicChartRenderer";
import { Trans } from "@lingui/react/macro";
import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";
import { css } from "@styled-system/css";
import { Box, VStack } from "@styled-system/jsx";
import { ChartConfig } from "@utils/chartTransformations";

interface PreviewPanelProps {
  chartConfig: ChartConfig | null;
  dataObject: RetrieveAllResponse | null;
  onChartTypeChange: (newConfig: ChartConfig) => void;
}

const NoColumnsSelectedMessage = () => (
  <Box
    className={css({
      display: "flex",
      flexDirection: "column",
      marginTop: "xl6",
    })}
  >
    <Box
      className={css({
        fontSize: "title_h2.fontSize",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "md",
      })}
    >
      <Trans>Select Columns</Trans>
    </Box>
    <Box
      className={css({
        fontSize: "paragraph_16.fontSize",
        textAlign: "center",
      })}
    >
      <Trans>Select at least two columns from the data panel</Trans>
      <br />
      <Trans>to generate a visualization.</Trans>
    </Box>
  </Box>
);

const SelectChartTypeMessage = () => (
  <Box
    className={css({
      display: "flex",
      flexDirection: "column",
      marginTop: "xl6",
    })}
  >
    <Box
      className={css({
        fontSize: "title_h2.fontSize",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "md",
      })}
    >
      <Trans>Select Chart Type</Trans>
    </Box>
    <Box
      className={css({
        fontSize: "paragraph_16.fontSize",
        textAlign: "center",
      })}
    >
      <Trans>Select a chart type from the options above</Trans>
      <br />
      <Trans>to visualize your data.</Trans>
    </Box>
  </Box>
);

const PreviewPanel = ({ chartConfig, dataObject, onChartTypeChange }: PreviewPanelProps) => {
  // Determine which content to display based on chart configuration
  const renderContent = () => {
    if (!chartConfig?.columns || chartConfig.columns.length <= 1) {
      return <NoColumnsSelectedMessage />;
    }

    if (chartConfig.suggestion === DataVisualizationType.TABLE) {
      return <SelectChartTypeMessage />;
    }

    return <DynamicChartRenderer chartConfig={chartConfig} dataObject={dataObject} />;
  };

  return (
    <VStack data-testid="preview-panel" height="100%" width="100%">
      {chartConfig ? (
        <VStack width="100%">
          <ChartSelector chartConfig={chartConfig} onChange={onChartTypeChange} />
          <ChartColumnSelector chartConfig={chartConfig} onChange={onChartTypeChange} />
        </VStack>
      ) : null}
      {renderContent()}
    </VStack>
  );
};

export { PreviewPanel };
