import { t } from "@lingui/core/macro";
import { sendPwdResetEmailWithFirebase } from "@services/firebase";
import { ActionFunctionArgs, json } from "react-router-dom";

interface ResetPasswordActionResponse {
  errors?: { email?: string };
  fields?: { email?: string | null };
  success?: boolean;
}

const getResetPasswordSuccessMessage = () =>
  t`If the email is registered, you will receive a reset link.`;

const resetPasswordAction = async ({
  request,
}: ActionFunctionArgs): Promise<ResetPasswordActionResponse | Response> => {
  const formData = await request.formData();
  const email = formData.get("email") as string;

  const errors: { email?: string } = {};
  if (!email) {
    errors.email = t`Email is required`;
  }

  if (Object.keys(errors).length > 0) {
    return json({ errors, fields: { email } }, { status: 400 });
  }

  try {
    await sendPwdResetEmailWithFirebase(email);
    return json({
      fields: { email: getResetPasswordSuccessMessage() },
      success: true,
    });
  } catch (error) {
    // Always return the same message to prevent user enumeration
    return json({
      fields: { email: getResetPasswordSuccessMessage() },
      success: true,
    });
  }
};

export { resetPasswordAction, type ResetPasswordActionResponse };
