import { PinboardResultCard } from "@components/PinboardResultCard";
import { WidgetCard } from "@components/WidgetCard";
import { usePinboard } from "@context/pinboard";
import { PinboardAccessLevel } from "@doowii-types/auth";
import { Trans } from "@lingui/react/macro";
import { updatePinboard } from "@services/api/generated/webserver/endpoints/pinboards/pinboards";
import { Box, Flex } from "@styled-system/jsx";
import debounce from "lodash/debounce";
import React, { ComponentClass, useEffect, useState } from "react";
import {
  Layout,
  Responsive,
  ResponsiveProps,
  WidthProvider,
  WidthProviderProps,
} from "react-grid-layout";

import { getGridItemStyle, gridLayoutStyle, widgetContainerStyle } from "./PinboardLayout.styles";

const ResponsiveGridLayout: ComponentClass<ResponsiveProps & WidthProviderProps> =
  WidthProvider(Responsive);

interface PinboardLayoutProps {
  className?: string;
  rowHeight?: number;
  cols?: Record<string, number>;
  layouts: Record<string, Layout[]>;
  setLayouts: (layouts: Record<string, Layout[]>) => void;
  accessLevel: PinboardAccessLevel;
}

const DEFAULT_COLS = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };
const DEFAULT_ROW_HEIGHT = 30; // 30px
const DEFAULT_CLASS_NAME = "layout";

const PinboardLayout: React.FC<PinboardLayoutProps> = ({
  className = DEFAULT_CLASS_NAME,
  rowHeight = DEFAULT_ROW_HEIGHT,
  cols = DEFAULT_COLS,
  layouts,
  setLayouts,
  accessLevel,
}) => {
  const { currBoardId, pinboardResults, widgets } = usePinboard();
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [showPinboard, setShowPinboard] = useState(false);
  const debouncedUpdatePinboard = debounce(updatePinboard, 1000);

  const gridItemStyle = getGridItemStyle(accessLevel);

  useEffect(() => {
    setTimeout(() => {
      setShowPinboard(true);
    }, 300);
  }, []);

  const handleBreakpointChange = (breakpoint: string) => {
    setCurrentBreakpoint(breakpoint);
  };

  const handleLayoutChange = (newLayout: Layout[], allLayouts: Record<string, Layout[]>) => {
    setLayouts(allLayouts);
    if (currBoardId) {
      debouncedUpdatePinboard(currBoardId, { pin_layouts: allLayouts });
    }
  };

  return (
    <ResponsiveGridLayout
      autoSize={true}
      className={`${className} ${gridLayoutStyle}`}
      cols={cols}
      compactType="vertical"
      draggableHandle=".drag-handle"
      layouts={layouts}
      margin={[10, 10]}
      measureBeforeMount={false}
      onBreakpointChange={handleBreakpointChange}
      onLayoutChange={handleLayoutChange}
      rowHeight={rowHeight}
      style={{ opacity: showPinboard ? 1 : 0 }}
      useCSSTransforms={true}
    >
      {layouts[currentBreakpoint]?.map((layout) => {
        const pin = pinboardResults.find((p) => p.id === layout.i);
        const widget = widgets.find((w) => w.id === layout.i);

        return (
          <div className={gridItemStyle} key={layout.i}>
            {pin ? (
              <PinboardResultCard accessLevel={accessLevel} pin={pin} />
            ) : widget ? (
              <Box className={widgetContainerStyle}>
                <WidgetCard accessLevel={accessLevel} widget={widget} />
              </Box>
            ) : (
              <div>Item not found</div>
            )}
          </div>
        );
      })}
    </ResponsiveGridLayout>
  );
};

export { PinboardLayout };
