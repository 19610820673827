import { HIGHER_ED } from "@constants/constants";
import { faEllipsisVertical, faPen, faShare, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
} from "doowii-ui";
import { useId, useRef, useState } from "react";

import { PinboardDoc } from "@services/api/generated/webserver/models/pinboardDoc";
import { env } from "../../../env";
import { DeletePinboardDialog } from "./DeletePinboardDialog";
import { SharePinboardDialog } from "./SharePinboardDialog";
import { EditPinboardDetailsDialog } from "./EditPinboardDetailsDialog";

interface Props {
  board: PinboardDoc;
}
const PinboardCardDropdown: React.FC<Props> = ({ board }) => {
  const id = useId();
  const { t } = useLingui();
  const [openEditNameDialog, setOpenEditNameDialog] = useState(false);
  const [openDeleteNameDialog, setOpenDeleteNameDialog] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const { featureFlags } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>();

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: handle image upload
    console.log(e.target.files);
  };

  const triggerId = `${id}-trigger`;

  return (
    <>
      <DropdownMenu modal={false}>
        <Tooltip content={t`See pinboard options`}>
          <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
            <Button
              aria-label={t`See pinboard options`}
              iconOnly={faEllipsisVertical}
              id={triggerId}
              variant="dropdown"
            />
          </DropdownMenuTrigger>
        </Tooltip>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={stopPropagationWrapper(() => setOpenEditNameDialog(true))}>
              <FontAwesomeIcon icon={faPen} />
              <Trans>Edit</Trans>
            </DropdownMenuItem>
            {env.REACT_APP_ENV !== HIGHER_ED ? (
              <DropdownMenuItem onClick={stopPropagationWrapper(() => setOpenShareDialog(true))}>
                <FontAwesomeIcon icon={faShare} />
                <Trans>Share</Trans>
              </DropdownMenuItem>
            ) : null}

            {/* TODO: Replace with img check */}
            {/* {board.name ? (
              <DropdownMenuItem onClick={() => fileInputRef.current?.click()}>
                <FontAwesomeIcon icon={faImage} />
                <Trans>Change thumbnail</Trans>
                <input
                  hidden
                  multiple={false}
                  onChange={handleAddImage}
                  ref={fileInputRef as React.MutableRefObject<HTMLInputElement>}
                  type="file"
                />
              </DropdownMenuItem>
            ) : null} */}
            <DropdownMenuItem onClick={stopPropagationWrapper(() => setOpenDeleteNameDialog(true))}>
              <FontAwesomeIcon icon={faTrash} />
              <Trans>Delete</Trans>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <EditPinboardDetailsDialog
        boardId={board.id}
        boardName={board.name}
        boardDescription={board.description}
        isOpen={openEditNameDialog}
        setIsOpen={setOpenEditNameDialog}
        triggerId={triggerId}
      />

      <DeletePinboardDialog
        boardId={board.id}
        isOpen={openDeleteNameDialog}
        setIsOpen={setOpenDeleteNameDialog}
        triggerId={triggerId}
      />

      <SharePinboardDialog
        boardId={board.id}
        boardName={board.name}
        isOpen={openShareDialog}
        setIsOpen={setOpenShareDialog}
        triggerId={triggerId}
      />
    </>
  );
};

export default PinboardCardDropdown;
