import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import React, { forwardRef } from "react";

interface ChatAreaProps {
  children: React.ReactNode;
}

const ApolloChatArea = forwardRef<HTMLDivElement, ChatAreaProps>(({ children }, ref) => (
  <Box
    className={css({
      bg: "background.base",
      border: "1px solid",
      borderColor: "lightGray",
      height: "100%",
      overflowY: "auto",
      p: "md",
      borderRadius: "md",
      width: "100%",
    })}
    ref={ref}
  >
    <Flex direction="column" gap="sm">
      {children}
    </Flex>
  </Box>
));

// eslint-disable-next-line lingui/no-unlocalized-strings
ApolloChatArea.displayName = "ApolloChatArea";

export { ApolloChatArea };
