import { faCommentLines } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "@lingui/react/macro";
import { Button } from "doowii-ui";
import { useState } from "react";

import { ContactMeDialog } from "./ContactMeDialog";

interface ContactMeBtnProps {
  size?: "small" | "medium" | "large";
}

const ContactMeBtn = ({ size = "medium" }: ContactMeBtnProps) => {
  const [openContactMeDialog, setOpenContactMeDialog] = useState(false);
  return (
    <>
      <Button
        iconLeft={faCommentLines}
        onClick={() => setOpenContactMeDialog(true)}
        size={size}
        variant="secondary"
      >
        <Trans>Contact</Trans>
      </Button>
      <ContactMeDialog
        isOpen={openContactMeDialog}
        setIsOpen={setOpenContactMeDialog}
        triggerId="contact-me-btn"
      />
    </>
  );
};

export { ContactMeBtn };
