import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/react/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { Card, Heading, Separator, Text } from "doowii-ui";
import { useState } from "react";

const DataProviderCard = () => {
  const [open, setOpen] = useState(false);
  return (
    <Card>
      <Collapsible.Root className={css({ w: "100%" })} onOpenChange={setOpen} open={open}>
        <Collapsible.Trigger className={css({ w: "100%", cursor: "pointer" })}>
          <Flex align="center" justify="space-between">
            <Heading level={3}>
              <Trans>The dataset is provided by the Urban Institute</Trans>
            </Heading>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="lg" />
          </Flex>
        </Collapsible.Trigger>

        <Collapsible.Content>
          <Separator className={css({ my: "4" })} colorful={false} />
          <Flex align="center" direction="column" gap="4">
            <Box>
              <Text>
                <Trans>
                  Urban Institute is a trusted source of data on education in the United States.
                  They compile and maintain detailed records from a wide range of educational
                  institutions, providing insights into critical aspects of the education system.
                </Trans>
              </Text>
            </Box>
            <Separator colorful={false} />

            <Flex align="center" justify="space-between" w="100%">
              <a
                href="https://educationdata.urban.org/documentation/index.html#about_the_api"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Heading level={3}>
                  <Trans>Link to the Urban Institute project</Trans>
                </Heading>
              </a>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Flex>
          </Flex>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  );
};

export { DataProviderCard };
