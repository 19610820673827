import { useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Column } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { InputActionMeta } from "react-select";
import ReactSelect from "react-select";

// Maximum number of options to show at once to prevent performance issues
const OPTIONS_LIMIT = 25;

interface MultiSelectFilterProps {
  column: Column<unknown[]>;
}

/**
 * MultiSelectFilter component handles multi-select filtering for categorical columns
 * Contains all logic for option selection and formatting
 * Implements dynamic filtering for large datasets
 */
export const MultiSelectFilter = ({ column }: MultiSelectFilterProps) => {
  const { t } = useLingui();
  const columnFilterValue = column.getFilterValue();
  const allUniqueValues = column.getFacetedUniqueValues();

  // Get all unique values for options
  const allOptions = useMemo(() => {
    if (!allUniqueValues) {
      return [];
    }

    return Array.from(allUniqueValues.keys())
      .filter((value) => value !== null && value !== undefined)
      .sort()
      .map((value) => ({
        value,
        label: String(value),
      }));
  }, [allUniqueValues]);

  // Keep track of filtered options
  const [filteredOptions, setFilteredOptions] = useState<Array<{ value: unknown; label: string }>>(
    []
  );

  // Update filtered options when all options change
  useEffect(() => {
    setFilteredOptions(allOptions.slice(0, OPTIONS_LIMIT));
  }, [allOptions]);

  // Convert the selected values to the format required by React Select
  const selectedOptions = useMemo(() => {
    if (!columnFilterValue) {
      return [];
    }
    return Array.isArray(columnFilterValue)
      ? columnFilterValue.map((value) => ({ value, label: String(value) }))
      : [{ value: columnFilterValue, label: String(columnFilterValue) }];
  }, [columnFilterValue]);

  // Handle input changes to filter options
  const handleInputChange = useCallback(
    (newValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action !== "input-change") {
        return;
      }

      if (!newValue) {
        // Reset to initial slice if search is cleared
        setFilteredOptions(allOptions.slice(0, OPTIONS_LIMIT));
        return;
      }

      // Filter options based on input
      const matchingOptions = allOptions
        .filter((option) => String(option.label).toLowerCase().includes(newValue.toLowerCase()))
        .slice(0, OPTIONS_LIMIT);

      setFilteredOptions(matchingOptions);
    },
    [allOptions]
  );

  // Determine placeholder text based on dataset size
  const optionsCountText = allOptions.length.toLocaleString();
  const placeholderText = t`Search ${optionsCountText} items`;

  return (
    <Box
      className={css({
        width: "100%",
        marginTop: "xs",
      })}
    >
      <ReactSelect
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        data-testid={`multi-select-filter-${column.id}`}
        isClearable
        isMulti
        noOptionsMessage={({ inputValue }) =>
          inputValue ? t`No matching options found` : t`Type to search for options`
        }
        onChange={(selected) => {
          const values = selected ? selected.map((option) => option.value) : [];
          column.setFilterValue(values.length ? values : undefined);
        }}
        onInputChange={handleInputChange}
        options={filteredOptions}
        placeholder={placeholderText}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: "0.875rem",
            minHeight: "2rem",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 10,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0 8px",
          }),
          noOptionsMessage: (baseStyles) => ({
            ...baseStyles,
            fontSize: "0.75rem",
          }),
        }}
        value={selectedOptions}
      />
    </Box>
  );
};
