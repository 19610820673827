import { usePinboard } from "@context/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Text,
  Textarea,
} from "doowii-ui";
import { useEffect, useState } from "react";

interface Props {
  isOpen: boolean;
  boardId: string;
  boardName: string;
  boardDescription?: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}

const EditPinboardDetailsDialog: React.FC<Props> = ({
  isOpen,
  boardId,
  boardName,
  boardDescription = "",
  triggerId,
  setIsOpen,
}) => {
  useModalClose(isOpen, triggerId);
  const { t } = useLingui();
  const [name, setName] = useState(boardName);
  const [description, setDescription] = useState(boardDescription);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { updateBoardDetails } = usePinboard();

  const characterLimit = 100;
  const totalCharacters = description.length;

  useEffect(() => {
    setName(boardName);
    setDescription(boardDescription);
  }, [isOpen, boardName, boardDescription]);

  const handleSend = async () => {
    setLoading(true);
    if (name.trim().length < 1) {
      setNameErrorMessage(t`Pinboard name cannot be empty`);
      setLoading(false);
      return;
    }

    await updateBoardDetails(boardId, {
      name,
      description,
    });
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Edit pinboard details</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <InputField
            disabled={loading}
            errorText={nameErrorMessage}
            label={t`Pinboard name`}
            onChange={(e) => {
              setName(e.target.value);
              if (e.target.value.trim().length > 0) {
                setNameErrorMessage("");
              }
            }}
            placeholder={t`Enter pinboard name`}
            type="text"
            value={name}
            required
          />
          <Flex direction="column">
            <Flex direction="column" gap="xs">
              <Textarea
                disabled={loading}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= characterLimit) {
                    setDescription(newValue);
                  }
                }}
                placeholder={t`Enter pinboard description...`}
                value={description}
                rows={3}
                label={t`Pinboard description (optional)`}
              />
            </Flex>
            <Text
              className={css({
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "sm",
                color: "gray.500",
                mt: "xs",
              })}
            >
              {totalCharacters} / {characterLimit}
            </Text>
          </Flex>
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button
            loading={loading}
            onClick={stopPropagationWrapper(handleSend)}
            size="large"
            variant="primary"
          >
            <Trans>Save</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { EditPinboardDetailsDialog };
