import { LogoAvatar } from "@components/ChatContainer";
import { UserAvatar } from "@components/UserAvatar";
import { css } from "@doowii/design-system/css";
import { Flex } from "@styled-system/jsx";
import { Text } from "doowii-ui";
import React from "react";

interface ApolloConversationProps {
  conversation: { author: string; message: string }[];
}

const formatMessage = (message: string) => {
  // Split the message into sentences
  const sentences = message.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+$/gu);

  if (!sentences) {
    return [message];
  }

  // Group sentences into paragraphs of two sentences
  const paragraphs = [];
  for (let i = 0; i < sentences.length; i += 2) {
    const paragraph = sentences
      .slice(i, i + 2)
      .join(" ")
      .trim();
    paragraphs.push(paragraph);
  }

  return paragraphs;
};

const UserChatBubble = ({ message }: { message: string }) => {
  const paragraphs = formatMessage(message);

  return (
    <div
      className={css({
        display: "flex",
        alignItems: "flex-start",
        gap: "sm",
        marginBottom: "sm",
        flexDirection: "row",
      })}
    >
      <UserAvatar />
      <div
        className={css({
          backgroundColor: "{colors.background.bar}",
          borderRadius: "md",
          maxWidth: "80%",
        })}
      >
        {paragraphs.map((paragraph, index) => (
          <Text
            className={css({
              marginBottom: index !== paragraphs.length - 1 ? "md" : "0",
              padding: "md",
            })}
            key={index}
          >
            {paragraph}
          </Text>
        ))}
      </div>
    </div>
  );
};

const ApolloChatBubble = ({ message }: { message: string }) => {
  const paragraphs = formatMessage(message);

  return (
    <div
      className={css({
        display: "flex",
        alignItems: "flex-start",
        gap: "sm",
        marginBottom: "sm",
        flexDirection: "row-reverse",
      })}
    >
      <LogoAvatar />
      <div
        className={css({
          backgroundColor: "{colors.background.bar}",
          borderRadius: "md",
          maxWidth: "80%",
        })}
      >
        {paragraphs.map((paragraph, index) => (
          <Text
            className={css({
              marginBottom: index !== paragraphs.length - 1 ? "sm" : "0",
              padding: "md",
            })}
            key={index}
          >
            {paragraph}
          </Text>
        ))}
      </div>
    </div>
  );
};

const ApolloConversation: React.FC<ApolloConversationProps> = ({ conversation }) => (
  <Flex direction="column">
    {conversation.map((item, index) =>
      item.author === "Doowii" ? (
        <ApolloChatBubble key={index} message={item.message} />
      ) : (
        <UserChatBubble key={index} message={item.message} />
      )
    )}
  </Flex>
);

export { ApolloConversation };
