import { Trans } from "@lingui/react/macro";
import { Box, Flex, VStack } from "@styled-system/jsx";
import { Switch, Text } from "doowii-ui";
import React, { useEffect, useState } from "react";

export interface SeriesOptionsProps {
  initialShowDataLabels?: boolean;
  initialSuffix?: string;
  onOptionsChange: (options: { showDataLabels?: boolean; suffix?: string }) => void;
}

export const SeriesOptions: React.FC<SeriesOptionsProps> = ({
  initialShowDataLabels = false,
  initialSuffix,
  onOptionsChange,
}) => {
  // Local state
  const [showDataLabels, setShowDataLabels] = useState(initialShowDataLabels);
  const [suffix, setSuffix] = useState(initialSuffix);

  // Initialize state from props
  useEffect(() => {
    setShowDataLabels(initialShowDataLabels === true);
    setSuffix(initialSuffix);
  }, [initialShowDataLabels, initialSuffix]);

  // Handle suffix change
  const handleSuffixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSuffix(value);
    onOptionsChange({
      suffix: value,
    });
  };

  // Notify parent of data label toggle changes
  const handleShowDataLabelsChange = (checked: boolean) => {
    setShowDataLabels(checked);
    onOptionsChange({
      showDataLabels: checked,
    });
  };

  return (
    <VStack alignItems="flex-start" gap="md" width="full">
      <Flex alignItems="center" gap="sm">
        <Switch checked={Boolean(showDataLabels)} onCheckedChange={handleShowDataLabelsChange} />
        <Text level={3}>
          <Trans>Show Data Labels</Trans>
        </Text>
      </Flex>

      {showDataLabels && initialSuffix !== undefined ? (
        <VStack gap="md" pl="md" width="full">
          <Box width="full">
            <Text level={4}>
              <Trans>Suffix</Trans>
            </Text>
            <Box style={{ marginTop: "0.5rem" }}>
              <input
                onChange={handleSuffixChange}
                placeholder="%"
                style={{
                  width: "100%",
                  padding: "0.5rem",
                  borderRadius: "0.25rem",
                  border: "1px solid #ccc",
                }}
                type="text"
                value={suffix}
              />
            </Box>
          </Box>
        </VStack>
      ) : null}
    </VStack>
  );
};
