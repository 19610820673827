/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Status options for knowledge items or chunks.
- PENDING: Ready to be processed.
- PROCESSING: Currently being processed.
- COMPLETED: Processed and stored.
- FAILED: Processing failed.
- DELETING: Marked for deletion.
 */
export enum KnowledgeStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  DELETING = "DELETING",
}
