import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { BackgroundBox, Button, InputField, LoadingLogo, Logo } from "doowii-ui";
import { Form, useActionData, useNavigate, useNavigation } from "react-router-dom";
import { env } from "../../env";
import { HIGHER_ED } from "@constants/constants";
import { PATHS } from "@constants/Paths";
import { useEffect } from "react";
import { PageHeader } from "@components/PageHeader";
import { VerifyEmailActionResponse } from "./verifyEmailAction";
import { sendVerificationCode } from "@services/api/generated/webserver/endpoints/emails/emails";
import { SendVerificationCodeRequest } from "@services/api/generated/webserver/models/sendVerificationCodeRequest";
import { VerifyEmailCodeResponseResult } from "@services/api/generated/webserver/models/verifyEmailCodeResponseResult";

const sendEmail = (emailAddress: string) => {
  const request = { email: emailAddress } as SendVerificationCodeRequest;
  sendVerificationCode(request);
}

const VerifyEmail = () => {
    const { t } = useLingui();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const actionData = useActionData() as VerifyEmailActionResponse | null;
    const { isLoading, userDocument, authUser, deleteAuthUser } = useAuth();
    const isSubmitting = navigation.state === "submitting";

    useEffect(() => {
      if (!isLoading) {
        if (userDocument ?? !authUser) {
          if (env.REACT_APP_ENV === HIGHER_ED) {
            navigate(PATHS.ONBOARDING);
          } else {
            navigate(PATHS.ROOT, { replace: true });
          }
        }
  
        if (authUser && !actionData?.result) {
          sendEmail(authUser.email);
        }
  
        if (actionData?.result === VerifyEmailCodeResponseResult.SUCCESS) {
          navigate(PATHS.REGISTER, { replace: true });
        }
      }
    }, [isLoading, authUser, actionData]);

    if (isLoading) {
      return <LoadingLogo />;
    }

    return (
        <>
          <PageHeader description={t`Verify your email`} title={t`Doowii - Verify email`} />
    
          <BackgroundBox
            containerProps={{ css: {} }}
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "100vh",
              padding: "xl6",
              opacity: isSubmitting ? 0.5 : 1,
              pointerEvents: isSubmitting ? "none" : "auto",
            }}
          >
            <Box
              className={flex({
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                flex: 1,
              })}
            >
              <Logo />
              <h2
                className={css({
                  textStyle: "title_h2",
                  fontWeight: 600,
                  marginTop: "md",
                })}
              >
                <Trans>Verify your email</Trans>
              </h2>
              <p
                className={css({
                  marginBottom: "xl",
                })}
              >
              <Trans>We have sent you a 6 digit code at { authUser?.email }</Trans>
              </p>
              <Form className={css({ width: "100%", maxWidth: "400px" })} method="post">
                <Box
                  className={flex({
                    flexDirection: "column",
                    gap: "ld",
                  })}
                >
                  <InputField
                    disabled={isSubmitting}
                    errorText={actionData?.errors?.code}
                    label={t`Enter 6 digit code`}
                    name="code"
                    required
                    type="text"
                  />
                </Box>

                <Flex align="center" justify="space-between" direction="row" marginTop="ld" marginBottom="xl">
                  <Trans>Didn't get the email?</Trans>
                  <p
                    className={css({
                      color: "base.blueBrandPrimary",
                      _hover: { textDecoration: "underline", cursor: "pointer" },
                    })}
                    onClick={() => sendEmail(authUser.email)}
                  >
                    Resend code
                  </p>
                </Flex>

                <Button disabled={isSubmitting} full loading={isSubmitting} type="submit">
                  <Trans>Verify</Trans>
                </Button>
              </Form>
            </Box>
          </BackgroundBox>
        </>
      );
}

export { VerifyEmail };