import { useInactivityLogout } from "@hooks/useInactivityLogout";
import { initializeAuthListener } from "@services/firebase/auth";
import { useEffect } from "react";

const AuthInitializer = ({ children }: { children: React.ReactNode }) => {
  useInactivityLogout();

  useEffect(() => {
    initializeAuthListener();
  }, []);

  return children;
};

export default AuthInitializer;
