import {
  faCircleCheck,
  faCircleXmark,
  faPlus,
  faSpinner,
  faTrash,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react/macro";
import { DocumentKnowledgeItem } from "@services/api/generated/webserver/models/documentKnowledgeItem";
import { css } from "@styled-system/css";
import { Stack } from "@styled-system/jsx";
import { token } from "@styled-system/tokens";
import { Button, Card, InputField, Text } from "doowii-ui";

// this is just the status for the local queue of files for upload.
// not to be confused with the status of the DocumentKnowledgeItem in pinecone
const enum UploadingFileStatus {
  QUEUED = "QUEUED",
  UPLOADING = "UPLOADING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

interface UploadingFile {
  file: File;
  description: DocumentKnowledgeItem["description"];
  isDescriptionOpen: boolean;
  error?: string;
  status: UploadingFileStatus;
  file_type: DocumentKnowledgeItem["file_type"];
}

interface FileListProps {
  files: UploadingFile[];
  onRemoveFile: (index: number) => void;
  onDescriptionChange: (index: number, description: string) => void;
  onToggleDescription: (index: number) => void;
}

const getStatusColor = (status: UploadingFile["status"]) => {
  switch (status) {
    case UploadingFileStatus.COMPLETED:
      return token("colors.complementary.greenPrimary");

    case UploadingFileStatus.FAILED:
      return token("colors.foreground.error");

    case UploadingFileStatus.UPLOADING:
      return token("colors.border.secondary");

    default:
      return token("colors.foreground.base");
  }
};

const getStatusIcon = (status: UploadingFile["status"]) => {
  switch (status) {
    case UploadingFileStatus.COMPLETED:
      return <FontAwesomeIcon icon={faCircleCheck} style={{ color: getStatusColor(status) }} />;

    case UploadingFileStatus.FAILED:
      return <FontAwesomeIcon icon={faCircleXmark} style={{ color: getStatusColor(status) }} />;

    case UploadingFileStatus.UPLOADING:
      return <FontAwesomeIcon icon={faSpinner} spin style={{ color: getStatusColor(status) }} />;

    default:
      return null;
  }
};

const FileList = ({
  files,
  onRemoveFile,
  onDescriptionChange,
  onToggleDescription,
}: FileListProps) => {
  const { t } = useLingui();

  if (files.length === 0) {
    return null;
  }

  return (
    <Stack gap={4}>
      {files.map((file, index) => {
        const size = (file.file.size / (1024 * 1024)).toFixed(2);
        const fileStatus = file.status.toLowerCase() as UploadingFileStatus;
        const fileName = file.file.name;

        return (
          <Card
            data-testid="file-list-item"
            key={fileName}
            style={{
              borderColor: getStatusColor(file.status),
            }}
            variant="flat"
          >
            <Stack gap={2} width="100%">
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Stack gap={1}>
                  <Stack alignItems="center" direction="row" gap={2}>
                    {getStatusIcon(file.status)}
                    <Stack alignItems="start" direction="column" gap={1}>
                      <Text
                        className={css({
                          fontWeight: "bold",
                        })}
                        level={3}
                        variant="primary"
                      >
                        {fileName}
                      </Text>
                      <Text
                        style={{
                          color: file.error ? token("colors.foreground.error") : "base",
                        }}
                        variant="primary"
                      >
                        {file.error ? file.error : t`${size} MB - ${fileStatus}`}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack alignItems="center" direction="row" gap={2}>
                  {file.status !== UploadingFileStatus.COMPLETED ? (
                    <Button
                      iconOnly={faTrash}
                      onClick={() => onRemoveFile(index)}
                      size="small"
                      style={{
                        color: getStatusColor(file.status),
                      }}
                      variant="text"
                    />
                  ) : null}
                </Stack>
              </Stack>

              {!file.error && file.status === UploadingFileStatus.QUEUED ? (
                <Stack alignItems="flex-start" gap={2}>
                  <Button
                    iconLeft={faPlus}
                    onClick={() => onToggleDescription(index)}
                    size="small"
                    style={{
                      display: file.isDescriptionOpen ? "none" : "flex",
                      padding: 0,
                    }}
                    variant="text"
                  >
                    {file.description ? t`Edit description` : t`Add description`}
                  </Button>

                  {file.isDescriptionOpen ? (
                    <Stack css={{ width: "100%", position: "relative" }}>
                      <InputField
                        label=""
                        onChange={(e) => onDescriptionChange(index, e.target.value)}
                        placeholder={t`Enter a description for this document`}
                        value={file.description}
                      />
                      <Button
                        aria-label={t`Clear description`}
                        className={css({
                          position: "absolute",
                          right: "2",
                          top: "2",
                        })}
                        iconOnly={faXmark}
                        onClick={() => {
                          onDescriptionChange(index, "");
                          onToggleDescription(index);
                        }}
                        size="small"
                        variant="text"
                      />
                    </Stack>
                  ) : null}
                </Stack>
              ) : null}
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
};

export { FileList, UploadingFileStatus };
export type { UploadingFile };
