/**
 * Chart Transformations
 *
 * This module provides utilities for transforming raw data into formats
 * suitable for various chart types.
 */

import { ChartData } from "@components/DynamicChartRenderer";
import { ChartConfig as ChartConfigLegacy } from "@doowii-types/chart";
import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { ChartConfig as ChartConfigGen } from "@services/api/generated/webserver/models/chartConfig";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";

import { transformForBarChart } from "./barChartTransformations";
import { transformForLineChart } from "./lineChartTransformations";
import { transformForPieChart } from "./pieChartTransformations";
import { transformForScatterChart } from "./scatterChartTransformations";

export * from "./barChartTransformations";
export * from "./commonTransformations";
export * from "./lineChartTransformations";
export * from "./pieChartTransformations";
export * from "./scatterChartTransformations";

export type ChartConfig = ChartConfigLegacy | ChartConfigGen;
/**
 * Main function to transform data based on chart type
 * @param data The raw data from the API
 * @param chartConfig Configuration specifying chart type and column settings
 * @returns Transformed data ready for the chart component
 */
export const transformDataForChart = (
  data: RetrieveAllResponse,
  chartConfig: ChartConfig
): ChartData => {
  // Default return with raw data
  const defaultReturn: ChartData = {
    series: [],
  };

  // Validate that data has the expected structure
  if (!data || !Array.isArray(data.rows) || !Array.isArray(data.column_names)) {
    console.error("Invalid data format for chart transformation", data);
    return defaultReturn;
  }
  if (chartConfig.columns.length <= 1) {
    return defaultReturn;
  }

  // Route to specific transformation based on chart type
  switch (chartConfig.suggestion) {
    case DataVisualizationType.BAR:
      return transformForBarChart(data, chartConfig);

    case DataVisualizationType.LINE:
      return transformForLineChart(data, chartConfig);

    case DataVisualizationType.PIE:
      return transformForPieChart(data, chartConfig);

    case DataVisualizationType.SCATTER:
      return transformForScatterChart(data, chartConfig);

    default:
      return defaultReturn;
  }
};
