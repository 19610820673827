import { isInvitedUser } from "@services/firebase";

export const verifyEmailDomain = async (email: string): Promise<boolean> => {
  const invited = await isInvitedUser(email);
  if (invited) {
    return true;
  }

  const emailDomain = email.split("@")[1];
  if (emailDomain) {
    const allowedEmailDomains = [".edu", ".gov", ".org"];
    return allowedEmailDomains.some((domain) => emailDomain.endsWith(domain));
  }
  return false;
};
