// src/Accordion/Accordion.tsx
import { css as css2 } from "@doowii/design-system/css";
import {
  faChevronDown,
  faMinus,
  faPlus
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

// src/Logo/Logo.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var LogoDoowii = () => /* @__PURE__ */ jsxs(
  "svg",
  {
    fill: "none",
    height: "48",
    viewBox: "0 0 134 48",
    width: "134",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M120.067 10.582C121.336 10.582 122.328 11.5198 122.328 12.7327C122.328 13.9456 121.335 14.8566 120.067 14.8566C118.799 14.8566 117.861 13.9465 117.861 12.7327C117.861 11.5189 118.799 10.582 120.067 10.582Z",
          fill: "#F5CB62"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M110.182 10.582C111.451 10.582 112.443 11.5198 112.443 12.7327C112.443 13.9456 111.45 14.8566 110.182 14.8566C108.914 14.8566 107.976 13.9465 107.976 12.7327C107.976 11.5189 108.914 10.582 110.182 10.582Z",
          fill: "#F5CB62"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M51.0439 19.6059C49.2049 17.8827 46.7328 16.8906 44.1823 16.8906C41.3397 16.8906 38.7972 17.937 36.9662 19.6967C35.1353 21.4555 34.0177 23.9294 34.0186 26.7596C34.0257 32.0146 38.6752 36.4593 43.9204 36.4646C49.8497 36.4646 54.0174 31.8062 54.0842 26.8281V26.7061C54.0859 23.7896 52.8828 21.3282 51.0439 19.6059ZM39.7438 22.3042C40.8623 21.1857 42.3878 20.4946 44.0184 20.4955C47.4505 20.4955 50.1836 23.2313 50.1845 26.6287C50.1827 30.157 47.3873 32.8571 43.9534 32.8598C42.3095 32.8598 40.8018 32.1393 39.702 31.0012C38.603 29.8631 37.9182 28.3109 37.9191 26.6616C37.9191 24.9642 38.6262 23.4236 39.7447 22.3051L39.7438 22.3042Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M31.3212 11.0859H27.4216V18.912C25.7055 17.6287 23.5967 16.8922 21.4193 16.8922C18.5767 16.8922 16.0342 17.9386 14.2033 19.6983C12.3724 21.4571 11.2547 23.9311 11.2556 26.7612C11.2627 32.0163 15.9122 36.4609 21.1575 36.4663C26.7804 36.4663 30.8127 32.2754 31.2713 27.5965L31.3212 26.8155C31.3212 26.8155 31.3212 26.8146 31.3212 26.8137V11.0859ZM16.9809 22.3058C18.0994 21.1873 19.6249 20.4963 21.2555 20.4971C24.6876 20.4971 27.4207 23.2329 27.4216 26.6303C27.4198 30.1586 24.6244 32.8587 21.1905 32.8614C19.5465 32.8614 18.0388 32.1409 16.939 31.0028C15.8401 29.8647 15.1553 28.3125 15.1562 26.6632C15.1562 24.9659 15.8633 23.4252 16.9818 22.3067L16.9809 22.3058Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M73.131 19.6059C71.2921 17.8827 68.8199 16.8906 66.2694 16.8906C63.4268 16.8906 60.8843 17.937 59.0534 19.6967C57.2224 21.4555 56.1048 23.9294 56.1057 26.7596C56.1128 32.0146 60.7623 36.4593 66.0076 36.4646C71.9368 36.4646 76.1045 31.8062 76.1713 26.8281V26.7061C76.1731 23.7896 74.97 21.3282 73.131 19.6059ZM61.831 22.3042C62.9495 21.1857 64.475 20.4946 66.1056 20.4955C69.5377 20.4955 72.2708 23.2313 72.2716 26.6287C72.2699 30.157 69.4745 32.8571 66.0406 32.8598C64.3966 32.8598 62.8889 32.1393 61.7891 31.0012C60.6902 29.8631 60.0054 28.3109 60.0063 26.6616C60.0063 24.9642 60.7134 23.4236 61.8319 22.3051L61.831 22.3042Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M112.162 17.3984H111.913H108.262V36.3197H112.162V17.3984Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M122.225 17.3984H121.976H118.325V36.3197H122.225V17.3984Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M100.926 17.3281H100.679L96.2111 29.9969L92.8689 17.3281H92.8235H92.575H89.2168H88.9692H88.9238L85.5816 29.9969L81.1138 17.3281H80.8653H77.2141L83.886 36.2494H83.9314H87.7857H87.8311L90.8963 24.6332L93.9607 36.2494H94.0061H97.8603H97.9058L104.579 17.3281H100.926Z",
          fill: "#053946"
        }
      )
    ]
  }
);
var LogoD = () => /* @__PURE__ */ jsxs(
  "svg",
  {
    fill: "none",
    height: "48",
    viewBox: "0 0 48 48",
    width: "48",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M38.3365 10.8555L38.3186 10.8715L38.3365 10.8555Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M5.3522 26.0644C5.64212 25.8807 5.97012 25.7508 6.32116 25.6887C6.59956 25.6394 6.89268 25.6359 7.18644 25.6798C6.97108 23.7322 7.0914 21.7153 7.59828 19.7066C9.87764 10.6711 19.0834 5.17448 28.1189 7.45416C30.5759 8.074 32.7682 9.20904 34.6165 10.7102L34.8028 10.4116C35.1247 9.89448 35.5999 9.5204 36.1231 9.3204C36.2872 9.25736 36.4572 9.21704 36.629 9.18696C34.4191 7.27688 31.7448 5.83624 28.7196 5.07304C18.3548 2.45832 7.83252 8.74088 5.21748 19.1057C4.5874 21.6039 4.4786 24.1098 4.8146 26.5134C4.97396 26.3418 5.1538 26.1898 5.3522 26.0641V26.0644Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M41.8553 16.4023C41.5456 16.9451 41.0835 17.7534 40.6067 18.5854C40.4829 18.8014 40.3587 19.0177 40.2358 19.2318C41.0243 21.995 41.1232 24.9927 40.3712 27.9739C38.0918 37.0094 28.8861 42.506 19.8506 40.2263C16.4074 39.3579 13.4806 37.4814 11.2998 34.994L7.58911 34.1163C10.1453 38.1959 14.2134 41.3364 19.2499 42.6071C29.6147 45.2219 40.1369 38.9393 42.752 28.5745C43.8144 24.3627 43.408 20.1249 41.855 16.4023H41.8553Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M39.1108 10.043L39.1224 10.0561L39.1227 10.0564L39.1108 10.043Z",
          fill: "white"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M7.21009 32.8547L15.2779 34.7629C15.4626 34.7747 15.6431 34.7037 15.7701 34.5689L16.9125 33.3549C17.0491 33.2099 17.2786 33.2067 17.4191 33.3478L20.0904 36.0307C20.2917 36.2329 20.6264 36.2048 20.7912 35.9721L24.4507 30.8073L24.4555 30.8105L29.2568 30.5923C29.4648 30.5827 29.6533 30.4678 29.757 30.2873L35.2258 20.7619C35.3775 20.4979 35.5282 20.3398 35.8133 20.4477L37.6107 21.1657C37.6955 21.2009 37.7931 21.1699 37.8405 21.0912C38.2786 20.3651 40.2498 16.9155 40.9749 15.6451C41.1432 15.3501 41.1531 14.9913 41.0018 14.687C40.7986 14.2784 40.221 14.2627 39.9963 14.6601C39.1707 16.1213 37.6293 18.8448 37.2587 19.463C37.2136 19.5382 37.1221 19.5712 37.0392 19.5427L35.38 18.9737C35.0677 18.8614 34.7787 18.9814 34.6264 19.2429L28.9064 29.0675C28.7992 29.2518 28.6031 29.3667 28.3896 29.3699L24.1202 29.4345C23.9256 29.4374 23.7442 29.5334 23.6319 29.6925L20.549 34.0662C20.4306 34.2342 20.1899 34.256 20.0434 34.1123L17.4213 31.5433C17.2591 31.3843 16.9979 31.3881 16.8405 31.5523L15.2242 33.2374C15.0968 33.3702 14.917 33.4397 14.7333 33.4269L7.23025 31.6777C6.80625 31.6483 6.61105 31.9984 6.62577 32.303C6.64241 32.6505 6.94353 32.8384 7.20977 32.8557L7.21009 32.8547Z",
          fill: "#F5CB62"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M38.3186 10.8706L38.2668 10.8114C38.0485 10.5631 37.7717 10.4024 37.4796 10.3288C37.1653 10.2495 36.8332 10.27 36.5317 10.3858C36.2303 10.5013 35.9586 10.7141 35.7705 11.0159L34.8028 12.5656C34.5157 13.0252 33.965 13.2469 33.4396 13.1144L32.9893 13.0008C32.2981 12.8264 31.5769 13.1423 31.2361 13.7682L26.6156 22.2568C26.3353 22.7724 25.7493 23.005 25.2149 22.8706C25.077 22.8357 24.9426 22.7765 24.8172 22.6911L22.6933 21.2428C22.5388 21.1375 22.3701 21.0626 22.1957 21.0188C21.7385 20.9032 21.2421 20.9989 20.8543 21.3007L12.6841 27.661C12.3858 27.893 11.9986 27.9775 11.6309 27.8908L7.15094 26.8322C6.71766 26.7295 6.29558 26.8175 5.96118 27.029C5.62646 27.2405 5.3759 27.5752 5.27638 27.9698C5.22326 28.18 5.21558 28.4072 5.25942 28.6389L5.25814 28.644L5.26582 28.6799C5.39094 29.261 5.83382 29.7199 6.40918 29.8652L6.42966 29.8703C7.97654 30.2383 11.1932 31.0005 12.5925 31.309C13.0533 31.4104 13.5349 31.2984 13.9036 31.004L21.1077 25.2463C21.4191 24.9973 21.8217 24.918 22.1919 25.0111C22.3349 25.0472 22.4732 25.109 22.5999 25.1973L25.6716 27.3391C25.8313 27.4504 26.0047 27.5279 26.1826 27.5727C26.8549 27.7426 27.5913 27.4469 27.9407 26.796L32.9852 17.3861C33.2418 16.9071 33.7794 16.6495 34.3135 16.7493L34.8687 16.853C35.5173 16.9743 36.1721 16.6744 36.5045 16.1042L38.4949 12.6882C38.5698 12.5596 38.6249 12.4236 38.6597 12.284C38.7833 11.7941 38.6636 11.2636 38.318 10.8709L38.3186 10.8706Z",
          fill: "#2D7E9B"
        }
      )
    ]
  }
);
var Logo = ({ full = true, className }) => /* @__PURE__ */ jsx("div", { className, children: full ? /* @__PURE__ */ jsx(LogoDoowii, {}) : /* @__PURE__ */ jsx(LogoD, {}) });

// src/Separator/Separator.tsx
import { css, cx } from "@doowii/design-system/css";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import * as React from "react";
import { jsx as jsx2 } from "react/jsx-runtime";
var Separator = React.forwardRef(
  ({
    className,
    orientation = "horizontal",
    decorative = true,
    colorful = true,
    ...props
  }, ref) => /* @__PURE__ */ jsx2(
    SeparatorPrimitive.Root,
    {
      className: cx(
        css({ flexShrink: "0", bg: "base.lightGray" }),
        orientation === "horizontal" ? css({ h: "1px", w: "full" }) : css({ h: "full", w: "1px" }),
        colorful && css({
          h: "0.5",
          // bgGradient: "gradient.primary",
          bg: "gradient.primary"
        }),
        className
      ),
      decorative,
      orientation,
      ref,
      ...props
    }
  )
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

// src/Accordion/Accordion.tsx
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var AccordionRow = ({ title, content }) => /* @__PURE__ */ jsxs2(
  AccordionPrimitive.Item,
  {
    className: css2({ mb: 3, "&:last-child": { mb: 0 } }),
    value: title,
    children: [
      /* @__PURE__ */ jsx3(AccordionPrimitive.Header, { children: /* @__PURE__ */ jsxs2(
        AccordionPrimitive.Trigger,
        {
          className: css2({
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            width: "100%",
            cursor: "pointer",
            p: 4,
            rounded: "md",
            bg: "white",
            _hover: {
              bg: "base.blueBrandSecondary",
              borderColor: "base.blueBrandSecondary"
            }
          }),
          children: [
            /* @__PURE__ */ jsx3(
              FontAwesomeIcon,
              {
                className: css2({
                  mr: 2,
                  display: "block !important",
                  "[data-state=open] > &": {
                    display: "none !important"
                  }
                }),
                icon: faPlus
              }
            ),
            /* @__PURE__ */ jsx3(
              FontAwesomeIcon,
              {
                className: css2({
                  mr: 2,
                  display: "none !important",
                  "[data-state=open] > &": {
                    display: "block !important"
                  }
                }),
                icon: faMinus
              }
            ),
            /* @__PURE__ */ jsx3("span", { className: css2({ fontWeight: "medium" }), children: title })
          ]
        }
      ) }),
      /* @__PURE__ */ jsx3(
        AccordionPrimitive.Content,
        {
          className: css2({
            bg: "base.blueBrandSecondary",
            color: "black",
            p: 4,
            mt: 2,
            rounded: "md"
          }),
          children: content
        }
      )
    ]
  }
);
var Accordion = ({
  title,
  rows,
  onValueChange,
  defaultOpen,
  lockedHeader = false,
  defaultExpandedRow,
  showHeader = true
}) => /* @__PURE__ */ jsx3(
  AccordionPrimitive.Root,
  {
    className: css2({
      w: "100%",
      maxW: "600px",
      mx: "auto",
      border: "1px solid",
      borderColor: "gray.200",
      rounded: "lg",
      overflow: "hidden"
    }),
    collapsible: true,
    ...lockedHeader ? { value: "header" } : { defaultValue: defaultOpen ? "header" : void 0 },
    onValueChange,
    type: "single",
    children: /* @__PURE__ */ jsxs2(AccordionPrimitive.Item, { value: "header", children: [
      showHeader ? /* @__PURE__ */ jsxs2(AccordionPrimitive.Header, { children: [
        /* @__PURE__ */ jsxs2(
          AccordionPrimitive.Trigger,
          {
            className: css2({
              display: "flex",
              alignItems: "center",
              width: "100%",
              p: 4,
              cursor: lockedHeader ? "default" : "pointer",
              bg: "white",
              _hover: lockedHeader ? {} : { bg: "base.blueBrandSecondary" }
            }),
            children: [
              /* @__PURE__ */ jsx3(LogoD, {}),
              /* @__PURE__ */ jsx3("span", { className: css2({ ml: 3, fontWeight: "semibold" }), children: title }),
              /* @__PURE__ */ jsx3("div", { className: css2({ flex: 1 }) }),
              /* @__PURE__ */ jsx3(
                FontAwesomeIcon,
                {
                  className: css2({
                    opacity: lockedHeader ? 0 : 1,
                    transition: "transform 0.2s",
                    "[data-state=open] > &": {
                      transform: "rotate(180deg)"
                    }
                  }),
                  icon: faChevronDown
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx3(Separator, {}),
        " "
      ] }) : null,
      /* @__PURE__ */ jsx3(AccordionPrimitive.Content, { children: /* @__PURE__ */ jsx3(
        "div",
        {
          className: css2({
            p: 4
          }),
          children: /* @__PURE__ */ jsx3(
            AccordionPrimitive.Root,
            {
              collapsible: true,
              defaultValue: defaultExpandedRow,
              type: "single",
              children: rows.map((row, index2) => /* @__PURE__ */ jsx3(AccordionRow, { ...row }, index2))
            }
          )
        }
      ) })
    ] })
  }
);

// src/AlertDialog/AlertDialog.tsx
import { cx as cx2, sva } from "@doowii/design-system/css";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import * as React3 from "react";

// src/Button/Button.tsx
import { cva } from "@doowii/design-system/css";
import { Box } from "@doowii/design-system/jsx";
import {
  FontAwesomeIcon as FontAwesomeIcon3
} from "@fortawesome/react-fontawesome";
import React2 from "react";

// src/Spinner/Spinner.tsx
import { css as css3 } from "@doowii/design-system/css";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import {
  FontAwesomeIcon as FontAwesomeIcon2
} from "@fortawesome/react-fontawesome";
import { jsx as jsx4 } from "react/jsx-runtime";
var Spinner = ({
  icon,
  ...props
}) => /* @__PURE__ */ jsx4(
  FontAwesomeIcon2,
  {
    className: css3({ animation: "spin" }),
    icon: icon ?? faSpinner,
    ...props
  }
);

// src/Button/Button.tsx
import { jsx as jsx5, jsxs as jsxs3 } from "react/jsx-runtime";
var buttonRecipe = cva({
  base: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    gap: "sm",
    cursor: "pointer",
    borderWidth: "2px",
    borderStyle: "solid",
    fontWeight: "medium"
  },
  variants: {
    size: {
      xsmall: {
        padding: "sm",
        textStyle: "paragraph_14",
        borderRadius: "md",
        height: "32px"
      },
      small: {
        padding: "md",
        textStyle: "paragraph_16",
        borderRadius: "lg",
        height: "40px"
      },
      medium: {
        py: `md`,
        px: `ld`,
        textStyle: "paragraph_18",
        borderRadius: "xl",
        height: "48px"
      },
      large: {
        py: `md`,
        px: `ld`,
        textStyle: "paragraph_18",
        borderRadius: "xl",
        height: "56px"
      }
    },
    variant: {
      primary: {
        backgroundColor: "background.primary",
        color: "foreground.primary",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.hover.primary",
          color: "foreground.hover.primary"
        },
        _active: {
          backgroundColor: "background.active",
          color: "foreground.active"
        }
      },
      secondary: {
        backgroundColor: "background.secondary",
        color: "foreground.secondary",
        borderColor: "border.secondary",
        _hover: {
          backgroundColor: "background.hover.secondary",
          color: "foreground.hover.secondary",
          borderColor: "border.hover.secondary"
        },
        _active: {
          backgroundColor: "background.active"
        }
      },
      tertiary: {
        backgroundColor: "background.tertiary",
        color: "foreground.tertiary",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.hover.tertiary",
          color: "foreground.hover.tertiary"
        },
        _active: {
          backgroundColor: "background.active"
        }
      },
      dropdown: {
        backgroundColor: "white",
        color: "base.darkBlue",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "base.blueBrandSecondary"
        },
        _active: {
          backgroundColor: "base.blueBrandTertiary"
        },
        _focus: {
          backgroundColor: "base.blueBrandTertiary"
        }
      },
      text: {
        fontSize: "inherit",
        padding: "0",
        textDecoration: "underline",
        backgroundColor: "transparent",
        color: "foreground.tertiary",
        borderColor: "transparent",
        _hover: {
          color: "base.darkBlue"
        }
      }
    },
    destructive: { true: {} },
    disabled: {
      true: {
        cursor: "not-allowed",
        color: "foreground.disabled",
        backgroundColor: "background.disabled",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.disabled",
          color: "foreground.disabled"
        },
        _active: {
          backgroundColor: "background.disabled",
          color: "foreground.disabled"
        }
      }
    },
    full: {
      true: {
        w: "100% !important",
        justifyContent: "center"
      }
    },
    iconOnly: {
      true: {
        p: "sm",
        justifyContent: "center"
      }
    },
    showIconLeft: {
      true: {}
    },
    showIconRight: {
      true: {}
    }
  },
  compoundVariants: [
    {
      variant: "primary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.primary",
        color: "foreground.destructive.primary",
        borderColor: "border.destructive.primary",
        _hover: {
          backgroundColor: "background.destructive.hover.primary",
          color: "foreground.destructive.hover.primary"
        },
        _active: {
          backgroundColor: "background.destructive.active.primary",
          color: "foreground.destructive.primary"
        }
      }
    },
    {
      variant: "secondary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.secondary",
        color: "foreground.destructive.secondary",
        borderColor: "border.destructive.secondary",
        _hover: {
          backgroundColor: "background.destructive.hover.secondary",
          color: "foreground.destructive.hover.secondary",
          borderColor: "border.destructive.hover.secondary"
        },
        _active: {
          backgroundColor: "background.destructive.secondary"
        }
      }
    },
    {
      variant: "tertiary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.tertiary",
        color: "foreground.destructive.tertiary",
        _hover: {
          backgroundColor: "background.destructive.hover.tertiary",
          color: "foreground.destructive.hover.tertiary"
        },
        _active: {
          backgroundColor: "background.destructive.active.tertiary"
        }
      }
    },
    {
      iconOnly: true,
      size: "xsmall",
      css: {
        w: "32px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "small",
      css: {
        w: "40px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "medium",
      css: {
        w: "48px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "large",
      css: {
        w: "56px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      showIconLeft: true,
      size: "xsmall",
      css: {
        pl: "2"
      }
    },
    {
      showIconLeft: true,
      size: "small",
      css: {
        pl: "3"
      }
    },
    {
      showIconLeft: true,
      size: "medium",
      css: {
        pr: "6"
      }
    },
    {
      showIconLeft: true,
      size: "large",
      css: {
        pr: "6"
      }
    },
    {
      showIconRight: true,
      size: "xsmall",
      css: {
        pr: "2"
      }
    },
    {
      showIconRight: true,
      size: "small",
      css: {
        pr: "3"
      }
    },
    {
      showIconRight: true,
      size: "medium",
      css: {
        pl: "6"
      }
    },
    {
      showIconRight: true,
      size: "large",
      css: {
        pl: "6"
      }
    }
  ],
  defaultVariants: {
    size: "medium",
    variant: "primary"
  }
});
var ButtonIcon = ({
  icon,
  loading,
  buttonSize = "medium"
}) => /* @__PURE__ */ jsx5(
  Box,
  {
    alignContent: "center",
    h: buttonSize === "xsmall" ? "4" : buttonSize === "small" ? "6" : "8",
    justifyContent: "center",
    w: buttonSize === "xsmall" ? "4" : buttonSize === "small" ? "6" : "8",
    children: /* @__PURE__ */ jsx5(FontAwesomeIcon3, { icon, opacity: loading ? 0 : 1 })
  }
);
var Button = React2.forwardRef(
  ({
    children,
    disabled,
    loading,
    iconOnly,
    iconRight,
    iconLeft,
    className,
    full,
    destructive,
    ...buttonProps
  }, ref) => {
    const showIconLeft = iconLeft && !iconOnly;
    const showIconRight = iconRight && !iconOnly;
    const showSingleSpinner = loading && !iconLeft;
    const buttonClass = buttonRecipe({
      ...buttonProps,
      full,
      iconOnly: Boolean(iconOnly),
      destructive,
      disabled: Boolean(disabled ?? loading),
      showIconLeft: Boolean(showIconLeft),
      showIconRight: Boolean(showIconRight)
    });
    return /* @__PURE__ */ jsxs3(
      "button",
      {
        className: `${buttonClass} ${className ?? ""}`,
        disabled: disabled ?? loading,
        ref,
        type: "button",
        ...buttonProps,
        children: [
          showSingleSpinner ? /* @__PURE__ */ jsx5(
            Box,
            {
              style: {
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%"
              },
              children: /* @__PURE__ */ jsx5(Spinner, { size: "xs" })
            }
          ) : null,
          showIconLeft ? /* @__PURE__ */ jsxs3(Box, { style: { position: "relative", display: "flex" }, children: [
            /* @__PURE__ */ jsx5(
              ButtonIcon,
              {
                buttonSize: buttonProps.size,
                icon: iconLeft,
                loading
              }
            ),
            loading ? /* @__PURE__ */ jsx5(
              Spinner,
              {
                size: "xs",
                style: {
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: "25%"
                }
              }
            ) : null
          ] }) : null,
          iconOnly ? /* @__PURE__ */ jsx5(
            ButtonIcon,
            {
              buttonSize: buttonProps.size,
              icon: iconOnly,
              loading
            }
          ) : /* @__PURE__ */ jsx5(Box, { style: { opacity: !iconLeft && loading ? 0 : 1 }, children }),
          showIconRight ? /* @__PURE__ */ jsx5(
            ButtonIcon,
            {
              buttonSize: buttonProps.size,
              icon: iconRight,
              loading
            }
          ) : null
        ]
      }
    );
  }
);
Button.displayName = "Button";

// src/AlertDialog/AlertDialog.tsx
import { jsx as jsx6, jsxs as jsxs4 } from "react/jsx-runtime";
var alertDialogRecipe = sva({
  className: "alertDialog",
  slots: [
    "root",
    "trigger",
    "portal",
    "overlay",
    "content",
    "header",
    "footer",
    "title",
    "description",
    "action",
    "cancel"
  ],
  base: {
    overlay: {
      position: "fixed",
      inset: "0",
      zIndex: 50,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      // Darker background
      animation: "overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      textAlign: "center"
    },
    content: {
      position: "fixed",
      left: "50%",
      top: "50%",
      zIndex: 50,
      p: "6",
      display: "flex",
      flexDirection: "column",
      w: "full",
      maxWidth: "lg",
      maxHeight: "85vh",
      // Limit the maximum height
      transform: "translate(-50%, -50%)",
      border: "base",
      bg: "background",
      boxShadow: "lg",
      animation: "contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      "&:focus": { outline: "none" },
      overflow: "hidden",
      alignItems: "center",
      sm: {
        rounded: "lg"
      }
    },
    header: {
      display: "flex",
      flexDirection: "column",
      spaceY: "2",
      textAlign: "center",
      alignItems: "center"
    },
    footer: {
      display: "flex",
      flexDirection: "column-reverse",
      marginTop: "sm",
      sm: {
        marginTop: "xl",
        flexDirection: "row",
        justifyContent: "flex-end",
        spaceX: "md"
      }
    },
    title: {
      textStyle: "lg",
      fontWeight: "semibold"
    },
    description: {
      textStyle: "sm",
      color: "muted.foreground"
    },
    cancel: {
      mt: {
        base: "2",
        sm: "0"
      }
    }
  }
});
var AlertDialog = AlertDialogPrimitive.Root;
var AlertDialogTrigger = AlertDialogPrimitive.Trigger;
var AlertDialogPortal = AlertDialogPrimitive.Portal;
var AlertDialogOverlay = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(
  AlertDialogPrimitive.Overlay,
  {
    className: cx2(alertDialogRecipe().overlay, className),
    ...props,
    ref
  }
));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;
var AlertDialogContent = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsxs4(AlertDialogPortal, { children: [
  /* @__PURE__ */ jsx6(AlertDialogOverlay, {}),
  /* @__PURE__ */ jsx6(
    AlertDialogPrimitive.Content,
    {
      className: cx2(alertDialogRecipe().content, className),
      ref,
      ...props
    }
  )
] }));
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;
var AlertDialogHeader = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(
  "div",
  {
    className: cx2(alertDialogRecipe().header, className),
    ref,
    ...props
  }
));
AlertDialogHeader.displayName = "AlertDialogHeader";
var AlertDialogFooter = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(
  "div",
  {
    className: cx2(alertDialogRecipe().footer, className),
    ref,
    ...props
  }
));
AlertDialogFooter.displayName = "AlertDialogFooter";
var AlertDialogTitle = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(
  AlertDialogPrimitive.Title,
  {
    className: cx2(alertDialogRecipe().title, className),
    ref,
    ...props
  }
));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;
var AlertDialogDescription = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(
  AlertDialogPrimitive.Description,
  {
    className: cx2(alertDialogRecipe().description, className),
    ref,
    ...props
  }
));
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName;
var AlertDialogAction = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(AlertDialogPrimitive.Action, { asChild: true, children: /* @__PURE__ */ jsx6(
  Button,
  {
    className: cx2(alertDialogRecipe().action, className),
    ref,
    ...props
  }
) }));
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;
var AlertDialogCancel = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(AlertDialogPrimitive.Cancel, { asChild: true, children: /* @__PURE__ */ jsx6(
  Button,
  {
    className: cx2(alertDialogRecipe().cancel, className),
    ref,
    variant: "secondary",
    ...props
  }
) }));
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

// src/AlertDialog/AlertDialog.stories.tsx
import { css as css5 } from "@doowii/design-system/css";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

// src/CircularIcon/CircularIcon.tsx
import { css as css4 } from "@doowii/design-system/css";
import { Box as Box2 } from "@doowii/design-system/jsx";
import { FontAwesomeIcon as FontAwesomeIcon4 } from "@fortawesome/react-fontawesome";
import { jsx as jsx7 } from "react/jsx-runtime";
var circleStyle = {
  width: "148px",
  height: "148px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(97.52deg, rgba(246, 205, 60, 0.1) 47.37%, rgba(41, 189, 240, 0.1) 65.41%)",
  border: "2px solid transparent",
  boxShadow: "4px 8px 12px 0px #EFC42814, -4px -8px 12px 0px #2ABDF114",
  position: "relative",
  overflow: "hidden",
  "&:after": {
    content: "''",
    position: "absolute",
    inset: 0,
    borderRadius: "50%",
    padding: "3px",
    width: "full",
    height: "full",
    background: "linear-gradient(97.52deg, #F6CD3C 47.37%, #29BDF0 65.41%)",
    "-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    "-webkit-mask-composite": "xor",
    "mask-composite": "exclude",
    "pointer-events": "none"
  }
};
var iconStyle = css4({
  fontSize: "42px",
  rotate: "10deg"
});
var CircularIcon = ({ icon, css: cssProp }) => /* @__PURE__ */ jsx7(Box2, { css: { ...circleStyle, ...cssProp ?? {} }, children: /* @__PURE__ */ jsx7(FontAwesomeIcon4, { className: iconStyle, icon }) });

// src/AlertDialog/AlertDialog.stories.tsx
import { jsx as jsx8, jsxs as jsxs5 } from "react/jsx-runtime";
var Default = {
  render: () => /* @__PURE__ */ jsxs5(AlertDialog, { children: [
    /* @__PURE__ */ jsx8(AlertDialogTrigger, { asChild: true, children: /* @__PURE__ */ jsx8(Button, { children: "Open Alert Dialog" }) }),
    /* @__PURE__ */ jsxs5(AlertDialogContent, { children: [
      /* @__PURE__ */ jsxs5(AlertDialogHeader, { children: [
        /* @__PURE__ */ jsx8(AlertDialogTitle, { children: "Are you sure?" }),
        /* @__PURE__ */ jsx8(AlertDialogDescription, { children: "This action cannot be undone. This will permanently delete your account and remove your data from our servers." })
      ] }),
      /* @__PURE__ */ jsxs5(AlertDialogFooter, { children: [
        /* @__PURE__ */ jsx8(AlertDialogCancel, { children: "Cancel" }),
        /* @__PURE__ */ jsx8(AlertDialogAction, { children: "Continue" })
      ] })
    ] })
  ] })
};
var WithIcon = {
  render: () => /* @__PURE__ */ jsxs5(AlertDialog, { children: [
    /* @__PURE__ */ jsx8(AlertDialogTrigger, { asChild: true, children: /* @__PURE__ */ jsx8(Button, { children: "Open Alert Dialog with Icon" }) }),
    /* @__PURE__ */ jsxs5(AlertDialogContent, { children: [
      /* @__PURE__ */ jsxs5(AlertDialogHeader, { children: [
        /* @__PURE__ */ jsx8(CircularIcon, { css: { marginBottom: "md" }, icon: faTrash }),
        /* @__PURE__ */ jsx8(AlertDialogTitle, { children: "Delete Chat" }),
        /* @__PURE__ */ jsx8(AlertDialogDescription, { children: "Are you sure you want to delete this chat?" })
      ] }),
      /* @__PURE__ */ jsxs5(AlertDialogFooter, { children: [
        /* @__PURE__ */ jsx8(AlertDialogCancel, { children: "Cancel" }),
        /* @__PURE__ */ jsx8(AlertDialogAction, { destructive: true, children: "Delete" })
      ] })
    ] })
  ] })
};

// src/Avatar/Avatar.tsx
import { css as css6, cva as cva2, cx as cx3 } from "@doowii/design-system/css";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React4 from "react";
import { jsx as jsx9 } from "react/jsx-runtime";
var avatarRecipe = cva2({
  base: {
    pos: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    overflow: "hidden",
    rounded: "full"
  },
  variants: {
    size: {
      small: { w: "24px", h: "24px", textStyle: "paragraph_12" },
      medium: { w: "32px", h: "32px", textStyle: "paragraph_14" },
      large: { w: "40px", h: "40px", textStyle: "paragraph_18" }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
var Avatar = React4.forwardRef(({ className, size: size4, ...props }, ref) => /* @__PURE__ */ jsx9(
  AvatarPrimitive.Root,
  {
    className: cx3(avatarRecipe({ size: size4 }), className),
    ref,
    ...props
  }
));
Avatar.displayName = AvatarPrimitive.Root.displayName;
var AvatarImage = React4.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx9(
  AvatarPrimitive.Image,
  {
    className: cx3(
      css6({ aspectRatio: "square", h: "full", w: "full" }),
      className
    ),
    ref,
    ...props
  }
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
var AvatarFallback = React4.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx9(
  AvatarPrimitive.Fallback,
  {
    className: cx3(
      css6({
        display: "flex",
        h: "full",
        w: "full",
        alignItems: "center",
        justifyContent: "center",
        rounded: "full",
        bg: "base.blueBrandPrimary",
        color: "white",
        fontWeight: "600"
      }),
      className
    ),
    ref,
    ...props
  }
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

// src/BackgroundBox/BackgroundBox.tsx
import { Box as Box3 } from "@doowii/design-system/jsx";
import { jsx as jsx10, jsxs as jsxs6 } from "react/jsx-runtime";
var BackgroundBox = ({ children, containerProps, ...props }) => /* @__PURE__ */ jsxs6(
  Box3,
  {
    ...containerProps,
    css: {
      position: "relative",
      overflow: "auto",
      ...containerProps?.css
    },
    children: [
      /* @__PURE__ */ jsx10(
        Box3,
        {
          css: {
            position: "fixed",
            background: "#F6F3EB",
            opacity: "20%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx10(
        Box3,
        {
          css: {
            position: "fixed",
            opacity: "6%",
            top: "-15%",
            left: "-15%",
            width: "70%",
            height: "70%",
            borderRadius: "full",
            background: "linear-gradient(274.7deg, #DD6C19 0.76%, #EFC428 77.58%)",
            zIndex: 0,
            filter: "blur(300px)",
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx10(
        Box3,
        {
          css: {
            position: "fixed",
            opacity: "6%",
            bottom: "-15%",
            right: "-15%",
            width: "70%",
            height: "70%",
            borderRadius: "full",
            background: "linear-gradient(141.47deg, #3CA0C3 16.12%, #7564E3 74.96%)",
            filter: "blur(300px)",
            zIndex: 0,
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx10(
        Box3,
        {
          ...props,
          css: { maxWidth: "100%", zIndex: 1, position: "relative", ...props.css },
          children
        }
      )
    ]
  }
);

// src/Badge/Badge.tsx
import { css as css8, cva as cva3, cx as cx4 } from "@doowii/design-system/css";
import { HStack } from "@doowii/design-system/jsx";
import {
  faArrowUpRightFromSquare,
  faCircleXmark
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon5 } from "@fortawesome/react-fontawesome";
import React6 from "react";

// src/Tooltip/Tooltip.tsx
import { css as css7 } from "@doowii/design-system/css";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { forwardRef as forwardRef4 } from "react";
import { jsx as jsx11, jsxs as jsxs7 } from "react/jsx-runtime";
var tooltipContentStyles = css7({
  bg: "base.darkBlue",
  color: "base.white",
  rounded: "xs",
  padding: "xs",
  textStyle: "paragraph_14",
  zIndex: 9999999,
  maxWidth: "400px"
});
var tooltipArrowStyles = css7({
  fill: "base.darkBlue"
});
var Tooltip = forwardRef4(
  ({
    open,
    children,
    side,
    sideOffset,
    align,
    content,
    delayDuration = 200,
    ...triggerProps
  }, ref) => /* @__PURE__ */ jsx11(TooltipPrimitive.Provider, { delayDuration, children: /* @__PURE__ */ jsxs7(TooltipPrimitive.Root, { open, children: [
    /* @__PURE__ */ jsx11(TooltipPrimitive.Trigger, { asChild: true, ...triggerProps, ref, children }),
    /* @__PURE__ */ jsxs7(
      TooltipPrimitive.Content,
      {
        align,
        className: tooltipContentStyles,
        side,
        sideOffset,
        children: [
          content,
          /* @__PURE__ */ jsx11(TooltipPrimitive.Arrow, { className: tooltipArrowStyles })
        ]
      }
    )
  ] }) })
);
Tooltip.displayName = "Tooltip";

// src/Badge/Badge.tsx
import { jsx as jsx12, jsxs as jsxs8 } from "react/jsx-runtime";
var badgeStyles = cva3({
  base: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    rounded: "full",
    p: "1",
    textStyle: "paragraph_14",
    fontWeight: "medium",
    gap: "3",
    backgroundColor: "base.lightherGray",
    transition: "background-color 0.2s ease-in-out"
  },
  variants: {
    hasIcons: {
      true: {
        alignItems: "space-between",
        pl: "3"
      },
      false: {
        px: "3"
      }
    },
    isClickable: {
      true: {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "base.lightGray"
        }
      },
      false: {}
    }
  },
  defaultVariants: {
    hasIcons: true,
    isClickable: false
  }
});
var stopPropagationWrapper = (callback) => (e) => {
  e.stopPropagation();
  callback(e);
};
var Badge = React6.forwardRef(
  ({
    children,
    className,
    withIcons = true,
    onNavigate,
    onRemove,
    onRemoveTooltip,
    onClick,
    ...props
  }, ref) => /* @__PURE__ */ jsxs8(
    "span",
    {
      className: cx4(
        badgeStyles({
          hasIcons: withIcons,
          isClickable: Boolean(onClick)
        }),
        className
      ),
      onClick,
      onKeyDown: (e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          onClick?.(e);
        }
      },
      role: "button",
      tabIndex: onClick ? 0 : void 0,
      ...props,
      ref,
      children: [
        children,
        withIcons ? /* @__PURE__ */ jsxs8(HStack, { gap: "none", children: [
          onNavigate ? /* @__PURE__ */ jsx12(Tooltip, { content: "Navigate to the Pin", children: /* @__PURE__ */ jsx12(
            FontAwesomeIcon5,
            {
              className: css8({
                w: "6",
                h: "6",
                cursor: "pointer"
              }),
              icon: faArrowUpRightFromSquare,
              onClick: stopPropagationWrapper(onNavigate),
              size: "sm"
            }
          ) }) : null,
          onRemove ? /* @__PURE__ */ jsx12(Tooltip, { content: onRemoveTooltip, children: /* @__PURE__ */ jsx12(
            FontAwesomeIcon5,
            {
              className: css8({
                w: "6",
                h: "6",
                cursor: "pointer"
              }),
              icon: faCircleXmark,
              onClick: stopPropagationWrapper(onRemove),
              size: "sm"
            }
          ) }) : null
        ] }) : null
      ]
    }
  )
);
Badge.displayName = "Badge";

// src/Breadcrumb/Breadcrumb.tsx
import { css as css9, cx as cx5 } from "@doowii/design-system/css";
import { faChevronRight, faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon6 } from "@fortawesome/react-fontawesome";

// ../../node_modules/@radix-ui/react-slot/dist/index.mjs
import * as React8 from "react";

// ../../node_modules/@radix-ui/react-compose-refs/dist/index.mjs
import * as React7 from "react";
function setRef(ref, value) {
  if (typeof ref === "function") {
    return ref(value);
  } else if (ref !== null && ref !== void 0) {
    ref.current = value;
  }
}
function composeRefs(...refs) {
  return (node) => {
    let hasCleanup = false;
    const cleanups = refs.map((ref) => {
      const cleanup = setRef(ref, node);
      if (!hasCleanup && typeof cleanup == "function") {
        hasCleanup = true;
      }
      return cleanup;
    });
    if (hasCleanup) {
      return () => {
        for (let i = 0; i < cleanups.length; i++) {
          const cleanup = cleanups[i];
          if (typeof cleanup == "function") {
            cleanup();
          } else {
            setRef(refs[i], null);
          }
        }
      };
    }
  };
}
function useComposedRefs(...refs) {
  return React7.useCallback(composeRefs(...refs), refs);
}

// ../../node_modules/@radix-ui/react-slot/dist/index.mjs
import { Fragment, jsx as jsx13 } from "react/jsx-runtime";
var Slot = React8.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  const childrenArray = React8.Children.toArray(children);
  const slottable = childrenArray.find(isSlottable);
  if (slottable) {
    const newElement = slottable.props.children;
    const newChildren = childrenArray.map((child) => {
      if (child === slottable) {
        if (React8.Children.count(newElement) > 1) return React8.Children.only(null);
        return React8.isValidElement(newElement) ? newElement.props.children : null;
      } else {
        return child;
      }
    });
    return /* @__PURE__ */ jsx13(SlotClone, { ...slotProps, ref: forwardedRef, children: React8.isValidElement(newElement) ? React8.cloneElement(newElement, void 0, newChildren) : null });
  }
  return /* @__PURE__ */ jsx13(SlotClone, { ...slotProps, ref: forwardedRef, children });
});
Slot.displayName = "Slot";
var SlotClone = React8.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  if (React8.isValidElement(children)) {
    const childrenRef = getElementRef(children);
    return React8.cloneElement(children, {
      ...mergeProps(slotProps, children.props),
      // @ts-ignore
      ref: forwardedRef ? composeRefs(forwardedRef, childrenRef) : childrenRef
    });
  }
  return React8.Children.count(children) > 1 ? React8.Children.only(null) : null;
});
SlotClone.displayName = "SlotClone";
var Slottable = ({ children }) => {
  return /* @__PURE__ */ jsx13(Fragment, { children });
};
function isSlottable(child) {
  return React8.isValidElement(child) && child.type === Slottable;
}
function mergeProps(slotProps, childProps) {
  const overrideProps = { ...childProps };
  for (const propName in childProps) {
    const slotPropValue = slotProps[propName];
    const childPropValue = childProps[propName];
    const isHandler = /^on[A-Z]/.test(propName);
    if (isHandler) {
      if (slotPropValue && childPropValue) {
        overrideProps[propName] = (...args) => {
          childPropValue(...args);
          slotPropValue(...args);
        };
      } else if (slotPropValue) {
        overrideProps[propName] = slotPropValue;
      }
    } else if (propName === "style") {
      overrideProps[propName] = { ...slotPropValue, ...childPropValue };
    } else if (propName === "className") {
      overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(" ");
    }
  }
  return { ...slotProps, ...overrideProps };
}
function getElementRef(element) {
  let getter = Object.getOwnPropertyDescriptor(element.props, "ref")?.get;
  let mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.ref;
  }
  getter = Object.getOwnPropertyDescriptor(element, "ref")?.get;
  mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }
  return element.props.ref || element.ref;
}

// src/Breadcrumb/Breadcrumb.tsx
import * as React9 from "react";
import { jsx as jsx14, jsxs as jsxs9 } from "react/jsx-runtime";
var Breadcrumb = React9.forwardRef(({ ...props }, ref) => /* @__PURE__ */ jsx14("nav", { "aria-label": "breadcrumb", ref, ...props }));
Breadcrumb.displayName = "Breadcrumb";
var BreadcrumbList = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx14(
  "ol",
  {
    className: cx5(
      css9({
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "1.5",
        overflowWrap: "break-word",
        textStyle: "paragraph_16",
        fontWeight: "500",
        lineHeight: "sm",
        sm: { gap: "2.5" }
      }),
      className
    ),
    ref,
    ...props
  }
));
BreadcrumbList.displayName = "BreadcrumbList";
var BreadcrumbItem = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx14(
  "li",
  {
    className: cx5(
      css9({ display: "inline-flex", alignItems: "center", gap: "1.5" }),
      className
    ),
    ref,
    ...props
  }
));
BreadcrumbItem.displayName = "BreadcrumbItem";
var BreadcrumbLink = React9.forwardRef(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : "a";
  return /* @__PURE__ */ jsx14(
    Comp,
    {
      className: cx5(
        css9({
          transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
          transitionTimingFunction: "colors",
          transitionDuration: "colors"
        }),
        className
      ),
      ref,
      ...props
    }
  );
});
BreadcrumbLink.displayName = "BreadcrumbLink";
var BreadcrumbPage = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx14(
  "span",
  {
    "aria-current": "page",
    "aria-disabled": "true",
    className: cx5(css9({ fontWeight: "normal" }), className),
    ref,
    role: "link",
    ...props
  }
));
BreadcrumbPage.displayName = "BreadcrumbPage";
var BreadcrumbSeparator = ({
  children,
  className,
  ...props
}) => /* @__PURE__ */ jsx14(
  "li",
  {
    "aria-hidden": "true",
    className: cx5("[&>svg]:size-3.5", className),
    role: "presentation",
    ...props,
    children: children ?? /* @__PURE__ */ jsx14(FontAwesomeIcon6, { icon: faChevronRight })
  }
);
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";
var BreadcrumbEllipsis = ({
  className,
  ...props
}) => /* @__PURE__ */ jsxs9(
  "span",
  {
    "aria-hidden": "true",
    className: cx5(
      css9({
        display: "flex",
        h: "9",
        w: "9",
        alignItems: "center",
        justifyContent: "center"
      }),
      className
    ),
    role: "presentation",
    ...props,
    children: [
      /* @__PURE__ */ jsx14(FontAwesomeIcon6, { className: css9({ h: "4", w: "4" }), icon: faEllipsis }),
      /* @__PURE__ */ jsx14(
        "span",
        {
          className: css9({
            pos: "absolute",
            w: "sr.only",
            h: "sr.only",
            p: "sr.only",
            m: "sr.only",
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            whiteSpace: "nowrap",
            borderWidth: "0"
          }),
          children: "More"
        }
      )
    ]
  }
);
BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";

// src/Callout/Callout.tsx
import { css as css10 } from "@doowii/design-system/css";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon7 } from "@fortawesome/react-fontawesome";
import React10 from "react";
import { jsx as jsx15, jsxs as jsxs10 } from "react/jsx-runtime";
var variantConfig = {
  success: {
    icon: faCircleCheck,
    background: "#E0F5E9",
    color: "#185332"
  },
  warning: {
    icon: faTriangleExclamation,
    background: "#F5EBE0",
    color: "#6B4212"
  },
  info: {
    icon: faCircleInfo,
    background: "#E0ECF5",
    color: "#204C6C"
  },
  error: {
    icon: faCircleExclamation,
    background: "#F8DDDD",
    color: "#5D1313"
  }
};
var sizeConfig = {
  "1": {
    padding: "sm",
    gap: "xs",
    iconSize: "1x",
    textStyle: "paragraph_12"
  },
  "2": {
    padding: "md",
    gap: "sm",
    iconSize: "lg",
    textStyle: "paragraph_14"
  },
  "3": {
    padding: "ld",
    gap: "md",
    iconSize: "2x",
    textStyle: "paragraph_16"
  }
};
var Callout = React10.forwardRef(
  ({ variant, size: size4 = "2", children, fullWidth = false, style }, ref) => {
    const variantStyle = variantConfig[variant];
    const sizeStyle = sizeConfig[size4];
    return /* @__PURE__ */ jsxs10(
      "div",
      {
        className: css10({
          display: "inline-flex",
          // Changed to inline-flex
          width: fullWidth ? "100%" : "fit-content",
          // Control width based on fullWidth prop
          maxWidth: "100%",
          // Ensure it doesn't overflow container
          alignItems: "center",
          boxShadow: "regular",
          transition: "all 0.2s ease-in-out",
          borderRadius: "md",
          padding: sizeStyle.padding,
          gap: sizeStyle.gap,
          _hover: {
            boxShadow: "hover"
          }
        }),
        ref,
        role: variant === "error" ? "alert" : "status",
        style: {
          backgroundColor: variantStyle.background,
          color: variantStyle.color,
          ...style
        },
        children: [
          /* @__PURE__ */ jsx15(
            "div",
            {
              className: css10({
                display: "flex",
                alignItems: "center",
                flexShrink: 0
              }),
              children: /* @__PURE__ */ jsx15(
                FontAwesomeIcon7,
                {
                  "aria-hidden": "true",
                  icon: variantStyle.icon,
                  size: sizeStyle.iconSize
                }
              )
            }
          ),
          /* @__PURE__ */ jsx15(
            "div",
            {
              className: css10({
                textStyle: sizeStyle.textStyle,
                display: "block",
                whiteSpace: "normal",
                // Always allow text wrapping
                wordBreak: "break-word"
                // Ensure long words can break
              }),
              children
            }
          )
        ]
      }
    );
  }
);
Callout.displayName = "Callout";

// src/Cards/Card.tsx
import { css as css11, cva as cva4 } from "@doowii/design-system/css";
import React11 from "react";
var cardRecipe = cva4({
  base: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "ld",
    bg: "base.white",
    p: "ld",
    rounded: "xl",
    border: "1px solid token(colors.base.lightGray)",
    shadow: "regular",
    w: "full",
    textAlign: "left",
    color: "base.darkBlue"
  },
  variants: {
    variant: {
      default: {},
      button: {
        cursor: "pointer",
        _hover: {
          shadow: "hover"
        }
      },
      flat: {
        shadow: "none",
        _hover: {
          shadow: "none"
        }
      }
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
var Card = React11.forwardRef(
  ({ children, variant, label, onClick, css: cssProp, ...props }, ref) => {
    const cardClass = css11(cardRecipe.raw({ variant }), cssProp);
    const CardComponent = variant === "button" ? "button" : "div";
    return React11.createElement(
      CardComponent,
      {
        "aria-label": label,
        className: cardClass,
        onClick: variant === "button" ? onClick : void 0,
        ref,
        type: variant === "button" ? "button" : void 0,
        ...props
      },
      children
    );
  }
);
Card.displayName = "Card";

// src/Checkbox/Checkbox.tsx
import { css as css12, cx as cx6 } from "@doowii/design-system/css";
import { Box as Box4, Flex } from "@doowii/design-system/jsx";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon8 } from "@fortawesome/react-fontawesome";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React12 from "react";
import { jsx as jsx16, jsxs as jsxs11 } from "react/jsx-runtime";
var Checkbox = React12.forwardRef(({ className, variant = "primary", secondaryLabel, ...props }, ref) => /* @__PURE__ */ jsxs11(
  Flex,
  {
    className: cx6(
      css12({
        alignItems: variant === "expanded" ? "center" : "flex-start",
        gap: "sm",
        p: variant === "expanded" ? "md" : "sm",
        pr: "md",
        width: variant === "expanded" ? "100%" : "fit-content",
        bg: variant === "secondary" ? "base.blueBrandSecondary" : "base.white",
        color: variant === "secondary" ? "base.blueBrandPrimary" : "base.darkBlue",
        rounded: variant === "expanded" ? "20px" : "sm",
        shadow: variant === "expanded" ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none"
      }),
      className
    ),
    children: [
      /* @__PURE__ */ jsx16(
        CheckboxPrimitive.Root,
        {
          className: cx6(
            css12({
              cursor: "pointer",
              h: "4",
              w: "4",
              rounded: "3px",
              borderWidth: "2px",
              _focusVisible: { ring: "none", ringOffset: "none", shadow: "1" },
              _disabled: { cursor: "not-allowed", opacity: "0.5" },
              _checked: {
                bg: variant === "secondary" ? "base.blueBrandPrimary" : "#117DA3",
                borderColor: variant === "secondary" ? "base.blueBrandPrimary" : "#117DA3",
                color: "white"
              }
            })
          ),
          ref,
          ...props,
          children: /* @__PURE__ */ jsx16(
            CheckboxPrimitive.Indicator,
            {
              className: cx6(
                css12({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "current"
                })
              ),
              children: /* @__PURE__ */ jsx16(FontAwesomeIcon8, { icon: faCheck, size: "xs" })
            }
          )
        }
      ),
      variant === "expanded" ? /* @__PURE__ */ jsxs11(Flex, { direction: "column", children: [
        /* @__PURE__ */ jsx16(
          "label",
          {
            className: css12({
              textStyle: "label_16",
              lineHeight: "none",
              fontWeight: "bold",
              _peerDisabled: { cursor: "not-allowed", opacity: "0.7" }
            }),
            htmlFor: props.id,
            children: props.label
          }
        ),
        secondaryLabel ? /* @__PURE__ */ jsx16(
          Box4,
          {
            css: {
              textStyle: "label_14",
              color: "base.gray",
              mt: "2px"
            },
            children: secondaryLabel
          }
        ) : null
      ] }) : /* @__PURE__ */ jsx16(
        "label",
        {
          className: css12({
            textStyle: "label_16",
            lineHeight: "none",
            fontWeight: "medium",
            _peerDisabled: { cursor: "not-allowed", opacity: "0.7" }
          }),
          htmlFor: props.id,
          children: props.label
        }
      )
    ]
  }
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

// src/CircularCounter/CircularCounter.tsx
import { Box as Box5 } from "@doowii/design-system/jsx";

// src/Label/Label.tsx
import { cva as cva5, cx as cx7 } from "@doowii/design-system/css";
import { jsx as jsx17 } from "react/jsx-runtime";
var labelRecipe = cva5({
  base: {
    fontWeight: 500
  },
  variants: {
    level: {
      1: {
        textStyle: "label_14"
      },
      2: {
        textStyle: "label_16"
      },
      3: {
        textStyle: "label_18"
      },
      4: {
        textStyle: "label_20"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Label = ({
  level,
  variant,
  children,
  ...props
}) => /* @__PURE__ */ jsx17(
  "label",
  {
    ...props,
    className: cx7(labelRecipe({ level, variant }), props.className),
    children
  }
);

// src/CircularCounter/CircularCounter.tsx
import { jsx as jsx18 } from "react/jsx-runtime";
var circleStyle2 = {
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid transparent",
  position: "relative",
  overflow: "hidden",
  "&:after": {
    content: "''",
    position: "absolute",
    inset: 0,
    borderRadius: "50%",
    padding: "3px",
    width: "full",
    height: "full",
    background: "linear-gradient(97.52deg, #F6CD3C 47.37%, #29BDF0 65.41%)",
    "-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    "-webkit-mask-composite": "xor",
    "mask-composite": "exclude",
    "pointer-events": "none"
  }
};
var CircularCounter = ({ counter }) => /* @__PURE__ */ jsx18(Box5, { css: circleStyle2, children: /* @__PURE__ */ jsx18(Label, { level: counter.toString().length > 2 ? 1 : 2, children: counter }) });

// src/ConfirmationDialog/ConfirmationDialog.tsx
import { css as css15 } from "@doowii/design-system/css";
import { Flex as Flex2 } from "@doowii/design-system/jsx";
import { DialogTitle as DialogTitle2 } from "@radix-ui/react-dialog";

// ../../node_modules/@radix-ui/react-visually-hidden/dist/index.mjs
import * as React14 from "react";

// ../../node_modules/@radix-ui/react-primitive/dist/index.mjs
import * as React13 from "react";
import * as ReactDOM from "react-dom";
import { jsx as jsx19 } from "react/jsx-runtime";
var NODES = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
];
var Primitive = NODES.reduce((primitive, node) => {
  const Node2 = React13.forwardRef((props, forwardedRef) => {
    const { asChild, ...primitiveProps } = props;
    const Comp = asChild ? Slot : node;
    if (typeof window !== "undefined") {
      window[Symbol.for("radix-ui")] = true;
    }
    return /* @__PURE__ */ jsx19(Comp, { ...primitiveProps, ref: forwardedRef });
  });
  Node2.displayName = `Primitive.${node}`;
  return { ...primitive, [node]: Node2 };
}, {});

// ../../node_modules/@radix-ui/react-visually-hidden/dist/index.mjs
import { jsx as jsx20 } from "react/jsx-runtime";
var NAME = "VisuallyHidden";
var VisuallyHidden = React14.forwardRef(
  (props, forwardedRef) => {
    return /* @__PURE__ */ jsx20(
      Primitive.span,
      {
        ...props,
        ref: forwardedRef,
        style: {
          // See: https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
          position: "absolute",
          border: 0,
          width: 1,
          height: 1,
          padding: 0,
          margin: -1,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          wordWrap: "normal",
          ...props.style
        }
      }
    );
  }
);
VisuallyHidden.displayName = NAME;
var Root7 = VisuallyHidden;

// src/Dialog/Dialog.tsx
import { css as css13, cx as cx8, sva as sva2 } from "@doowii/design-system/css";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon9 } from "@fortawesome/react-fontawesome";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React15 from "react";
import { jsx as jsx21, jsxs as jsxs12 } from "react/jsx-runtime";
var dialogSlotRecipe = sva2({
  className: "dialog",
  slots: [
    "root",
    "trigger",
    "portal",
    "overlay",
    "close",
    "content",
    "header",
    "body",
    "footer",
    "title",
    "description",
    "actionButtons"
  ],
  base: {
    overlay: {
      position: "fixed",
      inset: "0",
      zIndex: 50,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      // Darker background
      animation: "overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)"
    },
    close: {
      position: "absolute",
      right: "6",
      top: "5",
      rounded: "sm",
      transition: "opacity",
      cursor: "pointer",
      _hover: {
        opacity: "1"
      },
      _focus: {
        outline: "2px solid transparent",
        outlineOffset: "2px"
      },
      _disabled: {
        pointerEvents: "none"
      }
    },
    content: {
      position: "fixed",
      left: "50%",
      top: "50%",
      zIndex: 50,
      display: "flex",
      flexDirection: "column",
      w: "lg",
      maxHeight: "85vh",
      // Limit the maximum height
      transform: "translate(-50%, -50%)",
      border: "base",
      bg: "background",
      boxShadow: "lg",
      animation: "contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      "&:focus": { outline: "none" },
      overflow: "hidden",
      rounded: "lg"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      paddingX: "6",
      paddingY: "4",
      borderBottom: "1px solid",
      borderColor: "gray.200",
      flexShrink: 0
      // Prevent header from shrinking
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textStyle: "lg",
      fontWeight: "semibold",
      marginBottom: "2",
      paddingRight: "8"
    },
    description: {
      textStyle: "sm",
      color: "muted.foreground"
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      spaceX: "2",
      cursor: "pointer"
    },
    body: {
      flex: "1 1 auto",
      overflowY: "auto",
      padding: "6"
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingX: "6",
      paddingY: "6",
      borderTop: "1px solid",
      borderColor: "gray.200",
      flexShrink: 0,
      // Prevent footer from shrinking
      spaceX: "2",
      sm: {
        flexDirection: "row",
        justifyContent: "flex-end"
      }
    }
  },
  variants: {
    variant: {
      default: {},
      slideRight: {
        content: {
          left: "auto",
          right: "0",
          top: "0",
          bottom: "0",
          maxWidth: "80%",
          height: "100%",
          maxHeight: "100vh",
          transform: "none",
          animation: "slideInFromRight 300ms cubic-bezier(0.16, 1, 0.3, 1)"
        }
      },
      fullScreen: {
        content: {
          position: "fixed",
          top: "50%",
          left: "50%",
          maxHeight: "85vh",
          maxWidth: "85vw",
          width: "max-content"
        }
      }
    }
  }
});
var Dialog = DialogPrimitive.Root;
var DialogTrigger = DialogPrimitive.Trigger;
var DialogPortal = DialogPrimitive.Portal;
var DialogOverlay = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  DialogPrimitive.Overlay,
  {
    className: cx8(dialogSlotRecipe().overlay, className),
    ref,
    ...props
  }
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
var DialogClose = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  DialogPrimitive.Close,
  {
    className: cx8(dialogSlotRecipe().close, className),
    ref,
    ...props
  }
));
DialogClose.displayName = DialogPrimitive.Close.displayName;
var DialogContent = React15.forwardRef(({ children, className, variant = "default", ...props }, ref) => {
  const styles = dialogSlotRecipe({ variant });
  return /* @__PURE__ */ jsxs12(DialogPortal, { children: [
    /* @__PURE__ */ jsx21(DialogOverlay, {}),
    /* @__PURE__ */ jsxs12(
      DialogPrimitive.Content,
      {
        className: cx8(styles.content, className),
        ref,
        ...props,
        children: [
          children,
          /* @__PURE__ */ jsxs12(DialogClose, { children: [
            /* @__PURE__ */ jsx21(FontAwesomeIcon9, { icon: faXmark, size: "lg" }),
            /* @__PURE__ */ jsx21("span", { className: css13({ srOnly: true }), children: "Close" })
          ] })
        ]
      }
    )
  ] });
});
DialogContent.displayName = DialogPrimitive.Content.displayName;
var DialogHeader = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  "div",
  {
    className: cx8(dialogSlotRecipe().header, className),
    ref,
    ...props
  }
));
DialogHeader.displayName = "DialogHeader";
var DialogFooter = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  "div",
  {
    className: cx8(dialogSlotRecipe().footer, className),
    ref,
    ...props
  }
));
DialogFooter.displayName = "DialogFooter";
var DialogTitle = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  DialogPrimitive.Title,
  {
    className: cx8(dialogSlotRecipe().title, className),
    ref,
    ...props
  }
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;
var DialogDescription = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  DialogPrimitive.Description,
  {
    className: cx8(dialogSlotRecipe().description, className),
    ref,
    ...props
  }
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;
var DialogBody = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  "div",
  {
    className: cx8(dialogSlotRecipe().body, className),
    ref,
    ...props
  }
));
DialogBody.displayName = "DialogBody";
var DialogActionButtons = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx21(
  "div",
  {
    className: cx8(dialogSlotRecipe().actionButtons, className),
    ref,
    ...props
  }
));
DialogActionButtons.displayName = "DialogActionButtons";

// src/Heading/Heading.tsx
import { css as css14, cva as cva6 } from "@doowii/design-system/css";
import { jsx as jsx22 } from "react/jsx-runtime";
var headingRecipe = cva6({
  base: {
    fontWeight: 600
  },
  variants: {
    level: {
      1: {
        textStyle: "title_h1"
      },
      2: {
        textStyle: "title_h2"
      },
      3: {
        textStyle: "title_h3"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Heading = ({
  level = 2,
  variant,
  as,
  children,
  css: cssProp,
  component,
  ...props
}) => {
  const Tag = as ?? `h${level}`;
  const RenderComponent = component ?? Tag;
  return /* @__PURE__ */ jsx22(
    RenderComponent,
    {
      className: css14(headingRecipe.raw({ level, variant }), cssProp),
      ...props,
      children
    }
  );
};

// src/Text/Text.tsx
import { cva as cva7, cx as cx9 } from "@doowii/design-system/css";
import { jsx as jsx23 } from "react/jsx-runtime";
var textRecipe = cva7({
  base: {},
  variants: {
    level: {
      1: {
        textStyle: "paragraph_12"
      },
      2: {
        textStyle: "paragraph_14"
      },
      3: {
        textStyle: "paragraph_16"
      },
      4: {
        textStyle: "paragraph_18"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Text = ({
  level,
  variant,
  className,
  children,
  ...props
}) => /* @__PURE__ */ jsx23("p", { className: cx9(textRecipe({ level, variant }), className), ...props, children });

// src/ConfirmationDialog/ConfirmationDialog.tsx
import { jsx as jsx24, jsxs as jsxs13 } from "react/jsx-runtime";
var ConfirmationDialog = ({
  isOpen,
  title,
  description,
  confirmationButtonText,
  cancelButtonText,
  isLoadingAction,
  icon,
  destructive,
  setIsOpen,
  onConfirm,
  onCancel
}) => /* @__PURE__ */ jsx24(Dialog, { onOpenChange: setIsOpen, open: isOpen, children: /* @__PURE__ */ jsxs13(DialogContent, { onClick: (e) => e.stopPropagation(), variant: "default", children: [
  /* @__PURE__ */ jsx24(Root7, { children: /* @__PURE__ */ jsx24(DialogTitle2, { children: "Confirmation" }) }),
  /* @__PURE__ */ jsx24(DialogBody, { children: /* @__PURE__ */ jsxs13(
    Flex2,
    {
      css: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "md"
      },
      children: [
        icon ? /* @__PURE__ */ jsx24(CircularIcon, { icon }) : null,
        /* @__PURE__ */ jsxs13(
          Flex2,
          {
            css: {
              alignItems: "center",
              flexDirection: "column",
              gap: "xs"
            },
            children: [
              /* @__PURE__ */ jsx24(Heading, { level: 2, children: title }),
              description ? /* @__PURE__ */ jsx24(Text, { level: 3, children: description }) : null
            ]
          }
        )
      ]
    }
  ) }),
  /* @__PURE__ */ jsxs13(
    DialogFooter,
    {
      className: css15({
        display: "flex",
        justifyContent: "center!",
        border: "none",
        p: "xl2",
        gap: "sm"
      }),
      children: [
        /* @__PURE__ */ jsx24(
          Button,
          {
            destructive,
            loading: isLoadingAction,
            onClick: onConfirm,
            size: "large",
            variant: "primary",
            children: confirmationButtonText ?? "Confirm"
          }
        ),
        /* @__PURE__ */ jsx24(
          Button,
          {
            onClick: (e) => onCancel ? onCancel(e) : setIsOpen(false),
            size: "large",
            variant: "secondary",
            children: cancelButtonText ?? "Cancel"
          }
        )
      ]
    }
  )
] }) });

// src/Dropdown/Dropdown.tsx
import { css as css16, cx as cx10 } from "@doowii/design-system/css";
import {
  faCheck as faCheck2,
  faChevronRight as faChevronRight2,
  faPeriod
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon10 } from "@fortawesome/react-fontawesome";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import * as React16 from "react";
import { jsx as jsx25, jsxs as jsxs14 } from "react/jsx-runtime";
var DropdownMenu = DropdownMenuPrimitive.Root;
var DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
var DropdownMenuGroup = DropdownMenuPrimitive.Group;
var DropdownMenuPortal = DropdownMenuPrimitive.Portal;
var DropdownMenuSub = DropdownMenuPrimitive.Sub;
var DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
var DropdownMenuSubTrigger = React16.forwardRef(({ className, inset, children, ...props }, ref) => /* @__PURE__ */ jsxs14(
  DropdownMenuPrimitive.SubTrigger,
  {
    className: cx10(
      css16({
        display: "flex",
        cursor: "default",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "3",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      inset && css16({ pl: "8" }),
      className
    ),
    ref,
    ...props,
    children: [
      children,
      /* @__PURE__ */ jsx25(
        FontAwesomeIcon10,
        {
          className: css16({ ml: "auto", h: "4", w: "4" }),
          icon: faChevronRight2,
          size: "xs"
        }
      )
    ]
  }
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
var DropdownMenuSubContent = React16.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx25(
  DropdownMenuPrimitive.SubContent,
  {
    className: cx10(
      css16({
        zIndex: 50,
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        p: "1",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white",
        color: "foreground.base",
        textStyle: "paragraph_16",
        ml: "2",
        maxH: "25rem",
        overflowY: "auto"
      }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
var DropdownMenuContent = React16.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx25(DropdownMenuPrimitive.Portal, { children: /* @__PURE__ */ jsx25(
  DropdownMenuPrimitive.Content,
  {
    className: cx10(
      css16({
        zIndex: 50,
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        p: "1",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white",
        color: "foreground.base",
        textStyle: "paragraph_16"
      }),
      className
    ),
    ref,
    sideOffset,
    ...props
  }
) }));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
var DropdownMenuItem = React16.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx25(
  DropdownMenuPrimitive.Item,
  {
    className: cx10(
      css16({
        pos: "relative",
        display: "flex",
        gap: "4",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      inset && css16({ pl: "8" }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
var DropdownMenuCheckboxItem = React16.forwardRef(({ className, children, checked, ...props }, ref) => /* @__PURE__ */ jsxs14(
  DropdownMenuPrimitive.CheckboxItem,
  {
    checked,
    className: cx10(
      css16({
        pos: "relative",
        display: "flex",
        gap: "2",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx25(
        FontAwesomeIcon10,
        {
          className: css16({ h: "4", w: "4" }),
          icon: faCheck2,
          size: "xs"
        }
      ),
      children
    ]
  }
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
var DropdownMenuRadioItem = React16.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs14(
  DropdownMenuPrimitive.RadioItem,
  {
    className: cx10(
      css16({
        pos: "relative",
        display: "flex",
        gap: "2",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx25(
        FontAwesomeIcon10,
        {
          className: css16({ h: "4", w: "4", mb: "3" }),
          icon: faPeriod
        }
      ),
      children
    ]
  }
));
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
var DropdownMenuLabel = React16.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx25(
  DropdownMenuPrimitive.Label,
  {
    className: cx10(
      css16({
        pl: "2",
        pr: "2",
        pt: "1.5",
        pb: "1.5",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "semibold"
      }),
      inset && css16({ pl: "8" }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
var DropdownMenuSeparator = React16.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx25(
  DropdownMenuPrimitive.Separator,
  {
    className: cx10(
      css16({
        ml: ".mx-1",
        mr: ".mx-1",
        mt: "1",
        mb: "1",
        h: "1px",
        bg: "base.lightGray"
      }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
var DropdownMenuShortcut = ({
  className,
  ...props
}) => /* @__PURE__ */ jsx25(
  "span",
  {
    className: cx10(
      css16({
        ml: "auto",
        fontSize: "xs",
        letterSpacing: "widest",
        opacity: "0.6"
      }),
      className
    ),
    ...props
  }
);
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

// src/FeedbackButton/FeedbackButton.tsx
import { cva as cva8 } from "@doowii/design-system/css";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid
} from "@fortawesome/pro-solid-svg-icons";
import React17, { useState } from "react";
import { jsx as jsx26 } from "react/jsx-runtime";
var feedBackButtonRecipe = cva8({
  variants: {
    type: {
      positive: {
        color: "complementary.greenPrimary",
        backgroundColor: "white",
        _hover: {
          color: "complementary.greenPrimary",
          backgroundColor: "complementary.greenSecondary"
        }
      },
      negative: {
        color: "complementary.destructivePrimary",
        backgroundColor: "white",
        _hover: {
          color: "complementary.destructivePrimary",
          backgroundColor: "complementary.destructiveSecondary"
        }
      }
    },
    isActive: { true: {} }
  },
  compoundVariants: [
    {
      type: "positive",
      isActive: true,
      css: {
        color: "complementary.greenPrimary",
        backgroundColor: "complementary.greenSecondary"
      }
    },
    {
      type: "negative",
      isActive: true,
      css: {
        color: "complementary.destructivePrimary",
        backgroundColor: "complementary.destructiveSecondary"
      }
    }
  ]
});
var FeedbackButton = React17.forwardRef(
  ({ type, isActive, label, onClick }, ref) => {
    const [isHover, setIsHover] = useState(false);
    const positiveIcon = isHover || isActive ? faThumbsUpSolid : faThumbsUp;
    const negativeIcon = isHover || isActive ? faThumbsDownSolid : faThumbsDown;
    return /* @__PURE__ */ jsx26(Tooltip, { content: label, children: /* @__PURE__ */ jsx26(
      Button,
      {
        "aria-label": label,
        className: feedBackButtonRecipe({ type, isActive }),
        iconOnly: type === "positive" ? positiveIcon : negativeIcon,
        onClick,
        onMouseEnter: () => setIsHover(true),
        onMouseLeave: () => setIsHover(false),
        ref
      }
    ) });
  }
);
FeedbackButton.displayName = "FeedbackButton";

// src/FollowUpCard/FollowUpCard.tsx
import { css as css17, cx as cx11 } from "@doowii/design-system/css";
import { flex } from "@doowii/design-system/patterns";
import { faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon11 } from "@fortawesome/react-fontawesome";
import { jsx as jsx27, jsxs as jsxs15 } from "react/jsx-runtime";
var followUpCardStyle = cx11(
  flex({
    gap: "ld",
    direction: "row",
    align: "center",
    justify: "center"
  }),
  css17({
    bg: "base.blueBrandTertiary",
    p: "md",
    rounded: "xl",
    cursor: "pointer",
    w: "full",
    textAlign: "left",
    color: "base.darkBlue",
    _hover: {
      bg: "base.white"
    }
  })
);
var followUpCardTextStyle = cx11(
  flex({
    grow: 1
  }),
  css17({
    textStyle: "paragraph_16"
  })
);
var followUpCardIconStyle = cx11(
  flex({
    align: "center",
    justify: "center",
    shrink: 0
  }),
  css17({
    w: "32px",
    h: "32px"
  })
);
var FollowUpCard = ({ onClick, children }) => /* @__PURE__ */ jsxs15("button", { className: followUpCardStyle, onClick, type: "button", children: [
  /* @__PURE__ */ jsx27("div", { className: followUpCardTextStyle, children }),
  /* @__PURE__ */ jsx27("div", { className: followUpCardIconStyle, children: /* @__PURE__ */ jsx27(FontAwesomeIcon11, { icon: faPaperPlaneTop }) })
] });

// src/InputField/InputField.tsx
import { css as css18, cva as cva9 } from "@doowii/design-system/css";
import { Box as Box6, Flex as Flex3 } from "@doowii/design-system/jsx";
import { forwardRef as forwardRef12, useId, useMemo, useState as useState2 } from "react";
import { jsx as jsx28, jsxs as jsxs16 } from "react/jsx-runtime";
var DEFAULT_INPUT_SIZE = 20;
var inputFieldRecipe = cva9({
  base: {
    color: "foreground.base",
    outline: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "border.base",
    fontWeight: 500
  },
  variants: {
    size: {
      small: {
        padding: "sm",
        paddingRight: "xl3",
        textStyle: "paragraph_14",
        borderRadius: "sm"
      },
      medium: {
        padding: "md",
        paddingRight: "xl3",
        textStyle: "paragraph_16",
        borderRadius: "md"
      },
      large: {
        padding: "ld",
        paddingRight: "xl3",
        textStyle: "paragraph_18",
        borderRadius: "lg"
      }
    },
    disabled: {
      true: {
        backgroundColor: "background.disabled",
        borderColor: "border.disabled"
      }
    },
    error: {
      true: {
        borderColor: "border.error",
        "&:focus": {
          borderColor: "border.error"
        }
      }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
var InputField = forwardRef12(
  ({
    label,
    value,
    size: size4,
    defaultValue,
    onChange,
    helperText,
    errorText,
    disabled,
    icon,
    inputSize,
    id: propId,
    ...props
  }, ref) => {
    const [internalValue, setInternalValue] = useState2(defaultValue ?? "");
    const id = useId();
    const inputId = propId ?? `input-${id}`;
    const handleChange = (e) => {
      onChange?.(e);
      if (value === void 0) {
        setInternalValue(e.target.value);
      }
    };
    const ariaDescribedBy = useMemo(() => {
      const describedBy = [];
      if (errorText) {
        describedBy.push(`${inputId}-error`);
      }
      if (helperText) {
        describedBy.push(`${inputId}-helper`);
      }
      return describedBy.join(" ");
    }, [errorText, helperText, inputId]);
    return /* @__PURE__ */ jsxs16(Flex3, { direction: "column", gap: "sm", children: [
      label.length > 0 ? /* @__PURE__ */ jsx28(Label, { htmlFor: inputId, level: 1, children: label }) : null,
      /* @__PURE__ */ jsxs16(
        Box6,
        {
          className: css18({
            display: "flex",
            alignItems: "center",
            position: "relative"
          }),
          children: [
            /* @__PURE__ */ jsx28(
              "input",
              {
                "aria-describedby": ariaDescribedBy || void 0,
                "aria-invalid": Boolean(errorText),
                className: inputFieldRecipe({
                  size: size4,
                  disabled,
                  error: Boolean(errorText)
                }),
                disabled,
                id: inputId,
                onChange: handleChange,
                ref,
                value: value ?? internalValue,
                ...props,
                size: inputSize ?? DEFAULT_INPUT_SIZE
              }
            ),
            icon ? /* @__PURE__ */ jsx28(
              Box6,
              {
                className: css18({
                  position: "absolute",
                  right: "md",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }),
                children: icon
              }
            ) : null,
            errorText ? /* @__PURE__ */ jsx28(
              "p",
              {
                className: css18({
                  position: "absolute",
                  bottom: "-ld",
                  margin: 0,
                  textStyle: "paragraph_12",
                  color: "foreground.error"
                }),
                id: `${inputId}-error`,
                children: errorText
              }
            ) : null,
            helperText ? /* @__PURE__ */ jsx28(
              "p",
              {
                className: css18({
                  position: "absolute",
                  bottom: errorText ? "-xl2" : "-ld",
                  margin: 0,
                  textStyle: "paragraph_12"
                }),
                id: `${inputId}-helper`,
                children: helperText
              }
            ) : null
          ]
        }
      )
    ] });
  }
);
InputField.displayName = "InputField";

// src/Loading/LoadingLogo.tsx
import { css as css19, cx as cx12 } from "@doowii/design-system/css";
import { jsx as jsx29, jsxs as jsxs17 } from "react/jsx-runtime";
var loadingPageStyles = css19({
  fontSize: "5em",
  textAlign: "center",
  fontWeight: 600,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1
});
var bounceStyles = css19({
  display: "inline-block",
  animation: "bounce 0.6s infinite alternate"
});
var LoadingLogo = () => /* @__PURE__ */ jsx29("div", { className: loadingPageStyles, children: /* @__PURE__ */ jsxs17(
  "svg",
  {
    fill: "none",
    height: "120",
    viewBox: "0 0 334 120",
    width: "334",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx29(
        "path",
        {
          className: cx12(bounceStyles),
          d: "M300.167 26.457C303.339 26.457 305.819 28.8014 305.819 31.8337C305.819 34.8659 303.337 37.1435 300.167 37.1435C296.996 37.1435 294.652 34.8682 294.652 31.8337C294.652 28.7991 296.996 26.457 300.167 26.457Z",
          fill: "#F5CB62",
          style: { animationDelay: "0.23s" }
        }
      ),
      /* @__PURE__ */ jsx29(
        "path",
        {
          className: cx12(bounceStyles),
          d: "M275.454 26.457C278.627 26.457 281.107 28.8014 281.107 31.8337C281.107 34.8659 278.625 37.1435 275.454 37.1435C272.284 37.1435 269.94 34.8682 269.94 31.8337C269.94 28.7991 272.284 26.457 275.454 26.457Z",
          fill: "#F5CB62",
          style: { animationDelay: "0.2s" }
        }
      ),
      /* @__PURE__ */ jsx29(
        "path",
        {
          className: bounceStyles,
          d: "M127.61 49.0186C123.012 44.7106 116.832 42.2305 110.456 42.2305C103.349 42.2305 96.993 44.8464 92.4156 49.2457C87.8382 53.6427 85.0442 59.8275 85.0464 66.9028C85.0642 80.0405 96.6879 91.1522 109.801 91.1655C124.624 91.1655 135.043 79.5195 135.21 67.0743V66.7692C135.215 59.478 132.207 53.3243 127.61 49.0186ZM99.3596 55.7644C102.156 52.9681 105.97 51.2405 110.046 51.2427C118.626 51.2427 125.459 58.082 125.461 66.5756C125.457 75.3963 118.468 82.1466 109.883 82.1533C105.774 82.1533 102.004 80.3522 99.2549 77.5069C96.5076 74.6616 94.7956 70.7811 94.7978 66.6579C94.7978 62.4145 96.5655 58.5629 99.3618 55.7666L99.3596 55.7644Z",
          fill: "#053946",
          style: { animationDelay: "0s" }
        }
      ),
      /* @__PURE__ */ jsx29(
        "path",
        {
          d: "M78.3027 27.7148H68.5535V47.28C64.2633 44.0718 58.9914 42.2306 53.5479 42.2306C46.4415 42.2306 40.0852 44.8466 35.5079 49.2458C30.9305 53.6429 28.1364 59.8276 28.1387 66.903C28.1565 80.0406 39.7802 91.1523 52.8934 91.1657C66.9505 91.1657 77.0314 80.6885 78.178 68.9913L78.3027 67.0388C78.3027 67.0388 78.3027 67.0366 78.3027 67.0343V27.7148ZM42.4518 55.7646C45.2481 52.9683 49.0619 51.2406 53.1383 51.2429C61.7186 51.2429 68.5513 58.0822 68.5535 66.5757C68.5491 75.3965 61.5606 82.1468 52.9758 82.1534C48.8659 82.1534 45.0967 80.3523 42.3472 77.5071C39.5999 74.6618 37.8878 70.7813 37.8901 66.6581C37.8901 62.4147 39.6578 58.5631 42.4541 55.7668L42.4518 55.7646Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx29(
        "path",
        {
          className: bounceStyles,
          d: "M182.827 49.0186C178.23 44.7106 172.05 42.2305 165.673 42.2305C158.567 42.2305 152.211 44.8464 147.633 49.2457C143.056 53.6427 140.262 59.8275 140.264 66.9028C140.282 80.0405 151.906 91.1522 165.019 91.1655C179.842 91.1655 190.261 79.5195 190.428 67.0743V66.7692C190.433 59.478 187.425 53.3243 182.827 49.0186ZM154.577 55.7644C157.374 52.9681 161.187 51.2405 165.264 51.2427C173.844 51.2427 180.677 58.082 180.679 66.5756C180.675 75.3963 173.686 82.1466 165.101 82.1533C160.991 82.1533 157.222 80.3522 154.473 77.5069C151.725 74.6616 150.013 70.7811 150.016 66.6579C150.016 62.4145 151.783 58.5629 154.58 55.7666L154.577 55.7644Z",
          fill: "#053946",
          style: { animationDelay: "0.1s" }
        }
      ),
      /* @__PURE__ */ jsx29(
        "path",
        {
          d: "M280.403 43.4961H279.782H270.654V90.7992H280.403V43.4961Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx29(
        "path",
        {
          d: "M305.561 43.4961H304.94H295.812V90.7992H305.561V43.4961Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx29(
        "path",
        {
          d: "M252.316 43.3242H251.697L240.528 74.9962L232.172 43.3242H232.059H231.437H223.042H222.423H222.309L213.954 74.9962L202.784 43.3242H202.163H193.035L209.715 90.6274H209.828H219.464H219.578L227.241 61.5869L234.902 90.6274H235.015H244.651H244.764L261.446 43.3242H252.316Z",
          fill: "#053946"
        }
      )
    ]
  }
) });

// src/LogoLoader/LogoLoader.tsx
import { css as css20, cx as cx13 } from "@doowii/design-system/css";
import { useEffect, useRef } from "react";
import { Fragment as Fragment2, jsx as jsx30, jsxs as jsxs18 } from "react/jsx-runtime";
var containerStyles = css20({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%"
});
var sizeStyles = {
  sm: css20({
    width: "100px",
    height: "100px"
  }),
  md: css20({
    width: "200px",
    height: "200px"
  }),
  lg: css20({
    width: "300px",
    height: "300px"
  })
};
var svgStyles = css20({
  width: "100%",
  height: "100%"
});
var lineStyles = css20({
  fill: "none",
  strokeWidth: "25",
  strokeLinecap: "round"
});
var line1Styles = css20({
  stroke: "#2d7e9a",
  animation: `growLine 3s infinite forwards ease-in-out 0.5s`
});
var line2Styles = css20({
  stroke: "#f4cb61",
  animation: `growLine 3s infinite forwards ease-in-out 0.5s`
});
var LogoLoader = ({
  size: size4 = "md",
  variant,
  progress = 0
}) => {
  const topRectRef = useRef(null);
  const bottomRectRef = useRef(null);
  const line1Ref = useRef(null);
  const line2Ref = useRef(null);
  useEffect(() => {
    if (variant === "progress" && topRectRef.current && bottomRectRef.current) {
      const topRect = topRectRef.current;
      const bottomRect = bottomRectRef.current;
      if (progress <= 50) {
        const topWidth = progress / 50 * 5250;
        topRect.setAttribute("width", topWidth.toString());
        bottomRect.setAttribute("width", "0");
        bottomRect.setAttribute("x", "5250");
      } else {
        topRect.setAttribute("width", "5250");
        const bottomWidth = (progress - 50) / 50 * 5250;
        bottomRect.setAttribute("width", bottomWidth.toString());
        bottomRect.setAttribute("x", (5250 - bottomWidth).toString());
      }
    }
  }, [variant, progress]);
  useEffect(() => {
    if (line1Ref.current && line2Ref.current) {
      const line1 = line1Ref.current;
      const line2 = line2Ref.current;
      const length1 = line1.getTotalLength();
      const length2 = line2.getTotalLength();
      line1.style.strokeDasharray = `${length1}`;
      line1.style.strokeDashoffset = `${length1}`;
      line2.style.strokeDasharray = `${length2}`;
      line2.style.strokeDashoffset = `${length2}`;
    }
  }, [variant]);
  const sizeClass = sizeStyles[size4];
  return /* @__PURE__ */ jsx30("div", { className: cx13(containerStyles, sizeClass), children: /* @__PURE__ */ jsx30("svg", { className: svgStyles, viewBox: "0 0 5250 5250", children: variant === "progress" ? /* @__PURE__ */ jsxs18(Fragment2, { children: [
    /* @__PURE__ */ jsxs18("defs", { children: [
      /* @__PURE__ */ jsx30("mask", { id: "topMask", children: /* @__PURE__ */ jsx30(
        "rect",
        {
          fill: "white",
          height: "5250",
          ref: topRectRef,
          width: "0",
          x: "0",
          y: "0"
        }
      ) }),
      /* @__PURE__ */ jsx30("mask", { id: "bottomMask", children: /* @__PURE__ */ jsx30(
        "rect",
        {
          fill: "white",
          height: "5250",
          ref: bottomRectRef,
          width: "0",
          x: "5250",
          y: "0"
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        d: "M 2460.5,-0.5 C 2499.17,-0.5 2537.83,-0.5 2576.5,-0.5C 3031.49,12.7921 3453.16,137.292 3841.5,373C 3956.78,443.809 4065.45,523.643 4167.5,612.5C 4072.41,630.599 3996.58,678.599 3940,756.5C 3928.33,774.99 3916.5,793.324 3904.5,811.5C 3581.05,551.055 3212.05,393.222 2797.5,338C 2340.56,283.265 1907.89,361.599 1499.5,573C 1172.11,746.028 903.942,985.861 695,1292.5C 464.494,1639.04 340.16,2021.04 322,2438.5C 318.135,2546.11 321.968,2653.45 333.5,2760.5C 211.938,2745.46 108.938,2781.79 24.5,2869.5C 9.93922,2767.87 1.60589,2665.87 -0.5,2563.5C -0.5,2534.5 -0.5,2505.5 -0.5,2476.5C 9.89759,2033.54 126.398,1620.87 349,1238.5C 579.765,852.067 890.598,547.567 1281.5,325C 1647.65,120.041 2040.65,11.541 2460.5,-0.5 Z",
        fill: "#053946",
        mask: "url(#topMask)",
        style: { opacity: 0.997 }
      }
    ) }),
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        className: cx13(lineStyles, line1Styles),
        d: "M 189 3294 C 127 3274 103.249 3230.75 81 3160 C 67.9277 3071.04 91.4277 3003.21 153.5 2949 C 199.771 2913.01 252.104 2898.68 310.5 2906 C 513.901 2953.35 717.234 3001.02 920.5 3049 C 970.095 3056.85 1014.43 3045.19 1053.5 3014 C 1406.5 2739 1759.5 2464 2112.5 2189 C 2177.47 2142.53 2247.14 2134.2 2321.5 2164 C 2335.95 2170.8 2349.61 2178.8 2362.5 2188 C 2453.17 2250 2543.83 2312 2634.5 2374 C 2689.6 2405.53 2745.26 2406.53 2801.5 2377 C 2824.95 2362.55 2844.12 2343.71 2859 2320.5 C 3060.67 1949.83 3262.33 1579.17 3464 1208.5 C 3510.28 1131.77 3578.44 1096.94 3668.5 1104 C 3698.71 1110.05 3728.71 1117.05 3758.5 1125 C 3824.64 1135.63 3878.81 1115.13 3921 1063.5 C 3966.4 991.766 4011.4 919.766 4056 847.5 C 4100.86 782.49 4163.02 751.657 4242.5 755 C 4301.39 759.939 4349.39 785.105 4392 829 C 4438.16 893.667 4450.33 962.667 4425 1038.5 C 4420.13 1050.56 4414.47 1062.23 4408 1073.5 C 4321.33 1222.17 4234.67 1370.83 4148 1519.5 C 4113.35 1572.44 4064.51 1603.6 4001.5 1613 C 3987.19 1614.46 3972.86 1614.8 3958.5 1614 C 3923.75 1608.52 3889.08 1602.52 3854.5 1596 C 3785.02 1591.15 3731.86 1617.99 3695 1676.5 C 3475.33 2086.5 3255.67 2496.5 3036 2906.5 C 2995.93 2974.63 2936.09 3010.3 2856.5 3013.5 C 2813.26 3012.92 2773.92 3000.42 2738.5 2976 C 2603.01 2881.09 2467.34 2786.42 2331.5 2692 C 2273.24 2659.82 2215.57 2660.82 2158.5 2695 C 1841.54 2947.62 1524.88 3200.62 1208.5 3454 C 1156.57 3493.39 1098.57 3506.39 1034.5 3493 C 765.841 3431.75 497.508 3369.09 229.5 3305 Z",
        ref: line1Ref
      }
    ) }),
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        className: cx13(lineStyles, line2Styles),
        d: "M 275 3680 C 267.02 3668.2 252.52 3636.7 263 3593.5 C 275.395 3556.22 301.229 3538.72 340.5 3541 C 665.167 3616.67 989.833 3692.33 1314.5 3768 C 1337.71 3769.93 1358.05 3763.26 1375.5 3748 C 1448.47 3671.36 1521.8 3595.03 1595.5 3519 C 1618.91 3503.59 1641.91 3504.26 1664.5 3521 C 1779.86 3634.7 1895.53 3748.03 2011.5 3861 C 2032.35 3874.41 2051.85 3872.57 2070 3855.5 C 2204.33 3664.83 2338.67 3474.17 2473 3283.5 C 2486.91 3263.45 2506.07 3251.95 2530.5 3249 C 2719.83 3245.9 2909.16 3242.9 3098.5 3240 C 3126.45 3236.95 3147.62 3223.45 3162 3199.5 C 3411.58 2769.34 3661.91 2339.67 3913 1910.5 C 3938.78 1881.93 3969.95 1874.1 4006.5 1887 C 4078.09 1911.86 4149.76 1936.53 4221.5 1961 C 4232.36 1963.38 4241.2 1960.21 4248 1951.5 C 4369.01 1741.48 4489.01 1530.82 4608 1319.5 C 4620.57 1302.1 4637.4 1291.43 4659 1287 C 4701.99 1284.84 4730.49 1304.17 4744 1345.5 C 4755.14 1382.06 4752.14 1417.4 4735 1451.5 C 4599.01 1690.15 4462.01 1928.15 4324 2165.5 C 4316.93 2173.61 4308.1 2176.44 4297.5 2174 C 4215.95 2141.39 4134.28 2109.06 4052.5 2077 C 4031.92 2072.08 4015.09 2077.91 4002 2094.5 C 3995.59 2102.31 3989.92 2110.65 3985 2119.5 C 3746.67 2534.83 3508.33 2950.17 3270 3365.5 C 3256.29 3385.18 3237.46 3396.68 3213.5 3400 C 3002.84 3409.73 2792.17 3419.39 2581.5 3429 C 2421.54 3654.41 2261.71 3879.91 2102 4105.5 C 2083.4 4124.69 2061.24 4130.52 2035.5 4123 C 2029.01 4120.5 2023.01 4117.17 2017.5 4113 C 1898.86 3993.36 1779.86 3874.03 1660.5 3755 C 1642.17 3742.33 1623.83 3742.33 1605.5 3755 C 1551.87 3812.3 1497.87 3869.3 1443.5 3926 C 1426.11 3939.35 1406.44 3945.02 1384.5 3943 C 1025.08 3858.12 665.75 3773.12 306.5 3688 Z",
        ref: line2Ref
      }
    ) }),
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        d: "M 5041.5,2452.5 C 5041.5,2496.83 5041.5,2541.17 5041.5,2585.5C 5027.32,3019.59 4911.49,3424.25 4694,3799.5C 4485.3,4150.87 4208.13,4436.04 3862.5,4655C 3474.38,4896.53 3051.38,5025.36 2593.5,5041.5C 2546.17,5041.5 2498.83,5041.5 2451.5,5041.5C 2024.56,5026.94 1625.89,4913.77 1255.5,4702C 897.248,4491.92 607.248,4211.09 385.5,3859.5C 386.167,3859.17 386.833,3858.83 387.5,3858.5C 548.26,3896.36 708.927,3934.52 869.5,3973C 1158.9,4300.15 1514.9,4523.15 1937.5,4642C 2295,4738.86 2654.34,4746.2 3015.5,4664C 3412.69,4568.64 3757.69,4380.97 4050.5,4101C 4366.21,3793.25 4572.71,3422.75 4670,2989.5C 4746.9,2631.76 4735.9,2276.76 4637,1924.5C 4636.33,1922.5 4636.33,1920.5 4637,1918.5C 4707.17,1796.17 4777.33,1673.83 4847.5,1551.5C 4967.64,1839.88 5032.31,2140.21 5041.5,2452.5 Z",
        fill: "#053946",
        mask: "url(#bottomMask)",
        style: { opacity: 0.997 }
      }
    ) })
  ] }) : /* @__PURE__ */ jsxs18(Fragment2, { children: [
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        d: "M 2460.5,-0.5 C 2499.17,-0.5 2537.83,-0.5 2576.5,-0.5C 3031.49,12.7921 3453.16,137.292 3841.5,373C 3956.78,443.809 4065.45,523.643 4167.5,612.5C 4072.41,630.599 3996.58,678.599 3940,756.5C 3928.33,774.99 3916.5,793.324 3904.5,811.5C 3581.05,551.055 3212.05,393.222 2797.5,338C 2340.56,283.265 1907.89,361.599 1499.5,573C 1172.11,746.028 903.942,985.861 695,1292.5C 464.494,1639.04 340.16,2021.04 322,2438.5C 318.135,2546.11 321.968,2653.45 333.5,2760.5C 211.938,2745.46 108.938,2781.79 24.5,2869.5C 9.93922,2767.87 1.60589,2665.87 -0.5,2563.5C -0.5,2534.5 -0.5,2505.5 -0.5,2476.5C 9.89759,2033.54 126.398,1620.87 349,1238.5C 579.765,852.067 890.598,547.567 1281.5,325C 1647.65,120.041 2040.65,11.541 2460.5,-0.5 Z",
        fill: "#053946",
        style: { opacity: 0.997 }
      }
    ) }),
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        className: cx13(lineStyles, line1Styles),
        d: "M 189 3294 C 127 3274 103.249 3230.75 81 3160 C 67.9277 3071.04 91.4277 3003.21 153.5 2949 C 199.771 2913.01 252.104 2898.68 310.5 2906 C 513.901 2953.35 717.234 3001.02 920.5 3049 C 970.095 3056.85 1014.43 3045.19 1053.5 3014 C 1406.5 2739 1759.5 2464 2112.5 2189 C 2177.47 2142.53 2247.14 2134.2 2321.5 2164 C 2335.95 2170.8 2349.61 2178.8 2362.5 2188 C 2453.17 2250 2543.83 2312 2634.5 2374 C 2689.6 2405.53 2745.26 2406.53 2801.5 2377 C 2824.95 2362.55 2844.12 2343.71 2859 2320.5 C 3060.67 1949.83 3262.33 1579.17 3464 1208.5 C 3510.28 1131.77 3578.44 1096.94 3668.5 1104 C 3698.71 1110.05 3728.71 1117.05 3758.5 1125 C 3824.64 1135.63 3878.81 1115.13 3921 1063.5 C 3966.4 991.766 4011.4 919.766 4056 847.5 C 4100.86 782.49 4163.02 751.657 4242.5 755 C 4301.39 759.939 4349.39 785.105 4392 829 C 4438.16 893.667 4450.33 962.667 4425 1038.5 C 4420.13 1050.56 4414.47 1062.23 4408 1073.5 C 4321.33 1222.17 4234.67 1370.83 4148 1519.5 C 4113.35 1572.44 4064.51 1603.6 4001.5 1613 C 3987.19 1614.46 3972.86 1614.8 3958.5 1614 C 3923.75 1608.52 3889.08 1602.52 3854.5 1596 C 3785.02 1591.15 3731.86 1617.99 3695 1676.5 C 3475.33 2086.5 3255.67 2496.5 3036 2906.5 C 2995.93 2974.63 2936.09 3010.3 2856.5 3013.5 C 2813.26 3012.92 2773.92 3000.42 2738.5 2976 C 2603.01 2881.09 2467.34 2786.42 2331.5 2692 C 2273.24 2659.82 2215.57 2660.82 2158.5 2695 C 1841.54 2947.62 1524.88 3200.62 1208.5 3454 C 1156.57 3493.39 1098.57 3506.39 1034.5 3493 C 765.841 3431.75 497.508 3369.09 229.5 3305 Z",
        ref: line1Ref
      }
    ) }),
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        className: cx13(lineStyles, line2Styles),
        d: "M 275 3680 C 267.02 3668.2 252.52 3636.7 263 3593.5 C 275.395 3556.22 301.229 3538.72 340.5 3541 C 665.167 3616.67 989.833 3692.33 1314.5 3768 C 1337.71 3769.93 1358.05 3763.26 1375.5 3748 C 1448.47 3671.36 1521.8 3595.03 1595.5 3519 C 1618.91 3503.59 1641.91 3504.26 1664.5 3521 C 1779.86 3634.7 1895.53 3748.03 2011.5 3861 C 2032.35 3874.41 2051.85 3872.57 2070 3855.5 C 2204.33 3664.83 2338.67 3474.17 2473 3283.5 C 2486.91 3263.45 2506.07 3251.95 2530.5 3249 C 2719.83 3245.9 2909.16 3242.9 3098.5 3240 C 3126.45 3236.95 3147.62 3223.45 3162 3199.5 C 3411.58 2769.34 3661.91 2339.67 3913 1910.5 C 3938.78 1881.93 3969.95 1874.1 4006.5 1887 C 4078.09 1911.86 4149.76 1936.53 4221.5 1961 C 4232.36 1963.38 4241.2 1960.21 4248 1951.5 C 4369.01 1741.48 4489.01 1530.82 4608 1319.5 C 4620.57 1302.1 4637.4 1291.43 4659 1287 C 4701.99 1284.84 4730.49 1304.17 4744 1345.5 C 4755.14 1382.06 4752.14 1417.4 4735 1451.5 C 4599.01 1690.15 4462.01 1928.15 4324 2165.5 C 4316.93 2173.61 4308.1 2176.44 4297.5 2174 C 4215.95 2141.39 4134.28 2109.06 4052.5 2077 C 4031.92 2072.08 4015.09 2077.91 4002 2094.5 C 3995.59 2102.31 3989.92 2110.65 3985 2119.5 C 3746.67 2534.83 3508.33 2950.17 3270 3365.5 C 3256.29 3385.18 3237.46 3396.68 3213.5 3400 C 3002.84 3409.73 2792.17 3419.39 2581.5 3429 C 2421.54 3654.41 2261.71 3879.91 2102 4105.5 C 2083.4 4124.69 2061.24 4130.52 2035.5 4123 C 2029.01 4120.5 2023.01 4117.17 2017.5 4113 C 1898.86 3993.36 1779.86 3874.03 1660.5 3755 C 1642.17 3742.33 1623.83 3742.33 1605.5 3755 C 1551.87 3812.3 1497.87 3869.3 1443.5 3926 C 1426.11 3939.35 1406.44 3945.02 1384.5 3943 C 1025.08 3858.12 665.75 3773.12 306.5 3688 Z",
        ref: line2Ref
      }
    ) }),
    /* @__PURE__ */ jsx30("g", { children: /* @__PURE__ */ jsx30(
      "path",
      {
        d: "M 5041.5,2452.5 C 5041.5,2496.83 5041.5,2541.17 5041.5,2585.5C 5027.32,3019.59 4911.49,3424.25 4694,3799.5C 4485.3,4150.87 4208.13,4436.04 3862.5,4655C 3474.38,4896.53 3051.38,5025.36 2593.5,5041.5C 2546.17,5041.5 2498.83,5041.5 2451.5,5041.5C 2024.56,5026.94 1625.89,4913.77 1255.5,4702C 897.248,4491.92 607.248,4211.09 385.5,3859.5C 386.167,3859.17 386.833,3858.83 387.5,3858.5C 548.26,3896.36 708.927,3934.52 869.5,3973C 1158.9,4300.15 1514.9,4523.15 1937.5,4642C 2295,4738.86 2654.34,4746.2 3015.5,4664C 3412.69,4568.64 3757.69,4380.97 4050.5,4101C 4366.21,3793.25 4572.71,3422.75 4670,2989.5C 4746.9,2631.76 4735.9,2276.76 4637,1924.5C 4636.33,1922.5 4636.33,1920.5 4637,1918.5C 4707.17,1796.17 4777.33,1673.83 4847.5,1551.5C 4967.64,1839.88 5032.31,2140.21 5041.5,2452.5 Z",
        fill: "#053946",
        style: { opacity: 0.997 }
      }
    ) })
  ] }) }) });
};

// src/MainSearchBar/MainSearchBar.tsx
import { css as css21, cx as cx14 } from "@doowii/design-system/css";
import { VisuallyHidden as VisuallyHidden2 } from "@doowii/design-system/jsx";
import { flex as flex2 } from "@doowii/design-system/patterns";
import {
  faMagnifyingGlass,
  faPaperPlaneTop as faPaperPlaneTop2
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon12 } from "@fortawesome/react-fontawesome";
import { useId as useId2, useState as useState3 } from "react";
import { jsx as jsx31, jsxs as jsxs19 } from "react/jsx-runtime";
var MainSearchBar = ({
  placeholder = "How can I help you today?",
  label: LabelText,
  onSubmit,
  size: size4 = "default"
}) => {
  const id = useId2();
  const [inputValue, setInputValue] = useState3("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(inputValue);
      setInputValue("");
    }
  };
  const handleSubmitClick = () => {
    onSubmit(inputValue);
    setInputValue("");
  };
  const searchInputContainer = cx14(
    flex2({
      direction: "row",
      align: "center",
      gap: size4 === "small" ? "8px" : "16px",
      w: "full",
      maxW: size4 === "small" ? "100%" : "720px"
    }),
    css21({
      background: "base.white",
      rounded: "50px",
      position: "relative",
      textAlign: "center",
      paddingX: size4 === "small" ? "sm" : "ld",
      paddingY: size4 === "small" ? "sm" : "md",
      boxSizing: "border-box",
      boxShadow: "4px 8px 12px 0px #EFC42814, -4px -8px 12px 0px #2ABDF114",
      _before: {
        rounded: "50px",
        content: '""',
        backgroundImage: "linear-gradient(90deg, #29BDF0 47.66%, #F6CD3C 65.7%)",
        top: "-4px",
        left: "-4px",
        bottom: "-4px",
        right: "-4px",
        position: "absolute",
        zIndex: "-1"
      },
      _hover: {
        _before: {
          animation: "rotateBorder 3s ease-in-out infinite"
        },
        cursor: "pointer"
      }
    })
  );
  const iconStyle2 = css21({
    color: "base.darkBlue",
    fontSize: size4 === "small" ? "14px" : "24px"
  });
  const searchInput = css21({
    flexGrow: 1,
    fontSize: size4 === "small" ? "14px" : "24px",
    color: "base.gray",
    fontWeight: "medium",
    overflow: "hidden",
    _focus: {
      outline: "none",
      color: "base.darkBlue"
    }
  });
  return /* @__PURE__ */ jsxs19("div", { className: searchInputContainer, children: [
    /* @__PURE__ */ jsx31("div", { className: iconStyle2, children: /* @__PURE__ */ jsx31(FontAwesomeIcon12, { icon: faMagnifyingGlass }) }),
    /* @__PURE__ */ jsx31(VisuallyHidden2, { children: /* @__PURE__ */ jsx31("label", { htmlFor: `search-${id}`, children: LabelText }) }),
    /* @__PURE__ */ jsx31(
      "input",
      {
        className: searchInput,
        id: `search-${id}`,
        name: "chat input",
        onBlur: (e) => e.target.placeholder = placeholder,
        onChange: (e) => setInputValue(e.target.value),
        onFocus: (e) => e.target.placeholder = "",
        onKeyDown: handleKeyDown,
        placeholder,
        type: "text",
        value: inputValue
      }
    ),
    inputValue ? /* @__PURE__ */ jsx31(
      "button",
      {
        className: cx14(iconStyle2, css21({ cursor: "pointer" })),
        onClick: handleSubmitClick,
        onKeyDown: (e) => e.key === "Enter" && handleSubmitClick(),
        tabIndex: 0,
        type: "button",
        children: /* @__PURE__ */ jsx31(FontAwesomeIcon12, { icon: faPaperPlaneTop2 })
      }
    ) : null
  ] });
};

// src/MultiSelect/MultiSelect.tsx
import { css as css22, cx as cx15 } from "@doowii/design-system/css";
import { Flex as Flex4 } from "@doowii/design-system/jsx";
import { faCheck as faCheck3, faChevronDown as faChevronDown2 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon13 } from "@fortawesome/react-fontawesome";
import * as CheckboxPrimitive2 from "@radix-ui/react-checkbox";
import * as React20 from "react";
import { jsx as jsx32, jsxs as jsxs20 } from "react/jsx-runtime";
var MultiSelect = React20.forwardRef(
  ({
    options,
    selectedValues,
    onChange,
    placeholder = "Select options",
    className,
    disabled,
    onRemove,
    onRemoveTooltip
  }, ref) => {
    const [isOpen, setIsOpen] = React20.useState(false);
    const handleOptionClick = (value) => {
      const newValues = selectedValues.includes(value) ? selectedValues.filter((v) => v !== value) : [...selectedValues, value];
      onChange(newValues);
    };
    const handleRemove = (value) => {
      if (!disabled) {
        if (onRemove) {
          onRemove(value);
        }
        onChange(selectedValues.filter((v) => v !== value));
      }
    };
    return /* @__PURE__ */ jsxs20(Flex4, { direction: "column", width: "100%", children: [
      /* @__PURE__ */ jsxs20("div", { className: css22({ position: "relative", width: "100%" }), children: [
        /* @__PURE__ */ jsxs20(
          "button",
          {
            className: cx15(
              css22({
                display: "flex",
                w: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                color: "foreground.base",
                whiteSpace: "nowrap",
                rounded: "md",
                borderWidth: "2px",
                borderColor: "base.gray",
                bgColor: "transparent",
                p: "4",
                mt: "2",
                fontWeight: "500",
                lineHeight: "sm",
                cursor: "pointer",
                _focus: {
                  ring: "none",
                  ringOffset: "none",
                  shadow: "1",
                  borderColor: "base.blueBrandPrimary"
                },
                _disabled: { cursor: "not-allowed", opacity: "0.5" }
              }),
              className
            ),
            disabled,
            onClick: (e) => {
              e.preventDefault();
              if (!disabled) {
                setIsOpen(!isOpen);
              }
            },
            ref,
            tabIndex: 0,
            type: "button",
            children: [
              /* @__PURE__ */ jsx32(
                "span",
                {
                  className: css22({
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "foreground.disabled"
                  }),
                  children: placeholder
                }
              ),
              /* @__PURE__ */ jsx32(
                FontAwesomeIcon13,
                {
                  className: css22({
                    h: "4",
                    w: "4",
                    opacity: "0.5",
                    transform: isOpen ? "rotate(180deg)" : "none",
                    transition: "transform 0.2s ease-in-out"
                  }),
                  icon: faChevronDown2
                }
              )
            ]
          }
        ),
        isOpen && !disabled ? /* @__PURE__ */ jsx32(
          "div",
          {
            className: css22({
              position: "absolute",
              w: "100%",
              mt: "1",
              p: "1",
              bgColor: "white",
              rounded: "md",
              shadow: "hover",
              zIndex: "50",
              maxH: "60vh",
              overflowY: "auto"
            }),
            children: options.map((option) => /* @__PURE__ */ jsx32(
              "div",
              {
                className: css22({
                  p: "1"
                }),
                children: /* @__PURE__ */ jsxs20(
                  "div",
                  {
                    className: cx15(
                      css22({
                        display: "flex",
                        alignItems: "center",
                        gap: "sm",
                        p: "md",
                        width: "100%",
                        bg: selectedValues.includes(option.value) ? "base.blueBrandSecondary" : "transparent",
                        color: "base.darkBlue",
                        rounded: "sm",
                        cursor: option.disabled ? "not-allowed" : "pointer",
                        opacity: option.disabled ? "0.5" : "1",
                        _hover: {
                          bg: "base.blueBrandSecondary"
                        }
                      })
                    ),
                    onClick: () => !option.disabled && handleOptionClick(option.value),
                    onKeyDown: (e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        !option.disabled && handleOptionClick(option.value);
                      }
                    },
                    role: "button",
                    tabIndex: 0,
                    children: [
                      /* @__PURE__ */ jsx32(
                        CheckboxPrimitive2.Root,
                        {
                          checked: selectedValues.includes(option.value),
                          className: cx15(
                            css22({
                              cursor: "pointer",
                              h: "4",
                              w: "4",
                              rounded: "3px",
                              borderWidth: "2px",
                              borderColor: "base.gray",
                              bg: "transparent",
                              _focusVisible: {
                                ring: "none",
                                ringOffset: "none",
                                shadow: "1"
                              },
                              _disabled: { cursor: "not-allowed", opacity: "0.5" },
                              _checked: {
                                bg: "base.blueBrandPrimary",
                                borderColor: "base.blueBrandPrimary",
                                color: "white"
                              }
                            })
                          ),
                          disabled: option.disabled,
                          children: /* @__PURE__ */ jsx32(
                            CheckboxPrimitive2.Indicator,
                            {
                              className: css22({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "current"
                              }),
                              children: /* @__PURE__ */ jsx32(FontAwesomeIcon13, { icon: faCheck3, size: "xs" })
                            }
                          )
                        }
                      ),
                      /* @__PURE__ */ jsx32(
                        "span",
                        {
                          className: css22({
                            textStyle: "label_16",
                            lineHeight: "none",
                            fontWeight: "medium"
                          }),
                          children: option.label
                        }
                      )
                    ]
                  }
                )
              },
              option.value
            ))
          }
        ) : null
      ] }),
      selectedValues.length > 0 ? /* @__PURE__ */ jsx32(Flex4, { className: css22({ mt: "2" }), gap: "sm", wrap: "wrap", children: options.filter((option) => selectedValues.includes(option.value)).map((option) => /* @__PURE__ */ jsx32(
        Badge,
        {
          onRemove: () => handleRemove(option.value),
          onRemoveTooltip,
          children: option.label
        },
        option.value
      )) }) : null
    ] });
  }
);
MultiSelect.displayName = "MultiSelect";

// src/NavBar/NavBar.tsx
import { css as css23, cva as cva10, cx as cx16 } from "@doowii/design-system/css";
import { Center } from "@doowii/design-system/jsx";
import { flex as flex3 } from "@doowii/design-system/patterns";
import {
  faChevronsLeft,
  faChevronsRight
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon14 } from "@fortawesome/react-fontawesome";
import { useId as useId3, useState as useState5 } from "react";
import { NavLink } from "react-router-dom";
import { jsx as jsx33, jsxs as jsxs21 } from "react/jsx-runtime";
var navBarStyles = cva10({
  base: {
    position: "relative",
    height: "100vh",
    p: "md",
    zIndex: 2,
    bg: "base.white",
    borderRight: "1px solid token(colors.base.lightGray)",
    transition: "width 0.3s ease"
  },
  variants: {
    collapsed: {
      true: { width: "112px" },
      false: { width: "270px" }
    }
  },
  defaultVariants: {
    collapsed: false
  }
});
var navBarRoot = (props) => cx16(
  flex3({
    gap: "xl",
    direction: "column",
    align: "center"
  }),
  navBarStyles(props)
);
var navBarMenu = cx16(
  flex3({
    gap: "sm",
    direction: "column",
    align: "center",
    grow: 1,
    maxH: "full",
    overflowY: "auto",
    overflowX: "visible"
  }),
  css23({
    w: "100%",
    bg: "white"
  })
);
var navBarIcon = css23({
  w: "32px",
  h: "32px",
  minW: "32px"
});
var navBarItem = cx16(
  flex3({
    gap: "2.5",
    direction: "row",
    align: "center"
  }),
  css23({
    bg: "base.white",
    py: "3",
    px: "4",
    w: "100%",
    minWidth: "xl5",
    rounded: "xl",
    color: "base.darkBlue",
    fontSize: "20px",
    fontWeight: "medium",
    cursor: "pointer",
    _hover: {
      bg: "base.blueBrandSecondary"
    },
    _pressed: {
      bg: "base.blueBrandTertiary"
    }
  })
);
var disabledNavBarItem = css23({
  color: "base.lighterGrey",
  cursor: "not-allowed",
  pointerEvents: "none",
  opacity: 0.6
});
var collapseButton = css23({
  bg: "base.white",
  rounded: "full",
  cursor: "pointer",
  position: "absolute",
  top: "28px",
  right: "-20px",
  padding: "7px",
  color: "base.darkBlue",
  border: "1px solid token(colors.base.lightGray)",
  zIndex: 2,
  _hover: {
    bg: "base.blueBrandSecondary"
  },
  _pressed: {
    bg: "base.blueBrandTertiary"
  }
});
var sectionTitle = css23({
  color: "base.gray",
  fontSize: "sm",
  fontWeight: "medium",
  px: "4",
  py: "2"
});
var secondaryNav = css23({
  borderTop: "1px solid token(colors.base.lightGray)",
  marginTop: "6",
  paddingTop: "6"
});
var NavBarItem = ({
  children,
  to,
  disabled = false
}) => /* @__PURE__ */ jsx33("li", { className: css23({ w: "100%" }), children: /* @__PURE__ */ jsxs21(
  NavLink,
  {
    "aria-disabled": disabled,
    className: ({ isActive }) => cx16(
      navBarItem,
      isActive && css23({ bg: "base.blueBrandTertiary" }),
      disabled && disabledNavBarItem
    ),
    tabIndex: disabled ? -1 : void 0,
    to,
    children: [
      /* @__PURE__ */ jsx33(Center, { className: navBarIcon, children: children.icon }),
      children.label
    ]
  }
) });
var NavBar = ({
  navItems,
  secondaryNavItems,
  secondaryNavTitle,
  footerButton
}) => {
  const id = useId3();
  const [isCollapsed, setIsCollapsed] = useState5(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const renderNavItems = (items) => items.map(
    (item) => item && /* @__PURE__ */ jsx33(NavBarItem, { disabled: item.disabled, to: item.path, children: {
      icon: item.icon,
      label: isCollapsed ? null : item.label
    } }, item.path)
  );
  return /* @__PURE__ */ jsxs21("div", { className: navBarRoot({ collapsed: isCollapsed }), children: [
    /* @__PURE__ */ jsx33("div", { children: isCollapsed ? /* @__PURE__ */ jsx33(LogoD, {}) : /* @__PURE__ */ jsx33(LogoDoowii, {}) }),
    /* @__PURE__ */ jsx33(
      "button",
      {
        "aria-controls": `collapse-nav-${id}`,
        "aria-expanded": !isCollapsed,
        "aria-label": "Toggle navigation",
        className: collapseButton,
        id: `collapse-button-${id}`,
        onClick: toggleCollapse,
        type: "button",
        children: /* @__PURE__ */ jsx33(
          FontAwesomeIcon14,
          {
            className: css23({
              w: "24px",
              h: "24px",
              cursor: "pointer"
            }),
            icon: isCollapsed ? faChevronsRight : faChevronsLeft
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs21("div", { className: navBarMenu, children: [
      /* @__PURE__ */ jsx33("ul", { className: flex3({ direction: "column", gap: "sm", w: "full" }), children: renderNavItems(navItems) }),
      secondaryNavItems?.length ? /* @__PURE__ */ jsxs21(
        "ul",
        {
          className: cx16(
            flex3({ direction: "column", gap: "sm", w: "full" }),
            secondaryNav
          ),
          children: [
            !isCollapsed && secondaryNavTitle ? /* @__PURE__ */ jsx33("div", { className: sectionTitle, children: secondaryNavTitle }) : null,
            renderNavItems(secondaryNavItems)
          ]
        }
      ) : null
    ] }),
    isCollapsed ? null : footerButton
  ] });
};

// src/PageLayout/PageLayout.tsx
import { Flex as Flex5 } from "@doowii/design-system/jsx";
import { useId as useId4 } from "react";
import { jsx as jsx34, jsxs as jsxs22 } from "react/jsx-runtime";
var PageLayout = ({
  title,
  loading,
  isEmpty,
  emptyProps,
  action,
  children
}) => {
  const id = useId4();
  return /* @__PURE__ */ jsxs22(
    Flex5,
    {
      css: {
        flexDirection: "column",
        width: "full",
        height: "full",
        px: "xl3",
        pb: "xl3"
      },
      children: [
        /* @__PURE__ */ jsxs22(
          Flex5,
          {
            css: {
              justifyContent: "space-between",
              alignItems: "center",
              mb: "ld"
            },
            children: [
              /* @__PURE__ */ jsx34(Heading, { level: 1, children: title }),
              !loading && !isEmpty ? action : null
            ]
          }
        ),
        /* @__PURE__ */ jsxs22(Flex5, { css: { w: "full", flex: 1 }, children: [
          loading ? /* @__PURE__ */ jsx34(
            Flex5,
            {
              css: { alignItems: "center", justifyContent: "center", w: "full" },
              children: /* @__PURE__ */ jsx34(LoadingLogo, {})
            }
          ) : null,
          isEmpty && emptyProps && !loading ? /* @__PURE__ */ jsxs22(
            Flex5,
            {
              css: {
                alignItems: "center",
                flexDirection: "column",
                gap: "8",
                w: "full"
              },
              children: [
                /* @__PURE__ */ jsx34(CircularIcon, { icon: emptyProps.icon }),
                /* @__PURE__ */ jsxs22(
                  Flex5,
                  {
                    css: {
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "2"
                    },
                    children: [
                      /* @__PURE__ */ jsx34(Heading, { level: 2, children: emptyProps.title }),
                      /* @__PURE__ */ jsx34(Text, { level: 4, children: emptyProps.description })
                    ]
                  }
                ),
                /* @__PURE__ */ jsx34(Button, { id: `${id}-empty-button`, onClick: emptyProps.onClick, children: emptyProps.buttonText })
              ]
            }
          ) : null,
          !loading && !isEmpty ? children : null
        ] })
      ]
    }
  );
};

// src/Popover/Popover.tsx
import { css as css24, cx as cx17 } from "@doowii/design-system/css";

// ../../node_modules/@radix-ui/react-popover/dist/index.mjs
import * as React49 from "react";

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/primitive/dist/index.mjs
function composeEventHandlers(originalEventHandler, ourEventHandler, { checkForDefaultPrevented = true } = {}) {
  return function handleEvent(event) {
    originalEventHandler?.(event);
    if (checkForDefaultPrevented === false || !event.defaultPrevented) {
      return ourEventHandler?.(event);
    }
  };
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-compose-refs/dist/index.mjs
import * as React21 from "react";
function setRef2(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref !== null && ref !== void 0) {
    ref.current = value;
  }
}
function composeRefs2(...refs) {
  return (node) => refs.forEach((ref) => setRef2(ref, node));
}
function useComposedRefs2(...refs) {
  return React21.useCallback(composeRefs2(...refs), refs);
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-context/dist/index.mjs
import * as React22 from "react";
import { jsx as jsx35 } from "react/jsx-runtime";
function createContextScope(scopeName, createContextScopeDeps = []) {
  let defaultContexts = [];
  function createContext32(rootComponentName, defaultContext) {
    const BaseContext = React22.createContext(defaultContext);
    const index2 = defaultContexts.length;
    defaultContexts = [...defaultContexts, defaultContext];
    function Provider3(props) {
      const { scope, children, ...context } = props;
      const Context = scope?.[scopeName][index2] || BaseContext;
      const value = React22.useMemo(() => context, Object.values(context));
      return /* @__PURE__ */ jsx35(Context.Provider, { value, children });
    }
    function useContext22(consumerName, scope) {
      const Context = scope?.[scopeName][index2] || BaseContext;
      const context = React22.useContext(Context);
      if (context) return context;
      if (defaultContext !== void 0) return defaultContext;
      throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
    }
    Provider3.displayName = rootComponentName + "Provider";
    return [Provider3, useContext22];
  }
  const createScope = () => {
    const scopeContexts = defaultContexts.map((defaultContext) => {
      return React22.createContext(defaultContext);
    });
    return function useScope(scope) {
      const contexts = scope?.[scopeName] || scopeContexts;
      return React22.useMemo(
        () => ({ [`__scope${scopeName}`]: { ...scope, [scopeName]: contexts } }),
        [scope, contexts]
      );
    };
  };
  createScope.scopeName = scopeName;
  return [createContext32, composeContextScopes(createScope, ...createContextScopeDeps)];
}
function composeContextScopes(...scopes) {
  const baseScope = scopes[0];
  if (scopes.length === 1) return baseScope;
  const createScope = () => {
    const scopeHooks = scopes.map((createScope2) => ({
      useScope: createScope2(),
      scopeName: createScope2.scopeName
    }));
    return function useComposedScopes(overrideScopes) {
      const nextScopes = scopeHooks.reduce((nextScopes2, { useScope, scopeName }) => {
        const scopeProps = useScope(overrideScopes);
        const currentScope = scopeProps[`__scope${scopeName}`];
        return { ...nextScopes2, ...currentScope };
      }, {});
      return React22.useMemo(() => ({ [`__scope${baseScope.scopeName}`]: nextScopes }), [nextScopes]);
    };
  };
  createScope.scopeName = baseScope.scopeName;
  return createScope;
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-dismissable-layer/dist/index.mjs
import * as React27 from "react";

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-primitive/dist/index.mjs
import * as React24 from "react";
import * as ReactDOM2 from "react-dom";

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-slot/dist/index.mjs
import * as React23 from "react";
import { Fragment as Fragment3, jsx as jsx36 } from "react/jsx-runtime";
var Slot2 = React23.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  const childrenArray = React23.Children.toArray(children);
  const slottable = childrenArray.find(isSlottable2);
  if (slottable) {
    const newElement = slottable.props.children;
    const newChildren = childrenArray.map((child) => {
      if (child === slottable) {
        if (React23.Children.count(newElement) > 1) return React23.Children.only(null);
        return React23.isValidElement(newElement) ? newElement.props.children : null;
      } else {
        return child;
      }
    });
    return /* @__PURE__ */ jsx36(SlotClone2, { ...slotProps, ref: forwardedRef, children: React23.isValidElement(newElement) ? React23.cloneElement(newElement, void 0, newChildren) : null });
  }
  return /* @__PURE__ */ jsx36(SlotClone2, { ...slotProps, ref: forwardedRef, children });
});
Slot2.displayName = "Slot";
var SlotClone2 = React23.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  if (React23.isValidElement(children)) {
    const childrenRef = getElementRef2(children);
    return React23.cloneElement(children, {
      ...mergeProps2(slotProps, children.props),
      // @ts-ignore
      ref: forwardedRef ? composeRefs2(forwardedRef, childrenRef) : childrenRef
    });
  }
  return React23.Children.count(children) > 1 ? React23.Children.only(null) : null;
});
SlotClone2.displayName = "SlotClone";
var Slottable2 = ({ children }) => {
  return /* @__PURE__ */ jsx36(Fragment3, { children });
};
function isSlottable2(child) {
  return React23.isValidElement(child) && child.type === Slottable2;
}
function mergeProps2(slotProps, childProps) {
  const overrideProps = { ...childProps };
  for (const propName in childProps) {
    const slotPropValue = slotProps[propName];
    const childPropValue = childProps[propName];
    const isHandler = /^on[A-Z]/.test(propName);
    if (isHandler) {
      if (slotPropValue && childPropValue) {
        overrideProps[propName] = (...args) => {
          childPropValue(...args);
          slotPropValue(...args);
        };
      } else if (slotPropValue) {
        overrideProps[propName] = slotPropValue;
      }
    } else if (propName === "style") {
      overrideProps[propName] = { ...slotPropValue, ...childPropValue };
    } else if (propName === "className") {
      overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(" ");
    }
  }
  return { ...slotProps, ...overrideProps };
}
function getElementRef2(element) {
  let getter = Object.getOwnPropertyDescriptor(element.props, "ref")?.get;
  let mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.ref;
  }
  getter = Object.getOwnPropertyDescriptor(element, "ref")?.get;
  mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }
  return element.props.ref || element.ref;
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-primitive/dist/index.mjs
import { jsx as jsx37 } from "react/jsx-runtime";
var NODES2 = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
];
var Primitive2 = NODES2.reduce((primitive, node) => {
  const Node2 = React24.forwardRef((props, forwardedRef) => {
    const { asChild, ...primitiveProps } = props;
    const Comp = asChild ? Slot2 : node;
    if (typeof window !== "undefined") {
      window[Symbol.for("radix-ui")] = true;
    }
    return /* @__PURE__ */ jsx37(Comp, { ...primitiveProps, ref: forwardedRef });
  });
  Node2.displayName = `Primitive.${node}`;
  return { ...primitive, [node]: Node2 };
}, {});
function dispatchDiscreteCustomEvent(target, event) {
  if (target) ReactDOM2.flushSync(() => target.dispatchEvent(event));
}

// ../../node_modules/@radix-ui/react-use-callback-ref/dist/index.mjs
import * as React25 from "react";
function useCallbackRef(callback) {
  const callbackRef = React25.useRef(callback);
  React25.useEffect(() => {
    callbackRef.current = callback;
  });
  return React25.useMemo(() => (...args) => callbackRef.current?.(...args), []);
}

// ../../node_modules/@radix-ui/react-use-escape-keydown/dist/index.mjs
import * as React26 from "react";
function useEscapeKeydown(onEscapeKeyDownProp, ownerDocument = globalThis?.document) {
  const onEscapeKeyDown = useCallbackRef(onEscapeKeyDownProp);
  React26.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onEscapeKeyDown(event);
      }
    };
    ownerDocument.addEventListener("keydown", handleKeyDown, { capture: true });
    return () => ownerDocument.removeEventListener("keydown", handleKeyDown, { capture: true });
  }, [onEscapeKeyDown, ownerDocument]);
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-dismissable-layer/dist/index.mjs
import { jsx as jsx38 } from "react/jsx-runtime";
var DISMISSABLE_LAYER_NAME = "DismissableLayer";
var CONTEXT_UPDATE = "dismissableLayer.update";
var POINTER_DOWN_OUTSIDE = "dismissableLayer.pointerDownOutside";
var FOCUS_OUTSIDE = "dismissableLayer.focusOutside";
var originalBodyPointerEvents;
var DismissableLayerContext = React27.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
});
var DismissableLayer = React27.forwardRef(
  (props, forwardedRef) => {
    const {
      disableOutsidePointerEvents = false,
      onEscapeKeyDown,
      onPointerDownOutside,
      onFocusOutside,
      onInteractOutside,
      onDismiss,
      ...layerProps
    } = props;
    const context = React27.useContext(DismissableLayerContext);
    const [node, setNode] = React27.useState(null);
    const ownerDocument = node?.ownerDocument ?? globalThis?.document;
    const [, force] = React27.useState({});
    const composedRefs = useComposedRefs2(forwardedRef, (node2) => setNode(node2));
    const layers = Array.from(context.layers);
    const [highestLayerWithOutsidePointerEventsDisabled] = [...context.layersWithOutsidePointerEventsDisabled].slice(-1);
    const highestLayerWithOutsidePointerEventsDisabledIndex = layers.indexOf(highestLayerWithOutsidePointerEventsDisabled);
    const index2 = node ? layers.indexOf(node) : -1;
    const isBodyPointerEventsDisabled = context.layersWithOutsidePointerEventsDisabled.size > 0;
    const isPointerEventsEnabled = index2 >= highestLayerWithOutsidePointerEventsDisabledIndex;
    const pointerDownOutside = usePointerDownOutside((event) => {
      const target = event.target;
      const isPointerDownOnBranch = [...context.branches].some((branch) => branch.contains(target));
      if (!isPointerEventsEnabled || isPointerDownOnBranch) return;
      onPointerDownOutside?.(event);
      onInteractOutside?.(event);
      if (!event.defaultPrevented) onDismiss?.();
    }, ownerDocument);
    const focusOutside = useFocusOutside((event) => {
      const target = event.target;
      const isFocusInBranch = [...context.branches].some((branch) => branch.contains(target));
      if (isFocusInBranch) return;
      onFocusOutside?.(event);
      onInteractOutside?.(event);
      if (!event.defaultPrevented) onDismiss?.();
    }, ownerDocument);
    useEscapeKeydown((event) => {
      const isHighestLayer = index2 === context.layers.size - 1;
      if (!isHighestLayer) return;
      onEscapeKeyDown?.(event);
      if (!event.defaultPrevented && onDismiss) {
        event.preventDefault();
        onDismiss();
      }
    }, ownerDocument);
    React27.useEffect(() => {
      if (!node) return;
      if (disableOutsidePointerEvents) {
        if (context.layersWithOutsidePointerEventsDisabled.size === 0) {
          originalBodyPointerEvents = ownerDocument.body.style.pointerEvents;
          ownerDocument.body.style.pointerEvents = "none";
        }
        context.layersWithOutsidePointerEventsDisabled.add(node);
      }
      context.layers.add(node);
      dispatchUpdate();
      return () => {
        if (disableOutsidePointerEvents && context.layersWithOutsidePointerEventsDisabled.size === 1) {
          ownerDocument.body.style.pointerEvents = originalBodyPointerEvents;
        }
      };
    }, [node, ownerDocument, disableOutsidePointerEvents, context]);
    React27.useEffect(() => {
      return () => {
        if (!node) return;
        context.layers.delete(node);
        context.layersWithOutsidePointerEventsDisabled.delete(node);
        dispatchUpdate();
      };
    }, [node, context]);
    React27.useEffect(() => {
      const handleUpdate = () => force({});
      document.addEventListener(CONTEXT_UPDATE, handleUpdate);
      return () => document.removeEventListener(CONTEXT_UPDATE, handleUpdate);
    }, []);
    return /* @__PURE__ */ jsx38(
      Primitive2.div,
      {
        ...layerProps,
        ref: composedRefs,
        style: {
          pointerEvents: isBodyPointerEventsDisabled ? isPointerEventsEnabled ? "auto" : "none" : void 0,
          ...props.style
        },
        onFocusCapture: composeEventHandlers(props.onFocusCapture, focusOutside.onFocusCapture),
        onBlurCapture: composeEventHandlers(props.onBlurCapture, focusOutside.onBlurCapture),
        onPointerDownCapture: composeEventHandlers(
          props.onPointerDownCapture,
          pointerDownOutside.onPointerDownCapture
        )
      }
    );
  }
);
DismissableLayer.displayName = DISMISSABLE_LAYER_NAME;
var BRANCH_NAME = "DismissableLayerBranch";
var DismissableLayerBranch = React27.forwardRef((props, forwardedRef) => {
  const context = React27.useContext(DismissableLayerContext);
  const ref = React27.useRef(null);
  const composedRefs = useComposedRefs2(forwardedRef, ref);
  React27.useEffect(() => {
    const node = ref.current;
    if (node) {
      context.branches.add(node);
      return () => {
        context.branches.delete(node);
      };
    }
  }, [context.branches]);
  return /* @__PURE__ */ jsx38(Primitive2.div, { ...props, ref: composedRefs });
});
DismissableLayerBranch.displayName = BRANCH_NAME;
function usePointerDownOutside(onPointerDownOutside, ownerDocument = globalThis?.document) {
  const handlePointerDownOutside = useCallbackRef(onPointerDownOutside);
  const isPointerInsideReactTreeRef = React27.useRef(false);
  const handleClickRef = React27.useRef(() => {
  });
  React27.useEffect(() => {
    const handlePointerDown = (event) => {
      if (event.target && !isPointerInsideReactTreeRef.current) {
        let handleAndDispatchPointerDownOutsideEvent2 = function() {
          handleAndDispatchCustomEvent(
            POINTER_DOWN_OUTSIDE,
            handlePointerDownOutside,
            eventDetail,
            { discrete: true }
          );
        };
        var handleAndDispatchPointerDownOutsideEvent = handleAndDispatchPointerDownOutsideEvent2;
        const eventDetail = { originalEvent: event };
        if (event.pointerType === "touch") {
          ownerDocument.removeEventListener("click", handleClickRef.current);
          handleClickRef.current = handleAndDispatchPointerDownOutsideEvent2;
          ownerDocument.addEventListener("click", handleClickRef.current, { once: true });
        } else {
          handleAndDispatchPointerDownOutsideEvent2();
        }
      } else {
        ownerDocument.removeEventListener("click", handleClickRef.current);
      }
      isPointerInsideReactTreeRef.current = false;
    };
    const timerId = window.setTimeout(() => {
      ownerDocument.addEventListener("pointerdown", handlePointerDown);
    }, 0);
    return () => {
      window.clearTimeout(timerId);
      ownerDocument.removeEventListener("pointerdown", handlePointerDown);
      ownerDocument.removeEventListener("click", handleClickRef.current);
    };
  }, [ownerDocument, handlePointerDownOutside]);
  return {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => isPointerInsideReactTreeRef.current = true
  };
}
function useFocusOutside(onFocusOutside, ownerDocument = globalThis?.document) {
  const handleFocusOutside = useCallbackRef(onFocusOutside);
  const isFocusInsideReactTreeRef = React27.useRef(false);
  React27.useEffect(() => {
    const handleFocus = (event) => {
      if (event.target && !isFocusInsideReactTreeRef.current) {
        const eventDetail = { originalEvent: event };
        handleAndDispatchCustomEvent(FOCUS_OUTSIDE, handleFocusOutside, eventDetail, {
          discrete: false
        });
      }
    };
    ownerDocument.addEventListener("focusin", handleFocus);
    return () => ownerDocument.removeEventListener("focusin", handleFocus);
  }, [ownerDocument, handleFocusOutside]);
  return {
    onFocusCapture: () => isFocusInsideReactTreeRef.current = true,
    onBlurCapture: () => isFocusInsideReactTreeRef.current = false
  };
}
function dispatchUpdate() {
  const event = new CustomEvent(CONTEXT_UPDATE);
  document.dispatchEvent(event);
}
function handleAndDispatchCustomEvent(name, handler, detail, { discrete }) {
  const target = detail.originalEvent.target;
  const event = new CustomEvent(name, { bubbles: false, cancelable: true, detail });
  if (handler) target.addEventListener(name, handler, { once: true });
  if (discrete) {
    dispatchDiscreteCustomEvent(target, event);
  } else {
    target.dispatchEvent(event);
  }
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-focus-guards/dist/index.mjs
import * as React28 from "react";
var count = 0;
function useFocusGuards() {
  React28.useEffect(() => {
    const edgeGuards = document.querySelectorAll("[data-radix-focus-guard]");
    document.body.insertAdjacentElement("afterbegin", edgeGuards[0] ?? createFocusGuard());
    document.body.insertAdjacentElement("beforeend", edgeGuards[1] ?? createFocusGuard());
    count++;
    return () => {
      if (count === 1) {
        document.querySelectorAll("[data-radix-focus-guard]").forEach((node) => node.remove());
      }
      count--;
    };
  }, []);
}
function createFocusGuard() {
  const element = document.createElement("span");
  element.setAttribute("data-radix-focus-guard", "");
  element.tabIndex = 0;
  element.style.cssText = "outline: none; opacity: 0; position: fixed; pointer-events: none";
  return element;
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-focus-scope/dist/index.mjs
import * as React29 from "react";
import { jsx as jsx39 } from "react/jsx-runtime";
var AUTOFOCUS_ON_MOUNT = "focusScope.autoFocusOnMount";
var AUTOFOCUS_ON_UNMOUNT = "focusScope.autoFocusOnUnmount";
var EVENT_OPTIONS = { bubbles: false, cancelable: true };
var FOCUS_SCOPE_NAME = "FocusScope";
var FocusScope = React29.forwardRef((props, forwardedRef) => {
  const {
    loop = false,
    trapped = false,
    onMountAutoFocus: onMountAutoFocusProp,
    onUnmountAutoFocus: onUnmountAutoFocusProp,
    ...scopeProps
  } = props;
  const [container, setContainer] = React29.useState(null);
  const onMountAutoFocus = useCallbackRef(onMountAutoFocusProp);
  const onUnmountAutoFocus = useCallbackRef(onUnmountAutoFocusProp);
  const lastFocusedElementRef = React29.useRef(null);
  const composedRefs = useComposedRefs2(forwardedRef, (node) => setContainer(node));
  const focusScope = React29.useRef({
    paused: false,
    pause() {
      this.paused = true;
    },
    resume() {
      this.paused = false;
    }
  }).current;
  React29.useEffect(() => {
    if (trapped) {
      let handleFocusIn2 = function(event) {
        if (focusScope.paused || !container) return;
        const target = event.target;
        if (container.contains(target)) {
          lastFocusedElementRef.current = target;
        } else {
          focus(lastFocusedElementRef.current, { select: true });
        }
      }, handleFocusOut2 = function(event) {
        if (focusScope.paused || !container) return;
        const relatedTarget = event.relatedTarget;
        if (relatedTarget === null) return;
        if (!container.contains(relatedTarget)) {
          focus(lastFocusedElementRef.current, { select: true });
        }
      }, handleMutations2 = function(mutations) {
        const focusedElement = document.activeElement;
        if (focusedElement !== document.body) return;
        for (const mutation of mutations) {
          if (mutation.removedNodes.length > 0) focus(container);
        }
      };
      var handleFocusIn = handleFocusIn2, handleFocusOut = handleFocusOut2, handleMutations = handleMutations2;
      document.addEventListener("focusin", handleFocusIn2);
      document.addEventListener("focusout", handleFocusOut2);
      const mutationObserver = new MutationObserver(handleMutations2);
      if (container) mutationObserver.observe(container, { childList: true, subtree: true });
      return () => {
        document.removeEventListener("focusin", handleFocusIn2);
        document.removeEventListener("focusout", handleFocusOut2);
        mutationObserver.disconnect();
      };
    }
  }, [trapped, container, focusScope.paused]);
  React29.useEffect(() => {
    if (container) {
      focusScopesStack.add(focusScope);
      const previouslyFocusedElement = document.activeElement;
      const hasFocusedCandidate = container.contains(previouslyFocusedElement);
      if (!hasFocusedCandidate) {
        const mountEvent = new CustomEvent(AUTOFOCUS_ON_MOUNT, EVENT_OPTIONS);
        container.addEventListener(AUTOFOCUS_ON_MOUNT, onMountAutoFocus);
        container.dispatchEvent(mountEvent);
        if (!mountEvent.defaultPrevented) {
          focusFirst(removeLinks(getTabbableCandidates(container)), { select: true });
          if (document.activeElement === previouslyFocusedElement) {
            focus(container);
          }
        }
      }
      return () => {
        container.removeEventListener(AUTOFOCUS_ON_MOUNT, onMountAutoFocus);
        setTimeout(() => {
          const unmountEvent = new CustomEvent(AUTOFOCUS_ON_UNMOUNT, EVENT_OPTIONS);
          container.addEventListener(AUTOFOCUS_ON_UNMOUNT, onUnmountAutoFocus);
          container.dispatchEvent(unmountEvent);
          if (!unmountEvent.defaultPrevented) {
            focus(previouslyFocusedElement ?? document.body, { select: true });
          }
          container.removeEventListener(AUTOFOCUS_ON_UNMOUNT, onUnmountAutoFocus);
          focusScopesStack.remove(focusScope);
        }, 0);
      };
    }
  }, [container, onMountAutoFocus, onUnmountAutoFocus, focusScope]);
  const handleKeyDown = React29.useCallback(
    (event) => {
      if (!loop && !trapped) return;
      if (focusScope.paused) return;
      const isTabKey = event.key === "Tab" && !event.altKey && !event.ctrlKey && !event.metaKey;
      const focusedElement = document.activeElement;
      if (isTabKey && focusedElement) {
        const container2 = event.currentTarget;
        const [first, last] = getTabbableEdges(container2);
        const hasTabbableElementsInside = first && last;
        if (!hasTabbableElementsInside) {
          if (focusedElement === container2) event.preventDefault();
        } else {
          if (!event.shiftKey && focusedElement === last) {
            event.preventDefault();
            if (loop) focus(first, { select: true });
          } else if (event.shiftKey && focusedElement === first) {
            event.preventDefault();
            if (loop) focus(last, { select: true });
          }
        }
      }
    },
    [loop, trapped, focusScope.paused]
  );
  return /* @__PURE__ */ jsx39(Primitive2.div, { tabIndex: -1, ...scopeProps, ref: composedRefs, onKeyDown: handleKeyDown });
});
FocusScope.displayName = FOCUS_SCOPE_NAME;
function focusFirst(candidates, { select = false } = {}) {
  const previouslyFocusedElement = document.activeElement;
  for (const candidate of candidates) {
    focus(candidate, { select });
    if (document.activeElement !== previouslyFocusedElement) return;
  }
}
function getTabbableEdges(container) {
  const candidates = getTabbableCandidates(container);
  const first = findVisible(candidates, container);
  const last = findVisible(candidates.reverse(), container);
  return [first, last];
}
function getTabbableCandidates(container) {
  const nodes = [];
  const walker = document.createTreeWalker(container, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (node) => {
      const isHiddenInput = node.tagName === "INPUT" && node.type === "hidden";
      if (node.disabled || node.hidden || isHiddenInput) return NodeFilter.FILTER_SKIP;
      return node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  while (walker.nextNode()) nodes.push(walker.currentNode);
  return nodes;
}
function findVisible(elements, container) {
  for (const element of elements) {
    if (!isHidden(element, { upTo: container })) return element;
  }
}
function isHidden(node, { upTo }) {
  if (getComputedStyle(node).visibility === "hidden") return true;
  while (node) {
    if (upTo !== void 0 && node === upTo) return false;
    if (getComputedStyle(node).display === "none") return true;
    node = node.parentElement;
  }
  return false;
}
function isSelectableInput(element) {
  return element instanceof HTMLInputElement && "select" in element;
}
function focus(element, { select = false } = {}) {
  if (element && element.focus) {
    const previouslyFocusedElement = document.activeElement;
    element.focus({ preventScroll: true });
    if (element !== previouslyFocusedElement && isSelectableInput(element) && select)
      element.select();
  }
}
var focusScopesStack = createFocusScopesStack();
function createFocusScopesStack() {
  let stack = [];
  return {
    add(focusScope) {
      const activeFocusScope = stack[0];
      if (focusScope !== activeFocusScope) {
        activeFocusScope?.pause();
      }
      stack = arrayRemove(stack, focusScope);
      stack.unshift(focusScope);
    },
    remove(focusScope) {
      stack = arrayRemove(stack, focusScope);
      stack[0]?.resume();
    }
  };
}
function arrayRemove(array, item) {
  const updatedArray = [...array];
  const index2 = updatedArray.indexOf(item);
  if (index2 !== -1) {
    updatedArray.splice(index2, 1);
  }
  return updatedArray;
}
function removeLinks(items) {
  return items.filter((item) => item.tagName !== "A");
}

// ../../node_modules/@radix-ui/react-id/dist/index.mjs
import * as React31 from "react";

// ../../node_modules/@radix-ui/react-use-layout-effect/dist/index.mjs
import * as React30 from "react";
var useLayoutEffect2 = Boolean(globalThis?.document) ? React30.useLayoutEffect : () => {
};

// ../../node_modules/@radix-ui/react-id/dist/index.mjs
var useReactId = React31["useId".toString()] || (() => void 0);
var count2 = 0;
function useId5(deterministicId) {
  const [id, setId] = React31.useState(useReactId());
  useLayoutEffect2(() => {
    if (!deterministicId) setId((reactId) => reactId ?? String(count2++));
  }, [deterministicId]);
  return deterministicId || (id ? `radix-${id}` : "");
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-popper/dist/index.mjs
import * as React38 from "react";

// ../../node_modules/@floating-ui/utils/dist/floating-ui.utils.mjs
var sides = ["top", "right", "bottom", "left"];
var min = Math.min;
var max = Math.max;
var round = Math.round;
var floor = Math.floor;
var createCoords = (v) => ({
  x: v,
  y: v
});
var oppositeSideMap = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
};
var oppositeAlignmentMap = {
  start: "end",
  end: "start"
};
function clamp(start, value, end) {
  return max(start, min(value, end));
}
function evaluate(value, param) {
  return typeof value === "function" ? value(param) : value;
}
function getSide(placement) {
  return placement.split("-")[0];
}
function getAlignment(placement) {
  return placement.split("-")[1];
}
function getOppositeAxis(axis) {
  return axis === "x" ? "y" : "x";
}
function getAxisLength(axis) {
  return axis === "y" ? "height" : "width";
}
function getSideAxis(placement) {
  return ["top", "bottom"].includes(getSide(placement)) ? "y" : "x";
}
function getAlignmentAxis(placement) {
  return getOppositeAxis(getSideAxis(placement));
}
function getAlignmentSides(placement, rects, rtl) {
  if (rtl === void 0) {
    rtl = false;
  }
  const alignment = getAlignment(placement);
  const alignmentAxis = getAlignmentAxis(placement);
  const length = getAxisLength(alignmentAxis);
  let mainAlignmentSide = alignmentAxis === "x" ? alignment === (rtl ? "end" : "start") ? "right" : "left" : alignment === "start" ? "bottom" : "top";
  if (rects.reference[length] > rects.floating[length]) {
    mainAlignmentSide = getOppositePlacement(mainAlignmentSide);
  }
  return [mainAlignmentSide, getOppositePlacement(mainAlignmentSide)];
}
function getExpandedPlacements(placement) {
  const oppositePlacement = getOppositePlacement(placement);
  return [getOppositeAlignmentPlacement(placement), oppositePlacement, getOppositeAlignmentPlacement(oppositePlacement)];
}
function getOppositeAlignmentPlacement(placement) {
  return placement.replace(/start|end/g, (alignment) => oppositeAlignmentMap[alignment]);
}
function getSideList(side, isStart, rtl) {
  const lr = ["left", "right"];
  const rl = ["right", "left"];
  const tb = ["top", "bottom"];
  const bt = ["bottom", "top"];
  switch (side) {
    case "top":
    case "bottom":
      if (rtl) return isStart ? rl : lr;
      return isStart ? lr : rl;
    case "left":
    case "right":
      return isStart ? tb : bt;
    default:
      return [];
  }
}
function getOppositeAxisPlacements(placement, flipAlignment, direction, rtl) {
  const alignment = getAlignment(placement);
  let list = getSideList(getSide(placement), direction === "start", rtl);
  if (alignment) {
    list = list.map((side) => side + "-" + alignment);
    if (flipAlignment) {
      list = list.concat(list.map(getOppositeAlignmentPlacement));
    }
  }
  return list;
}
function getOppositePlacement(placement) {
  return placement.replace(/left|right|bottom|top/g, (side) => oppositeSideMap[side]);
}
function expandPaddingObject(padding) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...padding
  };
}
function getPaddingObject(padding) {
  return typeof padding !== "number" ? expandPaddingObject(padding) : {
    top: padding,
    right: padding,
    bottom: padding,
    left: padding
  };
}
function rectToClientRect(rect) {
  const {
    x,
    y,
    width,
    height
  } = rect;
  return {
    width,
    height,
    top: y,
    left: x,
    right: x + width,
    bottom: y + height,
    x,
    y
  };
}

// ../../node_modules/@floating-ui/core/dist/floating-ui.core.mjs
function computeCoordsFromPlacement(_ref, placement, rtl) {
  let {
    reference,
    floating
  } = _ref;
  const sideAxis = getSideAxis(placement);
  const alignmentAxis = getAlignmentAxis(placement);
  const alignLength = getAxisLength(alignmentAxis);
  const side = getSide(placement);
  const isVertical = sideAxis === "y";
  const commonX = reference.x + reference.width / 2 - floating.width / 2;
  const commonY = reference.y + reference.height / 2 - floating.height / 2;
  const commonAlign = reference[alignLength] / 2 - floating[alignLength] / 2;
  let coords;
  switch (side) {
    case "top":
      coords = {
        x: commonX,
        y: reference.y - floating.height
      };
      break;
    case "bottom":
      coords = {
        x: commonX,
        y: reference.y + reference.height
      };
      break;
    case "right":
      coords = {
        x: reference.x + reference.width,
        y: commonY
      };
      break;
    case "left":
      coords = {
        x: reference.x - floating.width,
        y: commonY
      };
      break;
    default:
      coords = {
        x: reference.x,
        y: reference.y
      };
  }
  switch (getAlignment(placement)) {
    case "start":
      coords[alignmentAxis] -= commonAlign * (rtl && isVertical ? -1 : 1);
      break;
    case "end":
      coords[alignmentAxis] += commonAlign * (rtl && isVertical ? -1 : 1);
      break;
  }
  return coords;
}
var computePosition = async (reference, floating, config) => {
  const {
    placement = "bottom",
    strategy = "absolute",
    middleware = [],
    platform: platform2
  } = config;
  const validMiddleware = middleware.filter(Boolean);
  const rtl = await (platform2.isRTL == null ? void 0 : platform2.isRTL(floating));
  let rects = await platform2.getElementRects({
    reference,
    floating,
    strategy
  });
  let {
    x,
    y
  } = computeCoordsFromPlacement(rects, placement, rtl);
  let statefulPlacement = placement;
  let middlewareData = {};
  let resetCount = 0;
  for (let i = 0; i < validMiddleware.length; i++) {
    const {
      name,
      fn
    } = validMiddleware[i];
    const {
      x: nextX,
      y: nextY,
      data,
      reset
    } = await fn({
      x,
      y,
      initialPlacement: placement,
      placement: statefulPlacement,
      strategy,
      middlewareData,
      rects,
      platform: platform2,
      elements: {
        reference,
        floating
      }
    });
    x = nextX != null ? nextX : x;
    y = nextY != null ? nextY : y;
    middlewareData = {
      ...middlewareData,
      [name]: {
        ...middlewareData[name],
        ...data
      }
    };
    if (reset && resetCount <= 50) {
      resetCount++;
      if (typeof reset === "object") {
        if (reset.placement) {
          statefulPlacement = reset.placement;
        }
        if (reset.rects) {
          rects = reset.rects === true ? await platform2.getElementRects({
            reference,
            floating,
            strategy
          }) : reset.rects;
        }
        ({
          x,
          y
        } = computeCoordsFromPlacement(rects, statefulPlacement, rtl));
      }
      i = -1;
    }
  }
  return {
    x,
    y,
    placement: statefulPlacement,
    strategy,
    middlewareData
  };
};
async function detectOverflow(state, options) {
  var _await$platform$isEle;
  if (options === void 0) {
    options = {};
  }
  const {
    x,
    y,
    platform: platform2,
    rects,
    elements,
    strategy
  } = state;
  const {
    boundary = "clippingAncestors",
    rootBoundary = "viewport",
    elementContext = "floating",
    altBoundary = false,
    padding = 0
  } = evaluate(options, state);
  const paddingObject = getPaddingObject(padding);
  const altContext = elementContext === "floating" ? "reference" : "floating";
  const element = elements[altBoundary ? altContext : elementContext];
  const clippingClientRect = rectToClientRect(await platform2.getClippingRect({
    element: ((_await$platform$isEle = await (platform2.isElement == null ? void 0 : platform2.isElement(element))) != null ? _await$platform$isEle : true) ? element : element.contextElement || await (platform2.getDocumentElement == null ? void 0 : platform2.getDocumentElement(elements.floating)),
    boundary,
    rootBoundary,
    strategy
  }));
  const rect = elementContext === "floating" ? {
    x,
    y,
    width: rects.floating.width,
    height: rects.floating.height
  } : rects.reference;
  const offsetParent = await (platform2.getOffsetParent == null ? void 0 : platform2.getOffsetParent(elements.floating));
  const offsetScale = await (platform2.isElement == null ? void 0 : platform2.isElement(offsetParent)) ? await (platform2.getScale == null ? void 0 : platform2.getScale(offsetParent)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  };
  const elementClientRect = rectToClientRect(platform2.convertOffsetParentRelativeRectToViewportRelativeRect ? await platform2.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements,
    rect,
    offsetParent,
    strategy
  }) : rect);
  return {
    top: (clippingClientRect.top - elementClientRect.top + paddingObject.top) / offsetScale.y,
    bottom: (elementClientRect.bottom - clippingClientRect.bottom + paddingObject.bottom) / offsetScale.y,
    left: (clippingClientRect.left - elementClientRect.left + paddingObject.left) / offsetScale.x,
    right: (elementClientRect.right - clippingClientRect.right + paddingObject.right) / offsetScale.x
  };
}
var arrow = (options) => ({
  name: "arrow",
  options,
  async fn(state) {
    const {
      x,
      y,
      placement,
      rects,
      platform: platform2,
      elements,
      middlewareData
    } = state;
    const {
      element,
      padding = 0
    } = evaluate(options, state) || {};
    if (element == null) {
      return {};
    }
    const paddingObject = getPaddingObject(padding);
    const coords = {
      x,
      y
    };
    const axis = getAlignmentAxis(placement);
    const length = getAxisLength(axis);
    const arrowDimensions = await platform2.getDimensions(element);
    const isYAxis = axis === "y";
    const minProp = isYAxis ? "top" : "left";
    const maxProp = isYAxis ? "bottom" : "right";
    const clientProp = isYAxis ? "clientHeight" : "clientWidth";
    const endDiff = rects.reference[length] + rects.reference[axis] - coords[axis] - rects.floating[length];
    const startDiff = coords[axis] - rects.reference[axis];
    const arrowOffsetParent = await (platform2.getOffsetParent == null ? void 0 : platform2.getOffsetParent(element));
    let clientSize = arrowOffsetParent ? arrowOffsetParent[clientProp] : 0;
    if (!clientSize || !await (platform2.isElement == null ? void 0 : platform2.isElement(arrowOffsetParent))) {
      clientSize = elements.floating[clientProp] || rects.floating[length];
    }
    const centerToReference = endDiff / 2 - startDiff / 2;
    const largestPossiblePadding = clientSize / 2 - arrowDimensions[length] / 2 - 1;
    const minPadding = min(paddingObject[minProp], largestPossiblePadding);
    const maxPadding = min(paddingObject[maxProp], largestPossiblePadding);
    const min$1 = minPadding;
    const max2 = clientSize - arrowDimensions[length] - maxPadding;
    const center = clientSize / 2 - arrowDimensions[length] / 2 + centerToReference;
    const offset4 = clamp(min$1, center, max2);
    const shouldAddOffset = !middlewareData.arrow && getAlignment(placement) != null && center !== offset4 && rects.reference[length] / 2 - (center < min$1 ? minPadding : maxPadding) - arrowDimensions[length] / 2 < 0;
    const alignmentOffset = shouldAddOffset ? center < min$1 ? center - min$1 : center - max2 : 0;
    return {
      [axis]: coords[axis] + alignmentOffset,
      data: {
        [axis]: offset4,
        centerOffset: center - offset4 - alignmentOffset,
        ...shouldAddOffset && {
          alignmentOffset
        }
      },
      reset: shouldAddOffset
    };
  }
});
var flip = function(options) {
  if (options === void 0) {
    options = {};
  }
  return {
    name: "flip",
    options,
    async fn(state) {
      var _middlewareData$arrow, _middlewareData$flip;
      const {
        placement,
        middlewareData,
        rects,
        initialPlacement,
        platform: platform2,
        elements
      } = state;
      const {
        mainAxis: checkMainAxis = true,
        crossAxis: checkCrossAxis = true,
        fallbackPlacements: specifiedFallbackPlacements,
        fallbackStrategy = "bestFit",
        fallbackAxisSideDirection = "none",
        flipAlignment = true,
        ...detectOverflowOptions
      } = evaluate(options, state);
      if ((_middlewareData$arrow = middlewareData.arrow) != null && _middlewareData$arrow.alignmentOffset) {
        return {};
      }
      const side = getSide(placement);
      const initialSideAxis = getSideAxis(initialPlacement);
      const isBasePlacement = getSide(initialPlacement) === initialPlacement;
      const rtl = await (platform2.isRTL == null ? void 0 : platform2.isRTL(elements.floating));
      const fallbackPlacements = specifiedFallbackPlacements || (isBasePlacement || !flipAlignment ? [getOppositePlacement(initialPlacement)] : getExpandedPlacements(initialPlacement));
      const hasFallbackAxisSideDirection = fallbackAxisSideDirection !== "none";
      if (!specifiedFallbackPlacements && hasFallbackAxisSideDirection) {
        fallbackPlacements.push(...getOppositeAxisPlacements(initialPlacement, flipAlignment, fallbackAxisSideDirection, rtl));
      }
      const placements2 = [initialPlacement, ...fallbackPlacements];
      const overflow = await detectOverflow(state, detectOverflowOptions);
      const overflows = [];
      let overflowsData = ((_middlewareData$flip = middlewareData.flip) == null ? void 0 : _middlewareData$flip.overflows) || [];
      if (checkMainAxis) {
        overflows.push(overflow[side]);
      }
      if (checkCrossAxis) {
        const sides2 = getAlignmentSides(placement, rects, rtl);
        overflows.push(overflow[sides2[0]], overflow[sides2[1]]);
      }
      overflowsData = [...overflowsData, {
        placement,
        overflows
      }];
      if (!overflows.every((side2) => side2 <= 0)) {
        var _middlewareData$flip2, _overflowsData$filter;
        const nextIndex = (((_middlewareData$flip2 = middlewareData.flip) == null ? void 0 : _middlewareData$flip2.index) || 0) + 1;
        const nextPlacement = placements2[nextIndex];
        if (nextPlacement) {
          return {
            data: {
              index: nextIndex,
              overflows: overflowsData
            },
            reset: {
              placement: nextPlacement
            }
          };
        }
        let resetPlacement = (_overflowsData$filter = overflowsData.filter((d) => d.overflows[0] <= 0).sort((a, b) => a.overflows[1] - b.overflows[1])[0]) == null ? void 0 : _overflowsData$filter.placement;
        if (!resetPlacement) {
          switch (fallbackStrategy) {
            case "bestFit": {
              var _overflowsData$filter2;
              const placement2 = (_overflowsData$filter2 = overflowsData.filter((d) => {
                if (hasFallbackAxisSideDirection) {
                  const currentSideAxis = getSideAxis(d.placement);
                  return currentSideAxis === initialSideAxis || // Create a bias to the `y` side axis due to horizontal
                  // reading directions favoring greater width.
                  currentSideAxis === "y";
                }
                return true;
              }).map((d) => [d.placement, d.overflows.filter((overflow2) => overflow2 > 0).reduce((acc, overflow2) => acc + overflow2, 0)]).sort((a, b) => a[1] - b[1])[0]) == null ? void 0 : _overflowsData$filter2[0];
              if (placement2) {
                resetPlacement = placement2;
              }
              break;
            }
            case "initialPlacement":
              resetPlacement = initialPlacement;
              break;
          }
        }
        if (placement !== resetPlacement) {
          return {
            reset: {
              placement: resetPlacement
            }
          };
        }
      }
      return {};
    }
  };
};
function getSideOffsets(overflow, rect) {
  return {
    top: overflow.top - rect.height,
    right: overflow.right - rect.width,
    bottom: overflow.bottom - rect.height,
    left: overflow.left - rect.width
  };
}
function isAnySideFullyClipped(overflow) {
  return sides.some((side) => overflow[side] >= 0);
}
var hide = function(options) {
  if (options === void 0) {
    options = {};
  }
  return {
    name: "hide",
    options,
    async fn(state) {
      const {
        rects
      } = state;
      const {
        strategy = "referenceHidden",
        ...detectOverflowOptions
      } = evaluate(options, state);
      switch (strategy) {
        case "referenceHidden": {
          const overflow = await detectOverflow(state, {
            ...detectOverflowOptions,
            elementContext: "reference"
          });
          const offsets = getSideOffsets(overflow, rects.reference);
          return {
            data: {
              referenceHiddenOffsets: offsets,
              referenceHidden: isAnySideFullyClipped(offsets)
            }
          };
        }
        case "escaped": {
          const overflow = await detectOverflow(state, {
            ...detectOverflowOptions,
            altBoundary: true
          });
          const offsets = getSideOffsets(overflow, rects.floating);
          return {
            data: {
              escapedOffsets: offsets,
              escaped: isAnySideFullyClipped(offsets)
            }
          };
        }
        default: {
          return {};
        }
      }
    }
  };
};
async function convertValueToCoords(state, options) {
  const {
    placement,
    platform: platform2,
    elements
  } = state;
  const rtl = await (platform2.isRTL == null ? void 0 : platform2.isRTL(elements.floating));
  const side = getSide(placement);
  const alignment = getAlignment(placement);
  const isVertical = getSideAxis(placement) === "y";
  const mainAxisMulti = ["left", "top"].includes(side) ? -1 : 1;
  const crossAxisMulti = rtl && isVertical ? -1 : 1;
  const rawValue = evaluate(options, state);
  let {
    mainAxis,
    crossAxis,
    alignmentAxis
  } = typeof rawValue === "number" ? {
    mainAxis: rawValue,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: rawValue.mainAxis || 0,
    crossAxis: rawValue.crossAxis || 0,
    alignmentAxis: rawValue.alignmentAxis
  };
  if (alignment && typeof alignmentAxis === "number") {
    crossAxis = alignment === "end" ? alignmentAxis * -1 : alignmentAxis;
  }
  return isVertical ? {
    x: crossAxis * crossAxisMulti,
    y: mainAxis * mainAxisMulti
  } : {
    x: mainAxis * mainAxisMulti,
    y: crossAxis * crossAxisMulti
  };
}
var offset = function(options) {
  if (options === void 0) {
    options = 0;
  }
  return {
    name: "offset",
    options,
    async fn(state) {
      var _middlewareData$offse, _middlewareData$arrow;
      const {
        x,
        y,
        placement,
        middlewareData
      } = state;
      const diffCoords = await convertValueToCoords(state, options);
      if (placement === ((_middlewareData$offse = middlewareData.offset) == null ? void 0 : _middlewareData$offse.placement) && (_middlewareData$arrow = middlewareData.arrow) != null && _middlewareData$arrow.alignmentOffset) {
        return {};
      }
      return {
        x: x + diffCoords.x,
        y: y + diffCoords.y,
        data: {
          ...diffCoords,
          placement
        }
      };
    }
  };
};
var shift = function(options) {
  if (options === void 0) {
    options = {};
  }
  return {
    name: "shift",
    options,
    async fn(state) {
      const {
        x,
        y,
        placement
      } = state;
      const {
        mainAxis: checkMainAxis = true,
        crossAxis: checkCrossAxis = false,
        limiter = {
          fn: (_ref) => {
            let {
              x: x2,
              y: y2
            } = _ref;
            return {
              x: x2,
              y: y2
            };
          }
        },
        ...detectOverflowOptions
      } = evaluate(options, state);
      const coords = {
        x,
        y
      };
      const overflow = await detectOverflow(state, detectOverflowOptions);
      const crossAxis = getSideAxis(getSide(placement));
      const mainAxis = getOppositeAxis(crossAxis);
      let mainAxisCoord = coords[mainAxis];
      let crossAxisCoord = coords[crossAxis];
      if (checkMainAxis) {
        const minSide = mainAxis === "y" ? "top" : "left";
        const maxSide = mainAxis === "y" ? "bottom" : "right";
        const min2 = mainAxisCoord + overflow[minSide];
        const max2 = mainAxisCoord - overflow[maxSide];
        mainAxisCoord = clamp(min2, mainAxisCoord, max2);
      }
      if (checkCrossAxis) {
        const minSide = crossAxis === "y" ? "top" : "left";
        const maxSide = crossAxis === "y" ? "bottom" : "right";
        const min2 = crossAxisCoord + overflow[minSide];
        const max2 = crossAxisCoord - overflow[maxSide];
        crossAxisCoord = clamp(min2, crossAxisCoord, max2);
      }
      const limitedCoords = limiter.fn({
        ...state,
        [mainAxis]: mainAxisCoord,
        [crossAxis]: crossAxisCoord
      });
      return {
        ...limitedCoords,
        data: {
          x: limitedCoords.x - x,
          y: limitedCoords.y - y,
          enabled: {
            [mainAxis]: checkMainAxis,
            [crossAxis]: checkCrossAxis
          }
        }
      };
    }
  };
};
var limitShift = function(options) {
  if (options === void 0) {
    options = {};
  }
  return {
    options,
    fn(state) {
      const {
        x,
        y,
        placement,
        rects,
        middlewareData
      } = state;
      const {
        offset: offset4 = 0,
        mainAxis: checkMainAxis = true,
        crossAxis: checkCrossAxis = true
      } = evaluate(options, state);
      const coords = {
        x,
        y
      };
      const crossAxis = getSideAxis(placement);
      const mainAxis = getOppositeAxis(crossAxis);
      let mainAxisCoord = coords[mainAxis];
      let crossAxisCoord = coords[crossAxis];
      const rawOffset = evaluate(offset4, state);
      const computedOffset = typeof rawOffset === "number" ? {
        mainAxis: rawOffset,
        crossAxis: 0
      } : {
        mainAxis: 0,
        crossAxis: 0,
        ...rawOffset
      };
      if (checkMainAxis) {
        const len = mainAxis === "y" ? "height" : "width";
        const limitMin = rects.reference[mainAxis] - rects.floating[len] + computedOffset.mainAxis;
        const limitMax = rects.reference[mainAxis] + rects.reference[len] - computedOffset.mainAxis;
        if (mainAxisCoord < limitMin) {
          mainAxisCoord = limitMin;
        } else if (mainAxisCoord > limitMax) {
          mainAxisCoord = limitMax;
        }
      }
      if (checkCrossAxis) {
        var _middlewareData$offse, _middlewareData$offse2;
        const len = mainAxis === "y" ? "width" : "height";
        const isOriginSide = ["top", "left"].includes(getSide(placement));
        const limitMin = rects.reference[crossAxis] - rects.floating[len] + (isOriginSide ? ((_middlewareData$offse = middlewareData.offset) == null ? void 0 : _middlewareData$offse[crossAxis]) || 0 : 0) + (isOriginSide ? 0 : computedOffset.crossAxis);
        const limitMax = rects.reference[crossAxis] + rects.reference[len] + (isOriginSide ? 0 : ((_middlewareData$offse2 = middlewareData.offset) == null ? void 0 : _middlewareData$offse2[crossAxis]) || 0) - (isOriginSide ? computedOffset.crossAxis : 0);
        if (crossAxisCoord < limitMin) {
          crossAxisCoord = limitMin;
        } else if (crossAxisCoord > limitMax) {
          crossAxisCoord = limitMax;
        }
      }
      return {
        [mainAxis]: mainAxisCoord,
        [crossAxis]: crossAxisCoord
      };
    }
  };
};
var size = function(options) {
  if (options === void 0) {
    options = {};
  }
  return {
    name: "size",
    options,
    async fn(state) {
      var _state$middlewareData, _state$middlewareData2;
      const {
        placement,
        rects,
        platform: platform2,
        elements
      } = state;
      const {
        apply = () => {
        },
        ...detectOverflowOptions
      } = evaluate(options, state);
      const overflow = await detectOverflow(state, detectOverflowOptions);
      const side = getSide(placement);
      const alignment = getAlignment(placement);
      const isYAxis = getSideAxis(placement) === "y";
      const {
        width,
        height
      } = rects.floating;
      let heightSide;
      let widthSide;
      if (side === "top" || side === "bottom") {
        heightSide = side;
        widthSide = alignment === (await (platform2.isRTL == null ? void 0 : platform2.isRTL(elements.floating)) ? "start" : "end") ? "left" : "right";
      } else {
        widthSide = side;
        heightSide = alignment === "end" ? "top" : "bottom";
      }
      const maximumClippingHeight = height - overflow.top - overflow.bottom;
      const maximumClippingWidth = width - overflow.left - overflow.right;
      const overflowAvailableHeight = min(height - overflow[heightSide], maximumClippingHeight);
      const overflowAvailableWidth = min(width - overflow[widthSide], maximumClippingWidth);
      const noShift = !state.middlewareData.shift;
      let availableHeight = overflowAvailableHeight;
      let availableWidth = overflowAvailableWidth;
      if ((_state$middlewareData = state.middlewareData.shift) != null && _state$middlewareData.enabled.x) {
        availableWidth = maximumClippingWidth;
      }
      if ((_state$middlewareData2 = state.middlewareData.shift) != null && _state$middlewareData2.enabled.y) {
        availableHeight = maximumClippingHeight;
      }
      if (noShift && !alignment) {
        const xMin = max(overflow.left, 0);
        const xMax = max(overflow.right, 0);
        const yMin = max(overflow.top, 0);
        const yMax = max(overflow.bottom, 0);
        if (isYAxis) {
          availableWidth = width - 2 * (xMin !== 0 || xMax !== 0 ? xMin + xMax : max(overflow.left, overflow.right));
        } else {
          availableHeight = height - 2 * (yMin !== 0 || yMax !== 0 ? yMin + yMax : max(overflow.top, overflow.bottom));
        }
      }
      await apply({
        ...state,
        availableWidth,
        availableHeight
      });
      const nextDimensions = await platform2.getDimensions(elements.floating);
      if (width !== nextDimensions.width || height !== nextDimensions.height) {
        return {
          reset: {
            rects: true
          }
        };
      }
      return {};
    }
  };
};

// ../../node_modules/@floating-ui/utils/dist/floating-ui.utils.dom.mjs
function hasWindow() {
  return typeof window !== "undefined";
}
function getNodeName(node) {
  if (isNode(node)) {
    return (node.nodeName || "").toLowerCase();
  }
  return "#document";
}
function getWindow(node) {
  var _node$ownerDocument;
  return (node == null || (_node$ownerDocument = node.ownerDocument) == null ? void 0 : _node$ownerDocument.defaultView) || window;
}
function getDocumentElement(node) {
  var _ref;
  return (_ref = (isNode(node) ? node.ownerDocument : node.document) || window.document) == null ? void 0 : _ref.documentElement;
}
function isNode(value) {
  if (!hasWindow()) {
    return false;
  }
  return value instanceof Node || value instanceof getWindow(value).Node;
}
function isElement(value) {
  if (!hasWindow()) {
    return false;
  }
  return value instanceof Element || value instanceof getWindow(value).Element;
}
function isHTMLElement(value) {
  if (!hasWindow()) {
    return false;
  }
  return value instanceof HTMLElement || value instanceof getWindow(value).HTMLElement;
}
function isShadowRoot(value) {
  if (!hasWindow() || typeof ShadowRoot === "undefined") {
    return false;
  }
  return value instanceof ShadowRoot || value instanceof getWindow(value).ShadowRoot;
}
function isOverflowElement(element) {
  const {
    overflow,
    overflowX,
    overflowY,
    display
  } = getComputedStyle2(element);
  return /auto|scroll|overlay|hidden|clip/.test(overflow + overflowY + overflowX) && !["inline", "contents"].includes(display);
}
function isTableElement(element) {
  return ["table", "td", "th"].includes(getNodeName(element));
}
function isTopLayer(element) {
  return [":popover-open", ":modal"].some((selector) => {
    try {
      return element.matches(selector);
    } catch (e) {
      return false;
    }
  });
}
function isContainingBlock(elementOrCss) {
  const webkit = isWebKit();
  const css42 = isElement(elementOrCss) ? getComputedStyle2(elementOrCss) : elementOrCss;
  return css42.transform !== "none" || css42.perspective !== "none" || (css42.containerType ? css42.containerType !== "normal" : false) || !webkit && (css42.backdropFilter ? css42.backdropFilter !== "none" : false) || !webkit && (css42.filter ? css42.filter !== "none" : false) || ["transform", "perspective", "filter"].some((value) => (css42.willChange || "").includes(value)) || ["paint", "layout", "strict", "content"].some((value) => (css42.contain || "").includes(value));
}
function getContainingBlock(element) {
  let currentNode = getParentNode(element);
  while (isHTMLElement(currentNode) && !isLastTraversableNode(currentNode)) {
    if (isContainingBlock(currentNode)) {
      return currentNode;
    } else if (isTopLayer(currentNode)) {
      return null;
    }
    currentNode = getParentNode(currentNode);
  }
  return null;
}
function isWebKit() {
  if (typeof CSS === "undefined" || !CSS.supports) return false;
  return CSS.supports("-webkit-backdrop-filter", "none");
}
function isLastTraversableNode(node) {
  return ["html", "body", "#document"].includes(getNodeName(node));
}
function getComputedStyle2(element) {
  return getWindow(element).getComputedStyle(element);
}
function getNodeScroll(element) {
  if (isElement(element)) {
    return {
      scrollLeft: element.scrollLeft,
      scrollTop: element.scrollTop
    };
  }
  return {
    scrollLeft: element.scrollX,
    scrollTop: element.scrollY
  };
}
function getParentNode(node) {
  if (getNodeName(node) === "html") {
    return node;
  }
  const result = (
    // Step into the shadow DOM of the parent of a slotted node.
    node.assignedSlot || // DOM Element detected.
    node.parentNode || // ShadowRoot detected.
    isShadowRoot(node) && node.host || // Fallback.
    getDocumentElement(node)
  );
  return isShadowRoot(result) ? result.host : result;
}
function getNearestOverflowAncestor(node) {
  const parentNode = getParentNode(node);
  if (isLastTraversableNode(parentNode)) {
    return node.ownerDocument ? node.ownerDocument.body : node.body;
  }
  if (isHTMLElement(parentNode) && isOverflowElement(parentNode)) {
    return parentNode;
  }
  return getNearestOverflowAncestor(parentNode);
}
function getOverflowAncestors(node, list, traverseIframes) {
  var _node$ownerDocument2;
  if (list === void 0) {
    list = [];
  }
  if (traverseIframes === void 0) {
    traverseIframes = true;
  }
  const scrollableAncestor = getNearestOverflowAncestor(node);
  const isBody = scrollableAncestor === ((_node$ownerDocument2 = node.ownerDocument) == null ? void 0 : _node$ownerDocument2.body);
  const win = getWindow(scrollableAncestor);
  if (isBody) {
    const frameElement = getFrameElement(win);
    return list.concat(win, win.visualViewport || [], isOverflowElement(scrollableAncestor) ? scrollableAncestor : [], frameElement && traverseIframes ? getOverflowAncestors(frameElement) : []);
  }
  return list.concat(scrollableAncestor, getOverflowAncestors(scrollableAncestor, [], traverseIframes));
}
function getFrameElement(win) {
  return win.parent && Object.getPrototypeOf(win.parent) ? win.frameElement : null;
}

// ../../node_modules/@floating-ui/dom/dist/floating-ui.dom.mjs
function getCssDimensions(element) {
  const css42 = getComputedStyle2(element);
  let width = parseFloat(css42.width) || 0;
  let height = parseFloat(css42.height) || 0;
  const hasOffset = isHTMLElement(element);
  const offsetWidth = hasOffset ? element.offsetWidth : width;
  const offsetHeight = hasOffset ? element.offsetHeight : height;
  const shouldFallback = round(width) !== offsetWidth || round(height) !== offsetHeight;
  if (shouldFallback) {
    width = offsetWidth;
    height = offsetHeight;
  }
  return {
    width,
    height,
    $: shouldFallback
  };
}
function unwrapElement(element) {
  return !isElement(element) ? element.contextElement : element;
}
function getScale(element) {
  const domElement = unwrapElement(element);
  if (!isHTMLElement(domElement)) {
    return createCoords(1);
  }
  const rect = domElement.getBoundingClientRect();
  const {
    width,
    height,
    $
  } = getCssDimensions(domElement);
  let x = ($ ? round(rect.width) : rect.width) / width;
  let y = ($ ? round(rect.height) : rect.height) / height;
  if (!x || !Number.isFinite(x)) {
    x = 1;
  }
  if (!y || !Number.isFinite(y)) {
    y = 1;
  }
  return {
    x,
    y
  };
}
var noOffsets = /* @__PURE__ */ createCoords(0);
function getVisualOffsets(element) {
  const win = getWindow(element);
  if (!isWebKit() || !win.visualViewport) {
    return noOffsets;
  }
  return {
    x: win.visualViewport.offsetLeft,
    y: win.visualViewport.offsetTop
  };
}
function shouldAddVisualOffsets(element, isFixed, floatingOffsetParent) {
  if (isFixed === void 0) {
    isFixed = false;
  }
  if (!floatingOffsetParent || isFixed && floatingOffsetParent !== getWindow(element)) {
    return false;
  }
  return isFixed;
}
function getBoundingClientRect(element, includeScale, isFixedStrategy, offsetParent) {
  if (includeScale === void 0) {
    includeScale = false;
  }
  if (isFixedStrategy === void 0) {
    isFixedStrategy = false;
  }
  const clientRect = element.getBoundingClientRect();
  const domElement = unwrapElement(element);
  let scale = createCoords(1);
  if (includeScale) {
    if (offsetParent) {
      if (isElement(offsetParent)) {
        scale = getScale(offsetParent);
      }
    } else {
      scale = getScale(element);
    }
  }
  const visualOffsets = shouldAddVisualOffsets(domElement, isFixedStrategy, offsetParent) ? getVisualOffsets(domElement) : createCoords(0);
  let x = (clientRect.left + visualOffsets.x) / scale.x;
  let y = (clientRect.top + visualOffsets.y) / scale.y;
  let width = clientRect.width / scale.x;
  let height = clientRect.height / scale.y;
  if (domElement) {
    const win = getWindow(domElement);
    const offsetWin = offsetParent && isElement(offsetParent) ? getWindow(offsetParent) : offsetParent;
    let currentWin = win;
    let currentIFrame = getFrameElement(currentWin);
    while (currentIFrame && offsetParent && offsetWin !== currentWin) {
      const iframeScale = getScale(currentIFrame);
      const iframeRect = currentIFrame.getBoundingClientRect();
      const css42 = getComputedStyle2(currentIFrame);
      const left = iframeRect.left + (currentIFrame.clientLeft + parseFloat(css42.paddingLeft)) * iframeScale.x;
      const top = iframeRect.top + (currentIFrame.clientTop + parseFloat(css42.paddingTop)) * iframeScale.y;
      x *= iframeScale.x;
      y *= iframeScale.y;
      width *= iframeScale.x;
      height *= iframeScale.y;
      x += left;
      y += top;
      currentWin = getWindow(currentIFrame);
      currentIFrame = getFrameElement(currentWin);
    }
  }
  return rectToClientRect({
    width,
    height,
    x,
    y
  });
}
function getWindowScrollBarX(element, rect) {
  const leftScroll = getNodeScroll(element).scrollLeft;
  if (!rect) {
    return getBoundingClientRect(getDocumentElement(element)).left + leftScroll;
  }
  return rect.left + leftScroll;
}
function getHTMLOffset(documentElement, scroll, ignoreScrollbarX) {
  if (ignoreScrollbarX === void 0) {
    ignoreScrollbarX = false;
  }
  const htmlRect = documentElement.getBoundingClientRect();
  const x = htmlRect.left + scroll.scrollLeft - (ignoreScrollbarX ? 0 : (
    // RTL <body> scrollbar.
    getWindowScrollBarX(documentElement, htmlRect)
  ));
  const y = htmlRect.top + scroll.scrollTop;
  return {
    x,
    y
  };
}
function convertOffsetParentRelativeRectToViewportRelativeRect(_ref) {
  let {
    elements,
    rect,
    offsetParent,
    strategy
  } = _ref;
  const isFixed = strategy === "fixed";
  const documentElement = getDocumentElement(offsetParent);
  const topLayer = elements ? isTopLayer(elements.floating) : false;
  if (offsetParent === documentElement || topLayer && isFixed) {
    return rect;
  }
  let scroll = {
    scrollLeft: 0,
    scrollTop: 0
  };
  let scale = createCoords(1);
  const offsets = createCoords(0);
  const isOffsetParentAnElement = isHTMLElement(offsetParent);
  if (isOffsetParentAnElement || !isOffsetParentAnElement && !isFixed) {
    if (getNodeName(offsetParent) !== "body" || isOverflowElement(documentElement)) {
      scroll = getNodeScroll(offsetParent);
    }
    if (isHTMLElement(offsetParent)) {
      const offsetRect = getBoundingClientRect(offsetParent);
      scale = getScale(offsetParent);
      offsets.x = offsetRect.x + offsetParent.clientLeft;
      offsets.y = offsetRect.y + offsetParent.clientTop;
    }
  }
  const htmlOffset = documentElement && !isOffsetParentAnElement && !isFixed ? getHTMLOffset(documentElement, scroll, true) : createCoords(0);
  return {
    width: rect.width * scale.x,
    height: rect.height * scale.y,
    x: rect.x * scale.x - scroll.scrollLeft * scale.x + offsets.x + htmlOffset.x,
    y: rect.y * scale.y - scroll.scrollTop * scale.y + offsets.y + htmlOffset.y
  };
}
function getClientRects(element) {
  return Array.from(element.getClientRects());
}
function getDocumentRect(element) {
  const html = getDocumentElement(element);
  const scroll = getNodeScroll(element);
  const body = element.ownerDocument.body;
  const width = max(html.scrollWidth, html.clientWidth, body.scrollWidth, body.clientWidth);
  const height = max(html.scrollHeight, html.clientHeight, body.scrollHeight, body.clientHeight);
  let x = -scroll.scrollLeft + getWindowScrollBarX(element);
  const y = -scroll.scrollTop;
  if (getComputedStyle2(body).direction === "rtl") {
    x += max(html.clientWidth, body.clientWidth) - width;
  }
  return {
    width,
    height,
    x,
    y
  };
}
function getViewportRect(element, strategy) {
  const win = getWindow(element);
  const html = getDocumentElement(element);
  const visualViewport = win.visualViewport;
  let width = html.clientWidth;
  let height = html.clientHeight;
  let x = 0;
  let y = 0;
  if (visualViewport) {
    width = visualViewport.width;
    height = visualViewport.height;
    const visualViewportBased = isWebKit();
    if (!visualViewportBased || visualViewportBased && strategy === "fixed") {
      x = visualViewport.offsetLeft;
      y = visualViewport.offsetTop;
    }
  }
  return {
    width,
    height,
    x,
    y
  };
}
function getInnerBoundingClientRect(element, strategy) {
  const clientRect = getBoundingClientRect(element, true, strategy === "fixed");
  const top = clientRect.top + element.clientTop;
  const left = clientRect.left + element.clientLeft;
  const scale = isHTMLElement(element) ? getScale(element) : createCoords(1);
  const width = element.clientWidth * scale.x;
  const height = element.clientHeight * scale.y;
  const x = left * scale.x;
  const y = top * scale.y;
  return {
    width,
    height,
    x,
    y
  };
}
function getClientRectFromClippingAncestor(element, clippingAncestor, strategy) {
  let rect;
  if (clippingAncestor === "viewport") {
    rect = getViewportRect(element, strategy);
  } else if (clippingAncestor === "document") {
    rect = getDocumentRect(getDocumentElement(element));
  } else if (isElement(clippingAncestor)) {
    rect = getInnerBoundingClientRect(clippingAncestor, strategy);
  } else {
    const visualOffsets = getVisualOffsets(element);
    rect = {
      x: clippingAncestor.x - visualOffsets.x,
      y: clippingAncestor.y - visualOffsets.y,
      width: clippingAncestor.width,
      height: clippingAncestor.height
    };
  }
  return rectToClientRect(rect);
}
function hasFixedPositionAncestor(element, stopNode) {
  const parentNode = getParentNode(element);
  if (parentNode === stopNode || !isElement(parentNode) || isLastTraversableNode(parentNode)) {
    return false;
  }
  return getComputedStyle2(parentNode).position === "fixed" || hasFixedPositionAncestor(parentNode, stopNode);
}
function getClippingElementAncestors(element, cache) {
  const cachedResult = cache.get(element);
  if (cachedResult) {
    return cachedResult;
  }
  let result = getOverflowAncestors(element, [], false).filter((el) => isElement(el) && getNodeName(el) !== "body");
  let currentContainingBlockComputedStyle = null;
  const elementIsFixed = getComputedStyle2(element).position === "fixed";
  let currentNode = elementIsFixed ? getParentNode(element) : element;
  while (isElement(currentNode) && !isLastTraversableNode(currentNode)) {
    const computedStyle = getComputedStyle2(currentNode);
    const currentNodeIsContaining = isContainingBlock(currentNode);
    if (!currentNodeIsContaining && computedStyle.position === "fixed") {
      currentContainingBlockComputedStyle = null;
    }
    const shouldDropCurrentNode = elementIsFixed ? !currentNodeIsContaining && !currentContainingBlockComputedStyle : !currentNodeIsContaining && computedStyle.position === "static" && !!currentContainingBlockComputedStyle && ["absolute", "fixed"].includes(currentContainingBlockComputedStyle.position) || isOverflowElement(currentNode) && !currentNodeIsContaining && hasFixedPositionAncestor(element, currentNode);
    if (shouldDropCurrentNode) {
      result = result.filter((ancestor) => ancestor !== currentNode);
    } else {
      currentContainingBlockComputedStyle = computedStyle;
    }
    currentNode = getParentNode(currentNode);
  }
  cache.set(element, result);
  return result;
}
function getClippingRect(_ref) {
  let {
    element,
    boundary,
    rootBoundary,
    strategy
  } = _ref;
  const elementClippingAncestors = boundary === "clippingAncestors" ? isTopLayer(element) ? [] : getClippingElementAncestors(element, this._c) : [].concat(boundary);
  const clippingAncestors = [...elementClippingAncestors, rootBoundary];
  const firstClippingAncestor = clippingAncestors[0];
  const clippingRect = clippingAncestors.reduce((accRect, clippingAncestor) => {
    const rect = getClientRectFromClippingAncestor(element, clippingAncestor, strategy);
    accRect.top = max(rect.top, accRect.top);
    accRect.right = min(rect.right, accRect.right);
    accRect.bottom = min(rect.bottom, accRect.bottom);
    accRect.left = max(rect.left, accRect.left);
    return accRect;
  }, getClientRectFromClippingAncestor(element, firstClippingAncestor, strategy));
  return {
    width: clippingRect.right - clippingRect.left,
    height: clippingRect.bottom - clippingRect.top,
    x: clippingRect.left,
    y: clippingRect.top
  };
}
function getDimensions(element) {
  const {
    width,
    height
  } = getCssDimensions(element);
  return {
    width,
    height
  };
}
function getRectRelativeToOffsetParent(element, offsetParent, strategy) {
  const isOffsetParentAnElement = isHTMLElement(offsetParent);
  const documentElement = getDocumentElement(offsetParent);
  const isFixed = strategy === "fixed";
  const rect = getBoundingClientRect(element, true, isFixed, offsetParent);
  let scroll = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const offsets = createCoords(0);
  if (isOffsetParentAnElement || !isOffsetParentAnElement && !isFixed) {
    if (getNodeName(offsetParent) !== "body" || isOverflowElement(documentElement)) {
      scroll = getNodeScroll(offsetParent);
    }
    if (isOffsetParentAnElement) {
      const offsetRect = getBoundingClientRect(offsetParent, true, isFixed, offsetParent);
      offsets.x = offsetRect.x + offsetParent.clientLeft;
      offsets.y = offsetRect.y + offsetParent.clientTop;
    } else if (documentElement) {
      offsets.x = getWindowScrollBarX(documentElement);
    }
  }
  const htmlOffset = documentElement && !isOffsetParentAnElement && !isFixed ? getHTMLOffset(documentElement, scroll) : createCoords(0);
  const x = rect.left + scroll.scrollLeft - offsets.x - htmlOffset.x;
  const y = rect.top + scroll.scrollTop - offsets.y - htmlOffset.y;
  return {
    x,
    y,
    width: rect.width,
    height: rect.height
  };
}
function isStaticPositioned(element) {
  return getComputedStyle2(element).position === "static";
}
function getTrueOffsetParent(element, polyfill) {
  if (!isHTMLElement(element) || getComputedStyle2(element).position === "fixed") {
    return null;
  }
  if (polyfill) {
    return polyfill(element);
  }
  let rawOffsetParent = element.offsetParent;
  if (getDocumentElement(element) === rawOffsetParent) {
    rawOffsetParent = rawOffsetParent.ownerDocument.body;
  }
  return rawOffsetParent;
}
function getOffsetParent(element, polyfill) {
  const win = getWindow(element);
  if (isTopLayer(element)) {
    return win;
  }
  if (!isHTMLElement(element)) {
    let svgOffsetParent = getParentNode(element);
    while (svgOffsetParent && !isLastTraversableNode(svgOffsetParent)) {
      if (isElement(svgOffsetParent) && !isStaticPositioned(svgOffsetParent)) {
        return svgOffsetParent;
      }
      svgOffsetParent = getParentNode(svgOffsetParent);
    }
    return win;
  }
  let offsetParent = getTrueOffsetParent(element, polyfill);
  while (offsetParent && isTableElement(offsetParent) && isStaticPositioned(offsetParent)) {
    offsetParent = getTrueOffsetParent(offsetParent, polyfill);
  }
  if (offsetParent && isLastTraversableNode(offsetParent) && isStaticPositioned(offsetParent) && !isContainingBlock(offsetParent)) {
    return win;
  }
  return offsetParent || getContainingBlock(element) || win;
}
var getElementRects = async function(data) {
  const getOffsetParentFn = this.getOffsetParent || getOffsetParent;
  const getDimensionsFn = this.getDimensions;
  const floatingDimensions = await getDimensionsFn(data.floating);
  return {
    reference: getRectRelativeToOffsetParent(data.reference, await getOffsetParentFn(data.floating), data.strategy),
    floating: {
      x: 0,
      y: 0,
      width: floatingDimensions.width,
      height: floatingDimensions.height
    }
  };
};
function isRTL(element) {
  return getComputedStyle2(element).direction === "rtl";
}
var platform = {
  convertOffsetParentRelativeRectToViewportRelativeRect,
  getDocumentElement,
  getClippingRect,
  getOffsetParent,
  getElementRects,
  getClientRects,
  getDimensions,
  getScale,
  isElement,
  isRTL
};
function observeMove(element, onMove) {
  let io = null;
  let timeoutId;
  const root = getDocumentElement(element);
  function cleanup() {
    var _io;
    clearTimeout(timeoutId);
    (_io = io) == null || _io.disconnect();
    io = null;
  }
  function refresh(skip, threshold) {
    if (skip === void 0) {
      skip = false;
    }
    if (threshold === void 0) {
      threshold = 1;
    }
    cleanup();
    const {
      left,
      top,
      width,
      height
    } = element.getBoundingClientRect();
    if (!skip) {
      onMove();
    }
    if (!width || !height) {
      return;
    }
    const insetTop = floor(top);
    const insetRight = floor(root.clientWidth - (left + width));
    const insetBottom = floor(root.clientHeight - (top + height));
    const insetLeft = floor(left);
    const rootMargin = -insetTop + "px " + -insetRight + "px " + -insetBottom + "px " + -insetLeft + "px";
    const options = {
      rootMargin,
      threshold: max(0, min(1, threshold)) || 1
    };
    let isFirstUpdate = true;
    function handleObserve(entries) {
      const ratio = entries[0].intersectionRatio;
      if (ratio !== threshold) {
        if (!isFirstUpdate) {
          return refresh();
        }
        if (!ratio) {
          timeoutId = setTimeout(() => {
            refresh(false, 1e-7);
          }, 1e3);
        } else {
          refresh(false, ratio);
        }
      }
      isFirstUpdate = false;
    }
    try {
      io = new IntersectionObserver(handleObserve, {
        ...options,
        // Handle <iframe>s
        root: root.ownerDocument
      });
    } catch (e) {
      io = new IntersectionObserver(handleObserve, options);
    }
    io.observe(element);
  }
  refresh(true);
  return cleanup;
}
function autoUpdate(reference, floating, update, options) {
  if (options === void 0) {
    options = {};
  }
  const {
    ancestorScroll = true,
    ancestorResize = true,
    elementResize = typeof ResizeObserver === "function",
    layoutShift = typeof IntersectionObserver === "function",
    animationFrame = false
  } = options;
  const referenceEl = unwrapElement(reference);
  const ancestors = ancestorScroll || ancestorResize ? [...referenceEl ? getOverflowAncestors(referenceEl) : [], ...getOverflowAncestors(floating)] : [];
  ancestors.forEach((ancestor) => {
    ancestorScroll && ancestor.addEventListener("scroll", update, {
      passive: true
    });
    ancestorResize && ancestor.addEventListener("resize", update);
  });
  const cleanupIo = referenceEl && layoutShift ? observeMove(referenceEl, update) : null;
  let reobserveFrame = -1;
  let resizeObserver = null;
  if (elementResize) {
    resizeObserver = new ResizeObserver((_ref) => {
      let [firstEntry] = _ref;
      if (firstEntry && firstEntry.target === referenceEl && resizeObserver) {
        resizeObserver.unobserve(floating);
        cancelAnimationFrame(reobserveFrame);
        reobserveFrame = requestAnimationFrame(() => {
          var _resizeObserver;
          (_resizeObserver = resizeObserver) == null || _resizeObserver.observe(floating);
        });
      }
      update();
    });
    if (referenceEl && !animationFrame) {
      resizeObserver.observe(referenceEl);
    }
    resizeObserver.observe(floating);
  }
  let frameId;
  let prevRefRect = animationFrame ? getBoundingClientRect(reference) : null;
  if (animationFrame) {
    frameLoop();
  }
  function frameLoop() {
    const nextRefRect = getBoundingClientRect(reference);
    if (prevRefRect && (nextRefRect.x !== prevRefRect.x || nextRefRect.y !== prevRefRect.y || nextRefRect.width !== prevRefRect.width || nextRefRect.height !== prevRefRect.height)) {
      update();
    }
    prevRefRect = nextRefRect;
    frameId = requestAnimationFrame(frameLoop);
  }
  update();
  return () => {
    var _resizeObserver2;
    ancestors.forEach((ancestor) => {
      ancestorScroll && ancestor.removeEventListener("scroll", update);
      ancestorResize && ancestor.removeEventListener("resize", update);
    });
    cleanupIo == null || cleanupIo();
    (_resizeObserver2 = resizeObserver) == null || _resizeObserver2.disconnect();
    resizeObserver = null;
    if (animationFrame) {
      cancelAnimationFrame(frameId);
    }
  };
}
var offset2 = offset;
var shift2 = shift;
var flip2 = flip;
var size2 = size;
var hide2 = hide;
var arrow2 = arrow;
var limitShift2 = limitShift;
var computePosition2 = (reference, floating, options) => {
  const cache = /* @__PURE__ */ new Map();
  const mergedOptions = {
    platform,
    ...options
  };
  const platformWithCache = {
    ...mergedOptions.platform,
    _c: cache
  };
  return computePosition(reference, floating, {
    ...mergedOptions,
    platform: platformWithCache
  });
};

// ../../node_modules/@floating-ui/react-dom/dist/floating-ui.react-dom.mjs
import * as React32 from "react";
import { useLayoutEffect as useLayoutEffect3, useEffect as useEffect7 } from "react";
import * as ReactDOM3 from "react-dom";
var index = typeof document !== "undefined" ? useLayoutEffect3 : useEffect7;
function deepEqual(a, b) {
  if (a === b) {
    return true;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  if (typeof a === "function" && a.toString() === b.toString()) {
    return true;
  }
  let length;
  let i;
  let keys;
  if (a && b && typeof a === "object") {
    if (Array.isArray(a)) {
      length = a.length;
      if (length !== b.length) return false;
      for (i = length; i-- !== 0; ) {
        if (!deepEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }
    keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) {
      return false;
    }
    for (i = length; i-- !== 0; ) {
      if (!{}.hasOwnProperty.call(b, keys[i])) {
        return false;
      }
    }
    for (i = length; i-- !== 0; ) {
      const key = keys[i];
      if (key === "_owner" && a.$$typeof) {
        continue;
      }
      if (!deepEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  return a !== a && b !== b;
}
function getDPR(element) {
  if (typeof window === "undefined") {
    return 1;
  }
  const win = element.ownerDocument.defaultView || window;
  return win.devicePixelRatio || 1;
}
function roundByDPR(element, value) {
  const dpr = getDPR(element);
  return Math.round(value * dpr) / dpr;
}
function useLatestRef(value) {
  const ref = React32.useRef(value);
  index(() => {
    ref.current = value;
  });
  return ref;
}
function useFloating(options) {
  if (options === void 0) {
    options = {};
  }
  const {
    placement = "bottom",
    strategy = "absolute",
    middleware = [],
    platform: platform2,
    elements: {
      reference: externalReference,
      floating: externalFloating
    } = {},
    transform = true,
    whileElementsMounted,
    open
  } = options;
  const [data, setData] = React32.useState({
    x: 0,
    y: 0,
    strategy,
    placement,
    middlewareData: {},
    isPositioned: false
  });
  const [latestMiddleware, setLatestMiddleware] = React32.useState(middleware);
  if (!deepEqual(latestMiddleware, middleware)) {
    setLatestMiddleware(middleware);
  }
  const [_reference, _setReference] = React32.useState(null);
  const [_floating, _setFloating] = React32.useState(null);
  const setReference = React32.useCallback((node) => {
    if (node !== referenceRef.current) {
      referenceRef.current = node;
      _setReference(node);
    }
  }, []);
  const setFloating = React32.useCallback((node) => {
    if (node !== floatingRef.current) {
      floatingRef.current = node;
      _setFloating(node);
    }
  }, []);
  const referenceEl = externalReference || _reference;
  const floatingEl = externalFloating || _floating;
  const referenceRef = React32.useRef(null);
  const floatingRef = React32.useRef(null);
  const dataRef = React32.useRef(data);
  const hasWhileElementsMounted = whileElementsMounted != null;
  const whileElementsMountedRef = useLatestRef(whileElementsMounted);
  const platformRef = useLatestRef(platform2);
  const openRef = useLatestRef(open);
  const update = React32.useCallback(() => {
    if (!referenceRef.current || !floatingRef.current) {
      return;
    }
    const config = {
      placement,
      strategy,
      middleware: latestMiddleware
    };
    if (platformRef.current) {
      config.platform = platformRef.current;
    }
    computePosition2(referenceRef.current, floatingRef.current, config).then((data2) => {
      const fullData = {
        ...data2,
        // The floating element's position may be recomputed while it's closed
        // but still mounted (such as when transitioning out). To ensure
        // `isPositioned` will be `false` initially on the next open, avoid
        // setting it to `true` when `open === false` (must be specified).
        isPositioned: openRef.current !== false
      };
      if (isMountedRef.current && !deepEqual(dataRef.current, fullData)) {
        dataRef.current = fullData;
        ReactDOM3.flushSync(() => {
          setData(fullData);
        });
      }
    });
  }, [latestMiddleware, placement, strategy, platformRef, openRef]);
  index(() => {
    if (open === false && dataRef.current.isPositioned) {
      dataRef.current.isPositioned = false;
      setData((data2) => ({
        ...data2,
        isPositioned: false
      }));
    }
  }, [open]);
  const isMountedRef = React32.useRef(false);
  index(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  index(() => {
    if (referenceEl) referenceRef.current = referenceEl;
    if (floatingEl) floatingRef.current = floatingEl;
    if (referenceEl && floatingEl) {
      if (whileElementsMountedRef.current) {
        return whileElementsMountedRef.current(referenceEl, floatingEl, update);
      }
      update();
    }
  }, [referenceEl, floatingEl, update, whileElementsMountedRef, hasWhileElementsMounted]);
  const refs = React32.useMemo(() => ({
    reference: referenceRef,
    floating: floatingRef,
    setReference,
    setFloating
  }), [setReference, setFloating]);
  const elements = React32.useMemo(() => ({
    reference: referenceEl,
    floating: floatingEl
  }), [referenceEl, floatingEl]);
  const floatingStyles = React32.useMemo(() => {
    const initialStyles = {
      position: strategy,
      left: 0,
      top: 0
    };
    if (!elements.floating) {
      return initialStyles;
    }
    const x = roundByDPR(elements.floating, data.x);
    const y = roundByDPR(elements.floating, data.y);
    if (transform) {
      return {
        ...initialStyles,
        transform: "translate(" + x + "px, " + y + "px)",
        ...getDPR(elements.floating) >= 1.5 && {
          willChange: "transform"
        }
      };
    }
    return {
      position: strategy,
      left: x,
      top: y
    };
  }, [strategy, transform, elements.floating, data.x, data.y]);
  return React32.useMemo(() => ({
    ...data,
    update,
    refs,
    elements,
    floatingStyles
  }), [data, update, refs, elements, floatingStyles]);
}
var arrow$1 = (options) => {
  function isRef(value) {
    return {}.hasOwnProperty.call(value, "current");
  }
  return {
    name: "arrow",
    options,
    fn(state) {
      const {
        element,
        padding
      } = typeof options === "function" ? options(state) : options;
      if (element && isRef(element)) {
        if (element.current != null) {
          return arrow2({
            element: element.current,
            padding
          }).fn(state);
        }
        return {};
      }
      if (element) {
        return arrow2({
          element,
          padding
        }).fn(state);
      }
      return {};
    }
  };
};
var offset3 = (options, deps) => ({
  ...offset2(options),
  options: [options, deps]
});
var shift3 = (options, deps) => ({
  ...shift2(options),
  options: [options, deps]
});
var limitShift3 = (options, deps) => ({
  ...limitShift2(options),
  options: [options, deps]
});
var flip3 = (options, deps) => ({
  ...flip2(options),
  options: [options, deps]
});
var size3 = (options, deps) => ({
  ...size2(options),
  options: [options, deps]
});
var hide3 = (options, deps) => ({
  ...hide2(options),
  options: [options, deps]
});
var arrow3 = (options, deps) => ({
  ...arrow$1(options),
  options: [options, deps]
});

// ../../node_modules/@radix-ui/react-arrow/dist/index.mjs
import * as React36 from "react";

// ../../node_modules/@radix-ui/react-arrow/node_modules/@radix-ui/react-primitive/dist/index.mjs
import * as React35 from "react";
import * as ReactDOM4 from "react-dom";

// ../../node_modules/@radix-ui/react-arrow/node_modules/@radix-ui/react-slot/dist/index.mjs
import * as React34 from "react";

// ../../node_modules/@radix-ui/react-arrow/node_modules/@radix-ui/react-compose-refs/dist/index.mjs
import * as React33 from "react";
function setRef3(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref !== null && ref !== void 0) {
    ref.current = value;
  }
}
function composeRefs3(...refs) {
  return (node) => refs.forEach((ref) => setRef3(ref, node));
}

// ../../node_modules/@radix-ui/react-arrow/node_modules/@radix-ui/react-slot/dist/index.mjs
import { Fragment as Fragment4, jsx as jsx40 } from "react/jsx-runtime";
var Slot3 = React34.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  const childrenArray = React34.Children.toArray(children);
  const slottable = childrenArray.find(isSlottable3);
  if (slottable) {
    const newElement = slottable.props.children;
    const newChildren = childrenArray.map((child) => {
      if (child === slottable) {
        if (React34.Children.count(newElement) > 1) return React34.Children.only(null);
        return React34.isValidElement(newElement) ? newElement.props.children : null;
      } else {
        return child;
      }
    });
    return /* @__PURE__ */ jsx40(SlotClone3, { ...slotProps, ref: forwardedRef, children: React34.isValidElement(newElement) ? React34.cloneElement(newElement, void 0, newChildren) : null });
  }
  return /* @__PURE__ */ jsx40(SlotClone3, { ...slotProps, ref: forwardedRef, children });
});
Slot3.displayName = "Slot";
var SlotClone3 = React34.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  if (React34.isValidElement(children)) {
    const childrenRef = getElementRef3(children);
    return React34.cloneElement(children, {
      ...mergeProps3(slotProps, children.props),
      // @ts-ignore
      ref: forwardedRef ? composeRefs3(forwardedRef, childrenRef) : childrenRef
    });
  }
  return React34.Children.count(children) > 1 ? React34.Children.only(null) : null;
});
SlotClone3.displayName = "SlotClone";
var Slottable3 = ({ children }) => {
  return /* @__PURE__ */ jsx40(Fragment4, { children });
};
function isSlottable3(child) {
  return React34.isValidElement(child) && child.type === Slottable3;
}
function mergeProps3(slotProps, childProps) {
  const overrideProps = { ...childProps };
  for (const propName in childProps) {
    const slotPropValue = slotProps[propName];
    const childPropValue = childProps[propName];
    const isHandler = /^on[A-Z]/.test(propName);
    if (isHandler) {
      if (slotPropValue && childPropValue) {
        overrideProps[propName] = (...args) => {
          childPropValue(...args);
          slotPropValue(...args);
        };
      } else if (slotPropValue) {
        overrideProps[propName] = slotPropValue;
      }
    } else if (propName === "style") {
      overrideProps[propName] = { ...slotPropValue, ...childPropValue };
    } else if (propName === "className") {
      overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(" ");
    }
  }
  return { ...slotProps, ...overrideProps };
}
function getElementRef3(element) {
  let getter = Object.getOwnPropertyDescriptor(element.props, "ref")?.get;
  let mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.ref;
  }
  getter = Object.getOwnPropertyDescriptor(element, "ref")?.get;
  mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }
  return element.props.ref || element.ref;
}

// ../../node_modules/@radix-ui/react-arrow/node_modules/@radix-ui/react-primitive/dist/index.mjs
import { jsx as jsx41 } from "react/jsx-runtime";
var NODES3 = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
];
var Primitive3 = NODES3.reduce((primitive, node) => {
  const Node2 = React35.forwardRef((props, forwardedRef) => {
    const { asChild, ...primitiveProps } = props;
    const Comp = asChild ? Slot3 : node;
    if (typeof window !== "undefined") {
      window[Symbol.for("radix-ui")] = true;
    }
    return /* @__PURE__ */ jsx41(Comp, { ...primitiveProps, ref: forwardedRef });
  });
  Node2.displayName = `Primitive.${node}`;
  return { ...primitive, [node]: Node2 };
}, {});

// ../../node_modules/@radix-ui/react-arrow/dist/index.mjs
import { jsx as jsx42 } from "react/jsx-runtime";
var NAME2 = "Arrow";
var Arrow2 = React36.forwardRef((props, forwardedRef) => {
  const { children, width = 10, height = 5, ...arrowProps } = props;
  return /* @__PURE__ */ jsx42(
    Primitive3.svg,
    {
      ...arrowProps,
      ref: forwardedRef,
      width,
      height,
      viewBox: "0 0 30 10",
      preserveAspectRatio: "none",
      children: props.asChild ? children : /* @__PURE__ */ jsx42("polygon", { points: "0,0 30,0 15,10" })
    }
  );
});
Arrow2.displayName = NAME2;
var Root11 = Arrow2;

// ../../node_modules/@radix-ui/react-use-size/dist/index.mjs
import * as React37 from "react";
function useSize(element) {
  const [size4, setSize] = React37.useState(void 0);
  useLayoutEffect2(() => {
    if (element) {
      setSize({ width: element.offsetWidth, height: element.offsetHeight });
      const resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) {
          return;
        }
        if (!entries.length) {
          return;
        }
        const entry = entries[0];
        let width;
        let height;
        if ("borderBoxSize" in entry) {
          const borderSizeEntry = entry["borderBoxSize"];
          const borderSize = Array.isArray(borderSizeEntry) ? borderSizeEntry[0] : borderSizeEntry;
          width = borderSize["inlineSize"];
          height = borderSize["blockSize"];
        } else {
          width = element.offsetWidth;
          height = element.offsetHeight;
        }
        setSize({ width, height });
      });
      resizeObserver.observe(element, { box: "border-box" });
      return () => resizeObserver.unobserve(element);
    } else {
      setSize(void 0);
    }
  }, [element]);
  return size4;
}

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-popper/dist/index.mjs
import { jsx as jsx43 } from "react/jsx-runtime";
var POPPER_NAME = "Popper";
var [createPopperContext, createPopperScope] = createContextScope(POPPER_NAME);
var [PopperProvider, usePopperContext] = createPopperContext(POPPER_NAME);
var Popper = (props) => {
  const { __scopePopper, children } = props;
  const [anchor, setAnchor] = React38.useState(null);
  return /* @__PURE__ */ jsx43(PopperProvider, { scope: __scopePopper, anchor, onAnchorChange: setAnchor, children });
};
Popper.displayName = POPPER_NAME;
var ANCHOR_NAME = "PopperAnchor";
var PopperAnchor = React38.forwardRef(
  (props, forwardedRef) => {
    const { __scopePopper, virtualRef, ...anchorProps } = props;
    const context = usePopperContext(ANCHOR_NAME, __scopePopper);
    const ref = React38.useRef(null);
    const composedRefs = useComposedRefs2(forwardedRef, ref);
    React38.useEffect(() => {
      context.onAnchorChange(virtualRef?.current || ref.current);
    });
    return virtualRef ? null : /* @__PURE__ */ jsx43(Primitive2.div, { ...anchorProps, ref: composedRefs });
  }
);
PopperAnchor.displayName = ANCHOR_NAME;
var CONTENT_NAME = "PopperContent";
var [PopperContentProvider, useContentContext] = createPopperContext(CONTENT_NAME);
var PopperContent = React38.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopePopper,
      side = "bottom",
      sideOffset = 0,
      align = "center",
      alignOffset = 0,
      arrowPadding = 0,
      avoidCollisions = true,
      collisionBoundary = [],
      collisionPadding: collisionPaddingProp = 0,
      sticky = "partial",
      hideWhenDetached = false,
      updatePositionStrategy = "optimized",
      onPlaced,
      ...contentProps
    } = props;
    const context = usePopperContext(CONTENT_NAME, __scopePopper);
    const [content, setContent] = React38.useState(null);
    const composedRefs = useComposedRefs2(forwardedRef, (node) => setContent(node));
    const [arrow4, setArrow] = React38.useState(null);
    const arrowSize = useSize(arrow4);
    const arrowWidth = arrowSize?.width ?? 0;
    const arrowHeight = arrowSize?.height ?? 0;
    const desiredPlacement = side + (align !== "center" ? "-" + align : "");
    const collisionPadding = typeof collisionPaddingProp === "number" ? collisionPaddingProp : { top: 0, right: 0, bottom: 0, left: 0, ...collisionPaddingProp };
    const boundary = Array.isArray(collisionBoundary) ? collisionBoundary : [collisionBoundary];
    const hasExplicitBoundaries = boundary.length > 0;
    const detectOverflowOptions = {
      padding: collisionPadding,
      boundary: boundary.filter(isNotNull),
      // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
      altBoundary: hasExplicitBoundaries
    };
    const { refs, floatingStyles, placement, isPositioned, middlewareData } = useFloating({
      // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
      strategy: "fixed",
      placement: desiredPlacement,
      whileElementsMounted: (...args) => {
        const cleanup = autoUpdate(...args, {
          animationFrame: updatePositionStrategy === "always"
        });
        return cleanup;
      },
      elements: {
        reference: context.anchor
      },
      middleware: [
        offset3({ mainAxis: sideOffset + arrowHeight, alignmentAxis: alignOffset }),
        avoidCollisions && shift3({
          mainAxis: true,
          crossAxis: false,
          limiter: sticky === "partial" ? limitShift3() : void 0,
          ...detectOverflowOptions
        }),
        avoidCollisions && flip3({ ...detectOverflowOptions }),
        size3({
          ...detectOverflowOptions,
          apply: ({ elements, rects, availableWidth, availableHeight }) => {
            const { width: anchorWidth, height: anchorHeight } = rects.reference;
            const contentStyle = elements.floating.style;
            contentStyle.setProperty("--radix-popper-available-width", `${availableWidth}px`);
            contentStyle.setProperty("--radix-popper-available-height", `${availableHeight}px`);
            contentStyle.setProperty("--radix-popper-anchor-width", `${anchorWidth}px`);
            contentStyle.setProperty("--radix-popper-anchor-height", `${anchorHeight}px`);
          }
        }),
        arrow4 && arrow3({ element: arrow4, padding: arrowPadding }),
        transformOrigin({ arrowWidth, arrowHeight }),
        hideWhenDetached && hide3({ strategy: "referenceHidden", ...detectOverflowOptions })
      ]
    });
    const [placedSide, placedAlign] = getSideAndAlignFromPlacement(placement);
    const handlePlaced = useCallbackRef(onPlaced);
    useLayoutEffect2(() => {
      if (isPositioned) {
        handlePlaced?.();
      }
    }, [isPositioned, handlePlaced]);
    const arrowX = middlewareData.arrow?.x;
    const arrowY = middlewareData.arrow?.y;
    const cannotCenterArrow = middlewareData.arrow?.centerOffset !== 0;
    const [contentZIndex, setContentZIndex] = React38.useState();
    useLayoutEffect2(() => {
      if (content) setContentZIndex(window.getComputedStyle(content).zIndex);
    }, [content]);
    return /* @__PURE__ */ jsx43(
      "div",
      {
        ref: refs.setFloating,
        "data-radix-popper-content-wrapper": "",
        style: {
          ...floatingStyles,
          transform: isPositioned ? floatingStyles.transform : "translate(0, -200%)",
          // keep off the page when measuring
          minWidth: "max-content",
          zIndex: contentZIndex,
          ["--radix-popper-transform-origin"]: [
            middlewareData.transformOrigin?.x,
            middlewareData.transformOrigin?.y
          ].join(" "),
          // hide the content if using the hide middleware and should be hidden
          // set visibility to hidden and disable pointer events so the UI behaves
          // as if the PopperContent isn't there at all
          ...middlewareData.hide?.referenceHidden && {
            visibility: "hidden",
            pointerEvents: "none"
          }
        },
        dir: props.dir,
        children: /* @__PURE__ */ jsx43(
          PopperContentProvider,
          {
            scope: __scopePopper,
            placedSide,
            onArrowChange: setArrow,
            arrowX,
            arrowY,
            shouldHideArrow: cannotCenterArrow,
            children: /* @__PURE__ */ jsx43(
              Primitive2.div,
              {
                "data-side": placedSide,
                "data-align": placedAlign,
                ...contentProps,
                ref: composedRefs,
                style: {
                  ...contentProps.style,
                  // if the PopperContent hasn't been placed yet (not all measurements done)
                  // we prevent animations so that users's animation don't kick in too early referring wrong sides
                  animation: !isPositioned ? "none" : void 0
                }
              }
            )
          }
        )
      }
    );
  }
);
PopperContent.displayName = CONTENT_NAME;
var ARROW_NAME = "PopperArrow";
var OPPOSITE_SIDE = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
};
var PopperArrow = React38.forwardRef(function PopperArrow2(props, forwardedRef) {
  const { __scopePopper, ...arrowProps } = props;
  const contentContext = useContentContext(ARROW_NAME, __scopePopper);
  const baseSide = OPPOSITE_SIDE[contentContext.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ jsx43(
      "span",
      {
        ref: contentContext.onArrowChange,
        style: {
          position: "absolute",
          left: contentContext.arrowX,
          top: contentContext.arrowY,
          [baseSide]: 0,
          transformOrigin: {
            top: "",
            right: "0 0",
            bottom: "center 0",
            left: "100% 0"
          }[contentContext.placedSide],
          transform: {
            top: "translateY(100%)",
            right: "translateY(50%) rotate(90deg) translateX(-50%)",
            bottom: `rotate(180deg)`,
            left: "translateY(50%) rotate(-90deg) translateX(50%)"
          }[contentContext.placedSide],
          visibility: contentContext.shouldHideArrow ? "hidden" : void 0
        },
        children: /* @__PURE__ */ jsx43(
          Root11,
          {
            ...arrowProps,
            ref: forwardedRef,
            style: {
              ...arrowProps.style,
              // ensures the element can be measured correctly (mostly for if SVG)
              display: "block"
            }
          }
        )
      }
    )
  );
});
PopperArrow.displayName = ARROW_NAME;
function isNotNull(value) {
  return value !== null;
}
var transformOrigin = (options) => ({
  name: "transformOrigin",
  options,
  fn(data) {
    const { placement, rects, middlewareData } = data;
    const cannotCenterArrow = middlewareData.arrow?.centerOffset !== 0;
    const isArrowHidden = cannotCenterArrow;
    const arrowWidth = isArrowHidden ? 0 : options.arrowWidth;
    const arrowHeight = isArrowHidden ? 0 : options.arrowHeight;
    const [placedSide, placedAlign] = getSideAndAlignFromPlacement(placement);
    const noArrowAlign = { start: "0%", center: "50%", end: "100%" }[placedAlign];
    const arrowXCenter = (middlewareData.arrow?.x ?? 0) + arrowWidth / 2;
    const arrowYCenter = (middlewareData.arrow?.y ?? 0) + arrowHeight / 2;
    let x = "";
    let y = "";
    if (placedSide === "bottom") {
      x = isArrowHidden ? noArrowAlign : `${arrowXCenter}px`;
      y = `${-arrowHeight}px`;
    } else if (placedSide === "top") {
      x = isArrowHidden ? noArrowAlign : `${arrowXCenter}px`;
      y = `${rects.floating.height + arrowHeight}px`;
    } else if (placedSide === "right") {
      x = `${-arrowHeight}px`;
      y = isArrowHidden ? noArrowAlign : `${arrowYCenter}px`;
    } else if (placedSide === "left") {
      x = `${rects.floating.width + arrowHeight}px`;
      y = isArrowHidden ? noArrowAlign : `${arrowYCenter}px`;
    }
    return { data: { x, y } };
  }
});
function getSideAndAlignFromPlacement(placement) {
  const [side, align = "center"] = placement.split("-");
  return [side, align];
}
var Root22 = Popper;
var Anchor = PopperAnchor;
var Content6 = PopperContent;
var Arrow3 = PopperArrow;

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-portal/dist/index.mjs
import * as React39 from "react";
import ReactDOM5 from "react-dom";
import { jsx as jsx44 } from "react/jsx-runtime";
var PORTAL_NAME = "Portal";
var Portal4 = React39.forwardRef((props, forwardedRef) => {
  const { container: containerProp, ...portalProps } = props;
  const [mounted, setMounted] = React39.useState(false);
  useLayoutEffect2(() => setMounted(true), []);
  const container = containerProp || mounted && globalThis?.document?.body;
  return container ? ReactDOM5.createPortal(/* @__PURE__ */ jsx44(Primitive2.div, { ...portalProps, ref: forwardedRef }), container) : null;
});
Portal4.displayName = PORTAL_NAME;

// ../../node_modules/@radix-ui/react-popover/node_modules/@radix-ui/react-presence/dist/index.mjs
import * as React210 from "react";
import * as ReactDOM6 from "react-dom";
import * as React40 from "react";
function useStateMachine(initialState, machine) {
  return React40.useReducer((state, event) => {
    const nextState = machine[state][event];
    return nextState ?? state;
  }, initialState);
}
var Presence = (props) => {
  const { present, children } = props;
  const presence = usePresence(present);
  const child = typeof children === "function" ? children({ present: presence.isPresent }) : React210.Children.only(children);
  const ref = useComposedRefs2(presence.ref, getElementRef4(child));
  const forceMount = typeof children === "function";
  return forceMount || presence.isPresent ? React210.cloneElement(child, { ref }) : null;
};
Presence.displayName = "Presence";
function usePresence(present) {
  const [node, setNode] = React210.useState();
  const stylesRef = React210.useRef({});
  const prevPresentRef = React210.useRef(present);
  const prevAnimationNameRef = React210.useRef("none");
  const initialState = present ? "mounted" : "unmounted";
  const [state, send] = useStateMachine(initialState, {
    mounted: {
      UNMOUNT: "unmounted",
      ANIMATION_OUT: "unmountSuspended"
    },
    unmountSuspended: {
      MOUNT: "mounted",
      ANIMATION_END: "unmounted"
    },
    unmounted: {
      MOUNT: "mounted"
    }
  });
  React210.useEffect(() => {
    const currentAnimationName = getAnimationName(stylesRef.current);
    prevAnimationNameRef.current = state === "mounted" ? currentAnimationName : "none";
  }, [state]);
  useLayoutEffect2(() => {
    const styles = stylesRef.current;
    const wasPresent = prevPresentRef.current;
    const hasPresentChanged = wasPresent !== present;
    if (hasPresentChanged) {
      const prevAnimationName = prevAnimationNameRef.current;
      const currentAnimationName = getAnimationName(styles);
      if (present) {
        send("MOUNT");
      } else if (currentAnimationName === "none" || styles?.display === "none") {
        send("UNMOUNT");
      } else {
        const isAnimating = prevAnimationName !== currentAnimationName;
        if (wasPresent && isAnimating) {
          send("ANIMATION_OUT");
        } else {
          send("UNMOUNT");
        }
      }
      prevPresentRef.current = present;
    }
  }, [present, send]);
  useLayoutEffect2(() => {
    if (node) {
      const handleAnimationEnd = (event) => {
        const currentAnimationName = getAnimationName(stylesRef.current);
        const isCurrentAnimation = currentAnimationName.includes(event.animationName);
        if (event.target === node && isCurrentAnimation) {
          ReactDOM6.flushSync(() => send("ANIMATION_END"));
        }
      };
      const handleAnimationStart = (event) => {
        if (event.target === node) {
          prevAnimationNameRef.current = getAnimationName(stylesRef.current);
        }
      };
      node.addEventListener("animationstart", handleAnimationStart);
      node.addEventListener("animationcancel", handleAnimationEnd);
      node.addEventListener("animationend", handleAnimationEnd);
      return () => {
        node.removeEventListener("animationstart", handleAnimationStart);
        node.removeEventListener("animationcancel", handleAnimationEnd);
        node.removeEventListener("animationend", handleAnimationEnd);
      };
    } else {
      send("ANIMATION_END");
    }
  }, [node, send]);
  return {
    isPresent: ["mounted", "unmountSuspended"].includes(state),
    ref: React210.useCallback((node2) => {
      if (node2) stylesRef.current = getComputedStyle(node2);
      setNode(node2);
    }, [])
  };
}
function getAnimationName(styles) {
  return styles?.animationName || "none";
}
function getElementRef4(element) {
  let getter = Object.getOwnPropertyDescriptor(element.props, "ref")?.get;
  let mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.ref;
  }
  getter = Object.getOwnPropertyDescriptor(element, "ref")?.get;
  mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }
  return element.props.ref || element.ref;
}

// ../../node_modules/@radix-ui/react-use-controllable-state/dist/index.mjs
import * as React41 from "react";
function useControllableState({
  prop,
  defaultProp,
  onChange = () => {
  }
}) {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({ defaultProp, onChange });
  const isControlled = prop !== void 0;
  const value = isControlled ? prop : uncontrolledProp;
  const handleChange = useCallbackRef(onChange);
  const setValue = React41.useCallback(
    (nextValue) => {
      if (isControlled) {
        const setter = nextValue;
        const value2 = typeof nextValue === "function" ? setter(prop) : nextValue;
        if (value2 !== prop) handleChange(value2);
      } else {
        setUncontrolledProp(nextValue);
      }
    },
    [isControlled, prop, setUncontrolledProp, handleChange]
  );
  return [value, setValue];
}
function useUncontrolledState({
  defaultProp,
  onChange
}) {
  const uncontrolledState = React41.useState(defaultProp);
  const [value] = uncontrolledState;
  const prevValueRef = React41.useRef(value);
  const handleChange = useCallbackRef(onChange);
  React41.useEffect(() => {
    if (prevValueRef.current !== value) {
      handleChange(value);
      prevValueRef.current = value;
    }
  }, [value, prevValueRef, handleChange]);
  return uncontrolledState;
}

// ../../node_modules/aria-hidden/dist/es2015/index.js
var getDefaultParent = function(originalTarget) {
  if (typeof document === "undefined") {
    return null;
  }
  var sampleTarget = Array.isArray(originalTarget) ? originalTarget[0] : originalTarget;
  return sampleTarget.ownerDocument.body;
};
var counterMap = /* @__PURE__ */ new WeakMap();
var uncontrolledNodes = /* @__PURE__ */ new WeakMap();
var markerMap = {};
var lockCount = 0;
var unwrapHost = function(node) {
  return node && (node.host || unwrapHost(node.parentNode));
};
var correctTargets = function(parent, targets) {
  return targets.map(function(target) {
    if (parent.contains(target)) {
      return target;
    }
    var correctedTarget = unwrapHost(target);
    if (correctedTarget && parent.contains(correctedTarget)) {
      return correctedTarget;
    }
    console.error("aria-hidden", target, "in not contained inside", parent, ". Doing nothing");
    return null;
  }).filter(function(x) {
    return Boolean(x);
  });
};
var applyAttributeToOthers = function(originalTarget, parentNode, markerName, controlAttribute) {
  var targets = correctTargets(parentNode, Array.isArray(originalTarget) ? originalTarget : [originalTarget]);
  if (!markerMap[markerName]) {
    markerMap[markerName] = /* @__PURE__ */ new WeakMap();
  }
  var markerCounter = markerMap[markerName];
  var hiddenNodes = [];
  var elementsToKeep = /* @__PURE__ */ new Set();
  var elementsToStop = new Set(targets);
  var keep = function(el) {
    if (!el || elementsToKeep.has(el)) {
      return;
    }
    elementsToKeep.add(el);
    keep(el.parentNode);
  };
  targets.forEach(keep);
  var deep = function(parent) {
    if (!parent || elementsToStop.has(parent)) {
      return;
    }
    Array.prototype.forEach.call(parent.children, function(node) {
      if (elementsToKeep.has(node)) {
        deep(node);
      } else {
        try {
          var attr = node.getAttribute(controlAttribute);
          var alreadyHidden = attr !== null && attr !== "false";
          var counterValue = (counterMap.get(node) || 0) + 1;
          var markerValue = (markerCounter.get(node) || 0) + 1;
          counterMap.set(node, counterValue);
          markerCounter.set(node, markerValue);
          hiddenNodes.push(node);
          if (counterValue === 1 && alreadyHidden) {
            uncontrolledNodes.set(node, true);
          }
          if (markerValue === 1) {
            node.setAttribute(markerName, "true");
          }
          if (!alreadyHidden) {
            node.setAttribute(controlAttribute, "true");
          }
        } catch (e) {
          console.error("aria-hidden: cannot operate on ", node, e);
        }
      }
    });
  };
  deep(parentNode);
  elementsToKeep.clear();
  lockCount++;
  return function() {
    hiddenNodes.forEach(function(node) {
      var counterValue = counterMap.get(node) - 1;
      var markerValue = markerCounter.get(node) - 1;
      counterMap.set(node, counterValue);
      markerCounter.set(node, markerValue);
      if (!counterValue) {
        if (!uncontrolledNodes.has(node)) {
          node.removeAttribute(controlAttribute);
        }
        uncontrolledNodes.delete(node);
      }
      if (!markerValue) {
        node.removeAttribute(markerName);
      }
    });
    lockCount--;
    if (!lockCount) {
      counterMap = /* @__PURE__ */ new WeakMap();
      counterMap = /* @__PURE__ */ new WeakMap();
      uncontrolledNodes = /* @__PURE__ */ new WeakMap();
      markerMap = {};
    }
  };
};
var hideOthers = function(originalTarget, parentNode, markerName) {
  if (markerName === void 0) {
    markerName = "data-aria-hidden";
  }
  var targets = Array.from(Array.isArray(originalTarget) ? originalTarget : [originalTarget]);
  var activeParentNode = parentNode || getDefaultParent(originalTarget);
  if (!activeParentNode) {
    return function() {
      return null;
    };
  }
  targets.push.apply(targets, Array.from(activeParentNode.querySelectorAll("[aria-live]")));
  return applyAttributeToOthers(targets, activeParentNode, markerName, "aria-hidden");
};

// ../../node_modules/tslib/tslib.es6.mjs
var __assign = function() {
  __assign = Object.assign || function __assign2(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
function __rest(s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
    t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
        t[p[i]] = s[p[i]];
    }
  return t;
}
function __spreadArray(to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
}

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/Combination.js
import * as React48 from "react";

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/UI.js
import * as React44 from "react";

// ../../node_modules/react-remove-scroll-bar/dist/es2015/constants.js
var zeroRightClassName = "right-scroll-bar-position";
var fullWidthClassName = "width-before-scroll-bar";
var noScrollbarsClassName = "with-scroll-bars-hidden";
var removedBarSizeVariable = "--removed-body-scroll-bar-size";

// ../../node_modules/use-callback-ref/dist/es2015/assignRef.js
function assignRef(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
  return ref;
}

// ../../node_modules/use-callback-ref/dist/es2015/useRef.js
import { useState as useState15 } from "react";
function useCallbackRef2(initialValue, callback) {
  var ref = useState15(function() {
    return {
      // value
      value: initialValue,
      // last callback
      callback,
      // "memoized" public interface
      facade: {
        get current() {
          return ref.value;
        },
        set current(value) {
          var last = ref.value;
          if (last !== value) {
            ref.value = value;
            ref.callback(value, last);
          }
        }
      }
    };
  })[0];
  ref.callback = callback;
  return ref.facade;
}

// ../../node_modules/use-callback-ref/dist/es2015/useMergeRef.js
import * as React42 from "react";
var useIsomorphicLayoutEffect = typeof window !== "undefined" ? React42.useLayoutEffect : React42.useEffect;
var currentValues = /* @__PURE__ */ new WeakMap();
function useMergeRefs(refs, defaultValue) {
  var callbackRef = useCallbackRef2(defaultValue || null, function(newValue) {
    return refs.forEach(function(ref) {
      return assignRef(ref, newValue);
    });
  });
  useIsomorphicLayoutEffect(function() {
    var oldValue = currentValues.get(callbackRef);
    if (oldValue) {
      var prevRefs_1 = new Set(oldValue);
      var nextRefs_1 = new Set(refs);
      var current_1 = callbackRef.current;
      prevRefs_1.forEach(function(ref) {
        if (!nextRefs_1.has(ref)) {
          assignRef(ref, null);
        }
      });
      nextRefs_1.forEach(function(ref) {
        if (!prevRefs_1.has(ref)) {
          assignRef(ref, current_1);
        }
      });
    }
    currentValues.set(callbackRef, refs);
  }, [refs]);
  return callbackRef;
}

// ../../node_modules/use-sidecar/dist/es2015/medium.js
function ItoI(a) {
  return a;
}
function innerCreateMedium(defaults, middleware) {
  if (middleware === void 0) {
    middleware = ItoI;
  }
  var buffer = [];
  var assigned = false;
  var medium = {
    read: function() {
      if (assigned) {
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      }
      if (buffer.length) {
        return buffer[buffer.length - 1];
      }
      return defaults;
    },
    useMedium: function(data) {
      var item = middleware(data, assigned);
      buffer.push(item);
      return function() {
        buffer = buffer.filter(function(x) {
          return x !== item;
        });
      };
    },
    assignSyncMedium: function(cb) {
      assigned = true;
      while (buffer.length) {
        var cbs = buffer;
        buffer = [];
        cbs.forEach(cb);
      }
      buffer = {
        push: function(x) {
          return cb(x);
        },
        filter: function() {
          return buffer;
        }
      };
    },
    assignMedium: function(cb) {
      assigned = true;
      var pendingQueue = [];
      if (buffer.length) {
        var cbs = buffer;
        buffer = [];
        cbs.forEach(cb);
        pendingQueue = buffer;
      }
      var executeQueue = function() {
        var cbs2 = pendingQueue;
        pendingQueue = [];
        cbs2.forEach(cb);
      };
      var cycle = function() {
        return Promise.resolve().then(executeQueue);
      };
      cycle();
      buffer = {
        push: function(x) {
          pendingQueue.push(x);
          cycle();
        },
        filter: function(filter) {
          pendingQueue = pendingQueue.filter(filter);
          return buffer;
        }
      };
    }
  };
  return medium;
}
function createSidecarMedium(options) {
  if (options === void 0) {
    options = {};
  }
  var medium = innerCreateMedium(null);
  medium.options = __assign({ async: true, ssr: false }, options);
  return medium;
}

// ../../node_modules/use-sidecar/dist/es2015/exports.js
import * as React43 from "react";
var SideCar = function(_a) {
  var sideCar = _a.sideCar, rest = __rest(_a, ["sideCar"]);
  if (!sideCar) {
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  }
  var Target = sideCar.read();
  if (!Target) {
    throw new Error("Sidecar medium not found");
  }
  return React43.createElement(Target, __assign({}, rest));
};
SideCar.isSideCarExport = true;
function exportSidecar(medium, exported) {
  medium.useMedium(exported);
  return SideCar;
}

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/medium.js
var effectCar = createSidecarMedium();

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/UI.js
var nothing = function() {
  return;
};
var RemoveScroll = React44.forwardRef(function(props, parentRef) {
  var ref = React44.useRef(null);
  var _a = React44.useState({
    onScrollCapture: nothing,
    onWheelCapture: nothing,
    onTouchMoveCapture: nothing
  }), callbacks = _a[0], setCallbacks = _a[1];
  var forwardProps = props.forwardProps, children = props.children, className = props.className, removeScrollBar = props.removeScrollBar, enabled = props.enabled, shards = props.shards, sideCar = props.sideCar, noIsolation = props.noIsolation, inert = props.inert, allowPinchZoom = props.allowPinchZoom, _b = props.as, Container = _b === void 0 ? "div" : _b, gapMode = props.gapMode, rest = __rest(props, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as", "gapMode"]);
  var SideCar2 = sideCar;
  var containerRef = useMergeRefs([ref, parentRef]);
  var containerProps = __assign(__assign({}, rest), callbacks);
  return React44.createElement(
    React44.Fragment,
    null,
    enabled && React44.createElement(SideCar2, { sideCar: effectCar, removeScrollBar, shards, noIsolation, inert, setCallbacks, allowPinchZoom: !!allowPinchZoom, lockRef: ref, gapMode }),
    forwardProps ? React44.cloneElement(React44.Children.only(children), __assign(__assign({}, containerProps), { ref: containerRef })) : React44.createElement(Container, __assign({}, containerProps, { className, ref: containerRef }), children)
  );
});
RemoveScroll.defaultProps = {
  enabled: true,
  removeScrollBar: true,
  inert: false
};
RemoveScroll.classNames = {
  fullWidth: fullWidthClassName,
  zeroRight: zeroRightClassName
};

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/SideEffect.js
import * as React47 from "react";

// ../../node_modules/react-remove-scroll-bar/dist/es2015/component.js
import * as React46 from "react";

// ../../node_modules/react-style-singleton/dist/es2015/hook.js
import * as React45 from "react";

// ../../node_modules/get-nonce/dist/es2015/index.js
var currentNonce;
var getNonce = function() {
  if (currentNonce) {
    return currentNonce;
  }
  if (typeof __webpack_nonce__ !== "undefined") {
    return __webpack_nonce__;
  }
  return void 0;
};

// ../../node_modules/react-style-singleton/dist/es2015/singleton.js
function makeStyleTag() {
  if (!document)
    return null;
  var tag = document.createElement("style");
  tag.type = "text/css";
  var nonce = getNonce();
  if (nonce) {
    tag.setAttribute("nonce", nonce);
  }
  return tag;
}
function injectStyles(tag, css42) {
  if (tag.styleSheet) {
    tag.styleSheet.cssText = css42;
  } else {
    tag.appendChild(document.createTextNode(css42));
  }
}
function insertStyleTag(tag) {
  var head = document.head || document.getElementsByTagName("head")[0];
  head.appendChild(tag);
}
var stylesheetSingleton = function() {
  var counter = 0;
  var stylesheet = null;
  return {
    add: function(style) {
      if (counter == 0) {
        if (stylesheet = makeStyleTag()) {
          injectStyles(stylesheet, style);
          insertStyleTag(stylesheet);
        }
      }
      counter++;
    },
    remove: function() {
      counter--;
      if (!counter && stylesheet) {
        stylesheet.parentNode && stylesheet.parentNode.removeChild(stylesheet);
        stylesheet = null;
      }
    }
  };
};

// ../../node_modules/react-style-singleton/dist/es2015/hook.js
var styleHookSingleton = function() {
  var sheet = stylesheetSingleton();
  return function(styles, isDynamic) {
    React45.useEffect(function() {
      sheet.add(styles);
      return function() {
        sheet.remove();
      };
    }, [styles && isDynamic]);
  };
};

// ../../node_modules/react-style-singleton/dist/es2015/component.js
var styleSingleton = function() {
  var useStyle = styleHookSingleton();
  var Sheet = function(_a) {
    var styles = _a.styles, dynamic = _a.dynamic;
    useStyle(styles, dynamic);
    return null;
  };
  return Sheet;
};

// ../../node_modules/react-remove-scroll-bar/dist/es2015/utils.js
var zeroGap = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
};
var parse = function(x) {
  return parseInt(x || "", 10) || 0;
};
var getOffset = function(gapMode) {
  var cs = window.getComputedStyle(document.body);
  var left = cs[gapMode === "padding" ? "paddingLeft" : "marginLeft"];
  var top = cs[gapMode === "padding" ? "paddingTop" : "marginTop"];
  var right = cs[gapMode === "padding" ? "paddingRight" : "marginRight"];
  return [parse(left), parse(top), parse(right)];
};
var getGapWidth = function(gapMode) {
  if (gapMode === void 0) {
    gapMode = "margin";
  }
  if (typeof window === "undefined") {
    return zeroGap;
  }
  var offsets = getOffset(gapMode);
  var documentWidth = document.documentElement.clientWidth;
  var windowWidth = window.innerWidth;
  return {
    left: offsets[0],
    top: offsets[1],
    right: offsets[2],
    gap: Math.max(0, windowWidth - documentWidth + offsets[2] - offsets[0])
  };
};

// ../../node_modules/react-remove-scroll-bar/dist/es2015/component.js
var Style = styleSingleton();
var lockAttribute = "data-scroll-locked";
var getStyles = function(_a, allowRelative, gapMode, important) {
  var left = _a.left, top = _a.top, right = _a.right, gap = _a.gap;
  if (gapMode === void 0) {
    gapMode = "margin";
  }
  return "\n  .".concat(noScrollbarsClassName, " {\n   overflow: hidden ").concat(important, ";\n   padding-right: ").concat(gap, "px ").concat(important, ";\n  }\n  body[").concat(lockAttribute, "] {\n    overflow: hidden ").concat(important, ";\n    overscroll-behavior: contain;\n    ").concat([
    allowRelative && "position: relative ".concat(important, ";"),
    gapMode === "margin" && "\n    padding-left: ".concat(left, "px;\n    padding-top: ").concat(top, "px;\n    padding-right: ").concat(right, "px;\n    margin-left:0;\n    margin-top:0;\n    margin-right: ").concat(gap, "px ").concat(important, ";\n    "),
    gapMode === "padding" && "padding-right: ".concat(gap, "px ").concat(important, ";")
  ].filter(Boolean).join(""), "\n  }\n  \n  .").concat(zeroRightClassName, " {\n    right: ").concat(gap, "px ").concat(important, ";\n  }\n  \n  .").concat(fullWidthClassName, " {\n    margin-right: ").concat(gap, "px ").concat(important, ";\n  }\n  \n  .").concat(zeroRightClassName, " .").concat(zeroRightClassName, " {\n    right: 0 ").concat(important, ";\n  }\n  \n  .").concat(fullWidthClassName, " .").concat(fullWidthClassName, " {\n    margin-right: 0 ").concat(important, ";\n  }\n  \n  body[").concat(lockAttribute, "] {\n    ").concat(removedBarSizeVariable, ": ").concat(gap, "px;\n  }\n");
};
var getCurrentUseCounter = function() {
  var counter = parseInt(document.body.getAttribute(lockAttribute) || "0", 10);
  return isFinite(counter) ? counter : 0;
};
var useLockAttribute = function() {
  React46.useEffect(function() {
    document.body.setAttribute(lockAttribute, (getCurrentUseCounter() + 1).toString());
    return function() {
      var newCounter = getCurrentUseCounter() - 1;
      if (newCounter <= 0) {
        document.body.removeAttribute(lockAttribute);
      } else {
        document.body.setAttribute(lockAttribute, newCounter.toString());
      }
    };
  }, []);
};
var RemoveScrollBar = function(_a) {
  var noRelative = _a.noRelative, noImportant = _a.noImportant, _b = _a.gapMode, gapMode = _b === void 0 ? "margin" : _b;
  useLockAttribute();
  var gap = React46.useMemo(function() {
    return getGapWidth(gapMode);
  }, [gapMode]);
  return React46.createElement(Style, { styles: getStyles(gap, !noRelative, gapMode, !noImportant ? "!important" : "") });
};

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/aggresiveCapture.js
var passiveSupported = false;
if (typeof window !== "undefined") {
  try {
    options = Object.defineProperty({}, "passive", {
      get: function() {
        passiveSupported = true;
        return true;
      }
    });
    window.addEventListener("test", options, options);
    window.removeEventListener("test", options, options);
  } catch (err) {
    passiveSupported = false;
  }
}
var options;
var nonPassive = passiveSupported ? { passive: false } : false;

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/handleScroll.js
var alwaysContainsScroll = function(node) {
  return node.tagName === "TEXTAREA";
};
var elementCanBeScrolled = function(node, overflow) {
  var styles = window.getComputedStyle(node);
  return (
    // not-not-scrollable
    styles[overflow] !== "hidden" && // contains scroll inside self
    !(styles.overflowY === styles.overflowX && !alwaysContainsScroll(node) && styles[overflow] === "visible")
  );
};
var elementCouldBeVScrolled = function(node) {
  return elementCanBeScrolled(node, "overflowY");
};
var elementCouldBeHScrolled = function(node) {
  return elementCanBeScrolled(node, "overflowX");
};
var locationCouldBeScrolled = function(axis, node) {
  var ownerDocument = node.ownerDocument;
  var current = node;
  do {
    if (typeof ShadowRoot !== "undefined" && current instanceof ShadowRoot) {
      current = current.host;
    }
    var isScrollable = elementCouldBeScrolled(axis, current);
    if (isScrollable) {
      var _a = getScrollVariables(axis, current), s = _a[1], d = _a[2];
      if (s > d) {
        return true;
      }
    }
    current = current.parentNode;
  } while (current && current !== ownerDocument.body);
  return false;
};
var getVScrollVariables = function(_a) {
  var scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
  return [
    scrollTop,
    scrollHeight,
    clientHeight
  ];
};
var getHScrollVariables = function(_a) {
  var scrollLeft = _a.scrollLeft, scrollWidth = _a.scrollWidth, clientWidth = _a.clientWidth;
  return [
    scrollLeft,
    scrollWidth,
    clientWidth
  ];
};
var elementCouldBeScrolled = function(axis, node) {
  return axis === "v" ? elementCouldBeVScrolled(node) : elementCouldBeHScrolled(node);
};
var getScrollVariables = function(axis, node) {
  return axis === "v" ? getVScrollVariables(node) : getHScrollVariables(node);
};
var getDirectionFactor = function(axis, direction) {
  return axis === "h" && direction === "rtl" ? -1 : 1;
};
var handleScroll = function(axis, endTarget, event, sourceDelta, noOverscroll) {
  var directionFactor = getDirectionFactor(axis, window.getComputedStyle(endTarget).direction);
  var delta = directionFactor * sourceDelta;
  var target = event.target;
  var targetInLock = endTarget.contains(target);
  var shouldCancelScroll = false;
  var isDeltaPositive = delta > 0;
  var availableScroll = 0;
  var availableScrollTop = 0;
  do {
    var _a = getScrollVariables(axis, target), position = _a[0], scroll_1 = _a[1], capacity = _a[2];
    var elementScroll = scroll_1 - capacity - directionFactor * position;
    if (position || elementScroll) {
      if (elementCouldBeScrolled(axis, target)) {
        availableScroll += elementScroll;
        availableScrollTop += position;
      }
    }
    if (target instanceof ShadowRoot) {
      target = target.host;
    } else {
      target = target.parentNode;
    }
  } while (
    // portaled content
    !targetInLock && target !== document.body || // self content
    targetInLock && (endTarget.contains(target) || endTarget === target)
  );
  if (isDeltaPositive && (noOverscroll && Math.abs(availableScroll) < 1 || !noOverscroll && delta > availableScroll)) {
    shouldCancelScroll = true;
  } else if (!isDeltaPositive && (noOverscroll && Math.abs(availableScrollTop) < 1 || !noOverscroll && -delta > availableScrollTop)) {
    shouldCancelScroll = true;
  }
  return shouldCancelScroll;
};

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/SideEffect.js
var getTouchXY = function(event) {
  return "changedTouches" in event ? [event.changedTouches[0].clientX, event.changedTouches[0].clientY] : [0, 0];
};
var getDeltaXY = function(event) {
  return [event.deltaX, event.deltaY];
};
var extractRef = function(ref) {
  return ref && "current" in ref ? ref.current : ref;
};
var deltaCompare = function(x, y) {
  return x[0] === y[0] && x[1] === y[1];
};
var generateStyle = function(id) {
  return "\n  .block-interactivity-".concat(id, " {pointer-events: none;}\n  .allow-interactivity-").concat(id, " {pointer-events: all;}\n");
};
var idCounter = 0;
var lockStack = [];
function RemoveScrollSideCar(props) {
  var shouldPreventQueue = React47.useRef([]);
  var touchStartRef = React47.useRef([0, 0]);
  var activeAxis = React47.useRef();
  var id = React47.useState(idCounter++)[0];
  var Style2 = React47.useState(styleSingleton)[0];
  var lastProps = React47.useRef(props);
  React47.useEffect(function() {
    lastProps.current = props;
  }, [props]);
  React47.useEffect(function() {
    if (props.inert) {
      document.body.classList.add("block-interactivity-".concat(id));
      var allow_1 = __spreadArray([props.lockRef.current], (props.shards || []).map(extractRef), true).filter(Boolean);
      allow_1.forEach(function(el) {
        return el.classList.add("allow-interactivity-".concat(id));
      });
      return function() {
        document.body.classList.remove("block-interactivity-".concat(id));
        allow_1.forEach(function(el) {
          return el.classList.remove("allow-interactivity-".concat(id));
        });
      };
    }
    return;
  }, [props.inert, props.lockRef.current, props.shards]);
  var shouldCancelEvent = React47.useCallback(function(event, parent) {
    if ("touches" in event && event.touches.length === 2) {
      return !lastProps.current.allowPinchZoom;
    }
    var touch = getTouchXY(event);
    var touchStart = touchStartRef.current;
    var deltaX = "deltaX" in event ? event.deltaX : touchStart[0] - touch[0];
    var deltaY = "deltaY" in event ? event.deltaY : touchStart[1] - touch[1];
    var currentAxis;
    var target = event.target;
    var moveDirection = Math.abs(deltaX) > Math.abs(deltaY) ? "h" : "v";
    if ("touches" in event && moveDirection === "h" && target.type === "range") {
      return false;
    }
    var canBeScrolledInMainDirection = locationCouldBeScrolled(moveDirection, target);
    if (!canBeScrolledInMainDirection) {
      return true;
    }
    if (canBeScrolledInMainDirection) {
      currentAxis = moveDirection;
    } else {
      currentAxis = moveDirection === "v" ? "h" : "v";
      canBeScrolledInMainDirection = locationCouldBeScrolled(moveDirection, target);
    }
    if (!canBeScrolledInMainDirection) {
      return false;
    }
    if (!activeAxis.current && "changedTouches" in event && (deltaX || deltaY)) {
      activeAxis.current = currentAxis;
    }
    if (!currentAxis) {
      return true;
    }
    var cancelingAxis = activeAxis.current || currentAxis;
    return handleScroll(cancelingAxis, parent, event, cancelingAxis === "h" ? deltaX : deltaY, true);
  }, []);
  var shouldPrevent = React47.useCallback(function(_event) {
    var event = _event;
    if (!lockStack.length || lockStack[lockStack.length - 1] !== Style2) {
      return;
    }
    var delta = "deltaY" in event ? getDeltaXY(event) : getTouchXY(event);
    var sourceEvent = shouldPreventQueue.current.filter(function(e) {
      return e.name === event.type && (e.target === event.target || event.target === e.shadowParent) && deltaCompare(e.delta, delta);
    })[0];
    if (sourceEvent && sourceEvent.should) {
      if (event.cancelable) {
        event.preventDefault();
      }
      return;
    }
    if (!sourceEvent) {
      var shardNodes = (lastProps.current.shards || []).map(extractRef).filter(Boolean).filter(function(node) {
        return node.contains(event.target);
      });
      var shouldStop = shardNodes.length > 0 ? shouldCancelEvent(event, shardNodes[0]) : !lastProps.current.noIsolation;
      if (shouldStop) {
        if (event.cancelable) {
          event.preventDefault();
        }
      }
    }
  }, []);
  var shouldCancel = React47.useCallback(function(name, delta, target, should) {
    var event = { name, delta, target, should, shadowParent: getOutermostShadowParent(target) };
    shouldPreventQueue.current.push(event);
    setTimeout(function() {
      shouldPreventQueue.current = shouldPreventQueue.current.filter(function(e) {
        return e !== event;
      });
    }, 1);
  }, []);
  var scrollTouchStart = React47.useCallback(function(event) {
    touchStartRef.current = getTouchXY(event);
    activeAxis.current = void 0;
  }, []);
  var scrollWheel = React47.useCallback(function(event) {
    shouldCancel(event.type, getDeltaXY(event), event.target, shouldCancelEvent(event, props.lockRef.current));
  }, []);
  var scrollTouchMove = React47.useCallback(function(event) {
    shouldCancel(event.type, getTouchXY(event), event.target, shouldCancelEvent(event, props.lockRef.current));
  }, []);
  React47.useEffect(function() {
    lockStack.push(Style2);
    props.setCallbacks({
      onScrollCapture: scrollWheel,
      onWheelCapture: scrollWheel,
      onTouchMoveCapture: scrollTouchMove
    });
    document.addEventListener("wheel", shouldPrevent, nonPassive);
    document.addEventListener("touchmove", shouldPrevent, nonPassive);
    document.addEventListener("touchstart", scrollTouchStart, nonPassive);
    return function() {
      lockStack = lockStack.filter(function(inst) {
        return inst !== Style2;
      });
      document.removeEventListener("wheel", shouldPrevent, nonPassive);
      document.removeEventListener("touchmove", shouldPrevent, nonPassive);
      document.removeEventListener("touchstart", scrollTouchStart, nonPassive);
    };
  }, []);
  var removeScrollBar = props.removeScrollBar, inert = props.inert;
  return React47.createElement(
    React47.Fragment,
    null,
    inert ? React47.createElement(Style2, { styles: generateStyle(id) }) : null,
    removeScrollBar ? React47.createElement(RemoveScrollBar, { gapMode: props.gapMode }) : null
  );
}
function getOutermostShadowParent(node) {
  var shadowParent = null;
  while (node !== null) {
    if (node instanceof ShadowRoot) {
      shadowParent = node.host;
      node = node.host;
    }
    node = node.parentNode;
  }
  return shadowParent;
}

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/sidecar.js
var sidecar_default = exportSidecar(effectCar, RemoveScrollSideCar);

// ../../node_modules/@radix-ui/react-popover/node_modules/react-remove-scroll/dist/es2015/Combination.js
var ReactRemoveScroll = React48.forwardRef(function(props, ref) {
  return React48.createElement(RemoveScroll, __assign({}, props, { ref, sideCar: sidecar_default }));
});
ReactRemoveScroll.classNames = RemoveScroll.classNames;
var Combination_default = ReactRemoveScroll;

// ../../node_modules/@radix-ui/react-popover/dist/index.mjs
import { jsx as jsx45 } from "react/jsx-runtime";
var POPOVER_NAME = "Popover";
var [createPopoverContext, createPopoverScope] = createContextScope(POPOVER_NAME, [
  createPopperScope
]);
var usePopperScope = createPopperScope();
var [PopoverProvider, usePopoverContext] = createPopoverContext(POPOVER_NAME);
var Popover = (props) => {
  const {
    __scopePopover,
    children,
    open: openProp,
    defaultOpen,
    onOpenChange,
    modal = false
  } = props;
  const popperScope = usePopperScope(__scopePopover);
  const triggerRef = React49.useRef(null);
  const [hasCustomAnchor, setHasCustomAnchor] = React49.useState(false);
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange
  });
  return /* @__PURE__ */ jsx45(Root22, { ...popperScope, children: /* @__PURE__ */ jsx45(
    PopoverProvider,
    {
      scope: __scopePopover,
      contentId: useId5(),
      triggerRef,
      open,
      onOpenChange: setOpen,
      onOpenToggle: React49.useCallback(() => setOpen((prevOpen) => !prevOpen), [setOpen]),
      hasCustomAnchor,
      onCustomAnchorAdd: React49.useCallback(() => setHasCustomAnchor(true), []),
      onCustomAnchorRemove: React49.useCallback(() => setHasCustomAnchor(false), []),
      modal,
      children
    }
  ) });
};
Popover.displayName = POPOVER_NAME;
var ANCHOR_NAME2 = "PopoverAnchor";
var PopoverAnchor = React49.forwardRef(
  (props, forwardedRef) => {
    const { __scopePopover, ...anchorProps } = props;
    const context = usePopoverContext(ANCHOR_NAME2, __scopePopover);
    const popperScope = usePopperScope(__scopePopover);
    const { onCustomAnchorAdd, onCustomAnchorRemove } = context;
    React49.useEffect(() => {
      onCustomAnchorAdd();
      return () => onCustomAnchorRemove();
    }, [onCustomAnchorAdd, onCustomAnchorRemove]);
    return /* @__PURE__ */ jsx45(Anchor, { ...popperScope, ...anchorProps, ref: forwardedRef });
  }
);
PopoverAnchor.displayName = ANCHOR_NAME2;
var TRIGGER_NAME = "PopoverTrigger";
var PopoverTrigger = React49.forwardRef(
  (props, forwardedRef) => {
    const { __scopePopover, ...triggerProps } = props;
    const context = usePopoverContext(TRIGGER_NAME, __scopePopover);
    const popperScope = usePopperScope(__scopePopover);
    const composedTriggerRef = useComposedRefs2(forwardedRef, context.triggerRef);
    const trigger = /* @__PURE__ */ jsx45(
      Primitive2.button,
      {
        type: "button",
        "aria-haspopup": "dialog",
        "aria-expanded": context.open,
        "aria-controls": context.contentId,
        "data-state": getState(context.open),
        ...triggerProps,
        ref: composedTriggerRef,
        onClick: composeEventHandlers(props.onClick, context.onOpenToggle)
      }
    );
    return context.hasCustomAnchor ? trigger : /* @__PURE__ */ jsx45(Anchor, { asChild: true, ...popperScope, children: trigger });
  }
);
PopoverTrigger.displayName = TRIGGER_NAME;
var PORTAL_NAME2 = "PopoverPortal";
var [PortalProvider, usePortalContext] = createPopoverContext(PORTAL_NAME2, {
  forceMount: void 0
});
var PopoverPortal = (props) => {
  const { __scopePopover, forceMount, children, container } = props;
  const context = usePopoverContext(PORTAL_NAME2, __scopePopover);
  return /* @__PURE__ */ jsx45(PortalProvider, { scope: __scopePopover, forceMount, children: /* @__PURE__ */ jsx45(Presence, { present: forceMount || context.open, children: /* @__PURE__ */ jsx45(Portal4, { asChild: true, container, children }) }) });
};
PopoverPortal.displayName = PORTAL_NAME2;
var CONTENT_NAME2 = "PopoverContent";
var PopoverContent = React49.forwardRef(
  (props, forwardedRef) => {
    const portalContext = usePortalContext(CONTENT_NAME2, props.__scopePopover);
    const { forceMount = portalContext.forceMount, ...contentProps } = props;
    const context = usePopoverContext(CONTENT_NAME2, props.__scopePopover);
    return /* @__PURE__ */ jsx45(Presence, { present: forceMount || context.open, children: context.modal ? /* @__PURE__ */ jsx45(PopoverContentModal, { ...contentProps, ref: forwardedRef }) : /* @__PURE__ */ jsx45(PopoverContentNonModal, { ...contentProps, ref: forwardedRef }) });
  }
);
PopoverContent.displayName = CONTENT_NAME2;
var PopoverContentModal = React49.forwardRef(
  (props, forwardedRef) => {
    const context = usePopoverContext(CONTENT_NAME2, props.__scopePopover);
    const contentRef = React49.useRef(null);
    const composedRefs = useComposedRefs2(forwardedRef, contentRef);
    const isRightClickOutsideRef = React49.useRef(false);
    React49.useEffect(() => {
      const content = contentRef.current;
      if (content) return hideOthers(content);
    }, []);
    return /* @__PURE__ */ jsx45(Combination_default, { as: Slot2, allowPinchZoom: true, children: /* @__PURE__ */ jsx45(
      PopoverContentImpl,
      {
        ...props,
        ref: composedRefs,
        trapFocus: context.open,
        disableOutsidePointerEvents: true,
        onCloseAutoFocus: composeEventHandlers(props.onCloseAutoFocus, (event) => {
          event.preventDefault();
          if (!isRightClickOutsideRef.current) context.triggerRef.current?.focus();
        }),
        onPointerDownOutside: composeEventHandlers(
          props.onPointerDownOutside,
          (event) => {
            const originalEvent = event.detail.originalEvent;
            const ctrlLeftClick = originalEvent.button === 0 && originalEvent.ctrlKey === true;
            const isRightClick = originalEvent.button === 2 || ctrlLeftClick;
            isRightClickOutsideRef.current = isRightClick;
          },
          { checkForDefaultPrevented: false }
        ),
        onFocusOutside: composeEventHandlers(
          props.onFocusOutside,
          (event) => event.preventDefault(),
          { checkForDefaultPrevented: false }
        )
      }
    ) });
  }
);
var PopoverContentNonModal = React49.forwardRef(
  (props, forwardedRef) => {
    const context = usePopoverContext(CONTENT_NAME2, props.__scopePopover);
    const hasInteractedOutsideRef = React49.useRef(false);
    const hasPointerDownOutsideRef = React49.useRef(false);
    return /* @__PURE__ */ jsx45(
      PopoverContentImpl,
      {
        ...props,
        ref: forwardedRef,
        trapFocus: false,
        disableOutsidePointerEvents: false,
        onCloseAutoFocus: (event) => {
          props.onCloseAutoFocus?.(event);
          if (!event.defaultPrevented) {
            if (!hasInteractedOutsideRef.current) context.triggerRef.current?.focus();
            event.preventDefault();
          }
          hasInteractedOutsideRef.current = false;
          hasPointerDownOutsideRef.current = false;
        },
        onInteractOutside: (event) => {
          props.onInteractOutside?.(event);
          if (!event.defaultPrevented) {
            hasInteractedOutsideRef.current = true;
            if (event.detail.originalEvent.type === "pointerdown") {
              hasPointerDownOutsideRef.current = true;
            }
          }
          const target = event.target;
          const targetIsTrigger = context.triggerRef.current?.contains(target);
          if (targetIsTrigger) event.preventDefault();
          if (event.detail.originalEvent.type === "focusin" && hasPointerDownOutsideRef.current) {
            event.preventDefault();
          }
        }
      }
    );
  }
);
var PopoverContentImpl = React49.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopePopover,
      trapFocus,
      onOpenAutoFocus,
      onCloseAutoFocus,
      disableOutsidePointerEvents,
      onEscapeKeyDown,
      onPointerDownOutside,
      onFocusOutside,
      onInteractOutside,
      ...contentProps
    } = props;
    const context = usePopoverContext(CONTENT_NAME2, __scopePopover);
    const popperScope = usePopperScope(__scopePopover);
    useFocusGuards();
    return /* @__PURE__ */ jsx45(
      FocusScope,
      {
        asChild: true,
        loop: true,
        trapped: trapFocus,
        onMountAutoFocus: onOpenAutoFocus,
        onUnmountAutoFocus: onCloseAutoFocus,
        children: /* @__PURE__ */ jsx45(
          DismissableLayer,
          {
            asChild: true,
            disableOutsidePointerEvents,
            onInteractOutside,
            onEscapeKeyDown,
            onPointerDownOutside,
            onFocusOutside,
            onDismiss: () => context.onOpenChange(false),
            children: /* @__PURE__ */ jsx45(
              Content6,
              {
                "data-state": getState(context.open),
                role: "dialog",
                id: context.contentId,
                ...popperScope,
                ...contentProps,
                ref: forwardedRef,
                style: {
                  ...contentProps.style,
                  // re-namespace exposed content custom properties
                  ...{
                    "--radix-popover-content-transform-origin": "var(--radix-popper-transform-origin)",
                    "--radix-popover-content-available-width": "var(--radix-popper-available-width)",
                    "--radix-popover-content-available-height": "var(--radix-popper-available-height)",
                    "--radix-popover-trigger-width": "var(--radix-popper-anchor-width)",
                    "--radix-popover-trigger-height": "var(--radix-popper-anchor-height)"
                  }
                }
              }
            )
          }
        )
      }
    );
  }
);
var CLOSE_NAME = "PopoverClose";
var PopoverClose = React49.forwardRef(
  (props, forwardedRef) => {
    const { __scopePopover, ...closeProps } = props;
    const context = usePopoverContext(CLOSE_NAME, __scopePopover);
    return /* @__PURE__ */ jsx45(
      Primitive2.button,
      {
        type: "button",
        ...closeProps,
        ref: forwardedRef,
        onClick: composeEventHandlers(props.onClick, () => context.onOpenChange(false))
      }
    );
  }
);
PopoverClose.displayName = CLOSE_NAME;
var ARROW_NAME2 = "PopoverArrow";
var PopoverArrow = React49.forwardRef(
  (props, forwardedRef) => {
    const { __scopePopover, ...arrowProps } = props;
    const popperScope = usePopperScope(__scopePopover);
    return /* @__PURE__ */ jsx45(Arrow3, { ...popperScope, ...arrowProps, ref: forwardedRef });
  }
);
PopoverArrow.displayName = ARROW_NAME2;
function getState(open) {
  return open ? "open" : "closed";
}
var Root23 = Popover;
var Anchor2 = PopoverAnchor;
var Trigger6 = PopoverTrigger;
var Portal5 = PopoverPortal;
var Content22 = PopoverContent;
var Close2 = PopoverClose;
var Arrow22 = PopoverArrow;

// src/Popover/Popover.tsx
import * as React50 from "react";
import { jsx as jsx46 } from "react/jsx-runtime";
var Popover2 = Root23;
var PopoverTrigger2 = Trigger6;
var PopoverAnchor2 = Anchor2;
var popoverContentStyles = css24({
  zIndex: 50,
  maxWidth: "calc(100vw - 16px)",
  minWidth: "8rem",
  maxHeight: "var(--radix-popover-content-available-height)",
  overflow: "auto",
  rounded: "md",
  p: "4",
  shadow: "0px 4px 20px 0px #1539462E",
  bg: "white",
  color: "foreground.base",
  textStyle: "paragraph_16",
  animationDuration: "150ms",
  animationTimingFunction: "ease-out",
  _focus: {
    outline: "none"
  }
});
var PopoverContent2 = React50.forwardRef(({ className, align = "center", sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx46(Portal5, { children: /* @__PURE__ */ jsx46(
  Content22,
  {
    align,
    className: cx17(popoverContentStyles, className),
    ref,
    sideOffset,
    ...props
  }
) }));
PopoverContent2.displayName = Content22.displayName;
var PopoverClose2 = Close2;
var popoverArrowStyles = css24({
  fill: "white",
  height: "8px",
  width: "14px"
});
var PopoverArrow2 = React50.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx46(
  Arrow22,
  {
    className: cx17(popoverArrowStyles, className),
    ref,
    ...props
  }
));
PopoverArrow2.displayName = Arrow22.displayName;

// src/Progress/Progress.tsx
import { css as css25, cx as cx18 } from "@doowii/design-system/css";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React51 from "react";
import { jsx as jsx47 } from "react/jsx-runtime";
var Progress = React51.forwardRef(({ className, value, ...props }, ref) => /* @__PURE__ */ jsx47(
  ProgressPrimitive.Root,
  {
    className: cx18(
      css25({
        pos: "relative",
        h: "2",
        w: "full",
        overflow: "hidden",
        rounded: "full",
        bg: "base.lightGray"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx47(
      ProgressPrimitive.Indicator,
      {
        className: css25({
          h: "full",
          w: "full",
          flex: "1",
          bg: "linear-gradient(92.59deg, #F6CD3C 2.17%, #29BDF0 94.8%)"
        }),
        style: { transform: `translateX(-${100 - (value ?? 0)}%)` }
      }
    )
  }
));
Progress.displayName = ProgressPrimitive.Root.displayName;

// src/RequestBar/RequestBar.tsx
import { css as css26, cx as cx19 } from "@doowii/design-system/css";
import { flex as flex4 } from "@doowii/design-system/patterns";
import { faChevronDown as faChevronDown3 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon15 } from "@fortawesome/react-fontawesome";
import * as AccordionPrimitive2 from "@radix-ui/react-accordion";
import * as React52 from "react";
import { jsx as jsx48, jsxs as jsxs23 } from "react/jsx-runtime";
var RequestBar = AccordionPrimitive2.Root;
var RequestBarItem = React52.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx48(
  AccordionPrimitive2.Item,
  {
    className: cx19(
      css26({
        p: "4",
        w: "100%",
        bg: "base.blueBrandSecondary",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)"
      }),
      className
    ),
    ref,
    ...props
  }
));
RequestBarItem.displayName = "RequestBarItem";
var RequestBarTrigger = React52.forwardRef(({ className, children, hasContent = false, ...props }, ref) => /* @__PURE__ */ jsx48(AccordionPrimitive2.Header, { className: "flex", children: /* @__PURE__ */ jsxs23(
  AccordionPrimitive2.Trigger,
  {
    className: cx19(
      flex4({
        direction: "row",
        align: "center",
        justify: "center",
        gap: "4"
      }),
      css26({
        w: "100% ",
        cursor: hasContent ? "pointer" : "text",
        userSelect: "text",
        '&[data-state="open"]': {
          "& .follow-up-text::before": {
            content: hasContent ? '"Hide follow-ups"' : '""'
          },
          "& .icon": {
            transform: hasContent ? "rotate(180deg)" : "none"
          }
        },
        '&[data-state="closed"]': {
          "& .follow-up-text::before": {
            content: hasContent ? '"Show follow-ups"' : '""'
          }
        }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx48(
        "div",
        {
          className: cx19(
            flex4({
              direction: "row",
              align: "center",
              justify: "flex-start",
              gap: "2",
              grow: 1
            }),
            css26({
              textStyle: "paragraph_18"
            })
          ),
          children
        }
      ),
      hasContent ? /* @__PURE__ */ jsxs23(
        "div",
        {
          className: cx19(
            flex4({
              direction: "row",
              align: "center",
              justify: "center",
              gap: "4"
            }),
            css26({
              p: "4",
              pr: "3",
              h: "10",
              textStyle: "label_16"
            })
          ),
          children: [
            /* @__PURE__ */ jsx48("span", { className: "follow-up-text" }),
            /* @__PURE__ */ jsx48(
              FontAwesomeIcon15,
              {
                className: cx19(
                  css26({
                    w: "6",
                    h: "6",
                    transition: "transform 0.2s"
                  }),
                  "icon"
                ),
                icon: faChevronDown3
              }
            )
          ]
        }
      ) : null
    ]
  }
) }));
RequestBarTrigger.displayName = "RequestBarTrigger";
var RequestBarContent = React52.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs23(AccordionPrimitive2.Content, { className: css26({}), ref, ...props, children: [
  /* @__PURE__ */ jsx48(
    "div",
    {
      className: css26({
        w: "100%",
        h: "0.5",
        my: "4",
        bg: "linear-gradient(97.52deg, #29BDF0 47.66%, #F6CD3C 65.7%)"
      })
    }
  ),
  /* @__PURE__ */ jsx48(
    "div",
    {
      className: cx19(
        flex4({
          direction: "column",
          align: "center",
          justify: "flex-start",
          gap: "2"
        }),
        css26({ pb: "4", pt: "0" }),
        className
      ),
      children
    }
  )
] }));
RequestBarContent.displayName = AccordionPrimitive2.Content.displayName;

// src/SearchInput/SearchInput.tsx
import { css as css27 } from "@doowii/design-system/css";
import { Box as Box7, VisuallyHidden as VisuallyHidden3 } from "@doowii/design-system/jsx";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon16 } from "@fortawesome/react-fontawesome";
import { useEffect as useEffect16, useId as useId6, useRef as useRef12, useState as useState19 } from "react";
import { jsx as jsx49, jsxs as jsxs24 } from "react/jsx-runtime";
var SearchInput = ({
  variant = "standard",
  ...props
}) => {
  const id = useId6();
  const [isExpanded, setIsExpanded] = useState19(variant === "standard");
  const [isShrinking, setIsShrinking] = useState19(false);
  const inputRef = useRef12(null);
  useEffect16(() => {
    if (isExpanded && inputRef.current && variant !== "standard") {
      inputRef.current.focus();
    }
  }, [isExpanded, variant]);
  const toggleExpand = () => {
    if (variant === "expandable") {
      setIsExpanded((prev) => !prev);
    }
  };
  const handleBlur = () => {
    if (variant === "expandable" && inputRef.current?.value.trim() === "") {
      setIsShrinking(true);
      setTimeout(() => {
        setIsShrinking(false);
        setIsExpanded(false);
      }, 300);
    }
  };
  if (variant === "expandable" && !isExpanded && !isShrinking) {
    return /* @__PURE__ */ jsx49(
      Button,
      {
        "aria-expanded": isExpanded,
        "aria-label": "Expand search input",
        iconOnly: faSearch,
        onClick: toggleExpand,
        size: "medium",
        variant: "dropdown"
      }
    );
  }
  return /* @__PURE__ */ jsxs24(
    Box7,
    {
      css: {
        position: "relative",
        background: "white",
        borderRadius: "56px",
        animation: variant === "standard" ? "" : isShrinking ? `shrinkWidth 0.3s ease-in-out forwards` : `growWidth 0.3s ease-in-out forwards`
      },
      children: [
        /* @__PURE__ */ jsx49(
          Box7,
          {
            css: {
              position: "absolute",
              left: "md",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "15px"
            },
            children: /* @__PURE__ */ jsx49(FontAwesomeIcon16, { icon: faSearch })
          }
        ),
        /* @__PURE__ */ jsx49(VisuallyHidden3, { children: /* @__PURE__ */ jsx49("label", { htmlFor: id, children: props["aria-label"] }) }),
        /* @__PURE__ */ jsx49(
          "input",
          {
            className: css27({
              display: "flex",
              padding: "10px",
              pl: "xl3",
              width: "100%",
              alignItems: "center",
              position: "relative",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "border.base",
              borderRadius: "56px",
              outline: "none"
            }),
            id,
            ref: inputRef,
            ...props,
            onBlur: handleBlur
          }
        )
      ]
    }
  );
};

// src/Select/Select.tsx
import { css as css28, cva as cva11, cx as cx20 } from "@doowii/design-system/css";
import {
  faCheck as faCheck4,
  faChevronDown as faChevronDown4,
  faChevronUp
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon17 } from "@fortawesome/react-fontawesome";
import * as SelectPrimitive from "@radix-ui/react-select";
import * as React53 from "react";
import { jsx as jsx50, jsxs as jsxs25 } from "react/jsx-runtime";
var selectTriggerRecipe = cva11({
  base: {
    display: "flex",
    w: "full",
    alignItems: "center",
    justifyContent: "space-between",
    color: "foreground.base",
    whiteSpace: "nowrap",
    rounded: "md",
    borderWidth: "2px",
    borderColor: "base.gray",
    bgColor: "transparent",
    fontWeight: "500",
    lineHeight: "sm",
    cursor: "pointer",
    _focus: {
      ring: "none",
      ringOffset: "none",
      shadow: "1",
      borderColor: "base.blueBrandPrimary"
    },
    _disabled: { cursor: "not-allowed", opacity: "0.5" },
    "&>span": {
      overflow: "hidden",
      display: "-webkit-box",
      // @ts-ignore
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1"
    }
  },
  variants: {
    size: {
      sm: {
        p: "2",
        mt: "1",
        fontSize: "sm"
      },
      md: {
        p: "4",
        mt: "2",
        fontSize: "md"
      },
      lg: {
        p: "6",
        mt: "3",
        fontSize: "lg"
      }
    },
    error: {
      true: {
        borderColor: "foreground.error"
      }
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        opacity: "0.5"
      }
    }
  },
  defaultVariants: {
    size: "md",
    error: false,
    disabled: false
  }
});
var Select = React53.forwardRef(({ ...props }) => /* @__PURE__ */ jsx50(SelectPrimitive.Root, { ...props }));
Select.displayName = "Select";
var SelectGroup = SelectPrimitive.Group;
var SelectValue = ({ placeholder, ...rest }) => /* @__PURE__ */ jsx50(SelectPrimitive.Value, { placeholder, ...rest });
var SelectTrigger = React53.forwardRef(
  ({
    className,
    children,
    size: size4 = "md",
    error = false,
    disabled = false,
    ...props
  }, ref) => /* @__PURE__ */ jsxs25(
    SelectPrimitive.Trigger,
    {
      className: cx20(selectTriggerRecipe({ size: size4, error, disabled }), className),
      disabled,
      ref,
      ...props,
      children: [
        children,
        /* @__PURE__ */ jsx50(SelectPrimitive.Icon, { asChild: true, children: /* @__PURE__ */ jsx50(
          FontAwesomeIcon17,
          {
            className: css28({
              h: size4 === "sm" ? "3" : size4 === "lg" ? "5" : "4",
              w: size4 === "sm" ? "3" : size4 === "lg" ? "5" : "4",
              opacity: "0.5"
            }),
            icon: faChevronDown4
          }
        ) })
      ]
    }
  )
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
var SelectScrollUpButton = React53.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx50(
  SelectPrimitive.ScrollUpButton,
  {
    className: cx20(
      css28({
        display: "flex",
        cursor: "default",
        alignItems: "center",
        justifyContent: "center",
        pt: "1",
        pb: "1"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx50(FontAwesomeIcon17, { icon: faChevronUp })
  }
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;
var SelectScrollDownButton = React53.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx50(
  SelectPrimitive.ScrollDownButton,
  {
    className: cx20(
      css28({
        display: "flex",
        cursor: "default",
        alignItems: "center",
        justifyContent: "center",
        pt: "1",
        pb: "1"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx50(FontAwesomeIcon17, { icon: faChevronDown4 })
  }
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;
var SelectContent = React53.forwardRef(
  ({ className, children, position = "popper", size: size4 = "md", ...props }, ref) => /* @__PURE__ */ jsx50(SelectPrimitive.Portal, { children: /* @__PURE__ */ jsxs25(
    SelectPrimitive.Content,
    {
      className: cx20(
        css28({
          pos: "relative",
          zIndex: "50",
          maxH: "96",
          minW: "var(--radix-select-trigger-width)",
          // Match trigger width
          w: "var(--radix-select-trigger-width)",
          // Use fullWidth prop
          overflow: "hidden",
          rounded: "md",
          shadow: "0px 4px 20px 0px #1539462E",
          bg: "white"
        }),
        position === "popper" && css28({
          // @ts-ignore
          "data-[side=bottom]": {
            transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
          },
          "data-[side=left]": {
            transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
          },
          "data-[side=right]": {
            transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
          },
          "data-[side=top]": {
            transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
          }
        }),
        className
      ),
      position,
      ref,
      ...props,
      children: [
        /* @__PURE__ */ jsx50(SelectScrollUpButton, {}),
        /* @__PURE__ */ jsx50(
          SelectPrimitive.Viewport,
          {
            className: cx20(
              css28({ p: "1" }),
              position === "popper" && css28({
                w: "var(--radix-select-trigger-width)"
                // Ensure the viewport width matches the trigger
              })
            ),
            children
          }
        ),
        /* @__PURE__ */ jsx50(SelectScrollDownButton, {})
      ]
    }
  ) })
);
SelectContent.displayName = SelectPrimitive.Content.displayName;
var SelectLabel = React53.forwardRef(({ className, size: size4 = "md", ...props }, ref) => /* @__PURE__ */ jsx50(
  SelectPrimitive.Label,
  {
    className: cx20(
      css28({
        pl: "2",
        pr: "2",
        pt: "1.5",
        pb: "1.5",
        fontSize: size4 === "sm" ? "xs" : size4 === "lg" ? "md" : "sm",
        lineHeight: "sm",
        fontWeight: "semibold",
        color: "foreground.base"
      }),
      className
    ),
    ref,
    ...props
  }
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;
var SelectItem = React53.forwardRef(({ className, children, size: size4 = "md", ...props }, ref) => /* @__PURE__ */ jsxs25(
  SelectPrimitive.Item,
  {
    className: cx20(
      css28({
        color: "foreground.base",
        pos: "relative",
        display: "flex",
        w: "full",
        cursor: "default",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        pt: size4 === "sm" ? "2" : size4 === "lg" ? "4" : "3",
        pb: size4 === "sm" ? "2" : size4 === "lg" ? "4" : "3",
        pl: size4 === "sm" ? "2" : size4 === "lg" ? "4" : "3",
        pr: "8",
        fontSize: size4 === "sm" ? "xs" : size4 === "lg" ? "md" : "sm",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        // @ts-ignore
        "data-[disabled]": { pointerEvents: "none", opacity: "0.5" },
        _hover: { bgColor: "base.blueBrandSecondary" },
        _active: { bgColor: "base.blueBrandTertiary" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx50(
        "span",
        {
          className: css28({
            pos: "absolute",
            right: "2",
            display: "flex",
            h: size4 === "sm" ? "3" : size4 === "lg" ? "4" : "3.5",
            w: size4 === "sm" ? "3" : size4 === "lg" ? "4" : "3.5",
            alignItems: "center",
            justifyContent: "center"
          }),
          children: /* @__PURE__ */ jsx50(SelectPrimitive.ItemIndicator, { children: /* @__PURE__ */ jsx50(
            FontAwesomeIcon17,
            {
              className: css28({
                h: size4 === "sm" ? "3" : size4 === "lg" ? "5" : "4",
                w: size4 === "sm" ? "3" : size4 === "lg" ? "5" : "4"
              }),
              icon: faCheck4
            }
          ) })
        }
      ),
      /* @__PURE__ */ jsx50(SelectPrimitive.ItemText, { children })
    ]
  }
));
SelectItem.displayName = SelectPrimitive.Item.displayName;
var SelectSeparator = React53.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx50(
  SelectPrimitive.Separator,
  {
    className: cx20(
      css28({ ml: ".mx-1", mr: ".mx-1", mt: "1", mb: "1", h: "px" }),
      className
    ),
    ref,
    ...props
  }
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

// src/Skeleton/Skeleton.tsx
import { css as css29, cx as cx21 } from "@doowii/design-system/css";
import { Box as Box8 } from "@doowii/design-system/jsx";
import { jsx as jsx51 } from "react/jsx-runtime";
var Skeleton = ({ className, ...props }) => /* @__PURE__ */ jsx51(
  Box8,
  {
    className: cx21(
      css29({ animation: "pulse", bg: "base.lightherGray" }),
      className
    ),
    rounded: props.rounded ?? "md",
    ...props
  }
);

// src/Slider/Slider.tsx
import { css as css30, cva as cva12, cx as cx22 } from "@doowii/design-system/css";
import { HStack as HStack2 } from "@doowii/design-system/jsx";

// ../../node_modules/@radix-ui/react-slider/dist/index.mjs
import * as React58 from "react";

// ../../node_modules/@radix-ui/number/dist/index.mjs
function clamp2(value, [min2, max2]) {
  return Math.min(max2, Math.max(min2, value));
}

// ../../node_modules/@radix-ui/primitive/dist/index.mjs
function composeEventHandlers2(originalEventHandler, ourEventHandler, { checkForDefaultPrevented = true } = {}) {
  return function handleEvent(event) {
    originalEventHandler?.(event);
    if (checkForDefaultPrevented === false || !event.defaultPrevented) {
      return ourEventHandler?.(event);
    }
  };
}

// ../../node_modules/@radix-ui/react-context/dist/index.mjs
import * as React54 from "react";
import { jsx as jsx52 } from "react/jsx-runtime";
function createContextScope2(scopeName, createContextScopeDeps = []) {
  let defaultContexts = [];
  function createContext32(rootComponentName, defaultContext) {
    const BaseContext = React54.createContext(defaultContext);
    const index2 = defaultContexts.length;
    defaultContexts = [...defaultContexts, defaultContext];
    const Provider3 = (props) => {
      const { scope, children, ...context } = props;
      const Context = scope?.[scopeName]?.[index2] || BaseContext;
      const value = React54.useMemo(() => context, Object.values(context));
      return /* @__PURE__ */ jsx52(Context.Provider, { value, children });
    };
    Provider3.displayName = rootComponentName + "Provider";
    function useContext22(consumerName, scope) {
      const Context = scope?.[scopeName]?.[index2] || BaseContext;
      const context = React54.useContext(Context);
      if (context) return context;
      if (defaultContext !== void 0) return defaultContext;
      throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
    }
    return [Provider3, useContext22];
  }
  const createScope = () => {
    const scopeContexts = defaultContexts.map((defaultContext) => {
      return React54.createContext(defaultContext);
    });
    return function useScope(scope) {
      const contexts = scope?.[scopeName] || scopeContexts;
      return React54.useMemo(
        () => ({ [`__scope${scopeName}`]: { ...scope, [scopeName]: contexts } }),
        [scope, contexts]
      );
    };
  };
  createScope.scopeName = scopeName;
  return [createContext32, composeContextScopes2(createScope, ...createContextScopeDeps)];
}
function composeContextScopes2(...scopes) {
  const baseScope = scopes[0];
  if (scopes.length === 1) return baseScope;
  const createScope = () => {
    const scopeHooks = scopes.map((createScope2) => ({
      useScope: createScope2(),
      scopeName: createScope2.scopeName
    }));
    return function useComposedScopes(overrideScopes) {
      const nextScopes = scopeHooks.reduce((nextScopes2, { useScope, scopeName }) => {
        const scopeProps = useScope(overrideScopes);
        const currentScope = scopeProps[`__scope${scopeName}`];
        return { ...nextScopes2, ...currentScope };
      }, {});
      return React54.useMemo(() => ({ [`__scope${baseScope.scopeName}`]: nextScopes }), [nextScopes]);
    };
  };
  createScope.scopeName = baseScope.scopeName;
  return createScope;
}

// ../../node_modules/@radix-ui/react-direction/dist/index.mjs
import * as React55 from "react";
import { jsx as jsx53 } from "react/jsx-runtime";
var DirectionContext = React55.createContext(void 0);
function useDirection(localDir) {
  const globalDir = React55.useContext(DirectionContext);
  return localDir || globalDir || "ltr";
}

// ../../node_modules/@radix-ui/react-use-previous/dist/index.mjs
import * as React56 from "react";
function usePrevious(value) {
  const ref = React56.useRef({ value, previous: value });
  return React56.useMemo(() => {
    if (ref.current.value !== value) {
      ref.current.previous = ref.current.value;
      ref.current.value = value;
    }
    return ref.current.previous;
  }, [value]);
}

// ../../node_modules/@radix-ui/react-collection/dist/index.mjs
import React57 from "react";
import { jsx as jsx54 } from "react/jsx-runtime";
function createCollection(name) {
  const PROVIDER_NAME = name + "CollectionProvider";
  const [createCollectionContext, createCollectionScope3] = createContextScope2(PROVIDER_NAME);
  const [CollectionProviderImpl, useCollectionContext] = createCollectionContext(
    PROVIDER_NAME,
    { collectionRef: { current: null }, itemMap: /* @__PURE__ */ new Map() }
  );
  const CollectionProvider = (props) => {
    const { scope, children } = props;
    const ref = React57.useRef(null);
    const itemMap = React57.useRef(/* @__PURE__ */ new Map()).current;
    return /* @__PURE__ */ jsx54(CollectionProviderImpl, { scope, itemMap, collectionRef: ref, children });
  };
  CollectionProvider.displayName = PROVIDER_NAME;
  const COLLECTION_SLOT_NAME = name + "CollectionSlot";
  const CollectionSlot = React57.forwardRef(
    (props, forwardedRef) => {
      const { scope, children } = props;
      const context = useCollectionContext(COLLECTION_SLOT_NAME, scope);
      const composedRefs = useComposedRefs(forwardedRef, context.collectionRef);
      return /* @__PURE__ */ jsx54(Slot, { ref: composedRefs, children });
    }
  );
  CollectionSlot.displayName = COLLECTION_SLOT_NAME;
  const ITEM_SLOT_NAME = name + "CollectionItemSlot";
  const ITEM_DATA_ATTR = "data-radix-collection-item";
  const CollectionItemSlot = React57.forwardRef(
    (props, forwardedRef) => {
      const { scope, children, ...itemData } = props;
      const ref = React57.useRef(null);
      const composedRefs = useComposedRefs(forwardedRef, ref);
      const context = useCollectionContext(ITEM_SLOT_NAME, scope);
      React57.useEffect(() => {
        context.itemMap.set(ref, { ref, ...itemData });
        return () => void context.itemMap.delete(ref);
      });
      return /* @__PURE__ */ jsx54(Slot, { ...{ [ITEM_DATA_ATTR]: "" }, ref: composedRefs, children });
    }
  );
  CollectionItemSlot.displayName = ITEM_SLOT_NAME;
  function useCollection3(scope) {
    const context = useCollectionContext(name + "CollectionConsumer", scope);
    const getItems = React57.useCallback(() => {
      const collectionNode = context.collectionRef.current;
      if (!collectionNode) return [];
      const orderedNodes = Array.from(collectionNode.querySelectorAll(`[${ITEM_DATA_ATTR}]`));
      const items = Array.from(context.itemMap.values());
      const orderedItems = items.sort(
        (a, b) => orderedNodes.indexOf(a.ref.current) - orderedNodes.indexOf(b.ref.current)
      );
      return orderedItems;
    }, [context.collectionRef, context.itemMap]);
    return getItems;
  }
  return [
    { Provider: CollectionProvider, Slot: CollectionSlot, ItemSlot: CollectionItemSlot },
    useCollection3,
    createCollectionScope3
  ];
}

// ../../node_modules/@radix-ui/react-slider/dist/index.mjs
import { jsx as jsx55, jsxs as jsxs26 } from "react/jsx-runtime";
var PAGE_KEYS = ["PageUp", "PageDown"];
var ARROW_KEYS = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
var BACK_KEYS = {
  "from-left": ["Home", "PageDown", "ArrowDown", "ArrowLeft"],
  "from-right": ["Home", "PageDown", "ArrowDown", "ArrowRight"],
  "from-bottom": ["Home", "PageDown", "ArrowDown", "ArrowLeft"],
  "from-top": ["Home", "PageDown", "ArrowUp", "ArrowLeft"]
};
var SLIDER_NAME = "Slider";
var [Collection, useCollection, createCollectionScope] = createCollection(SLIDER_NAME);
var [createSliderContext, createSliderScope] = createContextScope2(SLIDER_NAME, [
  createCollectionScope
]);
var [SliderProvider, useSliderContext] = createSliderContext(SLIDER_NAME);
var Slider = React58.forwardRef(
  (props, forwardedRef) => {
    const {
      name,
      min: min2 = 0,
      max: max2 = 100,
      step = 1,
      orientation = "horizontal",
      disabled = false,
      minStepsBetweenThumbs = 0,
      defaultValue = [min2],
      value,
      onValueChange = () => {
      },
      onValueCommit = () => {
      },
      inverted = false,
      form,
      ...sliderProps
    } = props;
    const thumbRefs = React58.useRef(/* @__PURE__ */ new Set());
    const valueIndexToChangeRef = React58.useRef(0);
    const isHorizontal = orientation === "horizontal";
    const SliderOrientation = isHorizontal ? SliderHorizontal : SliderVertical;
    const [values = [], setValues] = useControllableState({
      prop: value,
      defaultProp: defaultValue,
      onChange: (value2) => {
        const thumbs = [...thumbRefs.current];
        thumbs[valueIndexToChangeRef.current]?.focus();
        onValueChange(value2);
      }
    });
    const valuesBeforeSlideStartRef = React58.useRef(values);
    function handleSlideStart(value2) {
      const closestIndex = getClosestValueIndex(values, value2);
      updateValues(value2, closestIndex);
    }
    function handleSlideMove(value2) {
      updateValues(value2, valueIndexToChangeRef.current);
    }
    function handleSlideEnd() {
      const prevValue = valuesBeforeSlideStartRef.current[valueIndexToChangeRef.current];
      const nextValue = values[valueIndexToChangeRef.current];
      const hasChanged = nextValue !== prevValue;
      if (hasChanged) onValueCommit(values);
    }
    function updateValues(value2, atIndex, { commit } = { commit: false }) {
      const decimalCount = getDecimalCount(step);
      const snapToStep = roundValue(Math.round((value2 - min2) / step) * step + min2, decimalCount);
      const nextValue = clamp2(snapToStep, [min2, max2]);
      setValues((prevValues = []) => {
        const nextValues = getNextSortedValues(prevValues, nextValue, atIndex);
        if (hasMinStepsBetweenValues(nextValues, minStepsBetweenThumbs * step)) {
          valueIndexToChangeRef.current = nextValues.indexOf(nextValue);
          const hasChanged = String(nextValues) !== String(prevValues);
          if (hasChanged && commit) onValueCommit(nextValues);
          return hasChanged ? nextValues : prevValues;
        } else {
          return prevValues;
        }
      });
    }
    return /* @__PURE__ */ jsx55(
      SliderProvider,
      {
        scope: props.__scopeSlider,
        name,
        disabled,
        min: min2,
        max: max2,
        valueIndexToChangeRef,
        thumbs: thumbRefs.current,
        values,
        orientation,
        form,
        children: /* @__PURE__ */ jsx55(Collection.Provider, { scope: props.__scopeSlider, children: /* @__PURE__ */ jsx55(Collection.Slot, { scope: props.__scopeSlider, children: /* @__PURE__ */ jsx55(
          SliderOrientation,
          {
            "aria-disabled": disabled,
            "data-disabled": disabled ? "" : void 0,
            ...sliderProps,
            ref: forwardedRef,
            onPointerDown: composeEventHandlers2(sliderProps.onPointerDown, () => {
              if (!disabled) valuesBeforeSlideStartRef.current = values;
            }),
            min: min2,
            max: max2,
            inverted,
            onSlideStart: disabled ? void 0 : handleSlideStart,
            onSlideMove: disabled ? void 0 : handleSlideMove,
            onSlideEnd: disabled ? void 0 : handleSlideEnd,
            onHomeKeyDown: () => !disabled && updateValues(min2, 0, { commit: true }),
            onEndKeyDown: () => !disabled && updateValues(max2, values.length - 1, { commit: true }),
            onStepKeyDown: ({ event, direction: stepDirection }) => {
              if (!disabled) {
                const isPageKey = PAGE_KEYS.includes(event.key);
                const isSkipKey = isPageKey || event.shiftKey && ARROW_KEYS.includes(event.key);
                const multiplier = isSkipKey ? 10 : 1;
                const atIndex = valueIndexToChangeRef.current;
                const value2 = values[atIndex];
                const stepInDirection = step * multiplier * stepDirection;
                updateValues(value2 + stepInDirection, atIndex, { commit: true });
              }
            }
          }
        ) }) })
      }
    );
  }
);
Slider.displayName = SLIDER_NAME;
var [SliderOrientationProvider, useSliderOrientationContext] = createSliderContext(SLIDER_NAME, {
  startEdge: "left",
  endEdge: "right",
  size: "width",
  direction: 1
});
var SliderHorizontal = React58.forwardRef(
  (props, forwardedRef) => {
    const {
      min: min2,
      max: max2,
      dir,
      inverted,
      onSlideStart,
      onSlideMove,
      onSlideEnd,
      onStepKeyDown,
      ...sliderProps
    } = props;
    const [slider, setSlider] = React58.useState(null);
    const composedRefs = useComposedRefs(forwardedRef, (node) => setSlider(node));
    const rectRef = React58.useRef(void 0);
    const direction = useDirection(dir);
    const isDirectionLTR = direction === "ltr";
    const isSlidingFromLeft = isDirectionLTR && !inverted || !isDirectionLTR && inverted;
    function getValueFromPointer(pointerPosition) {
      const rect = rectRef.current || slider.getBoundingClientRect();
      const input = [0, rect.width];
      const output = isSlidingFromLeft ? [min2, max2] : [max2, min2];
      const value = linearScale(input, output);
      rectRef.current = rect;
      return value(pointerPosition - rect.left);
    }
    return /* @__PURE__ */ jsx55(
      SliderOrientationProvider,
      {
        scope: props.__scopeSlider,
        startEdge: isSlidingFromLeft ? "left" : "right",
        endEdge: isSlidingFromLeft ? "right" : "left",
        direction: isSlidingFromLeft ? 1 : -1,
        size: "width",
        children: /* @__PURE__ */ jsx55(
          SliderImpl,
          {
            dir: direction,
            "data-orientation": "horizontal",
            ...sliderProps,
            ref: composedRefs,
            style: {
              ...sliderProps.style,
              ["--radix-slider-thumb-transform"]: "translateX(-50%)"
            },
            onSlideStart: (event) => {
              const value = getValueFromPointer(event.clientX);
              onSlideStart?.(value);
            },
            onSlideMove: (event) => {
              const value = getValueFromPointer(event.clientX);
              onSlideMove?.(value);
            },
            onSlideEnd: () => {
              rectRef.current = void 0;
              onSlideEnd?.();
            },
            onStepKeyDown: (event) => {
              const slideDirection = isSlidingFromLeft ? "from-left" : "from-right";
              const isBackKey = BACK_KEYS[slideDirection].includes(event.key);
              onStepKeyDown?.({ event, direction: isBackKey ? -1 : 1 });
            }
          }
        )
      }
    );
  }
);
var SliderVertical = React58.forwardRef(
  (props, forwardedRef) => {
    const {
      min: min2,
      max: max2,
      inverted,
      onSlideStart,
      onSlideMove,
      onSlideEnd,
      onStepKeyDown,
      ...sliderProps
    } = props;
    const sliderRef = React58.useRef(null);
    const ref = useComposedRefs(forwardedRef, sliderRef);
    const rectRef = React58.useRef(void 0);
    const isSlidingFromBottom = !inverted;
    function getValueFromPointer(pointerPosition) {
      const rect = rectRef.current || sliderRef.current.getBoundingClientRect();
      const input = [0, rect.height];
      const output = isSlidingFromBottom ? [max2, min2] : [min2, max2];
      const value = linearScale(input, output);
      rectRef.current = rect;
      return value(pointerPosition - rect.top);
    }
    return /* @__PURE__ */ jsx55(
      SliderOrientationProvider,
      {
        scope: props.__scopeSlider,
        startEdge: isSlidingFromBottom ? "bottom" : "top",
        endEdge: isSlidingFromBottom ? "top" : "bottom",
        size: "height",
        direction: isSlidingFromBottom ? 1 : -1,
        children: /* @__PURE__ */ jsx55(
          SliderImpl,
          {
            "data-orientation": "vertical",
            ...sliderProps,
            ref,
            style: {
              ...sliderProps.style,
              ["--radix-slider-thumb-transform"]: "translateY(50%)"
            },
            onSlideStart: (event) => {
              const value = getValueFromPointer(event.clientY);
              onSlideStart?.(value);
            },
            onSlideMove: (event) => {
              const value = getValueFromPointer(event.clientY);
              onSlideMove?.(value);
            },
            onSlideEnd: () => {
              rectRef.current = void 0;
              onSlideEnd?.();
            },
            onStepKeyDown: (event) => {
              const slideDirection = isSlidingFromBottom ? "from-bottom" : "from-top";
              const isBackKey = BACK_KEYS[slideDirection].includes(event.key);
              onStepKeyDown?.({ event, direction: isBackKey ? -1 : 1 });
            }
          }
        )
      }
    );
  }
);
var SliderImpl = React58.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopeSlider,
      onSlideStart,
      onSlideMove,
      onSlideEnd,
      onHomeKeyDown,
      onEndKeyDown,
      onStepKeyDown,
      ...sliderProps
    } = props;
    const context = useSliderContext(SLIDER_NAME, __scopeSlider);
    return /* @__PURE__ */ jsx55(
      Primitive.span,
      {
        ...sliderProps,
        ref: forwardedRef,
        onKeyDown: composeEventHandlers2(props.onKeyDown, (event) => {
          if (event.key === "Home") {
            onHomeKeyDown(event);
            event.preventDefault();
          } else if (event.key === "End") {
            onEndKeyDown(event);
            event.preventDefault();
          } else if (PAGE_KEYS.concat(ARROW_KEYS).includes(event.key)) {
            onStepKeyDown(event);
            event.preventDefault();
          }
        }),
        onPointerDown: composeEventHandlers2(props.onPointerDown, (event) => {
          const target = event.target;
          target.setPointerCapture(event.pointerId);
          event.preventDefault();
          if (context.thumbs.has(target)) {
            target.focus();
          } else {
            onSlideStart(event);
          }
        }),
        onPointerMove: composeEventHandlers2(props.onPointerMove, (event) => {
          const target = event.target;
          if (target.hasPointerCapture(event.pointerId)) onSlideMove(event);
        }),
        onPointerUp: composeEventHandlers2(props.onPointerUp, (event) => {
          const target = event.target;
          if (target.hasPointerCapture(event.pointerId)) {
            target.releasePointerCapture(event.pointerId);
            onSlideEnd(event);
          }
        })
      }
    );
  }
);
var TRACK_NAME = "SliderTrack";
var SliderTrack = React58.forwardRef(
  (props, forwardedRef) => {
    const { __scopeSlider, ...trackProps } = props;
    const context = useSliderContext(TRACK_NAME, __scopeSlider);
    return /* @__PURE__ */ jsx55(
      Primitive.span,
      {
        "data-disabled": context.disabled ? "" : void 0,
        "data-orientation": context.orientation,
        ...trackProps,
        ref: forwardedRef
      }
    );
  }
);
SliderTrack.displayName = TRACK_NAME;
var RANGE_NAME = "SliderRange";
var SliderRange = React58.forwardRef(
  (props, forwardedRef) => {
    const { __scopeSlider, ...rangeProps } = props;
    const context = useSliderContext(RANGE_NAME, __scopeSlider);
    const orientation = useSliderOrientationContext(RANGE_NAME, __scopeSlider);
    const ref = React58.useRef(null);
    const composedRefs = useComposedRefs(forwardedRef, ref);
    const valuesCount = context.values.length;
    const percentages = context.values.map(
      (value) => convertValueToPercentage(value, context.min, context.max)
    );
    const offsetStart = valuesCount > 1 ? Math.min(...percentages) : 0;
    const offsetEnd = 100 - Math.max(...percentages);
    return /* @__PURE__ */ jsx55(
      Primitive.span,
      {
        "data-orientation": context.orientation,
        "data-disabled": context.disabled ? "" : void 0,
        ...rangeProps,
        ref: composedRefs,
        style: {
          ...props.style,
          [orientation.startEdge]: offsetStart + "%",
          [orientation.endEdge]: offsetEnd + "%"
        }
      }
    );
  }
);
SliderRange.displayName = RANGE_NAME;
var THUMB_NAME = "SliderThumb";
var SliderThumb = React58.forwardRef(
  (props, forwardedRef) => {
    const getItems = useCollection(props.__scopeSlider);
    const [thumb, setThumb] = React58.useState(null);
    const composedRefs = useComposedRefs(forwardedRef, (node) => setThumb(node));
    const index2 = React58.useMemo(
      () => thumb ? getItems().findIndex((item) => item.ref.current === thumb) : -1,
      [getItems, thumb]
    );
    return /* @__PURE__ */ jsx55(SliderThumbImpl, { ...props, ref: composedRefs, index: index2 });
  }
);
var SliderThumbImpl = React58.forwardRef(
  (props, forwardedRef) => {
    const { __scopeSlider, index: index2, name, ...thumbProps } = props;
    const context = useSliderContext(THUMB_NAME, __scopeSlider);
    const orientation = useSliderOrientationContext(THUMB_NAME, __scopeSlider);
    const [thumb, setThumb] = React58.useState(null);
    const composedRefs = useComposedRefs(forwardedRef, (node) => setThumb(node));
    const isFormControl = thumb ? context.form || !!thumb.closest("form") : true;
    const size4 = useSize(thumb);
    const value = context.values[index2];
    const percent = value === void 0 ? 0 : convertValueToPercentage(value, context.min, context.max);
    const label = getLabel(index2, context.values.length);
    const orientationSize = size4?.[orientation.size];
    const thumbInBoundsOffset = orientationSize ? getThumbInBoundsOffset(orientationSize, percent, orientation.direction) : 0;
    React58.useEffect(() => {
      if (thumb) {
        context.thumbs.add(thumb);
        return () => {
          context.thumbs.delete(thumb);
        };
      }
    }, [thumb, context.thumbs]);
    return /* @__PURE__ */ jsxs26(
      "span",
      {
        style: {
          transform: "var(--radix-slider-thumb-transform)",
          position: "absolute",
          [orientation.startEdge]: `calc(${percent}% + ${thumbInBoundsOffset}px)`
        },
        children: [
          /* @__PURE__ */ jsx55(Collection.ItemSlot, { scope: props.__scopeSlider, children: /* @__PURE__ */ jsx55(
            Primitive.span,
            {
              role: "slider",
              "aria-label": props["aria-label"] || label,
              "aria-valuemin": context.min,
              "aria-valuenow": value,
              "aria-valuemax": context.max,
              "aria-orientation": context.orientation,
              "data-orientation": context.orientation,
              "data-disabled": context.disabled ? "" : void 0,
              tabIndex: context.disabled ? void 0 : 0,
              ...thumbProps,
              ref: composedRefs,
              style: value === void 0 ? { display: "none" } : props.style,
              onFocus: composeEventHandlers2(props.onFocus, () => {
                context.valueIndexToChangeRef.current = index2;
              })
            }
          ) }),
          isFormControl && /* @__PURE__ */ jsx55(
            BubbleInput,
            {
              name: name ?? (context.name ? context.name + (context.values.length > 1 ? "[]" : "") : void 0),
              form: context.form,
              value
            },
            index2
          )
        ]
      }
    );
  }
);
SliderThumb.displayName = THUMB_NAME;
var BubbleInput = (props) => {
  const { value, ...inputProps } = props;
  const ref = React58.useRef(null);
  const prevValue = usePrevious(value);
  React58.useEffect(() => {
    const input = ref.current;
    const inputProto = window.HTMLInputElement.prototype;
    const descriptor = Object.getOwnPropertyDescriptor(inputProto, "value");
    const setValue = descriptor.set;
    if (prevValue !== value && setValue) {
      const event = new Event("input", { bubbles: true });
      setValue.call(input, value);
      input.dispatchEvent(event);
    }
  }, [prevValue, value]);
  return /* @__PURE__ */ jsx55("input", { style: { display: "none" }, ...inputProps, ref, defaultValue: value });
};
function getNextSortedValues(prevValues = [], nextValue, atIndex) {
  const nextValues = [...prevValues];
  nextValues[atIndex] = nextValue;
  return nextValues.sort((a, b) => a - b);
}
function convertValueToPercentage(value, min2, max2) {
  const maxSteps = max2 - min2;
  const percentPerStep = 100 / maxSteps;
  const percentage = percentPerStep * (value - min2);
  return clamp2(percentage, [0, 100]);
}
function getLabel(index2, totalValues) {
  if (totalValues > 2) {
    return `Value ${index2 + 1} of ${totalValues}`;
  } else if (totalValues === 2) {
    return ["Minimum", "Maximum"][index2];
  } else {
    return void 0;
  }
}
function getClosestValueIndex(values, nextValue) {
  if (values.length === 1) return 0;
  const distances = values.map((value) => Math.abs(value - nextValue));
  const closestDistance = Math.min(...distances);
  return distances.indexOf(closestDistance);
}
function getThumbInBoundsOffset(width, left, direction) {
  const halfWidth = width / 2;
  const halfPercent = 50;
  const offset4 = linearScale([0, halfPercent], [0, halfWidth]);
  return (halfWidth - offset4(left) * direction) * direction;
}
function getStepsBetweenValues(values) {
  return values.slice(0, -1).map((value, index2) => values[index2 + 1] - value);
}
function hasMinStepsBetweenValues(values, minStepsBetweenValues) {
  if (minStepsBetweenValues > 0) {
    const stepsBetweenValues = getStepsBetweenValues(values);
    const actualMinStepsBetweenValues = Math.min(...stepsBetweenValues);
    return actualMinStepsBetweenValues >= minStepsBetweenValues;
  }
  return true;
}
function linearScale(input, output) {
  return (value) => {
    if (input[0] === input[1] || output[0] === output[1]) return output[0];
    const ratio = (output[1] - output[0]) / (input[1] - input[0]);
    return output[0] + ratio * (value - input[0]);
  };
}
function getDecimalCount(value) {
  return (String(value).split(".")[1] || "").length;
}
function roundValue(value, decimalCount) {
  const rounder = Math.pow(10, decimalCount);
  return Math.round(value * rounder) / rounder;
}
var Root15 = Slider;
var Track = SliderTrack;
var Range = SliderRange;
var Thumb = SliderThumb;

// src/Slider/Slider.tsx
import * as React59 from "react";
import { jsx as jsx56, jsxs as jsxs27 } from "react/jsx-runtime";
var sliderRootStyles = cva12({
  base: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    touchAction: "none",
    width: "full",
    height: "20px"
  },
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: "not-allowed",
        pointerEvents: "none"
      }
    }
  },
  defaultVariants: {
    disabled: false
  }
});
var sliderTrackStyles = cva12({
  base: {
    backgroundColor: "base.lightGray",
    position: "relative",
    flexGrow: 1,
    borderRadius: "full",
    height: "4px",
    width: "100%"
  }
});
var sliderRangeStyles = cva12({
  base: {
    position: "absolute",
    backgroundColor: "base.blueBrandPrimary",
    borderRadius: "full",
    height: "100%"
  }
});
var sliderThumbStyles = cva12({
  base: {
    display: "block",
    width: "16px",
    height: "16px",
    backgroundColor: "white",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: "full",
    border: "2px solid",
    borderColor: "base.blueBrandPrimary",
    transition: "background-color 0.2s",
    _focus: {
      outline: "none",
      ring: "2px",
      ringColor: "base.blueBrandSecondary",
      ringOffset: "2px"
    },
    _hover: {
      cursor: "pointer",
      backgroundColor: "base.blueBrandSecondary"
    },
    _disabled: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed"
    }
  }
});
var titleStyles = cva12({
  base: {
    textStyle: "label_16",
    color: "foreground.base",
    mb: "2"
  }
});
var rangeValueStyles = cva12({
  base: {
    textStyle: "paragraph_14",
    color: "foreground.disabled"
  }
});
var SliderRoot = React59.forwardRef(
  ({
    className,
    showValues = false,
    formatValue = (value) => value.toString(),
    title,
    disabled = false,
    showTooltip = false,
    onValueChange,
    ...props
  }, ref) => {
    const rangeBounds = {
      min: props.min ?? 0,
      max: props.max ?? 100
    };
    const externalValue = props.value ?? props.defaultValue ?? [0];
    const [internalValue, setInternalValue] = React59.useState(externalValue);
    const value = props.value ? externalValue : internalValue;
    const [activeThumbIndex, setActiveThumbIndex] = React59.useState(null);
    const handleDragStart = (event) => {
      const sliderRect = event.currentTarget.getBoundingClientRect();
      const position = (event.clientX - sliderRect.left) / sliderRect.width * (rangeBounds.max - rangeBounds.min) + rangeBounds.min;
      let closestThumbIndex = 0;
      let minDistance = Math.abs(value[0] - position);
      for (let i = 1; i < value.length; i++) {
        const distance = Math.abs(value[i] - position);
        if (distance < minDistance) {
          minDistance = distance;
          closestThumbIndex = i;
        }
      }
      setActiveThumbIndex(closestThumbIndex);
    };
    const handleDragEnd = () => {
      setActiveThumbIndex(null);
    };
    const handleValueChange = (newValue) => {
      setInternalValue(newValue);
      if (onValueChange) {
        onValueChange(newValue);
      }
    };
    React59.useEffect(() => {
      const handlePointerUp = () => {
        setActiveThumbIndex(null);
      };
      document.addEventListener("pointerup", handlePointerUp);
      return () => {
        document.removeEventListener("pointerup", handlePointerUp);
      };
    }, []);
    return /* @__PURE__ */ jsxs27(
      "div",
      {
        className: css30({
          width: "full",
          display: "flex",
          flexDirection: "column",
          gap: "2"
        }),
        children: [
          title ? /* @__PURE__ */ jsx56("div", { className: css30(titleStyles.raw()), children: title }) : null,
          showValues ? /* @__PURE__ */ jsxs27(HStack2, { justify: "space-between", width: "full", children: [
            /* @__PURE__ */ jsx56("span", { className: css30(rangeValueStyles.raw()), children: formatValue(rangeBounds.min) }),
            /* @__PURE__ */ jsx56("span", { className: css30(rangeValueStyles.raw()), children: formatValue(rangeBounds.max) })
          ] }) : null,
          /* @__PURE__ */ jsxs27(
            Root15,
            {
              className: cx22(css30(sliderRootStyles.raw({ disabled })), className),
              "data-disabled": disabled || void 0,
              onPointerDown: handleDragStart,
              onPointerUp: handleDragEnd,
              onValueChange: handleValueChange,
              ref,
              ...props,
              children: [
                /* @__PURE__ */ jsx56(Track, { className: css30(sliderTrackStyles.raw()), children: /* @__PURE__ */ jsx56(Range, { className: css30(sliderRangeStyles.raw()) }) }),
                value.map(
                  (val, index2) => showTooltip ? /* @__PURE__ */ jsx56(
                    Tooltip,
                    {
                      className: css30({
                        padding: "0"
                      }),
                      content: formatValue(val),
                      delayDuration: 0,
                      open: activeThumbIndex === index2 || void 0,
                      side: "top",
                      sideOffset: 6,
                      children: /* @__PURE__ */ jsx56(
                        Thumb,
                        {
                          className: css30(sliderThumbStyles.raw())
                        }
                      )
                    },
                    index2
                  ) : /* @__PURE__ */ jsx56(
                    Thumb,
                    {
                      className: css30(sliderThumbStyles.raw())
                    },
                    index2
                  )
                )
              ]
            }
          )
        ]
      }
    );
  }
);
SliderRoot.displayName = Root15.displayName;
var RangeSlider = React59.forwardRef((props, ref) => /* @__PURE__ */ jsx56(SliderRoot, { ref, ...props }));
RangeSlider.displayName = "RangeSlider";
var Slider2 = React59.forwardRef(({ value, defaultValue, onValueChange, ...props }, ref) => {
  const singleValueToArray = (val) => val !== void 0 ? [val] : void 0;
  const handleValueChange = (values) => {
    if (onValueChange && values.length > 0) {
      onValueChange(values[0]);
    }
  };
  return /* @__PURE__ */ jsx56(
    SliderRoot,
    {
      defaultValue: singleValueToArray(defaultValue),
      onValueChange: handleValueChange,
      ref,
      value: singleValueToArray(value),
      ...props
    }
  );
});
Slider2.displayName = "Slider";

// src/SuggestionCard/SuggestionCard.tsx
import { css as css31, cx as cx23 } from "@doowii/design-system/css";
import { flex as flex5 } from "@doowii/design-system/patterns";
import { faPaperPlaneTop as faPaperPlaneTop3 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon18 } from "@fortawesome/react-fontawesome";
import { jsx as jsx57, jsxs as jsxs28 } from "react/jsx-runtime";
var suggestionCardStyle = cx23(
  flex5({
    gap: "ld",
    direction: "row",
    align: "center",
    justify: "start"
  }),
  css31({
    bg: "base.white",
    p: "ld",
    rounded: "xl",
    cursor: "pointer",
    border: "1px solid token(colors.base.lightGray)",
    shadow: "regular",
    w: "full",
    maxWidth: "720px",
    minHeight: "92px",
    textAlign: "left",
    color: "base.darkBlue",
    _hover: {
      shadow: "hover",
      bg: "base.blueBrandSecondary"
    }
  })
);
var suggestionCardTextStyle = cx23(
  flex5({
    grow: 1
  }),
  css31({
    textStyle: "paragraph_16"
  })
);
var suggestionCardIconStyle = cx23(
  flex5({
    align: "center",
    justify: "center",
    shrink: 0
  }),
  css31({
    w: "32px",
    h: "32px"
  })
);
var SuggestionCard = ({
  onClick,
  children,
  label
}) => /* @__PURE__ */ jsxs28(
  "button",
  {
    "aria-label": label,
    className: suggestionCardStyle,
    onClick,
    type: "button",
    children: [
      /* @__PURE__ */ jsx57("div", { className: suggestionCardTextStyle, children }),
      /* @__PURE__ */ jsx57("div", { className: suggestionCardIconStyle, children: /* @__PURE__ */ jsx57(FontAwesomeIcon18, { icon: faPaperPlaneTop3 }) })
    ]
  }
);

// src/Switch/Switch.tsx
import { css as css32, cx as cx24 } from "@doowii/design-system/css";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import * as React60 from "react";
import { jsx as jsx58 } from "react/jsx-runtime";
var Switch = React60.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx58(
  SwitchPrimitive.Root,
  {
    className: cx24(
      css32({
        all: "unset",
        width: "42px",
        // Default width
        height: "25px",
        // Default height
        backgroundColor: "#117DA3",
        borderRadius: "9999px",
        position: "relative",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
        transition: "background-color 0.2s",
        '&[data-state="checked"]': { backgroundColor: "#33A665" },
        "&[data-disabled]": { backgroundColor: "#A9A9A9" }
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx58(
      SwitchPrimitive.Thumb,
      {
        className: css32({
          display: "block",
          width: "21px",
          // Default thumb width
          height: "21px",
          // Default thumb height
          backgroundColor: "white",
          borderRadius: "9999px",
          boxShadow: "0 2px 2px rgba(0, 0, 0, 0.05)",
          transition: "transform 0.2s",
          transform: "translateX(2px)",
          // Default unchecked position
          '[data-state="checked"] &': { transform: "translateX(19px)" },
          // Checked position
          "[data-disabled] &": { backgroundColor: "#D3D3D3" }
        })
      }
    )
  }
));
Switch.displayName = "Switch";

// src/Table/SimpleTable.tsx
import { css as css35 } from "@doowii/design-system/css";
import { Box as Box10, Flex as Flex7 } from "@doowii/design-system/jsx";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";

// src/Table/Table.tsx
import { css as css34 } from "@doowii/design-system/css";
import { Box as Box9, Flex as Flex6 } from "@doowii/design-system/jsx";
import {
  faChevronLeft,
  faChevronRight as faChevronRight3,
  faChevronsLeft as faChevronsLeft2,
  faChevronsRight as faChevronsRight2
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon19 } from "@fortawesome/react-fontawesome";
import {
  flexRender
} from "@tanstack/react-table";
import { Fragment as Fragment7, useCallback as useCallback10 } from "react";

// src/Table/TablePrimitive.tsx
import { css as css33, cx as cx25 } from "@doowii/design-system/css";
import * as React61 from "react";
import { jsx as jsx59 } from "react/jsx-runtime";
var Table = React61.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx59(
  "table",
  {
    className: cx25(
      css33({
        w: "full",
        captionSide: "bottom",
        fontSize: "sm",
        lineHeight: "sm"
      }),
      className
    ),
    ref,
    ...props
  }
));
Table.displayName = "Table";
var TableHeader = React61.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx59(
  "thead",
  {
    className: cx25(
      css33({
        position: "sticky",
        top: 0,
        zIndex: 1,
        _first: { "&>tr": { borderBottomWidth: "1px" } }
      }),
      className
    ),
    ref,
    ...props
  }
));
TableHeader.displayName = "TableHeader";
var TableBody = React61.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx59("tbody", { className: cx25(className), ref, ...props }));
TableBody.displayName = "TableBody";
var TableFooter = React61.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx59(
  "tfoot",
  {
    className: cx25(
      css33({
        borderTopWidth: "1px",
        fontWeight: "medium",
        _last: { "&>tr": { borderBottomWidth: "0px" } }
      }),
      className
    ),
    ref,
    ...props
  }
));
TableFooter.displayName = "TableFooter";
var TableRow = React61.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx59(
  "tr",
  {
    className: cx25(
      css33({
        borderBottomWidth: "1px",
        transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
        transitionTimingFunction: "colors",
        transitionDuration: "colors",
        _last: {
          borderBottomWidth: "0px"
        }
      }),
      className
    ),
    ref,
    ...props
  }
));
TableRow.displayName = "TableRow";
var TableHead = React61.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsx59(
  "th",
  {
    className: cx25(
      css33({
        h: "12",
        px: "4",
        textAlign: "left",
        verticalAlign: "middle",
        fontWeight: "medium",
        "&:has([role=checkbox])": { pr: "0" }
      }),
      className
    ),
    ref,
    scope: "col",
    ...props,
    children
  }
));
TableHead.displayName = "TableHead";
var TableCell = React61.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsx59(
  "td",
  {
    className: cx25(
      css33({
        lineHeight: "normal",
        p: "4",
        verticalAlign: "middle",
        "&:has([role=checkbox])": { pr: "0" }
      }),
      className
    ),
    ref,
    ...props,
    children
  }
));
TableCell.displayName = "TableCell";
var TableCaption = React61.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx59(
  "caption",
  {
    className: cx25(
      css33({ mt: "4", fontSize: "sm", lineHeight: "sm" }),
      className
    ),
    ref,
    ...props
  }
));
TableCaption.displayName = "TableCaption";

// src/Table/Table.tsx
import { jsx as jsx60, jsxs as jsxs29 } from "react/jsx-runtime";
var TableHeader2 = ({
  table,
  className
}) => /* @__PURE__ */ jsx60(TableHeader, { className, children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ jsx60(TableRow, { children: headerGroup.headers.map((header) => /* @__PURE__ */ jsx60(TableHead, { children: header.isPlaceholder ? null : /* @__PURE__ */ jsx60(Label, { level: 1, children: flexRender(
  header.column.columnDef.header,
  header.getContext()
) }) }, header.id)) }, headerGroup.id)) });
var TableBody2 = ({
  table,
  className,
  renderSubComponent
}) => /* @__PURE__ */ jsx60(TableBody, { className, children: table.getRowModel().rows.map((row) => /* @__PURE__ */ jsxs29(Fragment7, { children: [
  /* @__PURE__ */ jsx60(
    TableRow,
    {
      "data-state": row.getIsSelected() ? "selected" : null,
      children: row.getVisibleCells().map((cell) => /* @__PURE__ */ jsx60(TableCell, { children: cell.column.columnDef.isAction ? flexRender(cell.column.columnDef.cell, cell.getContext()) : /* @__PURE__ */ jsx60(Label, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }) }, cell.id))
    }
  ),
  renderSubComponent && row.getIsExpanded() ? /* @__PURE__ */ jsx60(TableRow, { children: /* @__PURE__ */ jsx60(
    TableCell,
    {
      colSpan: row.getVisibleCells().length,
      style: { padding: "0" },
      children: renderSubComponent({ row })
    }
  ) }) : null
] }, row.id)) });
var LoadingSkeleton = () => /* @__PURE__ */ jsxs29(Flex6, { css: { flexDirection: "column", gap: "md" }, children: [
  /* @__PURE__ */ jsxs29(Flex6, { css: { alignItems: "center", gap: "md" }, children: [
    /* @__PURE__ */ jsx60(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx60(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx60(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx60(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) })
  ] }),
  Array.from(Array(11).keys()).map((index2) => /* @__PURE__ */ jsxs29(
    Flex6,
    {
      css: {
        alignItems: "center",
        gap: "md",
        justifyContent: "space-between"
      },
      children: [
        /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx60(Skeleton, { css: { height: "32px", flex: 1 } })
      ]
    },
    index2
  ))
] });
var DefaultEmptyComponent = () => /* @__PURE__ */ jsx60(Flex6, { css: { alignItems: "center", justifyContent: "center", h: "full" }, children: "No results." });
var Table2 = ({
  table,
  className,
  loading,
  emptyComponent,
  renderSubComponent
}) => {
  const renderTable = useCallback10(() => {
    if (loading) {
      return /* @__PURE__ */ jsx60(LoadingSkeleton, {});
    }
    if (table.getRowModel().rows.length < 1) {
      return emptyComponent ?? /* @__PURE__ */ jsx60(DefaultEmptyComponent, {});
    }
    return /* @__PURE__ */ jsx60(Box9, { className, overflowY: "auto", children: /* @__PURE__ */ jsxs29(Table, { children: [
      /* @__PURE__ */ jsx60(TableHeader2, { table }),
      /* @__PURE__ */ jsx60(TableBody2, { renderSubComponent, table })
    ] }) });
  }, [table, loading, emptyComponent, renderSubComponent, className]);
  return renderTable();
};
var TablePaginationActions = ({
  table
}) => /* @__PURE__ */ jsxs29(
  Flex6,
  {
    css: {
      alignItems: "center",
      mr: "2",
      ml: "2",
      gap: "sm",
      color: "foreground.base"
    },
    children: [
      /* @__PURE__ */ jsxs29(
        Button,
        {
          className: css34({
            display: "none",
            h: "8",
            w: "8",
            p: "0",
            lg: { display: "flex" }
          }),
          disabled: !table.getCanPreviousPage(),
          onClick: () => table.setPageIndex(0),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx60(
              "span",
              {
                className: css34({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to first page"
              }
            ),
            /* @__PURE__ */ jsx60(
              FontAwesomeIcon19,
              {
                className: css34({ h: "4", w: "4" }),
                icon: faChevronsLeft2,
                size: "sm"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs29(
        Button,
        {
          className: css34({ h: "8", w: "8", p: "0" }),
          disabled: !table.getCanPreviousPage(),
          onClick: () => table.previousPage(),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx60(
              "span",
              {
                className: css34({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to previous page"
              }
            ),
            /* @__PURE__ */ jsx60(
              FontAwesomeIcon19,
              {
                className: css34({ h: "4", w: "4" }),
                icon: faChevronLeft,
                size: "xs"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs29(
        "div",
        {
          className: css34({
            display: "flex",
            w: "100px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "sm",
            lineHeight: "sm",
            fontWeight: "medium"
          }),
          children: [
            "Page ",
            table.getState().pagination.pageIndex + 1,
            " of ",
            table.getPageCount()
          ]
        }
      ),
      /* @__PURE__ */ jsxs29(
        Button,
        {
          className: css34({ h: "8", w: "8", p: "0" }),
          disabled: !table.getCanNextPage(),
          onClick: () => table.nextPage(),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx60(
              "span",
              {
                className: css34({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to next page"
              }
            ),
            /* @__PURE__ */ jsx60(
              FontAwesomeIcon19,
              {
                className: css34({ h: "4", w: "4" }),
                icon: faChevronRight3,
                size: "xs"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs29(
        Button,
        {
          className: css34({
            display: "none",
            h: "8",
            w: "8",
            p: "0",
            lg: { display: "flex" }
          }),
          disabled: !table.getCanNextPage(),
          onClick: () => table.setPageIndex(table.getPageCount() - 1),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx60(
              "span",
              {
                className: css34({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to last page"
              }
            ),
            /* @__PURE__ */ jsx60(
              FontAwesomeIcon19,
              {
                className: css34({ h: "4", w: "4" }),
                icon: faChevronsRight2,
                size: "sm"
              }
            )
          ]
        }
      )
    ]
  }
);
var TablePaginationRPS = ({
  table
}) => /* @__PURE__ */ jsxs29(
  Flex6,
  {
    css: {
      alignItems: "center",
      mr: "2",
      ml: "2",
      gap: "sm"
    },
    children: [
      /* @__PURE__ */ jsx60(
        "p",
        {
          className: css34({
            fontSize: "sm",
            lineHeight: "sm",
            fontWeight: "medium",
            color: "foreground.base"
          }),
          children: "Rows per page"
        }
      ),
      /* @__PURE__ */ jsxs29(
        Select,
        {
          onValueChange: (value) => {
            table.setPageSize(Number(value));
          },
          value: `${table.getState().pagination.pageSize}`,
          children: [
            /* @__PURE__ */ jsx60(SelectTrigger, { className: css34({ w: "70px", h: "8", marginTop: "auto" }), children: /* @__PURE__ */ jsx60(SelectValue, { placeholder: table.getState().pagination.pageSize }) }),
            /* @__PURE__ */ jsx60(SelectContent, { side: "top", children: [10, 20, 30, 40, 50].map((pageSize) => /* @__PURE__ */ jsx60(SelectItem, { value: `${pageSize}`, children: pageSize }, pageSize)) })
          ]
        }
      )
    ]
  }
);
var TablePagination = ({
  table
}) => /* @__PURE__ */ jsxs29(
  Flex6,
  {
    css: {
      alignItems: "center",
      justifyContent: "space-between",
      p: "2"
    },
    children: [
      /* @__PURE__ */ jsx60(TablePaginationRPS, { table }),
      /* @__PURE__ */ jsx60(TablePaginationActions, { table })
    ]
  }
);

// src/Table/SimpleTable.tsx
import { jsx as jsx61, jsxs as jsxs30 } from "react/jsx-runtime";
var tableStyles = css35({
  "& > div.table-container": {
    bg: "base.white",
    color: "foreground.base",
    borderColor: "base.lightGray",
    p: "md",
    rounded: "md",
    borderWidth: "1px",
    cursor: "text",
    w: "full",
    position: "relative",
    "& thead": {
      bg: "base.lightherGray",
      "& th": {
        _firstOfType: {
          borderStartStartRadius: "md",
          borderEndStartRadius: "md"
        },
        _lastOfType: {
          borderStartEndRadius: "md",
          borderEndEndRadius: "md"
        }
      }
    },
    "& tr": {
      borderColor: "base.lightGray",
      cursor: "text",
      _lastOfType: {
        border: "none"
      },
      "& td": {
        py: "sm"
      }
    }
  }
});
var SimpleTable = ({
  columns,
  data,
  columnFilters,
  globalFilter,
  emptyComponent,
  loading = false,
  setColumnFilters,
  setGlobalFilter,
  paginationMode = "client-side",
  pageCount,
  pagination,
  onPaginationChange,
  renderSubComponent,
  getRowCanExpand,
  className
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: paginationMode === "client-side" ? getPaginationRowModel() : void 0,
    manualPagination: paginationMode === "server-side",
    ...paginationMode === "server-side" && { pageCount },
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    ...paginationMode === "server-side" && { onPaginationChange },
    getRowCanExpand,
    state: {
      columnFilters,
      globalFilter,
      ...paginationMode === "server-side" && { pagination }
    }
  });
  return /* @__PURE__ */ jsxs30(Flex7, { className: tableStyles, flexDirection: "column", children: [
    /* @__PURE__ */ jsx61("div", { className: "table-container", children: /* @__PURE__ */ jsx61(
      Table2,
      {
        className,
        emptyComponent,
        loading,
        renderSubComponent,
        table
      }
    ) }),
    paginationMode === "client-side" || paginationMode === "server-side" ? /* @__PURE__ */ jsx61(
      Flex7,
      {
        css: {
          alignItems: "center",
          justifyContent: "flex-end",
          mt: "md",
          gap: "md",
          pt: "0"
        },
        style: {
          paddingTop: "0",
          backgroundColor: "transparent"
        },
        children: /* @__PURE__ */ jsx61(Box10, { children: /* @__PURE__ */ jsx61(TablePagination, { table }) })
      }
    ) : null
  ] });
};

// src/Tabs/Tabs.tsx
import { css as css36, cx as cx26 } from "@doowii/design-system/css";

// ../../node_modules/@radix-ui/react-tabs/dist/index.mjs
import * as React64 from "react";

// ../../node_modules/@radix-ui/react-roving-focus/dist/index.mjs
import * as React62 from "react";
import { jsx as jsx62 } from "react/jsx-runtime";
var ENTRY_FOCUS = "rovingFocusGroup.onEntryFocus";
var EVENT_OPTIONS2 = { bubbles: false, cancelable: true };
var GROUP_NAME = "RovingFocusGroup";
var [Collection2, useCollection2, createCollectionScope2] = createCollection(GROUP_NAME);
var [createRovingFocusGroupContext, createRovingFocusGroupScope] = createContextScope2(
  GROUP_NAME,
  [createCollectionScope2]
);
var [RovingFocusProvider, useRovingFocusContext] = createRovingFocusGroupContext(GROUP_NAME);
var RovingFocusGroup = React62.forwardRef(
  (props, forwardedRef) => {
    return /* @__PURE__ */ jsx62(Collection2.Provider, { scope: props.__scopeRovingFocusGroup, children: /* @__PURE__ */ jsx62(Collection2.Slot, { scope: props.__scopeRovingFocusGroup, children: /* @__PURE__ */ jsx62(RovingFocusGroupImpl, { ...props, ref: forwardedRef }) }) });
  }
);
RovingFocusGroup.displayName = GROUP_NAME;
var RovingFocusGroupImpl = React62.forwardRef((props, forwardedRef) => {
  const {
    __scopeRovingFocusGroup,
    orientation,
    loop = false,
    dir,
    currentTabStopId: currentTabStopIdProp,
    defaultCurrentTabStopId,
    onCurrentTabStopIdChange,
    onEntryFocus,
    preventScrollOnEntryFocus = false,
    ...groupProps
  } = props;
  const ref = React62.useRef(null);
  const composedRefs = useComposedRefs(forwardedRef, ref);
  const direction = useDirection(dir);
  const [currentTabStopId = null, setCurrentTabStopId] = useControllableState({
    prop: currentTabStopIdProp,
    defaultProp: defaultCurrentTabStopId,
    onChange: onCurrentTabStopIdChange
  });
  const [isTabbingBackOut, setIsTabbingBackOut] = React62.useState(false);
  const handleEntryFocus = useCallbackRef(onEntryFocus);
  const getItems = useCollection2(__scopeRovingFocusGroup);
  const isClickFocusRef = React62.useRef(false);
  const [focusableItemsCount, setFocusableItemsCount] = React62.useState(0);
  React62.useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener(ENTRY_FOCUS, handleEntryFocus);
      return () => node.removeEventListener(ENTRY_FOCUS, handleEntryFocus);
    }
  }, [handleEntryFocus]);
  return /* @__PURE__ */ jsx62(
    RovingFocusProvider,
    {
      scope: __scopeRovingFocusGroup,
      orientation,
      dir: direction,
      loop,
      currentTabStopId,
      onItemFocus: React62.useCallback(
        (tabStopId) => setCurrentTabStopId(tabStopId),
        [setCurrentTabStopId]
      ),
      onItemShiftTab: React62.useCallback(() => setIsTabbingBackOut(true), []),
      onFocusableItemAdd: React62.useCallback(
        () => setFocusableItemsCount((prevCount) => prevCount + 1),
        []
      ),
      onFocusableItemRemove: React62.useCallback(
        () => setFocusableItemsCount((prevCount) => prevCount - 1),
        []
      ),
      children: /* @__PURE__ */ jsx62(
        Primitive.div,
        {
          tabIndex: isTabbingBackOut || focusableItemsCount === 0 ? -1 : 0,
          "data-orientation": orientation,
          ...groupProps,
          ref: composedRefs,
          style: { outline: "none", ...props.style },
          onMouseDown: composeEventHandlers2(props.onMouseDown, () => {
            isClickFocusRef.current = true;
          }),
          onFocus: composeEventHandlers2(props.onFocus, (event) => {
            const isKeyboardFocus = !isClickFocusRef.current;
            if (event.target === event.currentTarget && isKeyboardFocus && !isTabbingBackOut) {
              const entryFocusEvent = new CustomEvent(ENTRY_FOCUS, EVENT_OPTIONS2);
              event.currentTarget.dispatchEvent(entryFocusEvent);
              if (!entryFocusEvent.defaultPrevented) {
                const items = getItems().filter((item) => item.focusable);
                const activeItem = items.find((item) => item.active);
                const currentItem = items.find((item) => item.id === currentTabStopId);
                const candidateItems = [activeItem, currentItem, ...items].filter(
                  Boolean
                );
                const candidateNodes = candidateItems.map((item) => item.ref.current);
                focusFirst2(candidateNodes, preventScrollOnEntryFocus);
              }
            }
            isClickFocusRef.current = false;
          }),
          onBlur: composeEventHandlers2(props.onBlur, () => setIsTabbingBackOut(false))
        }
      )
    }
  );
});
var ITEM_NAME = "RovingFocusGroupItem";
var RovingFocusGroupItem = React62.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopeRovingFocusGroup,
      focusable = true,
      active = false,
      tabStopId,
      ...itemProps
    } = props;
    const autoId = useId5();
    const id = tabStopId || autoId;
    const context = useRovingFocusContext(ITEM_NAME, __scopeRovingFocusGroup);
    const isCurrentTabStop = context.currentTabStopId === id;
    const getItems = useCollection2(__scopeRovingFocusGroup);
    const { onFocusableItemAdd, onFocusableItemRemove } = context;
    React62.useEffect(() => {
      if (focusable) {
        onFocusableItemAdd();
        return () => onFocusableItemRemove();
      }
    }, [focusable, onFocusableItemAdd, onFocusableItemRemove]);
    return /* @__PURE__ */ jsx62(
      Collection2.ItemSlot,
      {
        scope: __scopeRovingFocusGroup,
        id,
        focusable,
        active,
        children: /* @__PURE__ */ jsx62(
          Primitive.span,
          {
            tabIndex: isCurrentTabStop ? 0 : -1,
            "data-orientation": context.orientation,
            ...itemProps,
            ref: forwardedRef,
            onMouseDown: composeEventHandlers2(props.onMouseDown, (event) => {
              if (!focusable) event.preventDefault();
              else context.onItemFocus(id);
            }),
            onFocus: composeEventHandlers2(props.onFocus, () => context.onItemFocus(id)),
            onKeyDown: composeEventHandlers2(props.onKeyDown, (event) => {
              if (event.key === "Tab" && event.shiftKey) {
                context.onItemShiftTab();
                return;
              }
              if (event.target !== event.currentTarget) return;
              const focusIntent = getFocusIntent(event, context.orientation, context.dir);
              if (focusIntent !== void 0) {
                if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) return;
                event.preventDefault();
                const items = getItems().filter((item) => item.focusable);
                let candidateNodes = items.map((item) => item.ref.current);
                if (focusIntent === "last") candidateNodes.reverse();
                else if (focusIntent === "prev" || focusIntent === "next") {
                  if (focusIntent === "prev") candidateNodes.reverse();
                  const currentIndex = candidateNodes.indexOf(event.currentTarget);
                  candidateNodes = context.loop ? wrapArray(candidateNodes, currentIndex + 1) : candidateNodes.slice(currentIndex + 1);
                }
                setTimeout(() => focusFirst2(candidateNodes));
              }
            })
          }
        )
      }
    );
  }
);
RovingFocusGroupItem.displayName = ITEM_NAME;
var MAP_KEY_TO_FOCUS_INTENT = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function getDirectionAwareKey(key, dir) {
  if (dir !== "rtl") return key;
  return key === "ArrowLeft" ? "ArrowRight" : key === "ArrowRight" ? "ArrowLeft" : key;
}
function getFocusIntent(event, orientation, dir) {
  const key = getDirectionAwareKey(event.key, dir);
  if (orientation === "vertical" && ["ArrowLeft", "ArrowRight"].includes(key)) return void 0;
  if (orientation === "horizontal" && ["ArrowUp", "ArrowDown"].includes(key)) return void 0;
  return MAP_KEY_TO_FOCUS_INTENT[key];
}
function focusFirst2(candidates, preventScroll = false) {
  const PREVIOUSLY_FOCUSED_ELEMENT = document.activeElement;
  for (const candidate of candidates) {
    if (candidate === PREVIOUSLY_FOCUSED_ELEMENT) return;
    candidate.focus({ preventScroll });
    if (document.activeElement !== PREVIOUSLY_FOCUSED_ELEMENT) return;
  }
}
function wrapArray(array, startIndex) {
  return array.map((_, index2) => array[(startIndex + index2) % array.length]);
}
var Root17 = RovingFocusGroup;
var Item5 = RovingFocusGroupItem;

// ../../node_modules/@radix-ui/react-presence/dist/index.mjs
import * as React211 from "react";
import * as React63 from "react";
function useStateMachine2(initialState, machine) {
  return React63.useReducer((state, event) => {
    const nextState = machine[state][event];
    return nextState ?? state;
  }, initialState);
}
var Presence2 = (props) => {
  const { present, children } = props;
  const presence = usePresence2(present);
  const child = typeof children === "function" ? children({ present: presence.isPresent }) : React211.Children.only(children);
  const ref = useComposedRefs(presence.ref, getElementRef5(child));
  const forceMount = typeof children === "function";
  return forceMount || presence.isPresent ? React211.cloneElement(child, { ref }) : null;
};
Presence2.displayName = "Presence";
function usePresence2(present) {
  const [node, setNode] = React211.useState();
  const stylesRef = React211.useRef({});
  const prevPresentRef = React211.useRef(present);
  const prevAnimationNameRef = React211.useRef("none");
  const initialState = present ? "mounted" : "unmounted";
  const [state, send] = useStateMachine2(initialState, {
    mounted: {
      UNMOUNT: "unmounted",
      ANIMATION_OUT: "unmountSuspended"
    },
    unmountSuspended: {
      MOUNT: "mounted",
      ANIMATION_END: "unmounted"
    },
    unmounted: {
      MOUNT: "mounted"
    }
  });
  React211.useEffect(() => {
    const currentAnimationName = getAnimationName2(stylesRef.current);
    prevAnimationNameRef.current = state === "mounted" ? currentAnimationName : "none";
  }, [state]);
  useLayoutEffect2(() => {
    const styles = stylesRef.current;
    const wasPresent = prevPresentRef.current;
    const hasPresentChanged = wasPresent !== present;
    if (hasPresentChanged) {
      const prevAnimationName = prevAnimationNameRef.current;
      const currentAnimationName = getAnimationName2(styles);
      if (present) {
        send("MOUNT");
      } else if (currentAnimationName === "none" || styles?.display === "none") {
        send("UNMOUNT");
      } else {
        const isAnimating = prevAnimationName !== currentAnimationName;
        if (wasPresent && isAnimating) {
          send("ANIMATION_OUT");
        } else {
          send("UNMOUNT");
        }
      }
      prevPresentRef.current = present;
    }
  }, [present, send]);
  useLayoutEffect2(() => {
    if (node) {
      let timeoutId;
      const ownerWindow = node.ownerDocument.defaultView ?? window;
      const handleAnimationEnd = (event) => {
        const currentAnimationName = getAnimationName2(stylesRef.current);
        const isCurrentAnimation = currentAnimationName.includes(event.animationName);
        if (event.target === node && isCurrentAnimation) {
          send("ANIMATION_END");
          if (!prevPresentRef.current) {
            const currentFillMode = node.style.animationFillMode;
            node.style.animationFillMode = "forwards";
            timeoutId = ownerWindow.setTimeout(() => {
              if (node.style.animationFillMode === "forwards") {
                node.style.animationFillMode = currentFillMode;
              }
            });
          }
        }
      };
      const handleAnimationStart = (event) => {
        if (event.target === node) {
          prevAnimationNameRef.current = getAnimationName2(stylesRef.current);
        }
      };
      node.addEventListener("animationstart", handleAnimationStart);
      node.addEventListener("animationcancel", handleAnimationEnd);
      node.addEventListener("animationend", handleAnimationEnd);
      return () => {
        ownerWindow.clearTimeout(timeoutId);
        node.removeEventListener("animationstart", handleAnimationStart);
        node.removeEventListener("animationcancel", handleAnimationEnd);
        node.removeEventListener("animationend", handleAnimationEnd);
      };
    } else {
      send("ANIMATION_END");
    }
  }, [node, send]);
  return {
    isPresent: ["mounted", "unmountSuspended"].includes(state),
    ref: React211.useCallback((node2) => {
      if (node2) stylesRef.current = getComputedStyle(node2);
      setNode(node2);
    }, [])
  };
}
function getAnimationName2(styles) {
  return styles?.animationName || "none";
}
function getElementRef5(element) {
  let getter = Object.getOwnPropertyDescriptor(element.props, "ref")?.get;
  let mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.ref;
  }
  getter = Object.getOwnPropertyDescriptor(element, "ref")?.get;
  mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }
  return element.props.ref || element.ref;
}

// ../../node_modules/@radix-ui/react-tabs/dist/index.mjs
import { jsx as jsx63 } from "react/jsx-runtime";
var TABS_NAME = "Tabs";
var [createTabsContext, createTabsScope] = createContextScope2(TABS_NAME, [
  createRovingFocusGroupScope
]);
var useRovingFocusGroupScope = createRovingFocusGroupScope();
var [TabsProvider, useTabsContext] = createTabsContext(TABS_NAME);
var Tabs = React64.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopeTabs,
      value: valueProp,
      onValueChange,
      defaultValue,
      orientation = "horizontal",
      dir,
      activationMode = "automatic",
      ...tabsProps
    } = props;
    const direction = useDirection(dir);
    const [value, setValue] = useControllableState({
      prop: valueProp,
      onChange: onValueChange,
      defaultProp: defaultValue
    });
    return /* @__PURE__ */ jsx63(
      TabsProvider,
      {
        scope: __scopeTabs,
        baseId: useId5(),
        value,
        onValueChange: setValue,
        orientation,
        dir: direction,
        activationMode,
        children: /* @__PURE__ */ jsx63(
          Primitive.div,
          {
            dir: direction,
            "data-orientation": orientation,
            ...tabsProps,
            ref: forwardedRef
          }
        )
      }
    );
  }
);
Tabs.displayName = TABS_NAME;
var TAB_LIST_NAME = "TabsList";
var TabsList = React64.forwardRef(
  (props, forwardedRef) => {
    const { __scopeTabs, loop = true, ...listProps } = props;
    const context = useTabsContext(TAB_LIST_NAME, __scopeTabs);
    const rovingFocusGroupScope = useRovingFocusGroupScope(__scopeTabs);
    return /* @__PURE__ */ jsx63(
      Root17,
      {
        asChild: true,
        ...rovingFocusGroupScope,
        orientation: context.orientation,
        dir: context.dir,
        loop,
        children: /* @__PURE__ */ jsx63(
          Primitive.div,
          {
            role: "tablist",
            "aria-orientation": context.orientation,
            ...listProps,
            ref: forwardedRef
          }
        )
      }
    );
  }
);
TabsList.displayName = TAB_LIST_NAME;
var TRIGGER_NAME2 = "TabsTrigger";
var TabsTrigger = React64.forwardRef(
  (props, forwardedRef) => {
    const { __scopeTabs, value, disabled = false, ...triggerProps } = props;
    const context = useTabsContext(TRIGGER_NAME2, __scopeTabs);
    const rovingFocusGroupScope = useRovingFocusGroupScope(__scopeTabs);
    const triggerId = makeTriggerId(context.baseId, value);
    const contentId = makeContentId(context.baseId, value);
    const isSelected = value === context.value;
    return /* @__PURE__ */ jsx63(
      Item5,
      {
        asChild: true,
        ...rovingFocusGroupScope,
        focusable: !disabled,
        active: isSelected,
        children: /* @__PURE__ */ jsx63(
          Primitive.button,
          {
            type: "button",
            role: "tab",
            "aria-selected": isSelected,
            "aria-controls": contentId,
            "data-state": isSelected ? "active" : "inactive",
            "data-disabled": disabled ? "" : void 0,
            disabled,
            id: triggerId,
            ...triggerProps,
            ref: forwardedRef,
            onMouseDown: composeEventHandlers2(props.onMouseDown, (event) => {
              if (!disabled && event.button === 0 && event.ctrlKey === false) {
                context.onValueChange(value);
              } else {
                event.preventDefault();
              }
            }),
            onKeyDown: composeEventHandlers2(props.onKeyDown, (event) => {
              if ([" ", "Enter"].includes(event.key)) context.onValueChange(value);
            }),
            onFocus: composeEventHandlers2(props.onFocus, () => {
              const isAutomaticActivation = context.activationMode !== "manual";
              if (!isSelected && !disabled && isAutomaticActivation) {
                context.onValueChange(value);
              }
            })
          }
        )
      }
    );
  }
);
TabsTrigger.displayName = TRIGGER_NAME2;
var CONTENT_NAME3 = "TabsContent";
var TabsContent = React64.forwardRef(
  (props, forwardedRef) => {
    const { __scopeTabs, value, forceMount, children, ...contentProps } = props;
    const context = useTabsContext(CONTENT_NAME3, __scopeTabs);
    const triggerId = makeTriggerId(context.baseId, value);
    const contentId = makeContentId(context.baseId, value);
    const isSelected = value === context.value;
    const isMountAnimationPreventedRef = React64.useRef(isSelected);
    React64.useEffect(() => {
      const rAF = requestAnimationFrame(() => isMountAnimationPreventedRef.current = false);
      return () => cancelAnimationFrame(rAF);
    }, []);
    return /* @__PURE__ */ jsx63(Presence2, { present: forceMount || isSelected, children: ({ present }) => /* @__PURE__ */ jsx63(
      Primitive.div,
      {
        "data-state": isSelected ? "active" : "inactive",
        "data-orientation": context.orientation,
        role: "tabpanel",
        "aria-labelledby": triggerId,
        hidden: !present,
        id: contentId,
        tabIndex: 0,
        ...contentProps,
        ref: forwardedRef,
        style: {
          ...props.style,
          animationDuration: isMountAnimationPreventedRef.current ? "0s" : void 0
        },
        children: present && children
      }
    ) });
  }
);
TabsContent.displayName = CONTENT_NAME3;
function makeTriggerId(baseId, value) {
  return `${baseId}-trigger-${value}`;
}
function makeContentId(baseId, value) {
  return `${baseId}-content-${value}`;
}
var Root24 = Tabs;
var List = TabsList;
var Trigger9 = TabsTrigger;
var Content9 = TabsContent;

// src/Tabs/Tabs.tsx
import * as React65 from "react";
import { jsx as jsx64, jsxs as jsxs31 } from "react/jsx-runtime";
var Tabs2 = React65.forwardRef(({ className, variant = "primary", items, children, ...props }, ref) => {
  const variants = {
    primary: {
      list: css36({
        borderBottomWidth: "1px",
        borderColor: "border.base",
        display: "flex",
        gap: "md"
      }),
      trigger: css36({
        px: "sm",
        py: "md",
        color: "foreground.disabled",
        cursor: "pointer",
        _hover: { color: "foreground.base" },
        _selected: {
          position: "relative",
          color: "foreground.base",
          "&::after": {
            content: '""',
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "-1px",
            height: "2px",
            bg: "border.secondary",
            borderRadius: 0
          }
        },
        _focus: {
          outline: "none",
          ring: "2px",
          ringColor: "border.secondary",
          rounded: "xs"
        },
        _disabled: {
          opacity: 0.5,
          cursor: "not-allowed",
          _hover: { color: "foreground.disabled" }
        }
      })
    },
    secondary: {
      list: css36({
        display: "flex",
        gap: "xs",
        p: "xs",
        bg: "background.bar",
        rounded: "lg",
        width: "fit-content"
      }),
      trigger: css36({
        px: "md",
        py: "sm",
        color: "foreground.disabled",
        cursor: "pointer",
        rounded: "md",
        _hover: { color: "foreground.base" },
        _selected: {
          color: "foreground.base",
          bg: "background.base",
          shadow: "regular"
        },
        _focus: {
          outline: "none",
          ring: "2px",
          ringColor: "border.secondary"
        },
        _disabled: {
          opacity: 0.5,
          cursor: "not-allowed",
          _hover: { color: "foreground.disabled" }
        }
      })
    }
  };
  return /* @__PURE__ */ jsxs31(
    Root24,
    {
      className: cx26(css36({ width: "full" }), className),
      ref,
      ...props,
      children: [
        /* @__PURE__ */ jsx64(List, { className: variants[variant].list, children: items.map((item) => /* @__PURE__ */ jsx64(
          Trigger9,
          {
            className: cx26(
              css36({
                textStyle: "label_14"
              }),
              variants[variant].trigger
            ),
            disabled: item.disabled,
            value: item.value,
            children: item.label
          },
          item.value
        )) }),
        children
      ]
    }
  );
});
var TabContent = React65.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx64(
  Content9,
  {
    className: cx26(
      css36({
        _focus: { outline: "none" }
      }),
      className
    ),
    ref,
    ...props
  }
));
Tabs2.displayName = Root24.displayName;
TabContent.displayName = Content9.displayName;

// src/Textarea/Textarea.tsx
import { css as css37, cx as cx27 } from "@doowii/design-system/css";
import { Flex as Flex8 } from "@doowii/design-system/jsx";
import { useId as useId7 } from "react";
import { jsx as jsx65, jsxs as jsxs32 } from "react/jsx-runtime";
var Textarea = ({ label, id, className, css: cssProp, ...props }) => {
  const generatedId = useId7();
  const textareaId = id ?? generatedId;
  return /* @__PURE__ */ jsxs32(Flex8, { direction: "column", gap: "sm", children: [
    label && label.length > 0 ? /* @__PURE__ */ jsx65(Label, { htmlFor: textareaId, level: 1, children: label }) : null,
    /* @__PURE__ */ jsx65(
      "textarea",
      {
        ...props,
        className: cx27(
          css37({
            p: "md",
            borderRadius: "md",
            borderWidth: "2px",
            borderColor: "border.base",
            outline: "none",
            resize: "none",
            color: "foreground.base"
          }),
          className,
          css37(cssProp)
        ),
        id: textareaId
      }
    )
  ] });
};

// src/Toast/Toast.tsx
import {
  css as css38,
  cva as cva13,
  cx as cx28
} from "@doowii/design-system/css";
import { token } from "@doowii/design-system/tokens";
import {
  faCircleCheck as faCircleCheck2,
  faCircleExclamation as faCircleExclamation2,
  faCircleInfo as faCircleInfo2,
  faClose
} from "@fortawesome/pro-solid-svg-icons";
import {
  FontAwesomeIcon as FontAwesomeIcon20
} from "@fortawesome/react-fontawesome";
import * as ToastPrimitives from "@radix-ui/react-toast";
import * as React66 from "react";
import { jsx as jsx66 } from "react/jsx-runtime";
var ToastProvider = ToastPrimitives.Provider;
var ToastViewport = React66.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx66(ToastPrimitives.Viewport, { className, ref, ...props }));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
var toastVariants = cva13({
  base: {
    pointerEvents: "auto",
    pos: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    m: "2",
    zIndex: "60",
    overflow: "hidden",
    rounded: "md",
    p: "md",
    pr: "ld",
    shadow: "lg",
    transitionProperty: "all",
    transitionTimingFunction: "all",
    transitionDuration: "all"
  },
  variants: {
    position: {
      "top-left": { top: 0, left: "0" },
      "top-right": { top: 0, right: "0" },
      "top-center": { top: 0, left: "50%", transform: "translateX(-50%)" },
      "bottom-left": { left: "0", bottom: "0" },
      "bottom-right": { right: "0", bottom: "0" },
      "bottom-center": {
        left: "50%",
        bottom: "0",
        transform: "translateX(-50%)"
      }
    },
    status: {
      info: {
        bgColor: "toast.info.background",
        color: "toast.info.foreground"
      },
      success: {
        bgColor: "toast.success.background",
        color: "toast.success.foreground"
      },
      warning: {
        bgColor: "toast.warning.background",
        color: "toast.warning.foreground"
      },
      error: {
        bgColor: "toast.error.background",
        color: "toast.error.foreground"
      }
    }
  },
  defaultVariants: { status: "info", position: "top-center" }
});
var Toast = React66.forwardRef(({ className, status, position, ...props }, ref) => /* @__PURE__ */ jsx66(
  ToastPrimitives.Root,
  {
    className: cx28(toastVariants({ status, position }), className),
    ref,
    ...props
  }
));
Toast.displayName = ToastPrimitives.Root.displayName;
var ToastAction = React66.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx66(
  ToastPrimitives.Action,
  {
    className: cx28(
      css38({
        display: "inline-flex",
        h: "8",
        flexShrink: "0",
        alignItems: "center",
        justifyContent: "center",
        rounded: "md",
        borderWidth: "1px",
        bgColor: "transparent",
        pl: "3",
        pr: "3",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "medium",
        transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
        transitionTimingFunction: "colors",
        transitionDuration: "colors",
        _focus: { ring: "none", ringOffset: "none", shadow: "1" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props
  }
));
ToastAction.displayName = ToastPrimitives.Action.displayName;
var ToastClose = React66.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx66(
  ToastPrimitives.Close,
  {
    className: cx28(
      css38({
        pos: "absolute",
        right: "1",
        top: "1",
        rounded: "md",
        p: "1",
        opacity: "0",
        transitionProperty: "opacity",
        transitionTimingFunction: "opacity",
        transitionDuration: "opacity",
        _focus: { opacity: "1", ring: "none", ringOffset: "none", shadow: "1" },
        _hover: { opacity: "1" }
      }),
      className
    ),
    ref,
    "toast-close": "",
    ...props,
    children: /* @__PURE__ */ jsx66(FontAwesomeIcon20, { icon: faClose })
  }
));
ToastClose.displayName = ToastPrimitives.Close.displayName;
var ToastTitle = React66.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx66(
  ToastPrimitives.Title,
  {
    className: cx28(
      css38({
        textStyle: "label_16",
        lineHeight: "sm",
        fontWeight: "400"
      }),
      className
    ),
    ref,
    ...props
  }
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;
var ToastDescription = React66.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx66(
  ToastPrimitives.Description,
  {
    className: cx28(
      css38({ textStyle: "paragraph_14", opacity: "0.9" }),
      className
    ),
    ref,
    ...props
  }
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;
var statusIconMap = {
  info: faCircleInfo2,
  success: faCircleCheck2,
  warning: faCircleExclamation2,
  error: faCircleExclamation2
};
var iconColorMap = {
  info: token("colors.toast.info.icon"),
  success: token("colors.toast.success.icon"),
  warning: token("colors.toast.warning.icon"),
  error: token("colors.toast.error.icon")
};
var ToastIcon = ({
  status = "info",
  icon,
  loading
}) => {
  if (loading) {
    return /* @__PURE__ */ jsx66(Spinner, {});
  }
  return /* @__PURE__ */ jsx66(
    FontAwesomeIcon20,
    {
      color: iconColorMap[status],
      icon: icon ?? statusIconMap[status]
    }
  );
};

// src/Toast/Toaster.tsx
import { css as css39 } from "@doowii/design-system/css";
import { Box as Box11 } from "@doowii/design-system/jsx";

// src/Toast/useToast.tsx
import * as React67 from "react";
var TOAST_LIMIT = 1;
var TOAST_REMOVE_DELAY = 1e6;
var count3 = 0;
var genId = () => {
  count3 = (count3 + 1) % Number.MAX_SAFE_INTEGER;
  return count3.toString();
};
var toastTimeouts = /* @__PURE__ */ new Map();
var addToRemoveQueue = (toastId) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: "REMOVE_TOAST",
      toastId
    });
  }, TOAST_REMOVE_DELAY);
  toastTimeouts.set(toastId, timeout);
};
var reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT)
      };
    case "UPDATE_TOAST":
      return {
        ...state,
        toasts: state.toasts.map(
          (t) => t.id === action.toast.id ? { ...t, ...action.toast } : t
        )
      };
    case "DISMISS_TOAST": {
      const { toastId } = action;
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((toast2) => {
          addToRemoveQueue(toast2.id);
        });
      }
      return {
        ...state,
        toasts: state.toasts.map(
          (t) => t.id === toastId || toastId === void 0 ? {
            ...t,
            open: false
          } : t
        )
      };
    }
    case "REMOVE_TOAST":
      if (action.toastId === void 0) {
        return {
          ...state,
          toasts: []
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId)
      };
  }
};
var memoryState = { toasts: [] };
var listeners = [];
var dispatch = (action) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
};
var toast = ({ ...props }) => {
  const id = genId();
  const update = (props2) => dispatch({
    type: "UPDATE_TOAST",
    toast: { ...props2, id }
  });
  const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id });
  dispatch({
    type: "ADD_TOAST",
    toast: {
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) {
          dismiss();
        }
      }
    }
  });
  return {
    id,
    dismiss,
    update
  };
};
var useToast = () => {
  const [state, setState] = React67.useState(memoryState);
  React67.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index2 = listeners.indexOf(setState);
      if (index2 > -1) {
        listeners.splice(index2, 1);
      }
    };
  }, [state]);
  return {
    ...state,
    toast,
    dismiss: (toastId) => dispatch({ type: "DISMISS_TOAST", toastId })
  };
};

// src/Toast/Toaster.tsx
import { jsx as jsx67, jsxs as jsxs33 } from "react/jsx-runtime";
var Toaster = () => {
  const { toasts } = useToast();
  return /* @__PURE__ */ jsxs33(ToastProvider, { children: [
    toasts.map(({ id, title, description, action, ...props }) => /* @__PURE__ */ jsxs33(Toast, { ...props, className: css39({ gap: "md" }), children: [
      /* @__PURE__ */ jsx67(
        ToastIcon,
        {
          icon: props.icon,
          loading: props.loading,
          status: props.status
        }
      ),
      /* @__PURE__ */ jsxs33(Box11, { className: "grid gap-1", children: [
        title ? /* @__PURE__ */ jsx67(ToastTitle, { children: title }) : null,
        description ? /* @__PURE__ */ jsx67(ToastDescription, { children: description }) : null
      ] }),
      action,
      /* @__PURE__ */ jsx67(ToastClose, {})
    ] }, id)),
    /* @__PURE__ */ jsx67(ToastViewport, {})
  ] });
};

// src/Toggle/Toggle.tsx
import { css as css40, cx as cx29 } from "@doowii/design-system/css";
import { Box as Box12, Flex as Flex9 } from "@doowii/design-system/jsx";
import { FontAwesomeIcon as FontAwesomeIcon21 } from "@fortawesome/react-fontawesome";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import * as React68 from "react";
import { jsx as jsx68, jsxs as jsxs34 } from "react/jsx-runtime";
var Toggle = React68.forwardRef(
  ({
    className,
    variant = "primary",
    size: size4 = "md",
    icon,
    label,
    secondaryLabel,
    ...props
  }, ref) => {
    const sizes = {
      xxs: { button: "h-5 px-1.5", icon: "w-1.5 h-1.5", text: "paragraph_12" },
      xs: { button: "h-6 px-2", icon: "w-2 h-2", text: "paragraph_12" },
      sm: { button: "h-8 px-3", icon: "w-3 h-3", text: "paragraph_14" },
      md: { button: "h-10 px-4", icon: "w-4 h-4", text: "paragraph_16" },
      lg: { button: "h-12 px-5", icon: "w-5 h-5", text: "paragraph_18" }
    };
    const variants = {
      primary: css40({
        bg: "background.base",
        color: "foreground.disabled",
        borderWidth: "1px",
        borderColor: "border.base",
        _hover: { bg: "background.hover.tertiary" },
        _focus: { ring: "2px", ringColor: "border.secondary" },
        _disabled: {
          bg: "background.disabled",
          borderColor: "border.disabled",
          cursor: "not-allowed"
        },
        _pressed: {
          bg: "background.primary",
          color: "foreground.primary",
          borderColor: "border.secondary",
          _hover: { bg: "base.darkBlue" }
        }
      }),
      secondary: css40({
        bg: "background.secondary",
        color: "foreground.disabled",
        _hover: { bg: "background.hover.secondary" },
        _focus: { ring: "2px", ringColor: "border.secondary" },
        _disabled: { opacity: 0.5, cursor: "not-allowed" },
        _pressed: {
          bg: "background.bar",
          color: "foreground.active",
          _hover: { bg: "base.blueBrandTertiary" }
        }
      }),
      expanded: css40({
        width: "100%",
        bg: "background.base",
        p: "md",
        rounded: "xl",
        shadow: "regular",
        _hover: { shadow: "hover" },
        _focus: { ring: "2px", ringColor: "border.secondary" },
        _disabled: { opacity: 0.5, cursor: "not-allowed" },
        _pressed: {
          bg: "background.bar",
          _hover: { bg: "base.blueBrandTertiary" }
        }
      })
    };
    const content = /* @__PURE__ */ jsxs34(Flex9, { align: "center", gap: "sm", children: [
      icon ? /* @__PURE__ */ jsx68(FontAwesomeIcon21, { className: sizes[size4].icon, icon }) : null,
      label ? /* @__PURE__ */ jsx68(Box12, { css: { textStyle: sizes[size4].text }, children: label }) : null
    ] });
    if (variant === "expanded" && secondaryLabel) {
      return /* @__PURE__ */ jsx68(
        TogglePrimitive.Root,
        {
          className: cx29(variants[variant], className),
          ref,
          ...props,
          children: /* @__PURE__ */ jsxs34(Flex9, { direction: "column", gap: "xs", children: [
            content,
            /* @__PURE__ */ jsx68(
              Box12,
              {
                css: {
                  textStyle: "paragraph_14",
                  color: "foreground.disabled"
                },
                children: secondaryLabel
              }
            )
          ] })
        }
      );
    }
    return /* @__PURE__ */ jsx68(
      TogglePrimitive.Root,
      {
        className: cx29(
          css40({
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            rounded: "md",
            padding: "sm",
            cursor: "pointer",
            _focusVisible: {
              outline: "none"
            }
          }),
          variants[variant],
          sizes[size4].button,
          className
        ),
        ref,
        ...props,
        children: content
      }
    );
  }
);
Toggle.displayName = TogglePrimitive.Root.displayName;

// src/ToggleGroup/ToggleGroup.tsx
import { css as css41, cx as cx30 } from "@doowii/design-system/css";
import { Flex as Flex10 } from "@doowii/design-system/jsx";
import * as RadixToggleGroup from "@radix-ui/react-toggle-group";
import { jsx as jsx69 } from "react/jsx-runtime";
var toggleGroupStyles = css41({
  display: "inline-flex",
  alignItems: "center",
  backgroundColor: "transparent",
  gap: "md",
  position: "relative",
  "&[data-orientation='vertical']": {
    flexDirection: "column"
  }
});
var toggleGroupItemStyles = css41({
  padding: "sm",
  paddingRight: "md",
  paddingLeft: "md",
  textStyle: "paragraph_14",
  color: "foreground.secondary",
  cursor: "pointer",
  position: "relative",
  backgroundColor: "transparent",
  border: "none",
  rounded: "lg",
  transition: "all 0.2s ease",
  "&[data-state='on']": {
    color: "foreground.secondary",
    backgroundColor: "background.bar"
  },
  "&:hover:not([data-state='on']):not([data-disabled])": {
    color: "foreground.secondary",
    backgroundColor: "background.bar"
  },
  "&[data-disabled]": {
    opacity: 0.5,
    cursor: "not-allowed",
    "&:hover": {
      color: "foreground.disabled"
    }
  },
  "&:focus": {
    outline: "none"
  },
  _focusVisible: {
    ring: "2px",
    ringColor: "border.secondary",
    rounded: "lg"
  }
});
var ToggleGroup = ({
  options,
  value,
  onValueChange,
  orientation = "horizontal",
  ariaLabel
}) => /* @__PURE__ */ jsx69(
  RadixToggleGroup.Root,
  {
    "aria-label": ariaLabel,
    className: cx30(toggleGroupStyles),
    onValueChange,
    orientation,
    type: "single",
    value,
    children: options.map(({ label, value: optionValue, disabled }) => /* @__PURE__ */ jsx69(
      RadixToggleGroup.Item,
      {
        "aria-label": label,
        className: cx30(toggleGroupItemStyles),
        disabled,
        value: optionValue,
        children: /* @__PURE__ */ jsx69(Flex10, { align: "center", children: label })
      },
      optionValue
    ))
  }
);
export {
  Accordion,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Avatar,
  AvatarFallback,
  AvatarImage,
  BackgroundBox,
  Badge,
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Callout,
  Card,
  Checkbox,
  CircularCounter,
  CircularIcon,
  ConfirmationDialog,
  Default,
  Dialog,
  DialogActionButtons,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  FeedbackButton,
  FollowUpCard,
  Heading,
  InputField,
  Label,
  LoadingLogo,
  Logo,
  LogoD,
  LogoDoowii,
  LogoLoader,
  MainSearchBar,
  MultiSelect,
  NavBar,
  NavBarItem,
  PageLayout,
  Popover2 as Popover,
  PopoverAnchor2 as PopoverAnchor,
  PopoverArrow2 as PopoverArrow,
  PopoverClose2 as PopoverClose,
  PopoverContent2 as PopoverContent,
  PopoverTrigger2 as PopoverTrigger,
  Progress,
  RangeSlider,
  RequestBar,
  RequestBarContent,
  RequestBarItem,
  RequestBarTrigger,
  SearchInput,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  Separator,
  SimpleTable,
  Skeleton,
  Slider2 as Slider,
  Spinner,
  SuggestionCard,
  Switch,
  TabContent,
  Table2 as Table,
  TableBody2 as TableBody,
  TableHeader2 as TableHeader,
  TablePagination,
  TablePaginationActions,
  TablePaginationRPS,
  Tabs2 as Tabs,
  Text,
  Textarea,
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  Toaster,
  Toggle,
  ToggleGroup,
  Tooltip,
  WithIcon,
  buttonRecipe,
  cardRecipe,
  reducer,
  toast,
  useToast
};
