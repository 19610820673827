import { ChartConfig } from "@doowii-types/chart";
import { Box } from "@styled-system/jsx";
import React, { useMemo } from "react";

import ChartTypeOptions from "./ChartTypeOptions";

interface ChartOptionsFormProps {
  chartConfig: ChartConfig;
  updateConfigState: (config: ChartConfig) => void;
}

const ChartOptionsForm: React.FC<ChartOptionsFormProps> = ({ chartConfig, updateConfigState }) => {
  // Get the visualization type from chartConfig
  const chartType = useMemo(() => chartConfig?.suggestion, [chartConfig]);

  return (
    <Box>
      <ChartTypeOptions
        chartConfig={chartConfig}
        chartType={chartType}
        updateConfigState={updateConfigState}
      />
    </Box>
  );
};

export { ChartOptionsForm };
