import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/core/macro";

export enum QuestionTypeEnum {
  USER = "user",
  REFINEMENT = "refinement",
  REGENERATE = "regenerate",
  SUGGESTION = "suggestion",
}

export const sequalizerErrorMessagesMap = {
  inappropriate_question: msg`Sorry, it looks like the question you've asked may not align with our guidelines or it's something we're not equipped to handle. Could you try asking something else? We're here to help!`,
  sql_generation: msg`Sorry, we're experiencing a hiccup while generating the information you requested. We're on it and will try to resolve this as quickly as possible. In the meantime, feel free to ask something else or give it another try shortly!`,
  agent_failure: msg`Sorry, something didn't go as planned on our end. We're looking into it right now. While we sort this out, you might want to check back in a bit or try asking something else. Your patience is greatly appreciated!`,
  server_error: msg`Sorry, looks like there is an issue. We're looking into it right now. In the meantime, feel free to ask something else or give it another try shortly!`,
};

export type SequalizerErrorKey = keyof typeof sequalizerErrorMessagesMap;

export const NO_SQL_MESSAGE = msg`We did not attempt to retrieve data because your question is either too ambiguous or requests data that does not exist in the dataset. Please refine your prompt to be more specific to your dataset!`;

export const DefaultError = {
  name: "default_error",
  message: msg`Sorry, it looks like there is an error! We are working on fixing it. Please give it another try in a few moments.`,
};

export interface ErrorType {
  name: string;
  message: string | MessageDescriptor;
}
