/**
 * Pie chart transformation utilities
 */
import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { Aggregations } from "@services/api/generated/webserver/models/aggregations";
import { ColumnDataType } from "@services/api/generated/webserver/models/columnDataType";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";
import { ChartConfig } from "@utils/chartTransformations";
import * as Highcharts from "highcharts";
import { PointOptionsObject } from "highcharts";

import {
  aggregateValues,
  getColumnValue,
  getTopBreakdownValues,
  groupDataByColumn,
  MAX_BREAKDOWN_VALUES,
} from "./commonTransformations";

export interface PieChartData {
  series: Highcharts.SeriesPieOptions[];
  [key: string]: unknown;
}

/**
 * Transforms raw data into a format suitable for pie charts
 * @param data Raw data from API or test data
 * @param chartConfig Chart configuration containing columns,
 * column_types, column_aggregations, and column_grouping
 * @returns Transformed data ready for chart rendering
 */
const transformForPieChart = (
  data: RetrieveAllResponse,
  chartConfig: ChartConfig
): PieChartData => {
  // Ensure data has the expected structure
  if (!data || !Array.isArray(data.rows) || !Array.isArray(data.column_names)) {
    console.error("Invalid data format for pie chart transformation", data);
    return { series: [] };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { rows, column_names } = data;
  const columnNames = column_names.map((name) => name.toLowerCase());

  // Get the selected columns from the chart config
  const selectedColumns = chartConfig.columns;

  // Get all categorical and numerical columns, filtered by selectedColumns
  const categoricalColumns = Object.entries(chartConfig.column_types || {})
    .filter(([_, type]) => type === ColumnDataType.categorical || type === ColumnDataType.temporal)
    .map(([col]) => col)
    .filter((col) => selectedColumns.includes(col));

  const numericalColumns = Object.entries(chartConfig.column_types || {})
    .filter(([_, type]) => type === ColumnDataType.numerical)
    .map(([col]) => col)
    .filter((col) => selectedColumns.includes(col));

  // Log warning if we don't have proper column types
  if (
    (categoricalColumns.length === 0 || numericalColumns.length === 0) &&
    chartConfig.columns.length >= 2
  ) {
    console.warn("Pie chart missing explicit column types. Using column positions as fallback.", {
      chartConfig,
    });
  }

  // Use the first categorical column as the category axis
  const categoryColumn =
    categoricalColumns.length > 0 ? categoricalColumns[0] : chartConfig.columns[0];

  // Use the first numerical column for values
  const valueColumn = numericalColumns.length > 0 ? numericalColumns[0] : chartConfig.columns[1];

  // For pie charts, use getTopBreakdownValues to limit categories
  // This is more efficient than sorting all data points and then slicing
  const { values: topCategories } = getTopBreakdownValues(rows, categoryColumn, columnNames, 10);

  // Group data by category column
  const groupedData = groupDataByColumn(rows, categoryColumn, columnNames);

  // Create data points for the pie chart - top categories first
  const topPieData: PointOptionsObject[] = topCategories.map((category) => {
    const categoryRows = groupedData[category];
    if (!categoryRows) {
      return { name: category, y: 0 };
    }

    // Extract values for this category and numerical column
    const values = categoryRows.map((row) => {
      const value = getColumnValue(row, valueColumn, columnNames);
      return typeof value === "number" && !isNaN(value) ? value : 0;
    });

    // Apply aggregation if specified
    const aggregation = chartConfig.column_aggregations?.[valueColumn];
    const aggregatedValue = aggregateValues(values, aggregation as Aggregations);

    return {
      name: category,
      y: aggregatedValue,
    };
  });

  // Now handle the "Other" category for values not in the top N
  let otherValue = 0;

  Object.entries(groupedData).forEach(([category, categoryRows]) => {
    // Skip categories already in topCategories
    if (topCategories.includes(category)) {
      return;
    }

    // Extract values for this category and numerical column
    const values = categoryRows.map((row) => {
      const value = getColumnValue(row, valueColumn, columnNames);
      return typeof value === "number" && !isNaN(value) ? value : 0;
    });

    // Apply aggregation if specified
    const aggregation = chartConfig.column_aggregations?.[valueColumn];
    const aggregatedValue = aggregateValues(values, aggregation as Aggregations);

    // Add to other value
    otherValue += aggregatedValue;
  });

  // Create the final data array, including "Other" if it has a value
  const pieData = [...topPieData];
  if (otherValue > 0) {
    pieData.push({
      name: "Other",
      y: otherValue,
    });
  }

  // Create the series
  const series: Highcharts.SeriesPieOptions[] = [
    {
      name: valueColumn,
      data: pieData,
      type: DataVisualizationType.PIE.toLowerCase() as Highcharts.SeriesPieOptions["type"],
    },
  ];

  return {
    series,
  };
};

export { transformForPieChart };
