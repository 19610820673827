import { PageHeader } from "@components/PageHeader";
import { CreatePinboardDialog } from "@components/PinboardCard";
import PinboardCardContainer from "@components/PinboardCard/components/PinboardCardContainer";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import { UserRoles } from "@doowii-types/auth";
import { faBars, faGrid2, faPlus, faRectangleHistory } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { Button, PageLayout, SearchInput, Tooltip } from "doowii-ui";
import React, { useEffect, useState } from "react";

export type PinboardsView = "grid" | "list";

interface ViewOptionsProps {
  view: PinboardsView;
  setView: React.Dispatch<React.SetStateAction<PinboardsView>>;
}
const ViewOptions: React.FC<ViewOptionsProps> = ({ view, setView }) => {
  const { t } = useLingui();

  return (
    <Flex css={{ gap: "md" }}>
      <Tooltip content={t`List view`}>
        <Button
          aria-label={t`Use list view`}
          className={css({ bg: view === "list" ? "base.blueBrandTertiary!" : "" })}
          iconOnly={faBars}
          onClick={() => setView("list")}
          variant="dropdown"
        />
      </Tooltip>
      <Tooltip content={t`Grid view`}>
        <Button
          aria-label={t`Use grid view`}
          className={css({ bg: view === "grid" ? "base.blueBrandTertiary!" : "" })}
          iconOnly={faGrid2}
          onClick={() => setView("grid")}
          variant="dropdown"
        />
      </Tooltip>
    </Flex>
  );
};

const Pinboards = () => {
  const { t } = useLingui();
  const { boards } = usePinboard();
  const { newChat } = useChatData();
  const { userDocument } = useAuth();
  const [isCreatePinboardDialogOpen, setIsCreatePinboardDialogOpen] = useState(false);
  const [view, setView] = useState<PinboardsView>("grid");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [triggerId, setTriggerId] = useState("");

  const handleCreatePinboard = (id: string) => {
    setTriggerId(id);
    setIsCreatePinboardDialogOpen(true);
  };

  useEffect(() => {
    newChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader description={t`Pinboards page`} title={t`Doowii - Pinboards`} />
      <PageLayout
        action={
          <Flex css={{ gap: "md" }}>
            <SearchInput
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t`Search pinboards`}
              value={searchQuery}
              variant="standard"
            />

            <ViewOptions setView={setView} view={view} />

            {userDocument?.role === UserRoles.ADMIN ? (
              <Button
                aria-label={t`Add new Pinboard`}
                iconLeft={faPlus}
                id="add-pinboard"
                onClick={(e) => handleCreatePinboard(e.currentTarget.id)}
              >
                <Trans>Add</Trans>
              </Button>
            ) : null}
          </Flex>
        }
        emptyProps={{
          title: t`It’s looking a bit empty here`,
          description: t`You can create new pinboards and save your chats to them`,
          buttonText: t`Create pinboard`,
          icon: faRectangleHistory,
          onClick: (e) => handleCreatePinboard(e.currentTarget.id),
        }}
        isEmpty={boards.size < 1}
        title={t`Pinboards`}
      >
        <PinboardCardContainer searchQuery={searchQuery} view={view} />
      </PageLayout>
      <CreatePinboardDialog
        isOpen={isCreatePinboardDialogOpen}
        setIsOpen={setIsCreatePinboardDialogOpen}
        triggerId={triggerId}
      />
    </>
  );
};

export { Pinboards };
