import { useLingui } from "@lingui/react/macro";
import { VStack } from "@styled-system/jsx";
import { InputField } from "doowii-ui";
import React, { memo, useEffect, useState } from "react";

export interface PieOptionsProps {
  initialInnerSize?: string;
  initialStartAngle?: number;
  initialEndAngle?: number;
  initialShowInSlice?: boolean;
  onOptionsChange: (options: {
    innerSize?: string;
    startAngle?: number;
    endAngle?: number;
    showInSlice?: boolean;
  }) => void;
}

const PieOptions: React.FC<PieOptionsProps> = memo(
  ({ initialInnerSize = "0%", initialStartAngle = 0, initialEndAngle, onOptionsChange }) => {
    const { t } = useLingui();

    // Set displayName inside the component
    PieOptions.displayName = t`Pie Chart Options`;

    // Local state
    const [innerSize, setInnerSize] = useState(initialInnerSize);
    const [startAngle, setStartAngle] = useState(initialStartAngle);
    const [endAngle, setEndAngle] = useState(initialEndAngle);

    // Update local state when props change
    useEffect(() => {
      setInnerSize(initialInnerSize || "0%");
      setStartAngle(initialStartAngle || 0);
      setEndAngle(initialEndAngle);
    }, [initialInnerSize, initialStartAngle, initialEndAngle]);

    // Handle innerSize change (for donut chart)
    const handleInnerSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newInnerSize = e?.target?.value ?? "0%";
      setInnerSize(newInnerSize);
      onOptionsChange({ innerSize: newInnerSize });
    };

    // Handle startAngle change
    const handleStartAngleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value ?? "0";
      const newStartAngle = parseInt(value, 10) || 0;
      setStartAngle(newStartAngle);
      onOptionsChange({ startAngle: newStartAngle });
    };

    // Handle endAngle change
    const handleEndAngleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value ?? "";
      const newEndAngle = value ? parseInt(value, 10) : undefined;
      setEndAngle(newEndAngle);
      onOptionsChange({ endAngle: newEndAngle });
    };

    return (
      <VStack alignItems="flex-start" gap="md" width="full">
        <InputField
          label={t`Inner Size (for donut chart)`}
          onChange={handleInnerSizeChange}
          placeholder={t`e.g. 50%`}
          value={innerSize}
        />
        <InputField
          label={t`Start Angle`}
          onChange={handleStartAngleChange}
          placeholder={t`e.g. 0`}
          type="number"
          value={startAngle?.toString() || "0"}
        />
        <InputField
          label={t`End Angle (optional)`}
          onChange={handleEndAngleChange}
          placeholder={t`e.g. 360`}
          type="number"
          value={endAngle?.toString() || ""}
        />
      </VStack>
    );
  }
);

export { PieOptions };
