import { PageHeader } from "@components/PageHeader";
import { DeletePinboardDialog, EditPinboardDetailsDialog } from "@components/PinboardCard";
import { AddWidgetDialog } from "@components/PinboardCard/AddWidgetDialog";
import { SharePinboardDialog } from "@components/PinboardCard/components/SharePinboardDialog";
import { HIGHER_ED } from "@constants/constants";
import { usePinboard } from "@context/pinboard";
import { PinboardAccessLevel } from "@doowii-types/auth";
import {
  faAdd,
  faEllipsisVertical,
  faPen,
  faRefresh,
  faShare,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { Plural, useLingui } from "@lingui/react/macro";
import { updatePinboard } from "@services/api/generated/webserver/endpoints/pinboards/pinboards";
import { getPinboardOwnerName } from "@services/firebase/pinboard";
import { css } from "@styled-system/css";
import { Flex, HStack } from "@styled-system/jsx";
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Heading,
  LoadingLogo,
  SearchInput,
  Text,
} from "doowii-ui";
import { useEffect, useId, useState } from "react";
import { Layouts } from "react-grid-layout";

import { env } from "../../../env";
import { PinboardLayout } from "./PinboardLayout";
import { ResetLayoutDialog } from "./ResetLayoutDialog";
import { generateLayouts } from "./utils";
const Pinboard = () => {
  const id = useId();
  const { t } = useLingui();
  const { pinboardResults, widgets, loading, currBoardId, boards } = usePinboard();
  const { userDocument } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [boardOwner, setBoardOwner] = useState<string>("Loading...");

  const boardData = boards.get(currBoardId);
  const boardName = boardData?.name;
  const totalShares: number = boardData?.can_edit.length + boardData?.can_view.length;
  const accessLevel: PinboardAccessLevel = boardData?.can_view.includes(userDocument.id)
    ? PinboardAccessLevel.VIEWER
    : PinboardAccessLevel.OWNER;

  const [isEditPinboardDetailsDialogOpen, setIsEditPinboardDetailsDialogOpen] = useState(false);
  const [isDeletePinboardDialogOpen, setIsDeletePinboardDialogOpen] = useState(false);
  const [isSharePinboardDialogOpen, setIsSharePinboardDialogOpen] = useState(false);
  const [isResetLayoutDialogOpen, setIsResetLayoutDialogOpen] = useState(false);
  const [isAddWidgetDialogOpen, setIsAddWidgetDialogOpen] = useState(false);
  const editButtonId = `${id}-edit-button`;
  const deleteButtonId = `${id}-delete-button`;
  const shareButtonId = `${id}-share-button`;
  const resetLayoutButtonId = `${id}-reset-layout-button`;
  const addWidgetButtonId = `${id}-add-widget-button`;
  const [layouts, setLayouts] = useState<Layouts>();
  const handleRefreshLayout = (newLayouts: Layouts) => {
    setLayouts(newLayouts);
  };

  useEffect(() => {
    if (!currBoardId || !pinboardResults) {
      return;
    }

    if (boards.get(currBoardId)?.pin_layouts) {
      setLayouts(boards.get(currBoardId)?.pin_layouts as Layouts);
    } else {
      const newLayouts = generateLayouts(pinboardResults, widgets);
      setLayouts(newLayouts);
      updatePinboard(currBoardId, { pin_layouts: newLayouts });
    }
  }, [currBoardId, pinboardResults, widgets]);

  useEffect(() => {
    const fetchOwner = async () => {
      if (boards.get(currBoardId)?.created_by === userDocument.id) {
        setBoardOwner(t`You`);
        return;
      }

      try {
        const ownerName = await getPinboardOwnerName(
          boards.get(currBoardId)?.created_by,
          userDocument.organization
        );
        setBoardOwner(ownerName);
      } catch (error) {
        console.error("Error fetching pinboard owner:", error);
        setBoardOwner(t`Another Admin`);
      }
    };

    fetchOwner();
  }, [boards.get(currBoardId)?.created_by, userDocument.organization]);

  return (
    <>
      <PageHeader
        description={t`Page to list pins for ${boardName} board`}
        title={t`Doowii - ${boardName} board`}
      />

      <Flex css={{ flexDirection: "column", w: "full", flex: 1, p: "xl3", pt: 0, gap: "ld" }}>
        <Flex alignItems="center" gap="6">
          <Flex css={{ gap: "md" }}>
            <Heading as="h1" level={1}>
              {boardName}
            </Heading>
          </Flex>
          <HStack gap="2">
            {accessLevel === PinboardAccessLevel.OWNER ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button iconOnly={faEllipsisVertical} variant="dropdown" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuItem
                    aria-label={t`Edit pinboard details`}
                    id={editButtonId}
                    onClick={() => {
                      setIsEditPinboardDetailsDialogOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                    {t`Edit`}
                  </DropdownMenuItem>
                  {env.REACT_APP_ENV !== HIGHER_ED ? (
                    <DropdownMenuItem
                      aria-label={t`Share pinboard`}
                      id={shareButtonId}
                      onClick={() => {
                        setIsSharePinboardDialogOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faShare} />
                      {t`Share`}
                    </DropdownMenuItem>
                  ) : null}
                  <DropdownMenuItem
                    aria-label={t`Add text widget to pinboard`}
                    id={addWidgetButtonId}
                    onClick={() => setIsAddWidgetDialogOpen(true)}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                    {t`Add text widget`}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    aria-label={t`Reset pinboard layout`}
                    id={resetLayoutButtonId}
                    onClick={() => setIsResetLayoutDialogOpen(true)}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                    {t`Reset layout`}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    aria-label={t`Delete pinboard`}
                    id={deleteButtonId}
                    onClick={() => {
                      setIsDeletePinboardDialogOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    {t`Delete`}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : null}

            {pinboardResults.length > 2 ? (
              <SearchInput
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                variant="expandable"
              />
            ) : null}
          </HStack>

          {env.REACT_APP_ENV !== HIGHER_ED ? (
            <Flex
              css={{
                gap: "md",
                marginLeft: "auto",
              }}
            >
              <Badge
                className={css({
                  backgroundColor: "white",
                })}
              >
                {t`Owned by: ${boardOwner}`}
              </Badge>

              {totalShares > 0 && accessLevel === PinboardAccessLevel.OWNER ? (
                <Badge
                  className={css({
                    backgroundColor: "white",
                  })}
                >
                  <Plural
                    one="Shared with # person"
                    other="Shared with # people"
                    value={totalShares}
                  />
                </Badge>
              ) : accessLevel === PinboardAccessLevel.VIEWER ? (
                <Badge
                  className={css({
                    backgroundColor: "white",
                  })}
                >
                  {t`Your access: Viewer`}
                </Badge>
              ) : null}
            </Flex>
          ) : null}
        </Flex>
        {boardData.description ? <Text level={3}>{boardData.description}</Text> : null}
        <Flex css={{ flexDirection: "column", flex: 1 }}>
          {loading ? (
            <Flex flex={1}>
              <LoadingLogo />
            </Flex>
          ) : (
            layouts && (
              <PinboardLayout accessLevel={accessLevel} layouts={layouts} setLayouts={setLayouts} />
            )
          )}
        </Flex>
      </Flex>

      {accessLevel === PinboardAccessLevel.OWNER ? (
        <>
          <EditPinboardDetailsDialog
            boardDescription={boardData?.description}
            boardId={currBoardId}
            boardName={boardName}
            isOpen={isEditPinboardDetailsDialogOpen}
            setIsOpen={setIsEditPinboardDetailsDialogOpen}
            triggerId={editButtonId}
          />

          <DeletePinboardDialog
            boardId={currBoardId}
            isOpen={isDeletePinboardDialogOpen}
            setIsOpen={setIsDeletePinboardDialogOpen}
            triggerId={deleteButtonId}
          />

          <SharePinboardDialog
            boardId={currBoardId}
            boardName={boardName}
            isOpen={isSharePinboardDialogOpen}
            setIsOpen={setIsSharePinboardDialogOpen}
            triggerId={shareButtonId}
          />

          <ResetLayoutDialog
            boardId={currBoardId}
            isOpen={isResetLayoutDialogOpen}
            onLayoutReset={handleRefreshLayout}
            setIsOpen={setIsResetLayoutDialogOpen}
            triggerId={resetLayoutButtonId}
          />

          <AddWidgetDialog
            isOpen={isAddWidgetDialogOpen}
            pinboardId={currBoardId}
            setIsOpen={setIsAddWidgetDialogOpen}
            triggerId={addWidgetButtonId}
          />
        </>
      ) : null}
    </>
  );
};

export { Pinboard };
