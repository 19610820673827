import { Result, ThreadSummary } from "@doowii-types/chat";
import { t } from "@lingui/core/macro";
import { withSentry } from "@utils/wrapper";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { QuestionTypeEnum } from "../../search/Doowii.i";
import { fetchTitleFromServer, getNewChartConfig } from "../webserver/openai";
import { db } from "./connection";

export const updateTitleInThread = withSentry(
  async (organization: string, threadId: string, title: string) => {
    const threadDocRef = doc(db, "organizations", organization, "threads", threadId);
    await updateDoc(threadDocRef, { title });
  }
);

export const updateTitleInThreadRoutine = withSentry(
  async (organization: string, threadId: string) => {
    const chatHistoryCollectionRef = collection(
      db,
      "organizations",
      organization,
      "threads",
      threadId,
      "chats"
    );

    const chatSnapshots = await getDocs(chatHistoryCollectionRef);
    const queries = chatSnapshots.docs.map((doc) => doc.data().query);
    const request = { questions: queries };
    const newTitle = (await fetchTitleFromServer(request)) ?? t`New Chat`;
    await updateTitleInThread(organization, threadId, newTitle);
  }
);

export const addToChatHistory = withSentry(
  async ({
    threads,
    currentThread,
    organization,
    userId,
    query,
    result,
    answer,
    questionType,
  }: {
    threads: ThreadSummary[];
    currentThread: string;
    organization: string;
    userId: string;
    query: string;
    result: Result;
    answer: string;
    questionType: QuestionTypeEnum;
  }) => {
    let threadDocRef;
    let count;
    if (!threads.some((thread: { id: string }) => thread.id === currentThread)) {
      count = 0;

      try {
        const threadsCollectionRef = collection(db, "organizations", organization, "threads");

        const newThreadDoc = {
          title: t`New Chat`,
          created_by: userId,
          created_at: new Date().toISOString(),
          id: currentThread,
          chatCount: 0,
        };
        threadDocRef = doc(threadsCollectionRef, currentThread);
        await setDoc(threadDocRef, newThreadDoc);
      } catch (e) {
        console.error("Error adding thread document: ", e);

        throw e;
      }
    } else {
      threadDocRef = doc(db, "organizations", organization, "threads", currentThread);
      const threadDoc = await getDoc(threadDocRef);
      const threadDocData = threadDoc.data() as ThreadSummary;
      count = threadDocData.chatCount || 0;
    }

    try {
      const chatDocRef = doc(
        db,
        "organizations",
        organization,
        "threads",
        currentThread,
        "chats",
        result.id
      );

      await setDoc(
        chatDocRef,
        {
          query,
          sql: result.sql,
          error: result.error,
          thread_id: currentThread,
          title: result.title,
          satisfied: result.satisfied,
          docId: result.id,
          schema_updated_at: result.schemaUpdatedAt ?? null,
          timestamp: result.timestamp,
          latency: result.latency,
          follow_up_prompts: result.follow_up_prompts,
          chart_config: result.chartConfig,
          total_rows: result.total_rows ?? -1,
          answer,
        },
        { merge: true }
      );

      await updateDoc(threadDocRef, {
        updated_at: new Date().toISOString(),
        chatCount: questionType !== QuestionTypeEnum.REGENERATE ? count + 1 : count,
      });
      if (questionType === QuestionTypeEnum.REGENERATE || count % 5 === 0) {
        await updateTitleInThreadRoutine(organization, currentThread);
      }

      const chatThreadMappingCollectionRef = collection(
        db,
        "organizations",
        organization,
        "chat_thread_mapping"
      );
      const chatThreadMappingDocRef = doc(chatThreadMappingCollectionRef, result.id);
      await setDoc(chatThreadMappingDocRef, {
        query,
        thread_id: currentThread,
        id: result.id,
        created_by: userId,
      });

      return chatDocRef.id;
    } catch (e) {
      console.error("Error adding chat record: ", e);

      throw e;
    }
  }
);

export const fetchAndUpdateChartConfig = withSentry(
  async (orgId: string, threadId: string, chatId: string, userQuery: string, sql: string) => {
    try {
      const chatRef = doc(db, "organizations", orgId, "threads", threadId, "chats", chatId);
      const newChartConfig = await getNewChartConfig(sql, userQuery);
      if (newChartConfig?.columns.length === 1) {
        newChartConfig.suggestion = "TABLE";
      }
      await updateDoc(chatRef, {
        chart_config: newChartConfig,
      });
      return newChartConfig;
    } catch (error) {
      console.error("Failed to update chart config:", error);

      throw error;
    }
  }
);

export const getThreads = withSentry(async (organization: string, userId: string) => {
  const threadsCollectionRef = collection(db, "organizations", organization, "threads");
  const q = query(threadsCollectionRef, where("user_id", "==", userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
});

export const getChatsForThread = withSentry(async (organization: string, threadId: string) => {
  const chatsCollectionRef = collection(
    db,
    "organizations",
    organization,
    "threads",
    threadId,
    "chats"
  );
  const querySnapshot = await getDocs(chatsCollectionRef);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
});
