/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { retrieverAxios } from "../../../../axios-client";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { RetrieveAllRequest } from "../../models/retrieveAllRequest";
import type { RetrieveAllResponse } from "../../models/retrieveAllResponse";

/**
 * @summary Retrieve All Data
 */
export const retrieveAllData = (retrieveAllRequest: RetrieveAllRequest, signal?: AbortSignal) =>
  retrieverAxios<RetrieveAllResponse>({
    url: `/retrieve-all`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: retrieveAllRequest,
    signal,
  });

export const getRetrieveAllDataMutationOptions = <
  TData = Awaited<ReturnType<typeof retrieveAllData>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: RetrieveAllRequest }, TContext>;
}) => {
  const mutationKey = ["retrieveAllData"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof retrieveAllData>>,
    { data: RetrieveAllRequest }
  > = (props) => {
    const { data } = props ?? {};

    return retrieveAllData(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: RetrieveAllRequest },
    TContext
  >;
};

export type RetrieveAllDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAllData>>
>;
export type RetrieveAllDataMutationBody = RetrieveAllRequest;
export type RetrieveAllDataMutationError = HTTPValidationError;

/**
 * @summary Retrieve All Data
 */
export const useRetrieveAllData = <
  TData = Awaited<ReturnType<typeof retrieveAllData>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: RetrieveAllRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: RetrieveAllRequest }, TContext> => {
  const mutationOptions = getRetrieveAllDataMutationOptions(options);

  return useMutation(mutationOptions);
};
