import { UserAvatar } from "@components/UserAvatar";
import { EDIT_QUESTION } from "@constants/features";
import { useChatData } from "@context/chat";
import { useDoowii } from "@context/doowii/doowii";
import { Result } from "@doowii-types/chat";
import { faPen, faRedo, faSend, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex, HStack } from "@styled-system/jsx";
import { Button, Text, Tooltip } from "doowii-ui";
import { useRef, useState } from "react";

import { QuestionTypeEnum } from "../../../search/Doowii.i";

interface ChatQuestionCardProps {
  result: Result;
}

const ChatQuestionCard = ({ result }: ChatQuestionCardProps) => {
  const { allResults, loading } = useChatData();
  const { featureFlags } = useAuth();
  const { t } = useLingui();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const doowii = useDoowii();

  const resendQuestion = async (query: string) => {
    const index = allResults?.findIndex((res) => res?.id === result?.id);
    await doowii.chat({
      query,
      index,
      questionType: QuestionTypeEnum.REGENERATE,
    });
  };

  const handleEditClick = async () => {
    if (isEditing) {
      const newQuery = inputRef.current?.value || result.query;
      setIsEditing(false);
      await resendQuestion(newQuery);
    } else {
      setIsEditing(true);
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  };

  const handleCancelRequest = () => {
    doowii.cancelQuery(result.id);
  };

  return (
    <Flex
      align="center"
      className={css({
        p: "4",
        w: "full",
        marginX: "auto",
        bg: isEditing
          ? "token(colors.base.blueBrandTertiary)"
          : "token(colors.base.blueBrandSecondary)",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)",
      })}
      direction="row"
      gap="8"
      justify="space-between"
    >
      <UserAvatar />
      {isEditing ? (
        <input
          className={css({
            w: "full",
            textStyle: "paragraph_18",
            resize: "none",
            outline: "none",
            cursor: "text",
          })}
          defaultValue={result.query}
          name="edit chat input"
          ref={inputRef}
        />
      ) : (
        <Text
          className={css({
            w: "full",
          })}
          level={4}
        >
          {result.query}
        </Text>
      )}

      <HStack gap="4">
        {!loading
          ? result.id === allResults[allResults.length - 1]?.id &&
            featureFlags.includes(EDIT_QUESTION) && (
              <Tooltip content={isEditing ? t`Regenerate` : t`Edit Question`}>
                <Button
                  aria-label={isEditing ? t`Regenerate` : t`Edit Question`}
                  iconOnly={isEditing ? faSend : faPen}
                  onClick={handleEditClick}
                  size="small"
                  variant={isEditing ? "primary" : "tertiary"}
                />
              </Tooltip>
            )
          : result.loading && (
              <Tooltip content={t`Cancel`}>
                <Button
                  aria-label={t`Cancel`}
                  iconOnly={faXmark}
                  onClick={handleCancelRequest}
                  size="small"
                  variant={isEditing ? "primary" : "tertiary"}
                />
              </Tooltip>
            )}
        {isEditing ? (
          <Tooltip content={t`Cancel`}>
            <Button
              aria-label={t`Cancel`}
              iconOnly={faXmark}
              onClick={() => setIsEditing(false)}
              size="small"
              variant="secondary"
            />
          </Tooltip>
        ) : null}
        {!isEditing && result.error?.message ? (
          <Tooltip content={t`Retry`}>
            <Button
              aria-label={t`Retry`}
              iconOnly={faRedo}
              onClick={() => resendQuestion(result.query)}
              size="small"
              variant="tertiary"
            />
          </Tooltip>
        ) : null}
      </HStack>
    </Flex>
  );
};

export { ChatQuestionCard };
