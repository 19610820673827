import { UserAvatar } from "@components/UserAvatar";
import { HIGHER_ED } from "@constants/constants";
import { MANAGE_USERS } from "@constants/features";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { UserRoles } from "@doowii-types/auth";
import { faChevronDown, faPowerOff } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "doowii-ui";
import { Link, useSubmit } from "react-router-dom";

import { env } from "../../env";
import { useAuth } from "../../hooks/useAuth";

const UserDropdown = () => {
  const { t } = useLingui();
  const submit = useSubmit();
  const { authUser, featureFlags, userDocument } = useAuth();
  const { loading, streamLoading } = useChatData();
  const disableNavigation = loading || streamLoading;

  const userDisplayName = authUser?.displayName ?? authUser?.email ?? t`Unknown User`;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disableNavigation}>
        <Button
          aria-label={t`Open account menu options`}
          data-testid="user-dropdown-button"
          style={{ padding: "2" }}
          variant="dropdown"
        >
          <Flex css={{ alignItems: "center", gap: "10px" }}>
            <UserAvatar />
            {userDisplayName}
            <FontAwesomeIcon icon={faChevronDown} />
          </Flex>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className={css({ w: "200px" })}>
        <Link to={PATHS.SETTINGS}>
          <DropdownMenuItem>
            <Trans>Settings</Trans>
          </DropdownMenuItem>
        </Link>
        {(env.REACT_APP_ENV !== HIGHER_ED || featureFlags?.includes(MANAGE_USERS)) &&
        /**
         * Temporary workaround to handle role-based access control (RBAC) limitations.
         *
         * Context:
         * Our RBAC system does not currently support an admin role that excludes access
         * to the "Manage Users" page. To address this, we rely on feature flags to
         * determine whether the "Manage Users" option should be displayed.
         *
         * Specific Condition:
         * - If the application is running in a non-higher education environment OR
         *   the `MANAGE_USERS` feature flag is enabled, the "Manage Users" option is shown.
         * - If the authenticated user's organization is "wgu" (Western Governors University),
         *   we apply an additional check requiring the `MANAGE_USERS` feature flag.
         * - The user must also have the `ADMIN` role.
         *
         * Why This Exists:
         * - We are using this as a temporary solution to enforce access control
         *   logic into our RBAC system.
         * - This will be removed once a proper RBAC role is implemented that provides
         *   admin-level functionality without "Manage Users" access.
         *
         * Action Plan:
         * - Track this as technical debt.
         * - Remove this condition post PoC stage once the RBAC requirements
         *   are determined and an appropriate system is developed.
         */
        (userDocument.organization !== "wgu" || featureFlags?.includes(MANAGE_USERS)) &&
        userDocument.role === UserRoles.ADMIN ? (
          <Link to={PATHS.USER_MANAGEMENT}>
            <DropdownMenuItem>
              <Trans>Manage users</Trans>
            </DropdownMenuItem>
          </Link>
        ) : null}
        <DropdownMenuItem asChild>
          <a href="https://support.doowii.io/" rel="noopener noreferrer" target="_blank">
            <Trans>Help & Documentation</Trans>
          </a>
        </DropdownMenuItem>

        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => submit(null, { method: "post", action: PATHS.SIGN_OUT })}>
          <FontAwesomeIcon icon={faPowerOff} />
          <Trans>Sign out</Trans>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { UserDropdown };
