import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@components/Table";
import { Aggregations } from "@doowii-types/chart";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useResize } from "@hooks/useResize";
import { Trans, useLingui } from "@lingui/react/macro";
import { CategoryType } from "@services/api/generated/webserver/models/categoryType";
import { css } from "@styled-system/css";
import { Box, Flex, HStack, VStack } from "@styled-system/jsx";
import { flexRender, Table as TanstackTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ChartConfig } from "@utils/chartTransformations";
import { Text, Toggle, Tooltip } from "doowii-ui";
import { useCallback, useRef, useState } from "react";

import { AggregationDropdown } from "./AggregationDropdown";
import { DataTableSkeleton } from "./DataTableSkeleton";
import { EmptyTableOverlay } from "./EmptyTableOverlay";
import { FilterBadges } from "./FilterBadges";
import { ColumnFilterWrapper } from "./filters/ColumnFilterWrapper";
import { SelectionControl } from "./SelectionControl";
import { SortingControl } from "./SortingControl";
import { displayAggregationDropdown, displayBreakdownToggle } from "./utils";

interface DataTableProps {
  isLoading?: boolean;
  chartConfig: ChartConfig;
  tableInstance: TanstackTable<unknown[]>;
  updateConfigState: (config: ChartConfig) => void;
  allowColumnSelection?: boolean;
  allowFiltering?: boolean;
  openFilterColumns?: boolean;
}

/**
 * DataTable component for displaying client-side data with virtualization
 * This component handles all data operations on the client side for smaller datasets
 * using Tanstack Virtual for virtualization and supports filtering, faceting, and sorting
 */
const DataTable = ({
  isLoading,
  chartConfig,
  tableInstance,
  updateConfigState,
  allowColumnSelection = true,
  allowFiltering = true,
  openFilterColumns,
}: DataTableProps) => {
  const { t } = useLingui();
  const { dimensions } = useResize();
  // Reference for the table container (for virtualization)
  const tableContainerRef = useRef<HTMLDivElement>(null);
  // Track which columns have visible filters
  const [visibleFilterColumns, setVisibleFilterColumns] = useState<Record<string, boolean>>({});

  // Toggle filter visibility for a specific column
  const toggleColumnFilter = (columnId: string) => {
    setVisibleFilterColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  // Check if a column has an active filter
  const hasActiveFilter = (columnId: string) => {
    const columnFilters = tableInstance.getState().columnFilters;
    return columnFilters.some((filter) => filter.id === columnId);
  };

  // Set up virtualization
  const { rows } = tableInstance.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: useCallback(() => 55, []), // estimated row height
    overscan: 10,
  });

  const handleAggregationChange = (columnId: string, aggregationType: Aggregations) => {
    updateConfigState({
      ...chartConfig,
      column_aggregations: {
        ...chartConfig.column_aggregations,
        [columnId]: aggregationType,
      } as any,
    });
  };

  const handleGroupingChange = (columnId: string) => {
    const { column_grouping } = chartConfig;

    // If this column is already TYPE, toggle it off
    if (column_grouping[columnId] === CategoryType.breakdown) {
      const newColumnGrouping = { ...column_grouping };
      const newBreakDownCol = Object.keys(column_grouping).find(
        (colId) => column_grouping[colId] === CategoryType.category
      );
      newColumnGrouping[newBreakDownCol] = CategoryType.breakdown;
      newColumnGrouping[columnId] = CategoryType.category;

      updateConfigState({
        ...chartConfig,
        column_grouping: newColumnGrouping as any,
      });
      return;
    }

    // Find the column that currently has BREAKDOWN
    const typeColumnId = Object.keys(column_grouping).find(
      (colId) => column_grouping[colId] === CategoryType.breakdown
    );

    const newColumnGrouping = { ...column_grouping };

    // If there was a previous BREAKDOWN column, set it to CATEGORY
    if (typeColumnId) {
      newColumnGrouping[typeColumnId] = CategoryType.category;
    }

    // Set this column to BREAKDOWN
    newColumnGrouping[columnId] = CategoryType.breakdown;

    updateConfigState({
      ...chartConfig,
      column_grouping: newColumnGrouping as any,
    });
  };

  // Check if there's data to display
  const filteredRows = tableInstance.getFilteredRowModel().rows;
  const totalRows = tableInstance.getCoreRowModel().rows.length.toLocaleString();

  if (isLoading) {
    return <DataTableSkeleton />;
  }

  if (totalRows === "0") {
    return <EmptyTableOverlay />;
  }

  // Get the virtual rows and calculate their positions
  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - virtualRows[virtualRows.length - 1].end : 0;
  const filteredRowsCount = filteredRows.length.toLocaleString();
  const rowsCountText = t`${filteredRowsCount} of ${totalRows} rows`;

  return (
    <VStack gap="0" height="100%" position="relative" pt="3" width="full">
      <FilterBadges allowFiltering={allowFiltering} tableInstance={tableInstance} />

      <div
        className={css({
          width: "full",
          height: "100%",
          // if parent height is not set, use max height otherwise all virtual rows will render
          maxHeight: "1800px",
          overflow: "auto",
          position: "relative",
          borderTopLeftRadius: "xs",
          borderTopRightRadius: "xs",
          paddingBottom: "xl1",
        })}
        ref={tableContainerRef}
      >
        <Box height={dimensions.height} width="full">
          <Table data-testid="data-table">
            <TableHeader
              className={css({ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" })}
            >
              {tableInstance.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead
                      key={header.id}
                      style={{
                        alignContent: "start",
                        width: header.getSize(),
                        minWidth: "250px",
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <VStack alignItems="stretch" gap="0" width="full">
                          {/* Column header content */}
                          <HStack
                            className="column-controls"
                            justify="space-between"
                            textWrap="nowrap"
                          >
                            {allowColumnSelection ? (
                              <SelectionControl
                                chartConfig={chartConfig as any}
                                id={header.column.id}
                                updateConfigState={updateConfigState}
                              />
                            ) : null}
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            <HStack>
                              {allowColumnSelection ? (
                                <div>
                                  {displayAggregationDropdown(header.id, chartConfig as any) ? (
                                    <Tooltip content={t`Column Aggregation for Charting`}>
                                      <div
                                        className={css({
                                          visibility: chartConfig.columns.includes(header.column.id)
                                            ? "visible"
                                            : "hidden",
                                        })}
                                      >
                                        <AggregationDropdown
                                          aggregationType={
                                            chartConfig?.column_aggregations?.[header.column.id] ||
                                            Aggregations.SUM
                                          }
                                          onAggregationChange={(type) =>
                                            handleAggregationChange(header.column.id, type)
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip content={t`Set as breakdown column`}>
                                      <div
                                        className={css({
                                          visibility: displayBreakdownToggle(header.id, chartConfig)
                                            ? "visible"
                                            : "hidden",
                                        })}
                                      >
                                        <Toggle
                                          aria-label={t`Toggle Type Column`}
                                          label={t`Breakdown`}
                                          onPressedChange={() =>
                                            handleGroupingChange(header.column.id)
                                          }
                                          pressed={
                                            chartConfig?.column_grouping?.[header.column.id] ===
                                            CategoryType.breakdown
                                          }
                                          size="xxs"
                                        />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              ) : null}
                              {allowFiltering &&
                              header.column.getCanFilter() &&
                              !openFilterColumns ? (
                                <Tooltip content={t`Toggle Filter Controls`}>
                                  <button
                                    aria-label={t`Toggle filter`}
                                    aria-pressed={visibleFilterColumns[header.column.id]}
                                    className={css({
                                      cursor: "pointer",
                                      padding: "2px",
                                      border: "none",
                                      background: "none",
                                      borderRadius: "sm",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: hasActiveFilter(header.column.id)
                                        ? "base.blueBrandPrimary"
                                        : visibleFilterColumns[header.column.id]
                                          ? "base.blueBrandSecondary"
                                          : "transparent",
                                      color: hasActiveFilter(header.column.id)
                                        ? "white"
                                        : "base.blueBrandPrimary",
                                      transition: "all 0.2s ease",
                                    })}
                                    onClick={() => toggleColumnFilter(header.column.id)}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter" || e.key === " ") {
                                        toggleColumnFilter(header.column.id);
                                      }
                                    }}
                                    type="button"
                                  >
                                    <Box p="1">
                                      <FontAwesomeIcon icon={faFilter} />
                                    </Box>
                                  </button>
                                </Tooltip>
                              ) : null}
                              <SortingControl
                                id={header.column.id}
                                isSorted={header.column.getIsSorted()}
                                setSorting={(updater) => tableInstance.setSorting(updater)}
                              />
                            </HStack>
                          </HStack>

                          {/* Column filter component */}
                          {(allowFiltering && openFilterColumns === true) ||
                          (visibleFilterColumns[header.column.id] &&
                            header.column.getCanFilter()) ? (
                            <div>
                              <ColumnFilterWrapper column={header.column} />
                            </div>
                          ) : null}
                        </VStack>
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody>
              {paddingTop > 0 ? (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              ) : null}
              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index];
                return (
                  <TableRow
                    data-state={row.getIsSelected() ? "selected" : null}
                    key={row.id}
                    style={{ overflow: "hidden" }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        className={css({
                          backgroundColor:
                            chartConfig.columns.includes(cell.column.id) && allowColumnSelection
                              ? "base.blueBrandSecondary"
                              : "inherit",
                        })}
                        key={cell.id}
                      >
                        <div
                          className={css({
                            alignContent: "center",
                            height: "30px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            lineClamp: "2",
                          })}
                          title={cell.getValue() as string}
                        >
                          {cell.getValue() === "null" ? (
                            <span
                              className={css({
                                color: "gray.400",
                                fontStyle: "italic",
                                opacity: 0.8,
                              })}
                            >
                              <Trans>null</Trans>
                            </span>
                          ) : (
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {paddingBottom > 0 ? (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              ) : null}
            </TableBody>
          </Table>
        </Box>
      </div>
      {/* Sticky Footer */}
      <Flex
        className={css({
          p: "md",
          bottom: 0,
          width: "full",
          backgroundColor: "background.base",
          borderTop: "1px solid",
          borderColor: "token(colors.base.lightGray)",
        })}
      >
        <Text level={2}>{rowsCountText}</Text>
      </Flex>
    </VStack>
  );
};

export { DataTable };
