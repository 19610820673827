import { usePinboard } from "@context/pinboard";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { useModalClose } from "@hooks/useModalClose";
import { useLingui } from "@lingui/react/macro";
import { updatePinboard } from "@services/api/generated/webserver/endpoints/pinboards/pinboards";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import { ConfirmationDialog } from "doowii-ui";
import { useState } from "react";
import { generateLayouts } from "../utils";

interface Props {
  isOpen: boolean;
  boardId: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
  onLayoutReset: (newLayouts: any) => void;
}

const ResetLayoutDialog: React.FC<Props> = ({
  isOpen,
  boardId,
  triggerId,
  setIsOpen,
  onLayoutReset,
}) => {
  useModalClose(isOpen, triggerId);
  const { t } = useLingui();
  const [loading, setLoading] = useState(false);
  const { pinboardResults, widgets } = usePinboard();

  const handleResetLayout = async () => {
    setLoading(true);
    try {
      const newLayouts = generateLayouts(pinboardResults, widgets);
      await updatePinboard(boardId, { pin_layouts: newLayouts });
      onLayoutReset(newLayouts);
      setIsOpen(false);
    } catch (error) {
      console.error("Error resetting layout:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmationDialog
      cancelButtonText={t`Cancel`}
      confirmationButtonText={t`Reset`}
      description={t`Are you sure you want to reset the layout of this pinboard? This will restore the default arrangement of all pins.`}
      icon={faRefresh}
      isLoadingAction={loading}
      isOpen={isOpen}
      onCancel={stopPropagationWrapper(() => setIsOpen(false))}
      onConfirm={stopPropagationWrapper(handleResetLayout)}
      setIsOpen={setIsOpen}
      title={t`Reset pinboard layout`}
    />
  );
};

export { ResetLayoutDialog };
