import { PATHS } from "@constants/Paths";
import { t } from "@lingui/core/macro";
import { verifyCode } from "@services/api/generated/webserver/endpoints/users/users";
import { VerifyEmailCodeRequest } from "@services/api/generated/webserver/models/verifyEmailCodeRequest";
import { VerifyEmailCodeResponseResult } from "@services/api/generated/webserver/models/verifyEmailCodeResponseResult";
import { useBoundStore } from "@stores/store";
import { ActionFunctionArgs, json, redirect } from "react-router-dom";


interface VerifyEmailActionResponse {
  errors?: {
    code?: string;
  };
  fields?: {
    code?: string | null;
  };
  result: VerifyEmailCodeResponseResult;
}
  
  const verifyEmailAction = async ({
    request,
  }: ActionFunctionArgs): Promise<VerifyEmailActionResponse | Response> => {
    const { authUser, featureFlags } = useBoundStore.getState();
    if (!authUser) {
      return redirect(PATHS.SIGN_IN);
    }
    const formData = await request.formData();

    const code = formData.get("code") as string;
    const errors: {
      code?: string;
    } = {};

    if (!code) {
      errors.code = t`Code is required`;
    }

    if (code.length !== 6) {
      errors.code = t`Code should be 6 digits`
    }

    if (Object.keys(errors).length > 0) {
      return json(
        { errors, fields: { code } },
        { status: 400 }
      );
    }
  
    try {
      const request = {
        code: code,
        email: authUser.email,
      } as VerifyEmailCodeRequest;

      const response = await verifyCode(request);

      if (response.result != VerifyEmailCodeResponseResult.SUCCESS) {
        return json(
          {
            errors: { code: "Code failed, please try again" },
            fields: {
              code: code,
            },
          },
          { status: 401 }
        );
      }

      return json({ result: response.result });

    } catch (error: unknown) {
      console.error(error);
      return json(
        {
          errors: { code: "Unknown error during code verification" },
          fields: {
            code: code,
          },
        },
        { status: 418 }
      );
    }
  };

export { verifyEmailAction, type VerifyEmailActionResponse };