import { t } from "@lingui/core/macro";
import { sendPwdResetEmailWithFirebase } from "@services/firebase";
import { disableAccount } from "@services/firebase/user";
import { useBoundStore } from "@stores/store";
import { ActionFunctionArgs, json, redirect } from "react-router-dom";

import { UserDocument } from "../../types/";

interface SettingsActionResponse {
  errors?: {
    form?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
  };
  fields?: {
    firstName: UserDocument["firstName"];
    lastName: UserDocument["lastName"];
  };
  success?: boolean;
  message?: string;
}

const handleUpdateProfile = async (formData: FormData) => {
  const firstName = formData.get("firstName");
  const lastName = formData.get("lastName");

  try {
    const userDetails = { firstName, lastName } as Partial<UserDocument>;
    await useBoundStore.getState().updateUserDocument(userDetails);
    return json({ success: true, message: t`Profile updated successfully` });
  } catch (error) {
    console.error(error);
    return json({ success: false, message: t`Failed to update user details` }, { status: 500 });
  }
};

const handleDeleteAccount = async (userDocument: UserDocument) => {
  const { id, email, organization } = userDocument;

  try {
    await disableAccount({ id, email, organization }, { email });
    return json({ success: true, message: t`Account deleted successfully` });
  } catch (error) {
    console.error(error);
    return json({ success: false, message: t`Failed to delete account` }, { status: 500 });
  }
};

const handleSendResetLink = async (email: string) => {
  try {
    await sendPwdResetEmailWithFirebase(email);
    return json({ success: true, message: t`Password reset link sent to email` });
  } catch (error) {
    console.error(error);
    return json(
      { success: false, message: t`Failed to send password reset link` },
      { status: 500 }
    );
  }
};

const settingsAction = async ({
  request,
}: ActionFunctionArgs): Promise<SettingsActionResponse | Response> => {
  const formData = await request.formData();
  const { authUser, userDocument } = useBoundStore.getState();

  if (!authUser || !userDocument) {
    return redirect("/sign-in");
  }

  const intent = formData.get("intent");

  switch (intent) {
    case "updateProfile":
      return handleUpdateProfile(formData);

    case "deleteAccount":
      return handleDeleteAccount(userDocument);

    case "sendResetLink":
      return handleSendResetLink(userDocument.email);

    default:
      return json({ success: false, message: t`Invalid action` }, { status: 400 });
  }
};

export { settingsAction, type SettingsActionResponse };
