import { Trans } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, HStack, VStack } from "@styled-system/jsx";
import { Column } from "@tanstack/react-table";
import { RangeSlider } from "doowii-ui";
import { useEffect, useMemo, useState } from "react";

interface NumericRangeFilterProps {
  column: Column<unknown[]>;
  sortedUniqueValues: unknown[];
}

/**
 * NumericRangeFilter component handles range filtering for numerical columns
 * Contains all logic for range calculation and formatting
 */
export const NumericRangeFilter = ({ column, sortedUniqueValues }: NumericRangeFilterProps) => {
  const columnFilterValue = column.getFilterValue() as [number, number] | undefined;
  const minMaxValues = column.getFacetedMinMaxValues();

  // Calculate numeric filter properties
  const numericFilterProps = useMemo(() => {
    const minValue = minMaxValues?.[0] ?? 0;
    const maxValue = minMaxValues?.[1] ?? 100;

    // Sample unique values to determine if decimals are needed
    let hasDecimals = false;
    let maxPrecision = 0;

    // Check a sample of values to determine if decimals are needed
    const sampleValues = sortedUniqueValues.slice(0, 20);

    for (const value of sampleValues) {
      if (typeof value === "number") {
        const valueString = value.toString();
        if (valueString.includes(".")) {
          hasDecimals = true;
          const decimalPart = valueString.split(".")[1];
          maxPrecision = Math.max(maxPrecision, decimalPart.length);
        }
      }
    }

    // Set step size based on whether decimals are present
    const step = hasDecimals
      ? Math.pow(10, -maxPrecision) // Use appropriate step for decimal precision
      : 1; // Use integer steps if no decimals

    // Current filter values or default to min/max
    const currentMinValue = columnFilterValue?.[0] ?? minValue;
    const currentMaxValue = columnFilterValue?.[1] ?? maxValue;

    // Format values for display based on observed precision
    const formatValue = (value: number) =>
      hasDecimals ? value.toFixed(maxPrecision) : value.toLocaleString();

    return {
      minValue,
      maxValue,
      step,
      currentMinValue,
      currentMaxValue,
      formatValue,
    };
  }, [columnFilterValue, minMaxValues, sortedUniqueValues]);

  // Initialize local state for the range slider
  const [localRange, setLocalRange] = useState<[number, number]>([
    numericFilterProps.currentMinValue,
    numericFilterProps.currentMaxValue,
  ]);

  // Update local range when filter values change externally
  useEffect(() => {
    setLocalRange([numericFilterProps.currentMinValue, numericFilterProps.currentMaxValue]);
  }, [numericFilterProps.currentMinValue, numericFilterProps.currentMaxValue]);

  const { minValue, maxValue, step, formatValue } = numericFilterProps;
  const formattedMinValue = formatValue(localRange[0]);
  const formattedMaxValue = formatValue(localRange[1]);

  return (
    <VStack
      className={css({
        width: "100%",
        marginTop: "xs",
        gap: "4px",
      })}
    >
      <RangeSlider
        data-testid="numeric-range-filter"
        disabled={minValue === maxValue}
        formatValue={formatValue}
        max={Math.max(maxValue, localRange[1])}
        min={Math.min(minValue, localRange[0])}
        onValueChange={(range) => {
          // Update local state for immediate visual feedback
          setLocalRange(range);
        }}
        onValueCommit={(range) => {
          const newRange = [
            range[0] === minValue ? null : range[0],
            range[1] === maxValue ? null : range[1],
          ];

          // Only update filter when dragging ends
          column.setFilterValue(newRange);
        }}
        showTooltip
        step={step}
        value={localRange}
      />

      <HStack justify="space-between" width="100%">
        <Box
          className={css({
            p: "xs",
            fontSize: "xs",
            rounded: "xs",
            border: "1px solid",
            borderColor: "border.base",
            backgroundColor: "background.base",
          })}
        >
          <Trans>Min: {formattedMinValue}</Trans>
        </Box>
        <Box
          className={css({
            p: "xs",
            fontSize: "xs",
            rounded: "xs",
            border: "1px solid",
            borderColor: "border.base",
            backgroundColor: "background.base",
          })}
        >
          <Trans>Max: {formattedMaxValue}</Trans>
        </Box>
      </HStack>
    </VStack>
  );
};
