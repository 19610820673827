import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/react/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { Card, Heading, Separator, Text } from "doowii-ui";
import { useState } from "react";
const IntroCard = () => {
  const [open, setOpen] = useState(false);
  return (
    <Card>
      <Collapsible.Root className={css({ w: "100%" })} onOpenChange={setOpen} open={open}>
        <Collapsible.Trigger className={css({ w: "100%", cursor: "pointer" })}>
          <Flex align="center" justify="space-between">
            <Heading level={3}>
              <Trans>This demo is using the IPEDS dataset</Trans>
            </Heading>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="lg" />
          </Flex>
        </Collapsible.Trigger>

        <Collapsible.Content>
          <Separator className={css({ my: "4" })} colorful={false} />
          <Flex align="center" direction="column" gap="4">
            <Box>
              <Text>
                <Trans>
                  There is information from every college, university, and technical and vocational
                  institution that participates in the federal student financial aid programs. There
                  is data around enrollments, program completions, graduation rates, finances,
                  institutional prices, and student financial aid.
                </Trans>
              </Text>
            </Box>
            <Separator colorful={false} />

            <Flex align="center" justify="space-between" w="100%">
              <a href="https://nces.ed.gov/ipeds/" rel="noopener noreferrer" target="_blank">
                <Heading level={3}>
                  <Trans>Link to the IPEDS site</Trans>
                </Heading>
              </a>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Flex>
          </Flex>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  );
};

export { IntroCard };
