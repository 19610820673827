/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { webserverAxios } from "../../../../axios-client";
import type { BodyShareResultApiShareResultPost } from "../../models/bodyShareResultApiShareResultPost";
import type { HTTPValidationError } from "../../models/hTTPValidationError";
import type { SendContactRequest } from "../../models/sendContactRequest";
import type { SendContactRequestResponse } from "../../models/sendContactRequestResponse";
import type { SendDemoRegisterRequest } from "../../models/sendDemoRegisterRequest";
import type { SendDemoRegisterResponse } from "../../models/sendDemoRegisterResponse";
import type { SendVerificationCodeRequest } from "../../models/sendVerificationCodeRequest";
import type { SendVerificationCodeResponse } from "../../models/sendVerificationCodeResponse";
import type { ShareResultResponse } from "../../models/shareResultResponse";

/**
 * Share a result image via email.

Args:
    share_request: Recipient email and image data
    current_user: Current user and organization info

Returns:
    ShareResultResponse: Operation status and message

Raises:
    HTTPException: If user not found or email sending fails
 * @summary Share Result
 */
export const shareResult = (
  bodyShareResultApiShareResultPost: BodyShareResultApiShareResultPost,
  signal?: AbortSignal
) =>
  webserverAxios<ShareResultResponse>({
    url: `/api/share-result/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: bodyShareResultApiShareResultPost,
    signal,
  });

export const getShareResultMutationOptions = <
  TData = Awaited<ReturnType<typeof shareResult>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyShareResultApiShareResultPost },
    TContext
  >;
}) => {
  const mutationKey = ["shareResult"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shareResult>>,
    { data: BodyShareResultApiShareResultPost }
  > = (props) => {
    const { data } = props ?? {};

    return shareResult(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyShareResultApiShareResultPost },
    TContext
  >;
};

export type ShareResultMutationResult = NonNullable<Awaited<ReturnType<typeof shareResult>>>;
export type ShareResultMutationBody = BodyShareResultApiShareResultPost;
export type ShareResultMutationError = HTTPValidationError;

/**
 * @summary Share Result
 */
export const useShareResult = <
  TData = Awaited<ReturnType<typeof shareResult>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyShareResultApiShareResultPost },
    TContext
  >;
}): UseMutationResult<TData, TError, { data: BodyShareResultApiShareResultPost }, TContext> => {
  const mutationOptions = getShareResultMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Process a contact form submission.

Args:
    contact_request: School contact details
    current_user: Current user and organization info

Returns:
    SendContactRequestResponse: Operation status and message

Raises:
    HTTPException: If email sending fails
 * @summary Send Contact Request
 */
export const sendContactRequest = (sendContactRequest: SendContactRequest, signal?: AbortSignal) =>
  webserverAxios<SendContactRequestResponse>({
    url: `/api/send-contact-request/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: sendContactRequest,
    signal,
  });

export const getSendContactRequestMutationOptions = <
  TData = Awaited<ReturnType<typeof sendContactRequest>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SendContactRequest }, TContext>;
}) => {
  const mutationKey = ["sendContactRequest"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendContactRequest>>,
    { data: SendContactRequest }
  > = (props) => {
    const { data } = props ?? {};

    return sendContactRequest(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: SendContactRequest },
    TContext
  >;
};

export type SendContactRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendContactRequest>>
>;
export type SendContactRequestMutationBody = SendContactRequest;
export type SendContactRequestMutationError = HTTPValidationError;

/**
 * @summary Send Contact Request
 */
export const useSendContactRequest = <
  TData = Awaited<ReturnType<typeof sendContactRequest>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SendContactRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: SendContactRequest }, TContext> => {
  const mutationOptions = getSendContactRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Process a demo registration request.

Args:
    contact_request: Organization contact details

Returns:
    SendDemoRegisterResponse: Operation status and message

Raises:
    HTTPException: If email sending fails or email already exists
 * @summary Send Demo Register Request
 */
export const sendDemoRegisterRequest = (
  sendDemoRegisterRequest: SendDemoRegisterRequest,
  signal?: AbortSignal
) =>
  webserverAxios<SendDemoRegisterResponse>({
    url: `/api/send-demo-register-request/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: sendDemoRegisterRequest,
    signal,
  });

export const getSendDemoRegisterRequestMutationOptions = <
  TData = Awaited<ReturnType<typeof sendDemoRegisterRequest>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SendDemoRegisterRequest }, TContext>;
}) => {
  const mutationKey = ["sendDemoRegisterRequest"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendDemoRegisterRequest>>,
    { data: SendDemoRegisterRequest }
  > = (props) => {
    const { data } = props ?? {};

    return sendDemoRegisterRequest(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: SendDemoRegisterRequest },
    TContext
  >;
};

export type SendDemoRegisterRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendDemoRegisterRequest>>
>;
export type SendDemoRegisterRequestMutationBody = SendDemoRegisterRequest;
export type SendDemoRegisterRequestMutationError = HTTPValidationError;

/**
 * @summary Send Demo Register Request
 */
export const useSendDemoRegisterRequest = <
  TData = Awaited<ReturnType<typeof sendDemoRegisterRequest>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SendDemoRegisterRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: SendDemoRegisterRequest }, TContext> => {
  const mutationOptions = getSendDemoRegisterRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Proceses a send verification code request.

Args:
    request: Request to send verification code to a specific email

Returns:
    SendVerificationCodeResponse: Operation status and message

Raises:
    HTTPException: If email sending fails or if email has been sent in the last minute
 * @summary Send Verification Code
 */
export const sendVerificationCode = (
  sendVerificationCodeRequest: SendVerificationCodeRequest,
  signal?: AbortSignal
) =>
  webserverAxios<SendVerificationCodeResponse>({
    url: `/api/send-verification-code/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: sendVerificationCodeRequest,
    signal,
  });

export const getSendVerificationCodeMutationOptions = <
  TData = Awaited<ReturnType<typeof sendVerificationCode>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SendVerificationCodeRequest }, TContext>;
}) => {
  const mutationKey = ["sendVerificationCode"];
  const { mutation: mutationOptions } = options
    ? options.mutation && "mutationKey" in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendVerificationCode>>,
    { data: SendVerificationCodeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return sendVerificationCode(data);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: SendVerificationCodeRequest },
    TContext
  >;
};

export type SendVerificationCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendVerificationCode>>
>;
export type SendVerificationCodeMutationBody = SendVerificationCodeRequest;
export type SendVerificationCodeMutationError = HTTPValidationError;

/**
 * @summary Send Verification Code
 */
export const useSendVerificationCode = <
  TData = Awaited<ReturnType<typeof sendVerificationCode>>,
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: SendVerificationCodeRequest }, TContext>;
}): UseMutationResult<TData, TError, { data: SendVerificationCodeRequest }, TContext> => {
  const mutationOptions = getSendVerificationCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
