/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Type of column grouping.

- CATEGORY: Discrete values like categories or labels
- BREAKDOWN: Continuous numerical values
 */
export enum CategoryType {
  category = "category",
  breakdown = "breakdown",
}
