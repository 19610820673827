/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Data type classification for columns.

Used to determine appropriate visualizations based on column types:
- CATEGORICAL: Discrete values like categories or labels
- NUMERICAL: Continuous numerical values
- TEMPORAL: Date/time values
 */
export enum ColumnDataType {
  categorical = "categorical",
  numerical = "numerical",
  temporal = "temporal",
}
