import { usePinboard } from "@context/pinboard";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useModalClose } from "@hooks/useModalClose";
import { useLingui } from "@lingui/react/macro";
import { ConfirmationDialog } from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  boardId: string;
  pinId: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const DeletePinDialog: React.FC<Props> = ({ isOpen, pinId, boardId, triggerId, setIsOpen }) => {
  useModalClose(isOpen, triggerId);

  const { t } = useLingui();
  const [loading, setLoading] = useState(false);
  const { unpinFromBoard } = usePinboard();
  const handleDelete = async () => {
    setLoading(true);
    await unpinFromBoard(pinId, boardId);
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <ConfirmationDialog
      cancelButtonText={t`Cancel`}
      confirmationButtonText={t`Delete`}
      description={t`Are you sure you want to delete this pin?`}
      destructive
      icon={faTrash}
      isLoadingAction={loading}
      isOpen={isOpen}
      onCancel={() => setIsOpen(false)}
      onConfirm={handleDelete}
      setIsOpen={setIsOpen}
      title={t`Delete the result`}
    />
  );
};

export { DeletePinDialog };
