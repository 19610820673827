import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faLock,
  faPlus,
  faRotate,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react/macro";
import {
  getGetDocumentKnowledgeQueryKey,
  useDeleteDocumentKnowledge,
  useGetDocumentKnowledge,
  useUpdateDocumentKnowledge,
} from "@services/api/generated/webserver/endpoints/knowledge/knowledge";
import type { DocumentKnowledgeItem } from "@services/api/generated/webserver/models/documentKnowledgeItem";
import { KnowledgeStatus } from "@services/api/generated/webserver/models/knowledgeStatus";
import type { UpdateDocumentKnowledgeRequest } from "@services/api/generated/webserver/models/updateDocumentKnowledgeRequest";
import { css } from "@styled-system/css";
import { Box, Stack } from "@styled-system/jsx";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import {
  Button,
  ConfirmationDialog,
  DropdownMenuItem,
  Label,
  SearchInput,
  SimpleTable,
  Text,
  useToast,
} from "doowii-ui";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";

import { EditKnowledgeItemDialog } from "./EditKnowledgeDialog";
import { EnableSwitch } from "./EnableSwitch";
import { ContextActionsDropdown } from "./KnowledgeActionsDropdown";
import { StatusBadge } from "./StatusBadge";
import { UploadDocumentDialog } from "./UploadDocumentDialog";

const columnHelper = createColumnHelper<DocumentKnowledgeItem>();

const DocumentKnowledgeTab = () => {
  const { t } = useLingui();
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetDocumentKnowledge({
    query: {
      refetchInterval: 12000,
    },
  });
  const { toast } = useToast();
  const [editingItem, setEditingItem] = useState<DocumentKnowledgeItem | null>(null);
  const [deletingItem, setDeletingItem] = useState<DocumentKnowledgeItem | null>(null);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { mutate: update, isPending: isUpdating } = useUpdateDocumentKnowledge({
    mutation: {
      onMutate: async (newData) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries({
          queryKey: getGetDocumentKnowledgeQueryKey(),
        });

        // Snapshot the previous value
        const previousItems =
          queryClient.getQueryData<DocumentKnowledgeItem[]>(getGetDocumentKnowledgeQueryKey()) ??
          [];

        // Optimistically update to the new value
        queryClient.setQueryData<DocumentKnowledgeItem[]>(
          getGetDocumentKnowledgeQueryKey(),
          previousItems.map((item) =>
            item.id === newData.data.id
              ? {
                  ...item,
                  ...newData.data,
                  updated_at: new Date().toISOString(),
                  status: KnowledgeStatus.PENDING,
                }
              : item
          )
        );

        return { previousItems };
      },
      onError: (err, variables, context) => {
        if (context?.previousItems) {
          queryClient.setQueryData(getGetDocumentKnowledgeQueryKey(), context.previousItems);
        }
      },
    } as UseMutationOptions<
      unknown,
      unknown,
      { data: UpdateDocumentKnowledgeRequest },
      { previousItems: DocumentKnowledgeItem[] }
    >,
  });

  const { mutate: remove, isPending: isDeleting } = useDeleteDocumentKnowledge();

  const handleEdit = useCallback((item: DocumentKnowledgeItem) => {
    setEditingItem(item);
  }, []);

  const handleDelete = useCallback((item: DocumentKnowledgeItem) => {
    setDeletingItem(item);
  }, []);

  const handleSaveEdit = useCallback(
    (data: UpdateDocumentKnowledgeRequest) => {
      update(
        { data },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: t`Document context updated successfully`,
            });
            setEditingItem(null);
          },
          onError: (error: { response?: { data?: { detail?: string } } }) => {
            console.error("Error updating document context:", error);
            const errorDetail = error.response?.data?.detail;
            toast({
              status: "error",
              title: t`Failed to update`,
              description: errorDetail || t`An unexpected error occurred`,
            });
          },
        }
      );
    },
    [t, toast, update]
  );

  const handleConfirmDelete = useCallback(() => {
    if (!deletingItem) {
      return;
    }

    remove(
      { knowledgeId: deletingItem.id },
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData<DocumentKnowledgeItem[]>(
            getGetDocumentKnowledgeQueryKey(),
            (old) =>
              old.map((item) =>
                item.id === variables.knowledgeId
                  ? {
                      ...item,
                      status: KnowledgeStatus.DELETING,
                      updated_at: new Date().toISOString(),
                    }
                  : item
              )
          );
          toast({
            status: "success",
            title: t`Document context deleted successfully`,
            description: t`The document has been removed`,
          });
          setDeletingItem(null);
        },
        onError: (error) => {
          console.error(error);
          toast({
            status: "error",
            title: t`Failed to delete document context`,
          });
          setDeletingItem(null);
        },
      }
    );
  }, [deletingItem, t, toast, remove, queryClient]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        header: t`Expand`,
        cell: ({ row }) => {
          if (!row.getCanExpand()) {
            return null;
          }
          return (
            <Stack alignItems="center">
              <Button
                aria-label={row.getIsExpanded() ? t`Collapse row` : t`Expand row`}
                className={css({
                  color: "gray.900",
                })}
                iconOnly={row.getIsExpanded() ? faChevronDown : faChevronRight}
                onClick={row.getToggleExpandedHandler()}
                size="xsmall"
                variant="text"
              />
            </Stack>
          );
        },
      },
      columnHelper.accessor("title", {
        header: t`Title`,
        cell: (info) => (
          <Box
            css={{
              lineHeight: "1.25",
              maxWidth: "200px",
              textOverflow: "ellipsis",
              lineClamp: 3,
            }}
          >
            {info.getValue()}
          </Box>
        ),
      }),
      columnHelper.accessor("description", {
        header: t`Description`,
        cell: (info) => (
          <Box
            css={{
              lineHeight: "1.25",
              textOverflow: "ellipsis",
              lineClamp: 3,
            }}
          >
            {info.getValue()}
          </Box>
        ),
      }),
      columnHelper.accessor("file_size", {
        header: t`File Size`,
        cell: (info) => {
          const size = info.getValue();
          return size ? (
            <Text
              className={css({
                whiteSpace: "nowrap",
                color: "gray.600",
              })}
            >
              {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
              {(size / (1024 * 1024)).toFixed(2)} MB
            </Text>
          ) : null;
        },
      }),
      columnHelper.accessor("status", {
        header: t`Status`,
        cell: (info) => <StatusBadge status={info.getValue()} />,
      }),
      columnHelper.accessor("updated_at", {
        header: t`Last Updated`,
        cell: (info) => {
          const date = info.getValue() ? DateTime.fromISO(info.getValue()) : null;
          return date ? (
            <Text
              className={css({
                whiteSpace: "nowrap",
                color: "gray.600",
              })}
            >
              {date.toRelative()}
            </Text>
          ) : null;
        },
      }),
      columnHelper.accessor("is_enabled", {
        header: t`Enable`,
        cell: (info) => (
          <EnableSwitch
            isEnabled={info.getValue()}
            onToggle={(checked) => {
              handleSaveEdit({
                id: info.row.original.id,
                is_enabled: checked,
              } as DocumentKnowledgeItem);
            }}
            status={info.row.original.status}
          />
        ),
      }),
      {
        id: "actions",
        header: t`Actions`,
        cell: ({ row }) => {
          const isEditable = row.original.status === KnowledgeStatus.COMPLETED;
          const isDeletable =
            row.original.status === KnowledgeStatus.COMPLETED ||
            row.original.status === KnowledgeStatus.FAILED;
          const canRetry =
            row.original.status === KnowledgeStatus.FAILED ||
            row.original.status === KnowledgeStatus.PENDING;

          return (
            <Stack alignItems="center">
              <ContextActionsDropdown>
                {isEditable ? (
                  <DropdownMenuItem onClick={() => handleEdit(row.original)}>
                    <FontAwesomeIcon icon={faEdit} />
                    {t`Edit`}
                  </DropdownMenuItem>
                ) : canRetry ? (
                  <DropdownMenuItem onClick={() => handleSaveEdit(row.original)}>
                    <FontAwesomeIcon icon={faRotate} />
                    {t`Retry`}
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem disabled>
                    <FontAwesomeIcon icon={faLock} />
                    {t`Processing`}
                  </DropdownMenuItem>
                )}
                {isDeletable ? (
                  <DropdownMenuItem onClick={() => handleDelete(row.original)}>
                    <FontAwesomeIcon icon={faTrash} />
                    {t`Delete`}
                  </DropdownMenuItem>
                ) : null}
              </ContextActionsDropdown>
            </Stack>
          );
        },
      },
    ],
    [t, handleEdit, handleDelete, handleSaveEdit]
  );

  const renderSubComponent = ({ row }: { row: Row<DocumentKnowledgeItem> }) => (
    <Stack
      css={{
        p: 4,
        bg: "gray.50",
        pl: 10,
        maxHeight: "300px",
        overflowY: "auto",
        boxShadow: "inset 0 0 10px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Label level={4} variant="primary">
        {row.original.title}
      </Label>
      <Text variant="primary">
        {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
        {t`File Size`}: {(row.original.file_size / (1024 * 1024)).toFixed(2)} MB
      </Text>
      <Text variant="primary">
        {t`File Type`}: {row.original.file_type}
      </Text>
      <Text variant="primary">
        {t`Description`}: {row.original.description}
      </Text>
    </Stack>
  );

  return (
    <Box>
      <Stack css={{ mb: 4 }} gap={4} justifyContent="space-between">
        <Text level={3} variant="primary">
          {t`Here you can upload and manage documents that will be used to provide context for Doowii's responses.`}
        </Text>
        <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
          <SearchInput
            aria-label={t`Search knowledge items`}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t`Search by title`}
            value={searchQuery}
            variant="standard"
          />
          <Button
            iconLeft={faPlus}
            onClick={() => setIsUploadDialogOpen(true)}
            size="small"
            variant="primary"
          >
            {t`Upload Document`}
          </Button>
        </Stack>
      </Stack>

      <SimpleTable
        className={css({
          maxHeight: "3xl",
        })}
        columnFilters={[{ id: "title", value: searchQuery }]}
        columns={columns as ColumnDef<DocumentKnowledgeItem>[]}
        data={data ?? []}
        emptyComponent={
          <div className={css({ textAlign: "center", py: "4" })}>{t`No data available`}</div>
        }
        getRowCanExpand={(row) =>
          String(row.getValue("description")).length > 0 || row.original.file_size > 0
        }
        loading={isLoading}
        paginationMode="none"
        renderSubComponent={renderSubComponent}
      />

      <EditKnowledgeItemDialog
        dialogDescription={t`Edit this document's metadata.`}
        dialogTitle={t`Edit Document`}
        isOpen={editingItem !== null}
        isSaving={isUpdating}
        item={
          editingItem
            ? {
                ...editingItem,
                content: editingItem.description ?? "",
              }
            : null
        }
        onClose={() => setEditingItem(null)}
        onSave={(data) => {
          const changes: UpdateDocumentKnowledgeRequest = {
            id: editingItem.id,
          };
          // only include changes if they are different from the original
          if (data.content !== editingItem.description) {
            changes.description = data.content;
          }
          if (data.title !== editingItem.title) {
            changes.title = data.title;
          }
          handleSaveEdit(changes);
        }}
      />

      <ConfirmationDialog
        confirmationButtonText={t`Delete`}
        description={t`Are you sure you want to delete this document? This action cannot be undone.`}
        destructive
        icon={faTrash}
        isLoadingAction={isDeleting}
        isOpen={deletingItem !== null}
        onConfirm={handleConfirmDelete}
        setIsOpen={() => setDeletingItem(null)}
        title={t`Delete Document`}
      />

      <UploadDocumentDialog
        isOpen={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}
        onSuccess={() => {
          void queryClient.invalidateQueries({ queryKey: getGetDocumentKnowledgeQueryKey() });
        }}
      />
    </Box>
  );
};

export { DocumentKnowledgeTab };
