import { ContactMeBtn } from "@components/ContactMe";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/react/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Flex, HStack } from "@styled-system/jsx";
import { Button, Card, Heading, Separator, Text } from "doowii-ui";
import { useState } from "react";

const DoowiiCard = () => {
  const [open, setOpen] = useState(true);

  return (
    <Card>
      <Collapsible.Root className={css({ w: "100%" })} onOpenChange={setOpen} open={open}>
        <Collapsible.Trigger className={css({ w: "100%", cursor: "pointer" })}>
          <Flex align="center" justify="space-between">
            <Heading level={3}>
              <Trans>About Doowii</Trans>
            </Heading>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="lg" />
          </Flex>
        </Collapsible.Trigger>

        <Collapsible.Content>
          <Separator className={css({ my: "4" })} colorful={false} />
          <Text>
            <Trans>
              At Doowii, we believe in changing education by putting data and insights into the
              hands of those who can take action. This means more than just data aggregation: it
              means allowing authorized users to query, explore, and visualize data -- all in real
              time. We've provided this tool as a free service to help the community explore the
              IPEDS data; if you'd like to learn more about what Doowii can do for you, we'd be
              delighted to discuss.
            </Trans>
          </Text>

          <br />
          <Text>
            <Trans> Find us by hitting the Contact button or through our website below.</Trans>
          </Text>
          <Separator className={css({ my: "4" })} colorful={false} />
          <HStack gap="ld">
            <ContactMeBtn size="small" />
            <Button
              iconLeft={faArrowUpRightFromSquare}
              onClick={() => window.open("https://doowii.io", "_blank", "noopener,noreferrer")}
              size="small"
              variant="secondary"
            >
              doowii.io
            </Button>
          </HStack>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  );
};

export { DoowiiCard };
