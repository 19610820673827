import { ParentDocTypeEnum } from "@api/retriever.i";
import { ApolloDialog } from "@components/Apollo/ApolloDialog";
import { ChartBuilderDialog } from "@components/ChartBuilder";
import { ShareResultDialog } from "@components/ShareResultDialog";
import { VisualizationRouter } from "@components/VisualizationRouter/VisualizationRouter";
import { usePinboard } from "@context/pinboard";
import { PinboardAccessLevel } from "@doowii-types/auth";
import { Pin } from "@doowii-types/pinboard";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { useLingui } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { Button, Separator, Text, Tooltip } from "doowii-ui";
import { useState } from "react";

import { PinDropdown } from "./PinDropdown";

interface Props {
  pin: Pin;
  accessLevel: PinboardAccessLevel;
}
const PinboardResultCard: React.FC<Props> = ({ pin, accessLevel }) => {
  const { t } = useLingui();
  const { currBoardId } = usePinboard();
  const [isShareResultDialogOpen, setIsShareResultDialogOpen] = useState(false);
  const [isApolloDialogOpen, setIsApolloDialogOpen] = useState(false);
  const [chartBuilderIsOpen, setChartBuilderIsOpen] = useState(false);
  return (
    <Flex
      css={{
        flexDirection: "column",
        bg: "white",
        borderRadius: "xl",
        boxShadow: "regular",
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Flex
        css={{
          p: "sm",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: 0,
          left: 0,
          bg: "white",
        }}
        id="pinboard-result-card-header"
      >
        <Flex alignItems="center">
          {accessLevel === PinboardAccessLevel.OWNER ? (
            <Tooltip content={t`Drag to reorder`}>
              <Button
                className="drag-handle"
                iconOnly={faGripDotsVertical}
                id="drag-handle"
                size="small"
                style={{ cursor: "grab" }}
                variant="dropdown"
              />
            </Tooltip>
          ) : null}
          <Tooltip content={pin.title}>
            <Box>
              <Text
                className={css({
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                })}
                level={4}
                style={{ WebkitBoxOrient: "vertical" }}
              >
                {pin.title}
              </Text>
              <Text level={2}>{pin.description}</Text>
            </Box>
          </Tooltip>
        </Flex>
        <Box>
          <PinDropdown
            accessLevel={accessLevel}
            pin={pin}
            setChartBuilderDialogOpen={setChartBuilderIsOpen}
            setIsShareResultDialogOpen={setIsShareResultDialogOpen}
          />
        </Box>
      </Flex>

      <Box
        aria-hidden="true"
        className={css({
          height: "72px",
          visibility: "hidden",
          overflow: "hidden",
          position: "absolute",
        })}
      />
      <Separator colorful={false} />
      <Box
        className={css({
          flexGrow: 1,
          overflow: "auto",
        })}
        id="pinboard-result-card-content"
      >
        <VisualizationRouter
          currentResult={pin}
          enableDataEditing={true}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
        />
      </Box>
      <ShareResultDialog
        isOpen={isShareResultDialogOpen}
        parentDocId={currBoardId}
        parentDocType={ParentDocTypeEnum.PINBOARD}
        result={pin}
        setIsShareResultDialogOpen={setIsShareResultDialogOpen}
      />
      <ApolloDialog
        isOpen={isApolloDialogOpen}
        parentDocId={currBoardId}
        parentDocType={ParentDocTypeEnum.PINBOARD}
        result={pin}
        setIsOpen={setIsApolloDialogOpen}
      />
      <ChartBuilderDialog
        isOpen={chartBuilderIsOpen}
        parentDocId={currBoardId}
        parentDocType={ParentDocTypeEnum.PINBOARD}
        result={pin}
        setIsOpen={setChartBuilderIsOpen}
      />
    </Flex>
  );
};

export { PinboardResultCard };
