import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataTable } from "@components/DataTable/DataTable";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { Trans } from "@lingui/react/macro";
import { getOrCreateTableStore } from "@stores/tableStore";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { ChartConfig } from "@utils/chartTransformations";
import React from "react";
import { useStore } from "zustand";

interface DataPanelProps {
  currentResult: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  chartConfig: ChartConfig;
  updateConfigState: (config: ChartConfig) => void;
}

const DataPanel: React.FC<DataPanelProps> = ({
  currentResult,
  chartConfig,
  updateConfigState,
  parentDocId,
  parentDocType,
}) => {
  // Get table store instance
  // This dialog can only render if data is already fetched
  const tableStore = useStore(
    getOrCreateTableStore({
      parentDocId,
      parentDocType,
      docId: currentResult.id,
      sql: currentResult.sql,
    })
  );
  const { tableInstance } = tableStore;

  // Wrapper for updateConfigState to handle conversion between the different signatures
  const handleConfigUpdate = (config: ChartConfig) => {
    updateConfigState(config);
  };

  // Check if table instance is available
  if (!tableInstance) {
    return (
      <Box
        className={css({
          py: "md",
          width: "100%",
          textAlign: "center",
        })}
      >
        <Trans>Loading data table...</Trans>
      </Box>
    );
  }

  return (
    <Box
      className={`${css({
        // py: "md",
        minHeight: "40vh",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      })} data-panel`}
      data-testid="data-panel"
    >
      <DataTable
        allowColumnSelection={true}
        chartConfig={chartConfig}
        openFilterColumns={true}
        tableInstance={tableInstance}
        updateConfigState={handleConfigUpdate}
      />
    </Box>
  );
};

export { DataPanel };
