const checkSharedUsersIsEqual = (originalList: string[], newList: string[]) => {
  if (originalList.length !== newList.length) {
    return false;
  }

  const sortedOriginal = [...originalList].sort();
  const sortedNew = [...newList].sort();

  return sortedOriginal.every((item, index) => item === sortedNew[index]);
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
  return re.test(email);
};

export { checkSharedUsersIsEqual, validateEmail };
