import { PATHS } from "@constants/Paths";
import { UserRoles } from "@doowii-types/auth";
import { Navigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

const Redirector = () => {
  const { authUser, userDocument } = useAuth();
  const adminHome = `/${PATHS.USERS}/${authUser?.uid}/${PATHS.HOME}`;
  const viewerHome = `/${PATHS.USERS}/${authUser?.uid}/${PATHS.PINBOARDS}`;
  return <Navigate to={userDocument?.role === UserRoles.ADMIN ? adminHome : viewerHome} />;
};

export { Redirector };
