import { PageHeader } from "@components/PageHeader";
import { Trans, useLingui } from "@lingui/react/macro";
import { Flex } from "@styled-system/jsx";
import { Button, Heading, Text } from "doowii-ui";
import React from "react";
import { useNavigate } from "react-router-dom";

import NotFoundSVG from "./404.svg";

const NotFoundPage: React.FC = () => {
  const { t } = useLingui();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader description={t`Not found page`} title={t`Doowii - Page not found`} />
      <Flex
        alignItems="center"
        css={{ width: "100%", height: "100vh", backgroundColor: "complementary.orangeSecondary" }}
        direction="column"
        gap="md"
        justifyContent="center"
      >
        <img alt="Not Found" src={NotFoundSVG} />
        <Heading level={3}>
          <Trans>Page Not Found</Trans>
        </Heading>

        <Text level={3}>
          <Trans>Sorry, the page you are looking for does not exist.</Trans>
        </Text>
        <Button onClick={() => navigate("/")}>
          <Trans>Return to Home</Trans>
        </Button>
      </Flex>
    </>
  );
};

export { NotFoundPage };
