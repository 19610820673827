import { DeleteWidgetDialog } from "@components/PinboardCard/DeleteWidgetDialog";
import { EditWidgetDialog } from "@components/PinboardCard/EditWidgetDialog";
import { usePinboard } from "@context/pinboard";
import { PinboardAccessLevel } from "@doowii-types/auth";
import {
  faEllipsisVertical,
  faGripDotsVertical,
  faPen,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WidgetDoc } from "@services/api/generated/webserver/models/widgetDoc";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Heading,
  Text,
  Tooltip,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  widget: WidgetDoc;
  accessLevel: PinboardAccessLevel;
}

const WidgetCard: React.FC<Props> = ({ widget, accessLevel }) => {
  const { currBoardId, fetchWidgetsForBoard } = usePinboard();
  const [isEditWidgetDialogOpen, setIsEditWidgetDialogOpen] = useState(false);
  const [isDeleteWidgetDialogOpen, setIsDeleteWidgetDialogOpen] = useState(false);

  const isOwner = accessLevel === PinboardAccessLevel.OWNER;

  return (
    <Flex
      align="center"
      className={css({
        py: "3",
        px: "4",
        w: "full",
        maxW: "100%",
        h: "full",
        bg: "white",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)",
        shadow: "regular",
        overflow: "hidden",
        position: "relative",
      })}
      direction="row"
      gap="4"
    >
      {isOwner ? (
        <Tooltip content="Drag to reorder">
          <Button
            className="drag-handle"
            iconOnly={faGripDotsVertical}
            id="drag-handle"
            size="small"
            style={{ cursor: "grab" }}
            variant="dropdown"
          />
        </Tooltip>
      ) : null}

      <Flex
        className={css({
          flexGrow: 1,
          overflow: "hidden",
          gap: "1",
          justifyContent: "center",
        })}
        direction="column"
      >
        <Heading level={2}>{widget.title}</Heading>
        {widget.description ? (
          <Box
            className={css({
              textWrap: "wrap",
              overflow: "auto",
              maxH: widget.description?.length > 100 ? "60%" : "auto",
            })}
          >
            <Text
              className={css({
                color: widget.description ? "foreground.base" : "base.gray",
                fontStyle: widget.description ? "normal" : "italic",
                lineHeight: "normal",
              })}
              level={2}
            >
              {widget.description}
            </Text>
          </Box>
        ) : null}
      </Flex>

      {isOwner ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Tooltip content="Widget options">
              <Button iconOnly={faEllipsisVertical} size="small" variant="dropdown" />
            </Tooltip>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => setIsEditWidgetDialogOpen(true)}>
              <FontAwesomeIcon icon={faPen} />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setIsDeleteWidgetDialogOpen(true)}>
              <FontAwesomeIcon icon={faTrash} />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null}

      {isOwner && currBoardId ? (
        <>
          <EditWidgetDialog
            isOpen={isEditWidgetDialogOpen}
            pinboardId={currBoardId}
            setIsOpen={setIsEditWidgetDialogOpen}
            widget={widget}
          />
          <DeleteWidgetDialog
            isOpen={isDeleteWidgetDialogOpen}
            pinboardId={currBoardId}
            setIsOpen={setIsDeleteWidgetDialogOpen}
            widgetId={widget.id}
          />
        </>
      ) : null}
    </Flex>
  );
};

export { WidgetCard };
