import { KnowledgeStatus } from "@services/api/generated/webserver/models/knowledgeStatus";
import { Stack } from "@styled-system/jsx";
import { Switch } from "doowii-ui";

interface EnableSwitchProps {
  isEnabled: boolean;
  status: KnowledgeStatus;
  onToggle: (checked: boolean) => void;
}

const EnableSwitch = ({ isEnabled, status, onToggle }: EnableSwitchProps) => (
  <Stack alignItems="center">
    <Switch
      checked={isEnabled}
      disabled={status === KnowledgeStatus.PENDING}
      onCheckedChange={onToggle}
    />
  </Stack>
);

export { EnableSwitch };
