/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Line chart transformation utilities
 */
import { CategoryType } from "@doowii-types/chart";
import { RetrieveAllResponse } from "@services/api/generated/retriever/models/retrieveAllResponse";
import { Aggregations } from "@services/api/generated/webserver/models/aggregations";
import { ColumnDataType } from "@services/api/generated/webserver/models/columnDataType";
import { DataVisualizationType } from "@services/api/generated/webserver/models/dataVisualizationType";
import { ChartConfig } from "@utils/chartTransformations";
import * as Highcharts from "highcharts";

import {
  aggregateValues,
  getColumnValue,
  getTopBreakdownValues,
  groupDataByColumn,
  MAX_BREAKDOWN_VALUES,
} from "./commonTransformations";

interface LineChartData {
  categories: string[];
  series: Highcharts.SeriesLineOptions[];
  [key: string]: unknown;
}

/**
 * Determines if the chart should be a grouped line chart based on column_grouping
 * @param chartConfig Chart configuration
 * @returns True if the chart should be a grouped line chart
 */
const isGroupedLineChart = (chartConfig: ChartConfig): boolean => {
  if (!chartConfig.column_grouping) {
    return false;
  }

  // Check if we have both category and breakdown roles in column_grouping
  const hasCategory = Object.values(chartConfig.column_grouping).includes(CategoryType.CATEGORY);
  const hasBreakdown = Object.values(chartConfig.column_grouping).includes(CategoryType.BREAKDOWN);

  return hasCategory && hasBreakdown;
};

/**
 * Transforms data for a regular line chart (no breakdown/series from column_grouping)
 * @param data Raw data from API or test data
 * @param categoricalColumns Array of categorical column names
 * @param numericalColumns Array of numerical column names
 * @param chartConfig Chart configuration
 * @returns Transformed data for a regular line chart
 */
const transformRegularLineChart = (
  data: RetrieveAllResponse,
  categoricalColumns: string[],
  numericalColumns: string[],
  chartConfig: ChartConfig
): LineChartData => {
  const { rows, column_names } = data;
  const columnNames = column_names.map((name) => name.toLowerCase());

  // Use the first categorical column as the category axis
  const categoryColumn =
    categoricalColumns.length > 0 ? categoricalColumns[0] : chartConfig.columns[0];

  // Group data by category column
  const groupedData = groupDataByColumn(rows, categoryColumn, columnNames);

  // Extract categories
  const categories = Object.keys(groupedData);

  // Create a series for each numerical column
  const series: Highcharts.SeriesLineOptions[] = numericalColumns.map((numCol) => {
    const seriesValues = categories.map((cat) => {
      const categoryRows = groupedData[cat];

      // Extract values for this category and numerical column
      const values = categoryRows.map((row) => {
        const value = getColumnValue(row, numCol, columnNames);
        return typeof value === "number" ? value : 0;
      });

      // Apply aggregation if specified
      const aggregation = chartConfig.column_aggregations?.[numCol];
      return aggregateValues(values, aggregation as Aggregations);
    });

    return {
      name: numCol,
      data: seriesValues,
      type: DataVisualizationType.LINE.toLowerCase() as Highcharts.SeriesLineOptions["type"],
    };
  });

  // If no numerical columns, create a single series with the first column
  if (series.length === 0 && chartConfig.columns.length > 1) {
    const valueColumn = chartConfig.columns[1];
    const seriesValues = categories.map((cat) => {
      const categoryRows = groupedData[cat];
      const values = categoryRows.map((row) => {
        const value = getColumnValue(row, valueColumn, columnNames);
        return typeof value === "number" ? value : 0;
      });
      const aggregation = chartConfig.column_aggregations?.[valueColumn];
      return aggregateValues(values, aggregation as Aggregations);
    });

    series.push({
      name: valueColumn,
      data: seriesValues,
      type: DataVisualizationType.LINE.toLowerCase() as Highcharts.SeriesLineOptions["type"],
    });
  }

  return {
    categories,
    series,
  };
};

/**
 * Transforms data for a grouped line chart (with breakdown/series from column_grouping)
 * @param data Raw data from API or test data
 * @param numericalColumns Array of numerical column names
 * @param chartConfig Chart configuration
 * @returns Transformed data for a grouped line chart
 */
const transformGroupedLineChart = (
  data: RetrieveAllResponse,
  numericalColumns: string[],
  chartConfig: ChartConfig
): LineChartData => {
  const { rows, column_names } = data;
  const columnNames = column_names.map((name) => name.toLowerCase());

  if (!chartConfig.column_grouping) {
    throw new Error("column_grouping must be defined for grouped line charts");
  }

  // Get the category and breakdown columns from column_grouping
  const categoryColumn = Object.entries(chartConfig.column_grouping).find(
    ([_, role]) => role === CategoryType.CATEGORY
  )?.[0];

  const breakdownColumn = Object.entries(chartConfig.column_grouping).find(
    ([_, role]) => role === CategoryType.BREAKDOWN
  )?.[0];

  if (!categoryColumn || !breakdownColumn) {
    throw new Error("Both category and breakdown columns must be specified in column_grouping");
  }

  // Group data by category column
  const groupedData = groupDataByColumn(rows, categoryColumn, columnNames);

  // Extract categories
  const categories = Object.keys(groupedData);

  // Get the top breakdown values instead of all unique values
  const { values: topBreakdownValues } = getTopBreakdownValues(
    rows,
    breakdownColumn,
    columnNames,
    MAX_BREAKDOWN_VALUES
  );

  // Create series for each numerical column and breakdown value combination
  const series: Highcharts.SeriesLineOptions[] = [];

  // For each numerical column
  numericalColumns.forEach((numCol) => {
    // First handle the top breakdown values
    topBreakdownValues.forEach((breakdownValue) => {
      // Create a series for this combination
      const seriesValues = categories.map((cat) => {
        // Find rows that match both category and breakdown value
        const matchingRows = groupedData[cat].filter(
          (row) => String(getColumnValue(row, breakdownColumn, columnNames)) === breakdownValue
        );

        if (matchingRows.length === 0) {
          return 0;
        }

        // Extract values for this category, breakdown value, and numerical column
        const values = matchingRows.map((row) => {
          const value = getColumnValue(row, numCol, columnNames);
          return typeof value === "number" ? value : 0;
        });

        // Apply aggregation if specified
        const aggregation = chartConfig.column_aggregations?.[numCol];
        return aggregateValues(values, aggregation as Aggregations);
      });

      series.push({
        name: `${numCol} - ${breakdownValue}`,
        data: seriesValues,
        type: DataVisualizationType.LINE.toLowerCase() as Highcharts.SeriesLineOptions["type"],
      });
    });

    // Now handle the "Other" category for values not in the top N
    const seriesValues = categories.map((cat) => {
      // Find rows that match this category but have breakdown values not in the top N
      const matchingRows = groupedData[cat].filter((row) => {
        const value = String(getColumnValue(row, breakdownColumn, columnNames));
        return !topBreakdownValues.includes(value);
      });

      if (matchingRows.length === 0) {
        return 0;
      }

      // Extract values for this category, breakdown value, and numerical column
      const values = matchingRows.map((row) => {
        const value = getColumnValue(row, numCol, columnNames);
        return typeof value === "number" ? value : 0;
      });

      // Apply aggregation if specified
      const aggregation = chartConfig.column_aggregations?.[numCol];
      return aggregateValues(values, aggregation as Aggregations);
    });

    // Only add the "Other" series if it has any non-zero values
    if (seriesValues.some((value) => value !== 0)) {
      series.push({
        name: `${numCol} - Other`,
        data: seriesValues,
        type: DataVisualizationType.LINE.toLowerCase() as Highcharts.SeriesLineOptions["type"],
      });
    }
  });

  // If no series were created (no numerical columns or breakdown values),
  // create a default series
  if (series.length === 0 && chartConfig.columns.length > 1) {
    const valueColumn = chartConfig.columns[1];
    const seriesValues = categories.map((cat) => {
      const categoryRows = groupedData[cat];
      const values = categoryRows.map((row) => {
        const value = getColumnValue(row, valueColumn, columnNames);
        return typeof value === "number" ? value : 0;
      });
      const aggregation = chartConfig.column_aggregations?.[valueColumn];
      return aggregateValues(values, aggregation as Aggregations);
    });

    series.push({
      name: valueColumn,
      data: seriesValues,
      type: DataVisualizationType.LINE.toLowerCase() as Highcharts.SeriesLineOptions["type"],
    });
  }

  return {
    categories,
    series,
  };
};

/**
 * Transforms raw data into a format suitable for line charts
 * @param data Raw data from API or test data
 * @param chartConfig Chart configuration containing columns,
 * column_types, column_aggregations, and column_grouping
 * @returns Transformed data ready for chart rendering
 */
const transformForLineChart = (
  data: RetrieveAllResponse,
  chartConfig: ChartConfig
): LineChartData => {
  // Ensure data has the expected structure
  if (!data || !Array.isArray(data.rows) || !Array.isArray(data.column_names)) {
    console.error("Invalid data format for line chart transformation", data);
    return { categories: [], series: [] };
  }

  const selectedColumns = chartConfig.columns;

  // Get all categorical and numerical columns
  const categoricalColumns = Object.entries(chartConfig.column_types || {})
    .filter(([_, type]) => type === ColumnDataType.categorical || type === ColumnDataType.temporal)
    .map(([col]) => col)
    .filter((col) => selectedColumns.includes(col));

  const numericalColumns = Object.entries(chartConfig.column_types || {})
    .filter(([_, type]) => type === ColumnDataType.numerical)
    .map(([col]) => col)
    .filter((col) => selectedColumns.includes(col));

  // Log warning if we don't have proper column types
  if (
    (categoricalColumns.length === 0 || numericalColumns.length === 0) &&
    chartConfig.columns.length >= 2
  ) {
    console.warn("Line chart missing explicit column types. Using column positions as fallback.", {
      chartConfig,
    });
  }

  // Determine if this is a grouped line chart
  if (isGroupedLineChart(chartConfig)) {
    return transformGroupedLineChart(data, numericalColumns, chartConfig);
  } else {
    return transformRegularLineChart(data, categoricalColumns, numericalColumns, chartConfig);
  }
};

export { transformForLineChart };
export type { LineChartData };
