import { PageHeader } from "@components/PageHeader";
import { PATHS } from "@constants/Paths";
import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";

import { useLingui } from "@lingui/react/macro";
import { Box, Flex } from "@styled-system/jsx";
import { PageLayout } from "doowii-ui";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoleChainBreadcrumb } from "./RoleChainBreadcrumb";

import {
  getGetRoleChainQueryKey,
  useGetRoleChain,
} from "@services/api/generated/webserver/endpoints/organization/organization";
import { RoleTable } from "./RoleTable";
import { buildRoleOrder, getChildRoleName, getRoleLabel, getUserAccessRole } from "./utils";

const Organization = () => {
  const { t } = useLingui();
  const navigate = useNavigate();

  const { authUser, organizationDoc } = useAuth();
  const roleHierarchy = organizationDoc?.role_hierarchy ?? {};
  const roleOrder = buildRoleOrder(roleHierarchy);

  const [crumbs, setCrumbs] = useState<
    Array<{
      role: string;
      name: string;
      label: string;
    }>
  >([]);

  const { data: initialRoleChain } = useGetRoleChain({
    query: {
      queryKey: getGetRoleChainQueryKey(),
      retry: false,
    },
  });

  const [roleState, setRoleState] = useState<{
    currentRole?: string;
    parentLevelName?: string;
    parentUserName?: string;
  }>({});

  useEffect(() => {
    if (!initialRoleChain || !authUser) return;

    const filterRoleChainUpToCurrent = async () => {
      const userRole = await getUserAccessRole(authUser);
      const newCrumbs = [];

      for (const roleKey of roleOrder) {
        const mentorName = initialRoleChain[roleKey];
        if (mentorName) {
          newCrumbs.push({
            role: roleKey,
            name: mentorName,
            label: getRoleLabel(roleHierarchy, roleKey),
          });
        }
        if (roleKey === userRole) break;
      }

      return { userRole, newCrumbs };
    };

    filterRoleChainUpToCurrent().then(({ userRole, newCrumbs }) => {
      const isLowestRole = userRole === roleOrder[roleOrder.length - 1];
      setRoleState({
        currentRole: isLowestRole ? userRole : getChildRoleName(roleHierarchy, userRole),
        parentLevelName: newCrumbs[newCrumbs.length - 1].role,
        parentUserName: newCrumbs[newCrumbs.length - 1].name,
      });
      setCrumbs(newCrumbs);
    });
  }, [initialRoleChain, authUser]);

  return (
    <>
      <PageHeader
        description={t`Manage your organization's users, settings and billing information`}
        title={t`Doowii - Organization`}
      />

      <PageLayout
        emptyProps={{
          title: t`No organization found`,
          description: t`Create an organization to get started`,
          buttonText: t`Create Organization`,
          icon: faUsers,
          onClick: () => navigate(PATHS.ROOT),
        }}
        isEmpty={false}
        title={t`Organization`}
      >
        <Box css={{ w: "full" }}>
          <Flex gap="md" flexDirection="column">
            <RoleChainBreadcrumb
              crumbs={crumbs}
              setCrumbs={setCrumbs}
              setRoleState={setRoleState}
            />

            <RoleTable roleState={roleState} setRoleState={setRoleState} setCrumbs={setCrumbs} />
          </Flex>
        </Box>
      </PageLayout>
    </>
  );
};

export { Organization };
