import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import { NavigationBar } from "@components/NavigationBar";
import { NotificationsButton } from "@components/NotificationsButton";
import { UserDropdown } from "@components/UserDropdown";
import { HIGHER_ED } from "@constants/constants";
import { OUTAGE_BANNER } from "@constants/features";
import { PATHS } from "@constants/Paths";
import { Trans } from "@lingui/react/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  BackgroundBox,
  Callout,
  LoadingLogo,
} from "doowii-ui";
import { Navigate, Outlet } from "react-router-dom";

import { env } from "../env";
import { useAuth } from "../hooks/useAuth";

const AuthLayout = () => {
  const currentPath = window.location.pathname;

  const { authUser, isLoading, userDocument, signOut, featureFlags = [] } = useAuth();
  const isAuthenticating = !authUser && isLoading;
  const showOutageWarning = featureFlags.includes(OUTAGE_BANNER);

  if (isAuthenticating) {
    return (
      <Flex css={{ h: "100vh", alignItems: "center" }}>
        <LoadingLogo />
      </Flex>
    );
  }

  const isAuthenticated = Boolean(authUser);
  const isRegistered = Boolean(userDocument);
  const isDisabled = userDocument?.registration.status === "disabled";

  if (isAuthenticated && !isRegistered) {
    if (authUser.email.startsWith("test_")) {
      return <Navigate replace state={{ from: currentPath }} to={PATHS.REGISTER} />;
    }

    return <Navigate replace state={{ from: currentPath }} to={PATHS.VERIFY_EMAIL} />;
  } else if (isDisabled) {
    return (
      <AlertDialog defaultOpen>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              <Trans>Account Disabled</Trans>
            </AlertDialogTitle>
            <AlertDialogDescription>
              <Trans>
                Your account has been disabled. Please contact your administrator for assistance.
              </Trans>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={signOut}>
              <Trans>Sign in again</Trans>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return isAuthenticated ? (
    <Flex
      css={{
        height: "100vh",
      }}
    >
      <NavigationBar />
      <BackgroundBox
        className={css({
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "2",
        })}
        containerProps={{
          css: {
            w: "full",
            display: "flex",
          },
        }}
      >
        {showOutageWarning ? (
          <Flex css={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
            <Callout size="2" variant="error">
              <Trans>
                We are currently experiencing system issues. Some features may be unavailable. Our
                team is working to resolve this as quickly as possible.
              </Trans>
            </Callout>
          </Flex>
        ) : null}
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "space-between",
            px: "10",
            py: "6",
          }}
        >
          <Breadcrumb />
          <Flex css={{ gap: 3 }}>
            <NotificationsButton />
            <UserDropdown />
          </Flex>
        </Flex>
        <Flex css={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
          <Outlet />
        </Flex>
      </BackgroundBox>
    </Flex>
  ) : (
    <Navigate
      replace
      state={{ from: currentPath }}
      to={env.REACT_APP_ENV === HIGHER_ED ? PATHS.SIGN_UP : PATHS.SIGN_IN}
    />
  );
};

export { AuthLayout };
