import { Trans, useLingui } from "@lingui/react/macro";
import { Flex, VStack } from "@styled-system/jsx";
import { InputField, Switch, Text } from "doowii-ui";
import React, { memo, useEffect, useState } from "react";

export interface AxesOptionsProps {
  initialXAxisTitle?: string;
  initialYAxisTitle?: string;
  initialShowXAxisGrid?: boolean;
  initialShowYAxisGrid?: boolean;
  onOptionsChange: (options: {
    xAxisTitle?: string;
    yAxisTitle?: string;
    showXAxisGrid?: boolean;
    showYAxisGrid?: boolean;
  }) => void;
}

export const AxesOptions: React.FC<AxesOptionsProps> = memo(
  ({
    initialXAxisTitle = "",
    initialYAxisTitle = "",
    initialShowXAxisGrid = true,
    initialShowYAxisGrid = true,
    onOptionsChange,
  }) => {
    const { t } = useLingui();

    // Set displayName inside the component
    AxesOptions.displayName = t`Axes Options`;

    // Local state
    const [xAxisTitle, setXAxisTitle] = useState(initialXAxisTitle);
    const [yAxisTitle, setYAxisTitle] = useState(initialYAxisTitle);
    const [showXAxisGrid, setShowXAxisGrid] = useState(initialShowXAxisGrid);
    const [showYAxisGrid, setShowYAxisGrid] = useState(initialShowYAxisGrid);

    // Update local state when props change
    useEffect(() => {
      setXAxisTitle(initialXAxisTitle || "");
      setYAxisTitle(initialYAxisTitle || "");
      setShowXAxisGrid(initialShowXAxisGrid !== false);
      setShowYAxisGrid(initialShowYAxisGrid !== false);
    }, [initialXAxisTitle, initialYAxisTitle, initialShowXAxisGrid, initialShowYAxisGrid]);

    // Handle X-axis title change
    const handleXAxisTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newXAxisTitle = e?.target?.value ?? "";
      setXAxisTitle(newXAxisTitle);
      onOptionsChange({ xAxisTitle: newXAxisTitle });
    };

    // Handle Y-axis title change
    const handleYAxisTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newYAxisTitle = e?.target?.value ?? "";
      setYAxisTitle(newYAxisTitle);
      onOptionsChange({ yAxisTitle: newYAxisTitle });
    };

    // Handle X-axis grid toggle
    const handleXAxisGridChange = (checked: boolean) => {
      setShowXAxisGrid(checked);
      onOptionsChange({ showXAxisGrid: checked });
    };

    // Handle Y-axis grid toggle
    const handleYAxisGridChange = (checked: boolean) => {
      setShowYAxisGrid(checked);
      onOptionsChange({ showYAxisGrid: checked });
    };

    return (
      <VStack alignItems="flex-start" gap="md" width="full">
        <InputField
          label={t`Categorical Axis Title`}
          onChange={handleXAxisTitleChange}
          placeholder={t`Enter Categorical Axis Title`}
          value={xAxisTitle}
        />
        <Flex alignItems="center" gap="sm">
          <Switch checked={showXAxisGrid} onCheckedChange={handleXAxisGridChange} />
          <Text level={3}>
            <Trans>Show Categorical Axis Grid Lines</Trans>
          </Text>
        </Flex>
        <InputField
          label={t`Series Axis Title`}
          onChange={handleYAxisTitleChange}
          placeholder={t`Enter Series Axis Title`}
          value={yAxisTitle}
        />
        <Flex alignItems="center" gap="sm">
          <Switch checked={showYAxisGrid} onCheckedChange={handleYAxisGridChange} />
          <Text level={3}>
            <Trans>Show Series Axis Grid Lines</Trans>
          </Text>
        </Flex>
      </VStack>
    );
  }
);
